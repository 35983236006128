import { Icon } from '@chakra-ui/react';

export const CustomEventsMultiIcon = (props) => (
  <Icon viewBox="0 0 67 43" {...props}>
    <path
      className="multicolorElToDark darkBgElToWhite"
      d="M13.5625 13.6643C13.5625 12.4769 14.5251 11.5143 15.7125 11.5143H50.5875C51.7749 11.5143 52.7375 12.4769 52.7375 13.6643V29.0167C52.7375 30.2041 51.7749 31.1667 50.5875 31.1667H15.7125C14.5251 31.1667 13.5625 30.2041 13.5625 29.0167V13.6643ZM17.8625 15.8143V26.8667H48.4375V15.8143H17.8625Z"
      fill="#FF8477"
    />
    <path
      className="multicolorElToWhite darkBgElToBrand"
      d="M0 3.15C0 1.4103 1.41031 0 3.15 0H63.15C64.8897 0 66.3 1.4103 66.3 3.15V15.1959C66.3 16.2499 65.5359 17.1484 64.4957 17.3179C64.2966 17.3503 64.0977 17.3979 63.9003 17.4615L63.8025 17.4929C62.1317 18.0306 60.9989 19.5852 60.9989 21.3405C60.9989 23.0957 62.1317 24.6503 63.8025 25.188L63.9003 25.2195C64.0977 25.283 64.2966 25.3306 64.4956 25.3631C65.5359 25.5325 66.3 26.4311 66.3 27.4851V39.531C66.3 41.2707 64.8897 42.681 63.15 42.681H3.15C1.4103 42.681 0 41.2706 0 39.531V27.5658C0 26.3823 0.956459 25.4213 2.13993 25.4158C2.54871 25.4139 2.96237 25.3502 3.36846 25.2195L3.46619 25.1881C5.13703 24.6503 6.26988 23.0957 6.26988 21.3405C6.26988 19.5852 5.13705 18.0306 3.46622 17.4929L3.36847 17.4615C2.96238 17.3308 2.54872 17.2671 2.13993 17.2652C0.956459 17.2596 0 16.2987 0 15.1152V3.15ZM4.3 4.3V13.2541C4.42901 13.289 4.55764 13.327 4.68579 13.3682L4.78352 13.3997C8.2319 14.5094 10.5699 17.7179 10.5699 21.3405C10.5699 24.963 8.2319 28.1715 4.78353 29.2813L4.68581 29.3127C4.55767 29.354 4.42901 29.392 4.3 29.4268V38.381H62V29.1086C58.8194 27.8648 56.6989 24.7904 56.6989 21.3405C56.6989 17.8905 58.8194 14.8161 62 13.5724V4.3H4.3Z"
      fill="#29323A"
    />
  </Icon>
);

import {
  Flex,
  Box,
  Square,
  useDisclosure,
  useOutsideClick,
  useColorModeValue,
  List,
  ListItem,
  VStack,
  Text,
  FormErrorMessage,
  FormControl,
} from '@chakra-ui/react';
import { HexColorPicker, HexColorInput } from 'react-colorful';
import React, { useRef, useState } from 'react';
import { CustomEyeDropperIcon } from '../../../theme/icons/CustomEyeDropperIcon';
import CanvasEyeDropper from './CanvasEyeDropper';

function CustomColorPicker({ color, onChange, hexError }) {
  const [dropperImage, setDropperImage] = useState();
  const modePickerPopupBg = useColorModeValue('white', 'primaryDark');
  const modeEyeDropperIconColor = useColorModeValue('primaryDark', 'white');
  const ref = useRef();
  useOutsideClick({
    ref: ref,
    handler: () => {
      onColorPickerClose();
      onEyeDropperMenuClose();
      onEyeDropperClose();
    },
  });
  const {
    isOpen: isColorPickerOpen,
    onOpen: onColorPickerOpen,
    onClose: onColorPickerClose,
  } = useDisclosure();
  const {
    isOpen: isEyeDropperOpen,
    onOpen: onEyeDropperOpen,
    onClose: onEyeDropperClose,
  } = useDisclosure();
  const {
    isOpen: isEyeDropperMenuOpen,
    onOpen: onEyeDropperMenuOpen,
    onClose: onEyeDropperMenuClose,
  } = useDisclosure();

  const handleColorPickerClick = () => {
    if (isEyeDropperOpen) {
      onEyeDropperClose();
    }
    if (isEyeDropperMenuOpen) {
      onEyeDropperMenuClose();
    }
    onColorPickerOpen();
  };

  const handleEyeDropperButtonClick = () => {
    if (isColorPickerOpen) {
      onColorPickerClose();
    }

    if (isEyeDropperOpen) {
      onEyeDropperClose();
    }

    onEyeDropperMenuOpen();
  };

  const handleEyeDropperMenuItemClick = (image) => {
    setDropperImage(image);

    if (isEyeDropperMenuOpen) {
      onEyeDropperMenuClose();
    }
    onEyeDropperOpen();
  };

  return (
    <Flex
      display="inline-flex"
      verticalAlign="top"
      pos="relative"
      ref={ref}
      className="reactColorfulParent"
    >
      <Flex direction="column">
        <Flex gap="15px">
          <Square
            size={8}
            borderRadius="4px"
            bg={color}
            onClick={handleColorPickerClick}
            border="solid 1px #333"
            // borderColor={LightenDarkenColor(color, -40)}
          />
          <HexColorInput
            color={color}
            onChange={onChange}
            onClick={handleColorPickerClick}
            className="reactColorfulInput"
          />

          <Box alignSelf="center" onClick={handleEyeDropperButtonClick}>
            <CustomEyeDropperIcon
              boxSize="20px"
              fill={modeEyeDropperIconColor}
              cursor="pointer"
            />
          </Box>
        </Flex>

        <FormControl isInvalid={hexError} mt="5px">
          <FormErrorMessage>{hexError}</FormErrorMessage>
        </FormControl>
      </Flex>

      {isColorPickerOpen && (
        <Box
          pos="absolute"
          bottom="calc(100% + 8px)"
          left="0"
          borderRadius="9px"
          p={4}
          bg={modePickerPopupBg}
          boxShadow="0 6px 12px rgba(0, 0, 0, 0.15)"
        >
          <HexColorPicker color={color} onChange={onChange} />
        </Box>
      )}

      {isEyeDropperOpen && (
        <Box
          pos="absolute"
          bottom="calc(100% + 8px)"
          left="0"
          borderRadius="9px"
          p={4}
          bg={modePickerPopupBg}
          boxShadow="0 6px 12px rgba(0, 0, 0, 0.15)"
        >
          <CanvasEyeDropper
            color={color}
            onChange={onChange}
            dropperImage={dropperImage}
          />
        </Box>
      )}

      {isEyeDropperMenuOpen && (
        <VStack
          pos="absolute"
          bottom="calc(100% + 8px)"
          left={{ base: '93%', sm: '100%' }}
          transform="translateX(-50%)"
          borderRadius="9px"
          bg={modePickerPopupBg}
          boxShadow="0 6px 12px rgba(0, 0, 0, 0.15)"
        >
          <Text fontSize="14px" fontFamily="Inter Bold" px={2} pt={2}>
            Choose an image to pick a colour from:
          </Text>
          <List minWidth="max-content" mt={1}>
            <ListItem
              fontSize="14px"
              textStyle="bodyFamilyMedium"
              p={2}
              _hover={{ bg: 'quinaryBackground' }}
              cursor="pointer"
              onClick={() => handleEyeDropperMenuItemClick('apple')}
            >
              Logo for iOS version
            </ListItem>
            <ListItem
              fontSize="14px"
              textStyle="bodyFamilyMedium"
              p={2}
              _hover={{ bg: 'quinaryBackground' }}
              cursor="pointer"
              onClick={() => handleEyeDropperMenuItemClick('android')}
            >
              Logo for Android version
            </ListItem>
            <ListItem
              fontSize="14px"
              textStyle="bodyFamilyMedium"
              p={2}
              borderBottomLeftRadius="9px"
              borderBottomRightRadius="9px"
              _hover={{ bg: 'quinaryBackground' }}
              cursor="pointer"
              onClick={() => handleEyeDropperMenuItemClick('banner')}
            >
              Banner image
            </ListItem>
          </List>
        </VStack>
      )}
    </Flex>
  );
}

export default CustomColorPicker;

import { createContext, useState } from 'react';

export const ClientDashboardStatsContext = createContext({
  dashboardState: {
    passesIssued: 0,
    activePasses: 0,
    passesPending: 0,
    emailsSent: 0,
    smsSent: 0,
    pushSent: 0,
    readers: 0,
    successfulPassTaps: 0,
    uniquePassTaps: 0,
    totalPassTaps: 0,
    totalInvalidPassTaps: 0,
    lastPassTap: null,
  },
  mostUsedReaders: [],
  filterTime: { name: 'All time', data: {}, prevSelection: '' },
  filterPassTemplate: null,
  updateFilterPassTemplate: () => {},
  updateFilterTime: () => {},
});

const ClientDashboardStatsContextProvider = ({ children }) => {
  const [dashboardState, setDashboardState] = useState({
    passesIssued: 0,
    activePasses: 0,
    passesPending: 0,
    emailsSent: 0,
    smsSent: 0,
    pushSent: 0,
    readers: 0,
    successfulPassTaps: 0,
    uniquePassTaps: 0,
    totalPassTaps: 0,
    totalInvalidPassTaps: 0,
    lastPassTap: null,
  });

  const [mostUsedReaders, setMostUsedReaders] = useState([]);
  const [filterTime, setFilterTime] = useState({
    name: 'All time',
    data: {},
    prevSelection: '',
  });

  const [filterPassTemplate, setFilterPassTemplate] = useState(null);

  const handleUpdatePassesInfoData = (data) => {
    const {
      passesIssued = data.meta.totalCount,
      activePasses = data.activePasses,
      passesPending = data.meta.pendingCount,
      emailsSent,
      smsSent,
      pushSent,
      readers,
      successfulPassTaps,
      uniquePassTaps,
      totalPassTaps,
      totalInvalidPassTaps,
      lastPassTap,
    } = data;

    setDashboardState((prev) => {
      return {
        ...prev,
        passesIssued,
        activePasses,
        passesPending,
        emailsSent,
        smsSent,
        pushSent,
        readers,
        successfulPassTaps,
        uniquePassTaps,
        totalPassTaps,
        totalInvalidPassTaps,
        lastPassTap,
      };
    });
  };

  const handleUpdateMostUsedReadersData = (data) => {
    setMostUsedReaders(data);
  };

  const handleUpdateFilterTime = (data) => {
    setFilterTime(data);
  };

  const handleUpdateFilterPassTemplate = (filterValue) => {
    setFilterPassTemplate(filterValue);
  };

  // // calculate percentage rounded on the second decimal + fix for 1.005
  // const calcPercentage = (smallerNum, largerNum) => {
  //   // for initial state rendering when both params are 0
  //   if (smallerNum === 0 && largerNum === 0) return 0;
  //   return (
  //     Math.round(smallerNum / ((largerNum + smallerNum) + Number.EPSILON) * 100)
  //   );
  // };

  const context = {
    dashboardState,
    mostUsedReaders,
    filterTime,
    filterPassTemplate,
    updatePassesInfoData: handleUpdatePassesInfoData,
    updateMostUsedReadersData: handleUpdateMostUsedReadersData,
    updateFilterTime: handleUpdateFilterTime,
    updateFilterPassTemplate: handleUpdateFilterPassTemplate,
  };

  return (
    <ClientDashboardStatsContext.Provider value={context}>
      {children}
    </ClientDashboardStatsContext.Provider>
  );
};

export default ClientDashboardStatsContextProvider;

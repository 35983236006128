import { ReadersContextProvider } from '../../store/client/ReadersContext';
import UseReader from '../../hooks/useReader';
import ReadersViewPageView from '../../components/client/readers/ReadersViewPageView';

const ReadersViewPage = () => {
  return (
    <ReadersContextProvider>
      <UseReader>
        <ReadersViewPageView />
      </UseReader>
    </ReadersContextProvider>
  );
};
export default ReadersViewPage;

import appsignal from '../appsignal';
import { useContext, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router';
import EventsContext from '../store/client/EventsContext';
import { Spinner, useToast } from '@chakra-ui/react';
import CustomToast from '../common/CustomToast';
import { getErrorResponsePayload } from '../utils/ajax';
import { HttpContext } from '../context/HttpContext';

const UseEvent = ({ children, admin }) => {
  const { uuid } = useParams();
  const { authAxios } = useContext(HttpContext);
  const eventsCtx = useContext(EventsContext);
  const navigate = useNavigate();
  // const eventsCtxAdmin = useContext(EventsContextAdmin);
  const toast = useToast();

  // const loading = admin
  //   ? eventsCtxAdmin?.eventState?.name === ''
  //   : eventsCtx?.eventState?.name === '';

  const loading = eventsCtx?.eventState?.name === '';

  // console.log(admin ? '/events-admin' : '/events');

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await authAxios.get(`api/v1/events/${uuid}`);
        eventsCtx.updateAllEventData(response?.data?.data?.attributes);
        eventsCtx.updateFirstLoad(true);
      } catch (error) {
        appsignal.sendError(error);
        const { code, errors } = getErrorResponsePayload(error);
        if (code === 403) {
          toast({
            render: (props) => (
              <CustomToast
                status="error"
                title="Something went wrong"
                description={errors[0] || 'Please try again later'}
                onClose={props.onClose}
              />
            ),
          });
        }
        code !== 401 &&
          code !== 403 &&
          toast({
            render: (props) => (
              <CustomToast
                status="error"
                title="Something went wrong"
                description="Please try again later"
                onClose={props.onClose}
              />
            ),
          });
        navigate('/events', { replace: true });
      }
    };

    getData();
  }, [authAxios]);

  if (!loading) {
    return <>{children}</>;
  }

  return (
    <Spinner
      width="52px"
      height="52px"
      thickness="4px"
      speed="0.65s"
      emptyColor="quinaryBackground"
      m="auto"
    />
  );
};

export default UseEvent;

import {
  Hide,
  Flex,
  IconButton,
  Text,
  Select,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  // useMediaQuery,
  useColorModeValue,
} from '@chakra-ui/react';
import { CustomChevronLeftIcon } from '../theme/icons/CustomChevronLeftIcon';
import { CustomChevronLeftDoubleIcon } from '../theme/icons/CustomChevronLeftDoubleIcon';

const ReactTablePagination = ({
  canPreviousPage,
  canNextPage,
  pageOptions,
  pageCount,
  gotoPage,
  nextPage,
  previousPage,
  setPageSize,
  pageIndex,
  pageSize,
}) => {
  const modeStepperColor = useColorModeValue('primaryDark', 'white');
  const modePrevNext = useColorModeValue('secondaryDark08', '#DDDDDD');

  return (
    <Flex
      alignItems="center"
      justifyContent={{ base: 'space-between', md: 'none' }}
      flexWrap={{ base: 'wrap', md: 'no-wrap' }}
    >
      <Flex order={{ base: 2, md: 1 }} mr="30px">
        <IconButton
          onClick={() => gotoPage(0)}
          isDisabled={!canPreviousPage}
          icon={<CustomChevronLeftDoubleIcon w="24px" h="24px" color={modePrevNext} />}
          mr={3}
          variant={useColorModeValue('secondary', 'white')}
          size="sm"
          w="36px"
        />
        <IconButton
          onClick={() => previousPage()}
          isDisabled={!canPreviousPage}
          icon={<CustomChevronLeftIcon w="24px" h="24px" color={modePrevNext} />}
          variant={useColorModeValue('secondary', 'white')}
          size="sm"
          w="36px"
        />
      </Flex>

      <Flex
        order={{ base: 1, md: 2 }}
        width={{ base: '100%', md: 'auto' }}
        justifyContent={{ base: 'center', md: 'none' }}
        mb={{ base: '12px', md: '0' }}
        alignItems="center"
        fontSize="12px"
      >
        <Text flexShrink="0">
          Page <Text as="span">{pageIndex + 1}</Text> of{' '}
          <Text as="span">{pageOptions.length}</Text>
        </Text>
        <Hide below="sm">
          <Text flexShrink="0" ml="30px">
            Go to page:
          </Text>{' '}
        </Hide>
        <NumberInput
          size="sm"
          ml={2}
          mr={5}
          w="72px"
          min={1}
          max={pageOptions.length}
          onChange={(value) => {
            const page = value ? value - 1 : 0;
            gotoPage(page);
          }}
          value={pageIndex + 1}
        >
          <NumberInputField />
          <NumberInputStepper>
            <NumberDecrementStepper
              children={
                <CustomChevronLeftIcon
                  transform="rotate(90deg)"
                  w="18px"
                  h="18px"
                  color={modeStepperColor}
                />
              }
            />
            <NumberIncrementStepper
              children={
                <CustomChevronLeftIcon
                  transform="rotate(270deg)"
                  w="18px"
                  h="18px"
                  color={modeStepperColor}
                />
              }
            />
          </NumberInputStepper>
        </NumberInput>
        <Select
          w={32}
          value={pageSize}
          onChange={(e) => {
            setPageSize(Number(e.target.value));
          }}
          size="sm"
        >
          {[5, 10, 20, 30, 40, 50].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </Select>
      </Flex>

      <Flex order={{ base: 3, md: 3 }} ml="20px">
        <IconButton
          onClick={() => nextPage()}
          isDisabled={!canNextPage}
          icon={
            <CustomChevronLeftIcon
              transform="rotate(180deg)"
              w="24px"
              h="24px"
              color={modePrevNext}
            />
          }
          variant={useColorModeValue('secondary', 'white')}
          size="sm"
          w="36px"
        />
        <IconButton
          onClick={() => gotoPage(pageCount - 1)}
          isDisabled={!canNextPage}
          icon={
            <CustomChevronLeftDoubleIcon
              transform="rotate(180deg)"
              w="24px"
              h="24px"
              color={modePrevNext}
            />
          }
          ml={3}
          variant={useColorModeValue('secondary', 'white')}
          size="sm"
          w="36px"
        />
      </Flex>
    </Flex>
  );
};

export default ReactTablePagination;

import {
  IconButton,
  Link,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Grid,
  GridItem,
  Divider,
  Button,
  Text,
  useDisclosure,
  useColorModeValue,
  HStack,
  VStack,
} from '@chakra-ui/react';
import moment from 'moment';

import { CustomChevronLeftIcon } from '../../../theme/icons/CustomChevronLeftIcon';
// import { CustomLockIcon } from '../../../theme/multicolor-icons/CustomLockIcon';
import { CustomPasswordDotsIcon } from '../../../theme/icons/CustomPasswordDotsIcon';
import ApiDocToggleVisibility from './ApiKeyItemVisibilityToggler';
import KeyHolder from './KeyHolder';

function ApiKeysListMoreInfo({
  cell,
  children,
  onDelete,
  visibleKeyUUID,
  setVisibleKeyUUID,
  isKeyVisible,
  setIsKeyVisible,
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const modeIconColor = useColorModeValue('secondaryDark08', '#DDDDDD');
  const modeBoxBg = useColorModeValue('white', 'secondaryDark');
  const modeTextBg = useColorModeValue('white', 'primaryDark06');
  const modeLabelBg = useColorModeValue('white', 'primaryDark03');
  const modeBorderColor = useColorModeValue('primaryBackground', 'primaryDark');

  function handleMoreInfoClick(e) {
    e.stopPropagation();
    onOpen();
  }

  const modeIcon = useColorModeValue('secondaryDark', '#fff');

  return (
    <>
      <IconButton
        onClick={handleMoreInfoClick}
        icon={
          <CustomChevronLeftIcon
            w="20px"
            h="20px"
            color={modeIconColor}
            transform="rotate(180deg)"
          />
        }
        variant="white"
        size="sm"
        w="36px"
        mr={1}
      />
      <Drawer onClose={onClose} isOpen={isOpen} size="md">
        <DrawerOverlay />
        <DrawerContent bg={modeBoxBg}>
          <DrawerCloseButton />
          <DrawerHeader
            borderBottomWidth="1px"
            borderBottomColor={modeBorderColor}
            pt={{ base: '16px', lg: '56px' }}
            pb={3}
            bg={modeTextBg}
          >
            API key overview
          </DrawerHeader>
          <DrawerBody p="0" bg={modeLabelBg}>
            <Grid
              templateColumns={{ base: '150px 1fr', md: '192px 1fr' }}
              gap={0}
            >
              <GridItem
                fontFamily="Inter Medium, sans-serif"
                fontSize="12px"
                color={modeIconColor}
                textTransform="uppercase"
                lineHeight="21px"
                padding="16px 4px 16px 22px"
              >
                API key name
              </GridItem>
              <GridItem
                fontFamily="Inter Bold, sans-serif"
                fontSize="14px"
                padding="16px 4px 16px 22px"
                bg="quinaryBackground"
                wordBreak="break-word"
              >
                {cell.row.original.apiKeyName}
              </GridItem>
              <GridItem colSpan={2} w="full">
                <Divider borderColor={modeBorderColor} />
              </GridItem>

              <GridItem
                fontFamily="Inter Medium, sans-serif"
                fontSize="12px"
                color={modeIconColor}
                textTransform="uppercase"
                lineHeight="21px"
                padding="16px 4px 16px 22px"
              >
                API key
              </GridItem>
              <GridItem
                fontSize="14px"
                padding="16px 4px 16px 22px"
                bg="quinaryBackground"
              >
                <VStack alignItems="flex-start" gap="20px">
                  <HStack>
                    {/* <CustomLockIcon width="15" height="18" /> */}
                    {visibleKeyUUID === cell.row.original.uuid ? (
                      <KeyHolder
                        id={cell.row.original.uuid}
                        secretKey={cell.row.original.token}
                        visibleKeyUUID={visibleKeyUUID}
                        isKeyVisible={isKeyVisible}
                      />
                    ) : (
                      <CustomPasswordDotsIcon
                        width="100px"
                        height="8px"
                        fill={modeIcon}
                      />
                    )}
                  </HStack>

                  <ApiDocToggleVisibility
                    id={cell.row.original.uuid}
                    visibleKeyUUID={visibleKeyUUID}
                    setVisibleKeyUUID={setVisibleKeyUUID}
                    setIsKeyVisible={setIsKeyVisible}
                  />
                </VStack>
              </GridItem>
              <GridItem colSpan={2} w="full">
                <Divider borderColor={modeBorderColor} />
              </GridItem>

              <GridItem
                fontFamily="Inter Medium, sans-serif"
                fontSize="12px"
                color={modeIconColor}
                textTransform="uppercase"
                lineHeight="21px"
                padding="16px 4px 16px 22px"
              >
                Last used
              </GridItem>
              <GridItem
                fontSize="14px"
                wordBreak="break-word"
                padding="16px 4px 16px 22px"
                bg="quinaryBackground"
              >
                {cell.row.original.lastTimeUsed === null ? (
                  <Text>Not used yet</Text>
                ) : (
                  <Text>
                    {moment(cell.row.original.lastTimeUsed).format(
                      'YYYY-MM-DD HH:mm:ss'
                    )}
                  </Text>
                )}
              </GridItem>
              <GridItem colSpan={2} w="full">
                <Divider borderColor={modeBorderColor} />
              </GridItem>
            </Grid>
          </DrawerBody>
          <DrawerFooter
            borderTopWidth="1px"
            borderTopColor={modeBorderColor}
            bg={modeTextBg}
          >
            <Link
              color={useColorModeValue('secondaryDark08', '#DDDDDD')}
              fontSize="14px"
              onClick={onClose}
              mr="30px"
            >
              <Text textStyle="headingFamilyMedium">Back to list</Text>
            </Link>
            {/* <Button
              alt="Delete API key"
              size="sm"
              px="30px"
              alignSelf="flex-start"
              onClick={onDelete}
              //  to={cell.row.original.exampleForNestedObject.eventSinglePageUrl}
            >
              Delete API key
            </Button> */}
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
}

export default ApiKeysListMoreInfo;

import React from 'react';
import EventsCreatePageForm from './EventsCreatePageForm';
import { EventsContextProvider } from '../../store/client/EventsContext';

const EventsCreatePage = () => {
  return (
    <EventsContextProvider>
      <EventsCreatePageForm />
    </EventsContextProvider>
  );
};

export default EventsCreatePage;

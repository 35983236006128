import Breadcrumbs from '../../common/Breadcrumbs';
import { Container, Divider, useColorModeValue } from '@chakra-ui/react';
import { MeContext } from '../../context/MeContext';
import { useContext } from 'react';
import useOrganizationStatus from '../../hooks/useOrganisationStatus';
import EmptyDefaultSvgAnimated from '../../theme/illustrations-animated//EmptyDefaultSvgAnimated';
import EmptyDefaultDarkSvgAnimated from '../../theme/illustrations-animated//EmptyDefaultDarkSvgAnimated';
import PassesBatchesHeader from '../../components/client/passes/PassesBatchesHeader';
import PassesBatchesList from '../../components/client/passes/PassesBatchesList';
import { BatchListContextProvider } from '../../store/client/BatchListContext';

const PassesBatchesPage = () => {
  const meCtx = useContext(MeContext);
  const { filterEntity } = meCtx.state;
  const modeSvgIcon = useColorModeValue(
    <EmptyDefaultSvgAnimated />,
    <EmptyDefaultDarkSvgAnimated />
  );
  const { shouldRender, components } = useOrganizationStatus(modeSvgIcon);

  const breadcrumbLinks = [
    {
      linkName: 'Home',
      linkUrl: '/',
    },
    {
      linkName: 'Passes',
      linkUrl: '/passes',
    },
    {
      linkName: 'Batches',
      linkUrl: '/passes/batches',
    },
  ];

  return (
    <Container display="flex" flexDir="column" flex="1" pb={6}>
      <Breadcrumbs breadcrumbLinks={breadcrumbLinks} />
      <PassesBatchesHeader />
      <Divider borderColor="primaryBackground" mb={6} />

      {components}
      {shouldRender && (
        <BatchListContextProvider>
          <PassesBatchesList filterEntity={filterEntity} />
        </BatchListContextProvider>
      )}
    </Container>
  );
};

export default PassesBatchesPage;

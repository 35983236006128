import {
  SimpleGrid,
  GridItem,
  Box,
  Container,
  Button,
  Stack,
  VStack,
  Text,
  Link,
  Divider,
} from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';

import { CustomFilterIcon } from '../../theme/icons/CustomFilterIcon';

function Buttons() {
  return (
    <Container bg="white" borderBottom="solid 1px #DDD">
      <Container maxW="1180px" px="0" py={6}>
        <SimpleGrid w="full" columns={{ base: 1, lg: 2 }} columnGap={4} rowGap={4}>
          <GridItem border="solid 1px #DDD">
            <Box p={3}>
              <VStack alignItems="flex-start" w="full">
                <Stack direction={{ base: 'column', md: 'row' }}>
                  <Button alt="Register">Register</Button>
                  <Button disabled>Register</Button>
                  {/* <Button alt="Register" w="full">
                      Register
                    </Button> */}
                </Stack>

                <Stack direction={{ base: 'column', md: 'row' }}>
                  <Button alt="Save" size="sm">
                    Save
                  </Button>
                  <Button size="sm" disabled>
                    Save
                  </Button>
                </Stack>

                <Stack direction={{ base: 'column', md: 'row' }} mb={5}>
                  <Button size="xs">
                    <Text
                      textStyle="headingFamilyMedium"
                      textTransform="none"
                      zIndex={1}
                      fontWeight="500"
                      lineHeight="28px"
                    >
                      Confirm client
                    </Text>
                  </Button>
                  <Button size="xs" disabled>
                    <Text
                      textStyle="headingFamilyMedium"
                      textTransform="none"
                      zIndex={1}
                      fontWeight="500"
                      lineHeight="28px"
                    >
                      Confirm client
                    </Text>
                  </Button>
                </Stack>

                <Divider />

                <Stack direction={{ base: 'column', md: 'row' }}>
                  <Button size="sm">
                    <Box as="span" zIndex={1}>
                      <CustomFilterIcon boxSize="19px" mr={2} />
                      Filter
                    </Box>
                  </Button>
                  <Button alt="Save" size="sm" isLoading>
                    Save
                  </Button>
                  <Button size="sm" isLoading loadingText="Saving">
                    Save
                  </Button>
                </Stack>

                <Stack direction={{ base: 'column', md: 'row' }}>
                  <Button as={RouterLink} to="/styleguide" alt="Router Link" size="sm">
                    Router Link
                  </Button>
                  <Link as={RouterLink} to="/styleguide" color="brand" alignSelf="center">
                    Router Link
                  </Link>
                </Stack>
              </VStack>
            </Box>
          </GridItem>

          <GridItem border="solid 1px #DDD">
            <Box p={3}>
              <VStack alignItems="flex-start" w="full">
                <Stack direction={{ base: 'column', md: 'row' }}>
                  <Button variant="secondary">Cancel</Button>
                  <Button variant="secondary" disabled>
                    Cancel
                  </Button>
                </Stack>

                <Stack direction={{ base: 'column', md: 'row' }}>
                  <Button variant="secondary" size="sm">
                    Cancel
                  </Button>
                  <Button variant="secondary" size="sm" disabled>
                    Cancel
                  </Button>
                </Stack>

                <Stack direction={{ base: 'column', md: 'row' }}>
                  <Button variant="secondary" size="xs">
                    <Text
                      textStyle="headingFamilyMedium"
                      textTransform="none"
                      fontWeight="500"
                      lineHeight="28px"
                    >
                      Cancel
                    </Text>
                  </Button>
                  <Button variant="secondary" size="xs" disabled>
                    <Text
                      textStyle="headingFamilyMedium"
                      textTransform="none"
                      fontWeight="500"
                      lineHeight="28px"
                    >
                      Cancel
                    </Text>
                  </Button>
                </Stack>
              </VStack>
            </Box>
          </GridItem>

          <GridItem border="solid 1px #DDD" bg="brand">
            <Box p={3}>
              <VStack alignItems="flex-start" w="full">
                <Stack direction={{ base: 'column', md: 'row' }}>
                  <Button variant="white">Developer tools</Button>
                  <Button variant="white" disabled>
                    Developer tools
                  </Button>
                </Stack>

                <Stack direction={{ base: 'column', md: 'row' }}>
                  <Button variant="white" size="sm" fontSize="12px">
                    Developer tools
                  </Button>
                  <Button variant="white" size="sm" fontSize="12px" disabled>
                    Developer tools
                  </Button>
                </Stack>

                <Stack direction={{ base: 'column', md: 'row' }}>
                  <Button variant="white" size="xs">
                    <Text
                      textStyle="headingFamilyMedium"
                      textTransform="none"
                      fontWeight="500"
                      lineHeight="28px"
                    >
                      Developer tools
                    </Text>
                  </Button>
                  <Button variant="white" size="xs" disabled>
                    <Text
                      textStyle="headingFamilyMedium"
                      textTransform="none"
                      fontWeight="500"
                      lineHeight="28px"
                    >
                      Developer tools
                    </Text>
                  </Button>
                </Stack>
              </VStack>
            </Box>
          </GridItem>
        </SimpleGrid>
      </Container>
    </Container>
  );
}

export default Buttons;

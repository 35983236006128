import appsignal from '../../appsignal';
import { useToast } from '@chakra-ui/react';
import { useContext } from 'react';
import { createContext, useState } from 'react';
import CustomToast from '../../common/CustomToast';
import { HttpContext } from '../../context/HttpContext';
import moment from 'moment';

const PassContext = createContext({
  passState: {
    pass: {
      externalId: '',
      updateExternalId: () => {},
      qrValue: '',
      updateQrValue: () => {},
      qrDisplayText: false,
      updateQrDisplayText: () => {},
      headerOne: '',
      updateHeaderOne: () => {},
      headerTwo: '',
      updateHeaderTwo: () => {},
      headerThree: '',
      updateHeaderThree: () => {},
      primaryOne: '',
      updatePrimaryOne: () => {},
      primaryTwo: '',
      updatePrimaryTwo: () => {},
      secOne: '',
      updateSecOne: () => {},
      secTwo: '',
      updateSecTwo: () => {},
      secThree: '',
      updateSecThree: () => {},
      auxOne: '',
      updateAuxOne: () => {},
      auxTwo: '',
      updateAuxTwo: () => {},
      auxThree: '',
      updateAuxThree: () => {},
      backOne: '',
      updateBackOne: () => {},
      backTwo: '',
      updateBackTwo: () => {},
      backThree: '',
      updateBackThree: () => {},
      backFour: '',
      updateBackFour: () => {},
      backFive: '',
      updateBackFive: () => {},
    },
    template: {
      name: '',
      passType: '',
      uuid: '',
      headerOne: '',
      headerTwo: '',
      headerThree: '',
      centralTitle: '',
      primaryOne: '',
      primaryTwo: '',
      secOne: '',
      secTwo: '',
      secThree: '',
      auxOne: '',
      auxTwo: '',
      auxThree: '',
      backOne: '',
      backTwo: '',
      backThree: '',
      backFour: '',
      backFive: '',
      appleAppLink: '',
      googleAppLinkPlayStoreUrl: '',
      googleAppLinkPackageName: '',
      googleAppLinkWebAppUrl: '',
    },
    qr: {
      value: '',
      displayText: false,
    },
    passDesign: {
      backgroundColor: '',
      bannerImageUrl: '',
      logoImageUrl: '',
      thumbnailImageUrl: '',
    },
    uuid: '',
    url: '',
    deviceType: '',
    createdAt: '',
    status: null,
    deactivated: null,
    addedToWalletAt: '',
    deactivatedAt: '',
    lastScan: '',
    nfc: true,
    groupTag: '',
    expiryDate: '',
    expiryTime: '',
    updateAllData: () => {},
    updateStampedPass: () => {},
    updateDeactivatedState: () => {},
  },
});

export const PassContextProvider = ({ children }) => {
  const { authAxios } = useContext(HttpContext);
  const toast = useToast();

  const [passState, setPassState] = useState({
    pass: {
      headerOne: '',
      headerTwo: '',
      headerThree: '',
      primaryOne: '',
      primaryTwo: '',
      secOne: '',
      secTwo: '',
      secThree: '',
      auxOne: '',
      auxTwo: '',
      auxThree: '',
      backOne: '',
      backTwo: '',
      backThree: '',
      backFour: '',
      backFive: '',
      url: '',
    },
    template: {
      name: '',
      uuid: '',
      headerOneId: '',
      headerOneLabel: '',
      headerOneValue: '',
      headerTwoId: '',
      headerTwoLabel: '',
      headerTwoValue: '',
      headerThreeId: '',
      headerThreeLabel: '',
      headerThreeValue: '',
      centralTitle: '',
      primaryOneId: '',
      primaryOneLabel: '',
      primaryOneValue: '',
      primaryTwoId: '',
      primaryTwoLabel: '',
      primaryTwoValue: '',
      secOneId: '',
      secOneLabel: '',
      secOneValue: '',
      secTwoId: '',
      secTwoLabel: '',
      secTwoValue: '',
      secThreeId: '',
      secThreeLabel: '',
      secThreeValue: '',
      auxOneId: '',
      auxOneLabel: '',
      auxOneValue: '',
      auxTwoId: '',
      auxTwoLabel: '',
      auxTwoValue: '',
      auxThreeId: '',
      auxThreeLabel: '',
      auxThreeValue: '',
      backOneId: '',
      backOneLabel: '',
      backOneValue: '',
      backTwoId: '',
      backTwoLabel: '',
      backTwoValue: '',
      backThreeId: '',
      backThreeLabel: '',
      backThreeValue: '',
      backFourId: '',
      backFourLabel: '',
      backFourValue: '',
      backFiveId: '',
      backFiveLabel: '',
      backFiveValue: '',
      appleAppLink: '',
      googleAppLinkPlayStoreUrl: '',
      googleAppLinkPackageName: '',
      googleAppLinkWebAppUrl: '',
    },
    passDesign: {
      backgroundColor: '',
      bannerImageUrl: '',
      logoImageUrl: '',
      thumbnailImageUrl: '',
    },
    uuid: '',
    url: '',
    deviceType: '',
    status: null,
    deactivated: null,
    createdAt: '',
    deactivatedAt: '',
    addedToWalletAt: '',
    qrValue: '',
    qrDisplayText: false,
    nfc: false,
    groupTag: '',
    expiryDate: '',
    expiryTime: '',
    lastScan: {},
  });
  const [externalId, setExternalId] = useState('');
  const [headerOne, setHeaderOne] = useState('');
  const [headerTwo, setHeaderTwo] = useState('');
  const [headerThree, setHeaderThree] = useState('');
  const [primaryOne, setPrimaryOne] = useState('');
  const [primaryTwo, setPrimaryTwo] = useState('');
  const [secOne, setSecOne] = useState('');
  const [secTwo, setSecTwo] = useState('');
  const [secThree, setSecThree] = useState('');
  const [auxOne, setAuxOne] = useState('');
  const [auxTwo, setAuxTwo] = useState('');
  const [auxThree, setAuxThree] = useState('');
  const [backOne, setBackOne] = useState('');
  const [backTwo, setBackTwo] = useState('');
  const [backThree, setBackThree] = useState('');
  const [backFour, setBackFour] = useState('');
  const [backFive, setBackFive] = useState('');
  const [url, setUrl] = useState('');
  const [deviceType, setDeviceType] = useState('');
  const [createdAt, setCreatedAt] = useState('');
  const [addedToWalletAt, setAddedToWalletAt] = useState('');
  const [qrValue, setQrValue] = useState('');
  const [qrDisplayText, setQrDisplayText] = useState(false);
  const [nfc, setNfc] = useState(false);
  const [groupTag, setGroupTag] = useState('');
  const [expiryDate, setExpiryDate] = useState('');
  const [expiryTime, setExpiryTime] = useState('');
  const [passDesign, setPassDesign] = useState({
    backgroundColor: '',
    bannerImageUrl: '',
    logoImageUrl: '',
    thumbnailImageUrl: '',
  });

  function updateExternalIdHandler(externalId) {
    setExternalId(externalId);
  }

  function updateHeaderOneHandler(header) {
    setHeaderOne(header);
  }

  function updateHeaderTwoHandler(header) {
    setHeaderTwo(header);
  }

  function updateHeaderThreeHandler(header) {
    setHeaderThree(header);
  }

  function updatePrimaryOneHandler(primary) {
    setPrimaryOne(primary);
  }

  function updatePrimaryTwoHandler(primary) {
    setPrimaryTwo(primary);
  }

  function updateSecOneHandler(field) {
    setSecOne(field);
  }

  function updateSecTwoHandler(field) {
    setSecTwo(field);
  }

  function updateSecThreeHandler(field) {
    setSecThree(field);
  }

  function updateAuxOneHandler(field) {
    setAuxOne(field);
  }

  function updateAuxTwoHandler(field) {
    setAuxTwo(field);
  }

  function updateAuxThreeHandler(field) {
    setAuxThree(field);
  }

  function updateBackOneHandler(field) {
    setBackOne(field);
  }

  function updateBackTwoHandler(field) {
    setBackTwo(field);
  }

  function updateBackThreeHandler(field) {
    setBackThree(field);
  }

  function updateBackFourHandler(field) {
    setBackFour(field);
  }

  function updateBackFiveHandler(field) {
    setBackFive(field);
  }

  function updateUrlHandler(field) {
    setUrl(field);
  }

  function updateDeviceTypeHandler(field) {
    setDeviceType(field);
  }

  function updateCreatedAtHandler(field) {
    setCreatedAt(field);
  }

  function updateAddedToWalletAtHandler(field) {
    setAddedToWalletAt(field);
  }

  function updateQrValueHandler(field) {
    setQrValue(field);
  }

  function updateQrDisplayTextHandler(field) {
    setQrDisplayText(field);
  }

  function updateNfcHandler(field) {
    setNfc(field);
  }

  function updatePassDesignHandler(field) {
    setPassDesign(field);
  }

  function updateGroupTagHandler(field) {
    setGroupTag(field);
  }

  function updateExpiryDateHandler(field) {
    setExpiryDate(field);
  }

  function updateExpiryTimeHandler(field) {
    setExpiryTime(field);
  }

  const updateLastScanHandler = (data) => {
    if (data) {
      setPassState((prevState) => {
        return {
          ...prevState,
          lastScan: { ...data },
        };
      });
    }
  };

  const updateAllDataHandler = (data) => {
    const {
      pass,
      template,
      qr,
      uuid,
      url,
      deviceType,
      status,
      deactivated,
      createdAt,
      deactivatedAt,
      addedToWalletAt,
      lastScan,
      nfc,
      passDesign,
      groupTag,
      expiryDate,
    } = data;

    let formattedExpiryDate = expiryDate ? moment(expiryDate).toDate() : '';
    let formattedExpiryTime = expiryDate
      ? moment(expiryDate).format('hh:mm A')
      : '';

    setPassState({
      pass,
      template,
      qr,
      uuid,
      url,
      deviceType,
      status,
      deactivated,
      createdAt,
      deactivatedAt,
      addedToWalletAt,
      lastScan,
      nfc,
      passDesign,
      groupTag,
      expiryDate: formattedExpiryDate,
      expiryTime: formattedExpiryTime,
    });
    // setHeader(header);
    // setPrimary(primary);
    // setSecOne(secOne);
    // setSecTwo(secTwo);
    // setSecThree(secThree);
    // setAuxOne(auxOne);
    // setAuxTwo(auxTwo);
    // setAuxThree(auxThree);
    // setBackOne(backOne);
    // setBackTwo(backTwo);
    // setBackThree(backThree);
    // setBackFour(backFour);
    // setBackFive(backFive);
  };

  const updateStampedPassHandler = (data) => {
    // function camelize(str) {
    //   return str.replace(/(?:^\p{L}+|\s+\p{Ll}+|\s+)/gu, function (match, index) {
    //     if (+match === 0) return ''; // or if (/\s+/.test(match)) for white spaces
    //     return index === 0 ? match.toLowerCase() : match.toUpperCase();
    //   });
    // }
    Object.entries(data).forEach(([key, value]) => {
      if (
        !!passState.template.secOneId &&
        key === passState.template.secOneId
      ) {
        updateSecOneHandler(value);
      }
      if (
        !!passState.template.secTwoId &&
        key === passState.template.secTwoId
      ) {
        updateSecTwoHandler(value);
      }
      if (
        !!passState.template.secThreeId &&
        key === passState.template.secThreeId
      ) {
        updateSecThreeHandler(value);
      }
      if (
        !!passState.template.auxOneId &&
        key === passState.template.auxOneId
      ) {
        updateAuxOneHandler(value);
      }
      if (
        !!passState.template.auxTwoId &&
        key === passState.template.auxTwoId
      ) {
        updateAuxTwoHandler(value);
      }
      if (
        !!passState.template.auxThreeId &&
        key === passState.template.auxThreeId
      ) {
        updateAuxThreeHandler(value);
      }
      if (
        !!passState.template.backOneId &&
        key === passState.template.backOneId
      ) {
        updateBackOneHandler(value);
      }
      if (
        !!passState.template.backTwoId &&
        key === passState.template.backTwoId
      ) {
        updateBackTwoHandler(value);
      }
      if (
        !!passState.template.backThreeId &&
        key === passState.template.backThreeId
      ) {
        updateBackThreeHandler(value);
      }
      if (
        !!passState.template.backFourId &&
        key === passState.template.backFourId
      ) {
        updateBackFourHandler(value);
      }
      if (
        !!passState.template.backFiveId &&
        key === passState.template.backFiveId
      ) {
        updateBackFiveHandler(value);
      }
    });
  };

  async function handleDeactivatedStatus(uuid) {
    let submittedData = {
      pass: {
        nfc: 'false',
        qr: {
          value: '',
          displayText: false,
        },
      },
      passAction: 'deactivate',
    };

    try {
      const response = await authAxios.patch(`passes/${uuid}`, submittedData);
      if (response.data.data.attributes.status === 'deactivated') {
        passState.status = 'deactivated';
        passState.deactivatedAt = response.data.data.attributes.deactivatedAt;
        toast({
          render: (props) => (
            <CustomToast
              status="success"
              title="Pass has been deactivated"
              description="The pass has been successfully deactivated."
              onClose={props.onClose}
            />
          ),
        });
      } else {
        toast({
          render: (props) => (
            <CustomToast
              status="error"
              title="Something went wrong"
              description="Please try again later."
              onClose={props.onClose}
            />
          ),
        });
      }

      // avoid lastScan = {reader: {}}
      // const lastScanObj =
      //   data.data.lastScan == null
      //     ? null
      //     : { ...passState.lastScan, reader: { ...passState.lastScan?.reader } };

      // setPassState({
      //   ...data.data,
      //   ticket: {
      //     ...passState.ticket,
      //     event: {
      //       ...passState.ticket.event,
      //       template: { ...passState.ticket.event.template },
      //     },
      //   },
      //   lastScan: lastScanObj,
      // });
    } catch (error) {
      appsignal.sendError(error);
      console.log(error);
      toast({
        render: (props) => (
          <CustomToast
            status="error"
            title="Pass deactivation has failed"
            description="Please try again later."
            onClose={props.onClose}
          />
        ),
      });
    }
  }

  const updateDeactivatedStateHandler = async (param) => {
    await handleDeactivatedStatus(param);
  };

  const context = {
    passState,
    updateAllData: updateAllDataHandler,
    externalId: externalId,
    updateExternalId: updateExternalIdHandler,
    headerOne,
    updateHeaderOne: updateHeaderOneHandler,
    headerTwo,
    updateHeaderTwo: updateHeaderTwoHandler,
    headerThree,
    updateHeaderThree: updateHeaderThreeHandler,
    primaryOne,
    updatePrimaryOne: updatePrimaryOneHandler,
    primaryTwo,
    updatePrimaryTwo: updatePrimaryTwoHandler,
    secOne: secOne,
    updateSecOne: updateSecOneHandler,
    secTwo: secTwo,
    updateSecTwo: updateSecTwoHandler,
    secThree: secThree,
    updateSecThree: updateSecThreeHandler,
    auxOne: auxOne,
    updateAuxOne: updateAuxOneHandler,
    auxTwo: auxTwo,
    updateAuxTwo: updateAuxTwoHandler,
    auxThree: auxThree,
    updateAuxThree: updateAuxThreeHandler,
    backOne: backOne,
    updateBackOne: updateBackOneHandler,
    backTwo: backTwo,
    updateBackTwo: updateBackTwoHandler,
    backThree: backThree,
    updateBackThree: updateBackThreeHandler,
    backFour: backFour,
    updateBackFour: updateBackFourHandler,
    backFive: backFive,
    updateBackFive: updateBackFiveHandler,
    qrValue: qrValue,
    updateQrValue: updateQrValueHandler,
    qrDisplayText: qrDisplayText,
    updateQrDisplayText: updateQrDisplayTextHandler,
    url: url,
    updateUrl: updateUrlHandler,
    createdAt: createdAt,
    updateCreatedAt: updateCreatedAtHandler,
    addedToWalletAt: addedToWalletAt,
    updateAddedToWalletAt: updateAddedToWalletAtHandler,
    deviceType: deviceType,
    updateDeviceType: updateDeviceTypeHandler,
    nfc: nfc,
    updateNfc: updateNfcHandler,
    updateDeactivatedState: updateDeactivatedStateHandler,
    updateLastScan: updateLastScanHandler,
    passDesign: passDesign,
    updatePassDesign: updatePassDesignHandler,
    groupTag: groupTag,
    updateGroupTag: updateGroupTagHandler,
    expiryDate: expiryDate,
    updateExpiryDate: updateExpiryDateHandler,
    expiryTime: expiryTime,
    updateExpiryTime: updateExpiryTimeHandler,
    updateStampedPass: updateStampedPassHandler,
  };

  return (
    <PassContext.Provider value={context}>{children}</PassContext.Provider>
  );
};

export default PassContext;

import { useMediaQuery } from '@chakra-ui/react';
import { Container, Flex, Box } from '@chakra-ui/react';
import Footer from './Footer';
import ScrollToTopForRouter from '../components/common/ScrollToTopForRouter';
import passentryIntroSrc from '../assets/images/passentry-intro.webp';
import passentryIntroVideoSrc from '../assets/videos/passentry-intro.mp4';
import useGATracker from '../hooks/useGATracker';

const LayoutAuth = (props) => {
  useGATracker();

  const [isLargerThanMD] = useMediaQuery('(min-width: 48em)');

  return (
    <>
      <ScrollToTopForRouter />
      <Flex direction="column" minH="100vh">
        {props.header}
        <Container variant="bodyContent">
          <Flex w="100%" justifyContent={{ base: 'center', md: 'flex-start' }}>
            {isLargerThanMD && (
              <Box w="45%">
                <Box
                  overflow="hidden"
                  h="full"
                  maxH="100vh"
                  borderBottomRightRadius="25px"
                  position="sticky"
                  top="0"
                  backgroundColor="secondaryDark"
                  backgroundImage={`url(${passentryIntroSrc})`}
                  backgroundPosition="center"
                  backgroundRepeat="no-repeat"
                  backgroundSize="cover"
                >
                  <Box
                    as="video"
                    muted
                    autoPlay
                    loop
                    src={passentryIntroVideoSrc}
                    poster={passentryIntroSrc}
                    alt="PassEntry"
                    position="absolute"
                    objectFit="cover"
                    w="full"
                    h="full"
                    pointerEvents="none"
                  />
                </Box>
              </Box>
            )}
            <Box
              pr="16px"
              pl={{ base: '16px', md: '5%', xl: '10%' }}
              flex={{ base: 1, sm: 'none' }}
            >
              {props.children}
            </Box>
          </Flex>
        </Container>
        <Footer />
      </Flex>
    </>
  );
};

export default LayoutAuth;

import React from 'react';
import ClientDashboardPage from '../client/ClientDashboardPage';
import ClientDashboardStatsContextProvider from '../../store/client/ClientDashboardStatsContext';

function DashboardPage() {
  return (
    <ClientDashboardStatsContextProvider>
      <ClientDashboardPage />
    </ClientDashboardStatsContextProvider>
  );
}

export default DashboardPage;

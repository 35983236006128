import {
  Flex,
  Heading,
  Text,
  useColorModeValue,
  Spacer,
  Box,
  Button,
} from '@chakra-ui/react';
import { useContext } from 'react';
import EventsContext from '../../../store/client/EventsContext';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { CustomEditIcon } from '../../../theme/icons/CustomEditIcon';

const EventViewHeader = () => {
  const { uuid } = useParams();
  const eventsCtx = useContext(EventsContext);
  const modeHelperText = useColorModeValue('secondaryDark08', '#DDDDDD');

  return (
    <Flex
      flexDirection={{ base: 'column', sm: 'row' }}
      alignItems="center"
      flexWrap="wrap"
      mt="15px"
      py="9px"
      minH="54px"
    >
      <Heading alignSelf={{ base: 'flex-start', sm: 'center' }} wordBreak="break-word">
        <>
          {eventsCtx.eventState.name}{' '}
          <Text
            as="span"
            display="inline-block"
            textStyle="bodyFamilyMedium"
            fontSize="14px"
            color={modeHelperText}
            lineHeight="130%"
            wordBreak="break-word"
          >
            / uuid: {uuid}
          </Text>
        </>
      </Heading>
      <Spacer />
      {eventsCtx.eventState.status !== 'ended' && (
        <>
          <Button
            alignSelf={{ base: 'flex-start', sm: 'center' }}
            as={RouterLink}
            size="sm"
            mr={3}
            px="30px"
            to={`/events/edit/${uuid}`}
          >
            <Box as="span" zIndex={1}>
              <CustomEditIcon boxSize="20px" mb="4px" color1="#fff" />
              <Text as="span" pl="8px">
                Edit event
              </Text>
            </Box>
          </Button>
          <Button
            alignSelf={{ base: 'flex-start', sm: 'center' }}
            as={RouterLink}
            size="sm"
            px="30px"
            to={`/pass-templates/create?event=${uuid}`}
          >
            <Box as="span" zIndex={1}>
              <Text as="span">Create pass template</Text>
            </Box>
          </Button>
        </>
      )}
    </Flex>
  );
};
export default EventViewHeader;

import appsignal from '../../appsignal';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Button,
  Heading,
  Stack,
  Input,
  FormControl,
  FormLabel,
  FormErrorMessage,
  useColorModeValue,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useContext, useState } from 'react';
import NewApiKeyModal from './NewApiKeyModal';
import { HttpContext } from '../../context/HttpContext';
import CustomToast from '../../common/CustomToast';

const CreateApiKeyModal = ({ onClose, isOpen, fetchData }) => {
  const { authAxios } = useContext(HttpContext);
  const [revealApiKey, setRevealApiKey] = useState([]);
  const {
    isOpen: isNewApiKeyOpen,
    onOpen: onNewApiKeyOpen,
    onClose: onNewApiKeyClose,
  } = useDisclosure();
  const modeBoxBg = useColorModeValue('white', 'secondaryDark');
  const modeTextBg = useColorModeValue('white', 'primaryDark06');
  const modeLabelBg = useColorModeValue('white', 'primaryDark03');
  const modeOffset = useColorModeValue('0', '16px !important');
  const toast = useToast();

  const validationSchema = Yup.object().shape({
    apiKeyName: Yup.string().required('Please enter API key name'),
  });
  const formOptions = {
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
  };

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting, isDirty, isValid },
    reset,
  } = useForm(formOptions);

  const onSubmit = async (data) => {
    const submittedData = {
      name: data.apiKeyName,
    };
    try {
      const { data } = await authAxios.post(`keys`, submittedData);
      setRevealApiKey((prevState) => [...prevState, data.token]);
      reset();
      onClose();
      onNewApiKeyOpen();
    } catch (error) {
      appsignal.sendError(error);
      const errors = error.response.data;
      if (errors) {
        errors.forEach((error) => {
          toast({
            render: (props) => (
              <CustomToast
                status="error"
                title={error}
                description=""
                onClose={props.onClose}
              />
            ),
          });
        });
      }
    }
  };

  return (
    <>
      <Modal onClose={onClose} isOpen={isOpen} size="xl">
        <ModalOverlay />
        <ModalContent bg={modeBoxBg}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <ModalHeader
              bg={modeTextBg}
              pb={modeOffset}
              borderRadius="15px 15px 0 0"
            >
              <Heading
                fontSize={{ base: '24px', lg: '32px' }}
                lineHeight="130%"
              >
                Create API key
              </Heading>
            </ModalHeader>
            <ModalBody bg={modeLabelBg}>
              <FormControl isInvalid={errors.apiKeyName}>
                <FormLabel>API key name</FormLabel>
                <Input
                  id="apiKeyName"
                  type="string"
                  placeholder="e.g. Event name API key"
                  {...register('apiKeyName')}
                  autoComplete="off"
                />
                <FormErrorMessage pl={4} pt={2}>
                  {errors.apiKeyName?.message || errors.apiKeyName}
                </FormErrorMessage>
              </FormControl>
            </ModalBody>
            <ModalFooter
              bg={modeTextBg}
              pt={modeOffset}
              borderRadius="0 0 15px 15px"
            >
              <Stack
                spacing="10px"
                direction={{ base: 'column', sm: 'row' }}
                width={{ base: 'full', sm: 'auto' }}
              >
                <Button
                  onClick={() => {
                    onClose();
                    reset();
                  }}
                  alt="Cancel"
                  variant="secondary"
                  width={{ base: 'full', sm: 'auto' }}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  alt="Create API key"
                  width={{ base: 'full', sm: 'auto' }}
                  isLoading={isSubmitting}
                  disabled={!isDirty || !isValid || isSubmitting}
                >
                  Create API key
                </Button>
              </Stack>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>

      <NewApiKeyModal
        isOpen={isNewApiKeyOpen}
        onClose={onNewApiKeyClose}
        onCloseCreate={onClose}
        revealApiKey={revealApiKey}
        setRevealApiKey={setRevealApiKey}
        fetchData={fetchData}
      />
    </>
  );
};
export default CreateApiKeyModal;

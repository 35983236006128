import {
  Box,
  Circle,
  useColorModeValue,
  HStack,
  Tooltip,
} from '@chakra-ui/react';

const ReadersRenderStatus = ({ loggedIn }) => {
  const modeColorText = useColorModeValue('secondaryDark', 'white');
  const bgTooltip = useColorModeValue('secondaryDark', '#dddddd');
  const textTooltip = useColorModeValue('#ffffff', 'primaryDark');

  return loggedIn ? (
    <>
      <Tooltip
        label="A device is currently logged in using these credentials."
        hasArrow
        placement="top"
        fontSize="12px"
        color={textTooltip}
        w="205px"
        borderRadius="6px"
        bg={bgTooltip}
        textAlign="center"
        p="10px"
        mb="5px"
        closeOnClick={false}
      >
        <HStack
          pr="12px"
          pl="12px"
          py="7px"
          bg="#ddd"
          borderRadius="20px"
          justifyContent="center"
          alignItems="center"
          width="fit-content"
        >
          <Circle bg="success" size="12px" />
          <Box
            fontSize="12px"
            top="1px"
            pos="relative"
            pb="1px"
            color="secondaryDark"
          >
            Logged in
          </Box>
        </HStack>
      </Tooltip>
    </>
  ) : (
    <Tooltip
      label="No devices are logged in using these credentials at the moment."
      hasArrow
      placement="top"
      fontSize="12px"
      color={textTooltip}
      w="205px"
      borderRadius="6px"
      bg={bgTooltip}
      textAlign="center"
      p="10px"
      mb="5px"
      closeOnClick={false}
    >
      <HStack
        pr="12px"
        pl="12px"
        py="7px"
        bg="#ddd"
        borderRadius="20px"
        justifyContent="center"
        alignItems="center"
        width="fit-content"
      >
        <Circle bg="error" size="12px" />
        <Box
          fontSize="12px"
          top="1px"
          pos="relative"
          pb="1px"
          color="secondaryDark"
        >
          Not logged in
        </Box>
      </HStack>
    </Tooltip>
  );
};

export default ReadersRenderStatus;

import { Icon } from '@chakra-ui/react';

export const CustomTrashcanMultiIcon = (props) => (
  <Icon viewBox="0 0 58 58" {...props}>
    <path
      className="multicolorElToDark darkBgElToWhite"
      d="M53.824 9.28H40.832V3.944C40.832 1.624 38.976 0 36.888 0H19.256C16.936 0 15.312 1.856 15.312 3.944V9.28H2.32C1.16 9.28 0 10.208 0 11.6C0 12.992 1.16 13.92 2.32 13.92H53.824C55.216 13.92 56.144 12.76 56.144 11.6C56.144 10.44 55.216 9.28 53.824 9.28ZM20.184 4.872H36.192V9.28H20.184V4.872Z"
      fill="#FF8477"
    />
    <path
      className="multicolorElToWhite darkBgElToBrand"
      d="M45.7917 18.5599L42.7757 53.1279H14.9357L11.9197 18.5599C11.9197 17.3999 10.7597 16.2399 9.59971 16.2399C8.20771 16.2399 7.04771 17.3999 7.27971 18.7919L10.2957 54.2879C10.5277 56.3759 12.3837 57.9999 14.2397 57.9999H43.4717C45.5597 57.9999 47.1837 56.3759 47.4157 54.2879L50.4317 19.0239C50.6637 17.6319 49.5037 16.4719 48.1117 16.4719C46.9517 16.4719 46.0237 17.3999 45.7917 18.5599Z"
      fill="#29323A"
    />
  </Icon>
);

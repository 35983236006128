import React, { useEffect } from 'react';
import { useTable, usePagination } from 'react-table';
import TableBox from '../../common/TableBox';
import ReactTablePagination from '../../../common/ReactTablePagination';
import EmptyState from '../../../common/EmptyState';
import MessageBatchStatus from '../messages/MessageStatus';
import { Link } from 'react-router-dom';
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Spinner,
  Box,
  Text,
  Heading,
  Flex,
  Stack,
  Button,
  Tooltip,
  useColorModeValue,
  useMediaQuery,
  Progress,
} from '@chakra-ui/react';
import { CustomCsvDownloadIcon } from '../../../theme/icons/CustomCsvDownloadIcon';

const PassesBatchesTable = ({
  columns,
  data,
  loading,
  pageCount,
  total,
  fetchData,
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    // Pagination props
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount: controlledPageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    setHiddenColumns,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      manualPagination: true,
      pageCount,
      initialState: { pageIndex: 0 },
    },
    usePagination
  );

  const [isLargerThan2XL] = useMediaQuery('(min-width: 96em)');
  const [isLargerThanXL] = useMediaQuery('(min-width: 80em)');
  const [isLargerThanMD] = useMediaQuery('(min-width: 48em)');
  const [isLargerThanSM] = useMediaQuery('(min-width: 30em)');
  const [isLargerThanS] = useMediaQuery('(min-width: 18em)');
  const [isLargerThanXS] = useMediaQuery('(min-width: 10em)');

  useEffect(() => {
    fetchData({ pageIndex, pageSize });
  }, [fetchData, pageIndex, pageSize]);

  useEffect(() => {
    if (isLargerThan2XL) {
      setHiddenColumns([]);
    } else if (isLargerThanXL) {
      setHiddenColumns(['passTemplate']);
    } else if (isLargerThanMD) {
      setHiddenColumns(['passTemplate', 'totalPasses', 'progress']);
    } else if (isLargerThanSM) {
      setHiddenColumns([
        'passTemplate',
        'totalPasses',
        'progress',
        'completedAt',
      ]);
    } else if (isLargerThanS) {
      setHiddenColumns([
        'passTemplate',
        'totalPasses',
        'progress',
        'completedAt',
        'status',
      ]);
    } else {
      setHiddenColumns([
        'passTemplate',
        'totalPasses',
        'progress',
        'completedAt',
        'status',
      ]);
    }
  }, [
    isLargerThan2XL,
    isLargerThanXL,
    isLargerThanMD,
    isLargerThanSM,
    isLargerThanS,
    isLargerThanXS,
    setHiddenColumns,
  ]);

  const bgTooltip = useColorModeValue('secondaryDark', '#dddddd');
  const textTooltip = useColorModeValue('#ffffff', 'primaryDark');

  if (loading) {
    return (
      <Box textAlign="center">
        <Spinner />
      </Box>
    );
  }

  if (data.length === 0 && !loading) {
    return (
      <EmptyState
        heading="No Pass Creation Batches Found"
        text="You haven't created any passes via CSV batches yet."
      >
        <Button alt="New Batch" size="sm" as={Link} to="/passes/batches/create">
          New Batch
        </Button>
      </EmptyState>
    );
  }

  return (
    <>
      <TableBox>
        <Heading size="lg" mb="16px">
          Pass Creation Batches{' '}
          <Text
            as="span"
            textStyle="headingFamilyMedium"
            fontSize="18px"
            fontWeight="500"
          >
            (
            <Box as="span" color="brand">
              {total}
            </Box>
            )
          </Text>
        </Heading>
        <Table {...getTableProps()}>
          <Thead>
            {headerGroups.map((headerGroup) => (
              <Tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <Th {...column.getHeaderProps()} style={column.style}>
                    {column.render('Header')}
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
          <Tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <Tr
                  {...row.getRowProps()}
                  _hover={{
                    cursor: 'default',
                  }}
                >
                  {row.cells.map((cell) => {
                    return (
                      <Td {...cell.getCellProps()} style={cell.column.style}>
                        {cell.column.id === 'status' ? (
                          <MessageBatchStatus status={cell.value} type="pass" />
                        ) : cell.column.id === 'progress' ? (
                          cell.row.values.status === 'processing' &&
                          cell.value !== null &&
                          cell.value !== undefined ? (
                            <Progress
                              colorScheme="green"
                              size="lg"
                              value={cell.value}
                            />
                          ) : null
                        ) : cell.column.id === 'csvResults' ? (
                          cell.value ? (
                            <Link to={cell.value}>
                              <CustomCsvDownloadIcon
                                color="brand"
                                boxSize={7}
                              />
                            </Link>
                          ) : null
                        ) : (
                          cell.render('Cell')
                        )}
                      </Td>
                    );
                  })}
                </Tr>
              );
            })}
          </Tbody>
        </Table>
        {data.length > 0 && (
          <Flex
            mt={6}
            flexDirection={{ base: 'column-reverse', '2xl': 'row' }}
            justifyContent={{ base: 'none', '2xl': 'space-between' }}
          >
            <Stack
              spacing="18px"
              direction={{ base: 'column', sm: 'row' }}
              shouldWrapChildren="true"
            ></Stack>

            <ReactTablePagination
              canPreviousPage={canPreviousPage}
              canNextPage={canNextPage}
              pageOptions={pageOptions}
              pageCount={pageCount}
              gotoPage={gotoPage}
              nextPage={nextPage}
              previousPage={previousPage}
              setPageSize={setPageSize}
              pageIndex={pageIndex}
              pageSize={pageSize}
            />
          </Flex>
        )}
      </TableBox>
    </>
  );
};

export default PassesBatchesTable;

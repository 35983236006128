import { useContext } from 'react';
import { Container, Divider, useColorModeValue, Text, Link } from '@chakra-ui/react';
import Breadcrumbs from '../../common/Breadcrumbs';
import EmptyState from '../../common/EmptyState';
import Prompt from '../../common/Prompt';
import { MeContext } from '../../context/MeContext';
import { ReadersListContextProvider } from '../../store/client/ReadersListContext';
import ReadersList from '../../components/client/readers/ReadersList';
import ReaderListHeader from '../../components/client/readers/ReaderListHeader';
import ReaderIconAnimated from '../../theme/illustrations-animated//ReaderIconAnimated';
import ReaderIconDarkAnimated from '../../theme/illustrations-animated//ReaderIconDarkAnimated';
import useOrganizationStatus from '../../hooks/useOrganisationStatus';

const ReadersListPage = () => {
  const breadcrumbLinks = [
    {
      linkName: 'Home',
      linkUrl: '/',
    },
    {
      linkName: 'Pass readers',
      linkUrl: '/readers',
    },
  ];

  const modeReadersIcon = useColorModeValue(
    <ReaderIconAnimated />,
    <ReaderIconDarkAnimated />
  );

  const { components, shouldRender } = useOrganizationStatus(modeReadersIcon);

  return (
    <ReadersListContextProvider>
      <Container display="flex" flexDir="column" flex="1" pb={6}>
        <Breadcrumbs breadcrumbLinks={breadcrumbLinks} />
        <ReaderListHeader />
        <Divider borderColor="primaryBackground" mb={6} />

        {components}

        {shouldRender && <ReadersList />}

      </Container>
    </ReadersListContextProvider>
  );
};
export default ReadersListPage;

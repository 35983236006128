import { Icon } from '@chakra-ui/react';

export const CustomSendIcon = (props) => {
  return (
    <Icon viewBox="0 0 512 512" fill="none" {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" fill="none">
        <path
          d="M498.1 5.59992C508.2 12.5999 513.5 24.6999 511.6 36.7999L447.6 452.8C446.1 462.5 440.2 471 431.6 475.8C423 480.6 412.7 481.2 403.6 477.4L284 427.7L215.5 501.8C206.6 511.5 192.6 514.7 180.3 509.9C168 505.1 160 493.2 160 480V396.4C160 392.4 161.5 388.6 164.2 385.7L331.8 202.8C337.6 196.5 337.4 186.8 331.4 180.8C325.4 174.8 315.7 174.4 309.4 180.1L106 360.8L17.6997 316.6C7.09967 311.3 0.299673 300.7 -0.000326642 288.9C-0.300327 277.1 5.89967 266.1 16.0997 260.2L464.1 4.19992C474.8 -1.90008 488 -1.30008 498.1 5.59992Z"
          fill="currentColor"
        />
      </svg>
    </Icon>
  );
};

import appsignal from '../appsignal';
import { useContext, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router';
import ReadersContext from '../store/client/ReadersContext';
import { Spinner, useToast } from '@chakra-ui/react';
import CustomToast from '../common/CustomToast';
import { getErrorResponsePayload } from '../utils/ajax';
import { HttpContext } from '../context/HttpContext';

const UseReader = ({ children }) => {
  const { uuid } = useParams();
  const { authAxios } = useContext(HttpContext);
  const readerCtx = useContext(ReadersContext);
  const toast = useToast();
  const navigate = useNavigate();

  const loading = readerCtx?.readerState?.name !== '';

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await authAxios.get(`api/v1/readers/${uuid}`);
        const data = response?.data?.data;
        readerCtx.updateAllData(data);
      } catch (error) {
        appsignal.sendError(error);
        const { code, errors } = getErrorResponsePayload(error);
        code !== 401 &&
          errors?.forEach((errorMessage) => {
            toast({
              render: ({ onClose }) => (
                <CustomToast
                  status="error"
                  title={errorMessage || 'Something went wrong'}
                  description={errorMessage ? '' : 'Please try again later.'}
                  onClose={onClose}
                />
              ),
            });
          });
        navigate('/readers', { replace: true });
      }
    };

    getData();
  }, []);

  if (loading) {
    return <>{children}</>;
  }

  return (
    <Spinner
      width="52px"
      height="52px"
      thickness="4px"
      speed="0.65s"
      emptyColor="quinaryBackground"
      m="auto"
    />
  );
};

export default UseReader;

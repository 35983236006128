import ReadersCreatePageForm from './ReadersCreatePageForm';
import { ReadersContextProvider } from '../../store/client/ReadersContext';

const ReadersCreatePage = () => {
  return (
    <ReadersContextProvider>
      <ReadersCreatePageForm />
    </ReadersContextProvider>
  );
};
export default ReadersCreatePage;

import {
  IconButton,
  Button,
  Grid,
  Flex,
  useDisclosure,
  useColorModeValue,
} from '@chakra-ui/react';
import { CustomChevronLeftIcon } from '../../../theme/icons/CustomChevronLeftIcon';
import InfoDrawer from '../../common/InfoDrawer';
import { Link } from 'react-router-dom';
import MoreInfoLine from './MoreInfoLine';
import MessageStatus from './MessageStatus';

function MessageLogMoreInfo({ cell, resend }) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const modeIconColor = useColorModeValue('secondaryDark08', '#DDDDDD');
  const modeBoxBg = useColorModeValue('white', 'secondaryDark');
  const modeTextBg = useColorModeValue('white', 'primaryDark06');
  const modeLabelBg = useColorModeValue('white', 'primaryDark03');
  const modeBorderColor = useColorModeValue('primaryBackground', 'primaryDark');

  function handleMoreInfoClick(e) {
    e.stopPropagation();
    onOpen();
  }

  const bgColors = {
    modeBoxBg,
    modeTextBg,
    modeBorderColor,
    modeLabelBg,
  };

  return (
    <>
      <IconButton
        onClick={handleMoreInfoClick}
        icon={
          <CustomChevronLeftIcon
            w="20px"
            h="20px"
            color={modeIconColor}
            transform="rotate(180deg)"
          />
        }
        variant="white"
        size="sm"
        w="36px"
        mr={1}
      />
      <InfoDrawer
        isOpen={isOpen}
        onClose={onClose}
        header="Message Overview"
        footer={
          <Flex w="full" justifyContent="space-between" alignItems="center">
            <Link
              color={useColorModeValue('secondaryDark08', '#DDDDDD')}
              fontSize="12px"
              onClick={onClose}
              mr="30px"
            >
              Back to list
            </Link>
            {cell.row.original.resendable && (
              <Button size="sm" onClick={() => resend(cell.row.original.id)}>
                Resend
              </Button>
            )}
          </Flex>
        }
        bgColors={bgColors}
      >
        <Grid templateColumns={{ base: '150px 1fr', md: '192px 1fr' }} gap={0}>
          <MoreInfoLine
            heading="Batch Name"
            value={cell.row.original.messageBatchName}
          />
          <MoreInfoLine
            heading="Batch ID"
            value={cell.row.original.messageBatchId}
          />
          <MoreInfoLine heading="Message ID" value={cell.row.original.id} />
          <MoreInfoLine
            heading="Message Type"
            value={cell.row.original.messageType}
          />
          <MoreInfoLine
            heading="Status"
            value={
              <MessageStatus status={cell.row.original.status} type="log" />
            }
          />
          {(cell.row.original.status === 'failed' ||
            cell.row.original.status === 'cancellation_failed' ||
            cell.row.original.status === 'undelivered') && (
            <MoreInfoLine
              heading="Error Message"
              value={cell.row.original.errorMessage}
            />
          )}
          {cell.row.original.resendable && (
            <MoreInfoLine
              heading="Resend Attempts"
              value={cell.row.original.resendAttempts}
            />
          )}
          <MoreInfoLine heading="Content" value={cell.row.original.content} />
          {cell.row.original.messageType === 'mms' && (
            <MoreInfoLine
              heading="Media URL"
              value={cell.row.original.mediaUrl}
            />
          )}
          <MoreInfoLine
            heading="Recipient"
            value={cell.row.original.recipient}
          />
          {cell.row.original.scheduledAt && (
            <MoreInfoLine
              heading="Scheduled At"
              value={cell.row.original.scheduledAt}
            />
          )}
          <MoreInfoLine
            heading="Failed At"
            value={cell.row.original.failedAt}
          />
        </Grid>
      </InfoDrawer>
    </>
  );
}

export default MessageLogMoreInfo;

import { Icon } from '@chakra-ui/react';

export const CustomInfoIcon = (props) => (
  <Icon viewBox="0 0 20 20" {...props}>
    <path
      fill="currentColor"
      d="M9.99999 0.626343C4.83062 0.626343 0.625 4.83197 0.625 10.0013C0.625 15.1707 4.83062 19.3763 9.99999 19.3763C15.1694 19.3763 19.375 15.1707 19.375 10.0013C19.375 4.83197 15.1694 0.626343 9.99999 0.626343ZM9.99999 4.47009C10.241 4.47009 10.4767 4.54157 10.6771 4.67549C10.8775 4.8094 11.0337 4.99975 11.126 5.22244C11.2182 5.44514 11.2424 5.69019 11.1953 5.92661C11.1483 6.16302 11.0322 6.38018 10.8618 6.55063C10.6913 6.72107 10.4742 6.83714 10.2378 6.88417C10.0013 6.9312 9.7563 6.90706 9.5336 6.81482C9.3109 6.72257 9.12056 6.56636 8.98664 6.36594C8.85272 6.16552 8.78124 5.92989 8.78124 5.68884C8.78124 5.36561 8.90965 5.05561 9.13821 4.82705C9.36677 4.59849 9.67676 4.47009 9.99999 4.47009V4.47009ZM12.25 15.0638H8.125C7.92608 15.0638 7.73532 14.9848 7.59467 14.8442C7.45401 14.7035 7.375 14.5127 7.375 14.3138C7.375 14.1149 7.45401 13.9242 7.59467 13.7835C7.73532 13.6429 7.92608 13.5638 8.125 13.5638H9.43749V9.43884H8.68749C8.48858 9.43884 8.29782 9.35982 8.15717 9.21917C8.01651 9.07852 7.9375 8.88775 7.9375 8.68884C7.9375 8.48993 8.01651 8.29916 8.15717 8.15851C8.29782 8.01786 8.48858 7.93884 8.68749 7.93884H10.1875C10.3864 7.93884 10.5772 8.01786 10.7178 8.15851C10.8585 8.29916 10.9375 8.48993 10.9375 8.68884V13.5638H12.25C12.4489 13.5638 12.6397 13.6429 12.7803 13.7835C12.921 13.9242 13 14.1149 13 14.3138C13 14.5127 12.921 14.7035 12.7803 14.8442C12.6397 14.9848 12.4489 15.0638 12.25 15.0638Z"
    />
  </Icon>
);

import { useContext, useRef } from 'react';
import DatepickerContext from './DatepickerContext';
import { useDay } from '@datepicker-react/hooks';
import { styled } from '@stitches/react';

const isToday = (date) => {
  const today = new Date();
  return (
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
  );
};

const Button = styled('button', {
  //   backgroundColor: '#fff',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: 2,
  fontSize: 12,
  width: 30,
  height: 30,
  borderRadius: '50%',
  transition: 'all .2s ease',
  cursor: 'pointer',
  border: '2px solid transparent',
  '&:hover': {
    borderColor: '#FF8477',
  },

  variants: {
    selected: {
      true: {
        color: '#fff !important',
        backgroundColor: '#FF8477',
        '&:hover': {
          backgroundColor: '#FF8477',
        },
      },
    },
    isToday: {
      true: {
        color: '#FF8477 !important',
      },
    },
    blocked: {
      true: {
        color: 'LightGray !important',
        '&:hover': {
          borderColor: 'unset !important',
        },
      }
    },
    isTodayAndBlocked: {
      true: {
        color: 'rgba(255, 132, 119, 0.7) !important',
      },
    },
    currentMonth: {
      true: {
        color: '#29323A',
      },
      false: {
        color: 'LightGray',
      },
    },
    currentMonthDark: {
      true: {
        color: '#fff',
      },
      false: {
        color: 'DarkGrey',
      },
    },
    backgroundColor: {
      darkMode: {
        backgroundColor: 'inherit',
        //    color: '#fff',
      },
    },
  },
});

export function Day({ dayLabel, date, type, darkMode }) {
  const dayRef = useRef(null);
  const {
    focusedDate,
    isDateFocused,
    isDateSelected,
    isDateHovered,
    isDateBlocked,
    isFirstOrLastSelectedDate,
    onDateSelect,
    onDateFocus,
    onDateHover,
    setPickerOpen,
  } = useContext(DatepickerContext);
  const { isSelected, onClick, onKeyDown, onMouseEnter, tabIndex } = useDay({
    date,
    focusedDate,
    isDateFocused,
    isDateSelected,
    isDateHovered,
    isDateBlocked,
    isFirstOrLastSelectedDate,
    onDateFocus,
    onDateSelect,
    onDateHover,
    dayRef,
  });
  if (!dayLabel) return <div></div>;

  return (
    <Button
      currentMonth={!darkMode && type !== 'next' && type !== 'prev'}
      currentMonthDark={darkMode && type !== 'next' && type !== 'prev'}
      selected={isSelected}
      blocked={isDateBlocked(date)}
      isTodayAndBlocked={isToday(date) && isDateBlocked(date)}
      onClick={() => {
        onClick();
        setPickerOpen(false);
      }}
      onKeyDown={onKeyDown}
      onMouseEnter={onMouseEnter}
      tabIndex={tabIndex}
      type="button"
      ref={dayRef}
      isToday={isToday(date)}
      backgroundColor={darkMode && 'darkMode'}
    >
      {parseInt(dayLabel, 10)}
    </Button>
  );
}

import { Icon } from '@chakra-ui/react';

export const CustomVisibilityIcon = (props) => {
  return (
    <Icon viewBox="0 0 58 41" {...props}>
      <path
        className="multicolorElToDark darkBgElToWhite"
        d="M29.0006 13.542C32.6388 13.542 35.5915 16.5193 35.5915 20.1878C35.5915 23.8563 32.6388 26.8337 29.0006 26.8337C25.3624 26.8337 22.4097 23.8563 22.4097 20.1878C22.4097 16.5193 25.3624 13.542 29.0006 13.542ZM29.0006 8.22534C22.4624 8.22534 17.137 13.5952 17.137 20.1878C17.137 26.7805 22.4624 32.1503 29.0006 32.1503C35.5388 32.1503 40.8642 26.7805 40.8642 20.1878C40.8642 13.5952 35.5388 8.22534 29.0006 8.22534Z"
        fill="#FF8477"
      />
      <path
        className="multicolorElToWhite darkBgElToBrand"
        d="M29 5.56667C38.9918 5.56667 47.9027 11.2289 52.2527 20.1875C47.9027 29.1461 38.9918 34.8083 29 34.8083C19.0082 34.8083 10.0973 29.1461 5.74727 20.1875C10.0973 11.2289 19.0082 5.56667 29 5.56667ZM29 0.25C15.8182 0.25 4.56091 8.51742 0 20.1875C4.56091 31.8576 15.8182 40.125 29 40.125C42.1818 40.125 53.4391 31.8576 58 20.1875C53.4391 8.51742 42.1818 0.25 29 0.25Z"
        fill="#29323A"
      />
    </Icon>
  );
};

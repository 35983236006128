import { Icon } from '@chakra-ui/react';

export const CustomEditIcon = (props) => (
  <Icon viewBox="0 0 15 14" fill="none" {...props}>
    <g clipPath="url(#clip0_3038_8643)">
      <path
        d="M8.87477 2.12913L8.11328 2.89062L11.3877 6.16504L12.1492 5.40355L8.87477 2.12913Z"
        fill="currentColor"
      />
      <path
        d="M2.11598 8.92601L1.35449 9.6875L4.62891 12.9619L5.3904 12.2004L2.11598 8.92601Z"
        fill="currentColor"
      />
      <path
        d="M0.818735 14C0.657197 14 0.549504 13.9462 0.441812 13.8385C0.33412 13.7308 0.280273 13.5154 0.280273 13.3538L1.19566 9.15385C1.19566 9.04615 1.2495 8.93846 1.3572 8.88462L10.0264 0.161538C10.188 0.0538462 10.2957 0 10.4572 0C10.6187 0 10.7264 0.0538462 10.8341 0.161538L14.1187 3.44615C14.2264 3.55385 14.2803 3.66154 14.2803 3.82308C14.2803 3.98462 14.2264 4.09231 14.1187 4.2L5.39566 12.8692C5.34181 12.9231 5.23412 12.9769 5.12643 13.0308L0.926427 14C0.872581 14 0.872581 14 0.818735 14ZM2.21873 9.53077L1.51874 12.7615L4.7495 12.0615L12.9341 3.82308L10.4034 1.29231L2.21873 9.53077Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_3038_8643">
        <rect
          width="14"
          height="14"
          fill="currentColor"
          transform="translate(0.280273)"
        />
      </clipPath>
    </defs>
  </Icon>
);

import {
  SimpleGrid,
  GridItem,
  Box,
  Container,
  AspectRatio,
  Image,
  Spinner,
} from '@chakra-ui/react';

import thankYouSrc from '../../assets/vectors/thank-you.svg';
import verifyingYourEmailSrc from '../../assets/vectors/verifying-your-email.svg';
import emptySrc from '../../assets/vectors/empty.svg';
import emptyReadersSrc from '../../assets/vectors/empty-readers.svg';
import emptyArtistsSrc from '../../assets/vectors/empty-artists.svg';
import emptyClientsSrc from '../../assets/vectors/empty-clients.svg';
import emptyEventsSrc from '../../assets/vectors/events.svg';
// import logoDarkModeSrc from '../../assets/vectors/logoDarkMode.svg';
import logoSrc from '../../assets/vectors/logo.svg';
import logoDevSrc from '../../assets/vectors/logoDev.svg';
// import logoDevDarkSrc from '../../assets/vectors/logoDevDarkMode.svg';
import viewEditSrc from '../../assets/vectors/view-edit.svg';
import passTemplateSrc from '../../assets/vectors/pass-template.svg';
import ticketingSrc from '../../assets/vectors/ticketing.svg';
import loyaltySrc from '../../assets/vectors/loyalty.svg';
import membershipSrc from '../../assets/vectors/membership.svg';
import accessControlSrc from '../../assets/vectors/accessControl.svg';
import readersSrc from '../../assets/vectors/reader.svg';

const illustrations = [
  { src: thankYouSrc, width: 'auto' },
  { src: verifyingYourEmailSrc, width: 'auto' },
  { src: emptySrc, width: 'auto' },
  { src: emptyReadersSrc, width: 'auto' },
  { src: emptyArtistsSrc, width: 'auto' },
  { src: emptyClientsSrc, width: 'auto' },
  { src: emptyEventsSrc, width: 'auto' },
  { src: readersSrc, width: 'auto' },
  { src: viewEditSrc, width: '64px' },
  { src: passTemplateSrc, width: 'auto' },
  { src: ticketingSrc, width: 'auto' },
  { src: loyaltySrc, width: 'auto' },
  { src: membershipSrc, width: 'auto' },
  { src: accessControlSrc, width: 'auto' },
];
const logos = [logoSrc, logoDevSrc];

function Illustrations() {
  return (
    <Container bg="white" borderBottom="solid 1px #DDD">
      <Container maxW="1180px" px="0" py={6}>
        <SimpleGrid w="full" columns={{ base: 1, sm: 2, md: 4 }} columnGap={4} rowGap={4}>
          {logos.map((item, index) => {
            return (
              <GridItem key={index} border="solid 1px #DDD">
                <AspectRatio ratio={1}>
                  <Box>
                    <Image w="137px" src={item} alt="" />
                  </Box>
                </AspectRatio>
              </GridItem>
            );
          })}
          {illustrations.map((item, index) => {
            return (
              <GridItem key={index} border="solid 1px #DDD">
                <AspectRatio ratio={1}>
                  <Box>
                    <Image src={item.src} alt="" width={item.width} />
                  </Box>
                </AspectRatio>
              </GridItem>
            );
          })}
          <GridItem border="solid 1px #DDD">
            <AspectRatio ratio={1}>
              <Box>
                <Spinner thickness="4px" speed="0.65s" emptyColor="secondaryBackground" />
              </Box>
            </AspectRatio>
          </GridItem>
        </SimpleGrid>
      </Container>
    </Container>
  );
}

export default Illustrations;

import {
  Button,
  Box,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  FormControl,
  Input,
  VStack,
  Link,
  Circle,
  Text,
  Checkbox,
  Divider,
  Collapse,
  useColorModeValue,
  Heading,
  Flex,
} from '@chakra-ui/react';
import { useDisclosure } from '@chakra-ui/react';
import { CustomFilterIcon } from '../../../theme/icons/CustomFilterIcon';
import { useForm } from 'react-hook-form';
import { useState, useContext } from 'react';
import ReadersListContext from '../../../store/client/ReadersListContext';
import { CustomReactSelect } from '../../common/CustomReactSelect';

function ReaderListFilter() {
  const readerListCtx = useContext(ReadersListContext);
  const [totalActiveFilters, setTotalActiveFilters] = useState(0);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isOpenName, onToggle: onToggleName } = useDisclosure();
  const { isOpen: isOpenUuid, onToggle: onToggleUuid } = useDisclosure();
  const { isOpen: isOpenType, onToggle: onToggleType } = useDisclosure();
  const modeBoxBg = useColorModeValue('white', 'secondaryDark');
  const modeTextBg = useColorModeValue('white', 'primaryDark06');
  const modeLabelBg = useColorModeValue('white', 'primaryDark03');
  const modeBorderColor = useColorModeValue('primaryBackground', 'primaryDark');
  const modeLabelColor = useColorModeValue('secondaryDark', 'white');
  const { register, handleSubmit, reset } = useForm();
  const [readerType, setReaderType] = useState(null);

  const onSubmit = async (values) => {
    values.type = readerType ? readerType.value : '';
    readerListCtx.updateFilterValues(values);

    let countableFilterValues = [];
    Object.entries(values).forEach(([key, value]) => {
      if (!key.startsWith('by')) {
        countableFilterValues = countableFilterValues.concat(value);
      }
    });
    setTotalActiveFilters(countableFilterValues.filter((val) => val.length !== 0).length);

    onClose();
  };

  const onResetAll = async () => {
    reset();
    readerListCtx.updateFilterValues({
      name: '',
      uuid: '',
      type: ''
    });
    setTotalActiveFilters(0);
    onClose();
    isOpenName && onToggleName();
    isOpenUuid && onToggleUuid();
    isOpenType && onToggleType();
  };

  const readerTypes = [
    {
      label: 'PassEntry Reader',
      value: 'PassentryReader',
    },
    {
      label: 'VTAP Reader',
      value: 'VtapReader',
    },
  ];

  const handleCheckboxChange = (e, toggleFunction, callbackFunction) => {
    toggleFunction();
    callbackFunction();
  };

  return (
    <Box mt={{ sm: '10px' }}>
      <Flex gap={{ base: '10px', sm: '0px' }} wrap="wrap">
        {totalActiveFilters > 0 && (
          <Button variant="white02" size="sm" px="20px" mr="10px" onClick={onResetAll}>
            <Text
              textStyle="headingFamilyMedium"
              textTransform="none"
              fontWeight="500"
              lineHeight="28px"
            >
              Clear filter
            </Text>
          </Button>
        )}
        <Button size="sm" px="30px" onClick={onOpen}>
          <Box as="span" zIndex={1}>
            {totalActiveFilters ? (
              <Box as="span" display="inline-block">
                <Circle bg="white" size="18px" mr="10px" color="brand" fontSize="12px">
                  {totalActiveFilters}
                </Circle>
              </Box>
            ) : (
              <CustomFilterIcon boxSize="19px" mr="10px" />
            )}
            Filter
          </Box>
        </Button>
      </Flex>
      <Drawer onClose={onClose} isOpen={isOpen}>
        <DrawerOverlay />
        <form onSubmit={handleSubmit(onSubmit)}>
          <DrawerContent bg={modeBoxBg}>
            <DrawerCloseButton />
            <DrawerHeader
              borderBottomWidth="1px"
              borderBottomColor={modeBorderColor}
              pt={{ base: '16px', lg: '56px' }}
              bg={modeTextBg}
            >
              <Heading>Filter readers</Heading>
            </DrawerHeader>
            <DrawerBody p="0" bg={modeLabelBg}>
              <VStack spacing={0}>
                <FormControl px="24px" mt="12px">
                  <Checkbox
                    id="byName"
                    {...register('byName')}
                    onChange={(e) =>
                      handleCheckboxChange(e, onToggleName, () => {
                        reset({ name: '' });
                      })
                    }
                  >
                    <Text color={modeLabelColor}>By name</Text>
                  </Checkbox>
                  <Collapse in={isOpenName} animateOpacity>
                    <Input
                      mt={3}
                      id="name"
                      type="text"
                      placeholder="Enter reader name"
                      {...register('name')}
                    />
                  </Collapse>
                </FormControl>

                <Box py="12px" w="full">
                  <Divider borderColor={modeBorderColor} />
                </Box>

                <FormControl px="24px" mt="12px">
                  <Checkbox
                    id="byUuid"
                    {...register('byUuid')}
                    onChange={(e) =>
                      handleCheckboxChange(e, onToggleUuid, () => {
                        reset({ uuid: '' });
                      })
                    }
                  >
                    <Text color={modeLabelColor}>By reader ID</Text>
                  </Checkbox>
                  <Collapse in={isOpenUuid} animateOpacity>
                    <Input
                      mt={3}
                      id="uuid"
                      type="text"
                      placeholder="Enter reader ID"
                      {...register('uuid')}
                    />
                  </Collapse>
                </FormControl>

                <Box py="12px" w="full">
                  <Divider borderColor={modeBorderColor} />
                </Box>

                <FormControl px="24px" mt="12px">
                  <Checkbox
                    id="byType"
                    {...register('byType')}
                    onChange={(e) =>
                      handleCheckboxChange(e, onToggleType, () => {
                        setReaderType(null);
                      })
                    }
                  >
                    <Text color={modeLabelColor}>By reader type</Text>
                  </Checkbox>
                  <Collapse in={isOpenType} animateOpacity>
                    <CustomReactSelect
                      options={readerTypes}
                      // formatOptionLabel={formatOptionLabelSelectPassType}
                      placeholder="Select reader type"
                      onChange={(readerType) => setReaderType(readerType)}
                      value={readerType}
                      id="readerTypeFilter"
                    />
                  </Collapse>
                </FormControl>

                <Box py="12px" w="full">
                  <Divider borderColor={modeBorderColor} />
                </Box>
              </VStack>
            </DrawerBody>
            <DrawerFooter
              borderTopWidth="1px"
              borderTopColor={modeBorderColor}
              bg={modeTextBg}
            >
              <Link
                color={useColorModeValue('secondaryDark08', '#DDDDDD')}
                fontSize="14px"
                onClick={onResetAll}
                mr="30px"
              >
                <Text textStyle="headingFamilyMedium">Reset all</Text>
              </Link>
              <Button
                type="submit"
                alt="Apply filters"
                size="sm"
                px="30px"
                alignSelf="flex-start"
              >
                Apply filters
              </Button>
            </DrawerFooter>
          </DrawerContent>
        </form>
      </Drawer>
    </Box>
  );
}

export default ReaderListFilter;

import {
  Heading,
  Center,
  Image,
  Text,
  VStack,
  useColorModeValue,
} from '@chakra-ui/react';
import emptySrc from '../assets/vectors/empty.svg';
import emptySrcDark from '../assets/vectors/empty-dark.svg';

const EmptyState = (props) => {
  const modeHeadingColor = useColorModeValue('secondaryDark', 'white');
  const modeTextColor = useColorModeValue('secondaryDark08', 'white');
  const emptyModeSrc = useColorModeValue(emptySrc, emptySrcDark);

  return (
    <Center flex="1">
      <VStack spacing={6} textAlign="center" maxW={props.maxW ? props.maxW : '410px'}>
        {props.iconSvgAnimated
          ? props.iconSvgAnimated
          : !props.noImage && (
              <Image src={props.iconSrc ? props.iconSrc : emptyModeSrc} alt="No data" />
            )}

        <VStack spacing={4}>
          <Heading size="lg" color={modeHeadingColor}>
            {props.heading}
          </Heading>
          <Text textStyle="bodyRoman" color={modeTextColor}>
            {props.text}
          </Text>
        </VStack>
        {props.children}
      </VStack>
    </Center>
  );
};

export default EmptyState;

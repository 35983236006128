import {
  useMediaQuery,
  Heading,
  Text,
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Td,
  Th,
  Button,
  Center,
  useDisclosure,
  Spinner,
  useColorModeValue,
  Flex,
} from '@chakra-ui/react';
import { useTable, usePagination } from 'react-table';
import TableBox from '../../common/TableBox';
import EmptyState from '../../../common/EmptyState';
// import { CustomTrashcanMultiIcon } from '../../../theme/multicolor-icons/CustomTrashcanMultiIcon';
import DeleteApiKeyModal from '../../modals/DeleteApiKeyModal';
import { useState, useEffect, useRef } from 'react';
import ApiKeysListMoreInfo from './ApiKeysListMoreInfo';
import CreateApiKeyModal from '../../modals/CreateApiKeyModal';
import moment from 'moment';
import ApiDocToggleVisibility from './ApiKeyItemVisibilityToggler';
import KeyHolder from './KeyHolder';
import ApiKeysIconAnimated from '../../../theme/illustrations-animated/ApiKeysIconAnimated';
import ApiKeysIconDarkAnimated from '../../../theme/illustrations-animated/ApiKeysIconDarkAnimated';

// Create a default prop getter (per documentation https://react-table.tanstack.com/docs/examples/data-driven-classes-and-styles)
const defaultPropGetter = () => ({});

export default function ApiKeysListTable({
  columns,
  data,
  fetchData,
  loading,
  total,
  pageCount: controlledPageCount,
  // getHeaderProps = defaultPropGetter,
  getColumnProps = defaultPropGetter,
  getCellProps = defaultPropGetter,
}) {
  // use this ref to keep track of updating internal table state
  const tableStateUpdateRef = useRef(false);
  const [deleteKey, setDeleteKey] = useState();
  const [visibleKeyUUID, setVisibleKeyUUID] = useState('');
  const [isKeyVisible, setIsKeyVisible] = useState(false);

  const [isLargerThan2XL] = useMediaQuery('(min-width: 96em)');
  const [isLargerThanXL] = useMediaQuery('(min-width: 80em)');
  const [isLargerThanLG] = useMediaQuery('(min-width: 62em)');
  const [isLargerThanMD] = useMediaQuery('(min-width: 48em)');
  const [isLargerThanSM] = useMediaQuery('(min-width: 30em)');
  const [isLargerThanS] = useMediaQuery('(min-width: 18em)');
  const [isLargerThanXS] = useMediaQuery('(min-width: 10em)');
  const modeKeysIcon = useColorModeValue(
    <ApiKeysIconAnimated />,
    <ApiKeysIconDarkAnimated />
  );

  const {
    isOpen: isCreateApiKeyOpen,
    onOpen: onCreateApiKeyOpen,
    onClose: onCreateApiKeyClose,
  } = useDisclosure();

  const {
    isOpen: isDeleteApiKeyOpen,
    onOpen: onDeleteApiKeyOpen,
    onClose: onDeleteApiKeyClose,
  } = useDisclosure();

  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page, // Instead of using 'rows', we'll use page, which has only the rows for the active page
    gotoPage,
    setHiddenColumns,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
      // initialState: { pageIndex: 2, hiddenColumns: hiddenTableColumns },
      manualPagination: true, // Tell the usePagination
      // hook that we'll handle our own data fetching
      // This means we'll also have to provide our own
      // pageCount.
      pageCount: controlledPageCount,
    },
    usePagination
  );

  useEffect(() => {
    if (isLargerThan2XL) {
      setHiddenColumns(['moreInfo']);
    } else if (isLargerThanXL) {
      setHiddenColumns(['moreInfo']);
    } else if (isLargerThanLG) {
      setHiddenColumns(['moreInfo']);
    } else if (isLargerThanMD) {
      setHiddenColumns(['moreInfo']);
    } else if (isLargerThanSM) {
      setHiddenColumns(['lastTimeUsed', 'apiKey', 'visibility']);
    } else if (isLargerThanS) {
      setHiddenColumns(['lastTimeUsed', 'apiKey', 'visibility']);
    } else if (isLargerThanXS) {
      setHiddenColumns(['lastTimeUsed', 'apiKey', 'visibility', 'action']);
    } else {
      setHiddenColumns(['lastTimeUsed', 'apiKey', 'visibility', 'action']);
    }
  }, [
    isLargerThan2XL,
    isLargerThanXL,
    isLargerThanLG,
    isLargerThanMD,
    isLargerThanSM,
    isLargerThanS,
    setHiddenColumns,
    isLargerThanXS,
  ]);

  // Listen for changes in pagination and use the state to fetch our new data
  useEffect(() => {
    fetchData();
  }, [fetchData]);

  // reset the page index to 0 when the table data updates due to something other than internal table state changes
  useEffect(() => {
    gotoPage(0);
  }, [gotoPage]);

  // clear our ref when the data is loaded, after we perform any side effects
  useEffect(() => {
    tableStateUpdateRef.current = false;
  }, [data]);

  // closes api key preview if delete or create other / new api key
  useEffect(() => {
    setIsKeyVisible(false);
    setVisibleKeyUUID('');
  }, [data]);

  function handleRowClick(row) {
    setDeleteKey(row.original.exampleForNestedObject.keySinglePageUrl);
  }

  if (loading) {
    return (
      <Center>
        <Spinner
          width="52px"
          height="52px"
          thickness="4px"
          speed="0.65s"
          emptyColor="quinaryBackground"
          mt={4}
          mx="auto"
        />
      </Center>
    );
  }

  return (
    <>
      <TableBox>
        <Flex justifyContent="space-between" alignItems="center">
          <Heading size="lg" mb="16px">
            API keys
          </Heading>
          <Button
            alignSelf={{ base: 'flex-start', sm: 'center' }}
            mt={{ base: '12px', sm: '0' }}
            alt="Create api key"
            size="sm"
            onClick={onCreateApiKeyOpen}
          >
            Create api key
          </Button>
        </Flex>
        <Table {...getTableProps()}>
          <Thead>
            {headerGroups.map((headerGroup) => (
              <Tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => {
                  return (
                    <Th
                      {...column.getHeaderProps()}
                      {...column.getToggleHiddenProps()}
                      isNumeric={column.isNumeric}
                    >
                      {column.render('Header')}
                    </Th>
                  );
                })}
              </Tr>
            ))}
          </Thead>
          {data.length > 0 && (
            <Tbody {...getTableBodyProps()}>
              {page.map((row, i) => {
                prepareRow(row);
                return (
                  <Tr {...row.getRowProps()} onClick={() => handleRowClick(row)}>
                    {row.cells.map((cell) => {
                      return (
                        <Td
                          {...cell.getCellProps([
                            {
                              className: cell.column.className,
                              style: cell.column.style,
                            },
                            getColumnProps(cell.column),
                            getCellProps(cell),
                          ])}
                          isNumeric={cell.column.isNumeric}
                        >
                          {cell.column.id === 'apiKey' ? (
                            <KeyHolder
                              id={cell.row.original.id}
                              secretKey={cell.row.original.token}
                              visibleKeyUUID={visibleKeyUUID}
                              isKeyVisible={isKeyVisible}
                            />
                          ) : cell.column.id === 'lastTimeUsed' ? (
                            cell.row.original.lastTimeUsed === null ? (
                              <Text>Not used yet</Text>
                            ) : (
                              <Text>
                                {moment(cell.row.original.lastTimeUsed).format(
                                  'YYYY-MM-DD HH:mm:ss'
                                )}
                              </Text>
                            )
                          ) : cell.column.id === 'visibility' ? (
                            <ApiDocToggleVisibility
                              id={cell.row.original.id}
                              visibleKeyUUID={visibleKeyUUID}
                              setVisibleKeyUUID={setVisibleKeyUUID}
                              setIsKeyVisible={setIsKeyVisible}
                            />
                          ) : // ) : cell.column.id === 'action' ? (
                          //   <Box onClick={onDeleteApiKeyOpen}>
                          //     <Flex justifyContent="center" alignItems="center">
                          //       <CustomTrashcanMultiIcon
                          //         className={colorMode === 'dark' && 'multicolorDarkBg'}
                          //       />
                          //       <Text color="brand" pl="7px" pt="2px">
                          //         Delete
                          //       </Text>
                          //     </Flex>
                          //   </Box>
                          cell.column.id === 'moreInfo' ? (
                            <ApiKeysListMoreInfo
                              cell={cell}
                              onDelete={onDeleteApiKeyOpen}
                              visibleKeyUUID={visibleKeyUUID}
                              setVisibleKeyUUID={setVisibleKeyUUID}
                              setIsKeyVisible={setIsKeyVisible}
                              isKeyVisible={isKeyVisible}
                            />
                          ) : cell.value !== null ? (
                            cell.render('Cell')
                          ) : (
                            <Text as="span" color="secondaryDark06">
                              N/D
                            </Text>
                          )}
                        </Td>
                      );
                    })}
                  </Tr>
                );
              })}
            </Tbody>
          )}
        </Table>
        {data.length === 0 && (
          <Box mt={7}>
            <EmptyState
              iconSvgAnimated={modeKeysIcon}
              heading="You don’t have any API Keys."
              text="You can use our API if you are looking to integrate PassEntry into your CRM / Platform. Get your API key by clicking the button below."
            >
              <Button alt="Create api key" size="sm" onClick={onCreateApiKeyOpen}>
                Create api key
              </Button>
            </EmptyState>
          </Box>
        )}
      </TableBox>

      <CreateApiKeyModal
        isOpen={isCreateApiKeyOpen}
        onClose={onCreateApiKeyClose}
        fetchData={fetchData}
      />
      <DeleteApiKeyModal
        isOpen={isDeleteApiKeyOpen}
        onClose={onDeleteApiKeyClose}
        actionUrl={deleteKey}
      />
    </>
  );
}

import {
  Heading,
  Input,
  Center,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Button,
  Box,
  Text,
  VStack,
  useToast,
  useColorModeValue,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import CardForm from '../../components/common/CardForm';
import { FORM_PADDING_TOP_XL } from '../../utils/consts';
import { useContext } from 'react';
import CustomToast from '../../common/CustomToast';
import { getErrorResponsePayload } from '../../utils/ajax';
import { HttpContext } from '../../context/HttpContext';

function ForgotPasswordPage() {
  const { publicAxios } = useContext(HttpContext);
  const toast = useToast();
  const modeText = useColorModeValue('secondaryDark08', '#DDDDDD');

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .required('Please enter your email address')
      .email('Please enter a valid email address'),
  });
  const formOptions = {
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
  };

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting, isDirty, isValid },
    reset,
  } = useForm(formOptions);

  function onSubmit(values) {
    return new Promise((resolve) => {
      const data = {
        email: values.email,
      };

      publicAxios
        .post(`/send_reset_password_link`, data)
        .catch((onError) => {
          console.log(onError);
          const { message } = getErrorResponsePayload(onError);
          toast({
            render: (props) => (
              <CustomToast
                status="error"
                title={message ? message : `Something went wrong`}
                description={!message && 'Please try again later'}
                onClose={props.onClose}
              />
            ),
          });
        })
        .finally(() => {
          toast({
            render: (props) => (
              <CustomToast
                status="success"
                title="Reset password."
                description="If you have an account, you'll receive an email with a reset link. Please check your inbox."
                onClose={props.onClose}
                id={props.id}
              />
            ),
          });

          reset();
        });
      resolve();
    });
  }

  return (
    <Center pt={FORM_PADDING_TOP_XL} pb="10px">
      <CardForm>
        <form onSubmit={handleSubmit(onSubmit)}>
          <VStack spacing={5} alignItems="flex-start">
            <VStack spacing={2} alignItems="flex-start" mb={4}>
              <Heading size="2xl">Forgot password?</Heading>
              <Text textStyle="bodyRoman" color={modeText}>
                Enter the email address you used when you joined and we’ll send
                you instructions to reset your password.
              </Text>
            </VStack>
            <FormControl isRequired isInvalid={errors.email}>
              <FormLabel>Email address</FormLabel>
              <Input
                id="email"
                type="email"
                placeholder="Your email address"
                {...register('email')}
              />
              <FormErrorMessage mt={2}>
                {errors.email?.message || errors.email}
              </FormErrorMessage>
            </FormControl>
            <Box w="full">
              <Button
                type="submit"
                isLoading={isSubmitting}
                disabled={!isDirty || !isValid || isSubmitting}
                mt={2}
                alt="Send reset instructions"
              >
                Send reset instructions
              </Button>
            </Box>
          </VStack>
        </form>
      </CardForm>
    </Center>
  );
}

export default ForgotPasswordPage;

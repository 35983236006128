import {
  IconButton,
  Link,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Grid,
  GridItem,
  Box,
  Divider,
  Button,
  Text,
  useDisclosure,
  useColorModeValue,
} from '@chakra-ui/react';
import moment from 'moment';
import { useContext } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import RedirectionContext from '../../../context/RedirectionContext';
import { CustomChevronLeftIcon } from '../../../theme/icons/CustomChevronLeftIcon';

function PassesListMoreInfo({ cell, children, isAdmin }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const modeIconColor = useColorModeValue('secondaryDark08', '#DDDDDD');
  const modeBoxBg = useColorModeValue('white', 'secondaryDark');
  const modeTextBg = useColorModeValue('white', 'primaryDark06');
  const modeLabelBg = useColorModeValue('white', 'primaryDark03');
  const modeBorderColor = useColorModeValue('primaryBackground', 'primaryDark');
  const redirectCtx = useContext(RedirectionContext);
  const navigate = useNavigate();

  function handleMoreInfoClick(e) {
    e.stopPropagation();
    onOpen();
  }

  return (
    <>
      <IconButton
        onClick={handleMoreInfoClick}
        icon={
          <CustomChevronLeftIcon
            w="20px"
            h="20px"
            color={modeIconColor}
            transform="rotate(180deg)"
          />
        }
        variant="white"
        size="sm"
        w="36px"
        mr={1}
      />
      <Drawer onClose={onClose} isOpen={isOpen} size="md">
        <DrawerOverlay />
        <DrawerContent bg={modeBoxBg}>
          <DrawerCloseButton />
          <DrawerHeader
            borderBottomWidth="1px"
            borderBottomColor={modeBorderColor}
            pt={{ base: '16px', lg: '56px' }}
            pb={3}
            bg={modeTextBg}
          >
            Pass overview
          </DrawerHeader>
          <DrawerBody p="0" bg={modeLabelBg}>
            <Grid
              templateColumns={{ base: '150px 1fr', md: '192px 1fr' }}
              gap={0}
            >
              <GridItem
                fontFamily="Inter Medium, sans-serif"
                fontSize="12px"
                color={modeIconColor}
                textTransform="uppercase"
                lineHeight="21px"
                padding="16px 4px 16px 22px"
              >
                Pass template
              </GridItem>
              <GridItem
                fontSize="14px"
                padding="16px 4px 16px 22px"
                bg="quinaryBackground"
              >
                <Text wordBreak="break-word">
                  {cell.row.original.templateName}
                </Text>
              </GridItem>
              <GridItem colSpan={2} w="full">
                <Divider borderColor={modeBorderColor} />
              </GridItem>

              <GridItem
                fontFamily="Inter Medium, sans-serif"
                fontSize="12px"
                color={modeIconColor}
                textTransform="uppercase"
                lineHeight="21px"
                padding="16px 4px 16px 22px"
              >
                Issued on
              </GridItem>
              <GridItem
                fontSize="14px"
                padding="16px 4px 16px 22px"
                bg="quinaryBackground"
              >
                <Text wordBreak="break-word">{cell.row.original.issuedAt}</Text>
              </GridItem>
              <GridItem colSpan={2} w="full">
                <Divider borderColor={modeBorderColor} />
              </GridItem>

              <GridItem
                fontFamily="Inter Medium, sans-serif"
                fontSize="12px"
                color={modeIconColor}
                textTransform="uppercase"
                lineHeight="21px"
                padding="16px 4px 16px 22px"
              >
                Status
              </GridItem>
              <GridItem
                fontSize="14px"
                padding="16px 4px 16px 22px"
                bg="quinaryBackground"
              >
                <Box display="inline-block">{children}</Box>
              </GridItem>

              <GridItem colSpan={2} w="full">
                <Divider borderColor={modeBorderColor} />
              </GridItem>

              <GridItem
                fontFamily="Inter Medium, sans-serif"
                fontSize="12px"
                color={modeIconColor}
                textTransform="uppercase"
                lineHeight="21px"
                padding="16px 4px 16px 22px"
              >
                Added to wallet
              </GridItem>
              <GridItem
                fontSize="14px"
                padding="16px 4px 16px 22px"
                bg="quinaryBackground"
                wordBreak="break-word"
              >
                {cell.row.original.addedToWallet !== 'n/a'
                  ? moment(cell.row.original.addedToWallet).format(
                      'DD/MM/YYYY HH:mm A'
                    )
                  : 'n/a'}
              </GridItem>

              <GridItem colSpan={2} w="full">
                <Divider borderColor={modeBorderColor} />
              </GridItem>

              <GridItem colSpan={2} w="full">
                <Divider borderColor={modeBorderColor} />
              </GridItem>
              {isAdmin ||
              cell.row.original.status === 'deactivated' ||
              cell.row.original.status === 'expired' ? null : (
                <>
                  <GridItem colSpan={2} padding="16px 24px" ml="auto">
                    <Button
                      alt="Edit pass"
                      size="sm"
                      onClick={(e) => {
                        navigate(`/passes/edit/${cell.row.original.id}`);
                        redirectCtx.updateAddEventFromPassState(false);
                        redirectCtx.updateAddEventFromEditPassState(false);
                        redirectCtx.updatePassTemplateFromPassState(false);
                        redirectCtx.updatePassTemplateFromEditPassState(false);
                        redirectCtx.updateAddReaderFromPassState(false);
                        redirectCtx.updateAddReaderFromEditPassState(false);
                        redirectCtx.updateReaderAddedExternally(false);
                      }}
                    >
                      Edit Pass
                    </Button>
                  </GridItem>
                  <GridItem colSpan={2} w="full">
                    <Divider borderColor={modeBorderColor} />
                  </GridItem>
                </>
              )}
            </Grid>
          </DrawerBody>
          <DrawerFooter
            borderTopWidth="1px"
            borderTopColor={modeBorderColor}
            bg={modeTextBg}
          >
            <Link
              color={useColorModeValue('secondaryDark08', '#DDDDDD')}
              fontSize="14px"
              onClick={onClose}
              mr="30px"
            >
              <Text textStyle="headingFamilyMedium">Back to list</Text>
            </Link>
            <Button
              alt="Go to pass page"
              size="sm"
              px="30px"
              alignSelf="flex-start"
              as={RouterLink}
              to={cell.row.original.exampleForNestedObject.passesSinglePageUrl}
            >
              Go to pass page
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
}

export default PassesListMoreInfo;

import {
  Box,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Hide,
  Link,
  Show,
  Text,
  Tooltip,
  Collapse,
  HStack,
  Heading,
  useColorModeValue,
} from '@chakra-ui/react';
import {
  CustomReactSelect,
  formatOptionLabelSelectPassType,
} from '../../common/CustomReactSelect';
import { CustomEditIcon } from '../../../theme/icons/CustomEditIcon';
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import PassTemplateContext from '../../../store/client/PassTemplateContext';
import { NUMBER_KEYS } from '../../../utils/consts';
import { useContext } from 'react';

const WatermarkFields = (props) => {
  const {
    type,
    isEdit,
    editedFields,
    setEditedFields,
    watermarkFieldsOpen,
    setWatermarkFieldsOpen,
    watermarkFieldsDisabled,
  } = props;

  const passTemplateCtx = useContext(PassTemplateContext);
  const activeStep = passTemplateCtx.activeStep;
  const modeBorderColor = useColorModeValue('primaryBackground', 'primaryDark');
  const modeFormControlBg = useColorModeValue('white', '');
  const modeLabelBg = useColorModeValue('#EFEFEF', 'primaryDark03');
  const modeHelperText = useColorModeValue('secondaryDark08', '#DDDDDD');
  const bgTooltip = useColorModeValue('secondaryDark', '#dddddd');
  const textTooltip = useColorModeValue('#ffffff', 'primaryDark');

  const watermarkColorOptions = [
    {
      label: 'Light',
      value: 'light',
    },
    {
      label: 'Dark',
      value: 'dark',
    },
  ];

  const watermarkPositionOptions = [
    {
      label: 'Top left',
      value: 'topLeft',
    },
    {
      label: 'Top right',
      value: 'topRight',
    },
    {
      label: 'Bottom left',
      value: 'bottomLeft',
    },
    {
      label: 'Bottom right',
      value: 'bottomRight',
    },
  ];

  return (
    <>
      <Box>
        {!watermarkFieldsOpen ? (
          <>
            <FormControl isRequired={true} bg={modeFormControlBg}>
              <Flex alignItems="center">
                <Hide below="2xl">
                  <Box pl="24px" flexShrink={0} w="240px">
                    <FormLabel m={0}>PassEntry watermark</FormLabel>
                    <Text
                      color={modeHelperText}
                      textStyle="bodyFamilyRegular"
                      fontSize="12px"
                    >
                      {type === 'banner'
                        ? 'Banner image'
                        : type === 'googleBanner'
                          ? 'Google banner image'
                          : ''}
                    </Text>
                  </Box>
                </Hide>
                <Box p="14px" w="full" bg={modeLabelBg}>
                  <Show below="2xl">
                    <FormLabel>PassEntry watermark</FormLabel>
                  </Show>
                  <Flex
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    onClick={() => setWatermarkFieldsOpen(!watermarkFieldsOpen)}
                    cursor="pointer"
                    pr={3}
                  >
                    <>
                      <Box
                        as={Link}
                        onClick={() =>
                          setWatermarkFieldsOpen(!watermarkFieldsOpen)
                        }
                        cursor="pointer"
                      >
                        <HStack zIndex={1} padding="10px">
                          <>
                            <>
                              <CustomEditIcon color="brand" boxSize="14px" />
                              <Heading
                                color="secondaryDark"
                                fontSize="14px"
                                top="1px"
                                pos="relative"
                              >
                                Edit
                              </Heading>
                            </>
                          </>
                        </HStack>
                      </Box>
                      <ChevronDownIcon color="secondaryDark" boxSize="20px" />
                    </>
                  </Flex>
                </Box>
              </Flex>
            </FormControl>
            <Divider borderColor={modeBorderColor} />
          </>
        ) : (
          <FormControl isRequired={true} bg={modeFormControlBg}>
            <Flex alignItems="center">
              <Hide below="2xl">
                <Box pl="24px" flexShrink={0} w="240px">
                  <FormLabel pt="18px">PassEntry watermark</FormLabel>
                </Box>
              </Hide>
              <Box p="14px" w="full" bg={modeLabelBg}>
                <Show below="2xl">
                  <Flex
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                    onClick={() => setWatermarkFieldsOpen(!watermarkFieldsOpen)}
                    cursor="pointer"
                    pr={3}
                  >
                    <ChevronUpIcon color="gray" boxSize="20px" />
                  </Flex>
                </Show>
                <Hide below="2xl">
                  <Flex
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                    onClick={() => setWatermarkFieldsOpen(!watermarkFieldsOpen)}
                    cursor="pointer"
                    pr={3}
                  >
                    <ChevronUpIcon color="gray" boxSize="20px" />
                  </Flex>
                </Hide>
              </Box>
            </Flex>
          </FormControl>
        )}
        <Collapse in={watermarkFieldsOpen} animateOpacity>
          <FormControl
            isRequired={true}
            bg={modeFormControlBg}
            isDisabled={watermarkFieldsDisabled}
          >
            <Flex>
              <Hide below="2xl">
                <Box pl="24px" flexShrink={0} w="240px">
                  <FormLabel m={0} pt="18px">
                    Colour
                  </FormLabel>
                </Box>
              </Hide>
              <Box p="14px" w="full" bg={modeLabelBg}>
                <Show below="2xl">
                  <FormLabel>Colour</FormLabel>
                </Show>
                <Tooltip
                  hidden={!watermarkFieldsDisabled}
                  label={
                    <Text>
                      {`Upload a new ${
                        type === 'googleBanner' ? 'google banner' : 'banner'
                      } image to enable editing`}
                    </Text>
                  }
                  hasArrow
                  placement="top"
                  fontSize="12px"
                  bg={bgTooltip}
                  color={textTooltip}
                  w="205px"
                  borderRadius="6px"
                  textAlign="center"
                  p="10px"
                >
                  <Box>
                    <CustomReactSelect
                      isDisabled={watermarkFieldsDisabled}
                      options={watermarkColorOptions}
                      formatOptionLabel={formatOptionLabelSelectPassType}
                      value={
                        type === 'banner'
                          ? passTemplateCtx.watermarkColor
                          : type === 'googleBanner'
                            ? passTemplateCtx.googleBannerWatermarkColor
                            : passTemplateCtx[
                                `stamp${NUMBER_KEYS[activeStep]}WatermarkColor`
                              ]
                      }
                      variant="filledForDarkBg"
                      id="passType"
                      type="text"
                      placeholder={
                        watermarkColorOptions.find(
                          (color) =>
                            color.value ===
                            (type === 'banner'
                              ? passTemplateCtx.watermarkColor
                              : type === 'googleBanner'
                                ? passTemplateCtx.googleBannerWatermarkColor
                                : passTemplateCtx[
                                    `stamp${NUMBER_KEYS[activeStep]}WatermarkColor`
                                  ])
                        )?.label
                      }
                      onChange={(e) => {
                        type === 'banner'
                          ? passTemplateCtx.updateWatermarkColor(e.value)
                          : type === 'googleBanner'
                            ? passTemplateCtx.updateGoogleBannerWatermarkColor(
                                e.value
                              )
                            : passTemplateCtx[
                                `updateStamp${NUMBER_KEYS[activeStep]}WatermarkColor`
                              ](e.value);
                        if (isEdit) {
                          setEditedFields({
                            ...editedFields,
                            ...(type === 'banner'
                              ? { watermark_color: e.value }
                              : type === 'googleBanner'
                                ? { google_banner_watermark_color: e.value }
                                : {
                                    [`stamp_${NUMBER_KEYS[activeStep]}_watermark_color`]:
                                      e.value,
                                  }),
                          });
                        }
                      }}
                      autoComplete="off"
                      passType
                    />
                  </Box>
                </Tooltip>
              </Box>
            </Flex>
          </FormControl>
          <FormControl
            isRequired={true}
            bg={modeFormControlBg}
            isDisabled={watermarkFieldsDisabled}
          >
            <Flex>
              <Hide below="2xl">
                <Box pl="24px" flexShrink={0} w="240px">
                  <FormLabel m={0} pt="18px">
                    Position
                  </FormLabel>
                </Box>
              </Hide>
              <Box p="14px" w="full" bg={modeLabelBg}>
                <Show below="2xl">
                  <FormLabel>Position</FormLabel>
                </Show>
                <Tooltip
                  hidden={!watermarkFieldsDisabled}
                  label={
                    <Text>
                      {`Upload a new ${
                        type === 'googleBanner' ? 'google banner' : 'banner'
                      } image to enable editing`}
                    </Text>
                  }
                  hasArrow
                  placement="top"
                  fontSize="12px"
                  bg={bgTooltip}
                  color={textTooltip}
                  w="205px"
                  borderRadius="6px"
                  textAlign="center"
                  p="10px"
                >
                  <Box>
                    <CustomReactSelect
                      isDisabled={watermarkFieldsDisabled}
                      options={watermarkPositionOptions}
                      formatOptionLabel={formatOptionLabelSelectPassType}
                      value={
                        type === 'banner'
                          ? passTemplateCtx.watermarkPosition
                          : type === 'googleBanner'
                            ? passTemplateCtx.googleBannerWatermarkPosition
                            : passTemplateCtx[
                                `stamp${NUMBER_KEYS[activeStep]}WatermarkPosition`
                              ]
                      }
                      variant="filledForDarkBg"
                      id="passType"
                      type="text"
                      placeholder={
                        watermarkPositionOptions.find(
                          (position) =>
                            position.value ===
                            (type === 'banner'
                              ? passTemplateCtx.watermarkPosition
                              : type === 'googleBanner'
                                ? passTemplateCtx.googleBannerWatermarkPosition
                                : passTemplateCtx[
                                    `stamp${NUMBER_KEYS[activeStep]}WatermarkPosition`
                                  ])
                        )?.label
                      }
                      onChange={(e) => {
                        type === 'banner'
                          ? passTemplateCtx.updateWatermarkPosition(e.value)
                          : type === 'googleBanner'
                            ? passTemplateCtx.updateGoogleBannerWatermarkPosition(
                                e.value
                              )
                            : passTemplateCtx[
                                `updateStamp${NUMBER_KEYS[activeStep]}WatermarkPosition`
                              ](e.value);
                        if (isEdit) {
                          setEditedFields({
                            ...editedFields,
                            ...(type === 'banner'
                              ? { watermark_position: e.value }
                              : type === 'googleBanner'
                                ? { google_banner_watermark_position: e.value }
                                : {
                                    [`stamp_${NUMBER_KEYS[activeStep]}_watermark_position`]:
                                      e.value,
                                  }),
                          });
                        }
                      }}
                      autoComplete="off"
                      passType
                    />
                  </Box>
                </Tooltip>
              </Box>
            </Flex>
          </FormControl>
          <Divider borderColor={modeBorderColor} />
        </Collapse>
      </Box>
    </>
  );
};

export default WatermarkFields;

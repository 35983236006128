import appsignal from '../../../appsignal';
import { useContext, useRef, useState } from 'react';
import {
  Button,
  Box,
  VStack,
  List,
  ListItem,
  Text,
  Stack,
  useDisclosure,
  useColorModeValue,
  useOutsideClick,
  Spinner,
  Center,
  useToast,
  HStack,
} from '@chakra-ui/react';
import { CustomSquareDownloadIcon } from '../../../theme/icons/CustomSquareDownloadIcon';
import CustomToast from '../../../common/CustomToast';
import { CustomCsvDownloadIcon } from '../../../theme/icons/CustomCsvDownloadIcon';
import { CustomPdfDownloadIcon } from '../../../theme/icons/CustomPdfDownloadIcon';
import { getErrorResponsePayload } from '../../../utils/ajax';
import LiveEventDataListContext from '../../../store/client/LiveEventDataListContext';
import { HttpContext } from '../../../context/HttpContext';
import { MeContext } from '../../../context/MeContext';

const LiveEventDataListDownload = () => {
  const { authAxios } = useContext(HttpContext);
  const { filterValues } = useContext(LiveEventDataListContext);
  const [isDownloading, setIsDownloading] = useState(false);
  const meCtx = useContext(MeContext);
  const { filterEntity } = meCtx.state;
  const modePickerPopupBg = useColorModeValue('white', 'primaryDark');
  const toast = useToast();
  const ref = useRef();
  useOutsideClick({
    ref: ref,
    handler: () => {
      onDownloadMenuClose();
    },
  });
  const {
    isOpen: isDownloadMenuOpen,
    onOpen: onDownloadMenuOpen,
    onClose: onDownloadMenuClose,
  } = useDisclosure();

  const generatePassTapsListData = async (fileType) => {
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const filterReader = filterValues?.reader
      ? `&reader=${filterValues.reader}`
      : '';
    const filterEvent = filterValues?.event
      ? `&event=${filterValues.event}`
      : '';
    const filterString = `${filterReader}${filterEvent}&timezone=${encodeURIComponent(
      userTimeZone
    )}`;

    if (fileType === 'pdf') {
      await authAxios.get(
        filterEntity
          ? `api/v1/entities/${filterEntity.uuid}/taps?export=true&export_type=pdf${filterString}&email=${meCtx.state.account.email}`
          : `taps?export=true&export_type=pdf${filterString}&email=${meCtx.state.account.email}`
      );
    } else {
      await authAxios.get(
        filterEntity
          ? `api/v1/entities/${filterEntity.uuid}/taps?export=true&export_type=csv${filterString}&email=${meCtx.state.account.email}`
          : `taps?export=true&export_type=csv${filterString}&email=${meCtx.state.account.email}`
      );
    }

    try {
      toast({
        render: ({ onClose }) => (
          <CustomToast
            status="info"
            title="Please check your email"
            description={`A download link has been sent to ${meCtx.state.account.email}. It may take a few minutes to arrive.`}
            onClose={onClose}
          />
        ),
      });
    } catch (error) {
      appsignal.sendError(error);
      console.log(error);
      setIsDownloading(false);
      const { message, code } = getErrorResponsePayload(error);
      code !== 401 &&
        toast({
          render: (props) => (
            <CustomToast
              status="error"
              title={message ? message : `Something went wrong`}
              description={!message && 'Please try again later'}
              onClose={props.onClose}
            />
          ),
        });
    }
  };

  return (
    <Stack
      direction={{ base: 'column', sm: 'row' }}
      position="relative"
      ref={ref}
    >
      <Button
        size="sm"
        px="30px"
        width={{ base: 'full', sm: '198px' }}
        onClick={() => {
          isDownloadMenuOpen ? onDownloadMenuClose() : onDownloadMenuOpen();
        }}
        isLoading={isDownloading}
      >
        {isDownloading ? (
          <Center as="span" zIndex={1}>
            <Spinner
              width="15px"
              height="15px"
              thickness="2px"
              speed="0.65s"
              emptyColor="quinaryBackground"
              mx="auto"
            />
          </Center>
        ) : (
          <Box as="span" zIndex={1}>
            <CustomSquareDownloadIcon
              h="20px"
              w="auto"
              mr="10px"
              fill="white"
              mb="5px"
            />
            Download list
          </Box>
        )}
      </Button>
      {isDownloadMenuOpen && (
        <VStack
          position="absolute"
          top="100%"
          m="0 !important"
          borderRadius="9px"
          bg={modePickerPopupBg}
          boxShadow="0 6px 12px rgba(0, 0, 0, 0.15)"
          width={{ base: 'full', sm: '198px' }}
          zIndex={10}
        >
          <List minWidth="full" textAlign="center">
            <ListItem
              fontSize="14px"
              textStyle="bodyFamilyMedium"
              p={2}
              borderTopLeftRadius="9px"
              borderTopRightRadius="9px"
              _hover={{ bg: 'quinaryBackground' }}
              cursor="pointer"
              onClick={() => {
                generatePassTapsListData('csv');
                onDownloadMenuClose();
              }}
            >
              <HStack alignItems="center" justifyContent="center">
                <CustomCsvDownloadIcon boxSize="20px" />
                <Text pt="4px">Download CSV</Text>
              </HStack>
            </ListItem>
            <ListItem
              fontSize="14px"
              borderBottomLeftRadius="9px"
              borderBottomRightRadius="9px"
              textStyle="bodyFamilyMedium"
              p={2}
              _hover={{ bg: 'quinaryBackground' }}
              cursor="pointer"
              onClick={() => {
                generatePassTapsListData('pdf');
                onDownloadMenuClose();
              }}
            >
              <HStack alignItems="center" justifyContent="center">
                <CustomPdfDownloadIcon boxSize="20px" />
                <Text pt="4px">Download PDF</Text>
              </HStack>
            </ListItem>
          </List>
        </VStack>
      )}
    </Stack>
  );
};

export default LiveEventDataListDownload;

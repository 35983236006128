import appsignal from '../../../appsignal';
import {
  Box,
  Button,
  Flex,
  Spacer,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  Text,
  useColorModeValue,
  Divider,
  Hide,
  Show,
  useColorMode,
  useToast,
  Select as ChakraSelect,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import EventsContext from '../../../store/client/EventsContext';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import moment from 'moment';
import CustomToast from '../../../common/CustomToast';
import { Link as RouterLink } from 'react-router-dom';
import { getErrorResponsePayload, getErrors } from '../../../utils/ajax';
import { timezoneList } from '../../../utils/timezones';
import CustomTimePicker from '../../common/CustomTimePicker/CustomTimePicker';
import CustomDatePicker from '../../common/CustomDatePicker/CustomDatePicker';
import RedirectionContext from '../../../context/RedirectionContext';
import { HttpContext } from '../../../context/HttpContext';
import { MeContext } from '../../../context/MeContext';

const EventsInfoCreateForm = ({ isEdit }) => {
  const toast = useToast();
  const navigate = useNavigate();
  const { uuid } = useParams();
  const eventsCtx = useContext(EventsContext);
  const { authAxios } = useContext(HttpContext);
  const redirectCtx = useContext(RedirectionContext);
  const timezones = useMemo(timezoneList, []);
  const [startDate, setStartDate] = useState(null);
  const [startTime, setStartTime] = useState('');
  const [endDate, setEndDate] = useState(null);
  const [endTime, setEndTime] = useState('');
  const { colorMode } = useColorMode();
  const [primaryIsValid, setPrimaryIsValid] = useState(false);
  const modeBoxBg = useColorModeValue('gradient', 'secondaryDark');
  const modeTextBg = useColorModeValue('quaternaryBackground', 'primaryDark06');
  const modeBorderColor = useColorModeValue('primaryBackground', 'primaryDark');
  const modeFormControlBg = useColorModeValue('white', '');
  const modeLabelBg = useColorModeValue('#EFEFEF', 'primaryDark03');
  const meCtx = useContext(MeContext);
  const { filterEntity, entities } = meCtx.state;
  const entityList = entities.map((entity) => {
    return { label: `${entity.name}`, value: entity.uuid };
  });
  const entity = entityList[0].value;

  const debounceTimeout = useRef(null);

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required()
      .test('is-unique', 'Name must be unique', async function (value) {
        return await isNameUnique(value);
      }),
    venueName: Yup.string().required(),
    startDate: Yup.string().required(),
    startTime: Yup.string().required(),
    endDate: Yup.string().required(),
    endTime: Yup.string().required(),
    timezone: Yup.string(),
  });

  const formOptions = {
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
  };

  const isNameUnique = async (value) => {
    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }
    return new Promise((resolve) => {
      debounceTimeout.current = setTimeout(async () => {
        try {
          if (isEdit && value === eventsCtx.eventState.name) {
            resolve(true);
          } else {
            const response = await authAxios.get(`api/v1/events`);
            const events = response.data.data;
            const eventNames = events.map((event) => event.attributes.name);
            resolve(!eventNames.includes(value));
          }
        } catch (error) {
          appsignal.sendError(error);
          const { message, code } = getErrorResponsePayload(error);
          code !== 401 &&
            toast({
              render: (props) => (
                <CustomToast
                  status="error"
                  title={message ? message : `Something went wrong`}
                  description={!message && 'Please try again later'}
                  onClose={props.onClose}
                />
              ),
            });
          resolve(false);
        }
      }, 500);
    });
  };

  const {
    handleSubmit,
    register,
    setValue,
    watch,
    formState: { errors, isSubmitting },
  } = useForm(formOptions);

  // overall form custom validation booleans
  const datesIsValid =
    watch('startDate') !== null &&
    watch('startTime') !== null &&
    watch('endDate') !== null &&
    watch('endTime') !== null;

  const requiredTextInputsIsValid =
    watch('name') !== '' && watch('venueName') !== '';

  useEffect(() => {
    if (requiredTextInputsIsValid && datesIsValid) {
      setPrimaryIsValid(true);
    } else {
      setPrimaryIsValid(false);
    }
  }, [requiredTextInputsIsValid, datesIsValid]);

  // if edit mode is true pre-populate all the fields
  useEffect(() => {
    if (isEdit) {
      // check in edit mode if add new pass template or add new reader were clicked
      if (
        redirectCtx.addReaderFromEditEventState ||
        redirectCtx.addTemplateFromEditEventState
      ) {
        setValue('name', redirectCtx.tempEventState.name);
        setValue('venueName', redirectCtx.tempEventState.venueName);
        setValue('startDate', redirectCtx.tempEventState.startDate);
        setStartDate(redirectCtx.tempEventState.startDate);
        setValue('startTime', redirectCtx.tempEventState.startTime);
        setStartTime(redirectCtx.tempEventState.startTime);
        setValue('endDate', redirectCtx.tempEventState.endDate);
        setEndDate(redirectCtx.tempEventState.endDate);
        setValue('endTime', redirectCtx.tempEventState.endTime);
        setEndTime(redirectCtx.tempEventState.endTime);
        setValue('timezone', redirectCtx.tempEventState.timezone);
      } else {
        setValue('name', eventsCtx.eventState.name);
        setValue('venueName', eventsCtx.eventState.venueName);
        setValue('timezone', eventsCtx.eventState.timeZone);
        setValue('startDate', eventsCtx.eventState.startDate);
        setValue('startTime', eventsCtx.eventState.startTime);
        setStartTime(eventsCtx.eventState.startTime);
        setValue('endDate', eventsCtx.eventState.endDate);
        setEndTime(eventsCtx.eventState.endTime);
        setValue('endTime', eventsCtx.eventState.endTime);
      }
    }
  }, [eventsCtx.eventState, eventsCtx.firstLoad, setValue]);

  // in create mode pre-populate fields with temporary stored values if returned from create new reader or create new pass template
  useEffect(() => {
    if (
      redirectCtx.addReaderFromEventState ||
      redirectCtx.addTemplateFromEventState
    ) {
      setValue('name', redirectCtx.tempEventState.name);
      setValue('venueName', redirectCtx.tempEventState.venueName);
      setValue('startDate', redirectCtx.tempEventState.startDate);
      setStartDate(redirectCtx.tempEventState.startDate);
      setValue('startTime', redirectCtx.tempEventState.startTime);
      setStartTime(redirectCtx.tempEventState.startTime);
      setValue('endDate', redirectCtx.tempEventState.endDate);
      setEndDate(redirectCtx.tempEventState.endDate);
      setValue('endTime', redirectCtx.tempEventState.endTime);
      setEndTime(redirectCtx.tempEventState.endTime);
      setValue('timezone', redirectCtx.tempEventState.timezone);
      // handle the scenario if template was not made externally and cancel was clicked on pass templates page
      // before setting the multi readers display remove the newly added reader because it is automatically pending activation and should be first activated from the admin view
    }
  }, [
    redirectCtx.addReaderFromEventState,
    redirectCtx.addTemplateFromEventState,
    redirectCtx.readerAddedExternally,
    redirectCtx.readersArray,
    redirectCtx.savedTemplate,
    redirectCtx.tempEventState,
    redirectCtx.templateAddedExternally,
    setValue,
  ]);

  // startDate
  useEffect(() => {
    setValue('startDate', startDate);
  }, [setValue, startDate]);

  // start time
  useEffect(() => {
    setValue('startTime', startTime);
  }, [setValue, startTime]);

  // endDate
  useEffect(() => {
    setValue('endDate', endDate);
  }, [endDate, setValue]);

  // end time
  useEffect(() => {
    setValue('endTime', endTime);
  }, [endTime, setValue]);

  // prepare data
  const prepareDataForSubmit = (data) => {
    const startDate = moment(data.startDate).format('YYYY-MM-DD');
    const startTime = moment(data.startTime, ['h:mm A']).format('HH:mm:ss');
    let startDateTime = moment(startDate + startTime, 'YYYY-MM-DD HH:mm:ss')
      .tz(data.timezone)
      .format('YYYY-MM-DD HH:mm:ss');

    let isoStartDateTime = moment(startDateTime).toISOString();

    const endDate = moment(data.endDate).format('YYYY-MM-DD');
    const endTime = moment(data.endTime, ['h:mm A']).format('HH:mm:ss');
    const endDateTime = moment(endDate + endTime, 'YYYY-MM-DD HH:mm:ss')
      .tz(data.timezone)
      .format('YYYY-MM-DD HH:mm:ss');

    let isoEndDateTime = moment(endDateTime).toISOString();

    let submittedData = {
      event: {
        name: data.name,
        venue_name: data.venueName,
        start_datetime: isoStartDateTime,
        end_datetime: isoEndDateTime,
        time_zone: data.timezone,
      },
    };
    return submittedData;
  };

  const onSubmit = async (values) => {
    async function handleEdit() {
      let submittedData = prepareDataForSubmit(values);
      try {
        const response = await authAxios.patch(
          `/api/v1/events/${uuid}`,
          submittedData
        );

        const data = response?.data?.data;
        eventsCtx.updateAllEventData(data);

        if (redirectCtx.addEventFromEditPassState) {
          redirectCtx.updateSavedEvent(data);
          redirectCtx.updateEventAddedExternally(true);
          navigate(`/passes/edit/${redirectCtx.passParamUrl}`);
        } else {
          navigate('/events', { replace: true });
        }
        toast({
          render: ({ onClose }) => (
            <CustomToast
              status="success"
              title="Event has been edited"
              description=""
              onClose={onClose}
            />
          ),
        });
      } catch (error) {
        appsignal.sendError(error);
        const errors = getErrors(error);
        toast({
          render: ({ onClose }) => (
            <CustomToast
              status="error"
              title={errors[0]}
              description=""
              onClose={onClose}
            />
          ),
        });
      }
    }

    let submittedData = prepareDataForSubmit(values);

    let currentDateTime = moment().format('YYYY-MM-DD HH:mm');
    let endTime = moment(submittedData.endTime).format('YYYY-MM-DD HH:mm');
    if (redirectCtx.addEventFromPassState) {
      if (moment(endTime).isBefore(currentDateTime)) {
        toast({
          render: ({ onClose }) => (
            <CustomToast
              status="error"
              title="Passes can not be issued for past events."
              description=""
              onClose={onClose}
            />
          ),
        });
        return;
      }
    }

    try {
      if (isEdit) {
        await handleEdit();
        return;
      }

      if (filterEntity) {
        await authAxios.post(
          `/api/v1/entities/${filterEntity.uuid}/events/`,
          submittedData
        );
      } else {
        await authAxios.post(
          `/api/v1/entities/${entity}/events/`,
          submittedData
        );
      }

      navigate(`/events`, {
        replace: true,
        state: { save: true },
      });
      toast({
        render: ({ onClose }) => (
          <CustomToast
            status="success"
            title="Event has been created"
            description=""
            onClose={onClose}
          />
        ),
      });
    } catch (onError) {
      appsignal.sendError(onError);
      const errors = getErrors(onError);
      toast({
        render: ({ onClose }) => (
          <CustomToast
            status="error"
            title={errors[0]}
            description=""
            onClose={onClose}
          />
        ),
      });
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Flex alignItems="center" flexWrap="wrap" minH="54px">
        <Flex w="full">
          {/* Primary fields */}
          <Box className="autofillForDarkBg" w="full" borderRadius="15px">
            <Box
              className="primaryBoxShadow"
              bg={modeBoxBg}
              borderRadius="15px"
            >
              <Text
                fontSize="18px"
                textStyle="headingFamilyMedium"
                p="14px 14px 14px 24px"
                bg={modeTextBg}
                borderRadius="15px 15px 0 0"
              >
                Primary fields
              </Text>
              <Divider borderColor={modeBorderColor} />

              {/* event name */}
              <FormControl
                isRequired
                isInvalid={errors.name}
                bg={modeFormControlBg}
              >
                <Flex alignItems="center">
                  <Hide below="2xl">
                    <Box pl="24px" flexShrink={0} w="240px">
                      <FormLabel m={0}>Event name</FormLabel>
                    </Box>
                  </Hide>
                  <Box p="14px" w="full" bg={modeLabelBg}>
                    <Show below="2xl">
                      <FormLabel>Event name</FormLabel>
                    </Show>
                    <Input
                      variant="filledForDarkBg"
                      id="name"
                      type="text"
                      placeholder="Type event name here..."
                      {...register('name', { required: true })}
                      autoComplete="off"
                      onBlur={() => {
                        const value = watch('name');
                        if (value.trim() === '') {
                          setValue('name', '');
                        }
                      }}
                    />
                    <FormErrorMessage pt={2} pl={3}>
                      {errors.name?.message || errors.name}
                    </FormErrorMessage>
                  </Box>
                </Flex>
              </FormControl>
              <Divider borderColor={modeBorderColor} />

              {/* venueName */}
              <FormControl
                isRequired
                isInvalid={errors.venueName}
                bg={modeFormControlBg}
              >
                <Flex alignItems="center">
                  <Hide below="2xl">
                    <Box pl="24px" flexShrink={0} w="240px">
                      <FormLabel m={0}>Venue name</FormLabel>
                    </Box>
                  </Hide>
                  <Box p="14px" w="full" bg={modeLabelBg}>
                    <Show below="2xl">
                      <FormLabel>Venue name</FormLabel>
                    </Show>
                    <Input
                      variant="filledForDarkBg"
                      id="venueName"
                      type="text"
                      placeholder="Type venue name here..."
                      {...register('venueName')}
                      autoComplete="off"
                      onBlur={() => {
                        const value = watch('venueName');
                        if (value.trim() === '') {
                          setValue('venueName', '');
                        }
                      }}
                    />
                  </Box>
                </Flex>
              </FormControl>
              <Divider borderColor={modeBorderColor} />

              {/* start date/time */}
              <Flex
                flexDir={{ base: 'column', xl: 'row' }}
                alignItems="center"
                w="full"
                bg={modeFormControlBg}
                borderBottomLeftRadius="15px"
              >
                <Hide below="2xl">
                  <Box pl="24px" flexShrink={0} w="240px">
                    <FormControl isRequired>
                      <FormLabel m={0}>Event start date and time</FormLabel>
                    </FormControl>
                  </Box>
                </Hide>
                <Box w="full">
                  <FormControl
                    isRequired
                    isInvalid={errors.startDate}
                    bg={modeFormControlBg}
                  >
                    <Box p="14px" w="full" bg={modeLabelBg}>
                      <Show below="2xl">
                        <FormLabel>Event start date</FormLabel>
                      </Show>
                      <Box
                        w={{ base: '100%', sm: '50%', md: '50%', xl: 'full' }}
                      >
                        {/* start date picker */}
                        <Input
                          id="startDate"
                          name="startDate"
                          type="hidden"
                          {...register('startDate')}
                        />
                        {colorMode === 'dark' ? (
                          <Box
                            as={CustomDatePicker}
                            placementTop
                            setDate={setStartDate}
                            darkMode
                            isEdit={isEdit}
                            startDate
                            minBookingDate={new Date()}
                          />
                        ) : (
                          <Box
                            as={CustomDatePicker}
                            placementTop
                            setDate={setStartDate}
                            isEdit={isEdit}
                            startDate
                            minBookingDate={new Date()}
                          />
                        )}
                      </Box>
                    </Box>
                  </FormControl>
                </Box>

                <Spacer backgroundColor={modeLabelBg} />

                <Show breakpoint="(max-width: 1279px)">
                  <Divider borderColor={modeBorderColor} />
                </Show>

                <Box w="full">
                  <FormControl
                    isRequired
                    isInvalid={errors.startTime}
                    bg={modeFormControlBg}
                  >
                    <Box p="14px" w="full" bg={modeLabelBg}>
                      <Show below="2xl">
                        <FormLabel>Event start time</FormLabel>
                      </Show>
                      <Box
                        w={{ base: '100%', sm: '50%', md: '50%', xl: 'full' }}
                      >
                        {/* start time picker */}
                        <Input
                          id="startTime"
                          name="startTime"
                          type="hidden"
                          {...register('startTime')}
                        />
                        {colorMode === 'dark' ? (
                          <CustomTimePicker
                            timeValue={startTime}
                            setTimeValue={setStartTime}
                            placementTop
                            darkMode
                          />
                        ) : (
                          <CustomTimePicker
                            timeValue={startTime}
                            setTimeValue={setStartTime}
                            placementTop
                          />
                        )}
                      </Box>
                    </Box>
                  </FormControl>
                </Box>
              </Flex>
              <Divider borderColor={modeBorderColor} />

              {/* end Date/time */}
              <Flex
                flexDir={{ base: 'column', xl: 'row' }}
                alignItems="center"
                w="full"
                bg={modeFormControlBg}
                borderBottomLeftRadius="15px"
                borderBottomRightRadius="15px"
              >
                <Hide below="2xl">
                  <Box pl="24px" flexShrink={0} w="240px">
                    <FormControl isRequired>
                      <FormLabel m={0}>Event end date and time</FormLabel>
                    </FormControl>
                  </Box>
                </Hide>
                <Box w="full">
                  <FormControl
                    isRequired
                    isInvalid={errors.endDate}
                    bg={modeFormControlBg}
                    borderBottomLeftRadius="15px"
                  >
                    <Box p="14px" w="full" bg={modeLabelBg}>
                      <Show below="2xl">
                        <FormLabel>Event end date</FormLabel>
                      </Show>
                      <Box
                        w={{ base: '100%', sm: '50%', md: '50%', xl: 'full' }}
                      >
                        {/* end date picker */}
                        <Input
                          id="endDate"
                          name="endDate"
                          type="hidden"
                          {...register('endDate')}
                        />

                        {colorMode === 'dark' ? (
                          <Box
                            as={CustomDatePicker}
                            placementTop
                            setDate={setEndDate}
                            darkMode
                            isEdit={isEdit}
                            endDate
                            minBookingDate={startDate || new Date()}
                          />
                        ) : (
                          <Box
                            as={CustomDatePicker}
                            placementTop
                            setDate={setEndDate}
                            isEdit={isEdit}
                            endDate
                            minBookingDate={startDate || new Date()}
                          />
                        )}
                      </Box>
                    </Box>
                  </FormControl>
                </Box>
                <Spacer backgroundColor={modeLabelBg} />

                <Show breakpoint="(max-width: 1279px)">
                  <Divider borderColor={modeBorderColor} />
                </Show>

                <Box w="full">
                  <FormControl
                    isRequired
                    isInvalid={errors.endTime}
                    bg={modeFormControlBg}
                  >
                    <Box p="14px" w="full" bg={modeLabelBg}>
                      <Show below="2xl">
                        <FormLabel>Event end time</FormLabel>
                      </Show>
                      <Box
                        w={{ base: '100%', sm: '50%', md: '50%', xl: 'full' }}
                      >
                        {/* end time picker */}
                        <Input
                          id="endTime"
                          name="endTime"
                          type="hidden"
                          {...register('endTime')}
                        />
                        {colorMode === 'dark' ? (
                          <CustomTimePicker
                            timeValue={endTime}
                            setTimeValue={setEndTime}
                            placementTop
                            darkMode
                          />
                        ) : (
                          <CustomTimePicker
                            timeValue={endTime}
                            setTimeValue={setEndTime}
                            placementTop
                          />
                        )}
                      </Box>
                    </Box>
                  </FormControl>
                </Box>
              </Flex>
              <Divider borderColor={modeBorderColor} />
              <FormControl
                isInvalid={errors.timezone}
                bg={modeFormControlBg}
                borderBottomLeftRadius="15px"
                borderBottomRightRadius="15px"
              >
                <Flex alignItems="center">
                  <Hide below="2xl">
                    <Box pl="24px" flexShrink={0} w="240px">
                      <FormLabel m={0}>Time zone</FormLabel>
                    </Box>
                  </Hide>
                  <Box
                    p="14px"
                    w="full"
                    bg={modeLabelBg}
                    borderBottomRightRadius="15px"
                    borderBottomLeftRadius={{ base: '15px', '2xl': '0px' }}
                  >
                    <Show below="2xl">
                      <FormLabel>Time zone</FormLabel>
                    </Show>
                    <ChakraSelect
                      {...register('timezone')}
                      variant="filledForDarkBg"
                    >
                      {timezones.map((timezone, index) => {
                        return (
                          <option key={index} value={timezone.name}>
                            {timezone.key}
                          </option>
                        );
                      })}
                    </ChakraSelect>
                    <FormErrorMessage pt={2} pl={3}>
                      {errors.timezone?.message || errors.timezone}
                    </FormErrorMessage>
                  </Box>
                </Flex>
              </FormControl>
            </Box>
          </Box>
        </Flex>

        <Box mt={{ base: '30px' }} w="full">
          <Divider borderColor="primaryBackground" mb="15px" />

          <Box display="flex">
            <Button
              type="submit"
              isLoading={isSubmitting}
              isDisabled={entityList.length > 1 && !filterEntity && !isEdit}
              // disabled={
              //   ((!primaryIsValid || isSubmitting) && !isEdit) || (isEdit && isSubmitting)
              // }
              alt="Save"
              size="sm"
              mr="24px"
              mb={{ base: '10px', sm: '0px' }}
            >
              Save
            </Button>
            <Button
              size="sm"
              variant="secondary"
              as={RouterLink}
              to={{
                pathname: redirectCtx.addEventFromPassState
                  ? '/passes/create'
                  : redirectCtx.addEventFromEditPassState
                    ? `/passes/edit/${redirectCtx.passParamUrl}`
                    : '/events',
              }}
              type="button"
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </Flex>
    </form>
  );
};
export default EventsInfoCreateForm;

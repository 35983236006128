import { useContext } from 'react';
import { MeContext } from '../context/MeContext';
import Prompt from '../common/Prompt';
import EmptyState from '../common/EmptyState';
import { Text, Link } from '@chakra-ui/react';

const useOrganizationStatus = (iconSvgAnimated = null) => {
  const meCtx = useContext(MeContext);
  const { organisationStatus } = meCtx.state;
  const isSetUpConfirmed = meCtx?.isSetUpConfirmed() ?? true;
  const isOrganisationActive = meCtx?.isOrganisationActive() ?? true;
  let components = [];

  // Handling specific scenarios
  if (!isSetUpConfirmed) {
    components.push(
      <Prompt
      key="setup-progress"
      title="Your account setup is in progress"
      description={
        <Text fontSize="14px">
          If you would like an update please reach out to {' '}
          <Link href="mailto:sales@passentry.com" color="">
            sales@passentry.com
          </Link>.
        </Text>
      }
      />
    );
  }

  if (isSetUpConfirmed && organisationStatus === "trial_expired") {
    components.push(
      <Prompt
      key="trial-expired"
      title="Your trial period has expired"
      description={
        <Text fontSize="14px">
          Please reach out to {' '}
          <Link href="mailto:sales@passentry.com" color="">
            sales@passentry.com
          </Link>{' '}
          to continue using our services.
        </Text>
      }
      />
    );
  }

  if (isSetUpConfirmed && organisationStatus === "frozen") {
    components.push(
      <Prompt
      key="account-frozen"
      title="Your account is currently frozen."
      description={
        <Text fontSize="14px">
          Please reach out to {' '}
          <Link href="mailto:sales@passentry.com" color="">
            sales@passentry.com
          </Link>{' '}
          to continue using our services.
        </Text>
      }
      />
    );
  }

  if (!isSetUpConfirmed || !isOrganisationActive) {
    components.push(
      <EmptyState
        key="empty-state"
        iconSvgAnimated={iconSvgAnimated ? iconSvgAnimated : null}
        heading="No data available."
        text="You will be able to see it here once your account is fully set up."
      />
    );
  }

  return {
    shouldRender: components.length === 0 || (components.length === 1 && organisationStatus === "trial"),
    components  // Return the list of components to render
  };
};

export default useOrganizationStatus;

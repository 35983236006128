import React from 'react';
import { Button, Flex, Heading, Spacer } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import useOrganizationStatus from '../../../hooks/useOrganisationStatus';

function PassesBatchesHeader() {
  const { shouldRender } = useOrganizationStatus();

  return (
    <Flex
      flexDirection={{ sm: 'row' }}
      alignItems="center"
      flexWrap="wrap"
      mt="15px"
      pt="9px"
      minH="54px"
    >
      <Heading alignSelf={{ base: 'flex-start', sm: 'center' }}>
        Pass Creation History
      </Heading>
      <Spacer />
      <Flex
        mb="10px"
        gap={{ base: '10px', sm: '0px' }}
        alignItems="flex-end"
        justifyContent="flex-start"
        flexWrap="wrap"
      >
        {shouldRender && (
          <Button
            mr={{ base: '0px', sm: '10px' }}
            px={{ base: '20px', sm: '40px' }}
            alt="Create a pass"
            size="sm"
            as={RouterLink}
            to="/passes/batches/create"
          >
            New batch
          </Button>
        )}
      </Flex>
    </Flex>
  );
}

export default PassesBatchesHeader;

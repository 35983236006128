import { Box, Text, Flex, VStack, Heading, Center } from '@chakra-ui/react';
// import { CustomInfoIcon } from '../theme/icons/CustomInfoIcon';

const Prompt = (props) => {
  return (
    <Center>
      <Box
        bg="linear-gradient(81.62deg, #FF8477 2.25%, #EC6759 79.87%)"
        w={{ base: '100%', md: '784px' }}
        maxW="100%"
        p="11px 18px"
        borderRadius="15px"
        color="white"
        mb={6}
        alignItems="center"
      >
        <Flex justifyContent="center">
          {/* <CustomInfoIcon mr="14px" mt="6px" boxSize="20px" /> */}
          <VStack spacing="2px" alignItems="center">
            <Heading>{props.title}</Heading>
            <Text textStyle="bodyFamilyMedium">{props.description}</Text>
          </VStack>
        </Flex>
      </Box>
    </Center>
  );
};

export default Prompt;

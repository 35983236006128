import Appsignal from '@appsignal/javascript';
import runtimeEnv from '@mars/heroku-js-runtime-env';

const env = runtimeEnv();
const appsignal = new Appsignal({
  key:
    env.REACT_APP_APPSIGNAL_API_KEY || window.env?.REACT_APP_APPSIGNAL_API_KEY,
});

export default appsignal;

import {
  Flex,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
} from '@chakra-ui/react';

const SliderInput = (props) => {
  const { radius, setRadius, disabled, max } = props;
  const handleChange = (value) => setRadius(value);

  return (
    <Flex>
      <NumberInput
        maxW="100px"
        mr="2rem"
        min={10}
        max={max}
        value={radius}
        defaultValue={10}
        onChange={handleChange}
        step={5}
      >
        <NumberInputField />
        <NumberInputStepper>
          <NumberIncrementStepper />
          <NumberDecrementStepper />
        </NumberInputStepper>
      </NumberInput>
      <Slider
        mr={3}
        flex="1"
        focusThumbOnChange={false}
        value={radius}
        onChange={handleChange}
        min={10}
        max={max}
        step={5}
        isDisabled={disabled}
      >
        <SliderTrack>
          <SliderFilledTrack />
        </SliderTrack>
        <SliderThumb color="white" fontSize="10px" boxSize="32px" children={radius} />
      </Slider>
    </Flex>
  );
};

export default SliderInput;

import { createContext, useState } from 'react';

const RedirectionContext = createContext({
  // generic pass info
  tempPassState: {},
  passParamUrl: '',
  coordinates: {},
  resetAllTempPassData: () => {},
  updateTempPassState: () => {},
  updatePassParamUrl: () => {},
  updateCoordinates: () => {},
  // pass-template relation info
  passTemplateFromPassState: null,
  passTemplateFromEditPassState: null,
  savedPassTemplate: {},
  updatePassTemplateFromPassState: () => {},
  updatePassTemplateFromEditPassState: () => {},
  updateSavedPassTemplate: () => {},
  // event reader relation info
  readersArray: [],
  addReaderFromPassState: null,
  addReaderFromEditPassState: null,
  readerAddedExternally: null,
  updateReadersArray: () => {},
  updateAddReaderFromPassState: () => {},
  updateAddReaderFromEditPassState: () => {},
  updateReaderAddedExternally: () => {},
  // pass event relation info
  tempEventState: {},
  addEventFromPassState: null,
  addEventFromEditPassState: null,
  savedEvent: {},
  eventAddedExternally: null,
  updateEventAddedExternally: () => {},
  updateAddEventFromPassState: () => {},
  updateAddEventFromEditPassState: () => {},
  updateTempEventState: () => {},
  updateSavedEvent: () => {},
  updateSavedMessages: () => {},
});

export const RedirectionContextProvider = ({ children }) => {
  // generic pass info and functions
  const [passParamUrl, setPassParamUrl] = useState('');
  const [tempPassState, setTempPassState] = useState({});
  const [coordinates, setCoordinates] = useState({});

  const updatePassParamUrlHandler = (param) => {
    setPassParamUrl(param);
  };

  const updateTempPassStateHandler = (param) => {
    setTempPassState(param);
  };

  const updateCoordinatesHandler = (param) => {
    setCoordinates(param);
  };

  const resetAllTempEventDataHandler = () => {};

  // event pass-template relation state info
  const [passTemplateFromPassState, setPassTemplateFromPassState] = useState(null);
  const [passTemplateFromEditPassState, setPassTemplateFromEditPassState] =
    useState(null);
  const [savedPassTemplate, setSavedPassTemplate] = useState({});

  // event pass-template relation function info
  const updatePassTemplateFromPassStateHandler = (param) => {
    setPassTemplateFromPassState(param);
  };

  const updatePassTemplateFromEditPassStateHandler = (param) => {
    setPassTemplateFromEditPassState(param);
  };

  const updateSavedPassTemplateHandler = (param) => {
    setSavedPassTemplate(param);
  };

  // event reader relation state info
  const [addReaderFromPassState, setAddReaderFromPassState] = useState(null);
  const [addReaderFromEditPassState, setAddReaderFromEditPassState] = useState(null);
  const [readersArray, setReadersArray] = useState([]);
  const [readerAddedExternally, setReaderAddedExternally] = useState(null);

  //  event reader relation functions info
  const updateAddReaderFromPassStateHandler = (param) => {
    setAddReaderFromPassState(param);
  };

  const updateAddReaderFromEditPassStateHandler = (param) => {
    setAddReaderFromEditPassState(param);
  };

  const updateReadersArrayHandler = (param) => {
    setReadersArray(param);
  };

  const updateReaderAddedExternallyHandler = (param) => {
    setReaderAddedExternally(param);
  };

  // pass event relation state info
  const [eventParamUrl, setEventParamUrl] = useState('');
  const [tempEventState, setTempEventState] = useState({});
  const [addEventFromPassState, setAddEventFromPassState] = useState(null);
  const [addEventFromEditPassState, setAddEventFromEditPassState] = useState(null);
  const [savedEvent, setSavedEvent] = useState({});
  const [eventAddedExternally, setEventAddedExternally] = useState(null);

  //   pass event relation function info
  const updateEventParamUrlHandler = (param) => {
    setEventParamUrl(param);
  };

  const updateTempEventStateHandler = (param) => {
    setTempEventState(param);
  };

  const updateAddEventFromPassStateHandler = (param) => {
    setAddEventFromPassState(param);
  };

  const updateAddEventFromEditPassStateHandler = (param) => {
    setAddEventFromEditPassState(param);
  };

  const updateSavedEventHandler = (param) => {
    setSavedEvent(param);
  };

  const updateSavedMessagesHandler = (param) => {
    const {
      message1,
      message1Title,
      message1Text,
      message2,
      message2Title,
      message2Text,
    } = param;
    setSavedEvent({
      ...savedEvent,
      message1,
      message1Text,
      message1Title,
      message2,
      message2Text,
      message2Title,
    });
  };

  const updateEventAddedExternallyHandler = (param) => {
    setEventAddedExternally(param);
  };

  const context = {
    // generic event info
    tempPassState,
    passParamUrl,
    coordinates,
    resetAllTempEventData: resetAllTempEventDataHandler,
    updateTempPassState: updateTempPassStateHandler,
    updatePassParamUrl: updatePassParamUrlHandler,
    updateCoordinates: updateCoordinatesHandler,
    //pass pass-template relation state info
    passTemplateFromPassState,
    passTemplateFromEditPassState,
    savedPassTemplate,
    // pass pass-template relation function info
    updatePassTemplateFromPassState: updatePassTemplateFromPassStateHandler,
    updatePassTemplateFromEditPassState: updatePassTemplateFromEditPassStateHandler,
    updateSavedPassTemplate: updateSavedPassTemplateHandler,
    // event reader relation state info
    addReaderFromPassState,
    addReaderFromEditPassState,
    readersArray,
    readerAddedExternally,
    // event reader relation function info
    updateReadersArray: updateReadersArrayHandler,
    updateAddReaderFromPassState: updateAddReaderFromPassStateHandler,
    updateAddReaderFromEditPassState: updateAddReaderFromEditPassStateHandler,
    updateReaderAddedExternally: updateReaderAddedExternallyHandler,
    // pass event relation state info
    eventParamUrl,
    addEventFromPassState,
    addEventFromEditPassState,
    tempEventState,
    savedEvent,
    eventAddedExternally,
    // pass event relation function info
    updateEventParamUrl: updateEventParamUrlHandler,
    updateEventAddedExternally: updateEventAddedExternallyHandler,
    updateAddEventFromPassState: updateAddEventFromPassStateHandler,
    updateAddEventFromEditPassState: updateAddEventFromEditPassStateHandler,
    updateTempEventState: updateTempEventStateHandler,
    updateSavedEvent: updateSavedEventHandler,
    updateSavedMessages: updateSavedMessagesHandler,
  };
  return (
    <RedirectionContext.Provider value={context}>{children}</RedirectionContext.Provider>
  );
};

export default RedirectionContext;

import { Container, Box, Flex } from '@chakra-ui/react';

function FooterPlain(props) {
  return (
    <Box as="footer">
      <Container maxW={props.leftPadding ? 'full' : '1592px'}>
        <Flex
          flexDirection={{ base: 'column-reverse', xl: 'row' }}
          alignItems="center"
          justifyContent="space-between"
          py={10}
        >
          <Box
            fontSize="12px"
            mt={{ base: '24px', xl: '0px' }}
            textAlign={{ base: 'center', md: 'left' }}
            ml={props.leftPadding ? '20px' : ''}
          >
            © {new Date().getFullYear()},&nbsp;PassEntry™&nbsp;🍍
            -&nbsp;Patent&nbsp;Pending
          </Box>
        </Flex>
      </Container>
    </Box>
  );
}

export default FooterPlain;

import { Icon } from '@chakra-ui/react';

export const CustomPdfDownloadIcon = (props) => (
  <Icon viewBox="0 0 22 26" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.5036 4.87354L16.5036 4.87357L16.5125 4.88448L17.5165 6.11108H15.6914V3.86689L16.5036 4.87354ZM13.6914 2.58984L3.96875 2.58984V18.917V23.0436H18.7109L18.7109 8.11108H15.1914C14.363 8.11108 13.6914 7.43951 13.6914 6.61108V2.58984ZM20.2586 6.30343L18.0601 3.61766L16.2176 1.33397C15.8379 0.86339 15.2657 0.589844 14.661 0.589844H3.96875C2.86418 0.589844 1.96875 1.48528 1.96875 2.58984V18.917V23.0436C1.96875 24.1482 2.86418 25.0436 3.96875 25.0436H18.7109C19.8155 25.0436 20.7109 24.1482 20.7109 23.0436V7.57024C20.7109 7.10841 20.5511 6.6608 20.2586 6.30343Z"
      fill="currentColor"
    />
    <rect
      x="0.890625"
      y="10.543"
      width="20.8056"
      height="10.6345"
      rx="1"
      fill="#EC6759"
    />
    <path
      d="M4.26758 19.0195H5.51558V16.8115H6.42758C7.61958 16.8115 8.52358 16.4035 8.52358 15.0755C8.52358 13.7315 7.54758 13.3555 6.37158 13.3555H4.26758V19.0195ZM5.51558 14.4115H6.15558C6.63558 14.4115 7.22758 14.4915 7.22758 15.0755C7.22758 15.6035 6.78758 15.7555 6.34758 15.7555H5.51558V14.4115Z"
      fill="#fff"
    />
    <path
      d="M9.30664 19.0195H11.3466C13.0346 19.0195 14.5306 18.0355 14.5306 16.2035C14.5306 14.1315 13.0026 13.3555 11.1786 13.3555H9.30664V19.0195ZM10.5546 14.5075H11.2906C12.3466 14.5075 13.2346 15.0275 13.2346 16.1395C13.2346 17.4275 12.3626 17.8675 11.2026 17.8675H10.5546V14.5075Z"
      fill="#fff"
    />
    <path
      d="M15.425 19.0195H16.673V16.8115H19.033V15.6595H16.673V14.5075H19.225V13.3555H15.425V19.0195Z"
      fill="#fff"
    />
  </Icon>
);

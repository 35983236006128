import { Icon } from '@chakra-ui/react';

export const CustomCopyIcon = (props) => {
  return (
    <Icon viewBox="0 0 30 34" fill="currentcolor" {...props}>
      <path d="M26.0825 30.7306V9.34799H9.34827V30.7306H26.0825ZM26.0825 6.34442C26.893 6.34442 27.6081 6.64239 28.2279 7.23834C28.8477 7.83429 29.1576 8.5375 29.1576 9.34799V30.7306C29.1576 31.5411 28.8477 32.2562 28.2279 32.876C27.6081 33.4958 26.893 33.8057 26.0825 33.8057H9.34827C8.53778 33.8057 7.82264 33.4958 7.20286 32.876C6.58307 32.2562 6.27318 31.5411 6.27318 30.7306V9.34799C6.27318 8.5375 6.58307 7.83429 7.20286 7.23834C7.82264 6.64239 8.53778 6.34442 9.34827 6.34442H26.0825ZM21.5056 0.194238V3.26933H3.19809V24.6519H0.194515V3.26933C0.194515 2.45884 0.492489 1.7437 1.08844 1.12392C1.68438 0.504131 2.3876 0.194238 3.19809 0.194238H21.5056Z" />
      <path d="M21.6996 0V3.46365H3.39214V24.8462H0V3.26937C0 2.4054 0.319747 1.64285 0.948158 0.989301C1.57824 0.334011 2.33103 0 3.19786 0H21.6996ZM3.19809 3.26933H21.5056V0.194238H3.19809C2.3876 0.194238 1.68438 0.504131 1.08844 1.12392C0.492489 1.7437 0.194515 2.45884 0.194515 3.26933V24.6519H3.19809V3.26933ZM26.0825 9.34799V30.7306H9.34827V9.34799H26.0825ZM9.54231 9.54231V30.5364H25.888V9.54231H9.54231ZM26.0822 6.15018C26.9462 6.15018 27.7088 6.46992 28.3623 7.09834C29.0176 7.72842 29.3516 8.48121 29.3516 9.34803V30.7306C29.3516 31.5971 29.018 32.3605 28.365 33.0134C27.7121 33.6664 26.9487 34 26.0822 34H9.34803C8.48156 34 7.71819 33.6664 7.06525 33.0134C6.4123 32.3605 6.07866 31.5971 6.07866 30.7306V9.34803C6.07866 8.48121 6.41267 7.72842 7.06797 7.09834C7.72151 6.46992 8.48407 6.15018 9.34803 6.15018H26.0822ZM28.2279 7.23834C27.6081 6.64239 26.893 6.34442 26.0825 6.34442H9.34827C8.53778 6.34442 7.82264 6.64239 7.20286 7.23834C6.58307 7.83429 6.27318 8.5375 6.27318 9.34799V30.7306C6.27318 31.5411 6.58307 32.2562 7.20286 32.876C7.82264 33.4958 8.53778 33.8057 9.34827 33.8057H26.0825C26.893 33.8057 27.6081 33.4958 28.2279 32.876C28.8477 32.2562 29.1576 31.5411 29.1576 30.7306V9.34799C29.1576 8.5375 28.8477 7.83429 28.2279 7.23834Z" />
    </Icon>
  );
};

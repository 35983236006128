import EventsCreatePageForm from './EventsCreatePageForm';
import { EventsContextProvider } from '../../store/client/EventsContext';
import UseEvent from '../../hooks/useEvent';

const EventsEditPage = () => {
  return (
    <EventsContextProvider>
      <UseEvent>
        <EventsCreatePageForm isEdit />
      </UseEvent>
    </EventsContextProvider>
  );
};
export default EventsEditPage;

import { Heading, Center, VStack, Box } from '@chakra-ui/react';
import HeaderPlain from '../../common/HeaderPlain';
import LayoutPlain from '../../common/LayoutPlain';
import { FORM_PADDING_TOP_XL, HEADER_TOP } from '../../utils/consts';
import { useLocation } from 'react-router-dom';
import PassIssuedIconAnimated from '../../theme/illustrations-animated//PassIssuedIconAnimated';
import EmptyDefaultSvgAnimated from '../../theme/illustrations-animated//EmptyDefaultSvgAnimated';

function ErrorNotFoundPage(props) {
  const location = useLocation();
  let { message, locationPage } = location.state;

  return (
    <LayoutPlain header={<HeaderPlain passIssuePage top={HEADER_TOP} />} leftPadding>
      <Center pt={FORM_PADDING_TOP_XL} pb="10px">
        <VStack spacing={2}>
          <Box mb={10}>
            {locationPage === 'passIssuePage' ? (
              <PassIssuedIconAnimated />
            ) : (
              <EmptyDefaultSvgAnimated />
            )}
          </Box>
          <Heading size="2xl" textAlign="center">
            {message}
          </Heading>
        </VStack>
      </Center>
    </LayoutPlain>
  );
}

export default ErrorNotFoundPage;

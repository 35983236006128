import { Icon } from '@chakra-ui/react';

export const CustomAnalyticsMultiIcon = (props) => (
  <Icon viewBox="0 0 63 64" {...props}>
    <path
      className="multicolorElToWhite darkBgElToBrand"
      d="M26.5287 63.1H26.5288C40.6642 63.1 52.1575 51.6023 52.1575 37.4712C52.1575 36.8672 51.9176 36.2879 51.4905 35.8608C51.0634 35.4338 50.4842 35.1938 49.8802 35.1938H28.8061V14.1212C28.8061 13.5172 28.5662 12.938 28.1391 12.5109C27.712 12.0838 27.1328 11.8438 26.5288 11.8438C12.3933 11.8438 0.901457 23.3401 0.9 37.4712L0.9 37.4713C0.905018 44.2669 3.60679 50.7827 8.41201 55.5879C13.2172 60.3932 19.7331 63.0949 26.5287 63.1ZM11.6311 52.3657C7.67993 48.4137 5.45854 43.0552 5.45471 37.4668C5.45619 26.6159 13.6987 17.6542 24.2514 16.5156V37.4712C24.2514 38.0752 24.4914 38.6544 24.9184 39.0815C25.3455 39.5086 25.9248 39.7485 26.5288 39.7485H47.48C46.3414 50.3027 37.3797 58.5452 26.5289 58.5452C20.9404 58.5403 15.5824 56.3178 11.6311 52.3657Z"
      fill="#29323A"
    />
    <path
      className="multicolorElToDark darkBgElToWhite"
      d="M36.0013 29.3911H59.9377C60.5417 29.3911 61.121 29.1512 61.5481 28.7241C61.9752 28.297 62.2151 27.7178 62.2151 27.1138C62.2151 12.6604 50.4562 0.9 36.0013 0.9C35.3973 0.9 34.8181 1.13994 34.391 1.56702C33.9639 1.99411 33.724 2.57337 33.724 3.17736V27.1138C33.724 27.7178 33.9639 28.297 34.391 28.7241C34.8181 29.1512 35.3973 29.3911 36.0013 29.3911ZM51.31 11.8055C54.8154 15.3107 57.0141 19.9096 57.542 24.8364H38.2787V5.57458C43.2055 6.10211 47.8046 8.30039 51.31 11.8055Z"
      fill="#FF8477"
    />
  </Icon>
);

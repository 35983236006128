import { Link as RouterLink } from 'react-router-dom';
import {
  Button,
  Text,
  HStack,
  useColorModeValue,
  useMediaQuery,
} from '@chakra-ui/react';

const PassesListCreatePassesButton = () => {
  const smallTextColor = useColorModeValue(
    'rgba(59, 72, 81, 0.8)',
    'rgba(221, 221, 221, 0.5)'
  );
  const [isLargerThan480] = useMediaQuery('(min-width: 480px)');

  return (
    <Button
      mt={{ base: '12px', sm: '0px' }}
      mr={{ base: '0px', sm: '10px' }}
      px={{ base: '10px', sm: '23px' }}
      alt="Create passes"
      size="sm"
      variant="white02"
      as={RouterLink}
      to="/passes/batches"
      fontSize="12px"
    >
      <HStack spacing="5px">
        <Text as="span">Create passes</Text>
        <Text as="span" w="1px" h="12px" bgColor="brand"></Text>

        <Text
          as="span"
          textStyle="bodyFamilyMedium"
          fontSize="10px"
          lineHeight="120%"
          fontWeight="400"
          color={smallTextColor}
          textTransform="none"
        >
          via CSV
        </Text>
      </HStack>
    </Button>
  );
};

export default PassesListCreatePassesButton;

import { createContext, useState } from 'react';

const KeysListContext = createContext({
  keyState: {
    items: [],
    total: 0,
    pageCount: 0,
    status: 'idle',
    loading: false,
    error: null,
  },
  updateKeyState: () => {},
});

export const KeysListContextProvider = ({ children }) => {
  const [keyState, setKeyState] = useState({
    items: [],
    total: 0,
    pageCount: 0,
    status: 'idle',
    loading: false,
    error: null,
  });

  const updateKeyStateHandler = (param) => {
    setKeyState(param);
  };

  const context = {
    keyState,
    updateKeyState: updateKeyStateHandler,
  };

  return <KeysListContext.Provider value={context}>{children}</KeysListContext.Provider>;
};

export default KeysListContext;

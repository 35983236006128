import { Icon } from '@chakra-ui/react';

export const CustomEmailMultiIcon = (props) => (
  <Icon viewBox="0 0 60 42" {...props}>
    <path
      className="multicolorElToWhite darkBgElToBrand"
      d="M57.6923 13.8461C57.0803 13.8461 56.4933 14.0893 56.0605 14.5221C55.6277 14.9548 55.3846 15.5418 55.3846 16.1538V34.6154C55.3846 35.2274 55.1415 35.8144 54.7087 36.2472C54.2759 36.6799 53.689 36.9231 53.0769 36.9231H6.92308C6.31104 36.9231 5.72407 36.6799 5.29129 36.2472C4.85852 35.8144 4.61538 35.2274 4.61538 34.6154V16.1538C4.61538 15.5418 4.37225 14.9548 3.93948 14.5221C3.5067 14.0893 2.91973 13.8461 2.30769 13.8461C1.69565 13.8461 1.10868 14.0893 0.675907 14.5221C0.243131 14.9548 0 15.5418 0 16.1538V34.6154C0.00161587 36.4509 0.73207 38.2106 2.03077 39.5077C3.32785 40.8064 5.08758 41.5368 6.92308 41.5385H53.0769C54.9124 41.5368 56.6721 40.8064 57.9692 39.5077C59.2679 38.2106 59.9984 36.4509 60 34.6154V16.1538C60 15.5418 59.7569 14.9548 59.3241 14.5221C58.8913 14.0893 58.3043 13.8461 57.6923 13.8461Z"
      fill="#29323A"
    />
    <path
      className="multicolorElToDark darkBgElToWhite"
      d="M28.6144 27.2308C29.0138 27.5304 29.4997 27.6923 29.999 27.6923C30.4983 27.6923 30.9842 27.5304 31.3836 27.2308L58.6375 6.78461C59.0626 6.46401 59.3638 6.00623 59.49 5.4889C59.6162 4.97156 59.5596 4.42651 59.3298 3.94615C58.9861 3.23613 58.5259 2.5887 57.9682 2.03077C56.6711 0.73207 54.9114 0.00161587 53.0759 0H6.92207C5.08658 0.00161587 3.32685 0.73207 2.02977 2.03077C1.47212 2.5887 1.01191 3.23613 0.668228 3.94615C0.43842 4.42651 0.381806 4.97156 0.507985 5.4889C0.634164 6.00623 0.935371 6.46401 1.36054 6.78461L28.6144 27.2308ZM6.92207 4.61538H53.0759C53.29 4.58168 53.508 4.58168 53.7221 4.61538L29.999 22.5L6.27592 4.61538C6.48999 4.58168 6.70801 4.58168 6.92207 4.61538Z"
      fill="#FF8477"
    />
  </Icon>
);

import { Icon } from '@chakra-ui/react';

export const CustomCloseIcon = (props) => (
  <Icon viewBox="0 0 30 30" {...props}>
    <path
      fill="currentColor"
      d="M3.32913 0.571142C2.56761 -0.190381 1.33294 -0.190381 0.571413 0.571142C-0.190109 1.33266 -0.190109 2.56734 0.571413 3.32886L12.1924 14.9499L0.571141 26.5712C-0.19038 27.3327 -0.190382 28.5674 0.571141 29.3289C1.33266 30.0904 2.56734 30.0904 3.32886 29.3289L14.9501 17.7076L26.5714 29.3289C27.3329 30.0904 28.5676 30.0904 29.3291 29.3289C30.0907 28.5673 30.0907 27.3327 29.3291 26.5711L17.7079 14.9499L29.3289 3.32888C30.0904 2.56736 30.0904 1.33269 29.3289 0.571168C28.5673 -0.190355 27.3327 -0.190355 26.5711 0.571168L14.9501 12.1922L3.32913 0.571142Z"
    />
  </Icon>
);

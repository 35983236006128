import { Icon } from '@chakra-ui/react';

export const CustomSmsMultiIcon = (props) => (
  <Icon viewBox="0 0 62 62" {...props}>
    <path
      className="multicolorElToDark darkBgElToWhite"
      d="M14.5 21.6667C14.5 20.286 15.6193 19.1667 17 19.1667H40.3333C41.714 19.1667 42.8333 20.286 42.8333 21.6667C42.8333 23.0474 41.714 24.1667 40.3333 24.1667H17C15.6193 24.1667 14.5 23.0474 14.5 21.6667Z"
      fill="#FF8477"
    />
    <path
      className="multicolorElToDark darkBgElToWhite"
      d="M14.5 31C14.5 29.6193 15.6193 28.5 17 28.5H26.3333C27.714 28.5 28.8333 29.6193 28.8333 31C28.8333 32.3807 27.714 33.5 26.3333 33.5H17C15.6193 33.5 14.5 32.3807 14.5 31Z"
      fill="#FF8477"
    />
    <path
      className="multicolorElToWhite darkBgElToBrand"
      d="M0.5 20.92C0.5 9.77661 9.28163 0.5 20.2308 0.5H41.7692C52.7184 0.5 61.5 9.77661 61.5 20.92V29.88C61.5 41.0234 52.7184 50.3 41.7692 50.3H31.6462C21.7383 50.3 12.2038 54.084 4.67186 60.8587C3.93792 61.5189 2.88426 61.6853 1.98256 61.2836C1.08087 60.8819 0.5 59.9871 0.5 59V20.92ZM20.2308 5.5C12.2261 5.5 5.5 12.3514 5.5 20.92V53.7914C13.1489 48.3286 22.2248 45.3 31.6462 45.3H41.7692C49.7739 45.3 56.5 38.4486 56.5 29.88V20.92C56.5 12.3514 49.7739 5.5 41.7692 5.5H20.2308Z"
      fill="#29323A"
    />
  </Icon>
);

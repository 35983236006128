import { Icon } from '@chakra-ui/react';

export const CustomMailIcon = (props) => (
  <Icon viewBox="0 0 26 18" {...props}>
    <path
      fill="currentColor"
      d="M25 6.00012C24.7348 6.00012 24.4804 6.10548 24.2929 6.29302C24.1054 6.48055 24 6.73491 24 7.00012V15.0001C24 15.2653 23.8946 15.5197 23.7071 15.7072C23.5196 15.8948 23.2652 16.0001 23 16.0001H3C2.73478 16.0001 2.48043 15.8948 2.29289 15.7072C2.10536 15.5197 2 15.2653 2 15.0001V7.00012C2 6.73491 1.89464 6.48055 1.70711 6.29302C1.51957 6.10548 1.26522 6.00012 1 6.00012C0.734784 6.00012 0.48043 6.10548 0.292893 6.29302C0.105357 6.48055 0 6.73491 0 7.00012V15.0001C0.000700208 15.7955 0.31723 16.5581 0.88 17.1201C1.44207 17.6829 2.20462 17.9994 3 18.0001H23C23.7954 17.9994 24.5579 17.6829 25.12 17.1201C25.6828 16.5581 25.9993 15.7955 26 15.0001V7.00012C26 6.73491 25.8946 6.48055 25.7071 6.29302C25.5196 6.10548 25.2652 6.00012 25 6.00012Z"
    />
    <path
      fill="currentColor"
      d="M12.3997 11.8C12.5728 11.9298 12.7833 12 12.9997 12C13.2161 12 13.4266 11.9298 13.5997 11.8L25.4097 2.94C25.5939 2.80107 25.7245 2.6027 25.7791 2.37852C25.8338 2.15434 25.8093 1.91815 25.7097 1.71C25.5608 1.40232 25.3613 1.12177 25.1197 0.88C24.5576 0.31723 23.7951 0.000700208 22.9997 0H2.99969C2.20431 0.000700208 1.44176 0.31723 0.879692 0.88C0.638047 1.12177 0.438619 1.40232 0.289692 1.71C0.190108 1.91815 0.165576 2.15434 0.220253 2.37852C0.274931 2.6027 0.405453 2.80107 0.589692 2.94L12.3997 11.8ZM2.99969 2H22.9997C23.0925 1.98539 23.1869 1.98539 23.2797 2L12.9997 9.75L2.71969 2C2.81245 1.98539 2.90693 1.98539 2.99969 2Z"
    />
  </Icon>
);

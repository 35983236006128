import { Icon } from '@chakra-ui/react';

export const CustomLoyaltyMultiIcon = (props) => (
  <Icon viewBox="0 0 62 61" {...props}>
    <path
      className="multicolorElToWhite darkBgElToBrand"
      d="M10.2499 60.46C9.59563 60.46 8.94138 60.2405 8.50522 59.5818C7.85097 58.7036 7.85097 57.1668 8.94138 56.5082L17.2285 49.7022C17.6647 49.2631 18.3189 49.2631 18.9732 49.2631L40.9995 51.8977C41.4357 51.8977 41.8718 51.8977 42.308 51.4586L57.3557 40.0421C57.7919 39.603 58.0099 39.1639 57.5738 38.5053C57.5738 38.5053 57.5738 38.5053 57.3557 38.2857C56.9195 37.8467 56.2653 37.6271 55.8291 38.0662C51.6855 41.1399 43.8346 46.8481 43.8346 46.8481C43.3984 47.0676 42.9622 47.2872 42.308 47.2872L30.9677 46.409C30.3135 46.409 29.8773 45.9699 29.4411 45.5308L26.8241 42.2376C26.388 41.579 26.1699 40.9203 26.6061 40.0421C26.8241 39.3835 27.4784 38.7248 28.1326 38.7248L37.7283 36.5294H37.9464C38.6006 36.5294 38.8187 35.8707 38.8187 35.4316C38.8187 34.773 38.3825 34.3339 37.7283 34.3339H21.5902C21.154 34.3339 20.7178 34.3339 20.2817 34.5534L3.27124 46.409C2.18082 47.0676 0.87233 46.8481 0.218082 45.9699C-0.218082 45.0917 0 43.7744 0.87233 42.8962L17.8828 31.0407C17.8828 31.0407 17.8828 31.0407 18.1008 31.0407C19.1913 30.3821 20.4998 30.1625 21.5902 30.1625H37.5102C40.3453 30.1625 42.7442 32.358 43.1803 35.4316C43.3984 38.5053 41.2176 40.9203 38.3825 41.3594L33.1485 42.4571L41.6538 43.1158C43.6165 41.7985 49.5047 37.4076 52.994 34.773C55.3929 33.0166 58.8823 33.4557 60.6269 35.8707L60.845 36.0903C62.8078 38.5053 62.1535 42.018 59.7546 43.994L44.7069 55.4104C43.3984 56.5082 41.6538 56.9473 40.1272 56.7277L19.1913 53.8736L11.5584 60.0209C11.1222 60.2405 10.686 60.46 10.2499 60.46Z"
      fill="#29323A"
    />
    <path
      className="multicolorElToDark darkBgElToWhite"
      d="M35.3351 4.66151C34.3782 4.66151 33.445 5.05314 32.7452 5.77535C32.043 6.50006 31.636 7.49822 31.636 8.55384C31.636 9.60946 32.043 10.6076 32.7452 11.3323L40.817 19.6628L48.8888 11.3323C49.591 10.6076 49.998 9.60946 49.998 8.55384C49.998 7.49822 49.591 6.50006 48.8888 5.77535C48.189 5.05314 47.2558 4.66151 46.2989 4.66151C45.342 4.66151 44.4089 5.05314 43.7091 5.77535C43.6592 5.82685 43.6069 5.87602 43.5525 5.9227L42.7984 6.56943C42.7602 6.60215 42.7211 6.63361 42.6809 6.66375C42.3237 7.13937 41.7948 7.46414 41.1999 7.56316C40.495 7.68046 39.7753 7.46796 39.2472 6.98662L38.0287 5.87605C37.9931 5.84357 37.9585 5.80998 37.9249 5.77535C37.2251 5.05314 36.292 4.66151 35.3351 4.66151ZM29.3975 2.53156C30.9593 0.919663 33.0935 -4.63079e-07 35.3351 0C37.3657 0 39.3083 0.754745 40.817 2.09515C42.3257 0.754746 44.2683 9.26159e-07 46.2989 4.6308e-07C48.5404 4.6308e-07 50.6747 0.919665 52.2365 2.53156C53.7959 4.14095 54.6595 6.30853 54.6595 8.55384C54.6595 10.7991 53.7959 12.9667 52.2365 14.5761L42.4965 24.6283C41.579 25.5752 40.0577 25.5781 39.1375 24.6283L29.3975 14.5761C27.838 12.9667 26.9745 10.7991 26.9745 8.55384C26.9745 6.30853 27.838 4.14095 29.3975 2.53156Z"
      fill="#FF8477"
    />
  </Icon>
);

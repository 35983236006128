import { Box, useColorModeValue } from '@chakra-ui/react';

const TableBox = (props) => {
  const modeBoxBg = useColorModeValue('white', 'secondaryDark');

  return (
    <Box
      bg={modeBoxBg}
      borderRadius="15px"
      padding="28px 24px 36px"
      boxShadow="0px 3.5px 5.5px rgba(0, 0, 0, 0.02)"
      maxHeight={props.liveEventData ? '70vh' : null}
      overflow={props.liveEventData ? 'auto' : null}
      id={props.liveEventData && 'scrollableTable'}
    >
      {props.children}
    </Box>
  );
};

export default TableBox;

import {
  Text,
  SimpleGrid,
  GridItem,
  Box,
  Container,
  Heading,
  VStack,
  useColorModeValue,
} from '@chakra-ui/react';

const colors = [
  'brand',
  'brandDark',
  'secondaryDark',
  'secondaryDark08',
  'secondaryDark06',
];
const textStyles = [
  'headingFamilyMedium',
  'bodyFamilyMedium',
  'bodyFamilyRegular',
  'bodyBold',
  'bodyMedium',
  'bodyRoman',
  'headingFamilyRegularGooglePay',
  'headingFamilySemiBoldGooglePay',
];

function Fonts() {
  return (
    <Container
      bg={useColorModeValue('white', 'primaryDark')}
      borderBottom="solid 1px #DDD"
    >
      <Container maxW="1180px" px="0" py={6}>
        <SimpleGrid w="full" columns={{ base: 1, md: 3 }} columnGap={4} rowGap={4}>
          <GridItem>
            <Box fontSize="24px" my={4}>
              Inter
            </Box>
            <VStack alignItems="flex-start">
              <Heading size="2xl">Inter Bold (700, 2xl)</Heading>
              <Heading>Inter Bold (700, xl)</Heading>
              <Heading size="lg">Inter Bold (700, lg)</Heading>
              <Text size="lg" textStyle="headingFamilyMedium">
                Inter Medium (500, lg)
              </Text>
            </VStack>
          </GridItem>

          <GridItem>
            <Box fontSize="24px" my={4}>
              Open Sans
            </Box>
            <VStack alignItems="flex-start">
              <Text textStyle="headingFamilyRegularGooglePay">
                Open Sans Regular (400)
              </Text>
              <Text textStyle="headingFamilySemiBoldGooglePay">
                Open Sans SemiBold (600)
              </Text>
            </VStack>
          </GridItem>

          <GridItem>
            <Box fontSize="20px" my={4}>
              text colors
            </Box>
            <VStack alignItems="flex-start">
              {colors.map((color, index) => {
                return (
                  <Text key={index} color={color}>
                    Color {color}
                    {index === colors.length - 1 && (
                      <Box as="span" color="error">
                        *
                      </Box>
                    )}
                  </Text>
                );
              })}
            </VStack>
          </GridItem>

          <GridItem>
            <Box fontSize="20px" my={4}>
              text styles
            </Box>
            <VStack alignItems="flex-start">
              {textStyles.map((textStyle, index) => {
                return (
                  <Text key={index} textStyle={textStyle}>
                    {textStyle}
                  </Text>
                );
              })}
            </VStack>
          </GridItem>
        </SimpleGrid>
      </Container>
    </Container>
  );
}

export default Fonts;

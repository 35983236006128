import UseClientPass from '../../hooks/useClientPass';
import PassViewPageView from '../../components/client/passes/PassViewPageView';
import { PassContextProvider } from '../../store/client/PassContext';
import { PassTemplateContextProvider } from '../../store/client/PassTemplateContext';

const PassesViewPage = () => {
  return (
    <PassTemplateContextProvider>
      <PassContextProvider>
        <UseClientPass>
          <PassViewPageView />
        </UseClientPass>
      </PassContextProvider>
    </PassTemplateContextProvider>
  );
};
export default PassesViewPage;

import Breadcrumbs from '../../common/Breadcrumbs';
import { Container, Divider, Heading, Flex } from '@chakra-ui/react';
import PassesCreateCSVForm from '../../components/client/passes/PassesCreateCSVForm';
const PassesBatchesPage = () => {
  const breadcrumbLinks = [
    {
      linkName: 'Home',
      linkUrl: '/',
    },
    {
      linkName: 'Passes',
      linkUrl: '/passes',
    },
    {
      linkName: 'Batches',
      linkUrl: '/passes/batches',
    },
    {
      linkName: 'Create',
      linkUrl: '/passes/batches/create',
    },
  ];

  return (
    <Container display="flex" flexDir="column" flex="1" pb={6}>
      <Breadcrumbs breadcrumbLinks={breadcrumbLinks} />
      <Flex
        flexDirection={{ base: 'column', sm: 'row' }}
        alignItems="center"
        flexWrap="wrap"
        mt="15px"
        pt="9px"
        minH="54px"
      >
        <Heading alignSelf={{ base: 'flex-start', sm: 'center' }}>
          New Pass Creation Batch
        </Heading>
      </Flex>
      <Divider borderColor="primaryBackground" mb={6} />
      <PassesCreateCSVForm />
    </Container>
  );
};

export default PassesBatchesPage;

import { createContext, useState } from 'react';

const MessageContext = createContext({
  messageText: '',
  updateMessageText: () => {},
});

export const MessageContextProvider = ({ children }) => {
  const [messageText, setMessageText] = useState('');

  const updateMessageTextHandler = (text) => {
    setMessageText(text);
  };

  const context = {
    messageText,
    updateMessageText: updateMessageTextHandler,
  };

  return (
    <MessageContext.Provider value={context}>
      {children}
    </MessageContext.Provider>
  );
};

export default MessageContext;

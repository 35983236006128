import { Icon } from '@chakra-ui/react';

export const CustomTemplatesMultiIcon = (props) => (
  <Icon viewBox="0 0 14 16" {...props}>
    <path
      className="multicolorElToDark darkBgElToWhite"
      d="M9.31447 4.86149H7.32217C7.0203 4.86149 6.77881 4.61534 6.77881 4.30764C6.77881 3.99995 7.0203 3.69226 7.38254 3.69226H9.37484C9.67671 3.69226 9.9182 3.93841 9.9182 4.24611C9.9182 4.5538 9.67671 4.86149 9.31447 4.86149Z"
      fill="#FF8477"
    />
    <path
      className="multicolorElToDark darkBgElToWhite"
      d="M10.5221 7.44613H6.35634C6.05448 7.44613 5.81299 7.19998 5.81299 6.89229C5.81299 6.58459 6.05448 6.33844 6.35634 6.33844H10.5221C10.8239 6.33844 11.0654 6.58459 11.0654 6.89229C11.0654 7.19998 10.8239 7.44613 10.5221 7.44613Z"
      fill="#FF8477"
    />
    <path
      className="multicolorElToDark darkBgElToWhite"
      d="M10.5221 9.90767H6.35634C6.05448 9.90767 5.81299 9.66151 5.81299 9.35382C5.81299 9.04613 6.05448 8.79997 6.35634 8.79997H10.5221C10.8239 8.79997 11.0654 9.04613 11.0654 9.35382C11.0654 9.66151 10.8239 9.90767 10.5221 9.90767Z"
      fill="#FF8477"
    />
    <path
      className="multicolorElToWhite darkBgElToBrand"
      d="M12.6953 0H4.00163C3.45827 0 3.03566 0.430769 3.03566 0.984615V2.4H1.46597C0.92261 2.4 0.5 2.83077 0.5 3.38462V15.0154C0.5 15.5692 0.92261 16 1.46597 16H10.3408C10.8841 16 11.3067 15.5692 11.3067 15.0154V13.3538H12.6953C13.2387 13.3538 13.6613 12.9231 13.6613 12.3692V0.984615C13.6613 0.430769 13.2387 0 12.6953 0ZM10.1597 14.8308H1.64708V3.56923H3.03566V12.4308C3.03566 12.9846 3.45827 13.4154 4.00163 13.4154H10.1597V14.8308ZM12.5142 12.2462H4.18275V1.16923H12.5142V12.2462Z"
      fill="#29323A"
    />
  </Icon>
);

import { createContext, useState } from 'react';
const PAGE_SIZE = 12;

const initialFilterValues = {
  passType: '',
  event: '',
};

export const initialPassTemplates = {
  items: [],
  total: 0,
  pageIndex: 0,
  pageSize: PAGE_SIZE,
  hasMore: false,
  status: 'pending',
  loading: false,
  error: null,
};

const PassTemplatesListContext = createContext({
  passTemplates: initialPassTemplates,
  filterValues: initialFilterValues,
  updatePassTemplates: () => {},
  updateFilterValues: () => {},
  incrementPageIndex: () => {},
  resetPageIndex: () => {},
});

export const PassTemplatesListContextProvider = ({ children }) => {
  const [passTemplates, setPassTemplates] = useState(initialPassTemplates);
  const [filterValues, setFilterValues] = useState(initialFilterValues);
  const [clearPageIndex, setClearPageIndex] = useState(0);

  const updatePassTemplatesHandler = (param) => {
    setPassTemplates(param);
  };

  function updateFilterValuesHandler(filterValues) {
    setFilterValues(filterValues);
    setClearPageIndex(clearPageIndex + 1);
  }

  function incrementPageIndexHandler() {
    const index = passTemplates.pageIndex;
    setPassTemplates({
      ...passTemplates,
      pageIndex: index + 1,
    });
  }

  const resetPageIndexHandler = () => {
    setPassTemplates({
      ...passTemplates,
      pageIndex: 0,
    });
  };

  const context = {
    passTemplates,
    filterValues,
    updatePassTemplates: updatePassTemplatesHandler,
    updateFilterValues: updateFilterValuesHandler,
    incrementPageIndex: incrementPageIndexHandler,
    resetPageIndex: resetPageIndexHandler,
  };

  return (
    <PassTemplatesListContext.Provider value={context}>
      {children}
    </PassTemplatesListContext.Provider>
  );
};

export default PassTemplatesListContext;

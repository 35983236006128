import {
  IconButton,
  Link,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Grid,
  GridItem,
  Box,
  Divider,
  Button,
  Text,
  useDisclosure,
  useColorModeValue,
} from '@chakra-ui/react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { CustomChevronLeftIcon } from '../../../theme/icons/CustomChevronLeftIcon';

function EventsListMoreInfo({ cell, children, isAdmin }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigate = useNavigate();
  const modeIconColor = useColorModeValue('secondaryDark08', '#DDDDDD');
  const modeBoxBg = useColorModeValue('white', 'secondaryDark');
  const modeTextBg = useColorModeValue('white', 'primaryDark06');
  const modeLabelBg = useColorModeValue('white', 'primaryDark03');
  const modeBorderColor = useColorModeValue('primaryBackground', 'primaryDark');

  function handleMoreInfoClick(e) {
    e.stopPropagation();
    onOpen();
  }

  return (
    <>
      <IconButton
        onClick={handleMoreInfoClick}
        icon={
          <CustomChevronLeftIcon
            w="20px"
            h="20px"
            color={modeIconColor}
            transform="rotate(180deg)"
          />
        }
        variant="white"
        size="sm"
        w="36px"
        mr={1}
      />
      <Drawer onClose={onClose} isOpen={isOpen} size="md">
        <DrawerOverlay />
        <DrawerContent bg={modeBoxBg}>
          <DrawerCloseButton />
          <DrawerHeader
            borderBottomWidth="1px"
            borderBottomColor={modeBorderColor}
            pt={{ base: '16px', lg: '56px' }}
            pb={3}
            bg={modeTextBg}
          >
            Event overview
          </DrawerHeader>
          <DrawerBody p="0" bg={modeLabelBg}>
            <Grid templateColumns={{ base: '150px 1fr', md: '192px 1fr' }} gap={0}>
              <GridItem
                fontFamily="Inter Medium, sans-serif"
                fontSize="12px"
                color={modeIconColor}
                textTransform="uppercase"
                lineHeight="21px"
                padding="16px 4px 16px 22px"
              >
                Event name
              </GridItem>
              <GridItem
                fontFamily="Inter Bold, sans-serif"
                fontSize="14px"
                padding="16px 4px 16px 22px"
                bg="quinaryBackground"
              >
                <Text wordBreak="break-word">{cell.row.original.name}</Text>
              </GridItem>
              <GridItem colSpan={2} w="full">
                <Divider borderColor={modeBorderColor} />
              </GridItem>
              {isAdmin && (
                <>
                  <GridItem
                    fontFamily="Inter Medium, sans-serif"
                    fontSize="12px"
                    color={modeIconColor}
                    textTransform="uppercase"
                    lineHeight="21px"
                    padding="16px 4px 16px 22px"
                  >
                    Creator
                  </GridItem>
                  <GridItem
                    fontFamily="Inter Bold, sans-serif"
                    fontSize="14px"
                    padding="16px 4px 16px 22px"
                    bg="quinaryBackground"
                    wordBreak="break-word"
                  >
                    {cell.row.original.creator}
                  </GridItem>
                  <GridItem colSpan={2} w="full">
                    <Divider borderColor={modeBorderColor} />
                  </GridItem>
                </>
              )}

              <GridItem
                fontFamily="Inter Medium, sans-serif"
                fontSize="12px"
                color={modeIconColor}
                textTransform="uppercase"
                lineHeight="21px"
                padding="16px 4px 16px 22px"
              >
                Venue name
              </GridItem>
              <GridItem
                fontSize="14px"
                padding="16px 4px 16px 22px"
                bg="quinaryBackground"
              >
                <Text wordBreak="break-word">{cell.row.original.venueName}</Text>
              </GridItem>
              <GridItem colSpan={2} w="full">
                <Divider borderColor={modeBorderColor} />
              </GridItem>

              <GridItem
                fontFamily="Inter Medium, sans-serif"
                fontSize="12px"
                color={modeIconColor}
                textTransform="uppercase"
                lineHeight="21px"
                padding="16px 4px 16px 22px"
              >
                Start date
              </GridItem>
              <GridItem
                fontSize="14px"
                padding="16px 4px 16px 22px"
                bg="quinaryBackground"
              >
                {cell.row.original.eventStartDate}
              </GridItem>
              <GridItem colSpan={2} w="full">
                <Divider borderColor={modeBorderColor} />
              </GridItem>

              <GridItem
                fontFamily="Inter Medium, sans-serif"
                fontSize="12px"
                color={modeIconColor}
                textTransform="uppercase"
                lineHeight="21px"
                padding="16px 4px 16px 22px"
              >
                Start time
              </GridItem>
              <GridItem
                fontSize="14px"
                padding="16px 4px 16px 22px"
                bg="quinaryBackground"
              >
                {cell.row.original.eventStartTime}
              </GridItem>
              <GridItem colSpan={2} w="full">
                <Divider borderColor={modeBorderColor} />
              </GridItem>

              <GridItem
                fontFamily="Inter Medium, sans-serif"
                fontSize="12px"
                color={modeIconColor}
                textTransform="uppercase"
                lineHeight="21px"
                padding="16px 4px 16px 22px"
              >
                Status
              </GridItem>
              <GridItem
                fontSize="14px"
                padding="16px 4px 16px 22px"
                bg="quinaryBackground"
              >
                <Box display="inline-block">{children}</Box>
              </GridItem>
              <GridItem colSpan={2} w="full">
                <Divider borderColor={modeBorderColor} />
              </GridItem>
              {!isAdmin && cell.row.original.status !== 'passed' && (
                <>
                  <GridItem colSpan={2} padding="16px 24px" ml="auto">
                    <Button
                      alt="Edit Event"
                      size="sm"
                      onClick={(e) => {
                        navigate(`/events/edit/${cell.row.original.id}`);
                        // redirectCtx.updateTemplateAddedExternally(false);
                        // redirectCtx.updateReaderAddedExternally(false);
                        // redirectCtx.updateAddReaderFromEventState(false);
                        // redirectCtx.updateAddTemplateFromEventState(false);
                        // redirectCtx.updateAddTemplateFromEditEventState(false);
                        // redirectCtx.updateAddReaderFromEditEventState(false);
                        // redirectCtx.updateAddEventFromEditPassState(false);
                        // redirectCtx.updateAddEventFromPassState(false);
                      }}
                    >
                      Edit Event
                    </Button>
                  </GridItem>
                  <GridItem colSpan={2} w="full">
                    <Divider borderColor={modeBorderColor} />
                  </GridItem>
                </>
              )}
            </Grid>
          </DrawerBody>
          <DrawerFooter
            borderTopWidth="1px"
            borderTopColor={modeBorderColor}
            bg={modeTextBg}
          >
            <Link
              color={useColorModeValue('secondaryDark08', '#DDDDDD')}
              fontSize="14px"
              onClick={onClose}
              mr="30px"
            >
              <Text textStyle="headingFamilyMedium">Back to list</Text>
            </Link>
            <Button
              alt="Go to event page"
              size="sm"
              px="30px"
              alignSelf="flex-start"
              as={RouterLink}
              to={cell.row.original.exampleForNestedObject.eventSinglePageUrl}
            >
              Go to event page
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
}

export default EventsListMoreInfo;

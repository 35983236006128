import React from 'react';
import PassesCreatePageForm from './PassesCreatePageForm';
import { PassTemplateContextProvider } from '../../store/client/PassTemplateContext';
import { PassContextProvider } from '../../store/client/PassContext';

const PassesCreatePage = () => {
  return (
    <PassContextProvider>
      <PassTemplateContextProvider>
        <PassesCreatePageForm confirmView />
      </PassTemplateContextProvider>
    </PassContextProvider>
  );
};

export default PassesCreatePage;

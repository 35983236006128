import { Icon } from '@chakra-ui/react';

export const CustomExclamationMarkInverseIcon = (props) => (
  <Icon viewBox="0 0 34 34" {...props}>
    <path
      fill={
        props.mode === 'dark' ? '#fff' : props.mode === 'sentstats' ? '#29323A' : '#ddd'
      }
      d="M17 33.6667C7.79504 33.6667 0.333374 26.205 0.333374 17C0.333374 7.79504 7.79504 0.333374 17 0.333374C26.205 0.333374 33.6667 7.79504 33.6667 17C33.6667 26.205 26.205 33.6667 17 33.6667ZM15.3334 15.3334V25.3334H18.6667V15.3334H15.3334ZM15.3334 8.66671V12H18.6667V8.66671H15.3334Z"
    />
  </Icon>
);

import { Icon } from '@chakra-ui/react';

export const CustomReadersAltMultiIcon = (props) => (
  <Icon viewBox="0 0 62 62" {...props}>
    <path
      className="multicolorElToDark darkBgElToWhite"
      d="M35.6 34.5C36.9 34.3 38.2 34.3 39.5 34.5C40.8 34.7 42 35 43.1 35.6C43.9 36 44.6 36.5 45.3 37.1C46.2 37.9 46.2 39.3 45.4 40.2C44.6 41.1 43.2 41.1 42.3 40.3C42.1 40.1 41.7 39.8 41.2 39.6C40.6 39.3 39.8 39.1 38.9 38.9C38 38.8 37.1 38.8 36.2 38.9C35.3 39 34.5 39.3 33.9 39.6C33.4 39.8 33.1 40.1 32.8 40.3C31.9 41.1 30.6 41.1 29.7 40.2C28.9 39.3 28.9 38 29.8 37.1C30.4 36.5 31.2 36 32 35.6C33.1 35 34.3 34.7 35.6 34.5Z"
      fill="#FF8477"
    />
    <path
      className="multicolorElToDark darkBgElToWhite"
      d="M37.5 48.2C39.2121 48.2 40.6 46.8121 40.6 45.1C40.6 43.3879 39.2121 42 37.5 42C35.7879 42 34.4 43.3879 34.4 45.1C34.4 46.8121 35.7879 48.2 37.5 48.2Z"
      fill="#FF8477"
    />
    <path
      className="multicolorElToDark darkBgElToWhite"
      d="M28.6 33.9C29.1 33.5 29.7 33.1 30.4 32.8C31.7 32.2 33.2 31.7 34.9 31.5C36.6 31.3 38.4 31.3 40 31.5C41.7 31.7 43.2 32.2 44.5 32.8C45.2 33.1 45.8 33.5 46.3 33.9C47.2 34.6 48.6 34.5 49.3 33.6C50 32.7 49.9 31.3 49 30.6C48.2 30 47.3 29.4 46.4 28.9C44.7 28 42.7 27.5 40.6 27.2C38.5 26.9 36.3 26.9 34.3 27.2C32.2 27.5 30.2 28.1 28.5 28.9C28 29.2 27.5 29.5 27 29.8V34.4C27.5 34.5 28.1 34.3 28.6 33.9Z"
      fill="#FF8477"
    />
    <path
      className="multicolorElToWhite darkBgElToBrand"
      d="M10.3 49.6C9.09998 49.6 8.09998 50.6 8.09998 51.8C8.09998 53 9.09998 54 10.3 54H16.7C17.9 54 18.9 53 18.9 51.8C18.9 50.6 17.9 49.6 16.7 49.6H10.3Z"
      fill="#29323A"
    />
    <path
      className="multicolorElToWhite darkBgElToBrand"
      d="M57.7 0H16.7C14.3 0 12.4 1.9 12.4 4.3V14.4H3.2C1.4 14.4 0 15.9 0 17.6V58.7C0 60.5 1.5 62 3.2 62H23.7C25.5 62 26.9 60.5 26.9 58.8V55.9H57.6C60 55.9 61.9 54 61.9 51.6V4.3C62 1.9 60.1 0 57.7 0ZM22.7 57.7H4.3V18.7H22.6V18.9V57.7H22.7ZM27 17.6V12.5H47.4V18.5H27V17.6ZM57.7 51.5H27V22.9H47.8C50 22.9 51.8 21.1 51.8 18.9V12.2C51.8 10 50 8.2 47.8 8.2H26.6C24.4 8.2 22.6 10 22.6 12.2V14.4H16.6V4.3H57.6V51.5H57.7Z"
      fill="#29323A"
    />
  </Icon>
);

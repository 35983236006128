import { motion } from 'framer-motion';
import { Icon } from '@chakra-ui/react';

const ReaderIconDarkAnimated = () => {
  return (
    <Icon viewBox="0 0 131 107" width="131px" height="107px">
      <circle cx="65.9194" cy="53.541" r="53" fill="#3B4851" />
      <motion.path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.91012 64.7407C9.32445 64.7407 9.66034 64.4049 9.66034 63.9907C9.66034 63.5765 9.32445 63.2407 8.91012 63.2407H6.23296V60.5644C6.23296 60.1502 5.89707 59.8144 5.48273 59.8144C5.0684 59.8144 4.73251 60.1502 4.73251 60.5644L4.73251 63.2407L2.05538 63.2407C1.64104 63.2407 1.30515 63.5765 1.30515 63.9907C1.30515 64.4049 1.64104 64.7407 2.05538 64.7407L4.73251 64.7407L4.73251 67.4171C4.73251 67.8313 5.0684 68.1671 5.48273 68.1671C5.89707 68.1671 6.23296 67.8313 6.23296 67.4171L6.23296 64.7407H8.91012Z"
        fill="#DDDDDD"
        fillOpacity="0.5"
        animate={{
          opacity: [0, 1],
          x: [20, 0],
          scale: [0.5, 1],
        }}
        transition={{ delay: 0.5, duration: 1, ease: 'easeInOut' }}
      />
      <motion.path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M127.085 42.6577C127.085 42.955 127.326 43.196 127.623 43.196C127.921 43.196 128.162 42.955 128.162 42.6577L128.162 40.737L130.083 40.737C130.38 40.737 130.621 40.496 130.621 40.1987C130.621 39.9015 130.38 39.6605 130.083 39.6605L128.162 39.6605L128.162 37.7396C128.162 37.4423 127.921 37.2013 127.623 37.2013C127.326 37.2013 127.085 37.4423 127.085 37.7396V39.6605L125.163 39.6605C124.866 39.6605 124.625 39.9015 124.625 40.1987C124.625 40.496 124.866 40.737 125.163 40.737L127.085 40.737L127.085 42.6577Z"
        fill="#DDDDDD"
        fillOpacity="0.5"
        animate={{
          opacity: [0, 1],
          x: [-20, 0],
          y: [10, 0],
          scale: [0.5, 1],
        }}
        transition={{ delay: 0.3, duration: 1, ease: 'easeInOut' }}
      />
      {/* <rect
        width="7.50637"
        height="1.39794"
        rx="0.698968"
        transform="matrix(0.707212 -0.707001 0.707212 0.707001 106.332 23.7354)"
        fill="#DDDDDD"
        fillOpacity="0.5"
      /> */}
      <motion.path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M103 20C103.552 20 104 19.5523 104 19C104 18.4477 103.552 18 103 18C102.447 18 102 18.4477 102 19C102 19.5523 102.447 20 103 20ZM103 22C104.657 22 106 20.6569 106 19C106 17.3431 104.657 16 103 16C101.343 16 99.9998 17.3431 99.9998 19C99.9998 20.6569 101.343 22 103 22Z"
        fill="#DDDDDD"
        fillOpacity="0.6"
        animate={{
          opacity: [0, 1],
          x: [-10, 0],
          y: [10, 0],
          scale: [0.5, 1],
        }}
        transition={{ delay: 0.5, duration: 1, ease: 'easeInOut' }}
      />
      <motion.rect
        width="7.9164"
        height="1.68802"
        rx="0.844009"
        //transform="matrix(-0.707212 -0.707001 0.707212 -0.707001 19.2876 12.6963)"
        fill="#DDDDDD"
        fillOpacity="0.6"
        animate={{
          opacity: [0, 1],
          x: [20, 10],
          y: [20, 10],
          scale: [0.5, 1],
          rotate: [45, 45],
        }}
        transition={{ delay: 0.3, duration: 1, ease: 'easeInOut' }}
      />
      <motion.path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.811 32.4784C11.4187 32.4784 11.9114 31.9859 11.9114 31.3784C11.9114 30.7709 11.4187 30.2784 10.811 30.2784C10.2034 30.2784 9.71072 30.7709 9.71072 31.3784C9.71072 31.9859 10.2034 32.4784 10.811 32.4784ZM10.811 33.8784C12.1922 33.8784 13.3118 32.7591 13.3118 31.3784C13.3118 29.9977 12.1922 28.8784 10.811 28.8784C9.42992 28.8784 8.3103 29.9977 8.3103 31.3784C8.3103 32.7591 9.42992 33.8784 10.811 33.8784Z"
        fill="#FF8477"
        animate={{
          opacity: [0, 1],
          x: [20, 0],
          y: [20, 0],
          scale: [0.5, 1],
        }}
        transition={{ delay: 0.3, duration: 1, ease: 'easeInOut' }}
      />
      <motion.path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.2077 85.366C23.7601 85.366 24.208 84.9183 24.208 84.366C24.208 83.8137 23.7601 83.366 23.2077 83.366C22.6552 83.366 22.2074 83.8137 22.2074 84.366C22.2074 84.9183 22.6552 85.366 23.2077 85.366ZM23.2077 87.366C24.865 87.366 26.2086 86.0228 26.2086 84.366C26.2086 82.7091 24.865 81.366 23.2077 81.366C21.5503 81.366 20.2068 82.7091 20.2068 84.366C20.2068 86.0228 21.5503 87.366 23.2077 87.366Z"
        fill="#DDDDDD"
        fillOpacity="0.5"
        animate={{
          opacity: [0, 1],
          x: [10, 0],
          y: [-10, 0],
          scale: [0.5, 1],
        }}
        transition={{ delay: 0.1, duration: 1, ease: 'easeInOut' }}
      />
      <motion.path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M107.845 87.0286C108.674 87.0286 109.346 86.357 109.346 85.5286C109.346 84.7001 108.674 84.0286 107.845 84.0286C107.017 84.0286 106.345 84.7001 106.345 85.5286C106.345 86.357 107.017 87.0286 107.845 87.0286ZM107.845 89.0286C109.779 89.0286 111.346 87.4616 111.346 85.5286C111.346 83.5956 109.779 82.0286 107.845 82.0286C105.912 82.0286 104.344 83.5956 104.344 85.5286C104.344 87.4616 105.912 89.0286 107.845 89.0286Z"
        fill="#FF8477"
        animate={{
          opacity: [0, 1],
          x: [-10, 0],
          y: [-10, 0],
          scale: [0.5, 1],
        }}
        transition={{ delay: 0.5, duration: 1, ease: 'easeInOut' }}
      />
      <motion.rect
        x="76.0825"
        y="20.1797"
        width="10.2877"
        height="1.87049"
        rx="0.935246"
        transform="rotate(-45 76.0825 20.1797)"
        fill="white"
        animate={{
          opacity: [0, 1],
        }}
        transition={{ delay: 1, duration: 1, ease: 'easeInOut' }}
      />
      <motion.rect
        x="66.824"
        y="21.5022"
        width="10.2877"
        height="1.87049"
        rx="0.935245"
        transform="rotate(-135 66.824 21.5022)"
        fill="white"
        animate={{
          opacity: [0, 1],
        }}
        transition={{ delay: 1, duration: 1, ease: 'easeInOut' }}
      />
      <motion.rect
        x="71.1633"
        y="18.3022"
        width="10.6618"
        height="1.77697"
        rx="0.888483"
        transform="rotate(-90 71.1633 18.3022)"
        fill="white"
        animate={{
          opacity: [0, 1],
        }}
        transition={{ delay: 1, duration: 1, ease: 'easeInOut' }}
      />
      <motion.path
        d="M96.9324 80.3191C96.9324 83.0805 94.6938 85.3191 91.9324 85.3191L52.9074 85.3191C50.1459 85.3191 47.9074 83.0805 47.9074 80.3191L47.9074 31.421C47.9074 28.6596 50.1459 26.421 52.9074 26.421L91.9324 26.421C94.6938 26.421 96.9324 28.6596 96.9324 31.421L96.9324 80.3191Z"
        fill="white"
        animate={{
          opacity: [0, 1],
        }}
        transition={{ duration: 1, ease: 'easeInOut' }}
      />
      <motion.path
        d="M53.5737 35.9895C53.5737 34.8849 54.4692 33.9895 55.5737 33.9895H89.2662C90.3707 33.9895 91.2662 34.8849 91.2662 35.9895V45.2395C91.2662 46.3441 90.3707 47.2395 89.2662 47.2395H55.5737C54.4692 47.2395 53.5737 46.3441 53.5737 45.2395V35.9895Z"
        fill="#899197"
        animate={{
          opacity: [0, 1],
        }}
        transition={{ duration: 1, ease: 'easeInOut' }}
      />
      <motion.path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M70.5203 62.7142C71.8203 62.5142 73.1203 62.5142 74.4203 62.7142C75.7203 62.9142 76.9203 63.2142 78.0203 63.8142C78.8203 64.2142 79.5203 64.7142 80.2203 65.3142C81.1203 66.1142 81.1203 67.5142 80.3203 68.4142C79.5203 69.3142 78.1203 69.3142 77.2203 68.5142C77.0203 68.3142 76.6203 68.0142 76.1203 67.8142C75.5203 67.5142 74.7203 67.3142 73.8203 67.1142C72.9203 67.0142 72.0203 67.0142 71.1203 67.1142C70.2203 67.2142 69.4203 67.5142 68.8203 67.8142C68.3203 68.0142 68.0203 68.3142 67.7203 68.5142C66.8203 69.3142 65.5203 69.3142 64.6203 68.4142C63.8203 67.5142 63.8203 66.2142 64.7203 65.3142C65.3203 64.7142 66.1203 64.2142 66.9203 63.8142C68.0203 63.2142 69.2203 62.9142 70.5203 62.7142Z"
        fill="#FF8477"
        animate={{
          opacity: [0, 1],
        }}
        transition={{ duration: 1, ease: 'easeInOut' }}
      />
      <motion.path
        d="M72.4198 76.4141C74.1319 76.4141 75.5198 75.0262 75.5198 73.3141C75.5198 71.602 74.1319 70.2141 72.4198 70.2141C70.7077 70.2141 69.3198 71.602 69.3198 73.3141C69.3198 75.0262 70.7077 76.4141 72.4198 76.4141Z"
        fill="#FF8477"
        animate={{
          opacity: [0, 1],
        }}
        transition={{ duration: 1, ease: 'easeInOut' }}
      />
      <motion.path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M69.92 59.7143C70.7162 59.6148 71.5619 59.5648 72.4201 59.5643C73.2784 59.5648 74.1241 59.6148 74.9203 59.7143C76.6203 59.9143 78.1203 60.4143 79.4203 61.0143C80.1203 61.3143 80.7203 61.7143 81.2203 62.1143C82.1203 62.8143 83.5203 62.7143 84.2203 61.8143C84.9203 60.9143 84.8203 59.5143 83.9203 58.8143C83.1203 58.2143 82.2203 57.6143 81.3203 57.1143C79.6203 56.2143 77.6203 55.7143 75.5203 55.4143C74.499 55.2684 73.4542 55.1935 72.4201 55.1895C71.3861 55.1935 70.3412 55.2684 69.32 55.4143C67.22 55.7143 65.22 56.2143 63.52 57.1143C62.62 57.6143 61.72 58.2143 60.92 58.8143C60.02 59.5143 59.92 60.9143 60.62 61.8143C61.32 62.7143 62.72 62.8143 63.62 62.1143C64.12 61.7143 64.72 61.3143 65.42 61.0143C66.72 60.4143 68.22 59.9143 69.92 59.7143Z"
        fill="#FF8477"
        animate={{
          opacity: [0, 1],
        }}
        transition={{ duration: 1, ease: 'easeInOut' }}
      />
      <motion.path
        d="M34.9067 46.3003C34.9067 45.1957 35.8022 44.3003 36.9067 44.3003H55.1922C56.2968 44.3003 57.1922 45.1957 57.1922 46.3003V85.366C57.1922 86.4705 56.2968 87.366 55.1922 87.366H36.9067C35.8022 87.366 34.9067 86.4705 34.9067 85.366V46.3003Z"
        fill="#FF8477"
        animate={{
          opacity: [0, 1],
        }}
        transition={{ duration: 1, ease: 'easeInOut' }}
      />
      <motion.path
        d="M39.5205 83.1384C39.5205 82.5237 40.0188 82.0254 40.6335 82.0254H51.4653C52.08 82.0254 52.5783 82.5237 52.5783 83.1384C52.5783 83.7531 52.08 84.2515 51.4653 84.2515H40.6335C40.0188 84.2515 39.5205 83.7531 39.5205 83.1384Z"
        fill="white"
        animate={{
          opacity: [0, 1],
        }}
        transition={{ duration: 1, ease: 'easeInOut' }}
      />
    </Icon>
  );
};

export default ReaderIconDarkAnimated;

import { Box, Text, useColorMode, Flex } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { CustomVisibilityIcon } from '../../../theme/multicolor-icons/CustomVisibilityIcon';
import { CustomVisibilityOffIcon } from '../../../theme/multicolor-icons/CustomVisibilityOffIcon';

const ApiKeyItemVisibilityToggler = ({
  id,
  visibleKeyUUID,
  setVisibleKeyUUID,
  setIsKeyVisible,
}) => {
  const { colorMode } = useColorMode();
  const [isVisible, setIsVisible] = useState(false);

  const handleClick = () => {
    if (visibleKeyUUID === id && isVisible) {
      setIsVisible(false);
      setIsKeyVisible(false);
      return;
    }
    if (visibleKeyUUID === id && !isVisible) {
      setIsVisible(true);
      setIsKeyVisible(true);
      return;
    }
    setIsVisible(true);
    setIsKeyVisible(true);
    setVisibleKeyUUID(id);
  };

  useEffect(() => {
    if (visibleKeyUUID !== id) {
      setIsVisible(false);
    }
  }, [visibleKeyUUID]);

  return (
    <Box
      onClick={handleClick}
      cursor="pointer"
      w="135px"
      data-testid="toggle-visibility"
    >
      {!isVisible ? (
        <Flex
          justifyContent={{ base: 'start', md: 'center' }}
          alignItems="center"
        >
          <CustomVisibilityIcon
            className={colorMode === 'dark' && 'multicolorDarkBg'}
          />
          <Text color="brand" pl="7px" pt="1px">
            Reveal
          </Text>
        </Flex>
      ) : (
        <Flex
          justifyContent={{ base: 'start', md: 'center' }}
          alignItems="center"
        >
          <CustomVisibilityOffIcon
            className={colorMode === 'dark' && 'multicolorDarkBg'}
          />
          <Text color="brand" pl="7px" pt="1px">
            Hide
          </Text>
        </Flex>
      )}
    </Box>
  );
};

export default ApiKeyItemVisibilityToggler;

import { createContext, useState } from 'react';

const initialFilterValues = {
  id: '',
  extId: '',
  // eventName: '',
  passTemplate: '',
  groupTag: '',
  passEntity: '',
  status: '',
  section: '',
  row: '',
  passType: '',
  deviceType: '',
};

const PassesListContext = createContext({
  passState: {
    items: [],
    rowNumber: null,
    total: 0,
    pageCount: 0,
    status: 'idle',
    loading: false,
    error: null,
  },
  filterValues: initialFilterValues,
  totalActiveFilters: 0,
  newFiltersSet: false,
  updatePassState: () => {},
  updateFilterValues: () => {},
  updateTotalActiveFilters: () => {},
  updateNewFiltersSet: () => {},
});

export const PassesListContextProvider = ({ children }) => {
  const [passState, setPassState] = useState({
    items: [],
    total: 0,
    pageCount: 0,
    status: 'idle',
    loading: false,
    error: null,
  });
  const [filterValues, setFilterValues] = useState(initialFilterValues);
  const [totalActiveFilters, setTotalActiveFilters] = useState(0);
  const [newFiltersSet, setNewFiltersSet] = useState(false);
  const [clearPageIndex, setClearPageIndex] = useState(0);

  const updatePassStateHandler = (param) => {
    setPassState(param);
  };

  function updateFilterValuesHandler(filterValues) {
    setFilterValues(filterValues);
    setClearPageIndex(clearPageIndex + 1);
  }

  function updateTotalActiveFiltersHandler(totalActiveFilters) {
    setTotalActiveFilters(totalActiveFilters);
  }

  function updateNewFiltersSetHandler(newFiltersSet) {
    setNewFiltersSet(newFiltersSet);
  }

  const context = {
    passState,
    filterValues,
    totalActiveFilters,
    newFiltersSet,
    updatePassState: updatePassStateHandler,
    updateFilterValues: updateFilterValuesHandler,
    updateTotalActiveFilters: updateTotalActiveFiltersHandler,
    updateNewFiltersSet: updateNewFiltersSetHandler,
  };

  return (
    <PassesListContext.Provider value={context}>{children}</PassesListContext.Provider>
  );
};

export default PassesListContext;

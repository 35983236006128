import { Icon } from '@chakra-ui/react';

export const CustomPassesMultiIcon = (props) => (
  <Icon viewBox="0 0 68 52" {...props}>
    <path
      className="multicolorElToWhite darkBgElToBrand"
      d="M21.76 24.1526C22.8933 24.3762 23.12 24.5999 24.48 25.2708C25.3867 25.718 26.52 25.718 27.2 24.8235C28.1067 23.929 27.6533 22.5872 26.7467 21.6926C26.52 21.469 26.0667 21.2454 25.6133 21.0217C29.6933 17.8908 31.5067 12.0764 29.0133 6.70913C27.4267 3.57825 24.48 1.11827 21.08 0.223735C13.1467 -1.56534 6.12 4.47279 6.12 11.8527C6.12 15.4309 7.70667 19.009 10.6533 21.2454C4.08 24.1526 0 30.4144 0 37.347V45.6215C0 46.7397 0.906667 47.6342 2.04 47.6342H27.6533C28.7867 47.6342 29.6933 46.7397 29.6933 45.6215C29.6933 44.5033 28.7867 43.6088 27.6533 43.6088H4.08V37.347C4.08 28.6253 12.4667 21.9163 21.76 24.1526ZM18.1333 4.02552C22.44 4.02552 26.0667 7.60367 26.0667 11.8527C26.0667 16.1018 22.44 19.6799 18.1333 19.6799C13.8267 19.6799 10.2 16.1018 10.2 11.8527C10.2 7.60367 13.8267 4.02552 18.1333 4.02552Z"
      fill="#29323A"
    />
    <path
      className="multicolorElToWhite darkBgElToBrand"
      d="M48.506 13.1941C37.8527 13.1941 29.0127 21.6922 29.0127 32.4267C29.0127 43.1611 37.626 51.6592 48.506 51.6592C59.386 51.6592 67.9994 43.1611 67.9994 32.4267C67.9994 21.6922 59.386 13.1941 48.506 13.1941ZM48.506 46.9629C40.346 46.9629 33.546 40.4775 33.546 32.203C33.546 23.9286 40.1194 17.4432 48.506 17.4432C56.8927 17.4432 63.466 23.9286 63.466 32.203C63.466 40.4775 56.8927 46.9629 48.506 46.9629Z"
      fill="#29323A"
    />
    <path
      className="multicolorElToDark darkBgElToWhite"
      d="M41.4788 34.3172L51.9055 34.3172L47.5988 39.0135C46.6922 39.9081 46.9188 41.2499 47.8255 42.1444C48.7322 43.0389 50.0922 42.8153 50.9988 41.9208L58.7055 33.4227C58.7055 33.4227 58.7055 33.199 58.9321 33.199L59.1588 32.9754C59.1588 32.7518 59.1588 32.7518 59.1588 32.5281C59.1588 32.3045 59.1588 32.3045 59.1588 32.0809C59.1588 31.8572 59.1588 31.8572 59.1588 31.6336C59.1588 31.41 59.1588 31.41 59.1588 31.1863L58.9321 30.9627C58.9321 30.9627 58.9321 30.7391 58.7055 30.7391L50.9988 22.2409C50.5455 21.7937 49.8655 21.57 49.4122 21.57C48.9588 21.57 48.2788 21.7937 47.8255 22.2409C46.9188 23.1355 46.9188 24.4773 47.5988 25.3718L51.9055 30.0681L41.4788 30.0681C40.1188 30.0681 39.2122 30.9627 39.2122 32.3045C39.2122 33.6463 40.3455 34.3172 41.4788 34.3172Z"
      fill="#FF8477"
    />
  </Icon>
);

import { Box, useColorModeValue } from '@chakra-ui/react';

function CardForm({ children }) {
  const modeBoxBg = useColorModeValue('gradient', 'secondaryDark');
  const modeLabelBg = useColorModeValue('transparent', 'primaryDark03');

  return (
    <Box
      className="primaryBoxShadow"
      bg={modeBoxBg}
      w={['100%', '454px']}
      borderRadius="15px"
      textAlign="left"
    >
      <Box p={['16px', '50px']} bg={modeLabelBg}>
        {children}
      </Box>
    </Box>
  );
}

export default CardForm;

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalBody,
  Button,
  VStack,
  Image,
  Heading,
  Text,
  Box,
  useColorModeValue,
} from '@chakra-ui/react';
import thankYouSrc from '../../assets/vectors/thank-you.svg';

const EmailConfirmationModal = (props) => {
  const modeText = useColorModeValue('secondaryDark08', '#DDDDDD');

  return (
    <Modal onClose={props.onClose} isOpen={props.isOpen} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalBody>
          <VStack spacing="30px" alignItems="flex-start" mt="36px">
            <Image
              src={thankYouSrc}
              alt="Thank you"
              mb="10px"
              alignSelf="center"
            />
            <Heading fontSize={{ base: '24px', lg: '32px' }} lineHeight="130%">
              Email confirmation required
            </Heading>
            <Box>
              <Text textStyle="bodyFamilyMedium" color={modeText}>
                Please click the verification link we sent to the email address
                provided. Make sure to check your spam folder if you can't see
                it in your inbox.
              </Text>
            </Box>
          </VStack>
        </ModalBody>
        <ModalFooter>
          <Button onClick={props.onClose} alt="OK">
            OK
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default EmailConfirmationModal;

export const isValidURL = (string) => {
  let regex = /[A-Za-z]+:\s+/i;
  if (regex.test(string)) {
    return false;
  } else {
    try {
      new URL(string);
      return true;
    } catch (_) {
      return false;
    }
  }
};

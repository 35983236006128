import { motion } from 'framer-motion';
import { Icon } from '@chakra-ui/react';

const ApiKeysIconAnimated = () => {
  return (
    <Icon viewBox="0 0 125 114" width="125px" height="114px">
      <circle cx="60" cy="61" r="53" fill="#D1D1D1" />
      <motion.rect
        x="25"
        y="16"
        width="93"
        height="26"
        rx="6"
        fill="white"
        animate={{
          opacity: [0, 1],
        }}
        transition={{ duration: 1, ease: 'easeInOut' }}
      />
      <motion.rect
        x="25"
        y="80"
        width="93"
        height="26"
        rx="6"
        fill="white"
        animate={{
          opacity: [0, 1],
        }}
        transition={{ duration: 1, ease: 'easeInOut' }}
      />
      <motion.rect
        y="48"
        width="98"
        height="26"
        rx="6"
        fill="white"
        animate={{
          opacity: [0, 1],
        }}
        transition={{ duration: 1, ease: 'easeInOut' }}
      />
      <motion.rect
        x="26"
        y="11"
        width="11"
        height="2"
        rx="1"
        transform="rotate(-90 26 11)"
        fill="#3B4851"
        fillOpacity="0.6"
        animate={{
          opacity: [0, 1],
        }}
        transition={{ delay: 1, duration: 1, ease: 'easeInOut' }}
      />
      <motion.rect
        x="20"
        y="19"
        width="11"
        height="2"
        rx="1"
        transform="rotate(180 20 19)"
        fill="#3B4851"
        fillOpacity="0.6"
        animate={{
          opacity: [0, 1],
        }}
        transition={{ delay: 1, duration: 1, ease: 'easeInOut' }}
      />
      <motion.path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M118.22 57.0653C117.927 57.3582 117.927 57.833 118.22 58.1259C118.513 58.4188 118.987 58.4188 119.28 58.1259L121.173 56.2335L123.065 58.1259C123.358 58.4188 123.833 58.4188 124.126 58.1259C124.419 57.833 124.419 57.3582 124.126 57.0653L122.233 55.1728L124.126 53.2803C124.419 52.9874 124.419 52.5126 124.126 52.2197C123.833 51.9268 123.358 51.9268 123.065 52.2197L121.173 54.1121L119.28 52.2197C118.987 51.9268 118.513 51.9268 118.22 52.2197C117.927 52.5126 117.927 52.9874 118.22 53.2803L120.112 55.1728L118.22 57.0653Z"
        fill="#3B4851"
        fillOpacity="0.8"
        animate={{
          opacity: [0, 1],
          x: [-10, 0],
          scale: [0.5, 1],
        }}
        transition={{ delay: 0.5, duration: 1, ease: 'easeInOut' }}
      />
      <motion.path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M54.1577 4.63529C53.9475 4.84549 53.9475 5.1863 54.1577 5.39651C54.3679 5.60672 54.7088 5.60672 54.919 5.39651L56.2771 4.03835L57.6353 5.39652C57.8455 5.60672 58.1863 5.60672 58.3965 5.39652C58.6067 5.18631 58.6067 4.8455 58.3965 4.63529L57.0383 3.27713L58.3966 1.91888C58.6068 1.70867 58.6068 1.36786 58.3966 1.15765C58.1864 0.947448 57.8456 0.947448 57.6354 1.15765L56.2771 2.5159L54.9189 1.15766C54.7087 0.947454 54.3679 0.947454 54.1577 1.15766C53.9474 1.36787 53.9474 1.70868 54.1577 1.91889L55.5159 3.27713L54.1577 4.63529Z"
        fill="#3B4851"
        fillOpacity="0.6"
        animate={{
          opacity: [0, 1],
          y: [10, 0],
          scale: [0.5, 1],
        }}
        transition={{ delay: 0.1, duration: 1, ease: 'easeInOut' }}
      />
      <motion.rect
        x="12"
        y="92.3071"
        width="7.50525"
        height="1.39773"
        rx="0.698864"
        transform="rotate(-45 12 92.3071)"
        fill="#3B4851"
        fillOpacity="0.6"
        animate={{
          opacity: [0, 1],
          x: [10, 0],
          y: [-10, 0],
          rotate: [-45, -45],
          scale: [0.5, 1],
        }}
        transition={{ delay: 0.3, duration: 1, ease: 'easeInOut' }}
      />
      <motion.rect
        x="20.8613"
        y="13.2998"
        width="11.4"
        height="1.9"
        rx="0.95"
        transform="rotate(-135 20.8613 13.2998)"
        fill="#3B4851"
        fillOpacity="0.6"
        animate={{
          opacity: [0, 1],
        }}
        transition={{ delay: 1, duration: 1, ease: 'easeInOut' }}
      />
      <motion.path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.5 35.6C5.10751 35.6 5.6 35.1075 5.6 34.5C5.6 33.8925 5.10751 33.4 4.5 33.4C3.89249 33.4 3.4 33.8925 3.4 34.5C3.4 35.1075 3.89249 35.6 4.5 35.6ZM4.5 37C5.88071 37 7 35.8807 7 34.5C7 33.1193 5.88071 32 4.5 32C3.11929 32 2 33.1193 2 34.5C2 35.8807 3.11929 37 4.5 37Z"
        fill="#FF8477"
        animate={{
          opacity: [0, 1],
          x: [10, 0],
          y: [10, 0],
          scale: [0.5, 1],
        }}
        transition={{ delay: 0.3, duration: 1, ease: 'easeInOut' }}
      />
      <motion.path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M112 73C112.552 73 113 72.5523 113 72C113 71.4477 112.552 71 112 71C111.448 71 111 71.4477 111 72C111 72.5523 111.448 73 112 73ZM112 75C113.657 75 115 73.6569 115 72C115 70.3431 113.657 69 112 69C110.343 69 109 70.3431 109 72C109 73.6569 110.343 75 112 75Z"
        fill="#3B4851"
        fillOpacity="0.6"
        animate={{
          opacity: [0, 1],
          x: [-10, 0],
          scale: [0.5, 1],
        }}
        transition={{ delay: 0.5, duration: 1, ease: 'easeInOut' }}
      />
      <motion.rect
        x="27"
        y="18"
        width="22"
        height="22"
        rx="4"
        fill="url(#paint0_linear_2030_1462)"
        animate={{
          opacity: [0, 1],
        }}
        transition={{ duration: 1, ease: 'easeInOut' }}
      />
      <motion.path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M39.4357 33.6353C39.4357 33.8162 39.3638 33.9898 39.2358 34.1178L38.5534 34.8001C38.287 35.0666 37.8549 35.0666 37.5884 34.8001L36.9061 34.1178C36.7781 33.9898 36.7062 33.8162 36.7062 33.6353L36.7062 33.2355L37.3886 32.5532L36.7062 31.8708L36.7062 31.5296L37.3886 30.8472L36.7062 30.1649L36.7062 28.8221C35.6951 28.3197 35.0003 27.2763 35.0003 26.0707C35.0003 24.3748 36.3751 23 38.0709 23C39.7668 23 41.1416 24.3748 41.1416 26.0707C41.1416 27.2763 40.4468 28.3197 39.4357 28.8221L39.4357 33.6353ZM38.0709 25.3883C38.4478 25.3883 38.7533 25.0828 38.7533 24.7059C38.7533 24.3291 38.4478 24.0236 38.0709 24.0236C37.6941 24.0236 37.3886 24.3291 37.3886 24.7059C37.3886 25.0828 37.6941 25.3883 38.0709 25.3883Z"
        fill="white"
        animate={{
          opacity: [0, 1],
        }}
        transition={{ duration: 1, ease: 'easeInOut' }}
      />
      <motion.rect
        x="27"
        y="82"
        width="22"
        height="22"
        rx="4"
        fill="url(#paint1_linear_2030_1462)"
        animate={{
          opacity: [0, 1],
        }}
        transition={{ duration: 1, ease: 'easeInOut' }}
      />
      <motion.path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M37.1245 88.2697C38.2425 87.9701 39.3915 88.6336 39.6911 89.7515L40.1301 91.3901C40.2157 91.7095 40.544 91.8991 40.8634 91.8135C41.1828 91.7279 41.3724 91.3996 41.2868 91.0802L40.8477 89.4416C40.377 87.6848 38.5713 86.6423 36.8146 87.113C35.0579 87.5837 34.0154 89.3894 34.4861 91.1462L34.9862 93.0127H34.3659C34.1638 93.0127 34 93.1765 34 93.3786V98.6342C34 98.8362 34.1638 99.0001 34.3659 99.0001H42.4156C42.6176 99.0001 42.7815 98.8362 42.7815 98.6342V93.3786C42.7815 93.1765 42.6176 93.0127 42.4156 93.0127H36.2259L35.6427 90.8362C35.3432 89.7183 36.0066 88.5692 37.1245 88.2697ZM37.792 96.6051C38.1227 96.9358 38.6588 96.9358 38.9895 96.6051C39.3201 96.2744 39.3201 95.7383 38.9895 95.4076C38.6588 95.077 38.1227 95.077 37.792 95.4076C37.4613 95.7383 37.4613 96.2744 37.792 96.6051Z"
        fill="white"
        animate={{
          opacity: [0, 1],
        }}
        transition={{ duration: 1, ease: 'easeInOut' }}
      />
      <motion.rect
        x="2"
        y="50"
        width="22"
        height="22"
        rx="4"
        fill="url(#paint2_linear_2030_1462)"
        animate={{
          opacity: [0, 1],
        }}
        transition={{ duration: 1, ease: 'easeInOut' }}
      />
      <motion.path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.8002 55C10.812 55 9.2002 56.6118 9.2002 58.6V60.4546H8.4C8.17909 60.4546 8 60.6337 8 60.8546V66.6C8 66.821 8.17909 67 8.4 67L17.2 67C17.4209 67 17.6 66.821 17.6 66.6V60.8546C17.6 60.6337 17.4209 60.4546 17.2 60.4546H16.4002V58.6C16.4002 56.6118 14.7884 55 12.8002 55ZM15.0911 60.4546V58.6C15.0911 57.3348 14.0654 56.3091 12.8002 56.3091C11.535 56.3091 10.5093 57.3348 10.5093 58.6V60.4546H15.0911ZM12.1455 64.3819C12.507 64.7434 13.0931 64.7434 13.4545 64.3819C13.816 64.0204 13.816 63.4343 13.4545 63.0728C13.0931 62.7113 12.507 62.7113 12.1455 63.0728C11.784 63.4343 11.784 64.0204 12.1455 64.3819Z"
        fill="white"
        animate={{
          opacity: [0, 1],
        }}
        transition={{ duration: 1, ease: 'easeInOut' }}
      />
      <motion.rect
        x="30"
        y="54"
        width="51"
        height="4"
        rx="2"
        fill="#3B4851"
        fillOpacity="0.8"
        animate={{
          opacity: [0, 1],
        }}
        transition={{ duration: 1, ease: 'easeInOut' }}
      />
      <motion.rect
        x="30"
        y="64"
        width="28"
        height="4"
        rx="2"
        fill="#3B4851"
        fillOpacity="0.6"
        animate={{
          opacity: [0, 1],
        }}
        transition={{ duration: 1, ease: 'easeInOut' }}
      />
      <motion.rect
        x="55"
        y="86"
        width="45"
        height="4"
        rx="2"
        fill="#3B4851"
        fillOpacity="0.8"
        animate={{
          opacity: [0, 1],
        }}
        transition={{ duration: 1, ease: 'easeInOut' }}
      />
      <motion.rect
        x="55"
        y="96"
        width="37"
        height="4"
        rx="2"
        fill="#3B4851"
        fillOpacity="0.6"
        animate={{
          opacity: [0, 1],
        }}
        transition={{ duration: 1, ease: 'easeInOut' }}
      />
      <motion.rect
        x="55"
        y="22"
        width="25"
        height="4"
        rx="2"
        fill="#3B4851"
        fillOpacity="0.8"
        animate={{
          opacity: [0, 1],
        }}
        transition={{ duration: 1, ease: 'easeInOut' }}
      />
      <motion.rect
        x="55"
        y="32"
        width="43"
        height="4"
        rx="2"
        fill="#3B4851"
        fillOpacity="0.6"
        animate={{
          opacity: [0, 1],
        }}
        transition={{ duration: 1, ease: 'easeInOut' }}
      />
      <defs>
        <linearGradient
          id="paint0_linear_2030_1462"
          x1="27.5677"
          y1="40"
          x2="46.7442"
          y2="37.1756"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF8477" />
          <stop offset="1" stopColor="#EC6759" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_2030_1462"
          x1="27.5677"
          y1="104"
          x2="46.7442"
          y2="101.176"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF8477" />
          <stop offset="1" stopColor="#EC6759" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_2030_1462"
          x1="2.56774"
          y1="72"
          x2="21.7442"
          y2="69.1756"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF8477" />
          <stop offset="1" stopColor="#EC6759" />
        </linearGradient>
      </defs>
    </Icon>
  );
};

export default ApiKeysIconAnimated;

import { Icon } from '@chakra-ui/react';

export const CustomRefreshIcon = (props) => (
  <Icon {...props}>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 499 443">
      <g clipPath="url(#clip0_13_126)">
        <path
          d="M89.1 171.6C96.8 149.8 109.3 129.3 126.9 111.8C189.4 49.3 290.7 49.3 353.2 111.8L370.3 129H336C318.3 129 304 143.3 304 161C304 178.7 318.3 193 336 193H447.5H447.9C465.6 193 479.9 178.7 479.9 161V49C479.9 31.3 465.6 17 447.9 17C430.2 17 415.9 31.3 415.9 49V84.2L398.4 66.6C310.9 -20.9 169.1 -20.9 81.6 66.6C57.2 91 39.6 119.7 28.8 150.4C22.9 167.1 31.7 185.3 48.3 191.2C64.9 197.1 83.2 188.3 89.1 171.7V171.6ZM23 258.3C18 259.8 13.2 262.5 9.3 266.5C5.3 270.5 2.6 275.3 1.2 280.5C0.900001 281.7 0.6 283 0.4 284.3C0.0999996 286 0 287.7 0 289.4V401C0 418.7 14.3 433 32 433C49.7 433 64 418.7 64 401V365.9L81.6 383.4C169.1 470.8 310.9 470.8 398.3 383.4C422.7 359 440.4 330.3 451.2 299.7C457.1 283 448.3 264.8 431.7 258.9C415.1 253 396.8 261.8 390.9 278.4C383.2 300.2 370.7 320.7 353.1 338.2C290.6 400.7 189.3 400.7 126.8 338.2L126.7 338.1L109.6 321H144C161.7 321 176 306.7 176 289C176 271.3 161.7 257 144 257H32.4C30.8 257 29.2 257.1 27.6 257.3C26 257.5 24.5 257.8 23 258.3Z"
          fill={!!props.color ? props.color : '#EC675A'}
        />
      </g>
      <g clipPath="url(#clip1_13_126)">
        <path
          d="M108.1 165.625C115.8 143.825 128.3 123.325 145.9 105.825C208.4 43.325 309.7 43.325 372.2 105.825L389.3 123.025H355C337.3 123.025 323 137.325 323 155.025C323 172.725 337.3 187.025 355 187.025H466.5H466.9C484.6 187.025 498.9 172.725 498.9 155.025V43.025C498.9 25.325 484.6 11.025 466.9 11.025C449.2 11.025 434.9 25.325 434.9 43.025V78.225L417.4 60.625C329.9 -26.875 188.1 -26.875 100.6 60.625C76.2 85.025 58.6 113.725 47.8 144.425C41.9 161.125 50.7 179.325 67.3 185.225C83.9 191.125 102.2 182.325 108.1 165.725V165.625ZM42 252.325C37 253.825 32.2 256.525 28.3 260.525C24.3 264.525 21.6 269.325 20.2 274.525C19.9 275.725 19.6 277.025 19.4 278.325C19.1 280.025 19 281.725 19 283.425V395.025C19 412.725 33.3 427.025 51 427.025C68.7 427.025 83 412.725 83 395.025V359.925L100.6 377.425C188.1 464.825 329.9 464.825 417.3 377.425C441.7 353.025 459.4 324.325 470.2 293.725C476.1 277.025 467.3 258.825 450.7 252.925C434.1 247.025 415.8 255.825 409.9 272.425C402.2 294.225 389.7 314.725 372.1 332.225C309.6 394.725 208.3 394.725 145.8 332.225L145.7 332.125L128.6 315.025H163C180.7 315.025 195 300.725 195 283.025C195 265.325 180.7 251.025 163 251.025H51.4C49.8 251.025 48.2 251.125 46.6 251.325C45 251.525 43.5 251.825 42 252.325Z"
          fill={!!props.color ? props.color : '#2B333C'}
        />
      </g>
      <defs>
        <clipPath id="clip0_13_126">
          <rect width="480" height="219" fill="white" />
        </clipPath>
        <clipPath id="clip1_13_126">
          <rect
            width="480"
            height="224"
            fill="white"
            transform="translate(19 219)"
          />
        </clipPath>
      </defs>
    </svg>{' '}
  </Icon>
);

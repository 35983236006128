import { useContext, useEffect } from 'react';
import LayoutDashboard from './LayoutDashboard';
import { MeContext } from '../context/MeContext';
import { useColorMode } from '@chakra-ui/react';
import useGATracker from '../hooks/useGATracker';

const LayoutLogged = (props) => {
  // google analytics integration
  useGATracker();

  const meCtx = useContext(MeContext);
  const { colorMode, toggleColorMode } = useColorMode();
  useEffect(() => {
    if (meCtx && meCtx.state && meCtx.state.data) {
      const lightMode = meCtx.state.data.lightMode;
      if (lightMode && colorMode === 'dark') {
        toggleColorMode();
      }
      if (!lightMode && colorMode === 'light') {
        toggleColorMode();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meCtx, meCtx.state]);
  return (
    <LayoutDashboard publicPage={props.publicPage}>{props.children}</LayoutDashboard>
  );
};

export default LayoutLogged;

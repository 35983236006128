import { Icon } from '@chakra-ui/react';

export const CustomWalletMultiIcon = (props) => (
  <Icon viewBox="0 0 60 60" {...props}>
    <path
      className="multicolorElToWhite darkBgElToBrand"
      d="M12.1538 59.1501H47.8462C50.8441 59.1501 53.7193 57.9596 55.8392 55.8404C57.959 53.7213 59.15 50.8471 59.15 47.8501V25.5502C59.15 22.5532 57.959 19.679 55.8392 17.5599C53.7193 15.4407 50.8441 14.2502 47.8462 14.2502H3.23077C2.59937 14.2502 1.99381 14.5009 1.54733 14.9473C1.10084 15.3936 0.85 15.999 0.85 16.6302V47.8501C0.85 50.8471 2.04095 53.7213 4.16084 55.8404C6.28072 57.9596 9.1559 59.1501 12.1538 59.1501ZM5.61154 47.8501V19.0102H47.8462C49.5813 19.0102 51.2454 19.6992 52.4723 20.9257C53.6992 22.1522 54.3885 23.8157 54.3885 25.5502V47.8501C54.3885 49.5846 53.6992 51.2481 52.4723 52.4746C51.2454 53.701 49.5813 54.3901 47.8462 54.3901H12.1538C10.4187 54.3901 8.75463 53.701 7.52772 52.4746C6.3008 51.2481 5.61154 49.5846 5.61154 47.8501Z"
      fill="#29323A"
    />
    <path
      className="multicolorElToWhite darkBgElToBrand"
      d="M49.9274 10.4894L49.9272 10.4893V10.4975V16.63C49.9272 17.2613 50.178 17.8666 50.6245 18.313C51.071 18.7593 51.6765 19.01 52.3079 19.01C52.9393 19.01 53.5449 18.7593 53.9914 18.313C54.4379 17.8666 54.6887 17.2613 54.6887 16.63V10.4995C54.7294 9.02874 54.4393 7.56755 53.8397 6.2238C53.2398 4.87937 52.3454 3.68685 51.2227 2.73428L51.2228 2.7342L51.2181 2.73053C50.1544 1.8982 48.9143 1.32031 47.5927 1.04114C46.2717 0.762094 44.9043 0.788899 43.5953 1.11949C43.5947 1.11964 43.5941 1.11979 43.5935 1.11994L6.16474 9.61537L6.16446 9.61543C4.64462 9.96348 3.28928 10.82 2.32285 12.0432C1.35655 13.2662 0.837013 14.7824 0.850247 16.3408C0.850417 16.9718 1.10124 17.5769 1.54758 18.0231C1.99406 18.4694 2.59961 18.7201 3.23102 18.7201C3.86242 18.7201 4.46797 18.4694 4.91446 18.0231C5.36094 17.5767 5.61179 16.9714 5.61179 16.3401H5.61183L5.61174 16.3365C5.59999 15.8557 5.75527 15.3856 6.05118 15.0064C6.34709 14.6272 6.76534 14.3621 7.23473 14.2565L7.23501 14.2564L44.645 5.76013L44.645 5.76026L44.651 5.75865C45.2634 5.59296 45.9055 5.56887 46.5286 5.68823C47.1497 5.8072 47.7357 6.06557 48.2423 6.44381C48.8086 6.9474 49.2541 7.572 49.5459 8.2713C49.8385 8.97251 49.9689 9.73072 49.9274 10.4894Z"
      fill="#29323A"
    />
    <path
      className="multicolorElToDark darkBgElToWhite"
      d="M41.1531 45.7699H56.7684C57.3998 45.7699 58.0054 45.5192 58.4519 45.0728C58.8984 44.6265 59.1492 44.0211 59.1492 43.3899V30.0099C59.1492 29.3787 58.8984 28.7734 58.4519 28.327C58.0054 27.8807 57.3998 27.63 56.7684 27.63H41.1531C38.7467 27.63 36.439 28.5855 34.7374 30.2865C33.0359 31.9874 32.08 34.2944 32.08 36.6999C32.08 39.1055 33.0359 41.4124 34.7374 43.1134C36.439 44.8143 38.7467 45.7699 41.1531 45.7699ZM38.1043 33.6523C38.9129 32.844 40.0096 32.3899 41.1531 32.3899H54.3877V41.0099H41.1531C40.0096 41.0099 38.9129 40.5558 38.1043 39.7475C37.2958 38.9393 36.8415 37.843 36.8415 36.6999C36.8415 35.5569 37.2958 34.4606 38.1043 33.6523Z"
      fill="#FF8477"
    />
  </Icon>
);

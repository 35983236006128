import { Container, Flex, Box, useColorModeValue } from '@chakra-ui/react';
import FooterPlain from './FooterPlain';
import ScrollToTopForRouter from '../components/common/ScrollToTopForRouter';
import useGATracker from '../hooks/useGATracker';

const LayoutPlain = (props) => {
  // google analytics integration
  useGATracker();

  const modeBg = useColorModeValue('tertiaryBackground', 'primaryDark');

  return (
    <>
      <ScrollToTopForRouter />
      <Flex direction="column" minH="100vh" bg={modeBg}>
        {props.header}
        <Container variant="bodyContent">
          <Flex w="full" justifyContent={{ base: 'center', md: 'flex-start' }}>
            <Box w="full" px={props.passIssuePage ? '0px' : '16px'}>
              {props.children}
            </Box>
          </Flex>
        </Container>
        {!props.passIssuePage && <FooterPlain leftPadding={props.leftPadding} />}
      </Flex>
    </>
  );
};

export default LayoutPlain;

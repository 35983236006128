import {
  Box,
  Text,
  Flex,
  VStack,
  Heading,
  CloseButton,
} from '@chakra-ui/react';
import { CustomCheckIcon } from '../theme/icons/CustomCheckIcon';
import { CustomInfoIcon } from '../theme/icons/CustomInfoIcon';
import { CustomExclamationMarkIcon } from '../theme/icons/CustomExclamationMarkIcon';
import { CustomExclamationMarkTriangleIcon } from '../theme/icons/CustomExclamationMarkTriangleIcon';

const CustomToast = (props) => {
  // id and onClose come from Chakra Toast component
  const { onClose, status, title, description } = props;
  const statusesBrand = ['info', 'error'];

  const renderIcon = (status) => {
    switch (status) {
      case 'success':
        return (
          <CustomCheckIcon
            data-testid="custom-check-icon"
            mr="14px"
            mt="6px"
            boxSize="20px"
            color="brandDark"
          />
        );
      case 'info':
        return (
          <CustomInfoIcon
            data-testid="custom-info-icon"
            mr="14px"
            mt="6px"
            boxSize="20px"
          />
        );
      case 'error':
        return (
          <CustomExclamationMarkIcon
            data-testid="custom-exclamation-mark-icon"
            mr="14px"
            mt="6px"
            boxSize="20px"
          />
        );
      case 'warning':
        return (
          <CustomExclamationMarkTriangleIcon
            data-testid="custom-exclamation-mark-triangle-icon"
            mr="14px"
            mt="6px"
            boxSize="20px"
          />
        );
      default:
        return null;
    }
  };

  return (
    <Box
      boxShadow="0px 3.5px 5.5px rgba(0, 0, 0, 0.15)"
      bg={
        statusesBrand.includes(status)
          ? 'linear-gradient(81.62deg, #FF8477 2.25%, #EC6759 79.87%)'
          : 'secondaryDark'
      }
      w={{ base: '100%', md: props.width ? props.width : '560px' }}
      p="18px"
      borderRadius="15px"
      color="white"
    >
      <Flex>
        {renderIcon(status)}
        <VStack spacing="2px" alignItems="left" flex="1" textAlign="center">
          <Heading wordBreak="break-word">{title}</Heading>
          <Text textStyle="bodyFamilyMedium" wordBreak="break-word">
            {description}
          </Text>
        </VStack>
        <CloseButton onClick={onClose} ml="14px" />
      </Flex>
    </Box>
  );
};

export default CustomToast;

import {
  SimpleGrid,
  GridItem,
  Box,
  Container,
  AspectRatio,
  useColorModeValue,
} from '@chakra-ui/react';

import { CustomCookieIcon } from '../../theme/icons/CustomCookieIcon';
import { CustomCheckIcon } from '../../theme/icons/CustomCheckIcon';
import { CustomExclamationMarkIcon } from '../../theme/icons/CustomExclamationMarkIcon';
import { CustomExclamationMarkInverseIcon } from '../../theme/icons/CustomExclamationMarkInverseIcon';
import { CustomExclamationMarkTriangleIcon } from '../../theme/icons/CustomExclamationMarkTriangleIcon';
import { CustomArrowRightIcon } from '../../theme/icons/CustomArrowRightIcon';
import { CustomInfoIcon } from '../../theme/icons/CustomInfoIcon';
import { CustomMenuOpenIcon } from '../../theme/icons/CustomMenuOpenIcon';
import { CustomCloseIcon } from '../../theme/icons/CustomCloseIcon';
import { CustomVisibilityIcon } from '../../theme/icons/CustomVisibilityIcon';
import { CustomVisibilityOffIcon } from '../../theme/icons/CustomVisibilityOffIcon';
import { CustomFilterIcon } from '../../theme/icons/CustomFilterIcon';
import { CustomChevronLeftIcon } from '../../theme/icons/CustomChevronLeftIcon';
import { CustomChevronLeftDoubleIcon } from '../../theme/icons/CustomChevronLeftDoubleIcon';
import { CustomMailIcon } from '../../theme/icons/CustomMailIcon';
import { CustomDownloadIcon } from '../../theme/icons/CustomDownloadIcon';
import { CustomAndroidIcon } from '../../theme/icons/CustomAndroidIcon';
import { CustomAppleIcon } from '../../theme/icons/CustomAppleIcon';
import { CustomNfcIcon } from '../../theme/icons/CustomNfcIcon';
import { CustomCalendarIcon } from '../../theme/icons/CustomCalendarIcon';
import { CustomClockIcon } from '../../theme/icons/CustomClockIcon';
import { CustomViewEditIcon } from '../../theme/icons/CustomViewEditIcon';
import { CustomEditIcon } from '../../theme/icons/CustomEditIcon';
import { CustomEyeDropperIcon } from '../../theme/icons/CustomEyeDropperIcon';
import { CustomCopyIcon } from '../../theme/icons/CustomCopyIcon';
import { CustomSquareDownloadIcon } from '../../theme/icons/CustomSquareDownloadIcon';
import { CustomPdfDownloadIcon } from '../../theme/icons/CustomPdfDownloadIcon';
import { CustomCsvDownloadIcon } from '../../theme/icons/CustomCsvDownloadIcon';

const iconSize = '32px';

const monocolorIcons = [
  <CustomCookieIcon fontSize={iconSize} />,
  <CustomCheckIcon fontSize={iconSize} />,
  <CustomExclamationMarkIcon fontSize={iconSize} />,
  <CustomExclamationMarkInverseIcon fontSize={iconSize} />,
  <CustomExclamationMarkTriangleIcon fontSize={iconSize} />,
  <CustomArrowRightIcon fontSize={iconSize} />,
  <CustomInfoIcon fontSize={iconSize} />,
  <CustomMenuOpenIcon fontSize={iconSize} />,
  <CustomCloseIcon fontSize={iconSize} />,
  <CustomVisibilityIcon fontSize={iconSize} />,
  <CustomVisibilityOffIcon fontSize={iconSize} />,
  <CustomFilterIcon fontSize={iconSize} />,
  <CustomChevronLeftIcon fontSize={iconSize} />,
  <CustomChevronLeftDoubleIcon fontSize={iconSize} />,
  <CustomMailIcon fontSize={iconSize} />,
  <CustomDownloadIcon fontSize={iconSize} />,
  <CustomAndroidIcon fontSize={iconSize} />,
  <CustomAppleIcon fontSize={iconSize} />,
  <CustomNfcIcon fontSize={iconSize} />,
  <CustomCalendarIcon fontSize={iconSize} styleguide />,
  <CustomClockIcon fontSize={iconSize} styleguide />,
  <CustomViewEditIcon fontSize="64px" />,
  <CustomEditIcon fontSize={iconSize} />,
  <CustomEyeDropperIcon fontSize={iconSize} />,
  <CustomCopyIcon fontSize={iconSize} />,
  <CustomSquareDownloadIcon fontSize={iconSize} />,
  <CustomPdfDownloadIcon fontSize={iconSize} />,
  <CustomCsvDownloadIcon fontSize={iconSize} />,
];

function MonocolorIcons() {
  const modeBgColor = useColorModeValue('white', 'primaryDark');

  return (
    <Container bg="white" borderBottom="solid 1px #DDD">
      <Container maxW="1180px" px="0" py={6}>
        <SimpleGrid w="full" columns={{ base: 2, md: 4, xl: 8 }} columnGap={4} rowGap={4}>
          {monocolorIcons.map((icon, index) => {
            return (
              <GridItem key={index} border="solid 1px #DDD" bg={modeBgColor}>
                <AspectRatio ratio={1}>
                  <Box>{icon}</Box>
                </AspectRatio>
              </GridItem>
            );
          })}
        </SimpleGrid>
      </Container>
    </Container>
  );
}

export default MonocolorIcons;

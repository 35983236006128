import { Container, Divider, useColorModeValue } from '@chakra-ui/react';
import Breadcrumbs from '../../common/Breadcrumbs';
import EventsList from '../../components/client/events/EventsList';
import { EventsListContextProvider } from '../../store/client/EventsListContext';
import EventsIconAnimated from '../../theme/illustrations-animated//EventsIconAnimated';
import EventsIconDarkAnimated from '../../theme/illustrations-animated//EventsIconDarkAnimated';
import EventListHeader from '../../components/client/events/EventListHeader';
import useOrganizationStatus from '../../hooks/useOrganisationStatus';

function EventsListPage() {
  const modeEventsIcon = useColorModeValue(
    <EventsIconAnimated />,
    <EventsIconDarkAnimated />
  );
  const { shouldRender, components } = useOrganizationStatus(modeEventsIcon);

  const breadcrumbLinks = [
    {
      linkName: 'Home',
      linkUrl: '/',
    },
    {
      linkName: 'Events',
      linkUrl: null,
    },
  ];

  return (
    <EventsListContextProvider>
      <Container display="flex" flexDir="column" flex="1" pb={6}>
        <Breadcrumbs breadcrumbLinks={breadcrumbLinks} />
        <EventListHeader />
        <Divider borderColor="primaryBackground" mb={6} />

        {components}

        {shouldRender && <EventsList />}

      </Container>
    </EventsListContextProvider>
  );
}

export default EventsListPage;

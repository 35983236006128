import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  VStack,
  Heading,
  Text,
  Box,
  Stack,
  useToast,
  Input,
  Flex,
} from '@chakra-ui/react';
import CustomToast from '../../common/CustomToast';
import { useContext, useEffect, useState } from 'react';
import { HttpContext } from '../../context/HttpContext';
import appsignal from '../../appsignal';
import { CustomSendIcon } from '../../theme/icons/CustomSendIcon';
import * as yup from 'yup';

const SendInvitationModal = (props) => {
  const { fetchData } = props;
  const { authAxios } = useContext(HttpContext);
  const toast = useToast();
  const [inviteeEmail, setInviteeEmail] = useState('');
  const [inviteSent, setInviteSent] = useState(false);
  const [emailError, setEmailError] = useState('');

  const emailSchema = yup.object().shape({
    email: yup
      .string()
      .email('Please enter a valid email address')
      .required('Email is required'),
  });

  const validateEmail = async (email) => {
    try {
      await emailSchema.validate({ email });
      setEmailError('');
      return true;
    } catch (err) {
      setEmailError(err.message);
      return false;
    }
  };

  const onEmailChange = async (e) => {
    const email = e.target.value;
    setInviteeEmail(email);
    await validateEmail(email);
  };

  const onSubmit = async () => {
    const isValid = await validateEmail(inviteeEmail);
    if (!isValid) {
      toast({
        render: (props) => (
          <CustomToast
            status="error"
            title="Invalid Email"
            description={emailError}
            onClose={props.onClose}
          />
        ),
      });
      return;
    }

    try {
      await authAxios.post(`accounts/invitation`, {
        invitation: {
          email: inviteeEmail,
        },
      });
      setInviteSent(true);
      props.onClose();
      toast({
        render: (props) => (
          <CustomToast
            status="success"
            title="Invitation sent."
            description="You have successfully sent an invitation."
            onClose={props.onClose}
            id={props.id}
          />
        ),
      });
    } catch (onError) {
      appsignal.sendError(onError);
      const errors = onError.response.data.errors;
      if (errors) {
        errors.forEach((error) => {
          toast({
            render: (props) => (
              <CustomToast
                status="error"
                title={error}
                description=""
                onClose={props.onClose}
              />
            ),
          });
        });
      }
    }
  };

  useEffect(() => {
    if (inviteSent) {
      fetchData({ inviteSent: true });
    }
    setInviteSent(false);
  }, [fetchData, inviteSent]);

  return (
    <Modal onClose={props.onClose} isOpen={props.isOpen} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Flex direction="column" align="center" gap={3} pt={3}>
            <Box mb={2}>
              <CustomSendIcon color="#EC675A" width="50px" height="50px" />
            </Box>
            <Heading fontSize={{ base: '24px', lg: '32px' }} lineHeight="130%">
              New invitation
            </Heading>
          </Flex>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack spacing="20px" alignItems="flex-start">
            <Text textAlign="center" fontSize="18px" px={2}>
              Enter the email of the person you want to invite to your team.
            </Text>
            <Box w="full">
              <Input
                variant="filled"
                id="inviteeEmail"
                type="text"
                placeholder="Enter email"
                w="full"
                onChange={onEmailChange}
                mb={2}
                isInvalid={!!emailError}
              />
              {emailError && (
                <Text pl={4} color="red.500" fontSize="sm">
                  {emailError}
                </Text>
              )}
            </Box>
          </VStack>
        </ModalBody>
        <ModalFooter>
          <Stack
            spacing="10px"
            direction={{ base: 'column', sm: 'row' }}
            width={{ base: 'full', sm: 'auto' }}
          >
            <Button
              onClick={props.onClose}
              alt="Cancel"
              variant="secondary"
              width={{ base: 'full', sm: 'auto' }}
            >
              Cancel
            </Button>
            <Button
              alt="Send"
              width={{ base: 'full', sm: 'auto' }}
              onClick={onSubmit}
              isDisabled={!!emailError}
            >
              Send
            </Button>
          </Stack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default SendInvitationModal;

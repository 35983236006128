import { useEffect, useState, useContext } from 'react';
import { HttpContext } from '../../../context/HttpContext';
import {
  Box,
  Text,
  Divider,
  VStack,
  Spinner,
  Link,
  useColorModeValue,
} from '@chakra-ui/react';
import ReaderField from './ReaderField';

const ReaderConfigurationDetails = ({ entityId }) => {
  const { authAxios } = useContext(HttpContext);
  const [isLoading, setIsLoading] = useState(true);
  const [configurationFields, setConfigurationFields] = useState([]);
  const modeBoxBg = useColorModeValue('gradient', 'secondaryDark');
  const modeTextBg = useColorModeValue('quaternaryBackground', 'primaryDark06');
  const modeBorderColor = useColorModeValue('primaryBackground', 'primaryDark');
  const modeFormControlBg = useColorModeValue('white', 'primaryDark08');

  useEffect(() => {
    const fetchReaderConfiguration = async () => {
      const response = await authAxios.get(
        `api/v1/entities/${entityId}/readerConfiguration`
      );
      const {
        applePassTypeId,
        googleCollectorId,
        googleKeyVersion,
        nfcPrivateKey,
        readerConfigurationApiKey,
      } = response?.data?.data?.attributes ?? {};

      setConfigurationFields([
        { name: 'Apple Pass Type ID', value: applePassTypeId },
        { name: 'Google Collector ID', value: googleCollectorId },
        { name: 'Google Key Version', value: googleKeyVersion },
        { name: 'NFC Private Key', value: nfcPrivateKey },
        {
          name: 'Reader Configuration API Key',
          value: readerConfigurationApiKey,
        },
      ]);
    };
    if (entityId) {
      fetchReaderConfiguration();
      setIsLoading(false);
    }
  }, [entityId, authAxios]);

  if (isLoading) {
    return (
      <Spinner
        width="52px"
        height="52px"
        thickness="4px"
        speed="0.65s"
        emptyColor="quinaryBackground"
        mt={4}
        mx="auto"
      />
    );
  }

  return (
    <VStack>
      <Box
        className="autofillForDarkBg"
        w="full"
        borderRadius="15px"
        overflow="hidden"
      >
        <Box className="primaryBoxShadow" bg={modeBoxBg} borderRadius="15px">
          <Text
            fontSize="18px"
            textStyle="headingFamilyMedium"
            p="14px 14px 14px 24px"
            bg={modeTextBg}
          >
            Reader Configuration Details
          </Text>
          <Divider borderColor={modeBorderColor} />
          <Text p="14px 14px 14px 24px" bg={modeFormControlBg}>
            You can use these configuration details to set up your readers. If
            you need help setting up a VTAP Cloud Reader, please refer to{' '}
            <Link
              href="https://docs.google.com/document/d/1kEA_nPeiLUwTPnkkweqkBCfw3N1MXFSY5fdllbwY1H8/edit?usp=sharing"
              textStyle="bodyBold"
              color="secondaryDark"
            >
              this guide.
            </Link>{' '}
          </Text>
          <Divider borderColor={modeBorderColor} />

          {configurationFields &&
            configurationFields.map((field, index) => {
              return <ReaderField field={field} index={index} key={index} />;
            })}
        </Box>
      </Box>
    </VStack>
  );
};

export default ReaderConfigurationDetails;

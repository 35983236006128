import { motion } from 'framer-motion';
import { Icon } from '@chakra-ui/react';

const EmptyArtistAnimated = () => {
  return (
    <Icon viewBox="0 0 125 114" width="125px" height="114px">
      <circle cx="60" cy="61" r="53" fill="#D1D1D1" />
      <motion.rect
        x="25"
        y="16"
        width="93"
        height="26"
        rx="6"
        fill="white"
        animate={{
          opacity: [0, 1],
        }}
        transition={{ duration: 1, ease: 'easeInOut' }}
      />
      <motion.rect
        x="25"
        y="80"
        width="93"
        height="26"
        rx="6"
        fill="white"
        animate={{
          opacity: [0, 1],
        }}
        transition={{ duration: 1, ease: 'easeInOut' }}
      />
      <motion.rect
        y="48"
        width="98"
        height="26"
        rx="6"
        fill="white"
        animate={{
          opacity: [0, 1],
        }}
        transition={{ duration: 1, ease: 'easeInOut' }}
      />
      <motion.rect
        x="26"
        y="11"
        width="11"
        height="2"
        rx="1"
        transform="rotate(-90 26 11)"
        fill="#3B4851"
        fillOpacity="0.6"
        animate={{
          opacity: [0, 1],
        }}
        transition={{ delay: 1, duration: 1, ease: 'easeInOut' }}
      />
      <motion.rect
        x="20"
        y="19"
        width="11"
        height="2"
        rx="1"
        transform="rotate(180 20 19)"
        fill="#3B4851"
        fillOpacity="0.6"
        animate={{
          opacity: [0, 1],
        }}
        transition={{ delay: 1, duration: 1, ease: 'easeInOut' }}
      />
      <motion.path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M118.22 57.0653C117.927 57.3582 117.927 57.833 118.22 58.1259C118.513 58.4188 118.987 58.4188 119.28 58.1259L121.173 56.2335L123.065 58.1259C123.358 58.4188 123.833 58.4188 124.126 58.1259C124.419 57.833 124.419 57.3582 124.126 57.0653L122.233 55.1728L124.126 53.2803C124.419 52.9874 124.419 52.5126 124.126 52.2197C123.833 51.9268 123.358 51.9268 123.065 52.2197L121.173 54.1121L119.28 52.2197C118.987 51.9268 118.513 51.9268 118.22 52.2197C117.927 52.5126 117.927 52.9874 118.22 53.2803L120.112 55.1728L118.22 57.0653Z"
        fill="#FF8477"
        fillOpacity="0.8"
        animate={{
          opacity: [0, 1],
          x: [-10, 0],
          scale: [0.5, 1],
        }}
        transition={{ delay: 0.5, duration: 1, ease: 'easeInOut' }}
      />
      <motion.path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M54.1577 4.63529C53.9475 4.84549 53.9475 5.1863 54.1577 5.39651C54.3679 5.60672 54.7088 5.60672 54.919 5.39651L56.2771 4.03835L57.6353 5.39652C57.8455 5.60672 58.1863 5.60672 58.3965 5.39652C58.6067 5.18631 58.6067 4.8455 58.3965 4.63529L57.0383 3.27713L58.3966 1.91888C58.6068 1.70867 58.6068 1.36786 58.3966 1.15765C58.1864 0.947448 57.8456 0.947448 57.6354 1.15765L56.2771 2.5159L54.9189 1.15766C54.7087 0.947454 54.3679 0.947454 54.1577 1.15766C53.9474 1.36787 53.9474 1.70868 54.1577 1.91889L55.5159 3.27713L54.1577 4.63529Z"
        fill="#3B4851"
        fillOpacity="0.6"
        animate={{
          opacity: [0, 1],
          y: [10, 0],
          scale: [0.5, 1],
        }}
        transition={{ delay: 0.1, duration: 1, ease: 'easeInOut' }}
      />
      <motion.rect
        x="12"
        y="92.307"
        width="7.50525"
        height="1.39773"
        rx="0.698864"
        transform="rotate(-45 12 92.307)"
        fill="#3B4851"
        fillOpacity="0.6"
        animate={{
          opacity: [0, 1],
          x: [10, 0],
          y: [-10, 0],
          rotate: [-45, -45],
          scale: [0.5, 1],
        }}
        transition={{ delay: 0.3, duration: 1, ease: 'easeInOut' }}
      />
      <motion.rect
        x="20.8613"
        y="13.2998"
        width="11.4"
        height="1.9"
        rx="0.95"
        transform="rotate(-135 20.8613 13.2998)"
        fill="#3B4851"
        fillOpacity="0.6"
        animate={{
          opacity: [0, 1],
        }}
        transition={{ delay: 1, duration: 1, ease: 'easeInOut' }}
      />
      <motion.path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.5 35.6C5.10751 35.6 5.6 35.1075 5.6 34.5C5.6 33.8925 5.10751 33.4 4.5 33.4C3.89249 33.4 3.4 33.8925 3.4 34.5C3.4 35.1075 3.89249 35.6 4.5 35.6ZM4.5 37C5.88071 37 7 35.8807 7 34.5C7 33.1193 5.88071 32 4.5 32C3.11929 32 2 33.1193 2 34.5C2 35.8807 3.11929 37 4.5 37Z"
        fill="#FF8477"
        animate={{
          opacity: [0, 1],
          x: [10, 0],
          y: [10, 0],
          scale: [0.5, 1],
        }}
        transition={{ delay: 0.3, duration: 1, ease: 'easeInOut' }}
      />
      <motion.path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M112 73C112.552 73 113 72.5523 113 72C113 71.4477 112.552 71 112 71C111.448 71 111 71.4477 111 72C111 72.5523 111.448 73 112 73ZM112 75C113.657 75 115 73.6569 115 72C115 70.3431 113.657 69 112 69C110.343 69 109 70.3431 109 72C109 73.6569 110.343 75 112 75Z"
        fill="#3B4851"
        fillOpacity="0.6"
        animate={{
          opacity: [0, 1],
          x: [-10, 0],
          scale: [0.5, 1],
        }}
        transition={{ delay: 0.5, duration: 1, ease: 'easeInOut' }}
      />
      <motion.rect
        x="27"
        y="18"
        width="22"
        height="22"
        rx="4"
        fill="url(#paint0_linear_235_424)"
        animate={{
          opacity: [0, 1],
        }}
        transition={{ duration: 1, ease: 'easeInOut' }}
      />
      <motion.path
        d="M36.2724 31.4883H37.7896L37.8063 30.9042C37.8063 30.1365 38.2903 29.9362 38.9746 29.7192C39.661 29.524 40.2652 29.1106 40.6958 28.5415C41.1264 27.9724 41.36 27.2785 41.3612 26.5649C41.3763 26.1292 41.3007 25.6951 41.1393 25.2901C40.9778 24.8851 40.7341 24.5181 40.4235 24.2121C40.1129 23.9062 39.7421 23.6681 39.3347 23.5129C38.9273 23.3577 38.4921 23.2887 38.0567 23.3104C37.5965 23.3004 37.1391 23.3839 36.7122 23.556C36.2853 23.728 35.8978 23.985 35.5731 24.3113C35.2485 24.6377 34.9936 25.0265 34.8238 25.4543C34.6539 25.8821 34.5728 26.34 34.5852 26.8001H36.1541C36.1481 26.546 36.1929 26.2933 36.2859 26.0568C36.3788 25.8203 36.518 25.6048 36.6954 25.4228C36.8727 25.2408 37.0846 25.096 37.3186 24.9969C37.5526 24.8979 37.8041 24.8465 38.0582 24.8459C38.2874 24.8326 38.5167 24.8688 38.7306 24.952C38.9445 25.0353 39.138 25.1636 39.298 25.3282C39.4579 25.4929 39.5805 25.6901 39.6574 25.9063C39.7344 26.1226 39.7638 26.3529 39.7438 26.5816C39.7337 26.9517 39.605 27.3088 39.3767 27.6003C39.1483 27.8917 38.8324 28.1021 38.4754 28.2005L37.9247 28.419C37.3855 28.5489 36.9147 28.8766 36.6056 29.3371C36.2965 29.7977 36.1716 30.3575 36.2557 30.9057L36.2724 31.4883ZM37.0234 35.0948C37.172 35.1001 37.3201 35.0755 37.459 35.0226C37.598 34.9696 37.7248 34.8894 37.8322 34.7865C37.9396 34.6837 38.0252 34.5604 38.0841 34.4239C38.143 34.2874 38.1739 34.1405 38.175 33.9918C38.1737 33.8433 38.1426 33.6965 38.0837 33.5602C38.0247 33.4238 37.939 33.3007 37.8317 33.198C37.7243 33.0954 37.5975 33.0152 37.4587 32.9624C37.3199 32.9095 37.1719 32.885 37.0234 32.8903C36.875 32.885 36.727 32.9095 36.5882 32.9624C36.4494 33.0152 36.3225 33.0954 36.2152 33.198C36.1079 33.3007 36.0222 33.4238 35.9632 33.5602C35.9042 33.6965 35.8732 33.8433 35.8718 33.9918C35.873 34.1405 35.9039 34.2874 35.9628 34.4239C36.0217 34.5604 36.1073 34.6837 36.2147 34.7865C36.322 34.8894 36.4489 34.9696 36.5878 35.0226C36.7267 35.0755 36.8749 35.1001 37.0234 35.0948Z"
        fill="white"
        animate={{
          opacity: [0, 1],
        }}
        transition={{ duration: 1, ease: 'easeInOut' }}
      />
      <motion.rect
        x="27"
        y="82"
        width="22"
        height="22"
        rx="4"
        fill="url(#paint1_linear_235_424)"
        animate={{
          opacity: [0, 1],
        }}
        transition={{ duration: 1, ease: 'easeInOut' }}
      />
      <motion.path
        d="M36.2724 95.4883H37.7896L37.8063 94.9042C37.8063 94.1365 38.2903 93.9362 38.9746 93.7192C39.661 93.524 40.2652 93.1106 40.6958 92.5415C41.1264 91.9724 41.36 91.2785 41.3612 90.5649C41.3763 90.1292 41.3007 89.6951 41.1393 89.2901C40.9778 88.8851 40.7341 88.5181 40.4235 88.2121C40.1129 87.9062 39.7421 87.6681 39.3347 87.5129C38.9273 87.3577 38.4921 87.2887 38.0567 87.3104C37.5965 87.3004 37.1391 87.3839 36.7122 87.556C36.2853 87.728 35.8978 87.985 35.5731 88.3113C35.2485 88.6377 34.9936 89.0265 34.8238 89.4543C34.6539 89.8821 34.5728 90.34 34.5852 90.8001H36.1541C36.1481 90.546 36.1929 90.2933 36.2859 90.0568C36.3788 89.8203 36.518 89.6048 36.6954 89.4228C36.8727 89.2408 37.0846 89.096 37.3186 88.9969C37.5526 88.8979 37.8041 88.8465 38.0582 88.8459C38.2874 88.8326 38.5167 88.8688 38.7306 88.952C38.9445 89.0353 39.138 89.1636 39.298 89.3282C39.4579 89.4929 39.5805 89.6901 39.6574 89.9063C39.7344 90.1226 39.7638 90.3529 39.7438 90.5816C39.7337 90.9517 39.605 91.3088 39.3767 91.6003C39.1483 91.8917 38.8324 92.1021 38.4754 92.2005L37.9247 92.419C37.3855 92.5489 36.9147 92.8766 36.6056 93.3371C36.2965 93.7977 36.1716 94.3575 36.2557 94.9057L36.2724 95.4883ZM37.0234 99.0948C37.172 99.1001 37.3201 99.0755 37.459 99.0226C37.598 98.9696 37.7248 98.8894 37.8322 98.7865C37.9396 98.6837 38.0252 98.5604 38.0841 98.4239C38.143 98.2874 38.1739 98.1405 38.175 97.9918C38.1737 97.8433 38.1426 97.6965 38.0837 97.5602C38.0247 97.4238 37.939 97.3007 37.8317 97.198C37.7243 97.0954 37.5975 97.0152 37.4587 96.9624C37.3199 96.9095 37.1719 96.885 37.0234 96.8903C36.875 96.885 36.727 96.9095 36.5882 96.9624C36.4494 97.0152 36.3225 97.0954 36.2152 97.198C36.1079 97.3007 36.0222 97.4238 35.9632 97.5602C35.9042 97.6965 35.8732 97.8433 35.8718 97.9918C35.873 98.1405 35.9039 98.2874 35.9628 98.4239C36.0217 98.5604 36.1073 98.6837 36.2147 98.7865C36.322 98.8894 36.4489 98.9696 36.5878 99.0226C36.7267 99.0755 36.8749 99.1001 37.0234 99.0948Z"
        fill="white"
        animate={{
          opacity: [0, 1],
        }}
        transition={{ duration: 1, ease: 'easeInOut' }}
      />
      <motion.rect
        x="2"
        y="50"
        width="22"
        height="22"
        rx="4"
        fill="url(#paint2_linear_235_424)"
        animate={{
          opacity: [0, 1],
        }}
        transition={{ duration: 1, ease: 'easeInOut' }}
      />
      <motion.path
        d="M11.2724 63.4883H12.7896L12.8063 62.9042C12.8063 62.1365 13.2903 61.9362 13.9746 61.7192C14.661 61.524 15.2652 61.1106 15.6958 60.5415C16.1264 59.9724 16.36 59.2785 16.3612 58.5649C16.3763 58.1292 16.3007 57.6951 16.1393 57.2901C15.9778 56.8851 15.7341 56.5181 15.4235 56.2121C15.1129 55.9062 14.7421 55.6681 14.3347 55.5129C13.9273 55.3577 13.4921 55.2887 13.0567 55.3104C12.5965 55.3004 12.1391 55.3839 11.7122 55.556C11.2853 55.728 10.8978 55.985 10.5731 56.3113C10.2485 56.6377 9.99358 57.0265 9.82376 57.4543C9.65394 57.8821 9.57279 58.34 9.58523 58.8001H11.1541C11.1481 58.546 11.1929 58.2933 11.2859 58.0568C11.3788 57.8203 11.518 57.6048 11.6954 57.4228C11.8727 57.2408 12.0846 57.096 12.3186 56.9969C12.5526 56.8979 12.8041 56.8465 13.0582 56.8459C13.2874 56.8326 13.5167 56.8688 13.7306 56.952C13.9445 57.0353 14.138 57.1636 14.298 57.3282C14.4579 57.4929 14.5805 57.6901 14.6574 57.9063C14.7344 58.1226 14.7638 58.3529 14.7438 58.5816C14.7337 58.9517 14.605 59.3088 14.3767 59.6003C14.1483 59.8917 13.8324 60.1021 13.4754 60.2005L12.9247 60.419C12.3855 60.5489 11.9147 60.8766 11.6056 61.3371C11.2965 61.7977 11.1716 62.3575 11.2557 62.9057L11.2724 63.4883ZM12.0234 67.0948C12.172 67.1001 12.3201 67.0755 12.459 67.0226C12.598 66.9696 12.7248 66.8894 12.8322 66.7865C12.9396 66.6837 13.0252 66.5604 13.0841 66.4239C13.143 66.2874 13.1739 66.1405 13.175 65.9918C13.1737 65.8433 13.1426 65.6965 13.0837 65.5602C13.0247 65.4238 12.939 65.3007 12.8317 65.198C12.7243 65.0954 12.5975 65.0152 12.4587 64.9624C12.3199 64.9095 12.1719 64.885 12.0234 64.8903C11.875 64.885 11.727 64.9095 11.5882 64.9624C11.4494 65.0152 11.3225 65.0954 11.2152 65.198C11.1079 65.3007 11.0222 65.4238 10.9632 65.5602C10.9042 65.6965 10.8732 65.8433 10.8718 65.9918C10.873 66.1405 10.9039 66.2874 10.9628 66.4239C11.0217 66.5604 11.1073 66.6837 11.2147 66.7865C11.322 66.8894 11.4489 66.9696 11.5878 67.0226C11.7267 67.0755 11.8749 67.1001 12.0234 67.0948Z"
        fill="white"
        animate={{
          opacity: [0, 1],
        }}
        transition={{ duration: 1, ease: 'easeInOut' }}
      />
      <motion.rect
        x="30"
        y="54"
        width="24"
        height="4"
        rx="2"
        fill="#3B4851"
        fillOpacity="0.8"
        animate={{
          opacity: [0, 1],
        }}
        transition={{ duration: 1, ease: 'easeInOut' }}
      />
      <motion.rect
        x="30"
        y="64"
        width="39"
        height="4"
        rx="2"
        fill="#3B4851"
        fillOpacity="0.6"
        animate={{
          opacity: [0, 1],
        }}
        transition={{ duration: 1, ease: 'easeInOut' }}
      />
      <motion.rect
        x="55"
        y="86"
        width="33"
        height="4"
        rx="2"
        fill="#3B4851"
        fillOpacity="0.8"
        animate={{
          opacity: [0, 1],
        }}
        transition={{ duration: 1, ease: 'easeInOut' }}
      />
      <motion.rect
        x="55"
        y="96"
        width="37"
        height="4"
        rx="2"
        fill="#3B4851"
        fillOpacity="0.6"
        animate={{
          opacity: [0, 1],
        }}
        transition={{ duration: 1, ease: 'easeInOut' }}
      />
      <motion.rect
        x="55"
        y="22"
        width="32"
        height="4"
        rx="2"
        fill="#3B4851"
        fillOpacity="0.8"
        animate={{
          opacity: [0, 1],
        }}
        transition={{ duration: 1, ease: 'easeInOut' }}
      />
      <motion.rect
        x="55"
        y="32"
        width="29"
        height="4"
        rx="2"
        fill="#3B4851"
        fillOpacity="0.6"
        animate={{
          opacity: [0, 1],
        }}
        transition={{ duration: 1, ease: 'easeInOut' }}
      />
      <defs>
        <linearGradient
          id="paint0_linear_235_424"
          x1="27.5677"
          y1="40"
          x2="46.7442"
          y2="37.1756"
          gradientUnits="userSpaceOnUse"
          animate={{
            opacity: [0, 1],
          }}
          transition={{ duration: 1, ease: 'easeInOut' }}
        >
          <stop stopColor="#FF8477" />
          <stop offset="1" stopColor="#EC6759" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_235_424"
          x1="27.5677"
          y1="104"
          x2="46.7442"
          y2="101.176"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF8477" />
          <stop offset="1" stopColor="#EC6759" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_235_424"
          x1="2.56774"
          y1="72"
          x2="21.7442"
          y2="69.1756"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF8477" />
          <stop offset="1" stopColor="#EC6759" />
        </linearGradient>
      </defs>
    </Icon>
  );
};

export default EmptyArtistAnimated;

import appsignal from '../../appsignal';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Heading,
  Stack,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  useToast,
} from '@chakra-ui/react';

import Cropper from 'react-easy-crop';
import classes from './Crop.module.css';
import { useState, useEffect, useContext } from 'react';
import { getCroppedImg } from '../../utils/base64';
import CustomToast from '../../common/CustomToast';
import { MeContext } from '../../context/MeContext';

const CropModal = ({
  onClose,
  isOpen,
  image,
  updateImage,
  title,
  parent,
  droppedImage,
  saveDroppedImage,
}) => {
  const { aspect, crop, zoom } = image;
  const [initialCroppedArea, setInitialCroppedArea] = useState(undefined);
  const [initialCroppedAreaPixels, setInitialCroppedAreaPixels] =
    useState(undefined);
  const [isSavingCrop, setIsSavingCrop] = useState(false);
  const toast = useToast();
  const meCtx = useContext(MeContext);
  const { watermarkEnabled } = meCtx.state;

  let recalculateCropGridTimeout;

  useEffect(() => {
    return () => {
      clearTimeout(recalculateCropGridTimeout);
    };
  }, [recalculateCropGridTimeout]);

  function onCropChange(crop) {
    updateImage({
      ...image,
      crop,
    });
  }

  /* onCropComplete() will occur each time the user modifies the cropped area,
    which isn't ideal. A better implementation would be getting the blob
    only when the user hits the save button, but this works for now  */
  async function onCropComplete(croppedArea, croppedAreaPixels) {
    // console.log({ croppedArea, croppedAreaPixels });
    // window.localStorage.setItem('croppedArea', JSON.stringify(croppedArea))
    setInitialCroppedArea(croppedArea);
    setInitialCroppedAreaPixels(croppedAreaPixels);
  }

  async function onSave(e) {
    try {
      e.preventDefault();
      setIsSavingCrop(true);

      const croppedImage = await getCroppedImg(
        droppedImage.preview,
        initialCroppedAreaPixels,
        image.mimeType
      );
      saveDroppedImage(croppedImage);

      onClose();
      setIsSavingCrop(false);
    } catch (onError) {
      appsignal.sendError(onError);
      console.log(onError);
      toast({
        render: (props) => (
          <CustomToast
            status="error"
            title="Something went wrong"
            description="Please try again later."
            onClose={props.onClose}
          />
        ),
      });
      setIsSavingCrop(false);
    }
  }

  async function onDiscard(e) {
    try {
      e.preventDefault();
      onClose();
    } catch (onError) {
      appsignal.sendError(onError);
      console.log(onError);
      toast({
        render: (props) => (
          <CustomToast
            status="error"
            title="Something went wrong"
            description="Please try again later."
            onClose={props.onClose}
          />
        ),
      });
    }
  }

  function onZoomChange(zoom) {
    updateImage({
      ...image,
      zoom,
    });
  }

  function onMediaLoadHandler(mediaSize) {
    recalculateCropGridTimeout = setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 1000);
  }

  return (
    <Modal onClose={onClose} isOpen={isOpen} size="xl">
      <ModalOverlay />
      <ModalContent maxW="760px" mx={{ base: '32px', lg: '0' }}>
        <ModalHeader mb={5}>
          <Heading fontSize={{ base: '24px', lg: '32px' }} lineHeight="130%">
            {title ?? `Crop background image`}
          </Heading>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody
          position="relative"
          minH={{ base: 'max(400px, 50vh)', '2xl': '528px' }}
          p="0"
        >
          <div className={classes.Cropper}>
            <div className={classes.CropContainer}>
              <Cropper
                image={droppedImage?.preview}
                crop={crop}
                zoom={zoom}
                aspect={aspect}
                onCropChange={onCropChange}
                onCropComplete={onCropComplete}
                onZoomChange={onZoomChange}
                initialCroppedAreaPercentages={initialCroppedArea}
                initialCroppedAreaPixels={initialCroppedAreaPixels}
                onMediaLoaded={onMediaLoadHandler}
                objectFit={
                  parent === 'icon_logo' || parent === 'thumbnail_image'
                    ? 'contain'
                    : 'horizontal-cover'
                }
                restrictPosition={
                  parent === 'banner_image' || parent === 'google_banner_image'
                }
              />
            </div>
            <div className={classes.CropControls}>
              <Slider
                min={
                  parent === 'banner_image' || parent === 'google_banner_image'
                    ? 1
                    : 0.1
                }
                max={
                  (parent === 'banner_image' ||
                    parent === 'google_banner_image') &&
                  watermarkEnabled
                    ? 1.5
                    : 3
                }
                step={
                  parent === 'banner_image' || parent === 'google_banner_image'
                    ? 0.1
                    : 0.01
                }
                value={zoom}
                onChange={(val) => onZoomChange(val)}
              >
                <SliderTrack>
                  <SliderFilledTrack />
                </SliderTrack>
                <SliderThumb />
              </Slider>
            </div>
          </div>
        </ModalBody>
        <ModalFooter
          justifyContent="center"
          borderTopWidth="1px"
          borderTopColor="primaryBackground"
        >
          <Stack
            spacing="10px"
            direction={{ base: 'column-reverse', sm: 'row' }}
            width={{ base: 'full', sm: 'auto' }}
            mt={6}
          >
            {/*<Button*/}
            {/*  onClick={onReset}*/}
            {/*  variant="secondary"*/}
            {/*  width={{ base: 'full', sm: 'auto' }}*/}
            {/*  size="sm"*/}
            {/*>*/}
            {/*  Reset*/}
            {/*</Button>*/}
            <Button
              onClick={onDiscard}
              variant="secondary"
              width={{ base: 'full', sm: 'auto' }}
              size="sm"
              disabled={isSavingCrop}
            >
              Discard
            </Button>
            <Button
              alt="Save"
              width={{ base: 'full', sm: 'auto' }}
              size="sm"
              onClick={onSave}
              isLoading={isSavingCrop}
            >
              Save
            </Button>
          </Stack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default CropModal;

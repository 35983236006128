export function getErrorResponsePayload(onError) {
  const data = onError?.response?.data || null;
  if (data) {
    const { message, code, errors } = data;
    return {
      code,
      message,
      errors: errors?.children || null,
    };
  }

  return {
    code: null,
    message: null,
    errors: null,
  };
}

export function getErrors(error) {
  return error?.response?.data?.errors || null;
}

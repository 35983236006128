import { Heading, Center, VStack, Text, useColorModeValue } from '@chakra-ui/react';
import CardForm from '../../components/common/CardForm';
import { FORM_PADDING_TOP_XL } from '../../utils/consts';
import ThankYouIconAnimated from '../../theme/illustrations-animated//ThankYouIconAnimated';
import { useLocation } from 'react-router';

function ThankYouPage() {
  const modeText = useColorModeValue('secondaryDark08', '#DDDDDD');
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get('token');

  return (
    <Center pt={FORM_PADDING_TOP_XL} pb="10px">
      <CardForm>
        <VStack spacing={2} alignItems="flex-start">
          <Center mb={10} alignSelf="center">
            <ThankYouIconAnimated />
          </Center>
          <Heading size="2xl" textAlign={!token && 'center'}>
            Thank you{!token ? ' for your enquiry' : ''}!
          </Heading>
          <VStack spacing={5} color={modeText}>
            {token ? (
              <>
                <Text textStyle="bodyRoman">
                  Please click the verification link we sent to the email address
                  provided.
                </Text>
                <Text textStyle="bodyRoman">
                  Make sure to check your spam folder if you can't see it in your inbox.
                </Text>
              </>
            ) : (
              <Text mt={3} textStyle="bodyRoman" textAlign="center">
                Our team will reach out to you shortly. If your enquiry is urgent please
                call us on +44 207 933 3157.
              </Text>
            )}
          </VStack>
        </VStack>
      </CardForm>
    </Center>
  );
}

export default ThankYouPage;

import { createContext, useState } from 'react';
import moment from 'moment';

const EventsContext = createContext({
  eventState: {
    name: '',
    uuid: '',
    venueName: '',
    startDate: '',
    startTime: '',
    endDate: '',
    endTime: '',
    timeZone: '',
    status: null,
  },
  updateAllEventData: () => {},
});

export const EventsContextProvider = ({ children }) => {
  const [eventState, setEventState] = useState({
    name: '',
    uuid: '',
    venueName: '',
    startDate: '',
    startTime: '',
    endDate: '',
    endTime: '',
    timeZone: '',
    status: null,
  });

  const [firstLoad, setFirstLoad] = useState(null);

  const updateFirstLoad = (value) => {
    setFirstLoad(value);
  };

  const updateAllEventDataHandler = (data) => {
    const { name, uuid, venueName, startTime, endTime, timeZone, status } = data;

    // format the incoming start date/time, end date/time, gate date/time for datepicker and timepicker correct display
    let startDate = moment(startTime).toDate();
    const startTimeHour = moment(startTime).format('hh:mm A');
    let endDate = moment(endTime).toDate();
    const endTimeHour = moment(endTime).format('hh:mm A');

    setEventState({
      name,
      uuid,
      venueName,
      startDate,
      startTime: startTimeHour,
      endDate,
      endTime: endTimeHour,
      timeZone,
      status,
    });
  };

  const context = {
    eventState,
    firstLoad,
    updateAllEventData: updateAllEventDataHandler,
    updateFirstLoad,
  };
  return <EventsContext.Provider value={context}>{children}</EventsContext.Provider>;
};

export default EventsContext;

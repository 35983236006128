import {
  SimpleGrid,
  GridItem,
  Container,
  Button,
  Box,
  useColorModeValue,
} from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';

function StaticPages() {
  return (
    <Container
      bg={useColorModeValue('white', 'primaryDark')}
      borderBottom="solid 1px #DDD"
    >
      <Container maxW="1180px" px="0" py={6}>
        <Box fontSize="24px" my={4}>
          Auth Pages
        </Box>
        <SimpleGrid w="full" columns={{ base: 1, sm: 2, md: 4 }} columnGap={4} rowGap={4}>
          <GridItem>
            <Button alt="Reset Password" size="sm" as={RouterLink} to="/reset-password">
              Reset Password
            </Button>
          </GridItem>
          <GridItem>
            <Button alt="Thank You" size="sm" as={RouterLink} to="/thank-you">
              Thank You
            </Button>
          </GridItem>
          <GridItem>
            <Button alt="Verifying Your Email" size="sm" as={RouterLink} to="/confirm">
              Verifying Your Email
            </Button>
          </GridItem>
        </SimpleGrid>

        <Box fontSize="24px" mt={12} mb={4}>
          Client Pages
        </Box>
        <SimpleGrid w="full" columns={{ base: 1, sm: 2, md: 4 }} columnGap={4} rowGap={4}>
          <GridItem>
            <Button alt="Dashboard" size="sm" as={RouterLink} to="/dashboard">
              Dashboard
            </Button>
          </GridItem>
          <GridItem>
            <Button alt="Passes" size="sm" as={RouterLink} to="/passes">
              Passes
            </Button>
          </GridItem>
          <GridItem>
            <Button alt="Pass Templates" size="sm" as={RouterLink} to="/pass-templates">
              Pass Templates
            </Button>
          </GridItem>
          <GridItem>
            <Button
              alt="Account Settings"
              size="sm"
              as={RouterLink}
              to="/account-settings"
            >
              Account Settings
            </Button>
          </GridItem>
          <GridItem>
            <Button
              alt="Create Pass Template"
              size="sm"
              as={RouterLink}
              to="/pass-templates/create"
            >
              Create Pass Template
            </Button>
          </GridItem>
        </SimpleGrid>

        <Box fontSize="24px" mt={12} mb={4}>
          Admin Pages
        </Box>
        <SimpleGrid w="full" columns={{ base: 1, sm: 2, md: 4 }} columnGap={4} rowGap={4}>
          <GridItem>
            <Button size="sm" as={RouterLink} to="/admin-login" variant="secondary">
              Admin Login
            </Button>
          </GridItem>
          <GridItem>
            <Button size="sm" as={RouterLink} to="/dashboard-admin" variant="secondary">
              Dashboard
            </Button>
          </GridItem>
          <GridItem>
            <Button size="sm" as={RouterLink} to="/clients" variant="secondary">
              Client List
            </Button>
          </GridItem>
          <GridItem>
            <Button size="sm" as={RouterLink} to="/client" variant="secondary">
              Client Single
            </Button>
          </GridItem>
          <GridItem>
            <Button
              size="sm"
              as={RouterLink}
              to="/pass-templates-admin"
              variant="secondary"
            >
              Pass templates
            </Button>
          </GridItem>
          <GridItem>
            <Button
              size="sm"
              as={RouterLink}
              to="/pass-templates-admin/1/view"
              variant="secondary"
            >
              Pass template view
            </Button>
          </GridItem>
        </SimpleGrid>
      </Container>
    </Container>
  );
}

export default StaticPages;

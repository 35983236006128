import { useContext, useEffect, useState } from 'react';
import {
  Container,
  Divider,
  Flex,
  Heading,
  SimpleGrid,
} from '@chakra-ui/react';
import Breadcrumbs from '../../common/Breadcrumbs';
import { MeContext } from '../../context/MeContext';
import ReaderConfigSettings from '../../components/client/readers/ReaderConfigSettings';
import EmptyState from '../../common/EmptyState';
import ReaderIconAnimated from '../../theme/illustrations-animated//ReaderIconAnimated';
import ReaderConfigurationDetails from '../../components/client/readers/ReaderConfigurationDetails';

const ReaderConfigurationPage = () => {
  const meCtx = useContext(MeContext);
  const { filterEntity } = meCtx.state;
  const multipleEntities = meCtx?.state?.entities?.length > 1;

  const entityId = multipleEntities
    ? filterEntity
      ? filterEntity.uuid
      : ''
    : meCtx?.state?.entities?.[0]?.uuid;

  const breadcrumbLinks = [
    {
      linkName: 'Home',
      linkUrl: '/',
    },
    {
      linkName: 'Readers',
      linkUrl: '/readers',
    },
    {
      linkName: 'Reader Configuration',
      linkUrl: '/reader-configuration',
    },
  ];

  return (
    <Container display="flex" flexDir="column" flex="1" pb={6}>
      <Breadcrumbs breadcrumbLinks={breadcrumbLinks} />
      <Flex
        flexDirection={{ base: 'column', sm: 'row' }}
        alignItems="center"
        flexWrap="wrap"
        mt="15px"
        py="9px"
        minH="54px"
      >
        <Heading alignSelf="flex-start" mr={2} wordBreak="break-word">
          Reader Configuration
        </Heading>
      </Flex>
      <Divider borderColor="primaryBackground" mb={6} />
      {entityId ? (
        <SimpleGrid
          w="full"
          columns={{ base: 1, md: 1, lg: 1, xl: 2 }}
          columnGap={6}
          rowGap={6}
        >
          <ReaderConfigurationDetails entityId={entityId} />
          <ReaderConfigSettings entityId={entityId} />
        </SimpleGrid>
      ) : (
        <EmptyState
          key="empty-state"
          iconSvgAnimated={<ReaderIconAnimated />}
          heading="No data available."
          text="Please select an entity to view the reader configuration details."
        />
      )}
    </Container>
  );
};

export default ReaderConfigurationPage;

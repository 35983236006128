import { Icon } from '@chakra-ui/react';

export const CustomNfcIcon = (props) => (
  <Icon viewBox="0 0 24 30" {...props}>
    <path
      fill={props.color}
      d="M4.78272 15.0549C4.78272 15.0355 4.78272 15.0174 4.78272 14.9981C4.78272 12.5165 3.73925 10.7301 3.13303 9.90072C2.92408 9.60148 2.77317 9.42993 2.75124 9.40542C2.17082 8.75664 1.17379 8.69989 0.525008 9.28031C-0.123773 9.86073 -0.180525 10.8578 0.399895 11.5065C0.401185 11.5078 0.417953 11.5285 0.446329 11.5633L0.43988 11.5697L0.428271 11.5581C0.43859 11.5685 0.494052 11.6317 0.575311 11.7387C0.910665 12.2198 1.61233 13.4206 1.62781 15.0536C1.61233 16.6813 0.913245 17.8809 0.576601 18.362C0.495342 18.4703 0.43859 18.5335 0.428271 18.5438L0.43988 18.5322L0.446329 18.5387C0.417953 18.5748 0.401185 18.5941 0.399895 18.5954C-0.180525 19.2442 -0.125063 20.2412 0.525008 20.8217C1.17379 21.4021 2.17082 21.3466 2.75124 20.6965C2.77317 20.672 2.92408 20.4992 3.13432 20.1987C3.74054 19.368 4.78143 17.5829 4.78143 15.1039C4.78143 15.0858 4.78143 15.069 4.78143 15.051L4.78272 15.0549Z"
    />
    <path
      fill={props.color}
      d="M7.10437 23.9804C6.77676 23.9804 6.44656 23.8773 6.16538 23.6631C5.48565 23.1446 5.35666 22.1734 5.87517 21.4937C5.88678 21.4769 7.65384 19.0572 7.65384 15.1065C7.65384 11.1557 5.86098 8.48967 5.84293 8.46387C5.34892 7.76607 5.51273 6.8 6.21053 6.30599C6.90703 5.8107 7.8744 5.9758 8.3684 6.67359C8.46514 6.81032 10.7494 10.089 10.7494 15.1077C10.7494 20.1264 8.43418 23.2452 8.33615 23.3742C8.03176 23.7728 7.57129 23.983 7.10566 23.983L7.10437 23.9804Z"
    />
    <path
      fill={props.color}
      d="M12.7822 26.9341C12.4597 26.9341 12.1347 26.8335 11.8574 26.6258C11.1725 26.1138 11.0319 25.1438 11.544 24.4602C11.5659 24.4305 14.1004 20.9067 14.1004 15.1477C14.1004 9.38862 11.5375 5.53334 11.5117 5.49593C11.0242 4.79298 11.1996 3.82948 11.9013 3.34193C12.6042 2.85437 13.5677 3.02979 14.0553 3.73145C14.1829 3.9159 17.196 8.33741 17.196 15.1477C17.196 21.9579 14.1533 26.1396 14.0243 26.3124C13.7199 26.7187 13.2543 26.9341 12.7835 26.9341H12.7822Z"
    />
    <path
      fill={props.color}
      d="M18.3774 30C18.0549 30 17.7299 29.8993 17.4526 29.6917C16.7677 29.1796 16.6271 28.2097 17.1391 27.5261C17.1701 27.4835 20.5417 22.8182 20.5417 15.1902C20.5417 7.56219 17.1404 2.47513 17.1056 2.42483C16.6232 1.72058 16.7999 0.758377 17.5016 0.273404C18.2045 -0.21028 19.1642 -0.0374437 19.6504 0.66422C19.8129 0.898968 23.636 6.51099 23.636 15.1902C23.636 23.8694 19.782 29.159 19.6182 29.3783C19.3138 29.7846 18.8481 30 18.3774 30Z"
    />
  </Icon>
);

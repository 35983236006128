import {
  Text,
  SimpleGrid,
  GridItem,
  Box,
  Container,
  AspectRatio,
  useColorMode,
} from '@chakra-ui/react';

function Colors() {
  const { colorMode } = useColorMode();

  const colors = [
    {
      codeName: 'brand',
      name: 'brand',
      code: colorMode === 'light' ? '#FF8477' : '#FF8477',
    },
    {
      codeName: 'brandDark',
      name: 'brand Dark',
      code: colorMode === 'light' ? '#EC6759' : '#44105C',
    },
    { codeName: 'gradientBrand', name: 'gradient Brand', code: 'gradientBrand' },
    { codeName: 'primaryDark', name: 'primary Dark', code: '#29323A' },
    {
      codeName: 'secondaryDark',
      name: 'secondary Dark',
      code: colorMode === 'light' ? '#3B4851' : '#3B4851',
    },
    {
      codeName: 'secondaryDark08',
      name: 'secondary Dark08',
      code: 'rgba(59, 72, 81, 0.8)',
    },
    {
      codeName: 'secondaryDark06',
      name: 'secondary Dark06',
      code: 'rgba(59, 72, 81, 0.6)',
    },
    {
      codeName: 'primaryBackground',
      name: 'primary Background',
      code: colorMode === 'light' ? '#DDDDDD' : 'rgba(59, 72, 81, 0.8)',
      color: true,
    },
    {
      codeName: 'secondaryBackground',
      name: 'secondary Background',
      code: '#EAEAEA',
      color: 'primaryDark',
    },
    {
      codeName: 'tertiaryBackground',
      name: 'tertiary Background',
      code: '#FBFBFB',
      color: 'primaryDark',
      border: true,
    },
    {
      codeName: 'quaternaryBackground',
      name: 'quaternary Background',
      code: '#F5F3F1',
      color: 'primaryDark',
    },
    {
      codeName: 'quinaryBackground',
      name: 'quinary Background',
      code: colorMode === 'light' ? '#EFEFEF' : '#3B4851',
      color: true,
    },
    {
      codeName: 'gradient',
      name: 'gradient White',
      code: 'gradient',
      color: 'primaryDark',
      border: true,
    },
    { codeName: 'gradientDark', name: 'gradient Dark', code: 'gradientDark' },
    {
      codeName: 'error',
      name: 'error',
      code: colorMode === 'light' ? '#E53E3E' : 'brand',
    },
    { codeName: 'success', name: 'success', code: '#1DBE68' },
    { codeName: 'blueLight', name: 'blue Light', code: '#0DB2F9' },
  ];

  return (
    <Container bg="white" borderBottom="solid 1px #DDD">
      <Container maxW="1180px" px="0" py={6}>
        <SimpleGrid w="full" columns={{ base: 2, md: 4, lg: 6 }} columnGap={6} rowGap={6}>
          {colors.map((color, index) => {
            return (
              <GridItem
                key={index}
                bg={color.codeName}
                color={color.color ? color.color : 'white'}
                border={color.border ? 'solid 1px #DDD' : ''}
              >
                <AspectRatio ratio={1}>
                  <Box
                    p={3}
                    style={{
                      alignItems: 'flex-start',
                      justifyContent: 'flex-end',
                      flexDirection: 'column',
                    }}
                  >
                    <Text w="full" fontSize="16px">
                      {color.name}
                    </Text>
                    <Text as="div" fontSize="14px">
                      {color.code}
                    </Text>
                  </Box>
                </AspectRatio>
              </GridItem>
            );
          })}
        </SimpleGrid>
      </Container>
    </Container>
  );
}

export default Colors;

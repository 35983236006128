import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  VStack,
  Heading,
  Text,
  Box,
  Stack,
} from '@chakra-ui/react';
import { useContext } from 'react';
import PassTemplateContext from '../../store/client/PassTemplateContext';

const ClearFieldsModal = (props) => {
  const {
    isOpen,
    onClose,
    isEdit,
    fieldName,
    editedFieldName,
    editedFields,
    setEditedFields,
    handleUpdateStampKeys,
  } = props;

  const passTemplateCtx = useContext(PassTemplateContext);

  const onClearFields = (e) => {
    e.preventDefault();

    const lowerCaseFieldName = fieldName[0].toLowerCase() + fieldName.slice(1);
    handleUpdateStampKeys(lowerCaseFieldName, '');

    passTemplateCtx[`update${fieldName}Id`]('');
    passTemplateCtx[`update${fieldName}Label`]('');
    passTemplateCtx[`update${fieldName}Value`]('');

    if (isEdit) {
      setEditedFields({
        ...editedFields,
        [`${editedFieldName}_id`]: '',
        [`${editedFieldName}_label`]: '',
        [`${editedFieldName}_default_value`]: '',
      });
    }

    onClose();
  };

  return (
    <Modal onClose={onClose} isOpen={isOpen} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Heading fontSize={{ base: '24px', lg: '32px' }} lineHeight="130%">
            Clear fields
          </Heading>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack spacing="30px" alignItems="flex-start">
            <Box>
              <Text textStyle="bodyFamilyMedium" color="secondaryDark08">
                Are you sure you want to clear these fields?
              </Text>
            </Box>
          </VStack>
        </ModalBody>
        <ModalFooter>
          <Stack
            spacing="10px"
            direction={{ base: 'column', sm: 'row' }}
            width={{ base: 'full', sm: 'auto' }}
          >
            <Button
              onClick={onClose}
              alt="Cancel"
              variant="secondary"
              width={{ base: 'full', sm: 'auto' }}
            >
              Cancel
            </Button>
            <Button
              alt="Delete image"
              width={{ base: 'full', sm: 'auto' }}
              onClick={onClearFields}
            >
              Clear
            </Button>
          </Stack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ClearFieldsModal;

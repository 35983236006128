import {
  Box,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  Show,
  Hide,
  Text,
  useColorModeValue,
  Button,
  useToast,
} from '@chakra-ui/react';
import { useContext } from 'react';
import PassTemplateContext from '../../../store/client/PassTemplateContext';
import { TextareaAutosize } from '../../common/TextareaAutosize';
import { CustomPlusIcon } from '../../../theme/icons/CustomPlusIcon';
import CustomToast from '../../../common/CustomToast';

function BeaconDetails(props) {
  const {
    isEdit,
    activeStep,
    editedFields,
    setEditedFields,
    handleBeaconCountChange,
  } = props;
  const passTemplateCtx = useContext(PassTemplateContext);
  const modeBorderColor = useColorModeValue('primaryBackground', 'primaryDark');
  const modeFormControlBg = useColorModeValue('white', '');
  const modeLabelBg = useColorModeValue('#EFEFEF', 'primaryDark03');
  const toast = useToast();

  const handleRemoveBeacon = () => {
    if (passTemplateCtx.beacons[activeStep]) {
      passTemplateCtx.updateBeacons(
        passTemplateCtx.beacons.filter((beacon, i) => i !== activeStep)
      );
      passTemplateCtx.updateBeaconCount(passTemplateCtx.beacons.length - 1);
      if (isEdit) {
        setEditedFields({
          ...editedFields,
          beacons: true,
        });
      }
    } else {
      passTemplateCtx.updateBeaconCount(passTemplateCtx.beaconCount - 1);
    }
    toast({
      render: (props) => (
        <CustomToast
          status="success"
          title="Beacon removed"
          description=""
          onClose={props.onClose}
        />
      ),
    });
  };

  return (
    <>
      <FormControl bg={modeFormControlBg} borderRadius="0 15px 0 0" isRequired>
        <Flex alignItems="center">
          <Hide below="2xl">
            <Box pl="24px" flexShrink={0} w="240px">
              <FormLabel m={0}>Name</FormLabel>
            </Box>
          </Hide>
          <Box p="14px" w="full" bg={modeLabelBg}>
            <Show below="2xl">
              <FormLabel>Name</FormLabel>
            </Show>
            <Input
              value={passTemplateCtx.beacons[activeStep]?.name}
              variant="filledForDarkBg"
              id="templateName"
              type="text"
              placeholder="Type a beacon name here..."
              onChange={(e) => {
                passTemplateCtx.updateBeacons(
                  passTemplateCtx.beacons.map((beacon, i) =>
                    i === activeStep
                      ? { ...beacon, name: e.target.value }
                      : beacon
                  )
                );
                if (isEdit) {
                  setEditedFields({
                    ...editedFields,
                    beacons: true,
                  });
                }
              }}
              onBlur={() => {
                const value = passTemplateCtx.beacons[activeStep]?.name;
                if (value.trim() === '') {
                  passTemplateCtx.updateBeacons(
                    passTemplateCtx.beacons.map((beacon, i) =>
                      i === activeStep ? { ...beacon, name: '' } : beacon
                    )
                  );
                  if (isEdit) {
                    setEditedFields({
                      ...editedFields,
                      beacons: true,
                    });
                  }
                }
              }}
              autoComplete="off"
            />
            <FormErrorMessage></FormErrorMessage>
          </Box>
        </Flex>
      </FormControl>
      <Divider borderColor={modeBorderColor} />
      <FormControl bg={modeFormControlBg} borderRadius="0 15px 0 0" isRequired>
        <Flex alignItems="center">
          <Hide below="2xl">
            <Box pl="24px" flexShrink={0} w="240px">
              <FormLabel m={0}>Proximity UUID</FormLabel>
            </Box>
          </Hide>
          <Box p="14px" w="full" bg={modeLabelBg}>
            <Show below="2xl">
              <FormLabel>Proximity UUID</FormLabel>
            </Show>
            <Input
              value={passTemplateCtx.beacons[activeStep]?.proximity_uuid}
              variant="filledForDarkBg"
              id="templateName"
              type="text"
              placeholder="Type a proximity UUID here..."
              onChange={(e) => {
                passTemplateCtx.updateBeacons(
                  passTemplateCtx.beacons.map((beacon, i) =>
                    i === activeStep
                      ? { ...beacon, proximity_uuid: e.target.value }
                      : beacon
                  )
                );
                if (isEdit) {
                  setEditedFields({
                    ...editedFields,
                    beacons: true,
                  });
                }
              }}
              onBlur={() => {
                const value =
                  passTemplateCtx.beacons[activeStep]?.proximity_uuid;
                if (value.trim() === '') {
                  passTemplateCtx.updateBeacons(
                    passTemplateCtx.beacons.map((beacon, i) =>
                      i === activeStep
                        ? { ...beacon, proximity_uuid: '' }
                        : beacon
                    )
                  );
                  if (isEdit) {
                    setEditedFields({
                      ...editedFields,
                      beacons: true,
                    });
                  }
                }
              }}
              autoComplete="off"
            />
            <FormErrorMessage></FormErrorMessage>
          </Box>
        </Flex>
      </FormControl>
      <Divider borderColor={modeBorderColor} />
      <FormControl bg={modeFormControlBg} borderRadius="0 15px 0 0">
        <Flex alignItems="center">
          <Hide below="2xl">
            <Box pl="24px" flexShrink={0} w="240px">
              <FormLabel m={0}>Major ID</FormLabel>
            </Box>
          </Hide>
          <Box p="14px" w="full" bg={modeLabelBg}>
            <Show below="2xl">
              <FormLabel>Major ID</FormLabel>
            </Show>
            <Input
              value={passTemplateCtx.beacons[activeStep]?.major}
              variant="filledForDarkBg"
              id="templateName"
              type="text"
              placeholder="Type a major ID here..."
              onChange={(e) => {
                passTemplateCtx.updateBeacons(
                  passTemplateCtx.beacons.map((beacon, i) =>
                    i === activeStep
                      ? { ...beacon, major: e.target.value }
                      : beacon
                  )
                );
                if (isEdit) {
                  setEditedFields({
                    ...editedFields,
                    beacons: true,
                  });
                }
              }}
              onBlur={() => {
                const value = passTemplateCtx.beacons[activeStep]?.major;
                if (value.trim() === '') {
                  passTemplateCtx.updateBeacons(
                    passTemplateCtx.beacons.map((beacon, i) =>
                      i === activeStep ? { ...beacon, major: '' } : beacon
                    )
                  );
                  if (isEdit) {
                    setEditedFields({
                      ...editedFields,
                      beacons: true,
                    });
                  }
                }
              }}
              autoComplete="off"
            />
            <FormErrorMessage></FormErrorMessage>
          </Box>
        </Flex>
      </FormControl>
      <Divider borderColor={modeBorderColor} />
      <FormControl bg={modeFormControlBg} borderRadius="0 15px 0 0">
        <Flex alignItems="center">
          <Hide below="2xl">
            <Box pl="24px" flexShrink={0} w="240px">
              <FormLabel m={0}>Minor ID</FormLabel>
            </Box>
          </Hide>
          <Box p="14px" w="full" bg={modeLabelBg}>
            <Show below="2xl">
              <FormLabel>Minor ID</FormLabel>
            </Show>
            <Input
              value={passTemplateCtx.beacons[activeStep]?.minor}
              variant="filledForDarkBg"
              id="templateName"
              type="text"
              placeholder="Type a minor ID here..."
              onChange={(e) => {
                passTemplateCtx.updateBeacons(
                  passTemplateCtx.beacons.map((beacon, i) =>
                    i === activeStep
                      ? { ...beacon, minor: e.target.value }
                      : beacon
                  )
                );
                if (isEdit) {
                  setEditedFields({
                    ...editedFields,
                    beacons: true,
                  });
                }
              }}
              onBlur={() => {
                const value = passTemplateCtx.beacons[activeStep]?.minor;
                if (value.trim() === '') {
                  passTemplateCtx.updateBeacons(
                    passTemplateCtx.beacons.map((beacon, i) =>
                      i === activeStep ? { ...beacon, minor: '' } : beacon
                    )
                  );
                  if (isEdit) {
                    setEditedFields({
                      ...editedFields,
                      beacons: true,
                    });
                  }
                }
              }}
              autoComplete="off"
            />
            <FormErrorMessage></FormErrorMessage>
          </Box>
        </Flex>
      </FormControl>
      <Divider borderColor={modeBorderColor} />

      <FormControl bg={modeFormControlBg} borderRadius="0 15px 0 0">
        <Flex alignItems="center">
          <Hide below="2xl">
            <Box pl="24px" flexShrink={0} w="240px">
              <FormLabel m={0}>Notification message</FormLabel>
            </Box>
          </Hide>
          <Box p="14px" w="full" bg={modeLabelBg}>
            <Show below="2xl">
              <FormLabel>Notification message</FormLabel>
            </Show>
            <TextareaAutosize
              variant="filled"
              value={passTemplateCtx.beacons[activeStep]?.message}
              placeholder="Write message text here..."
              type="text"
              resize="none"
              rows={4}
              onChange={(e) => {
                passTemplateCtx.updateBeacons(
                  passTemplateCtx.beacons.map((beacon, i) =>
                    i === activeStep
                      ? { ...beacon, message: e.target.value }
                      : beacon
                  )
                );
                if (isEdit) {
                  setEditedFields({
                    ...editedFields,
                    beacons: true,
                  });
                }
              }}
              onBlur={() => {
                const value = passTemplateCtx.beacons[activeStep]?.message;
                if (value.trim() === '') {
                  passTemplateCtx.updateBeacons(
                    passTemplateCtx.beacons.map((beacon, i) =>
                      i === activeStep ? { ...beacon, message: '' } : beacon
                    )
                  );
                  if (isEdit) {
                    setEditedFields({
                      ...editedFields,
                      beacons: true,
                    });
                  }
                }
              }}
            />
            <FormErrorMessage></FormErrorMessage>
          </Box>
        </Flex>
      </FormControl>
      <Divider borderColor={modeBorderColor} />
      <FormControl bg={modeFormControlBg} borderRadius="0 15px 0 0">
        <Flex alignItems="center">
          <Hide below="2xl">
            <Box pl="24px" flexShrink={0} w="240px"></Box>
          </Hide>
          <Box p="14px" w="full" bg={modeLabelBg}>
            <Show below="2xl"></Show>
            <Flex w="full" justifyContent="space-between" mt={1} px={2}>
              <Button size="xs" w="25px" onClick={handleRemoveBeacon}>
                Delete
              </Button>
              <Button
                size="xs"
                w="25px"
                onClick={handleBeaconCountChange}
                isDisabled={
                  !passTemplateCtx.beacons[activeStep] ||
                  (!!passTemplateCtx.beacons[activeStep] &&
                    (!passTemplateCtx.beacons[activeStep].name ||
                      !passTemplateCtx.beacons[activeStep].proximity_uuid))
                }
              >
                <CustomPlusIcon w="12px" />
                <Text ml={1}>New</Text>
              </Button>
            </Flex>
          </Box>
        </Flex>
      </FormControl>
    </>
  );
}

export default BeaconDetails;

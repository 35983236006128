import { SimpleGrid, GridItem, Container, Text, useMediaQuery } from '@chakra-ui/react';

function Other() {
  const [isLargerThan2XL] = useMediaQuery('(min-width: 96em)');
  const [isLargerThanXL] = useMediaQuery('(min-width: 80em)');
  const [isLargerThanLG] = useMediaQuery('(min-width: 62em)');
  const [isLargerThanMD] = useMediaQuery('(min-width: 48em)');
  const [isLargerThanSM] = useMediaQuery('(min-width: 30em)');

  return (
    <Container bg="white" borderBottom="solid 1px #DDD">
      <Container maxW="1180px" px="0" py={6}>
        <SimpleGrid w="full" columns={{ base: 1, sm: 2, md: 4 }} columnGap={4} rowGap={4}>
          <GridItem>
            <Text color="brandDark">
              {isLargerThan2XL
                ? 'isLargerThan2XL 96em'
                : isLargerThanXL
                ? 'isLargerThanXL 80em'
                : isLargerThanLG
                ? 'isLargerThanLG 62em'
                : isLargerThanMD
                ? 'isLargerThanMD 48em'
                : isLargerThanSM
                ? 'isLargerThanSM 30em'
                : 'useMediaQuery:none'}
            </Text>
          </GridItem>
        </SimpleGrid>
      </Container>
    </Container>
  );
}

export default Other;

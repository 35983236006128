import { useContext } from 'react';
import { Grid, GridItem, Heading, useColorModeValue } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import InfoBox from '../../common/InfoBox';
import { CustomWalletIconStrokeAnimated } from '../../../theme/icons-animated/CustomWalletIconStrokeAnimated';
import { CustomPassesMultiIconStrokeAnimated } from '../../../theme/icons-animated/CustomPassesMultiIconStrokeAnimated';
import { ClientDashboardStatsContext } from '../../../store/client/ClientDashboardStatsContext';
import { CustomReaderIconAnimated } from '../../../theme/icons/CustomReaderIconAnimated';

const InfoBoxList = ({ loading }) => {
  const dashboardCtx = useContext(ClientDashboardStatsContext);
  const { dashboardState } = dashboardCtx;
  const borderColor = useColorModeValue('black', 'white');

  return (
    <>
      <Grid
        templateColumns={{
          base: 'repeat(1, 1fr)',
          sm: 'repeat(2, 1fr)',
          xl: 'repeat(2, 1fr)',
          '2xl': 'repeat(2, 1fr)',
        }}
        gap="30px"
      >
        <GridItem
          w="100%"
          border="2px solid transparent"
          borderRadius="15px"
          _hover={{ borderColor: borderColor }}
          transition="all .3s ease-in-out"
        >
          <Link to="/passes">
            <InfoBox
              icon={<CustomPassesMultiIconStrokeAnimated boxSize="32px" />}
              text="Issued passes"
              loading={loading}
            >
              {!loading && (
                <Heading pt="10px">{dashboardState.passesIssued}</Heading>
              )}
            </InfoBox>
          </Link>
        </GridItem>
        <GridItem w="100%">
          <InfoBox
            icon={<CustomWalletIconStrokeAnimated boxSize="28px" />}
            text="Active passes"
            tooltipClientDashboard
            loading={loading}
          >
            {!loading && (
              <Heading pt="10px">{dashboardState.activePasses} </Heading>
            )}
          </InfoBox>
        </GridItem>
        <GridItem w="100%">
          <InfoBox
            icon={<CustomReaderIconAnimated boxSize="31px" />}
            text="Readers"
            loading={loading}
            inverse
          >
            {!loading && (
              <Heading pt="10px" color="white">
                {dashboardState.readers || 0}
              </Heading>
            )}
          </InfoBox>
        </GridItem>
      </Grid>
    </>
  );
};

export default InfoBoxList;

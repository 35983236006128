import { Icon } from '@chakra-ui/react';

export const CustomViewEditIcon = (props) => (
  <Icon viewBox="0 0 40 20" {...props}>
    <path
      d="M31.5945 3.12938L30.833 3.89087L34.1074 7.16529L34.8689 6.40379L31.5945 3.12938Z"
      fill="currentColor"
    />
    <path
      d="M24.8357 9.92576L24.0742 10.6873L27.3486 13.9617L28.1101 13.2002L24.8357 9.92576Z"
      fill="currentColor"
    />
    <path
      d="M23.5385 15C23.3769 15 23.2692 14.9462 23.1615 14.8385C23.0538 14.7308 23 14.5154 23 14.3538L23.9154 10.1538C23.9154 10.0462 23.9692 9.93846 24.0769 9.88462L32.7462 1.16154C32.9077 1.05385 33.0154 1 33.1769 1C33.3385 1 33.4462 1.05385 33.5538 1.16154L36.8385 4.44615C36.9462 4.55385 37 4.66154 37 4.82308C37 4.98462 36.9462 5.09231 36.8385 5.2L28.1154 13.8692C28.0615 13.9231 27.9538 13.9769 27.8462 14.0308L23.6462 15C23.5923 15 23.5923 15 23.5385 15ZM24.9385 10.5308L24.2385 13.7615L27.4692 13.0615L35.6538 4.82308L33.1231 2.29231L24.9385 10.5308Z"
      fill="currentColor"
    />
    <line
      x1="18.5"
      y1="15.5"
      x2="18.5"
      y2="0.5"
      stroke="currentColor"
      strokeLinecap="round"
    />
    <path
      d="M7 4.03333C9.41182 4.03333 11.5627 5.40008 12.6127 7.5625C11.5627 9.72492 9.41182 11.0917 7 11.0917C4.58818 11.0917 2.43727 9.72492 1.38727 7.5625C2.43727 5.40008 4.58818 4.03333 7 4.03333ZM7 2.75C3.81818 2.75 1.10091 4.74558 0 7.5625C1.10091 10.3794 3.81818 12.375 7 12.375C10.1818 12.375 12.8991 10.3794 14 7.5625C12.8991 4.74558 10.1818 2.75 7 2.75Z"
      fill="currentColor"
    />
    <path
      d="M7.00133 5.95838C7.87951 5.95838 8.59224 6.67705 8.59224 7.56255C8.59224 8.44805 7.87951 9.16672 7.00133 9.16672C6.12315 9.16672 5.41042 8.44805 5.41042 7.56255C5.41042 6.67705 6.12315 5.95838 7.00133 5.95838ZM7.00133 4.67505C5.42315 4.67505 4.1377 5.97122 4.1377 7.56255C4.1377 9.15388 5.42315 10.45 7.00133 10.45C8.57951 10.45 9.86497 9.15388 9.86497 7.56255C9.86497 5.97122 8.57951 4.67505 7.00133 4.67505Z"
      fill="currentColor"
    />
  </Icon>
);

import React, { useContext, useState } from 'react';
import PassTemplateContext from '../../../store/client/PassTemplateContext';
import CropModal from '../../modals/CropModal';
import CustomDropzone from '../../common/CustomDropzone';
import {
  Box,
  Flex,
  Heading,
  Link,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react';
import placeholderThumbnail from '../../../assets/images/thumbnail.png';
import { CustomPlusIcon } from '../../../theme/icons/CustomPlusIcon';

function ThumbnailImage(props) {
  const [droppedImage, setDroppedImage] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const passTemplateCtx = useContext(PassTemplateContext);
  const [showCustomDropzone, setShowCustomDropzone] = useState(
    !!passTemplateCtx.thumbnailImage
  );
  const modeColorText = useColorModeValue('secondaryDark', '#DDDD');
  const modeColorTextHover = useColorModeValue('black', 'white');
  const { thumbnailLogo: image } = passTemplateCtx;

  function handleAddThumbnailToTemplate() {
    passTemplateCtx.resetThumbnailLogo();
    passTemplateCtx.updateThumbnailImage(placeholderThumbnail);
    props.setEditedImages({ ...props.editedImages, thumbnail_image: true });
    setShowCustomDropzone(true);
  }

  function handleUpdateDroppedImage(image) {
    setDroppedImage(image);
    passTemplateCtx.updateThumbnailLogo({
      ...image,
      crop: { x: 0, y: 0 },
      zoom: 1,
      aspect: 1,
    });
  }

  function handleSaveDroppedImage(base64DroppedFile) {
    passTemplateCtx.updateThumbnailImage(base64DroppedFile);
    passTemplateCtx.updateThumbnailLogo({
      ...image,
      src: droppedImage.preview,
      mimeType: droppedImage.type,
    });
    props.setEditedImages({ ...props.editedImages, thumbnail_image: true });
  }

  function isImageAdded() {
    onOpen();
  }

  async function updateImage(image) {
    passTemplateCtx.updateThumbnailLogo(image);
  }

  return (
    <>
      {showCustomDropzone ? (
        <>
          <CropModal
            key="thumbnail_image_crop_modal"
            onClose={onClose}
            onOpen={onOpen}
            isOpen={isOpen}
            image={image}
            updateImage={updateImage}
            parent="thumbnail_image"
            title="Crop thumbnail image"
            droppedImage={droppedImage}
            saveDroppedImage={handleSaveDroppedImage}
          />
          <CustomDropzone
            key="thumbnail_image_drop_zone"
            isSquare
            image={image}
            isImageAdded={isImageAdded}
            parent="thumbnail_image"
            // explanation="Recommended size: 150x150 pixels. Recommended file type is png with transparent background. Images must be square."
            explanation="Minimum size: 660x660 pixels. Images must be square. Recommended file type is png."
            accept={['image/jpg', 'image/jpeg', 'image/png', 'image/x-png']}
            droppedImage={droppedImage}
            updateDroppedImage={handleUpdateDroppedImage}
            removable={props.removable}
            setShowCustomDropzone={setShowCustomDropzone}
          />
        </>
      ) : (
        <Box as={Link} onClick={handleAddThumbnailToTemplate}>
          <Flex align="center" gap={1} mt={2}>
            <CustomPlusIcon color="#FF8477" boxSize="12px" />
            <Heading
              fontSize="14px"
              top="1px"
              pos="relative"
              color={props.publicPage ? '#DDD' : modeColorText}
              _hover={
                props.publicPage
                  ? { color: 'white', transition: '0.3s' }
                  : { color: modeColorTextHover, transition: '0.3s' }
              }
              transition="0.3s"
            >
              Add thumbnail image to template
            </Heading>
          </Flex>
        </Box>
      )}
    </>
  );
}

export default ThumbnailImage;

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  VStack,
  Heading,
  Text,
  Box,
  Stack,
  useToast,
} from '@chakra-ui/react';
import CustomToast from '../../common/CustomToast';
import { useContext } from 'react';
import PassTemplateContext from '../../store/client/PassTemplateContext';
import placeholderIcon from '../../assets/images/icon.png';
import placeholderLogo from '../../assets/images/logo.png';

const DeleteImageModal = (props) => {
  const passTemplateCtx = useContext(PassTemplateContext);
  const toast = useToast();

  const onDeleteImage = (e) => {
    e.preventDefault();

    if (props.parent === 'logo_image') {
      passTemplateCtx.resetIosLogo();
      passTemplateCtx.updateLogoImage(placeholderLogo);
    }

    if (props.parent === 'icon_logo') {
      passTemplateCtx.resetAndroidLogo();
      passTemplateCtx.updateIconLogo(placeholderIcon);
    }

    if (props.parent === 'banner_image') {
      passTemplateCtx.resetBannerLogo();
      passTemplateCtx.updateBannerImage('');
      passTemplateCtx.updateWatermarkColor('light');
      passTemplateCtx.updateWatermarkPosition('bottomRight');
      if (props.isEdit) {
        props.setEditedFields({
          ...props.editedFields,
          watermark_color: 'light',
          watermark_position: 'bottomRight',
        });
      }
    }

    if (props.parent === 'google_banner_image') {
      passTemplateCtx.resetGoogleBannerLogo();
      passTemplateCtx.updateGoogleBannerImage('');
      passTemplateCtx.updateGoogleBannerWatermarkColor('light');
      passTemplateCtx.updateGoogleBannerWatermarkPosition('bottomRight');
      if (props.isEdit) {
        props.setEditedFields({
          ...props.editedFields,
          google_banner_watermark_color: 'light',
          google_banner_watermark_position: 'bottomRight',
        });
      }
    }

    if (props.parent === 'thumbnail_image') {
      passTemplateCtx.resetThumbnailLogo();
      passTemplateCtx.updateThumbnailImage('');
    }

    props.setShowCustomDropzone(false);

    props.onClose();
    toast({
      render: (props) => (
        <CustomToast
          status="success"
          title="Image has been removed from template"
          description=""
          onClose={props.onClose}
        />
      ),
    });
  };

  return (
    <Modal onClose={props.onClose} isOpen={props.isOpen} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Heading fontSize={{ base: '24px', lg: '32px' }} lineHeight="130%">
            Remove image from template
          </Heading>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack spacing="30px" alignItems="flex-start">
            <Box>
              <Text textStyle="bodyFamilyMedium" color="secondaryDark08">
                Are you sure you want to remove this image?
              </Text>
            </Box>
          </VStack>
        </ModalBody>
        <ModalFooter>
          <Stack
            spacing="10px"
            direction={{ base: 'column', sm: 'row' }}
            width={{ base: 'full', sm: 'auto' }}
          >
            <Button
              onClick={props.onClose}
              alt="Cancel"
              variant="secondary"
              width={{ base: 'full', sm: 'auto' }}
            >
              Cancel
            </Button>
            <Button
              alt="Delete image"
              width={{ base: 'full', sm: 'auto' }}
              onClick={onDeleteImage}
            >
              Remove
            </Button>
          </Stack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default DeleteImageModal;

import { Container, Flex, Box } from '@chakra-ui/react';
import FooterPlain from './FooterPlain';
import ScrollToTopForRouter from '../components/common/ScrollToTopForRouter';

const LayoutStyleguide = (props) => {
  return (
    <>
      <ScrollToTopForRouter />
      <Flex direction="column" minH="100vh">
        {props.header}
        <Container variant="bodyContent">
          <Flex w="full" justifyContent={{ base: 'center', md: 'flex-start' }}>
            <Box w="full">{props.children}</Box>
          </Flex>
        </Container>
        <FooterPlain />
      </Flex>
    </>
  );
};

export default LayoutStyleguide;

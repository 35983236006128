import React, { useContext, useState } from 'react';
import PassTemplateContext from '../../../store/client/PassTemplateContext';
import CropModal from '../../modals/CropModal';
import CustomDropzone from '../../common/CustomDropzone';
import { useDisclosure } from '@chakra-ui/react';

function LogoImage(props) {
  const [droppedImage, setDroppedImage] = useState(null);
  const passTemplateCtx = useContext(PassTemplateContext);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { iosLogo: image } = passTemplateCtx;

  function handleUpdateDroppedImage(image) {
    setDroppedImage(image);
    passTemplateCtx.updateIosLogo({
      ...image,
      crop: { x: 0, y: 0 },
      zoom: 1,
      // aspect: 16 / 5,
      aspect: 360 / 110,
    });
  }

  function handleSaveDroppedImage(base64DroppedFile) {
    passTemplateCtx.updateLogoImage(base64DroppedFile);
    passTemplateCtx.updateIosLogo({
      ...image,
      src: droppedImage.preview,
      mimeType: droppedImage.type,
    });
    props.setEditedImages({ ...props.editedImages, logo_image: true });
  }

  function isImageAdded() {
    onOpen();
  }

  async function updateImage(image) {
    passTemplateCtx.updateIosLogo(image);
  }

  return (
    <>
      <CropModal
        key="logo_image_crop_modal"
        onClose={onClose}
        onOpen={onOpen}
        isOpen={isOpen}
        image={image}
        updateImage={updateImage}
        parent="logo_image"
        title="Crop logo image"
        droppedImage={droppedImage}
        saveDroppedImage={handleSaveDroppedImage}
      />
      <CustomDropzone
        key="logo_image_drop_zone"
        image={image}
        isImageAdded={isImageAdded}
        parent="logo_image"
        // explanation="Recommended size: 480x150 pixels. Recommended file type is png with transparent
        explanation="Recommended size: 360x110 pixels. Recommended file type is png with transparent background."
        accept={['image/jpg', 'image/jpeg', 'image/png', 'image/x-png']}
        droppedImage={droppedImage}
        updateDroppedImage={handleUpdateDroppedImage}
      />
    </>
  );
}

export default LogoImage;

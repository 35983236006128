import svgWatermarkLight from '../assets/vectors/svgWatermarkLight';
import svgWatermarkDark from '../assets/vectors/svgWatermarkDark';
import { useContext } from 'react';
import PassTemplateContext from '../store/client/PassTemplateContext';

const useAddWatermark = () => {
  const passTemplateCtx = useContext(PassTemplateContext);
  const { passType } = passTemplateCtx;
  function getAverageBrightness(image) {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    canvas.width = image.width;
    canvas.height = image.height;
    ctx.drawImage(image, 0, 0, canvas.width, canvas.height);
    const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height).data;
    let totalBrightness = 0;
    for (let i = 0; i < imageData.length; i += 4) {
      const r = imageData[i];
      const g = imageData[i + 1];
      const b = imageData[i + 2];
      totalBrightness += (r + g + b) / 3;
    }
    return totalBrightness / (imageData.length / 4);
  }
  const determineWatermarkColor = (image) => {
    return new Promise((resolve) => {
      const img = new Image();
      img.src = image;
      img.onload = () => {
        const averageBrightness = getAverageBrightness(img);
        resolve(averageBrightness > 127 ? 'dark' : 'light');
      };
    });
  };
  const addWatermark = async (base64Image, svgWatermark, watermarkPosition) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = base64Image;
      img.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        canvas.width = img.width;
        canvas.height = img.height;

        ctx.drawImage(img, 0, 0);

        const svgImage = new Image();
        svgImage.src = 'data:image/svg+xml;base64,' + btoa(svgWatermark);
        svgImage.onload = () => {
          const scalingWidthFactor = passType === 'eventTicket' ? 0.34 : 0.35;
          const scalingHeightFactor = passType === 'eventTicket' ? 0.17 : 0.13;
          const watermarkWidth = canvas.width * scalingWidthFactor;
          const watermarkHeight = canvas.height * scalingHeightFactor;
          const offsetFactor = 0.04;
          let x = 0;
          let y = 0;

          if (watermarkPosition === 'topLeft') {
            x = canvas.width * offsetFactor;
            y = canvas.height * offsetFactor;
          } else if (watermarkPosition === 'bottomRight') {
            x = canvas.width * (1 - scalingWidthFactor - offsetFactor);
            y = canvas.height * (1 - scalingHeightFactor - offsetFactor);
          } else if (watermarkPosition === 'topRight') {
            x = canvas.width * (1 - scalingWidthFactor - offsetFactor);
            y = canvas.height * offsetFactor;
          } else if (watermarkPosition === 'bottomLeft') {
            x = canvas.width * offsetFactor;
            y = canvas.height * (1 - scalingHeightFactor - offsetFactor);
          }

          ctx.drawImage(svgImage, x, y, watermarkWidth, watermarkHeight);
          resolve(canvas.toDataURL());
        };
        svgImage.onerror = reject;
      };
      img.onerror = reject;
    });
  };

  const addWatermarkToBannerImage = async (
    base64DroppedFile,
    watermarkColor,
    watermarkPosition
  ) => {
    const svgWatermark =
      watermarkColor === 'light' ? svgWatermarkLight : svgWatermarkDark;

    const watermarkedImage = await addWatermark(
      base64DroppedFile,
      svgWatermark,
      watermarkPosition
    );
    return watermarkedImage;
  };

  return { addWatermarkToBannerImage, determineWatermarkColor };
};

export default useAddWatermark;

import { motion } from 'framer-motion';
import { Icon } from '@chakra-ui/react';

const EmptyDefaultDarkSvgAnimated = () => {
  return (
    <Icon viewBox="0 0 128 115" width="128px" height="115px">
      <circle cx="63" cy="62" r="53" fill="#3B4851" />
      <motion.path
        d="M27 32C27 29.2386 29.2386 27 32 27H93C95.7614 27 98 29.2386 98 32V54H27V32Z"
        fill="url(#paint0_linear_396_278)"
        animate={{
          opacity: [0, 1],
        }}
        transition={{ duration: 1, ease: 'easeInOut' }}
      />
      <motion.path
        d="M98 92C98 94.7614 95.7614 97 93 97L32 97C29.2386 97 27 94.7614 27 92L27 54L98 54L98 92Z"
        fill="#fff"
        animate={{
          opacity: [0, 1],
        }}
        transition={{ duration: 1, ease: 'easeInOut' }}
      />
      <motion.rect
        x="57"
        y="80"
        width="10"
        height="3"
        rx="1.5"
        fill="#3B4851"
        fillOpacity="0.8"
        animate={{
          opacity: [0, 1],
        }}
        transition={{ duration: 1, ease: 'easeInOut' }}
      />
      <motion.rect
        x="33"
        y="33"
        width="33"
        height="3"
        rx="1.5"
        fill="#fff"
        animate={{
          opacity: [0, 1],
        }}
        transition={{ duration: 1, ease: 'easeInOut' }}
      />
      <motion.rect
        x="33"
        y="39"
        width="21"
        height="3"
        rx="1.5"
        fill="#fff"
        animate={{
          opacity: [0, 1],
        }}
        transition={{ duration: 1, ease: 'easeInOut' }}
      />
      <motion.rect
        x="33"
        y="45"
        width="14"
        height="3"
        rx="1.5"
        fill="#DDDDDD"
        animate={{
          opacity: [0, 1],
        }}
        transition={{ duration: 1, ease: 'easeInOut' }}
      />
      <motion.rect
        x="107"
        y="27"
        width="18"
        height="3"
        rx="1.5"
        fill="#DDDDDD"
        fillOpacity="0.6"
        animate={{
          opacity: [0, 1],
        }}
        transition={{ delay: 1, duration: 1, ease: 'easeInOut' }}
      />
      <motion.rect
        x="95"
        y="18"
        width="18"
        height="3"
        rx="1.5"
        transform="rotate(-90 95 18)"
        fill="#DDDDDD"
        fillOpacity="0.6"
        animate={{
          opacity: [0, 1],
        }}
        transition={{ delay: 1, duration: 1, ease: 'easeInOut' }}
      />
      <motion.path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.21967 59.0653C-0.0732233 59.3582 -0.0732233 59.833 0.21967 60.1259C0.512563 60.4188 0.987437 60.4188 1.28033 60.1259L3.17279 58.2335L5.06526 60.1259C5.35815 60.4188 5.83303 60.4188 6.12592 60.1259C6.41881 59.833 6.41881 59.3582 6.12592 59.0653L4.23345 57.1728L6.12592 55.2803C6.41881 54.9874 6.41881 54.5126 6.12592 54.2197C5.83303 53.9268 5.35815 53.9268 5.06526 54.2197L3.17279 56.1121L1.28033 54.2197C0.987437 53.9268 0.512563 53.9268 0.21967 54.2197C-0.0732235 54.5126 -0.073223 54.9874 0.21967 55.2803L2.11213 57.1728L0.21967 59.0653Z"
        fill="#DDDDDD"
        fillOpacity="0.8"
        animate={{
          opacity: [0, 1],
          x: [20, 0],
          scale: [0.5, 1],
        }}
        transition={{ delay: 0.1, duration: 1, ease: 'easeInOut' }}
      />
      <motion.path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M123.38 75.8583C123.17 76.0685 123.17 76.4093 123.38 76.6195C123.591 76.8297 123.931 76.8297 124.142 76.6195L125.5 75.2614L126.858 76.6195C127.068 76.8297 127.409 76.8297 127.619 76.6195C127.829 76.4093 127.829 76.0685 127.619 75.8583L126.261 74.5002L127.619 73.1419C127.829 72.9317 127.829 72.5909 127.619 72.3807C127.409 72.1705 127.068 72.1705 126.858 72.3807L125.5 73.7389L124.142 72.3807C123.931 72.1705 123.591 72.1705 123.38 72.3807C123.17 72.5909 123.17 72.9317 123.38 73.1419L124.739 74.5002L123.38 75.8583Z"
        fill="#DDDDDD"
        fillOpacity="0.6"
        animate={{
          opacity: [0, 1],
          x: [-20, 0],
          scale: [0.5, 1],
        }}
        transition={{ delay: 0.1, duration: 1, ease: 'easeInOut' }}
      />
      <motion.rect
        x="22"
        y="15.307"
        width="7.50525"
        height="1.39773"
        rx="0.698864"
        transform="rotate(-45 22 15.307)"
        fill="#DDDDDD"
        fillOpacity="0.6"
        animate={{
          opacity: [0, 1],
          x: [10, 0],
          y: [10, 0],
          rotate: [-45, -45],
          scale: [0.5, 1],
        }}
        transition={{ delay: 0.5, duration: 1, ease: 'easeInOut' }}
      />
      <motion.rect
        x="109.597"
        y="61.7903"
        width="7.91522"
        height="1.68777"
        rx="0.843883"
        transform="rotate(-135 109.597 61.7903)"
        fill="#DDDDDD"
        fillOpacity="0.6"
        animate={{
          opacity: [0, 1],
          x: [-10, -5],
          y: [-10, -5],
          rotate: [-135, -135],
          scale: [0.5, 1],
        }}
        transition={{ delay: 0.3, duration: 1, ease: 'easeInOut' }}
      />
      <motion.rect
        x="104"
        y="18.7279"
        width="18"
        height="3"
        rx="1.5"
        transform="rotate(-45 104 18.7279)"
        fill="#DDDDDD"
        fillOpacity="0.6"
        animate={{
          opacity: [0, 1],
        }}
        transition={{ delay: 1, duration: 1, ease: 'easeInOut' }}
      />
      <circle cx="52" cy="67" r="2" fill="#3B4851" fillOpacity="0.8" />
      <circle cx="72" cy="67" r="2" fill="#3B4851" fillOpacity="0.8" />
      <motion.path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.5 35.6C14.1075 35.6 14.6 35.1075 14.6 34.5C14.6 33.8925 14.1075 33.4 13.5 33.4C12.8925 33.4 12.4 33.8925 12.4 34.5C12.4 35.1075 12.8925 35.6 13.5 35.6ZM13.5 37C14.8807 37 16 35.8807 16 34.5C16 33.1193 14.8807 32 13.5 32C12.1193 32 11 33.1193 11 34.5C11 35.8807 12.1193 37 13.5 37Z"
        fill="#FF8477"
        animate={{
          opacity: [0, 1],
          x: [10, 0],
          y: [5, 0],
          scale: [0.5, 1],
        }}
        transition={{ delay: 0.3, duration: 1, ease: 'easeInOut' }}
      />
      <motion.path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 85C15.5523 85 16 84.5523 16 84C16 83.4477 15.5523 83 15 83C14.4477 83 14 83.4477 14 84C14 84.5523 14.4477 85 15 85ZM15 87C16.6569 87 18 85.6569 18 84C18 82.3431 16.6569 81 15 81C13.3431 81 12 82.3431 12 84C12 85.6569 13.3431 87 15 87Z"
        fill="#3B4851"
        fillOpacity="0.6"
        animate={{
          opacity: [0, 1],
          x: [10, 0],
          y: [-5, 0],
          scale: [0.5, 1],
        }}
        transition={{ delay: 0.3, duration: 1, ease: 'easeInOut' }}
      />
      <motion.path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M113.5 101C114.328 101 115 100.328 115 99.5C115 98.6716 114.328 98 113.5 98C112.672 98 112 98.6716 112 99.5C112 100.328 112.672 101 113.5 101ZM113.5 103C115.433 103 117 101.433 117 99.5C117 97.567 115.433 96 113.5 96C111.567 96 110 97.567 110 99.5C110 101.433 111.567 103 113.5 103Z"
        fill="#FF8477"
        animate={{
          opacity: [0, 1],
          x: [-10, 0],
          y: [-5, 0],
          scale: [0.5, 1],
        }}
        transition={{ delay: 0.3, duration: 1, ease: 'easeInOut' }}
      />
      <defs>
        <linearGradient
          id="paint0_linear_396_278"
          x1="28.8323"
          y1="54"
          x2="83.815"
          y2="32.7053"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF8477" />
          <stop offset="1" stopColor="#EC6759" />
        </linearGradient>
      </defs>
    </Icon>
  );
};
export default EmptyDefaultDarkSvgAnimated;

import { Icon } from '@chakra-ui/react';

export const CustomMoonMultiIcon = (props) => (
  <Icon viewBox="0 0 58 58" {...props}>
    <path
      className="multicolorElToDark darkBgElToWhite"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M39.9735 6.84778C39.7195 6.88872 39.4594 6.91002 39.1945 6.91002C38.2553 6.91002 37.4954 7.67001 37.4954 8.6092C37.4954 9.5484 38.2553 10.3084 39.1945 10.3084C39.461 10.3084 39.7227 10.3289 39.9782 10.3685C40.1195 10.3904 40.2589 10.4181 40.3961 10.4514C42.2426 10.8992 43.6993 12.3551 44.1483 14.2011C44.1819 14.3394 44.2099 14.4798 44.232 14.6222C44.2715 14.8778 44.2921 15.1395 44.2921 15.4059C44.2921 16.3451 45.0521 17.1051 45.9913 17.1051C46.9305 17.1051 47.6905 16.3451 47.6905 15.4059C47.6905 15.1395 47.711 14.8778 47.7506 14.6222C47.7727 14.4799 47.8006 14.3394 47.8343 14.2011C48.2833 12.3551 49.74 10.8992 51.5865 10.4514C51.7237 10.4181 51.8631 10.3904 52.0043 10.3685C52.2598 10.3289 52.5216 10.3084 52.788 10.3084C53.7272 10.3084 54.4872 9.5484 54.4872 8.6092C54.4872 7.67001 53.7272 6.91002 52.788 6.91002C52.5232 6.91002 52.2631 6.88871 52.009 6.84778C51.8709 6.82552 51.7346 6.79746 51.6003 6.76385C49.7459 6.29975 48.2821 4.77913 47.8332 2.90636C47.7999 2.76729 47.7722 2.62628 47.7503 2.48361C47.7109 2.22693 47.6905 1.96489 47.6905 1.69918C47.6905 0.759989 46.9305 0 45.9913 0C45.0521 0 44.2921 0.759989 44.2921 1.69918C44.2921 1.9649 44.2717 2.22694 44.2323 2.48362C44.2104 2.62629 44.1827 2.7673 44.1493 2.90636C43.7005 4.77912 42.2367 6.29974 40.3823 6.76386C40.248 6.79746 40.1117 6.82552 39.9735 6.84778ZM44.6324 8.58846C45.1333 8.98867 45.5892 9.44288 45.9913 9.94221C46.3934 9.44288 46.8493 8.98867 47.3502 8.58846C46.8481 8.17811 46.3925 7.71354 45.9913 7.20527C45.5901 7.71354 45.1345 8.17811 44.6324 8.58846Z"
      fill="#FF8477"
    />
    <path
      className="multicolorElToDark darkBgElToWhite"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.7085 20.3699C23.4852 20.41 23.259 20.4413 23.0301 20.4635C22.7578 20.49 22.4818 20.5035 22.2027 20.5035C21.2635 20.5035 20.5035 21.2635 20.5035 22.2027C20.5035 23.1419 21.2635 23.9019 22.2027 23.9019C22.4818 23.9019 22.7578 23.9154 23.0301 23.9418C23.259 23.9641 23.4852 23.9954 23.7085 24.0355C27.1884 24.6605 29.94 27.4121 30.565 30.8921C30.6051 31.1153 30.6365 31.3415 30.6587 31.5704C30.6851 31.8427 30.6986 32.1187 30.6986 32.3978C30.6986 33.337 31.4586 34.097 32.3978 34.097C33.337 34.097 34.097 33.337 34.097 32.3978C34.097 32.1187 34.1105 31.8427 34.137 31.5704C34.1592 31.3415 34.1906 31.1153 34.2307 30.8921C34.8557 27.4121 37.6072 24.6605 41.0872 24.0355C41.3105 23.9954 41.5367 23.9641 41.7656 23.9418C42.0378 23.9154 42.3138 23.9019 42.5929 23.9019C43.5321 23.9019 44.2921 23.1419 44.2921 22.2027C44.2921 21.2635 43.5321 20.5035 42.5929 20.5035C42.3138 20.5035 42.0378 20.49 41.7656 20.4635C41.5367 20.4413 41.3105 20.4099 41.0872 20.3699C37.6072 19.7448 34.8557 16.9933 34.2307 13.5133C34.1906 13.2901 34.1592 13.0638 34.137 12.8349C34.1105 12.5627 34.097 12.2867 34.097 12.0076C34.097 11.0684 33.337 10.3084 32.3978 10.3084C31.4586 10.3084 30.6986 11.0684 30.6986 12.0076C30.6986 12.2867 30.6851 12.5627 30.6587 12.8349C30.6365 13.0638 30.6051 13.2901 30.565 13.5133C29.94 16.9933 27.1884 19.7448 23.7085 20.3699ZM32.3978 18.5594C31.4576 20.0179 30.2131 21.2625 28.7545 22.2027C30.2131 23.1429 31.4576 24.3874 32.3978 25.846C33.3381 24.3874 34.5826 23.1429 36.0411 22.2027C34.5826 21.2625 33.3381 20.0179 32.3978 18.5594Z"
      fill="#FF8477"
    />
    <path
      className="multicolorElToWhite darkBgElToBrand"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M57.9834 32.9316C58.0896 32.1451 57.64 31.3917 56.8999 31.1113C56.1565 30.8308 55.3203 31.0996 54.8838 31.7617C51.0854 37.48 44.3402 40.8949 37.4953 40.8949C26.2515 40.8949 17.1051 31.7485 17.1051 20.5046C17.1051 13.6598 20.52 6.91263 26.2381 3.11438C26.8987 2.67633 27.1691 1.83999 26.8887 1.09824C26.6082 0.358188 25.8516 -0.0832609 25.0684 0.0147255C24.7988 0.0515462 24.5305 0.0922246 24.2634 0.136699C24.2624 0.136858 24.2615 0.137018 24.2605 0.137177C24.2398 0.140628 24.2191 0.144104 24.1984 0.147601C24.1991 0.147489 24.1977 0.147714 24.1984 0.147601C10.404 2.47917 0 14.9386 0 29.0006C0 44.9287 13.0713 58 28.9994 58C43.0614 58 55.5187 47.5979 57.8503 33.8035C57.8501 33.8042 57.8504 33.8027 57.8503 33.8035C57.8537 33.7829 57.8576 33.76 57.861 33.7394C57.8618 33.7349 57.8625 33.7303 57.8633 33.7258C57.907 33.4623 57.9471 33.1975 57.9834 32.9316ZM52.2656 39.6291C48.116 48.3502 39.0231 54.3993 28.9994 54.3993C15.0599 54.3993 3.60076 42.9401 3.60076 29.0006C3.60076 18.9765 9.65004 9.88252 18.3711 5.7326C15.2367 9.98247 13.5044 15.2272 13.5044 20.5046C13.5044 33.7371 24.2629 44.4956 37.4953 44.4956C42.7728 44.4956 48.0165 42.7633 52.2656 39.6291Z"
      fill="#3B4851"
    />
  </Icon>
);

import UseEvent from '../../hooks/useEvent';
import EventsViewPageView from '../../components/client/events/EventsViewPageView';
import { EventsContextProvider } from '../../store/client/EventsContext';

const EventsViewPage = () => {
  return (
    <EventsContextProvider>
      <UseEvent>
        <EventsViewPageView />
      </UseEvent>
    </EventsContextProvider>
  );
};
export default EventsViewPage;

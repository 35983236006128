import { SimpleGrid, GridItem, Box, Container, AspectRatio } from '@chakra-ui/react';

import { CustomDashboardMultiIcon } from '../../theme/multicolor-icons/CustomDashboardMultiIcon';
import { CustomPassesMultiIcon } from '../../theme/multicolor-icons/CustomPassesMultiIcon';
import { CustomAnalyticsMultiIcon } from '../../theme/multicolor-icons/CustomAnalyticsMultiIcon';
import { CustomTemplatesMultiIcon } from '../../theme/multicolor-icons/CustomTemplatesMultiIcon';
import { CustomEventsMultiIcon } from '../../theme/multicolor-icons/CustomEventsMultiIcon';
import { CustomReadersMultiIcon } from '../../theme/multicolor-icons/CustomReadersMultiIcon';
import { CustomReadersAltMultiIcon } from '../../theme/multicolor-icons/CustomReadersAltMultiIcon';
import { CustomCogMultiIcon } from '../../theme/multicolor-icons/CustomCogMultiIcon';
import { CustomLogoutMultiIcon } from '../../theme/multicolor-icons/CustomLogoutMultiIcon';
import { CustomWalletMultiIcon } from '../../theme/multicolor-icons/CustomWalletMultiIcon';
import { CustomScanMultiIcon } from '../../theme/multicolor-icons/CustomScanMultiIcon';
import { CustomEmailMultiIcon } from '../../theme/multicolor-icons/CustomEmailMultiIcon';
import { CustomSmsMultiIcon } from '../../theme/multicolor-icons/CustomSmsMultiIcon';
import { CustomBellMultiIcon } from '../../theme/multicolor-icons/CustomBellMultiIcon';
import { CustomClientsBookIcon } from '../../theme/multicolor-icons/CustomClientsBookIcon';
import { CustomLocationsMultiIcon } from '../../theme/multicolor-icons/CustomLocationsMultiIcon';
import { CustomLoyaltyMultiIcon } from '../../theme/multicolor-icons/CustomLoyaltyMultiIcon';
import { CustomDropzoneMultiIcon } from '../../theme/multicolor-icons/CustomDropzoneMultiIcon';
import { CustomDeleteMultiIcon } from '../../theme/multicolor-icons/CustomDeleteMultiIcon';
import { CustomTrashcanMultiIcon } from '../../theme/multicolor-icons/CustomTrashcanMultiIcon';
import { CustomMoonMultiIcon } from '../../theme/multicolor-icons/CustomMoonMultiIcon';
import { CustomSunMultiIcon } from '../../theme/multicolor-icons/CustomSunMultiIcon';
import { CustomVisibilityIcon } from '../../theme/multicolor-icons/CustomVisibilityIcon';
import { CustomVisibilityOffIcon } from '../../theme/multicolor-icons/CustomVisibilityOffIcon';
import { CustomLiveEventDataMultiIcon } from '../../theme/multicolor-icons/CustomLiveEventDataMultiIcon';
import { CustomUserAcceptedMultiIcon } from '../../theme/multicolor-icons/CustomUserAcceptedMultiIcon';
import { CustomUserRejectedMultiIcon } from '../../theme/multicolor-icons/CustomUserRejectedMultiIcon';

const iconSize = '32px';

const multicolorIcons = [
  <CustomDashboardMultiIcon fontSize={iconSize} />,
  <CustomPassesMultiIcon fontSize={iconSize} />,
  <CustomUserAcceptedMultiIcon fontSize={iconSize} />,
  <CustomUserRejectedMultiIcon fontSize={iconSize} />,
  <CustomAnalyticsMultiIcon fontSize={iconSize} />,
  <CustomLiveEventDataMultiIcon fontSize={iconSize} />,
  <CustomTemplatesMultiIcon fontSize={iconSize} />,
  <CustomEventsMultiIcon fontSize={iconSize} />,
  <CustomReadersMultiIcon fontSize={iconSize} />,
  <CustomReadersAltMultiIcon fontSize={iconSize} />,
  <CustomCogMultiIcon fontSize={iconSize} />,
  <CustomLogoutMultiIcon fontSize={iconSize} />,
  <CustomWalletMultiIcon fontSize={iconSize} />,
  <CustomScanMultiIcon fontSize={iconSize} />,
  <CustomEmailMultiIcon fontSize={iconSize} />,
  <CustomSmsMultiIcon fontSize={iconSize} />,
  <CustomBellMultiIcon fontSize={iconSize} />,
  <CustomClientsBookIcon fontSize={iconSize} />,
  <CustomLocationsMultiIcon fontSize={iconSize} />,
  <CustomLoyaltyMultiIcon fontSize={iconSize} />,
  <CustomDropzoneMultiIcon fontSize={iconSize} />,
  <CustomDeleteMultiIcon fontSize={iconSize} />,
  <CustomTrashcanMultiIcon fontSize={iconSize} />,
  <CustomMoonMultiIcon fontSize={iconSize} />,
  <CustomSunMultiIcon fontSize={iconSize} />,
  <CustomVisibilityIcon fontSize={iconSize} />,
  <CustomVisibilityOffIcon fontSize={iconSize} />,
];

function MulticolorIcons() {
  return (
    <Container bg="white" borderBottom="solid 1px #DDD">
      <Container maxW="1180px" px="0" py={6}>
        <SimpleGrid w="full" columns={{ base: 2, md: 4, xl: 8 }} columnGap={4} rowGap={4}>
          {multicolorIcons.map((icon, index) => {
            return (
              <GridItem key={index} border="solid 1px #DDD">
                <AspectRatio ratio={1}>
                  <Box>{icon}</Box>
                </AspectRatio>
                <AspectRatio ratio={1} className="sideNav" borderTop="solid 1px #DDD">
                  <a href="/styleguide" className="active">
                    <Box bg="gradientBrand" className="sideNavSquare" p={3}>
                      {icon}
                    </Box>
                  </a>
                </AspectRatio>
                <AspectRatio
                  ratio={1}
                  className="gradientDark"
                  borderTop="solid 1px #DDD"
                >
                  <Box>
                    <Box
                      bg="gradientDark"
                      className="multicolorDarkBg"
                      p={3}
                      borderRadius="15px"
                    >
                      {icon}
                    </Box>
                  </Box>
                </AspectRatio>
                {/* <AspectRatio ratio={1} bg="gradientDark">
                  <Box bg="gradientDark" className="multicolorDarkBg">
                    {icon}
                  </Box>
                </AspectRatio> */}
              </GridItem>
            );
          })}
        </SimpleGrid>
      </Container>
    </Container>
  );
}

export default MulticolorIcons;

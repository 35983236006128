import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  VStack,
  Heading,
  Text,
  Box,
  Stack,
  useToast,
  useColorModeValue,
} from '@chakra-ui/react';
import CustomToast from '../../common/CustomToast';

const DeletePassTemplateModal = (props) => {
  const toast = useToast();

  const modeText = useColorModeValue('secondaryDark08', '#DDDDDD');

  async function deletePassTemplate(e) {
    e.preventDefault();

    props.onClose();
    toast({
      render: (props) => (
        <CustomToast
          status="success"
          title="Pass template has been deleted"
          description=""
          onClose={props.onClose}
        />
      ),
    });
  }

  return (
    <Modal onClose={props.onClose} isOpen={props.isOpen} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Heading fontSize={{ base: '24px', lg: '32px' }} lineHeight="130%">
            Delete pass template
          </Heading>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack spacing="30px" alignItems="flex-start">
            <Box>
              <Text textStyle="bodyFamilyMedium" color={modeText}>
                Are you sure you want to delete this pass template? This action can not be
                undone.
              </Text>
            </Box>
          </VStack>
        </ModalBody>
        <ModalFooter>
          <Stack
            spacing="10px"
            direction={{ base: 'column', sm: 'row' }}
            width={{ base: 'full', sm: 'auto' }}
          >
            <Button
              onClick={props.onClose}
              alt="Cancel"
              variant="secondary"
              width={{ base: 'full', sm: 'auto' }}
            >
              Cancel
            </Button>
            <Button
              alt="Delete"
              width={{ base: 'full', sm: 'auto' }}
              onClick={deletePassTemplate}
            >
              Delete
            </Button>
          </Stack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default DeletePassTemplateModal;

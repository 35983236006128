import {
  Text,
  Heading,
  Box,
  Container,
  Link,
  Button,
  // Switch,
  useColorMode,
  useColorModeValue,
} from '@chakra-ui/react';

function Intro() {
  const { colorMode, toggleColorMode } = useColorMode();

  return (
    <Container bg={useColorModeValue('brand', 'primaryDark')}>
      <Container maxW="1180px" color="white" px="0">
        <Heading fontSize={{ base: '36px', lg: '6vw' }} py="4vw">
          UI Components
        </Heading>
        <Box maxW="880px" pb="4vw">
          <Text
            textStyle="bodyFamilyMedium"
            fontSize={{ base: '18px', lg: '24px' }}
            lineHeight="normal"
          >
            This UI Kit is a live inventory of UI components, design patterns, brand
            assets and guidelines for the purpose of the designers and developers to build
            a cohesive and consistent experience.
          </Text>
          <Text
            as="div"
            mt={3}
            textStyle="bodyFamilyMedium"
            fontSize="16px"
            lineHeight="normal"
          >
            Note: DE - FE - RE process is described inside{' '}
            <Link
              href="https://git.etondigital.com/symfony/passentry-app/-/tree/sprint-2"
              target="blank"
              textDecoration="underline"
            >
              ReadMe.md
            </Link>{' '}
            file on GitLab.
          </Text>
          <Button variant="secondary" size="sm" mt={4} onClick={toggleColorMode}>
            Toggle {colorMode === 'light' ? 'Dark' : 'Light'}
          </Button>
          {/* <Box bg="brand">
            <Switch onChange={toggleColorMode} isChecked={colorMode === 'light'} />
          </Box> */}
        </Box>
      </Container>
    </Container>
  );
}

export default Intro;

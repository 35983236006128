import { Icon } from '@chakra-ui/react';

export const CustomSearchIcon = () => {
  return (
    <Icon>
      <svg width="26" height="25" viewBox="0 0 26 25" fill="none">
        <path
          d="M24.914 21.4844L16.0819 13.8593C15.5191 13.3734 14.5534 13.5696 13.925 14.2974C13.2966 15.0253 13.2434 16.0093 13.8063 16.4952L22.6383 24.1203C23.2011 24.6062 24.1668 24.41 24.7952 23.6821C25.4236 22.9542 25.4768 21.9703 24.914 21.4844Z"
          fill="#F47467"
        />
        <path
          d="M17.2359 9.36795C17.2359 13.7133 13.7133 17.2359 9.36795 17.2359C5.0226 17.2359 1.5 13.7133 1.5 9.36795C1.5 5.0226 5.0226 1.5 9.36795 1.5C13.7133 1.5 17.2359 5.0226 17.2359 9.36795Z"
          stroke="url(#paint0_linear_9730_2663)"
          strokeWidth="3"
        />
        <mask
          id="mask0_9730_2663"
          maskUnits="userSpaceOnUse"
          x="3"
          y="3"
          width="13"
          height="13"
        >
          <path
            d="M14.5 9.5C14.5 12.2614 12.2614 14.5 9.5 14.5C6.73858 14.5 4.5 12.2614 4.5 9.5C4.5 6.73858 6.73858 4.5 9.5 4.5C12.2614 4.5 14.5 6.73858 14.5 9.5Z"
            fill="white"
            stroke="url(#paint1_linear_9730_2663)"
            strokeWidth="3"
          />
        </mask>
        <g mask="url(#mask0_9730_2663)"></g>
        <defs>
          <linearGradient
            id="paint0_linear_9730_2663"
            x1="0.483507"
            y1="18.7359"
            x2="16.8148"
            y2="16.3306"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FF8477" />
            <stop offset="1" stopColor="#EC6759" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_9730_2663"
            x1="3.33548"
            y1="16"
            x2="14.667"
            y2="14.3311"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FF8477" />
            <stop offset="1" stopColor="#EC6759" />
          </linearGradient>
        </defs>
      </svg>
    </Icon>
  );
};

import appsignal from './appsignal';
import { ErrorBoundary } from '@appsignal/react';
import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import 'mapbox-gl/dist/mapbox-gl.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ChakraProvider, ColorModeScript } from '@chakra-ui/react';
import { customTheme } from './theme/customTheme';
import Fonts from './theme/Fonts';

// const port = process.env.PORT || 8080;

createRoot(document.getElementById('root')).render(
  <ErrorBoundary
    instance={appsignal}
    // tags={{ tag: "value" }}
  >
    <React.StrictMode>
      <ColorModeScript initialColorMode={customTheme.config.initialColorMode} />
      <ChakraProvider resetCSS theme={customTheme}>
        <Fonts />
        <App />
      </ChakraProvider>
    </React.StrictMode>
  </ErrorBoundary>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// App.listen(port, () => {
//   console.log(`Server listening on port ${port}`);
// });

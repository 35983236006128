import { Icon } from '@chakra-ui/react';

export const CustomDeleteMultiIcon = (props) => (
  <Icon viewBox="0 0 58 58" {...props}>
    <path
      className="multicolorElToDark darkBgElToWhite"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M40.7678 20.7678C41.7441 19.7915 41.7441 18.2085 40.7678 17.2322C39.7915 16.2559 38.2085 16.2559 37.2322 17.2322L28.5001 25.9644L19.7679 17.2322C18.7916 16.2559 17.2087 16.2559 16.2324 17.2322C15.2561 18.2085 15.2561 19.7915 16.2324 20.7678L24.9645 29.4999L16.2322 38.2322C15.2559 39.2085 15.2559 40.7915 16.2322 41.7678C17.2085 42.7441 18.7915 42.7441 19.7678 41.7678L28.5001 33.0355L37.2324 41.7678C38.2087 42.7441 39.7916 42.7441 40.7679 41.7678C41.7442 40.7915 41.7442 39.2085 40.7679 38.2322L32.0356 29.4999L40.7678 20.7678Z"
      fill="#FF8477"
    />
    <path
      className="multicolorElToWhite darkBgElToBrand"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M29 53C42.2548 53 53 42.2548 53 29C53 15.7452 42.2548 5 29 5C15.7452 5 5 15.7452 5 29C5 42.2548 15.7452 53 29 53ZM29 58C45.0163 58 58 45.0163 58 29C58 12.9837 45.0163 0 29 0C12.9837 0 0 12.9837 0 29C0 45.0163 12.9837 58 29 58Z"
      fill="#29323A"
    />
  </Icon>
);

import { Icon } from '@chakra-ui/react';

export const CustomDashboardMultiIcon = (props) => (
  <Icon viewBox="0 0 54 62" {...props}>
    <path
      className="multicolorElToWhite darkBgElToBrand"
      d="M53.0649 23.9008L28.0519 0.709924C27.5844 0.236641 26.8831 0 26.1818 0C25.4805 0 24.7792 0.236641 24.3117 0.709924L0.701299 24.1374C0.233766 24.6107 0 25.3206 0 26.0305V59.3969C0 60.8168 1.16883 62 2.57143 62H12.3896C13.7922 62 14.961 60.8168 14.961 59.3969C14.961 57.9771 13.7922 56.7939 12.3896 56.7939H5.14286L4.90909 27.2137L26.1818 6.15267L48.6234 26.7405V56.7939H41.3766C39.974 56.7939 38.8052 57.9771 38.8052 59.3969C38.8052 60.8168 39.974 62 41.3766 62H51.4286C52.8312 62 54 60.8168 54 59.3969V25.7939C54 25.084 53.7662 24.6107 53.0649 23.9008Z"
      fill="#29323A"
    />
    <path
      className="multicolorElToDark darkBgElToWhite"
      d="M26.8831 39.0458C26.6494 39.0458 26.1818 39.0458 25.9481 39.0458C21.039 39.5191 17.0649 44.0153 17.0649 49.458V59.397C17.0649 60.8168 18.2338 62 19.6364 62C21.039 62 22.2078 60.8168 22.2078 59.397V48.9847C22.2078 46.1451 24.3117 44.0153 27.1169 44.0153C27.3507 44.0153 27.8182 44.0153 28.052 44.0153C30.3896 44.4886 32.026 46.6183 32.026 49.2214V59.397C32.026 60.8168 33.1948 62 34.5974 62C36 62 37.1688 60.8168 37.1688 59.397V48.9847C36.9351 43.542 32.4935 39.0458 26.8831 39.0458Z"
      fill="#FF8477"
    />
  </Icon>
);

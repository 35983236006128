import {
  Box,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Hide,
  Show,
  Text,
  useColorModeValue,
  Code,
  useClipboard,
  useToast,
  Tooltip,
} from '@chakra-ui/react';
import { useEffect } from 'react';
import CustomToast from '../../../common/CustomToast';

const ReaderField = ({ field, index, readerFields }) => {
  const modeFormControlBg = useColorModeValue('#fff', 'secondaryDark');
  const modeLabelBg = useColorModeValue('#EFEFEF', 'primaryDark03');
  const modeBorderColor = useColorModeValue('primaryBackground', 'primaryDark');
  const { onCopy, hasCopied } = useClipboard(field.value);
  const toast = useToast();
  const bgTooltip = useColorModeValue('secondaryDark', '#dddddd');
  const textTooltip = useColorModeValue('#ffffff', 'primaryDark');

  const isCode =
    field.name === 'NFC Private Key' ||
    field.name === 'Reader Configuration API Key';

  const onCopyHandler = () => {
    onCopy();
  };

  useEffect(() => {
    if (hasCopied) {
      toast({
        render: (props) => (
          <CustomToast
            status="success"
            title={'Copied to clipboard'}
            description=""
            onClose={props.onClose}
          />
        ),
      });
    }
  }, [hasCopied]);

  return (
    <Box key={index}>
      <FormControl bg={modeFormControlBg}>
        <Flex alignItems="center">
          <Hide below="md">
            <Box pl="24px" flexShrink={0} w="240px">
              <FormLabel m={0}>{field.name}</FormLabel>
            </Box>
          </Hide>
          <Box p="14px" w="full" bg={modeLabelBg}>
            <Show below="md">
              <FormLabel pl="10px">{field.name}</FormLabel>
            </Show>
            {isCode ? (
              <Tooltip
                label="Click to copy"
                isDisabled={hasCopied}
                hasArrow
                placement="right"
                fontSize="12px"
                borderRadius="6px"
                p="10px"
                bg={bgTooltip}
                color={textTooltip}
              >
                <pre
                  style={{
                    whiteSpace: 'pre-wrap',
                    overflowX: 'auto',
                    margin: '0',
                    padding: '3px',
                    fontFamily: 'monospace',
                  }}
                >
                  <Code
                    p={3}
                    wordBreak="break-all"
                    backgroundColor="inherit"
                    onClick={onCopyHandler}
                    cursor="pointer"
                  >
                    {field.value}
                  </Code>
                </pre>
              </Tooltip>
            ) : (
              <Text p={3} wordBreak="break-word">
                {field.value}
              </Text>
            )}
          </Box>
        </Flex>
      </FormControl>
      <Divider borderColor={modeBorderColor} />
    </Box>
  );
};

export default ReaderField;

import { useState, useEffect, useContext } from 'react';
import {
  useColorModeValue,
  Box,
  FormControl,
  FormLabel,
  Input,
  RadioGroup,
  Radio,
  Stack,
  Tooltip,
  Flex,
  Text,
  Button,
  FormErrorMessage,
  useToast,
  Divider,
  HStack,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Link,
  Hide,
  Show,
  Spinner,
  Alert,
  AlertIcon,
} from '@chakra-ui/react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import appsignal from '../../../appsignal';
import { HttpContext } from '../../../context/HttpContext';
import CustomToast from '../../../common/CustomToast';
import { CustomExclamationMarkInverseIcon } from '../../../theme/icons/CustomExclamationMarkInverseIcon';

const ReaderConfigSettings = ({ entityId }) => {
  const { authAxios } = useContext(HttpContext);
  const toast = useToast();
  const modeBoxBg = useColorModeValue('gradient', 'secondaryDark');
  const modeFormControlBg = useColorModeValue('white', '');
  const modeLabelBg = useColorModeValue('#EFEFEF', 'primaryDark03');
  const modeTextBg = useColorModeValue('quaternaryBackground', 'primaryDark06');
  const modeBorderColor = useColorModeValue('primaryBackground', 'primaryDark');
  const bgTooltip = useColorModeValue('secondaryDark', '#dddddd');
  const textTooltip = useColorModeValue('#ffffff', 'primaryDark');
  const [isLoading, setIsLoading] = useState(true);

  // Define validation schema
  const validationSchema = Yup.object().shape({
    validationType: Yup.string().required('Please select a validation Type'),
    externalEndpoint: Yup.string().when('validationType', {
      is: 'external',
      then: (schema) =>
        schema
          .required('External endpoint URL is required')
          .url('Please enter a valid URL'),
      otherwise: (schema) => schema.notRequired().nullable(),
    }),
    validityPeriodHours: Yup.number()
      .transform((value) => (isNaN(value) ? 0 : value))
      .min(0, 'Hours cannot be negative')
      .integer('Hours must be an integer'),
    validityPeriodMinutes: Yup.number()
      .transform((value) => (isNaN(value) ? 0 : value))
      .min(0, 'Minutes cannot be negative')
      .max(59, 'Minutes cannot exceed 59')
      .integer('Minutes must be an integer'),
  });

  const {
    register,
    handleSubmit,
    control,
    watch,
    reset,
    formState: { errors, isSubmitting, isDirty, isValid },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
    defaultValues: {
      validationType: 'internal',
      externalEndpoint: '',
      validityPeriodHours: '',
      validityPeriodMinutes: '',
    },
    shouldUnregister: false,
  });

  const validationType = watch('validationType');

  // Fetch data from the backend to prepopulate values
  useEffect(() => {
    async function fetchData() {
      try {
        const response = await authAxios.get(
          `/api/v1/entities/${entityId}/validation-configuration`
        );
        const data = response.data.data;
        const fetchedValidationType =
          data.attributes.validationType || 'internal';
        const fetchedExternalEndpoint =
          data.attributes.externalEndpointUrl || '';
        const totalSeconds = data.attributes.validityPeriod;
        const hours = totalSeconds ? Math.floor(totalSeconds / 3600) : '';
        const minutes = totalSeconds
          ? Math.floor((totalSeconds % 3600) / 60)
          : '';

        reset({
          validationType: fetchedValidationType,
          externalEndpoint: fetchedExternalEndpoint,
          validityPeriodHours: hours,
          validityPeriodMinutes: minutes,
        });
        setIsLoading(false);
      } catch (error) {
        appsignal.sendError(error);
        toast({
          render: ({ onClose }) => (
            <CustomToast
              status="error"
              title="Error fetching reader config data"
              description="Please try again later."
              onClose={onClose}
            />
          ),
        });
        setIsLoading(false);
      }
    }
    fetchData();
  }, [authAxios, entityId, toast, reset]);

  const onSubmit = async (data) => {
    try {
      let payload = {
        validationConfiguration: {
          validationType: data.validationType,
        },
      };

      if (data.validationType === 'internal') {
        const hoursInSeconds = parseInt(data.validityPeriodHours || 0) * 3600;
        const minutesInSeconds = parseInt(data.validityPeriodMinutes || 0) * 60;
        const totalValidityPeriod = hoursInSeconds + minutesInSeconds;

        payload.validationConfiguration.validityPeriod =
          totalValidityPeriod === 0 ? null : totalValidityPeriod;
        payload.validationConfiguration.externalEndpointUrl = null;
      } else if (data.validationType === 'external') {
        payload.validationConfiguration.externalEndpointUrl =
          data.externalEndpoint;
        payload.validationConfiguration.validityPeriod = null;
      }
      await authAxios.patch(
        `/api/v1/entities/${entityId}/validation-configuration`,
        payload
      );

      reset(data);

      toast({
        render: ({ onClose }) => (
          <CustomToast
            status="success"
            title="Validation settings updated successfully"
            onClose={onClose}
          />
        ),
      });
    } catch (error) {
      appsignal.sendError(error);
      toast({
        render: ({ onClose }) => (
          <CustomToast
            status="error"
            title="Error updating validation settings."
            description="Please try again later."
            onClose={onClose}
          />
        ),
      });
    }
  };

  if (isLoading) {
    return (
      <Spinner
        width="52px"
        height="52px"
        thickness="4px"
        speed="0.65s"
        emptyColor="quinaryBackground"
        mt={4}
        mx="auto"
      />
    );
  }

  return (
    <>
      <Box className="autofillForDarkBg" w="full">
        <Box className="primaryBoxShadow" bg={modeBoxBg} borderRadius="15px">
          <form onSubmit={handleSubmit(onSubmit)}>
            <Text
              fontSize="18px"
              textStyle="headingFamilyMedium"
              p="14px 14px 14px 24px"
              bg={modeTextBg}
              borderRadius="15px 15px 0 0"
            >
              Validation Settings
            </Text>
            <Divider borderColor={modeBorderColor} />
            {/* Validation Type */}
            <FormControl
              isRequired
              isInvalid={errors.validationType}
              bg={modeFormControlBg}
            >
              <Flex alignItems="center">
                <Hide below="md">
                  <Box flexShrink={0} w="240px" pl="24px">
                    <FormLabel m={0}>Validation Type</FormLabel>
                  </Box>
                </Hide>
                <Box p="19px" w="full" bg={modeLabelBg}>
                  <Show below="md">
                    <FormLabel mb={3}>Validation Type</FormLabel>
                  </Show>
                  <Controller
                    name="validationType"
                    control={control}
                    render={({ field }) => (
                      <RadioGroup {...field}>
                        <Stack direction="row" spacing={4}>
                          <Radio value="internal">PassEntry Validation</Radio>
                          <Radio value="external">Third-Party Validation</Radio>
                        </Stack>
                      </RadioGroup>
                    )}
                  />
                  <FormErrorMessage mt="4px">
                    {errors.validationType && errors.validationType.message}
                  </FormErrorMessage>
                </Box>
              </Flex>
            </FormControl>
            <Divider borderColor={modeBorderColor} />
            <Text
              p="14px 14px 14px 24px"
              bg={modeFormControlBg}
              fontSize="14px"
            >
              {validationType === 'internal' ? (
                "The internal PassEntry validation provides a simple and convenient out-of-the-box solution that ensures the pass is valid and active and, if a validity period is configured, that it hasn't been scanned within that timeframe."
              ) : (
                <>
                  Third-Party Validation is highly flexible, allowing you to
                  route reader scans through your server, decide whether to
                  accept or reject them based on your internal logic, and
                  customise the reader's response. If you have any questions
                  about setting up external validation, please{' '}
                  <Link
                    textStyle="bodyBold"
                    fontSize="14px"
                    href="mailto:devteam@passentry.com"
                    color="secondaryDark"
                  >
                    contact the PassEntry team
                  </Link>
                  .
                </>
              )}
            </Text>
            <Divider borderColor={modeBorderColor} />
            {/* External Endpoint URL */}
            {validationType === 'external' && (
              <FormControl
                isRequired
                isInvalid={errors.externalEndpoint}
                bg={modeFormControlBg}
              >
                <Flex alignItems="center">
                  <Hide below="md">
                    <Box flexShrink={0} w="240px" pl="24px">
                      <HStack alignItems="center">
                        <FormLabel m={0}>External Endpoint URL</FormLabel>
                        <Tooltip
                          label={
                            'Provide the URL of your external validation service. This endpoint will be called to validate passes externally.'
                          }
                          hasArrow
                          placement="top"
                          fontSize="12px"
                          bg={bgTooltip}
                          color={textTooltip}
                          w="205px"
                          borderRadius="6px"
                          textAlign="center"
                          p="10px"
                        >
                          <Box mb={1} cursor="pointer">
                            <CustomExclamationMarkInverseIcon
                              boxSize="14px"
                              mode={'light'}
                              ml={1}
                            />
                          </Box>
                        </Tooltip>
                      </HStack>
                    </Box>
                  </Hide>
                  <Box p="19px" w="full" bg={modeLabelBg}>
                    <Show below="md">
                      <HStack alignItems="center">
                        <FormLabel mb={3} mr={0}>
                          External Endpoint URL
                        </FormLabel>
                        <Tooltip
                          label={
                            'Provide the URL of your external validation service. This endpoint will be called to validate passes externally.'
                          }
                          hasArrow
                          placement="top"
                          fontSize="12px"
                          bg={bgTooltip}
                          color={textTooltip}
                          w="205px"
                          borderRadius="6px"
                          textAlign="center"
                          p="10px"
                        >
                          <Box mb={1} cursor="pointer">
                            <CustomExclamationMarkInverseIcon
                              boxSize="14px"
                              mode={'light'}
                              mb={2}
                            />
                          </Box>
                        </Tooltip>
                      </HStack>
                    </Show>
                    <Input
                      type="text"
                      placeholder="https://example.com/validate"
                      {...register('externalEndpoint')}
                    />
                    <FormErrorMessage mt="4px">
                      {errors.externalEndpoint &&
                        errors.externalEndpoint.message}
                    </FormErrorMessage>
                  </Box>
                </Flex>
              </FormControl>
            )}

            {/* Validity Period */}
            {validationType === 'internal' && (
              <FormControl
                isInvalid={
                  errors.validityPeriodHours || errors.validityPeriodMinutes
                }
                bg={modeFormControlBg}
              >
                <Flex alignItems="center">
                  <Hide below="md">
                    <Box flexShrink={0} w="240px" pl="24px">
                      <HStack alignItems="center">
                        <FormLabel m={0}>Validity Period</FormLabel>
                        <Tooltip
                          label={
                            'Set the validity period to control how often a pass can be tapped/scanned.'
                          }
                          hasArrow
                          placement="top"
                          fontSize="12px"
                          bg={bgTooltip}
                          color={textTooltip}
                          w="205px"
                          borderRadius="6px"
                          textAlign="center"
                          p="10px"
                        >
                          <Box mb={1} cursor="pointer">
                            <CustomExclamationMarkInverseIcon
                              boxSize="14px"
                              mode={'light'}
                              ml={1}
                            />
                          </Box>
                        </Tooltip>
                      </HStack>
                    </Box>
                  </Hide>
                  <Box p="19px" w="full" bg={modeLabelBg}>
                    <Show below="md">
                      <HStack alignItems="center">
                        <FormLabel mb={3} mr={0}>
                          Validity Period
                        </FormLabel>
                        <Tooltip
                          label={
                            'Set the validity period to control how often a pass can be tapped/scanned.'
                          }
                          hasArrow
                          placement="top"
                          fontSize="12px"
                          bg={bgTooltip}
                          color={textTooltip}
                          w="205px"
                          borderRadius="6px"
                          textAlign="center"
                          p="10px"
                        >
                          <Box mb={1} cursor="pointer">
                            <CustomExclamationMarkInverseIcon
                              boxSize="14px"
                              mode={'light'}
                              mb={2}
                            />
                          </Box>
                        </Tooltip>
                      </HStack>
                    </Show>
                    <HStack spacing={4} alignItems="center">
                      <Controller
                        name="validityPeriodHours"
                        control={control}
                        render={({ field }) => (
                          <HStack>
                            <NumberInput
                              size="md"
                              maxW={20}
                              min={0}
                              max={999}
                              {...field}
                              onChange={(value) => field.onChange(value)}
                            >
                              <NumberInputField placeholder="0" />
                              <NumberInputStepper>
                                <NumberIncrementStepper />
                                <NumberDecrementStepper />
                              </NumberInputStepper>
                            </NumberInput>
                            <Text>HR</Text>
                          </HStack>
                        )}
                      />

                      <Controller
                        name="validityPeriodMinutes"
                        control={control}
                        render={({ field }) => (
                          <HStack>
                            <NumberInput
                              size="md"
                              maxW={20}
                              min={0}
                              max={59}
                              {...field}
                              onChange={(value) => field.onChange(value)}
                            >
                              <NumberInputField placeholder="0" />
                              <NumberInputStepper>
                                <NumberIncrementStepper />
                                <NumberDecrementStepper />
                              </NumberInputStepper>
                            </NumberInput>
                            <Text>MIN</Text>
                          </HStack>
                        )}
                      />
                    </HStack>
                    {(errors.validityPeriodHours ||
                      errors.validityPeriodMinutes) && (
                      <FormErrorMessage mt="4px">
                        {errors.validityPeriodHours?.message ||
                          errors.validityPeriodMinutes?.message}
                      </FormErrorMessage>
                    )}
                  </Box>
                </Flex>
              </FormControl>
            )}
            <Divider borderColor={modeBorderColor} />

            {/* Submit Button */}
            <Flex justifyContent="flex-end" p="19px">
              <Button
                type="submit"
                isLoading={isSubmitting}
                isDisabled={isSubmitting || !isDirty || !isValid}
              >
                Save
              </Button>
            </Flex>
          </form>
        </Box>
        <Alert status="warning" borderRadius="15px" fontSize="14px" mt={4}>
          <AlertIcon color="brandDark" />
          Please note: Any changes made to the validation settings will result
          in the automatic logout of all readers belonging to this entity.
        </Alert>
      </Box>
    </>
  );
};

export default ReaderConfigSettings;

import { Icon } from '@chakra-ui/react';

export const CustomBellMultiIcon = (props) => (
  <Icon viewBox="0 0 53 58" {...props}>
    <path
      className="multicolorElToDark darkBgElToWhite"
      d="M26.0299 0C21.5136 0 17.948 3.56556 17.948 8.08193V10.9344H22.7021V8.08193C22.9398 6.41801 24.366 4.75408 26.0299 4.75408C27.6939 4.75408 29.1201 6.1803 29.1201 7.84423V10.6967H33.8742V8.08193C34.1119 3.56556 30.5463 0 26.0299 0Z"
      fill="#FF8477"
    />
    <path
      className="multicolorElToWhite darkBgElToBrand"
      d="M51.7006 47.3034L46.2334 38.746V27.8116C46.2334 16.6396 37.2007 7.3691 26.0286 7.3691C14.8565 7.3691 5.82375 16.6396 5.82375 27.8116V38.746L0.356556 47.3034C-0.118852 48.0165 -0.118852 48.9673 0.356556 49.9181C0.831964 50.6312 1.54508 51.1066 2.49589 51.1066H16.9958C18.1844 55.1476 21.9876 58 26.2663 58H26.0286C30.3073 58 34.1105 55.1476 35.299 51.1066H49.799C50.7498 51.1066 51.4629 50.6312 51.9383 49.9181C52.4137 48.9673 52.176 48.0165 51.7006 47.3034ZM26.0286 53.0083C24.3647 53.0083 22.9384 52.2951 21.9876 50.8689H30.0696C29.1187 52.2951 27.6925 53.0083 26.0286 53.0083ZM41.717 40.6477L45.2826 46.1148H6.77456L10.3401 40.6477C10.5778 40.1722 10.8155 39.6968 10.8155 39.4591V27.8116C10.5778 19.0166 17.4712 12.1232 26.0286 12.1232C34.5859 12.1232 41.4793 19.0166 41.4793 27.8116V39.4591C41.4793 39.9345 41.717 40.4099 41.717 40.6477Z"
      fill="#29323A"
    />
  </Icon>
);

import { Icon } from '@chakra-ui/react';

export const CustomVisibilityOffIcon = (props) => {
  return (
    <Icon viewBox="0 0 58 51" {...props}>
      <path
        className="multicolorElToDark darkBgElToWhite"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.0168 15.8301C18.3532 18.093 16.6631 21.4671 16.6631 25.236C16.6631 32.0494 22.1864 37.5728 28.9998 37.5728C32.7687 37.5728 36.1429 35.8827 38.4058 33.2191L34.6556 29.4689C33.3671 31.1878 31.3133 32.3 28.9998 32.3C25.0985 32.3 21.9358 29.1374 21.9358 25.236C21.9358 22.9225 23.048 20.8687 24.7669 19.5802L21.0168 15.8301ZM35.6247 22.7788C34.9105 20.8542 33.3817 19.3253 31.4571 18.6112L26.0903 13.2444C27.0232 13.0188 27.9976 12.8993 28.9998 12.8993C35.8132 12.8993 41.3365 18.4227 41.3365 25.236C41.3365 26.2383 41.217 27.2126 40.9914 28.1455L35.6247 22.7788Z"
        fill="#FF8477"
      />
      <path
        className="multicolorElToWhite darkBgElToBrand"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.3068 9.0202C7.82898 12.527 2.72196 18.2798 0 25.2445C4.56091 36.9146 15.8182 45.182 29 45.182C35.1126 45.182 40.8113 43.4043 45.6204 40.3339L41.7791 36.4925C37.9755 38.664 33.6024 39.8653 29 39.8653C19.0082 39.8653 10.0973 34.2031 5.74727 25.2445C8.44023 19.6985 12.8811 15.4158 18.2444 12.9578L14.3068 9.0202ZM46.2245 33.2788C48.6859 31.0632 50.7437 28.3522 52.2527 25.2445C47.9027 16.2859 38.9918 10.6237 29 10.6237C27.3113 10.6237 25.6534 10.7854 24.0432 11.0975L19.6839 6.73815C22.6251 5.80827 25.7542 5.30701 29 5.30701C42.1818 5.30701 53.4391 13.5744 58 25.2445C56.2311 29.7708 53.4548 33.7851 49.9496 37.0038L46.2245 33.2788Z"
        fill="#29323A"
      />
      <path
        className="multicolorElToWhite darkBgElToBrand"
        d="M6.22595 3.05176e-05L52.843 46.617L49.0134 50.4466L2.39635 3.82963L6.22595 3.05176e-05Z"
        fill="#29323A"
      />
    </Icon>
  );
};

import appsignal from '../../../appsignal';
import { useContext } from 'react';
import {
  useToast,
  Tooltip,
  useColorModeValue,
  Spinner,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { CustomBinIcon } from '../../../theme/multicolor-icons/CustomBinIcon';
import CustomToast from '../../../common/CustomToast';
import { getErrorResponsePayload } from '../../../utils/ajax';
import { HttpContext } from '../../../context/HttpContext';

export default function DeleteTemplateButton({ previewData }) {
  const toast = useToast();
  const { authAxios } = useContext(HttpContext);
  const bgTooltip = useColorModeValue('secondaryDark', '#dddddd');
  const textTooltip = useColorModeValue('white', 'primaryDark');

  const {
    handleSubmit,
    formState: { isSubmitting },
    setError,
  } = useForm();

  const onSubmit = async () => {
    try {
      let submittedData = {
        hidden: true,
      };

      await authAxios.patch(
        `passes/templates/${previewData.uuid}`,
        submittedData
      );

      toast({
        render: ({ onClose }) => (
          <CustomToast
            status="success"
            title="Pass template has been deleted"
            description=""
            onClose={onClose}
          />
        ),
      });

      setTimeout(() => {
        window.location.reload(true);
      }, 1000);
    } catch (onError) {
      const { status, message } = getErrorResponsePayload(onError);
      if (status === 422) {
        setError('passTemplate', { type: 'server', message });
      }

      toast({
        render: (props) => (
          <CustomToast
            status="error"
            title="This pass template can't be deleted"
            description="It has passes associated to it."
            onClose={props.onClose}
            id={props.id}
          />
        ),
      });
    }
  };

  return (
    <Tooltip
      label="Delete template"
      hasArrow
      placement="top"
      fontSize="12px"
      color={textTooltip}
      w="180px"
      borderRadius="6px"
      bg={bgTooltip}
      textAlign="center"
      p="10px"
      closeOnClick={false}
    >
      <form>
        <button
          data-testid="delete-button"
          type="submit"
          onClick={handleSubmit(onSubmit)}
        >
          {isSubmitting ? (
            <Spinner
              width="15px"
              height="15px"
              thickness="2px"
              speed="0.65s"
              emptyColor="quinaryBackground"
              mx="auto"
            />
          ) : (
            <CustomBinIcon />
          )}
        </button>
      </form>
    </Tooltip>
  );
}

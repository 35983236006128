import { Icon } from '@chakra-ui/react';

export const CustomFilterIcon = (props) => (
  <Icon viewBox="0 0 31 30" {...props}>
    <path
      fill="currentColor"
      d="M0.157371 0.95974C0.43167 0.3741 1.01999 0 1.66669 0H29.0171C29.6638 0 30.2521 0.3741 30.5264 0.95974C30.8007 1.54538 30.7115 2.23683 30.2975 2.73364L19.939 15.1638V23.2654C19.939 23.7495 19.7285 24.2096 19.3624 24.5261L13.5016 29.594C13.0083 30.0206 12.3115 30.1202 11.7184 29.849C11.1252 29.5778 10.7448 28.9855 10.7448 28.3333V15.1638L0.386318 2.73364C-0.0276865 2.23683 -0.116928 1.54538 0.157371 0.95974ZM5.22509 3.33333L13.6919 13.4935C13.9415 13.793 14.0782 14.1705 14.0782 14.5604V24.6888L16.6056 22.5033V14.5604C16.6056 14.1705 16.7423 13.793 16.9919 13.4935L25.4587 3.33333H5.22509Z"
    />
  </Icon>
);

import { Icon } from '@chakra-ui/react';

export const CustomLiveEventDataMultiIcon = (props) => (
  <Icon viewBox="0 0 19 25" {...props}>
    <path
      className="multicolorElToDark darkBgElToWhite"
      d="M11.3671 15.0957C11.7537 15.0957 12.0671 15.4091 12.0671 15.7957V19.7411L9.46295 21.1343C9.12206 21.3166 8.69789 21.1881 8.51553 20.8472C8.33316 20.5064 8.46167 20.0822 8.80256 19.8998L10.6671 18.9023V15.7957C10.6671 15.4091 10.9805 15.0957 11.3671 15.0957Z"
      fill="#FF8477"
    />
    <path
      className="multicolorElToWhite darkBgElToBrand"
      d="M13.6795 2.04258C13.5138 2.04258 13.3795 2.17689 13.3795 2.34258V11.1445C13.3795 11.5311 13.0661 11.8445 12.6795 11.8445C12.2929 11.8445 11.9795 11.5311 11.9795 11.1445V2.34258C11.9795 1.40369 12.7406 0.642578 13.6795 0.642578H15.1442C16.0831 0.642578 16.8442 1.40369 16.8442 2.34258V13.2247C16.8442 13.6113 16.5308 13.9247 16.1442 13.9247C15.7576 13.9247 15.4442 13.6113 15.4442 13.2247V2.34258C15.4442 2.17689 15.3099 2.04258 15.1442 2.04258H13.6795Z"
      fill="#29323A"
    />
    <path
      className="multicolorElToWhite darkBgElToBrand"
      d="M8.78145 5.4332C8.94713 5.4332 9.08145 5.56752 9.08145 5.7332V11.0681C9.08145 11.4547 9.39485 11.7681 9.78145 11.7681C10.168 11.7681 10.4814 11.4547 10.4814 11.0681V5.7332C10.4814 4.79432 9.72033 4.0332 8.78145 4.0332H7.31669C6.37781 4.0332 5.61669 4.79432 5.61669 5.7332V13.2266C5.61669 13.6132 5.93009 13.9266 6.31669 13.9266C6.70329 13.9266 7.01669 13.6132 7.01669 13.2266V5.7332C7.01669 5.56752 7.15101 5.4332 7.31669 5.4332H8.78145Z"
      fill="#29323A"
    />
    <path
      fillRule="evenodd"
      className="multicolorElToWhite darkBgElToBrand"
      d="M1.54844 9.72617V15.3444H3.02416V9.72617H1.54844ZM1.14844 8.32617C0.596153 8.32617 0.148438 8.77389 0.148438 9.32617V16.2444C0.148438 16.5205 0.372295 16.7444 0.648438 16.7444H3.92416C4.20031 16.7444 4.42416 16.5205 4.42416 16.2444V9.32617C4.42416 8.77389 3.97645 8.32617 3.42416 8.32617H1.14844Z"
      fill="#29323A"
    />
    <path
      className="multicolorElToDark darkBgElToWhite"
      d="M6.52344 18.5629C6.52344 16.0245 8.58117 13.9668 11.1195 13.9668C13.2702 13.9668 15.0759 15.444 15.5772 17.4392H14.2199C14.0563 17.4392 13.9619 17.6251 14.0585 17.7572L16.1311 20.5939C16.211 20.7032 16.3742 20.7032 16.4541 20.5939L18.5267 17.7572C18.6233 17.6251 18.5289 17.4392 18.3652 17.4392H17.1123C16.5849 14.6092 14.1024 12.4668 11.1195 12.4668C7.75274 12.4668 5.02344 15.1961 5.02344 18.5629C5.02344 21.9296 7.75274 24.659 11.1195 24.659C12.9616 24.659 14.6129 23.8419 15.7307 22.5504L14.5784 21.5895C13.736 22.5515 12.4987 23.159 11.1195 23.159C8.58117 23.159 6.52344 21.1012 6.52344 18.5629Z"
      fill="#FF8477"
    />
    <path
      className="multicolorElToDark darkBgElToWhite"
      d="M14.0585 17.7572C13.9834 17.6545 14.0237 17.5194 14.1222 17.4643C14.1504 17.4484 14.1835 17.4392 14.2199 17.4392H15.5772C15.4503 16.9342 15.2399 16.4623 14.9614 16.0392C14.1397 14.7908 12.7258 13.9668 11.1195 13.9668C8.58117 13.9668 6.52344 16.0245 6.52344 18.5629C6.52344 21.1012 8.58117 23.159 11.1195 23.159C12.4987 23.159 13.736 22.5515 14.5784 21.5895L15.7307 22.5504C15.4219 22.9072 15.0724 23.2278 14.6893 23.5049C13.686 24.231 12.4527 24.659 11.1195 24.659C7.75274 24.659 5.02344 21.9296 5.02344 18.5629C5.02344 15.1961 7.75274 12.4668 11.1195 12.4668C14.0693 12.4668 16.5297 14.5618 17.0939 17.3451C17.1003 17.3764 17.1064 17.4078 17.1123 17.4392H18.3652C18.5289 17.4392 18.6233 17.6251 18.5267 17.7572L16.4541 20.5939C16.3742 20.7032 16.211 20.7032 16.1311 20.5939L14.0585 17.7572Z"
      fill="#FF8477"
    />
  </Icon>
);

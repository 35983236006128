import { Icon } from '@chakra-ui/react';

export const CustomDropzoneMultiIcon = (props) => (
  <Icon viewBox="0 0 38 22" {...props}>
    <path
      className="multicolorElToWhite darkBgElToBrand"
      d="M18 10C17.4067 10 16.8266 9.82405 16.3333 9.49441C15.8399 9.16477 15.4554 8.69623 15.2284 8.14805C15.0013 7.59987 14.9419 6.99667 15.0576 6.41473C15.1734 5.83279 15.4591 5.29824 15.8787 4.87868C16.2982 4.45912 16.8328 4.1734 17.4147 4.05765C17.9967 3.94189 18.5999 4.0013 19.1481 4.22836C19.6962 4.45543 20.1648 4.83994 20.4944 5.33329C20.8241 5.82664 21 6.40666 21 7C21 7.79565 20.6839 8.55871 20.1213 9.12132C19.5587 9.68393 18.7957 10 18 10ZM18 6C17.8022 6 17.6089 6.05865 17.4444 6.16853C17.28 6.27841 17.1518 6.43459 17.0761 6.61732C17.0004 6.80004 16.9806 7.00111 17.0192 7.19509C17.0578 7.38907 17.153 7.56726 17.2929 7.70711C17.4327 7.84696 17.6109 7.9422 17.8049 7.98079C17.9989 8.01937 18.2 7.99957 18.3827 7.92388C18.5654 7.84819 18.7216 7.72002 18.8315 7.55557C18.9414 7.39112 19 7.19778 19 7C19 6.73479 18.8946 6.48043 18.7071 6.2929C18.5196 6.10536 18.2652 6 18 6Z"
      fill="#29323A"
    />
    <path
      className="multicolorElToWhite darkBgElToBrand"
      d="M22.9996 22.0001C22.8349 21.9992 22.6729 21.9577 22.5281 21.8792C22.3833 21.8007 22.2601 21.6876 22.1696 21.5501L17.8296 15.0501C17.7381 14.914 17.6145 14.8024 17.4698 14.7253C17.325 14.6482 17.1636 14.6078 16.9996 14.6078C16.8356 14.6078 16.6741 14.6482 16.5293 14.7253C16.3846 14.8024 16.261 14.914 16.1696 15.0501L15.8296 15.5501C15.6733 15.7441 15.4501 15.8728 15.2039 15.911C14.9577 15.9491 14.7061 15.894 14.4984 15.7564C14.2907 15.6187 14.1419 15.4085 14.081 15.1669C14.0202 14.9253 14.0518 14.6696 14.1696 14.4501L14.4996 13.9401C14.7732 13.5275 15.1447 13.1891 15.5809 12.955C16.0171 12.7209 16.5045 12.5984 16.9996 12.5984C17.4946 12.5984 17.982 12.7209 18.4182 12.955C18.8544 13.1891 19.2259 13.5275 19.4996 13.9401L23.8296 20.4501C23.9743 20.6705 24.0261 20.9393 23.9736 21.1977C23.9212 21.4562 23.7688 21.6835 23.5496 21.8301C23.3885 21.9429 23.1962 22.0023 22.9996 22.0001Z"
      fill="#29323A"
    />
    <path
      className="multicolorElToWhite darkBgElToBrand"
      d="M3.00002 22C2.80841 21.9995 2.62098 21.9439 2.46002 21.84C2.23754 21.6965 2.08102 21.4707 2.02478 21.212C1.96854 20.9533 2.01718 20.6829 2.16002 20.46L8.39002 10.84C8.6604 10.4222 9.03047 10.0782 9.4669 9.83905C9.90332 9.59989 10.3924 9.47305 10.89 9.46997C11.3849 9.46979 11.8722 9.59204 12.3084 9.82584C12.7446 10.0596 13.1162 10.3977 13.39 10.81L19.81 20.45C19.9278 20.6695 19.9594 20.9252 19.8985 21.1668C19.8377 21.4084 19.6889 21.6186 19.4812 21.7563C19.2735 21.8939 19.0219 21.949 18.7757 21.9109C18.5294 21.8727 18.3063 21.744 18.15 21.55L11.72 11.92C11.6294 11.7824 11.5063 11.6694 11.3615 11.5909C11.2167 11.5123 11.0547 11.4708 10.89 11.47C10.7244 11.4719 10.5619 11.515 10.417 11.5952C10.2721 11.6755 10.1495 11.7906 10.06 11.93L3.84002 21.54C3.74967 21.6808 3.62543 21.7967 3.47867 21.8771C3.33192 21.9574 3.16734 21.9997 3.00002 22Z"
      fill="#29323A"
    />
    <path
      className="multicolorElToDark darkBgElToWhite"
      d="M32.5633 9.55986H30.5887V7.57204C30.5887 6.97569 30.1938 6.57812 29.6015 6.57812C29.0091 6.57812 28.6142 6.97569 28.6142 7.57204V9.55986H26.6396C26.0473 9.55986 25.6523 9.95743 25.6523 10.5538C25.6523 11.1501 26.0473 11.5477 26.6396 11.5477H28.6142V13.5355C28.6142 14.1319 29.0091 14.5294 29.6015 14.5294C30.1938 14.5294 30.5887 14.1319 30.5887 13.5355V11.5477H32.5633C33.1557 11.5477 33.5506 11.1501 33.5506 10.5538C33.5506 9.95743 33.1557 9.55986 32.5633 9.55986Z"
      fill="#FF8477"
    />
    <path
      className="multicolorElToWhite darkBgElToBrand"
      d="M3 22H23C23.7956 22 24.5587 21.6839 25.1213 21.1213C25.6839 20.5587 26 19.7956 26 19V18.2493C25.273 17.9207 24.6005 17.4929 24 16.983V19C24 19.2652 23.8946 19.5196 23.7071 19.7071C23.5196 19.8946 23.2652 20 23 20H3C2.73478 20 2.48043 19.8946 2.29289 19.7071C2.10536 19.5196 2 19.2652 2 19V3C2 2.73478 2.10536 2.48043 2.29289 2.29289C2.48043 2.10536 2.73478 2 3 2H23C23.2652 2 23.5196 2.10536 23.7071 2.29289C23.8946 2.48043 24 2.73478 24 3V4.01703C24.5978 3.50948 25.2668 3.08321 25.99 2.75523C25.9322 2.0492 25.6259 1.38331 25.1213 0.87868C24.5587 0.316071 23.7956 0 23 0H3C2.20435 0 1.44129 0.316071 0.87868 0.87868C0.316071 1.44129 0 2.20435 0 3V19C0 19.7956 0.316071 20.5587 0.87868 21.1213C1.44129 21.6839 2.20435 22 3 22Z"
      fill="#29323A"
    />
    <path
      className="multicolorElToWhite darkBgElToBrand"
      d="M29.5033 3C25.3568 3 22 6.3793 22 10.5537C22 14.7282 25.3568 18.1075 29.5033 18.1075C33.6499 18.1075 37.0067 14.7282 37.0067 10.5537C37.0067 6.3793 33.6499 3 29.5033 3ZM29.5033 16.1196C26.4428 16.1196 23.9746 13.6349 23.9746 10.5537C23.9746 7.4726 26.5415 4.98782 29.5033 4.98782C32.4652 4.98782 35.0321 7.4726 35.0321 10.5537C35.0321 13.6349 32.5639 16.1196 29.5033 16.1196Z"
      fill="#29323A"
    />
  </Icon>
);

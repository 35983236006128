import { Link } from '@chakra-ui/react';
import { isValidURL } from '../utils/isValidUrl';

const useLinkFormatter = (inputString) => {
  const parseString = (inputString) => {
    const parts = inputString.split(/(<a.*?>.*?<\/a>|https?:\/\/[^\s]+)/);
    return (
      <>
        {parts.map((part, index) => {
          if (part.startsWith('<a') && part.endsWith('</a>')) {
            const hrefMatch = part.match(/href=['"](.*?)['"]/);
            const href = hrefMatch
              ? isValidURL(hrefMatch[1])
                ? hrefMatch[1]
                : null
              : null;
            return (
              <Link
                key={index}
                cursor="pointer"
                isExternal
                href={href}
                style={{ color: '#1D60D3' }}
              >
                {part.match(/>(.*?)</)[1]}
              </Link>
            );
          } else if (isValidURL(part)) {
            return (
              <Link
                key={index}
                cursor="pointer"
                isExternal
                href={part}
                style={{ color: '#1D60D3' }}
              >
                {part}
              </Link>
            );
          }
          return <span key={index}>{part}</span>;
        })}
      </>
    );
  };

  return parseString(inputString);
};

export default useLinkFormatter;

import { PassTemplatesListContextProvider } from '../../store/client/PassTemplatesListContext';
import PassTemplatesClientContent from './PassTemplatesClientContent';

function PassTemplateClientPage() {
  return (
    <PassTemplatesListContextProvider>
      <PassTemplatesClientContent />
    </PassTemplatesListContextProvider>
  );
}

export default PassTemplateClientPage;

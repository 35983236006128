import {
  Flex,
  Heading,
  Text,
  useColorModeValue,
  Spacer,
  Box,
  Button,
} from '@chakra-ui/react';
import { useContext } from 'react';
import ReadersContext from '../../../store/client/ReadersContext';
import { Link as RouterLink } from 'react-router-dom';
import { CustomEditIcon } from '../../../theme/icons/CustomEditIcon';

const ReadersViewHeader = () => {
  const modeHelperText = useColorModeValue('secondaryDark08', '#DDDDDD');
  const readersCtx = useContext(ReadersContext);

  return (
    <Flex
      flexDirection={{ base: 'column', sm: 'row' }}
      alignItems="center"
      flexWrap="wrap"
      mt="15px"
      py="9px"
      minH="54px"
    >
      <Heading alignSelf={{ base: 'flex-start', sm: 'center' }} wordBreak="break-word">
        <>
          {readersCtx.readerState.name}{' '}
          <Text
            as="span"
            display="inline-block"
            textStyle="bodyFamilyMedium"
            fontSize="14px"
            color={modeHelperText}
            lineHeight="130%"
            wordBreak="break-word"
          >
            / uuid: {readersCtx.readerState.uuid}
          </Text>
        </>
      </Heading>
      <Spacer />
      <Button
        alignSelf={{ base: 'flex-start', sm: 'center' }}
        as={RouterLink}
        size="sm"
        px="30px"
        to={`/readers/edit/${readersCtx.readerState.uuid}`}
      >
        <Box as="span" zIndex={1}>
          <CustomEditIcon boxSize="20px" mb="4px" color1="#fff" />
          <Text as="span" pl="8px">
            Edit reader
          </Text>
        </Box>
      </Button>
    </Flex>
  );
};
export default ReadersViewHeader;

import {
  Box,
  Heading,
  Grid,
  GridItem,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  HStack,
  Text,
  useMediaQuery,
  useColorModeValue,
} from '@chakra-ui/react';
import PreviewPassTemplateApple from './PreviewPassTemplateApple';
import PreviewPassTemplateAndroid from './PreviewPassTemplateAndroid';
import { CustomAppleIcon } from '../../../theme/icons/CustomAppleIcon';
import { CustomAndroidIcon } from '../../../theme/icons/CustomAndroidIcon';
import { useContext, useEffect, useState } from 'react';
import PassTemplateContext from '../../../store/client/PassTemplateContext';
import PassContext from '../../../store/client/PassContext';
import PreviewPassNotificationsApple from './PreviewPassNotificationsApple';
import PreviewPassNotificationsAndroid from './PreviewPassNotificationsAndroid';
import { NUMBER_KEYS } from '../../../utils/consts';

function PreviewPassTemplates(props) {
  const passTemplateCtx = useContext(PassTemplateContext);
  const passCtx = useContext(PassContext);
  const passContent = passCtx;
  const [isLargeForParallelDisplay] = useMediaQuery('(min-width: 1530px)');
  const modeBoxBg = useColorModeValue('white', 'secondaryDark');
  const displayStampValues =
    !!props.createTemplate &&
    passTemplateCtx.passType === 'stampCard' &&
    props.stampCardDetailsPage;

  const getMatchingStampValue = (keyType, activeStep, passTemplateCtx) => {
    const stampValueKey = `stamp${NUMBER_KEYS[activeStep]}${keyType}Value`;
    return passTemplateCtx[stampValueKey] || '';
  };

  const getStampKeyValue = (field, value) => {
    const { stampShortKey, stampLongKey, activeStep } = passTemplateCtx;

    if (!stampShortKey && !stampLongKey) {
      return passTemplateCtx[field];
    }
    if (stampShortKey && value.toLowerCase() === stampShortKey.toLowerCase()) {
      return getMatchingStampValue('Short', activeStep, passTemplateCtx);
    } else if (
      stampLongKey &&
      value.toLowerCase() === stampLongKey.toLowerCase()
    ) {
      return getMatchingStampValue('Long', activeStep, passTemplateCtx);
    }

    return passTemplateCtx[field];
  };

  const previewData = {
    templateName: passTemplateCtx.templateName,
    passType: passTemplateCtx.passType,
    description: passTemplateCtx.description,
    centralTitle: passTemplateCtx.centralTitle,
    headerOneId: passTemplateCtx.headerOneId,
    headerOneLabel: passTemplateCtx.headerOneLabel,
    headerOneValue: displayStampValues
      ? passTemplateCtx.activeStep || '0'
      : !!passContent.headerOne || passContent.headerOne === 0
        ? passContent.headerOne.toString()
        : passTemplateCtx.headerOneValue
          ? passTemplateCtx.headerOneValue
          : '',
    headerTwoId: passTemplateCtx.headerTwoId,
    headerTwoLabel: passTemplateCtx.headerTwoLabel,
    headerTwoValue: displayStampValues
      ? getStampKeyValue('headerTwoValue', passTemplateCtx.headerTwoId)
      : passContent.headerTwo
        ? passContent.headerTwo
        : passTemplateCtx.headerTwoValue
          ? passTemplateCtx.headerTwoValue
          : '',
    headerThreeId: passTemplateCtx.headerThreeId,
    headerThreeLabel: passTemplateCtx.headerThreeLabel,
    headerThreeValue: displayStampValues
      ? getStampKeyValue('headerThreeValue', passTemplateCtx.headerThreeId)
      : passContent.headerThree
        ? passContent.headerThree
        : passTemplateCtx.headerThreeValue
          ? passTemplateCtx.headerThreeValue
          : '',
    primaryOneId: passTemplateCtx.primaryOneId,
    primaryOneLabel: passTemplateCtx.primaryOneLabel,
    primaryOneValue: passContent.primaryOne
      ? passContent.primaryOne
      : passTemplateCtx.primaryOneValue
        ? passTemplateCtx.primaryOneValue
        : '',
    primaryTwoId: passTemplateCtx.primaryTwoId,
    primaryTwoLabel: passTemplateCtx.primaryTwoLabel,
    primaryTwoValue: passContent.primaryTwo
      ? passContent.primaryTwo
      : passTemplateCtx.primaryTwoValue
        ? passTemplateCtx.primaryTwoValue
        : '',
    logoImage: passTemplateCtx.logoImage,
    iconLogo: passTemplateCtx.iconLogo,
    bannerImage: passTemplateCtx.bannerImage,
    googleBannerImage: passTemplateCtx.googleBannerImage,
    thumbnailImage: passTemplateCtx.thumbnailImage,
    monochromeBadge: passTemplateCtx.monochromeBadge,
    secOneId: passTemplateCtx.secOneId,
    secOneLabel: passTemplateCtx.secOneLabel,
    secOneValue: displayStampValues
      ? getStampKeyValue('secOneValue', passTemplateCtx.secOneId)
      : passContent.secOne
        ? passContent.secOne
        : passTemplateCtx.secOneValue
          ? passTemplateCtx.secOneValue
          : '',
    secTwoId: passTemplateCtx.secTwoId,
    secTwoLabel: passTemplateCtx.secTwoLabel,
    secTwoValue: displayStampValues
      ? getStampKeyValue('secTwoValue', passTemplateCtx.secTwoId)
      : passContent.secTwo
        ? passContent.secTwo
        : passTemplateCtx.secTwoValue
          ? passTemplateCtx.secTwoValue
          : '',
    secThreeId: passTemplateCtx.secThreeId,
    secThreeLabel: passTemplateCtx.secThreeLabel,
    secThreeValue: displayStampValues
      ? getStampKeyValue('secThreeValue', passTemplateCtx.secThreeId)
      : passContent.secThree
        ? passContent.secThree
        : passTemplateCtx.secThreeValue
          ? passTemplateCtx.secThreeValue
          : '',
    auxOneId: passTemplateCtx.auxOneId,
    auxOneLabel: passTemplateCtx.auxOneLabel,
    auxOneValue: displayStampValues
      ? getStampKeyValue('auxOneValue', passTemplateCtx.auxOneId)
      : passContent.auxOne
        ? passContent.auxOne
        : passTemplateCtx.auxOneValue
          ? passTemplateCtx.auxOneValue
          : '',
    auxTwoId: passTemplateCtx.auxTwoId,
    auxTwoLabel: passTemplateCtx.auxTwoLabel,
    auxTwoValue: displayStampValues
      ? getStampKeyValue('auxTwoValue', passTemplateCtx.auxTwoId)
      : passContent.auxTwo
        ? passContent.auxTwo
        : passTemplateCtx.auxTwoValue
          ? passTemplateCtx.auxTwoValue
          : '',
    auxThreeId: passTemplateCtx.auxThreeId,
    auxThreeLabel: passTemplateCtx.auxThreeLabel,
    auxThreeValue: displayStampValues
      ? getStampKeyValue('auxThreeValue', passTemplateCtx.auxThreeId)
      : passContent.auxThree
        ? passContent.auxThree
        : passTemplateCtx.auxThreeValue
          ? passTemplateCtx.auxThreeValue
          : '',
    backOneId: passTemplateCtx.backOneId,
    backOneLabel: passTemplateCtx.backOneLabel,
    backOneValue: displayStampValues
      ? getStampKeyValue('backOneValue', passTemplateCtx.backOneId)
      : passContent.backOne
        ? passContent.backOne
        : passTemplateCtx.backOneValue
          ? passTemplateCtx.backOneValue
          : '',
    backTwoId: passTemplateCtx.backTwoId,
    backTwoLabel: passTemplateCtx.backTwoLabel,
    backTwoValue: displayStampValues
      ? getStampKeyValue('backTwoValue', passTemplateCtx.backTwoId)
      : passContent.backTwo
        ? passContent.backTwo
        : passTemplateCtx.backTwoValue
          ? passTemplateCtx.backTwoValue
          : '',
    backThreeId: passTemplateCtx.backThreeId,
    backThreeLabel: passTemplateCtx.backThreeLabel,
    backThreeValue: displayStampValues
      ? getStampKeyValue('backThreeValue', passTemplateCtx.backThreeId)
      : passContent.backThree
        ? passContent.backThree
        : passTemplateCtx.backThreeValue
          ? passTemplateCtx.backThreeValue
          : '',
    backFourId: passTemplateCtx.backFourId,
    backFourLabel: passTemplateCtx.backFourLabel,
    backFourValue: displayStampValues
      ? getStampKeyValue('backFourValue', passTemplateCtx.backFourId)
      : passContent.backFour
        ? passContent.backFour
        : passTemplateCtx.backFourValue
          ? passTemplateCtx.backFourValue
          : '',
    backFiveId: passTemplateCtx.backFiveId,
    backFiveLabel: passTemplateCtx.backFiveLabel,
    backFiveValue: displayStampValues
      ? getStampKeyValue('backFiveValue', passTemplateCtx.backFiveId)
      : passContent.backFive
        ? passContent.backFive
        : passTemplateCtx.backFiveValue
          ? passTemplateCtx.backFiveValue
          : '',
    passBackgroundColor: passTemplateCtx.passBackgroundColor,
    titleFontColor: passTemplateCtx.titleFontColor,
    bodyFontColor: passTemplateCtx.bodyFontColor,
    qrValue: passContent.qrValue,
    qrDisplayText: passContent.qrDisplayText,
    nfc: passContent.nfc,
    googleNfcEnabled: passTemplateCtx.googleNfcEnabled,
    notificationHeader: passTemplateCtx.notificationHeader,
    appleAppLink: passTemplateCtx.appleAppLink,
    googleAppLinkPlayStoreUrl: passTemplateCtx.googleAppLinkPlayStoreUrl,
    googleAppLinkPackageName: passTemplateCtx.googleAppLinkPackageName,
    googleAppLinkWebAppUrl: passTemplateCtx.googleAppLinkWebAppUrl,
    passDesign: passContent.passDesign,
    stampZeroImage: passTemplateCtx.stampZeroImage,
    stampOneImage: passTemplateCtx.stampOneImage,
    stampTwoImage: passTemplateCtx.stampTwoImage,
    stampThreeImage: passTemplateCtx.stampThreeImage,
    stampFourImage: passTemplateCtx.stampFourImage,
    stampFiveImage: passTemplateCtx.stampFiveImage,
    stampSixImage: passTemplateCtx.stampSixImage,
    stampSevenImage: passTemplateCtx.stampSevenImage,
    stampEightImage: passTemplateCtx.stampEightImage,
    stampNineImage: passTemplateCtx.stampNineImage,
    stampTenImage: passTemplateCtx.stampTenImage,
    stampElevenImage: passTemplateCtx.stampElevenImage,
    stampTwelveImage: passTemplateCtx.stampTwelveImage,
    activeStep: passTemplateCtx.activeStep || 0,
    watermarkColor:
      passTemplateCtx.passType !== 'stampCard'
        ? passTemplateCtx.watermarkColor
        : passTemplateCtx[
            `stamp${NUMBER_KEYS[passTemplateCtx.activeStep || 0]}WatermarkColor`
          ],
    watermarkPosition:
      passTemplateCtx.passType !== 'stampCard'
        ? passTemplateCtx.watermarkPosition
        : passTemplateCtx[
            `stamp${
              NUMBER_KEYS[passTemplateCtx.activeStep || 0]
            }WatermarkPosition`
          ],
    googleBannerWatermarkColor: passTemplateCtx.googleBannerWatermarkColor,
    googleBannerWatermarkPosition:
      passTemplateCtx.googleBannerWatermarkPosition,
    uuid: passTemplateCtx.uuid,
    displayBannerWatermarkPreview:
      passTemplateCtx.displayBannerWatermarkPreview,
    displayGoogleBannerWatermarkPreview:
      passTemplateCtx.displayGoogleBannerWatermarkPreview,
  };

  const [isValidImage, setIsValidImage] = useState(false);

  const validateImageUrl = (url) => {
    const img = new Image();
    img.onload = () => {
      setIsValidImage(true);
    };
    img.onerror = () => {
      setIsValidImage(false);
    };
    img.src = url;
  };

  useEffect(() => {
    validateImageUrl(passTemplateCtx.googleAppLinkIconUrl);
  }, [passTemplateCtx.googleAppLinkIconUrl]);

  return (
    <Box>
      <Box
        bg={modeBoxBg}
        borderRadius="15px"
        pb={8}
        zIndex="1"
        minW={{ base: 'none', md: 'fit-content' }}
        className="primaryBoxShadow"
        position="sticky"
        top="20px"
        width="100%"
        transition="top 0.3s"
      >
        <Heading size="lg" p="24px 0 0">
          <Text
            pl={
              isLargeForParallelDisplay ? '20px' : { base: '14px', md: '32px' }
            }
          >
            Preview
          </Text>
        </Heading>
        {props.createTemplate ? (
          <Tabs variant="unstyled" size="sm" align="center">
            <TabList>
              <Tab
                style={{ fontSize: '16px' }}
                _selected={{ color: '#FF8577' }}
                _hover={{ color: '#FF8577' }}
              >
                <Text
                  pl={
                    isLargeForParallelDisplay
                      ? 'min(32px, (100% - 686px)/4)'
                      : { base: '14px', md: '32px' }
                  }
                >
                  Pass Template
                </Text>
              </Tab>
              <Tab
                style={{ fontSize: '16px' }}
                _selected={{ color: '#FF8577' }}
                _hover={{ color: '#FF8577' }}
              >
                <Text
                  pl={
                    isLargeForParallelDisplay
                      ? 'min(32px, (100% - 686px)/4)'
                      : { base: '14px', md: '32px' }
                  }
                >
                  Notifications
                </Text>
              </Tab>
            </TabList>
            <TabPanels>
              <TabPanel>
                {isLargeForParallelDisplay ? (
                  <Grid
                    templateColumns={{ base: '1fr', md: '1fr 1fr' }}
                    gap={6}
                  >
                    <GridItem>
                      <PreviewPassTemplateApple
                        previewData={previewData}
                        clientView={props.clientView}
                        isEdit={props.isEdit}
                        createView={props.createView}
                        createTemplate={props.createTemplate}
                      />
                    </GridItem>
                    <GridItem>
                      <PreviewPassTemplateAndroid
                        previewData={previewData}
                        clientView={props.clientView}
                        isEdit={props.isEdit}
                        createView={props.createView}
                        createTemplate={props.createTemplate}
                        isValidImage={isValidImage}
                      />
                    </GridItem>
                  </Grid>
                ) : (
                  <Tabs px={{ base: '14px', md: '32px' }}>
                    <TabList>
                      <Tab>
                        <HStack alignItems="baseline">
                          <CustomAppleIcon fontSize="18px" />
                          <Text textStyle="bodyMedium">iOS</Text>
                        </HStack>
                      </Tab>
                      <Tab>
                        <HStack alignItems="baseline">
                          <CustomAndroidIcon fontSize="18px" />
                          <Text textStyle="bodyMedium">Android</Text>
                        </HStack>
                      </Tab>
                    </TabList>

                    <TabPanels mt={8}>
                      <TabPanel px="0">
                        <PreviewPassTemplateApple
                          previewData={previewData}
                          clientView={props.clientView}
                          isEdit={props.isEdit}
                          createView={props.createView}
                          createTemplate={props.createTemplate}
                        />
                      </TabPanel>
                      <TabPanel px="0">
                        <PreviewPassTemplateAndroid
                          previewData={previewData}
                          clientView={props.clientView}
                          isEdit={props.isEdit}
                          createView={props.createView}
                          createTemplate={props.createTemplate}
                          isValidImage={isValidImage}
                        />
                      </TabPanel>
                    </TabPanels>
                  </Tabs>
                )}
              </TabPanel>
              <TabPanel>
                {isLargeForParallelDisplay ? (
                  <Grid
                    templateColumns={{ base: '1fr', md: '1fr 1fr' }}
                    gap={4}
                  >
                    <GridItem>
                      <PreviewPassNotificationsApple
                        previewData={previewData}
                        clientView={props.clientView}
                        isEdit={props.isEdit}
                        createView={props.createView}
                        createTemplate={props.createTemplate}
                      />
                    </GridItem>
                    <GridItem>
                      <PreviewPassNotificationsAndroid
                        previewData={previewData}
                        clientView={props.clientView}
                        isEdit={props.isEdit}
                        createView={props.createView}
                        createTemplate={props.createTemplate}
                      />
                    </GridItem>
                  </Grid>
                ) : (
                  <Tabs px={{ base: '14px', md: '32px' }}>
                    <TabList>
                      <Tab>
                        <HStack alignItems="baseline">
                          <CustomAppleIcon fontSize="18px" />
                          <Text textStyle="bodyMedium">iOS</Text>
                        </HStack>
                      </Tab>
                      <Tab>
                        <HStack alignItems="baseline">
                          <CustomAndroidIcon fontSize="18px" />
                          <Text textStyle="bodyMedium">Android</Text>
                        </HStack>
                      </Tab>
                    </TabList>
                    <TabPanels mt={8}>
                      <TabPanel px="0">
                        <PreviewPassNotificationsApple
                          previewData={previewData}
                          clientView={props.clientView}
                          isEdit={props.isEdit}
                          createView={props.createView}
                          createTemplate={props.createTemplate}
                        />
                      </TabPanel>
                      <TabPanel px="0">
                        <PreviewPassNotificationsAndroid
                          previewData={previewData}
                          clientView={props.clientView}
                          isEdit={props.isEdit}
                          createView={props.createView}
                          createTemplate={props.createTemplate}
                        />
                      </TabPanel>
                    </TabPanels>
                  </Tabs>
                )}
              </TabPanel>
            </TabPanels>
          </Tabs>
        ) : (
          <>
            {isLargeForParallelDisplay ? (
              <Grid templateColumns={{ base: '1fr', md: '1fr 1fr' }} gap={6}>
                <GridItem pt={4} pl={4}>
                  <PreviewPassTemplateApple
                    previewData={previewData}
                    clientView={props.clientView}
                    isEdit={props.isEdit}
                    createView={props.createView}
                    createTemplate={props.createTemplate}
                    isValidImage={isValidImage}
                  />
                </GridItem>
                <GridItem pt={4} pr={4}>
                  <PreviewPassTemplateAndroid
                    previewData={previewData}
                    clientView={props.clientView}
                    isEdit={props.isEdit}
                    createView={props.createView}
                    createTemplate={props.createTemplate}
                  />
                </GridItem>
              </Grid>
            ) : (
              <Tabs px={{ base: '14px', md: '32px' }}>
                <TabList>
                  <Tab>
                    <HStack alignItems="baseline">
                      <CustomAppleIcon fontSize="18px" />
                      <Text textStyle="bodyMedium">iOS</Text>
                    </HStack>
                  </Tab>
                  <Tab>
                    <HStack alignItems="baseline">
                      <CustomAndroidIcon fontSize="18px" />
                      <Text textStyle="bodyMedium">Android</Text>
                    </HStack>
                  </Tab>
                </TabList>

                <TabPanels mt={8}>
                  <TabPanel px="0">
                    <PreviewPassTemplateApple
                      previewData={previewData}
                      clientView={props.clientView}
                      isEdit={props.isEdit}
                      createView={props.createView}
                      createTemplate={props.createTemplate}
                    />
                  </TabPanel>
                  <TabPanel px="0">
                    <PreviewPassTemplateAndroid
                      previewData={previewData}
                      clientView={props.clientView}
                      isEdit={props.isEdit}
                      createView={props.createView}
                      createTemplate={props.createTemplate}
                      isValidImage={isValidImage}
                    />
                  </TabPanel>
                </TabPanels>
              </Tabs>
            )}
          </>
        )}
      </Box>
    </Box>
  );
}

export default PreviewPassTemplates;

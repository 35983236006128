import { Icon } from '@chakra-ui/react';

export const CustomLockIcon = (props) => (
  <Icon viewBox="0 0 15 18" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.91831 0C3.93597 0 1.51831 2.41766 1.51831 5.4V8.18207H0.6C0.268629 8.18207 0 8.4507 0 8.78207V17.4002C0 17.7316 0.268629 18.0002 0.6 18.0002H13.8C14.1314 18.0002 14.4 17.7316 14.4 17.4002V8.78207C14.4 8.4507 14.1314 8.18207 13.8 8.18207H12.3183V5.4C12.3183 2.41766 9.90065 0 6.91831 0ZM10.3547 8.18207V5.4C10.3547 3.50215 8.81616 1.96364 6.91831 1.96364C5.02046 1.96364 3.48195 3.50215 3.48195 5.4V8.18207H10.3547ZM6.21818 14.073C6.76043 14.6152 7.63958 14.6152 8.18182 14.073C8.72406 13.5307 8.72406 12.6516 8.18182 12.1093C7.63958 11.5671 6.76043 11.5671 6.21818 12.1093C5.67594 12.6516 5.67594 13.5307 6.21818 14.073Z"
      fill="url(#paint0_linear_2063_1592)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_2063_1592"
        x1="0.371613"
        y1="18.0002"
        x2="13.0202"
        y2="16.5099"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF8477" />
        <stop offset="1" stopColor="#EC6759" />
      </linearGradient>
    </defs>
  </Icon>
);

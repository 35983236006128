import { Icon } from '@chakra-ui/react';

export const CustomBinIcon = () => {
  return (
    <Icon cursor={'pointer'}>
      <svg viewBox="0 0 26 28" fill="none">
        <g clipPath="url(#clip0_9751_2666)">
          <path
            d="M25.984 4.48H19.712V1.904C19.712 0.784 18.816 0 17.808 0H9.296C8.176 0 7.392 0.896 7.392 1.904V4.48H1.12C0.56 4.48 0 4.928 0 5.6C0 6.272 0.56 6.72 1.12 6.72H25.984C26.656 6.72 27.104 6.16 27.104 5.6C27.104 5.04 26.656 4.48 25.984 4.48ZM9.744 2.352H17.472V4.48H9.744V2.352Z"
            fill="#FF8477"
          />
          <path
            d="M22.1063 8.95984L20.6503 25.6478H7.21034L5.75434 8.95984C5.75434 8.39984 5.19434 7.83984 4.63434 7.83984C3.96234 7.83984 3.40234 8.39984 3.51434 9.07184L4.97034 26.2078C5.08234 27.2158 5.97834 27.9998 6.87434 27.9998H20.9863C21.9943 27.9998 22.7783 27.2158 22.8903 26.2078L24.3463 9.18384C24.4583 8.51184 23.8983 7.95184 23.2263 7.95184C22.6663 7.95184 22.2183 8.39984 22.1063 8.95984Z"
            fill="#3B4851"
          />
        </g>
        <defs>
          <clipPath id="clip0_9751_2666">
            <rect width="28" height="28" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </Icon>
  );
};

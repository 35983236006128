import { SimpleGrid, GridItem, Container, useToast, Button } from '@chakra-ui/react';

import CustomToast from '../../common/CustomToast';

function FlashMessages() {
  const toast = useToast();
  const toastStatuses = ['success', 'error', 'warning', 'info'];

  return (
    <Container bg="white" borderBottom="solid 1px #DDD">
      <Container maxW="1180px" px="0" py={6}>
        <SimpleGrid w="full" columns={{ base: 1, sm: 2, md: 4 }} columnGap={4} rowGap={4}>
          {toastStatuses.map((status, index) => {
            return (
              <GridItem key={index}>
                <Button
                  key={index}
                  alt={status}
                  size="sm"
                  onClick={() =>
                    toast({
                      // position: 'top',
                      // duration: '9000',
                      render: (props) => (
                        <CustomToast
                          status={status}
                          title={`${status} - Title Here`}
                          description={`${status} - Description Here`}
                          onClose={props.onClose}
                          id={props.id}
                        />
                      ),
                    })
                  }
                >
                  {status}
                </Button>
              </GridItem>
            );
          })}
        </SimpleGrid>
      </Container>
    </Container>
  );
}

export default FlashMessages;

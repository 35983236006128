import React, { useEffect } from 'react';
import { useTable, usePagination } from 'react-table';
import TableBox from '../../common/TableBox';
import ReactTablePagination from '../../../common/ReactTablePagination';
import EmptyState from '../../../common/EmptyState';
import MessageBatchStatus from './MessageStatus';
import { Link } from 'react-router-dom';
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Spinner,
  Box,
  Text,
  Heading,
  Flex,
  Stack,
  Button,
  Tooltip,
  useColorModeValue,
  useMediaQuery,
} from '@chakra-ui/react';
import { CustomExclamationMarkInverseIcon } from '../../../theme/icons/CustomExclamationMarkInverseIcon';
import MessageLogMoreInfo from './MessageLogMoreInfo';
const MessagingLogsTable = ({
  columns,
  data,
  loading,
  fetchData,
  pageCount,
  total,
  resend,
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    // Pagination props
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount: controlledPageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    setHiddenColumns,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      manualPagination: true,
      pageCount,
      initialState: { pageIndex: 0 },
    },
    usePagination
  );

  const [isLargerThan2XL] = useMediaQuery('(min-width: 96em)');
  const [isLargerThanXL] = useMediaQuery('(min-width: 80em)');
  const [isLargerThanMD] = useMediaQuery('(min-width: 48em)');
  const [isLargerThanSM] = useMediaQuery('(min-width: 30em)');
  const [isLargerThanS] = useMediaQuery('(min-width: 18em)');
  const [isLargerThanXS] = useMediaQuery('(min-width: 10em)');

  useEffect(() => {
    fetchData({ pageIndex, pageSize });
  }, [fetchData, pageIndex, pageSize]);

  useEffect(() => {
    if (isLargerThan2XL) {
      setHiddenColumns([]);
    } else if (isLargerThanXL) {
      setHiddenColumns(['resendAttempts', 'resendAction', 'status']);
    } else if (isLargerThanMD) {
      setHiddenColumns([
        'resendAttempts',
        'resendAction',
        'status',
        'recipient',
      ]);
    } else if (isLargerThanSM) {
      setHiddenColumns([
        'resendAttempts',
        'resendAction',
        'status',
        'recipient',
        'errorMessage',
      ]);
    } else if (isLargerThanS) {
      setHiddenColumns([
        'resendAttempts',
        'resendAction',
        'status',
        'recipient',
        'errorMessage',
        'failedAt',
      ]);
    } else {
      setHiddenColumns([
        'resendAttempts',
        'resendAction',
        'status',
        'recipient',
        'errorMessage',
        'messageType',
        'failedAt',
      ]);
    }
  }, [
    isLargerThan2XL,
    isLargerThanXL,
    isLargerThanMD,
    isLargerThanSM,
    isLargerThanS,
    isLargerThanXS,
    setHiddenColumns,
  ]);

  const bgTooltip = useColorModeValue('secondaryDark', '#dddddd');
  const textTooltip = useColorModeValue('#ffffff', 'primaryDark');

  if (loading) {
    return (
      <Box textAlign="center">
        <Spinner />
      </Box>
    );
  }

  if (data.length === 0 && !loading) {
    return (
      <EmptyState
        heading="No Failed Messages Found"
        text="There are currently no failed messages."
      >
        <Button alt="New Message" size="sm" as={Link} to="/messaging/create">
          New Message
        </Button>
      </EmptyState>
    );
  }

  return (
    <>
      <TableBox>
        <Heading size="lg" mb="16px">
          Failed Messages{' '}
          <Text
            as="span"
            textStyle="headingFamilyMedium"
            fontSize="18px"
            fontWeight="500"
          >
            (
            <Box as="span" color="brand">
              {total}
            </Box>
            )
          </Text>
        </Heading>
        <Table {...getTableProps()}>
          <Thead>
            {headerGroups.map((headerGroup) => (
              <Tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <Th {...column.getHeaderProps()} style={column.style}>
                    {column.id === 'resendAttempts' ? (
                      <Tooltip
                        label="Resend attempts are limited to 5 and are only shown for messages that can be resent, along with the option to resend."
                        hasArrow
                        placement="top"
                        fontSize="12px"
                        bg={bgTooltip}
                        color={textTooltip}
                        w="205px"
                        borderRadius="6px"
                        textAlign="center"
                        p="10px"
                      >
                        <Box
                          cursor="pointer"
                          display="flex"
                          alignItems="center"
                        >
                          {column.render('Header')}
                          <CustomExclamationMarkInverseIcon
                            boxSize="14px"
                            mode={'light'}
                            ml={1}
                          />
                        </Box>
                      </Tooltip>
                    ) : (
                      column.render('Header')
                    )}
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
          <Tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <Tr
                  {...row.getRowProps()}
                  _hover={{
                    cursor: 'default',
                  }}
                >
                  {row.cells.map((cell) => {
                    return (
                      <Td {...cell.getCellProps()} style={cell.column.style}>
                        {cell.column.id === 'status' ? (
                          <MessageBatchStatus status={cell.value} type="log" />
                        ) : cell.column.id === 'resendAction' &&
                          !!cell.row.original.resendable ? (
                          <Button
                            size="sm"
                            px="19px"
                            onClick={() => resend(cell.row.original.id)}
                            isDisabled={cell.row.original.resendAttempts >= 5}
                          >
                            <Text
                              textStyle="headingFamilyMedium"
                              fontWeight="500"
                              lineHeight="28px"
                              textTransform="none"
                            >
                              Resend
                            </Text>
                          </Button>
                        ) : cell.column.id === 'moreInfo' ? (
                          <MessageLogMoreInfo cell={cell} resend={resend} />
                        ) : (
                          cell.render('Cell')
                        )}
                      </Td>
                    );
                  })}
                </Tr>
              );
            })}
          </Tbody>
        </Table>
        {data.length > 0 && (
          <Flex
            mt={6}
            flexDirection={{ base: 'column-reverse', '2xl': 'row' }}
            justifyContent={{ base: 'none', '2xl': 'space-between' }}
          >
            <Stack
              spacing="18px"
              direction={{ base: 'column', sm: 'row' }}
              shouldWrapChildren="true"
            ></Stack>

            <ReactTablePagination
              canPreviousPage={canPreviousPage}
              canNextPage={canNextPage}
              pageOptions={pageOptions}
              pageCount={pageCount}
              gotoPage={gotoPage}
              nextPage={nextPage}
              previousPage={previousPage}
              setPageSize={setPageSize}
              pageIndex={pageIndex}
              pageSize={pageSize}
            />
          </Flex>
        )}
      </TableBox>
    </>
  );
};

export default MessagingLogsTable;

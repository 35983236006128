import { createContext, useState } from 'react';

const MessagingLogsContext = createContext({
  dataState: {
    items: [],
    total: 0,
    pageCount: 0,
    loading: false,
    status: 'idle',
  },
  updateDataState: () => {},
});

export const MessagingLogsContextProvider = ({ children }) => {
  const [dataState, setDataState] = useState({
    items: [],
    total: 0,
    pageCount: 0,
    loading: false,
    status: 'idle',
  });

  const updateDataState = (newState) => {
    setDataState((prevState) => ({
      ...prevState,
      ...newState,
    }));
  };

  const context = {
    dataState,
    updateDataState,
  };

  return (
    <MessagingLogsContext.Provider value={context}>
      {children}
    </MessagingLogsContext.Provider>
  );
};

export default MessagingLogsContext;

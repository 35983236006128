import { Icon } from '@chakra-ui/react';

export const CustomClockIcon = (props) => (
  <Icon viewBox="0 0 24 24" {...props} fill="transparent">
    <path
      d="M12 3.00073C7.03125 3.00073 3 7.03198 3 12.0007C3 16.9695 7.03125 21.0007 12 21.0007C16.9687 21.0007 21 16.9695 21 12.0007C21 7.03198 16.9687 3.00073 12 3.00073Z"
      stroke="url(#paint0_linear_1889_1867)"
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
    <path
      d="M12 6.00183V12.7518H16.5"
      stroke="url(#paint1_linear_1889_1867)"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <defs>
      <linearGradient
        id="paint0_linear_1889_1867"
        x1="3.46452"
        y1="21.0007"
        x2="19.1543"
        y2="18.6899"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.styleguide ? 'currentColor' : '#FF8477'} />
        <stop offset="1" stopColor={props.styleguide ? 'currentColor' : '#FF8477'} />
      </linearGradient>
      <linearGradient
        id="paint1_linear_1889_1867"
        x1="12.1161"
        y1="12.7518"
        x2="16.0854"
        y2="12.3621"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.styleguide ? 'currentColor' : '#FF8477'} />
        <stop offset="1" stopColor={props.styleguide ? 'currentColor' : '#FF8477'} />
      </linearGradient>
    </defs>
  </Icon>
);

export function getContrastYIQ(hexcolor, light = '#ffffff', dark = '#29323A') {
  hexcolor = hexcolor ? hexcolor.replace('#', '') : '';
  const foreground = getContrastYIQDiff(hexcolor);
  const darkBackground = Math.abs(foreground - getContrastYIQDiff(dark));
  const lightBackground = Math.abs(foreground - getContrastYIQDiff(light));
  return darkBackground > lightBackground ? dark : light;
}

function getContrastYIQDiff(hexcolor) {
  hexcolor = hexcolor ? hexcolor.replace('#', '') : '';
  const r = parseInt(hexcolor.substr(0, 2), 16);
  const g = parseInt(hexcolor.substr(2, 2), 16);
  const b = parseInt(hexcolor.substr(4, 2), 16);
  const yiq = (r * 299 + g * 587 + b * 114) / 1000;
  return yiq;
}

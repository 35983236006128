import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalBody,
  Button,
  VStack,
  Heading,
  Text,
  Box,
  useColorModeValue,
  useToast,
} from '@chakra-ui/react';
import { CustomRefreshIcon } from '../../theme/icons/CustomRefreshIcon';
import { HttpContext } from '../../context/HttpContext';
import { useContext, useEffect, useState } from 'react';
import appsignal from '../../appsignal';
import { CustomCheckIcon } from '../../theme/icons/CustomCheckIcon';
import CustomToast from '../../common/CustomToast';

const RefreshKeyModal = (props) => {
  const { fetchData, onClose, isOpen } = props;
  const modeText = useColorModeValue('secondaryDark08', '#DDDDDD');
  const [newKey, setNewKey] = useState('');
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const { authAxios } = useContext(HttpContext);
  const toast = useToast();

  const handleKeyRefresh = async () => {
    try {
      const response = await authAxios.patch(
        '/api/v1/webhook_subscriptions/rotate_secret'
      );
      const refreshedKey = response.data.data.attributes.secretKey;
      setNewKey(refreshedKey);
    } catch (onError) {
      appsignal.sendError(onError);
      console.log(onError);
      toast({
        render: () => (
          <CustomToast
            status="error"
            title={`Something went wrong`}
            description={'Please try again later'}
            onClose={onClose}
          />
        ),
      });
    }
  };

  const handleClose = () => {
    setNewKey('');
    setShowSuccessModal(false);
    onClose();
  };

  useEffect(() => {
    if (newKey) {
      setShowSuccessModal(true);
    }
  }, [newKey]);

  useEffect(() => {
    if (newKey) {
      fetchData({ newKey: true });
    }
  }, [fetchData, newKey]);

  return (
    <Modal onClose={onClose} isOpen={isOpen} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalBody>
          {!showSuccessModal ? (
            <VStack spacing="30px" mt="36px">
              <CustomRefreshIcon boxSize="50px" />
              <Heading
                fontSize={{ base: '24px', lg: '32px' }}
                lineHeight="130%"
              >
                Refresh key
              </Heading>
              <Box>
                <Text
                  textStyle="bodyFamilyMedium"
                  color={modeText}
                  textAlign="center"
                >
                  Are you sure you'd like to refresh your key? This action
                  cannot be undone.
                </Text>
              </Box>
            </VStack>
          ) : (
            <VStack spacing="30px" mt="36px">
              <CustomCheckIcon color="#FA7D6F" boxSize="50px" />
              <Heading
                fontSize={{ base: '24px', lg: '32px' }}
                lineHeight="130%"
              >
                Key refreshed
              </Heading>
              <Box>
                <Text
                  textStyle="bodyFamilyMedium"
                  color={modeText}
                  textAlign="center"
                >
                  Your key has been refreshed:
                </Text>
                <Text
                  textStyle="bodyFamilyMedium"
                  color={modeText}
                  textAlign="center"
                  mt={3}
                  fontSize="18px"
                >
                  {newKey}
                </Text>
              </Box>
            </VStack>
          )}
        </ModalBody>
        <ModalFooter
          justifyContent={!showSuccessModal ? 'space-between' : 'end'}
        >
          <>
            {!showSuccessModal && (
              <Button
                variant="secondary"
                size="sm"
                onClick={handleClose}
                alt="Cancel"
              >
                Cancel
              </Button>
            )}
            <Button
              size="sm"
              onClick={!showSuccessModal ? handleKeyRefresh : handleClose}
              alt="OK"
            >
              {!showSuccessModal ? 'Refresh' : 'Close'}
            </Button>
          </>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default RefreshKeyModal;

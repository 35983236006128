import {
  Container,
  Flex,
  Image,
  HStack,
  Text,
  Button,
  Box,
  Link,
} from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import { useMediaQuery } from '@chakra-ui/react';

import logoSrc from '../assets/vectors/logo.svg';

import { HEADER_TYPE_RESET } from '../utils/consts';
import { HEADER_TYPE_LOGIN } from '../utils/consts';
import { HEADER_TYPE_BACK } from '../utils/consts';

const HeaderNotLogged = (props) => {
  const [isLargerThanMD] = useMediaQuery('(min-width: 48em)');

  const renderWording = (type) => {
    switch (type) {
      case HEADER_TYPE_RESET:
      case HEADER_TYPE_BACK:
        return 'Back to';
      case HEADER_TYPE_LOGIN:
        return "Don't have an account?";
      default:
        return 'Already a member?';
    }
  };

  const renderCTA = (type) => {
    switch (type) {
      case HEADER_TYPE_RESET:
        return (
          <Button
            alt="Login"
            as={RouterLink}
            to="/login"
            size="sm"
            textTransform="none"
          >
            Login
          </Button>
        );
      case HEADER_TYPE_LOGIN:
        return (
          <Button alt="Enquire" size="sm" textTransform="none">
            <Link
              href="https://passentry.com/get-started/"
              isExternal
              textDecoration="none"
              _hover={{ textDecoration: 'none' }}
            >
              Enquire now
            </Link>
          </Button>
        );
      case HEADER_TYPE_BACK:
        return (
          <Button
            alt="Home page"
            as={RouterLink}
            to="/"
            size="sm"
            textTransform="none"
          >
            Home page
          </Button>
        );
      default:
        return (
          <Button
            alt="Login"
            as={RouterLink}
            to="/login"
            size="sm"
            textTransform="none"
          >
            Login
          </Button>
        );
    }
  };

  return (
    <Box as="header" w="full" position="fixed" zIndex="10" top={props.top}>
      <Container maxW="1020px">
        <Flex
          className="primaryBoxShadow blurFilterFallback"
          alignItems="center"
          bg="gradient"
          h="70px"
          px="24px"
          borderRadius="15px"
          justifyContent="space-between"
          backdropFilter="blur(8px)"
        >
          <Link isExternal href="https://passentry.com">
            <Image
              src={logoSrc}
              alt="PassEntry"
              loading="lazy"
              w={{ base: '100px', md: '150px' }}
            />
          </Link>
          <HStack spacing={5}>
            {isLargerThanMD && (
              <Text textStyle="bodyRoman" color="secondaryDark08">
                {renderWording(props.type)}
              </Text>
            )}
            {renderCTA(props.type)}
          </HStack>
        </Flex>
      </Container>
    </Box>
  );
};

export default HeaderNotLogged;

import { Container, Divider, SimpleGrid, Box } from '@chakra-ui/react';
import { useContext } from 'react';
import Breadcrumbs from '../../../common/Breadcrumbs';
import ReadersContext from '../../../store/client/ReadersContext';
import ReadersFieldsView from './ReadersFieldsView';
import ReadersViewHeader from './ReadersViewHeader';

const ReadersViewPageView = () => {
  const readersCtx = useContext(ReadersContext);

  const breadcrumbLinks = [
    {
      linkName: 'Home',
      linkUrl: '/',
    },
    {
      linkName: 'Pass readers',
      linkUrl: '/readers',
    },
    {
      linkName: readersCtx.readerState.name,
      linkUrl: null,
    },
  ];

  return (
    <Container display="flex" flexDir="column" flex="1" pb={6}>
      <Breadcrumbs breadcrumbLinks={breadcrumbLinks} />
      <ReadersViewHeader />
      <Divider borderColor="primaryBackground" mb={6} />
      <Box>
        <SimpleGrid w="full" columns={{ base: 1 }} mt={6}>
          <ReadersFieldsView />
        </SimpleGrid>
      </Box>
    </Container>
  );
};
export default ReadersViewPageView;

import { Icon } from '@chakra-ui/react';

export const CustomUserAcceptedMultiIcon = (props) => (
  <Icon viewBox="0 0 51 51" fill="none" {...props}>
    {/* #3B4851, #fff */}
    <path
      d="M23.738 23.022C23.2993 22.6969 22.8433 22.412 22.6772 22.3296C25.0284 20.5147 26.0734 17.1443 24.6365 14.0331C23.7222 12.2182 22.0241 10.7922 20.0648 10.2737C15.493 9.23661 11.4438 12.7367 11.4438 17.0146C11.4438 19.0888 12.3581 21.1629 14.0562 22.4593C10.2682 24.1445 7.91699 27.7742 7.91699 31.7929V36.5893C7.91699 37.2375 8.43948 37.756 9.09259 37.756H26.0889C27.9563 39.2218 30.3224 40.0898 32.917 40.0898C39.0565 40.0898 43.917 35.2294 43.917 29.0898C43.917 22.9503 39.0565 18.0898 32.917 18.0898C29.1125 18.0898 25.7178 20.0365 23.738 23.022ZM22.5471 25.4048C22.1227 24.9099 21.034 24.2591 20.4567 24.1445C15.1012 22.8482 10.2682 26.7372 10.2682 31.7929V35.4226H23.8842C22.6414 33.6397 21.917 31.4601 21.917 29.0898C21.917 27.7932 22.1395 26.5536 22.5471 25.4048ZM22.9385 17.0146C22.9385 14.5516 20.8485 12.4775 18.3667 12.4775C15.8849 12.4775 13.795 14.5516 13.795 17.0146C13.795 19.4777 15.8849 21.5518 18.3667 21.5518C20.8485 21.5518 22.9385 19.4777 22.9385 17.0146ZM24.4751 28.9619C24.4751 33.6945 28.3123 37.4038 32.917 37.4038C37.6495 37.4038 41.3589 33.6945 41.3589 28.9619C41.3589 24.2294 37.6495 20.5201 32.917 20.5201C28.1844 20.5201 24.4751 24.2294 24.4751 28.9619Z"
      fill={props.mode === 'dark' ? '#fff' : '#3B4851'}
    />
    <path
      d="M22.5475 25.4078C22.1231 24.9129 21.0344 24.2621 20.457 24.1475C15.1016 22.8512 10.2686 26.7402 10.2686 31.7959V35.4256H23.8846C22.6418 33.6427 21.9174 31.4631 21.9174 29.0929C21.9174 27.7962 22.1399 26.5566 22.5475 25.4078ZM22.9388 17.0177C22.9388 14.5546 20.8489 12.4805 18.3671 12.4805C15.8853 12.4805 13.7953 14.5546 13.7953 17.0177C13.7953 19.4807 15.8853 21.5548 18.3671 21.5548C20.8489 21.5548 22.9388 19.4807 22.9388 17.0177ZM24.4755 28.965C24.4755 33.6975 28.3127 37.4068 32.9174 37.4068C37.6499 37.4068 41.3592 33.6975 41.3592 28.965C41.3592 24.2324 37.6499 20.5231 32.9174 20.5231C28.1848 20.5231 24.4755 24.2324 24.4755 28.965Z"
      fill={props.mode === 'dark' ? '#3B4851' : '#fff'}
    />
    <path
      d="M28.0838 30.2137L30.5132 32.5962C30.9096 32.9849 31.5467 32.9763 31.9325 32.5771L37.9816 26.3164"
      stroke="#1DBE68"
      strokeWidth="2.7"
      strokeLinecap="round"
    />
  </Icon>
);

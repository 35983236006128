import { Icon } from '@chakra-ui/react';
import { motion } from 'framer-motion';

const pathVariants = {
  hidden: {
    opacity: 0,
    pathLength: 0,
  },
  visible: {
    opacity: 1,
    pathLength: 1,
    transition: {
      duration: 0.9,
      ease: 'easeInOut',
    },
  },
};

export const CustomPassesMultiIconStrokeAnimated = (props) => (
  <Icon viewBox="0 0 34 26" {...props} fill="transparent">
    <motion.circle
      cx="9.15456"
      cy="6.43336"
      r="4.36501"
      stroke="#FF8477"
      strokeWidth="2.5"
      variants={pathVariants}
      initial="hidden"
      animate="visible"
    />
    <motion.circle
      cx="24.8789"
      cy="15.9879"
      r="7.94373"
      stroke="#FF8477"
      strokeWidth="2.5"
      variants={pathVariants}
      initial="hidden"
      animate="visible"
    />
    <motion.path
      d="M13.5516 11.7363H7.92725C4.61354 11.7363 1.92725 14.4226 1.92725 17.7363V21.8573C1.92725 21.9678 2.01679 22.0573 2.12725 22.0573H13.5516"
      stroke="#FF8477"
      strokeWidth="2.5"
      strokeLinecap="round"
      variants={pathVariants}
      initial="hidden"
      animate="visible"
    />
    <motion.line
      x1="20.5248"
      y1="15.9044"
      x2="27.5542"
      y2="15.9044"
      stroke="#FFFFFF"
      strokeWidth="2"
      strokeLinecap="round"
      variants={pathVariants}
      initial="hidden"
      animate="visible"
    />
    <motion.path
      d="M25.364 12.0483L28.9541 15.6385C29.1494 15.8338 29.1494 16.1504 28.9541 16.3456L25.364 19.9357"
      stroke="#FFFFFF"
      strokeWidth="2"
      strokeLinecap="round"
      variants={pathVariants}
      initial="hidden"
      animate="visible"
    />
  </Icon>
);

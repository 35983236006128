import appsignal from '../../appsignal';
import {
  Heading,
  Center,
  VStack,
  Text,
  Image,
  useToast,
} from '@chakra-ui/react';
import { FORM_PADDING_TOP_XL } from '../../utils/consts';
import { useNavigate, useParams } from 'react-router';
import { useContext, useEffect } from 'react';
import { AuthContext } from '../../context/AuthContext';
import verifyingYourEmailSrc from '../../assets/vectors/verifying-your-email.svg';
import CustomToast from '../../common/CustomToast';
import { getErrorResponsePayload } from '../../utils/ajax';
import { HttpContext } from '../../context/HttpContext';

function VerifyingChangedEmailPage() {
  const navigate = useNavigate();
  const { confirmationToken } = useParams();
  const { setAuthState } = useContext(AuthContext);
  const { publicAxios } = useContext(HttpContext);
  const toast = useToast();

  useEffect(() => {
    const getData = async () => {
      try {
        const { data: userDataWithMeta } = await publicAxios.post(
          `users/email/confirm`,
          {
            token: confirmationToken,
          }
        );

        const { data } = userDataWithMeta;

        const { token, refresh_token: refreshToken, user } = data;

        setAuthState({ token, user, refreshToken });

        navigate('/');
      } catch (onError) {
        appsignal.sendError(onError);
        console.log(onError);
        const { message, code, errors } = getErrorResponsePayload(onError);
        toast({
          render: (props) => (
            <CustomToast
              status="error"
              title={message ? message : `Something went wrong`}
              description={!message && 'Please try again later'}
              onClose={props.onClose}
            />
          ),
        });
        navigate('/error', { state: { message, code, errors } });
      }
    };

    getData();
  }, []);

  return (
    <Center pt={FORM_PADDING_TOP_XL} pb="10px">
      <VStack spacing={2}>
        <Image
          src={verifyingYourEmailSrc}
          alt="Verifying your email"
          mb={10}
          alignSelf="center"
        />
        <Heading size="2xl" textAlign="center">
          Verifying your email...
        </Heading>
        <VStack spacing={5}>
          <Text textStyle="bodyRoman" color="secondaryDark08">
            Please be patient.
          </Text>
        </VStack>
      </VStack>
    </Center>
  );
}

export default VerifyingChangedEmailPage;

import React from 'react';
import { Flex, Heading, Button } from '@chakra-ui/react';
import { Link } from 'react-router-dom';

function MessagesHeader({ isCreatePage }) {
  return (
    <>
      <Flex
        flexDirection={{ base: 'column', sm: 'row' }}
        flexWrap="wrap"
        mt="15px"
        pt="9px"
        minH="54px"
      >
        <Flex
          gap={{ base: '10px' }}
          alignItems="center"
          justifyContent="space-between"
          flexWrap="wrap"
          width="full"
          mb={{ base: '10px', sm: '0px' }}
        >
          <Heading alignSelf={{ base: 'flex-start', sm: 'center' }}>
            {isCreatePage ? 'New message' : 'Message History'}
          </Heading>
          <Button
            alt="New message"
            mr={{ base: '0px', sm: '10px' }}
            px={{ base: '20px', sm: '40px' }}
            size="sm"
            as={Link}
            to={isCreatePage ? '/messaging' : '/messaging/create'}
          >
            {isCreatePage ? 'Message History' : 'New message'}
          </Button>
        </Flex>
      </Flex>
    </>
  );
}

export default MessagesHeader;

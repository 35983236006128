import { useState, useEffect, useMemo, useContext } from 'react';
import {
  Box,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  Select,
  Button,
  Grid,
  GridItem,
  useToast,
  useColorModeValue,
  IconButton,
  Flex,
  Text,
  Hide,
  Show,
  Divider,
  Checkbox,
  FormHelperText,
  Switch,
  FormErrorMessage,
  useDisclosure,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { useForm, useFieldArray } from 'react-hook-form';
import CustomToast from '../../common/CustomToast';
import { CustomPlusIcon } from '../../theme/icons/CustomPlusIcon';
import { CustomCloseIcon } from '../../theme/icons/CustomCloseIcon';
import CustomDatePicker from '../../components/common/CustomDatePicker/CustomDatePicker';
import CustomTimePicker from '../../components/common/CustomTimePicker/CustomTimePicker';
import moment from 'moment';
import { timezoneList } from '../../utils/timezones';
import { HttpContext } from '../../context/HttpContext';
import { yupResolver } from '@hookform/resolvers/yup';
import AlertDialogWrapper from '../../components/common/AlertDialogWrapper';
import { getErrors } from '../../utils/ajax';
import * as Yup from 'yup';

const SmsMessagesPage = ({ filterEntity }) => {
  const modeBoxBg = useColorModeValue('gradient', 'secondaryDark');
  const modeFormControlBg = useColorModeValue('white', '');
  const modeLabelBg = useColorModeValue('#EFEFEF', 'primaryDark03');
  const modeTextBg = useColorModeValue('quaternaryBackground', 'primaryDark06');
  const modeBorderColor = useColorModeValue('primaryBackground', 'primaryDark');
  const [variableNames, setVariableNames] = useState([]);
  const timezones = useMemo(timezoneList, []);
  const { authAxios } = useContext(HttpContext);
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [formData, setFormData] = useState(null);

  const validationSchema = Yup.object().shape({
    batchName: Yup.string(),
    type: Yup.string().required('Type is required'),
    tag: Yup.string(),
    message: Yup.string()
      .required('Message is required')
      .max(160, 'Message cannot exceed 160 characters')
      .min(10, 'Message must be at least 10 characters'),
    mediaUrl: Yup.string().when('type', {
      is: 'MMS',
      then: (schema) =>
        schema
          .url('Media URL must be a valid URL: https://example.com/image.jpg')
          .required('Media URL is required for MMS messages'),
      otherwise: (schema) => schema.notRequired(),
    }),
    schedule: Yup.boolean(),
    scheduledDate: Yup.date()
      .nullable()
      .when('schedule', {
        is: true,
        then: (schema) => schema.required('Scheduled date is required'),
        otherwise: (schema) => schema.notRequired().nullable(),
      }),
    scheduledTime: Yup.string()
      .nullable()
      .when('schedule', {
        is: true,
        then: (schema) =>
          schema
            .required('Scheduled time is required')
            .matches(
              /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]\s?(AM|PM)$/i,
              'Scheduled time must be in HH:MM AM/PM format'
            ),
        otherwise: (schema) => schema.notRequired().nullable(),
      }),
    timezone: Yup.string().when('schedule', {
      is: true,
      then: (schema) => schema.required('Timezone is required'),
    }),
    useCsvUpload: Yup.boolean(),
    recipients: Yup.array().when('useCsvUpload', {
      is: false,
      then: (schema) =>
        schema
          .of(
            Yup.object().shape({
              phoneNumber: Yup.string()
                .required('Phone Number is required')
                .matches(
                  /^\+?[1-9]\d{1,14}$/,
                  'Phone number must be in international E.164 format: +14155552671'
                ),
              variables: Yup.lazy(() =>
                Yup.object().shape(
                  variableNames.reduce((acc, varName) => {
                    acc[varName] = Yup.string().required(
                      `${varName} is required`
                    );
                    return acc;
                  }, {})
                )
              ),
            })
          )
          .required('Recipients are required')
          .min(1, 'At least one recipient is required'),
      otherwise: (schema) => schema.notRequired(),
    }),
    csvFile: Yup.mixed().when('useCsvUpload', {
      is: true,
      then: (schema) =>
        schema
          .required('CSV file is required')
          .test('fileType', 'Only .csv files are accepted', (value) => {
            return value?.[0]?.type === 'text/csv';
          }),
      otherwise: (schema) => schema.notRequired(),
    }),
  });

  const {
    register,
    handleSubmit,
    watch,
    control,
    reset,
    setValue,
    getValues,
    formState: { errors, isSubmitting, isValid },
  } = useForm({
    resolver: yupResolver(validationSchema),
    shouldUnregister: true,
    defaultValues: {
      message: '',
      type: 'SMS',
      tag: '',
      mediaUrl: '',
      schedule: false,
      scheduledAt: '',
      scheduledDate: null,
      scheduledTime: '',
      timezone: moment.tz.guess(),
      batchName: '',
      recipients: [{ phoneNumber: '', variables: {} }],
      useCsvUpload: false,
      csvFile: null,
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'recipients',
  });

  const watchMessage = watch('message');
  const watchType = watch('type');
  const watchSchedule = watch('schedule');
  const [scheduledDate, setScheduledDate] = useState(null);
  const [scheduledTime, setScheduledTime] = useState('');
  const toast = useToast();
  const watchUseCsvUpload = watch('useCsvUpload');
  const watchCsvFile = watch('csvFile');

  useEffect(() => {
    const regex = /%\{(\w+)\}/g;
    const names = [];
    let match;
    while ((match = regex.exec(watchMessage)) !== null) {
      names.push(match[1]);
    }
    setVariableNames(names);

    if (!watchUseCsvUpload) {
      fields.forEach((field, index) => {
        const currentVariables =
          getValues(`recipients.${index}.variables`) || {};
        const updatedVariables = {};
        variableNames.forEach((name) => {
          updatedVariables[name] = currentVariables[name] || '';
        });
        setValue(`recipients.${index}.variables`, updatedVariables);
      });
    }
  }, [watchMessage, fields, setValue, watchUseCsvUpload]);

  const handleSendMessage = async (data) => {
    try {
      // Extract and format the scheduledAt date and time
      let scheduledAt = null;
      if (
        !!data.schedule &&
        data.scheduledDate &&
        data.scheduledTime &&
        data.timezone
      ) {
        const date = moment(scheduledDate).format('YYYY-MM-DD');
        const time = moment(scheduledTime, ['h:mm A']).format('HH:mm:ss');
        const dateTime = moment(date + time, 'YYYY-MM-DD HH:mm:ss')
          .tz(data.timezone)
          .format('YYYY-MM-DD HH:mm:ss');

        scheduledAt = moment(dateTime).toISOString();
      }

      // Construct the message object
      const message = {
        type: data.type,
      };

      if (variableNames.length > 0) {
        message.messageTemplate = data.message;
      } else {
        message.content = data.message;
      }

      if (data.mediaUrl) {
        message.mediaUrl = data.mediaUrl;
      }

      const useCsvUpload = data.useCsvUpload;

      // If using CSV upload
      if (useCsvUpload && data.csvFile && data.csvFile[0]) {
        const formData = new FormData();

        formData.append('csvFile', data.csvFile[0]);

        if (data.batchName) formData.append('batchName', data.batchName);
        if (data.tag) formData.append('tag', data.tag);

        // Append message fields to formData
        for (const key in message) {
          formData.append(`message[${key}]`, message[key]);
        }

        if (scheduledAt) {
          formData.append('scheduledAt', scheduledAt);
        }

        // Make a POST request with FormData
        await authAxios.post(
          `api/v1/entities/${filterEntity.uuid}/messaging/mobile-messaging`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          }
        );
      } else {
        // Construct the request payload
        const requestPayload = {
          message: message,
          recipients: data.recipients,
        };

        if (data.batchName) {
          requestPayload.batchName = data.batchName;
        }

        if (data.tag) {
          requestPayload.tag = data.tag;
        }

        if (scheduledAt) {
          requestPayload.scheduledAt = scheduledAt;
        }

        await authAxios.post(
          `api/v1/entities/${filterEntity.uuid}/messaging/mobile-messaging`,
          requestPayload
        );
      }

      // Show success message
      toast({
        render: ({ onClose }) => (
          <CustomToast
            status="success"
            title="Message batch created"
            description="Your messages are currently being processed."
            onClose={onClose}
          />
        ),
      });

      reset();
      navigate('/messaging');
    } catch (error) {
      onClose();
      const errors = getErrors(error);
      errors?.forEach((errorMessage) => {
        toast({
          render: ({ onClose }) => (
            <CustomToast
              status="error"
              title="Submission failed"
              description={
                errorMessage ? errorMessage : 'Please try again later.'
              }
              onClose={onClose}
            />
          ),
        });
      });
    }
  };

  const onSubmit = async (data) => {
    setFormData(data);
    onOpen();
  };

  useEffect(() => {
    setValue('scheduledDate', scheduledDate, { shouldValidate: true });
  }, [scheduledDate, setValue]);

  useEffect(() => {
    setValue('scheduledTime', scheduledTime, { shouldValidate: true });
  }, [scheduledTime, setValue]);

  const sampleCsvContent = `phone_number,name,ticket_number
+12505550199,John Doe,ABC123
+441234567890,Jane Smith,XYZ789`;

  const handleDownloadSampleCsv = () => {
    const csvContent =
      'data:text/csv;charset=utf-8,' + encodeURIComponent(sampleCsvContent);
    const link = document.createElement('a');
    link.setAttribute('href', csvContent);
    link.setAttribute('download', 'sample_recipients.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <>
      <Box w="full">
        <Box className="primaryBoxShadow" bg={modeBoxBg} borderRadius="15px">
          <form onSubmit={handleSubmit(onSubmit)} noValidate>
            <Text
              fontSize="18px"
              textStyle="headingFamilyMedium"
              p="14px 14px 14px 24px"
              bg={modeTextBg}
              borderRadius="15px 15px 0 0"
            >
              Create SMS/MMS Message
            </Text>
            <Divider borderColor={modeBorderColor} />

            {/* Batch Name */}
            <FormControl bg={modeFormControlBg}>
              <Flex alignItems="center">
                <Hide below="md">
                  <Box flexShrink={0} w="240px" pl="24px">
                    <FormLabel m={0}>Batch Name</FormLabel>
                  </Box>
                </Hide>
                <Box p="19px" w="full" bg={modeLabelBg}>
                  <Show below="md">
                    <FormLabel mb={3}>Batch Name</FormLabel>
                  </Show>
                  <Input
                    placeholder="Enter batch name"
                    {...register('batchName')}
                  />
                </Box>
              </Flex>
            </FormControl>
            <Divider borderColor={modeBorderColor} />

            {/* Type */}
            <FormControl isRequired bg={modeFormControlBg}>
              <Flex alignItems="center">
                <Hide below="md">
                  <Box flexShrink={0} w="240px" pl="24px">
                    <FormLabel m={0}>Type</FormLabel>
                  </Box>
                </Hide>
                <Box p="19px" w="full" bg={modeLabelBg}>
                  <Show below="md">
                    <FormLabel mb={3}>Type</FormLabel>
                  </Show>
                  <Select {...register('type')}>
                    <option value="SMS">SMS</option>
                    <option value="MMS">MMS</option>
                  </Select>
                </Box>
              </Flex>
            </FormControl>
            <Divider borderColor={modeBorderColor} />

            {/* Tag */}
            <FormControl bg={modeFormControlBg}>
              <Flex alignItems="center">
                <Hide below="md">
                  <Box flexShrink={0} w="240px" pl="24px">
                    <FormLabel m={0}>Tag</FormLabel>
                  </Box>
                </Hide>
                <Box p="19px" w="full" bg={modeLabelBg}>
                  <Show below="md">
                    <FormLabel mb={3}>Tag</FormLabel>
                  </Show>

                  <Input placeholder="Enter tag" {...register('tag')} />

                  <FormHelperText mt={2}>
                    Assign a custom label to this message batch. This tag helps
                    you categorise and identify your messages for future
                    reference or reporting.
                  </FormHelperText>
                </Box>
              </Flex>
            </FormControl>
            <Divider borderColor={modeBorderColor} />

            {/* Message */}
            <FormControl
              isRequired
              bg={modeFormControlBg}
              isInvalid={!!errors.message}
            >
              <Flex alignItems="center">
                <Hide below="md">
                  <Box flexShrink={0} w="240px" pl="24px">
                    <FormLabel m={0}>Message</FormLabel>
                  </Box>
                </Hide>
                <Box p="19px" w="full" bg={modeLabelBg}>
                  <Show below="md">
                    <FormLabel mb={3}>Message</FormLabel>
                  </Show>
                  <Textarea
                    id="message"
                    placeholder="Enter your message here (max 160 characters)"
                    maxLength={160}
                    {...register('message')}
                  />
                  {errors.message && (
                    <FormErrorMessage mt={2}>
                      {errors.message.message}
                    </FormErrorMessage>
                  )}
                  <FormHelperText mt={2}>
                    You can use variables to customise your message. Example:
                    <Text fontWeight="bold" display="inline">
                      {' '}
                      Welcome, %{'{'}name{'}'}! Your membership ID is: %{'{'}
                      membership_id{'}'}
                    </Text>
                    .
                  </FormHelperText>
                </Box>
              </Flex>
            </FormControl>
            <Divider borderColor={modeBorderColor} />

            {/* Media URL */}
            {watchType === 'MMS' && (
              <>
                <FormControl
                  isRequired
                  bg={modeFormControlBg}
                  isInvalid={errors.mediaUrl}
                >
                  <Flex alignItems="center">
                    <Hide below="md">
                      <Box flexShrink={0} w="240px" pl="24px">
                        <FormLabel m={0}>Media URL</FormLabel>
                      </Box>
                    </Hide>
                    <Box p="19px" w="full" bg={modeLabelBg}>
                      <Show below="md">
                        <FormLabel mb={3}>Media URL</FormLabel>
                      </Show>
                      <Input
                        placeholder="Enter media URL starting with https://"
                        {...register('mediaUrl', {
                          required: watchType === 'MMS',
                        })}
                      />
                      {errors.mediaUrl && (
                        <FormErrorMessage mt={2}>
                          {errors.mediaUrl.message}
                        </FormErrorMessage>
                      )}
                    </Box>
                  </Flex>
                </FormControl>
                <Divider borderColor={modeBorderColor} />
              </>
            )}

            {/* Schedule Checkbox */}
            <FormControl bg={modeFormControlBg}>
              <Flex alignItems="center">
                <Hide below="md">
                  <Box flexShrink={0} w="240px" pl="24px">
                    <FormLabel m={0}>Send Options</FormLabel>
                  </Box>
                </Hide>
                <Box p="19px" w="full" bg={modeLabelBg}>
                  <Show below="md">
                    <FormLabel mb={3}>Send Options</FormLabel>
                  </Show>
                  <Checkbox {...register('schedule')}>
                    Schedule for later
                  </Checkbox>
                </Box>
              </Flex>
            </FormControl>
            <Divider borderColor={modeBorderColor} />

            {/* Scheduled At */}
            {watchSchedule && (
              <>
                <Flex alignItems="center">
                  <Hide below="md">
                    <Box flexShrink={0} w="240px" pl="24px">
                      <FormLabel m={0}>Send At</FormLabel>
                    </Box>
                  </Hide>
                  <Box p="19px" w="full" bg={modeLabelBg}>
                    <Show below="md">
                      <FormLabel mb={3}>Send At</FormLabel>
                    </Show>
                    <Box w="full">
                      <Input
                        id="scheduledAt"
                        name="scheduledAt"
                        type="hidden"
                        {...register('scheduledAt')}
                      />
                      <Flex
                        direction={{
                          base: 'column',
                          xl: 'row',
                        }}
                        gap={4}
                      >
                        <Box>
                          <FormControl isInvalid={errors.scheduledDate}>
                            <CustomDatePicker
                              placementTop
                              setDate={setScheduledDate}
                              dateValue={scheduledDate}
                              startDate
                              minBookingDate={new Date()}
                            />
                            {errors.scheduledDate && (
                              <FormErrorMessage mt={2}>
                                {errors.scheduledDate.message}
                              </FormErrorMessage>
                            )}
                          </FormControl>
                        </Box>
                        <Box>
                          <FormControl isInvalid={errors.scheduledTime}>
                            <CustomTimePicker
                              timeValue={scheduledTime}
                              setTimeValue={setScheduledTime}
                              placementTop
                              startTime
                            />
                            {errors.scheduledTime && (
                              <FormErrorMessage mt={2}>
                                {errors.scheduledTime.message}
                              </FormErrorMessage>
                            )}
                          </FormControl>
                        </Box>
                        <Box>
                          <Select
                            {...register('timezone')}
                            variant="filledForDarkBg"
                          >
                            {timezones.map((timezone, index) => {
                              return (
                                <option key={index} value={timezone.name}>
                                  {timezone.key}
                                </option>
                              );
                            })}
                          </Select>
                          {errors.timezone && (
                            <FormErrorMessage mt={2}>
                              {errors.timezone.message}
                            </FormErrorMessage>
                          )}
                        </Box>
                      </Flex>
                    </Box>
                    <FormControl>
                      <FormHelperText mt={2}>
                        You can schedule messages between 20 minutes from now
                        and up to 35 days in advance. Messages will be sent
                        according to the selected timezone.
                      </FormHelperText>
                    </FormControl>
                  </Box>
                </Flex>
                <Divider borderColor={modeBorderColor} />
              </>
            )}

            {/* Recipients */}
            <FormControl bg={modeFormControlBg}>
              <Flex alignItems="flex-start">
                <Hide below="md">
                  <Box flexShrink={0} w="240px" pl="24px" pt="19px">
                    <FormLabel m={0}>Recipients</FormLabel>
                    <FormHelperText mt={2}>
                      You can add up to 10 recipients. For more, please use CSV
                      upload.
                    </FormHelperText>
                    <Flex alignItems="center" mt={4}>
                      <Text mr={2} fontSize="17px">
                        CSV
                      </Text>
                      <Switch
                        id="csv-mode"
                        size="lg"
                        {...register('useCsvUpload')}
                      />
                    </Flex>
                  </Box>
                </Hide>
                <Box p="19px" w="full" bg={modeLabelBg}>
                  <Show below="md">
                    <FormLabel mb={3}>Recipients</FormLabel>
                    <FormHelperText my={2}>
                      You can add up to 10 recipients. For more, please use CSV
                      upload.
                    </FormHelperText>
                  </Show>
                  {watchUseCsvUpload ? (
                    <>
                      {/* CSV Upload Form */}
                      <FormControl isInvalid={!!errors.csvFile}>
                        <FormLabel mb={3}>
                          Upload CSV File with Recipients
                        </FormLabel>
                        <Button
                          as="label"
                          htmlFor="csv-upload"
                          cursor="pointer"
                          variant="outline"
                          size="md"
                          width={{ base: 'full', xl: '50%', '2xl': '33%' }}
                          bg={modeFormControlBg}
                          borderColor={errors.csvFile ? 'error' : 'inherit'}
                          borderWidth={errors.csvFile ? '2px' : '0px'}
                          color={errors.csvFile ? 'red.500' : 'inherit'}
                        >
                          {watchCsvFile && watchCsvFile[0]
                            ? watchCsvFile[0].name
                            : 'Choose File'}
                          <input
                            id="csv-upload"
                            type="file"
                            accept=".csv"
                            style={{ display: 'none' }}
                            {...register('csvFile')}
                          />
                        </Button>
                        {errors.csvFile && (
                          <FormErrorMessage mt={3}>
                            {errors.csvFile.message}
                          </FormErrorMessage>
                        )}
                        <FormHelperText mt={3}>
                          The CSV file should have a 'phone_number' column and
                          any variables used in the message.
                          <Text>
                            <Button
                              variant="link"
                              size="sm"
                              onClick={handleDownloadSampleCsv}
                            >
                              Download Sample CSV
                            </Button>{' '}
                            for message{' '}
                            <Text as="span" fontWeight="semibold">
                              &quot;Hello, %&#123;name&#125;. Your ticket number
                              is %&#123;ticket_number&#125;&quot;
                            </Text>
                            :
                          </Text>
                        </FormHelperText>
                      </FormControl>
                    </>
                  ) : (
                    <>
                      <Grid
                        templateColumns={{
                          base: '1fr',
                          md:
                            variableNames.length === 0
                              ? 'repeat(2, 1fr)'
                              : '1fr',
                          '2xl':
                            (variableNames.length > 0 && fields.length > 1) ||
                            variableNames.length === 1 ||
                            variableNames.length > 3
                              ? 'repeat(2, 1fr)'
                              : variableNames.length > 0 && fields.length === 1
                                ? '1fr'
                                : 'repeat(3, 1fr)',
                        }}
                        gap={4}
                        width="100%"
                      >
                        {fields.map((field, index) => (
                          <GridItem key={field.id}>
                            <Box
                              flex="1"
                              borderWidth="1px"
                              p={4}
                              borderRadius="15px"
                              bg={modeFormControlBg}
                            >
                              <Flex
                                alignItems="center"
                                justifyContent="space-between"
                              >
                                <Box flex="1">
                                  <Grid
                                    templateColumns={{
                                      base: '1fr',
                                      sm:
                                        variableNames.length === 0
                                          ? '1fr auto'
                                          : 'repeat(2, 1fr)',
                                      lg: '1fr',
                                      xl:
                                        variableNames.length === 0
                                          ? '1fr'
                                          : variableNames.length < 4 &&
                                              fields.length === 1
                                            ? `repeat(${variableNames.length + 1}, 1fr)`
                                            : 'repeat(2, 1fr)',
                                    }}
                                    gap={2}
                                  >
                                    <GridItem>
                                      <FormControl
                                        isRequired
                                        isInvalid={
                                          !!errors?.recipients?.[index]
                                            ?.phoneNumber
                                        }
                                      >
                                        <Input
                                          placeholder="Phone Number"
                                          {...register(
                                            `recipients.${index}.phoneNumber`
                                          )}
                                        />
                                        {!!errors?.recipients?.[index]
                                          ?.phoneNumber && (
                                          <FormErrorMessage mt={2}>
                                            {
                                              errors.recipients[index]
                                                .phoneNumber.message
                                            }
                                          </FormErrorMessage>
                                        )}
                                      </FormControl>
                                    </GridItem>

                                    {/* Variables Input Fields */}
                                    {variableNames.length > 0 &&
                                      variableNames.map((varName, varIndex) => (
                                        <GridItem
                                          key={`${varIndex}-${varName}`}
                                        >
                                          <FormControl
                                            key={varName}
                                            isRequired
                                            isInvalid={
                                              !!errors?.recipients?.[index]
                                                ?.variables?.[varName]
                                            }
                                          >
                                            <Input
                                              placeholder={`${varName
                                                .split('_')
                                                .map(
                                                  (word) =>
                                                    word
                                                      .charAt(0)
                                                      .toUpperCase() +
                                                    word.slice(1)
                                                )
                                                .join(' ')}`}
                                              {...register(
                                                `recipients.${index}.variables.${varName}`
                                              )}
                                            />
                                            {!!errors?.recipients?.[index]
                                              ?.variables?.[varName]
                                              ?.message && (
                                              <FormErrorMessage mt={2}>
                                                {
                                                  errors.recipients[index]
                                                    .variables[varName].message
                                                }
                                              </FormErrorMessage>
                                            )}
                                          </FormControl>
                                        </GridItem>
                                      ))}
                                  </Grid>
                                </Box>
                                {fields.length > 1 && (
                                  <IconButton
                                    icon={<CustomCloseIcon w="12px" h="12px" />}
                                    w="30px"
                                    h="30px"
                                    p={0}
                                    m={0}
                                    ml={2}
                                    onClick={() => remove(index)}
                                    aria-label="Remove recipient"
                                    borderRadius="50%"
                                    minWidth="unset"
                                  />
                                )}
                              </Flex>
                            </Box>
                          </GridItem>
                        ))}
                      </Grid>
                      <Button
                        leftIcon={<CustomPlusIcon />}
                        size="sm"
                        onClick={() =>
                          append({ phoneNumber: '', variables: {} })
                        }
                        mt={4}
                        isDisabled={fields.length >= 10}
                      >
                        Add Recipient
                      </Button>
                    </>
                  )}
                </Box>
              </Flex>
            </FormControl>

            <Divider borderColor={modeBorderColor} />

            {/* Submit Button */}
            <Flex justifyContent="flex-end" p="19px">
              <Button
                type="submit"
                width={{ base: 'full', sm: 'auto' }}
                isLoading={isSubmitting}
                isDisabled={!filterEntity}
              >
                Send Message
              </Button>
            </Flex>
          </form>
        </Box>
      </Box>
      <AlertDialogWrapper
        isOpen={isOpen}
        onClose={() => {
          onClose();
          setFormData(null);
        }}
        onConfirm={() => handleSendMessage(formData)}
        title="Send Message"
        body="Please double check your message and recipients before sending."
        buttonText="Send"
      />
    </>
  );
};

export default SmsMessagesPage;

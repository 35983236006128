import {
  SimpleGrid,
  GridItem,
  Container,
  Badge,
  Center,
  Circle,
  Box,
  Flex,
  HStack,
} from '@chakra-ui/react';

function Badges() {
  return (
    <Container bg="white" borderBottom="solid 1px #DDD">
      <Container maxW="1180px" px="0" py={6}>
        <SimpleGrid
          w="full"
          columns={{ base: 1, sm: 2, md: 4 }}
          columnGap={4}
          rowGap={4}
        >
          <GridItem py={2} textAlign="center">
            <Badge variant="active">Active</Badge>
          </GridItem>
          <GridItem py={3}>
            <Center>
              <Circle bg="success" size="12px" mr="6px"></Circle>
              <Box
                fontSize="12px"
                color="secondaryDark"
                top="1px"
                pos="relative"
              >
                Email verified
              </Box>
            </Center>
          </GridItem>
          <GridItem py={3}>
            <Center>
              <Flex
                bg="#ddd"
                borderRadius="20px"
                justifyContent="center"
                alignItems="center"
                maxWidth="100px"
              >
                <HStack pr="12px" pl="12px">
                  <Circle bg="error" size="12px" />
                  <Box
                    fontSize="12px"
                    top="1px"
                    pos="relative"
                    pb="1px"
                    color="secondaryDark"
                  >
                    Disabled
                  </Box>
                </HStack>
              </Flex>
            </Center>
          </GridItem>
          <GridItem py={3}>
            <Center>
              <Flex
                bg="#ddd"
                borderRadius="20px"
                justifyContent="center"
                alignItems="center"
                maxWidth="100px"
              >
                <HStack pr="12px" pl="12px">
                  <Circle bg="error" size="12px" />
                  <Box
                    fontSize="12px"
                    top="1px"
                    pos="relative"
                    pb="1px"
                    color="secondaryDark"
                  >
                    Deactivated
                  </Box>
                </HStack>
              </Flex>
            </Center>
          </GridItem>
          <GridItem py={3}>
            <Center>
              <Flex
                bg="#ddd"
                borderRadius="20px"
                justifyContent="center"
                alignItems="center"
                maxWidth="100px"
              >
                <HStack pr="12px" pl="12px">
                  <Circle bg="success" size="12px" />
                  <Box
                    fontSize="12px"
                    top="1px"
                    pos="relative"
                    pb="1px"
                    color="secondaryDark"
                  >
                    Ongoing
                  </Box>
                </HStack>
              </Flex>
            </Center>
          </GridItem>
          <GridItem py={3}>
            <Center>
              <Badge variant="issued">Issued</Badge>
            </Center>
          </GridItem>
          <GridItem py={3}>
            <Center>
              <Badge variant="error">Passed</Badge>
            </Center>
          </GridItem>
          <GridItem py={3}>
            <Center>
              <Badge variant="error">Expired</Badge>
            </Center>
          </GridItem>
          <GridItem py={3}>
            <Center>
              <Circle bg="secondaryDark08" size="12px" mr="6px"></Circle>
              <Box
                fontSize="12px"
                color="secondaryDark"
                top="1px"
                pos="relative"
                // maxW="82px"
                // lineHeight="120%"
                // textAlign="left"
              >
                Pending email verification
              </Box>
            </Center>
          </GridItem>
          <GridItem py={3}>
            <Center>
              <Circle bg="secondaryDark08" size="12px" />
              <Box
                fontSize="12px"
                color="secondaryDark"
                top="1px"
                pos="relative"
                // maxW="82px"
                // lineHeight="120%"
                // textAlign="left"
              >
                Pending activation
              </Box>
            </Center>
          </GridItem>
        </SimpleGrid>
      </Container>
    </Container>
  );
}

export default Badges;

import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
} from '@chakra-ui/react';

function InfoDrawer({ isOpen, onClose, header, footer, children, bgColors }) {
  const { modeBoxBg, modeTextBg, modeBorderColor, modeLabelBg } = bgColors;

  return (
    <Drawer onClose={onClose} isOpen={isOpen} size="md">
      <DrawerOverlay />
      <DrawerContent bg={modeBoxBg}>
        <DrawerCloseButton />
        <DrawerHeader
          borderBottomWidth="1px"
          borderBottomColor={modeBorderColor}
          bg={modeTextBg}
        >
          {header}
        </DrawerHeader>
        <DrawerBody p={0} bg={modeLabelBg}>
          {children}
        </DrawerBody>
        <DrawerFooter
          borderTopWidth="1px"
          borderTopColor={modeBorderColor}
          bg={modeTextBg}
        >
          {footer}
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
}

export default InfoDrawer;

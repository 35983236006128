import { Icon } from '@chakra-ui/react';

export const CustomLogoutMultiIcon = (props) => (
  <Icon viewBox="0 0 62 53" {...props}>
    <path
      className="multicolorElToDark darkBgElToWhite"
      d="M61.7934 27.4603C62.2068 26.6406 62.0001 25.616 61.3801 25.0012L49.1868 12.0907C48.3601 11.0661 46.9134 11.0661 45.8801 12.0907C45.2601 12.5006 45.0534 13.1154 45.0534 13.7302C45.0534 14.345 45.2601 14.9597 45.6734 15.3696L54.1468 24.3864H22.1134C20.8734 24.3864 19.8401 25.4111 19.8401 26.6406C19.8401 27.8702 20.8734 28.8948 22.1134 28.8948H54.1468L45.6734 37.9117C44.6401 38.9363 44.8468 40.3708 45.6734 41.1905C46.7068 42.2151 48.1534 42.0102 48.9801 41.1905L60.9668 28.485C61.3801 28.0751 61.5868 27.8702 61.7934 27.4603Z"
      fill="#FF8477"
    />
    <path
      className="multicolorElToWhite darkBgElToBrand"
      d="M0 50.0024V2.25421C0 1.02464 1.03333 0 2.27333 0H39.2667C40.5067 0 41.54 1.02464 41.54 2.25421V14.7548C41.54 15.9844 40.5067 17.009 39.2667 17.009C38.0267 17.009 36.9933 15.9844 36.9933 14.7548V4.50841H4.75333V47.5433H36.9933V37.2969C36.9933 36.0673 38.0267 35.0427 39.2667 35.0427C40.5067 35.0427 41.54 36.0673 41.54 37.2969V49.7975C41.54 51.027 40.5067 52.0517 39.2667 52.0517H2.27333C1.03333 52.4615 0 51.4369 0 50.0024Z"
      fill="currentColor"
    />
  </Icon>
);

import { useRef, useEffect, useContext } from 'react';
import { Circle, Box } from '@chakra-ui/react';
import PassTemplateContext from '../../../store/client/PassTemplateContext';

const CanvasEyeDropper = ({ color, onChange, dropperImage }) => {
  const canvasRef = useRef(null);
  const previewElemRef = useRef(null);
  const { iconLogo, logoImage, bannerImage, passType } =
    useContext(PassTemplateContext);

  // load dropper image, set canvas width / height
  let baseImage;
  let baseImageAspectRatio;
  let width;
  let height;

  if (dropperImage === 'android') {
    baseImage = iconLogo;
    //baseImageAspectRatio = 1
    width = 200;
    height = 200;
  }

  if (dropperImage === 'apple') {
    baseImage = logoImage;
    baseImageAspectRatio = 110 / 360;
    // calculated canvas width and height based on dropper image aspect ratio
    // max width is 405px
    width = window.innerWidth - 100 > 403 ? 403 : window.innerWidth - 93;
    height = width * baseImageAspectRatio;
  }

  if (dropperImage === 'banner') {
    baseImage = bannerImage;
    let baseImageAspectRatio;
    if (passType === 'eventTicket') {
      baseImageAspectRatio = 294 / 1125;
    } else if (passType === 'storeCard' || passType === 'stampCard') {
      baseImageAspectRatio = 432 / 1125;
    } else {
      baseImageAspectRatio = 370 / 1125;
    }
    // need to be changed after google integration
    // baseImageAspectRatio = 336 / 1032;
    width = window.innerWidth - 100 > 403 ? 403 : window.innerWidth - 93;
    height = width * baseImageAspectRatio;
  }

  const rgbToHex = (r, g, b) =>
    '#' +
    [r, g, b]
      .map((x) => {
        const hex = x.toString(16);
        return hex.length === 1 ? '0' + hex : hex;
      })
      .join('');

  const colorPickUp = (context, xMousePosition, yMousePosition) => {
    const pixel = context.getImageData(xMousePosition, yMousePosition, 1, 1);
    const data = pixel.data;

    const r = data[0];
    const b = data[1];
    const g = data[2];

    return rgbToHex(r, b, g);
  };

  const setPreviewElementPosition = (xMousePosition, yMousePosition) => {
    const previewElem = previewElemRef.current;

    // include in calculation the width / height of preview element
    const widthOffset = width - 55;
    const heightOffset = height - 55;

    previewElem.style.left =
      xMousePosition > widthOffset
        ? `${widthOffset - 10}px`
        : `${xMousePosition + 15}px`;
    previewElem.style.top =
      yMousePosition > heightOffset
        ? `${heightOffset - 10}px`
        : `${yMousePosition + 15}px`;
  };

  const prepareHoverColorHandle = () => {
    let isImageLoaded = false;

    return (e) => {
      const canvas = canvasRef.current;
      const ctx = canvas.getContext('2d');

      if (!isImageLoaded) {
        const image = new Image();
        image.onload = () => {
          ctx.drawImage(image, 0, 0, width, height);
        };
        image.crossOrigin = 'Anonymous';
        image.src = baseImage;
        isImageLoaded = true;
      }

      const x = e.nativeEvent.offsetX;
      const y = e.nativeEvent.offsetY;

      setPreviewElementPosition(x, y);

      const hexColor = colorPickUp(ctx, x, y);

      previewElemRef.current.style.backgroundColor = hexColor;
    };
  };

  const handleHoverColor = prepareHoverColorHandle();

  const applySelectedColorHandler = (e) => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');

    const x = e.nativeEvent.offsetX;
    const y = e.nativeEvent.offsetY;

    const hexColor = colorPickUp(ctx, x, y);
    onChange(hexColor);
  };

  useEffect(() => {
    const canvas = canvasRef.current;
    canvas.style.borderRadius = '9px';
    canvas.width = width;
    canvas.height = height;

    const ctx = canvas.getContext('2d');
    const image = new Image();
    image.crossOrigin = 'Anonymous';
    image.src = baseImage;
    image.onload = () => {
      // logo images have transparent bg
      ctx.fillStyle = '#efefef';
      ctx.fillRect(0, 0, width, height);
      ctx.drawImage(image, 0, 0, width, height);
    };
  }, [iconLogo, width, height]);

  return (
    <Box position="relative">
      <canvas
        ref={canvasRef}
        onMouseMove={handleHoverColor}
        onClick={applySelectedColorHandler}
      />
      <Circle
        ref={previewElemRef}
        size="40px"
        border="1px"
        borderColor="gray.400"
        bg={color}
        position="absolute"
        left="0px"
        top="0px"
      ></Circle>
    </Box>
  );
};

export default CanvasEyeDropper;

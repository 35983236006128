import appsignal from '../../appsignal';
import { Heading, Center, VStack, Text } from '@chakra-ui/react';
import { FORM_PADDING_TOP_XL } from '../../utils/consts';
import { useParams } from 'react-router';
import { useEffect, useState } from 'react';
import VerifyingEmailIconAnimated from '../../theme/illustrations-animated//VerifyingEmailIconAnimated';
import { useContext } from 'react';
import { HttpContext } from '../../context/HttpContext';
import { CustomEmailMultiIcon } from '../../theme/multicolor-icons/CustomEmailMultiIcon';

function VerifyingYourEmail() {
  const { confirmationToken } = useParams();
  const { publicAxios } = useContext(HttpContext);
  const [emailVerified, setEmailVerified] = useState(false);

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await publicAxios.post(`accounts/confirmation`, {
          token: confirmationToken,
        });

        if (response.data.account) {
          if (response.data.account.email_verified) {
            setEmailVerified(true);
          }
        }
      } catch (onError) {
        appsignal.sendError(onError);
        console.log(onError);
      }
    };

    getData();
  }, []);

  return (
    <>
      {emailVerified ? (
        <Center pt={FORM_PADDING_TOP_XL} pb="10px">
          <VStack spacing={2}>
            <CustomEmailMultiIcon width="70" height="70" />
            <Heading mt={4} size="2xl" textAlign="center">
              Your email has been verified!
            </Heading>
            <VStack spacing={5}>
              <Text textStyle="bodyRoman" color="secondaryDark08">
                You can now login to your account.
              </Text>
            </VStack>
          </VStack>
        </Center>
      ) : (
        <Center pt={FORM_PADDING_TOP_XL} pb="10px">
          <VStack spacing={2}>
            <VerifyingEmailIconAnimated />
            <Heading size="2xl" textAlign="center">
              Verifying your email...
            </Heading>
            <VStack spacing={5}>
              <Text textStyle="bodyRoman" color="secondaryDark08">
                Please be patient.
              </Text>
            </VStack>
          </VStack>
        </Center>
      )}
    </>
  );
}

export default VerifyingYourEmail;

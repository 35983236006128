import { Flex, Heading } from '@chakra-ui/react';

const ApiKeyListHeader = () => {
  return (
    <Flex
      flexDirection={{ base: 'column', sm: 'row' }}
      alignItems="center"
      flexWrap="wrap"
      mt="15px"
      py="9px"
      minH="54px"
    >
      <Heading alignSelf="flex-start" mr={2}>
        Developer tools
      </Heading>
    </Flex>
  );
};
export default ApiKeyListHeader;

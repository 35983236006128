import { Icon } from '@chakra-ui/react';

export const CustomPlusIcon = (props) => (
  <Icon viewBox="0 0 8 8" fill="none" {...props}>
    <path
      d="M3.51672 7.50416H5.14872V4.54416H7.96472V3.08816H5.14872V0.160156H3.51672V3.08816H0.636719V4.54416H3.51672V7.50416Z"
      fill="currentColor"
    />
  </Icon>
);

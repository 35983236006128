import appsignal from '../../appsignal';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Button,
  Heading,
  Text,
  Stack,
  useToast,
  useColorModeValue,
} from '@chakra-ui/react';
import CustomToast from '../../common/CustomToast';
import { useForm } from 'react-hook-form';
import { useContext } from 'react';
import { useNavigate } from 'react-router';
import KeysListContext from '../../store/client/KeysListContext';
import { getErrorResponsePayload } from '../../utils/ajax';
import { HttpContext } from '../../context/HttpContext';

const DeleteApiKeyModal = ({ onClose, isOpen, actionUrl }) => {
  const { authAxios } = useContext(HttpContext);
  const keysListCtx = useContext(KeysListContext);
  const toast = useToast();
  const navigate = useNavigate();
  const modeBoxBg = useColorModeValue('white', 'secondaryDark');
  const modeTextBg = useColorModeValue('white', 'primaryDark06');
  const modeLabelBg = useColorModeValue('white', 'primaryDark03');
  const modeOffset = useColorModeValue('0', '16px !important');

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = useForm();

  const onSubmit = async (data) => {
    keysListCtx.updateKeyState({
      ...keysListCtx.keyState,
      loading: true,
      status: 'pending',
    });

    try {
      await authAxios.delete(`${actionUrl}`);
      const { data } = await authAxios.get(`keys`);
      const keys = data?.data || [];
      const total = data?.data.length ?? 0;
      keysListCtx.updateKeyState({
        ...keysListCtx.keyState,
        items: keys,
        total: total,
        loading: false,
        status: 'resolved',
      });

      toast({
        render: () => (
          <CustomToast
            status="success"
            title="API key has been deleted"
            description=""
            onClose={onClose}
          />
        ),
      });
      navigate('/developer-tools', { replace: true });
    } catch (error) {
      appsignal.sendError(error);
      console.log(error);
      keysListCtx.updateKeyState({
        ...keysListCtx.keyState,
        items: [],
        total: 0,
        loading: false,
        error: 'Something went wrong',
        status: 'rejected',
      });
      const { message, code } = getErrorResponsePayload(error);
      code !== 401 &&
        toast({
          render: (props) => (
            <CustomToast
              status="error"
              title={message ? message : `Something went wrong`}
              description={!message && 'Please try again later'}
              onClose={props.onClose}
            />
          ),
        });
    }
    onClose();
  };

  return (
    <Modal onClose={onClose} isOpen={isOpen} size="xl">
      <ModalOverlay />
      <ModalContent bg={modeBoxBg}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalHeader bg={modeTextBg} pb={modeOffset} borderRadius="15px 15px 0 0">
            <Heading fontSize={{ base: '24px', lg: '32px' }} lineHeight="130%">
              Delete API key
            </Heading>
          </ModalHeader>
          <ModalBody bg={modeLabelBg}>
            <Text>
              Are you sure you want to delete this API key? This action can not be undone.
            </Text>
          </ModalBody>
          <ModalFooter bg={modeTextBg} pt={modeOffset} borderRadius="0 0 15px 15px">
            <Stack
              spacing="10px"
              direction={{ base: 'column', sm: 'row' }}
              width={{ base: 'full', sm: 'auto' }}
            >
              <Button
                onClick={onClose}
                alt="Cancel"
                variant="secondary"
                width={{ base: 'full', sm: 'auto' }}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                alt="Delete API key"
                width={{ base: 'full', sm: 'auto' }}
                isLoading={isSubmitting}
              >
                Delete API key
              </Button>
            </Stack>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};
export default DeleteApiKeyModal;

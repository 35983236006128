import AppRoutes from './AppRoutes';
import { AuthProvider } from './context/AuthContext';
import { HttpProvider } from './context/HttpContext';
import { RedirectionContextProvider } from './context/RedirectionContext';

function App() {
  return (
    <AuthProvider>
      <HttpProvider>
        <RedirectionContextProvider>
          <AppRoutes />
        </RedirectionContextProvider>
      </HttpProvider>
    </AuthProvider>
  );
}

export default App;

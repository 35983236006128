import {
  SimpleGrid,
  GridItem,
  Container,
  Link,
  useColorModeValue,
} from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';

function FormControls() {
  return (
    <Container
      bg={useColorModeValue('white', 'primaryDark')}
      borderBottom="solid 1px #DDD"
    >
      <Container maxW="1180px" px="0" py={12}>
        <SimpleGrid w="full" columns="1" columnGap={4} rowGap={4}>
          <GridItem>
            <Link
              href="https://react-hook-form.com/"
              target="blank"
              textDecoration="underline"
            >
              React Hook Form
            </Link>{' '}
            library has been used for PassEntry project, and to see all variations for
            form controls like:
            <br />
            Input, Textarea, Select, Number input, Checkbox, Switch... and validation
            states of the controls and the form,
            <br />
            please log-out and visit static{' '}
            <Link
              size="sm"
              as={RouterLink}
              to="/form-example-page"
              textDecoration="underline"
              color="brand"
            >
              Example&nbsp;Form&nbsp;Page
            </Link>
            .
          </GridItem>
        </SimpleGrid>
      </Container>
    </Container>
  );
}

export default FormControls;

import appsignal from '../../appsignal';
import {
  Container,
  Heading,
  Divider,
  Flex,
  Box,
  useColorModeValue,
  Spacer,
  Button,
  SimpleGrid,
  FormControl,
  Hide,
  FormLabel,
  Show,
  Collapse,
  useMediaQuery,
  useToast,
  FormErrorMessage,
  Input,
  Text,
  Stack,
  Link,
  Checkbox,
  Alert,
  AlertIcon,
  VStack,
} from '@chakra-ui/react';
import {
  REGEX_CONTAINS_BOTH_LOWER_AND_UPPERCASE,
  REGEX_HAS_AT_LEAST_ONE_NUMBER,
  REGEX_HAS_SYMBOL,
} from '../../utils/validations';

import { CustomReactSelect } from '../../components/common/CustomReactSelect';
import Breadcrumbs from '../../common/Breadcrumbs';
import { useContext, useState, useMemo, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { getErrorResponsePayload, getErrors } from '../../utils/ajax';
import CustomToast from '../../common/CustomToast';
import ReadersContext from '../../store/client/ReadersContext';
import RedirectionContext from '../../context/RedirectionContext';
import { HttpContext } from '../../context/HttpContext';
import { MeContext } from '../../context/MeContext';

const ReadersCreatePageForm = ({ isEdit }) => {
  const { authAxios } = useContext(HttpContext);
  const redirectCtx = useContext(RedirectionContext);
  const readersCtx = useContext(ReadersContext);
  const toast = useToast();
  const navigate = useNavigate();
  // initial disabled state is not used but it is necessary to be declared as initial state
  // eslint-disable-next-line no-unused-vars
  const [disabled, setDisabled] = useState();
  const [readerType, setReaderType] = useState();
  const [changePassword, setChangePassword] = useState(false);
  const modeFormBoxBg = useColorModeValue('gradient', 'secondaryDark');
  const modeBorderColor = useColorModeValue('primaryBackground', 'primaryDark');
  const modeFormControlBg = useColorModeValue('white', '');
  const modeLabelBg = useColorModeValue('#EFEFEF', 'primaryDark03');
  const modeHelperText = useColorModeValue('secondaryDark08', '#DDDDDD');
  const modeNoteText = useColorModeValue('secondaryDark06', '#DDDDDD');
  const modeLabelColor = useColorModeValue('secondaryDark', 'white');
  const meCtx = useContext(MeContext);
  const { filterEntity } = meCtx.state;

  const breadcrumbLinks = [
    {
      linkName: 'Home',
      linkUrl: '/',
    },
    {
      linkName: 'Pass readers',
      linkUrl: '/readers',
    },
    {
      linkName: isEdit ? readersCtx.readerState.name : 'Add reader',
      linkUrl: null,
    },
  ];

  const preloadedValues = {
    name: readersCtx.readerState.name,
    uuid: readersCtx.readerState.uuid,
    type: readersCtx.readerState.type,
    qrEnabled: readersCtx.readerState.qrEnabled,
    mifareClassicNfcEnabled: readersCtx.readerState.mifareClassicNfcEnabled,
    ndefTagScanningEnabled: readersCtx.readerState.ndefTagScanningEnabled,
    scanEnabled: readersCtx.readerState.scanEnabled,
    redeemEnabled: readersCtx.readerState.redeemEnabled,
    addPointsEnabled: readersCtx.readerState.addPointsEnabled,
    // disabled: readersCtx.readerState.enabled,
    // wifiSSID: readersCtx.readerState.wifiSSID,
    // wifiPassword: readersCtx.readerState.wifiPassword,
  };

  const readerTypes = [
    {
      label: 'PassEntry Reader',
      value: 'PassentryReader',
    },
    {
      label: 'VTAP Reader',
      value: 'VtapReader',
    },
  ];

  const selectedReaderType = isEdit
    ? readerTypes.find((type) => type.value === preloadedValues.type)
    : readerTypes.find((type) => type.value === readerType);

  const validationSchema = useMemo(() => {
    const schema = {
      name: Yup.string().required('Please enter reader name'),
      uuid: Yup.string().required('Please enter reader ID'),
    };

    if (selectedReaderType && selectedReaderType.value === 'PassentryReader') {
      // Extend schema for password if PassentryReader is selected
      if (isEdit ? changePassword : true) {
        schema.password = Yup.string()
          .required('Password is required')
          .min(6, 'Password must be at least 6 characters')
          .max(30, 'Password cannot be more than 30 characters')
          .matches(
            REGEX_CONTAINS_BOTH_LOWER_AND_UPPERCASE,
            'Password must contain both upper and lower case letters'
          )
          .matches(
            REGEX_HAS_AT_LEAST_ONE_NUMBER,
            'Password must contain at least one number'
          )
          .matches(REGEX_HAS_SYMBOL, 'Password must contain a symbol');
      }

      // Add a custom validation test to ensure at least one of the three checkboxes is checked
      schema.scanEnabled = Yup.boolean().test(
        'atLeastOne',
        'At least one of the following must be enabled: scanning, redeeming points, or adding points.',
        function (value) {
          const { redeemEnabled, addPointsEnabled } = this.parent;
          return value || redeemEnabled || addPointsEnabled;
        }
      );
    }

    return Yup.object().shape(schema);
  }, [selectedReaderType, changePassword, isEdit]);

  const formOptions = {
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
    defaultValues: preloadedValues,
  };

  const {
    register,
    setValue,
    watch,
    reset,
    trigger,
    handleSubmit,
    formState: { errors, isSubmitting, isValid },
  } = useForm(formOptions);

  const onSubmit = async (data) => {
    async function handleEdit() {
      try {
        const {
          name,
          uuid,
          password,
          mifareClassicNfcEnabled,
          ndefTagScanningEnabled,
          qrEnabled,
          scanEnabled,
          redeemEnabled,
          addPointsEnabled,
        } = data;

        let payload = {
          reader: {
            name,
            uuid,
            mifare_classic_nfc_enabled: mifareClassicNfcEnabled,
            ndef_tag_scanning_enabled: ndefTagScanningEnabled,
            qr_enabled: qrEnabled,
            scan_enabled: scanEnabled,
            redeem_enabled: redeemEnabled,
            add_points_enabled: addPointsEnabled,
          },
        };

        // Conditionally add password to the payload if it is not empty
        if (password && password.trim() !== '') {
          payload.reader.password = password;
        }

        const response = await authAxios.patch(
          `api/v1/readers/${preloadedValues.uuid}`,
          payload
        );

        readersCtx.updateAllData(response?.data?.data);

        toast({
          render: ({ onClose }) => (
            <CustomToast
              status="success"
              title="Reader has been edited"
              description=""
              onClose={onClose}
            />
          ),
        });
        navigate('/readers', { replace: true });
      } catch (error) {
        appsignal.sendError(error);
        const errors = getErrors(error);
        errors?.forEach((errorMessage) => {
          toast({
            render: ({ onClose }) => (
              <CustomToast
                status="error"
                title={errorMessage || 'Something went wrong'}
                description={errorMessage ? '' : 'Please try again later.'}
                onClose={onClose}
              />
            ),
          });
        });
      }
    }
    try {
      if (isEdit) {
        await handleEdit();
        return;
      }

      const {
        name,
        uuid,
        password,
        mifareClassicNfcEnabled,
        ndefTagScanningEnabled,
        qrEnabled,
        scanEnabled,
        redeemEnabled,
        addPointsEnabled,
      } = data;

      const endpoint = filterEntity
        ? `api/v1/entities/${filterEntity.uuid}/readers`
        : 'api/v1/readers';

      const response = await authAxios.post(endpoint, {
        reader: {
          name,
          uuid,
          type: readerType,
          password,
          mifare_classic_nfc_enabled: mifareClassicNfcEnabled,
          ndef_tag_scanning_enabled: ndefTagScanningEnabled,
          qr_enabled: qrEnabled,
          scan_enabled: scanEnabled,
          redeem_enabled: redeemEnabled,
          add_points_enabled: addPointsEnabled,
        },
      });

      readersCtx.updateAllData(response.data.data);

      toast({
        render: ({ onClose }) => (
          <CustomToast
            status="success"
            title="Reader has been added"
            description=""
            onClose={onClose}
          />
        ),
      });
      navigate('/readers', { replace: true });
    } catch (error) {
      appsignal.sendError(error);
      const errors = getErrors(error);

      errors?.forEach((errorMessage) => {
        toast({
          render: ({ onClose }) => (
            <CustomToast
              status="error"
              title={errorMessage || 'Something went wrong'}
              description={errorMessage ? '' : 'Please try again later.'}
              onClose={onClose}
            />
          ),
        });
      });
    }
  };

  useEffect(() => {
    reset({}, { keepValues: true });
  }, [readerType, changePassword, isEdit]);

  const redeemEnabled = watch('redeemEnabled');
  const addPointsEnabled = watch('addPointsEnabled');

  useEffect(() => {
    // This will ensure that validation logic is checked whenever any checkbox changes
    trigger('scanEnabled');
  }, [redeemEnabled, addPointsEnabled, trigger]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Container display="flex" flexDir="column" flex="1" pb={6}>
        <Breadcrumbs breadcrumbLinks={breadcrumbLinks} />
        <Flex
          flexDirection={{ base: 'column', sm: 'row' }}
          alignItems="center"
          flexWrap="wrap"
          mt="15px"
          py="9px"
          minH="54px"
        >
          <Heading alignSelf="flex-start" mr={2} wordBreak="break-word">
            {isEdit ? readersCtx.readerState.name : 'Add reader'}{' '}
            {isEdit && (
              <Text
                as="span"
                display="inline-block"
                textStyle="bodyFamilyMedium"
                fontSize="14px"
                color={modeHelperText}
                lineHeight="130%"
              >
                / uuid: {readersCtx.readerState.uuid}
              </Text>
            )}
          </Heading>
        </Flex>

        <Divider borderColor="primaryBackground" mb={4} />

        {isEdit &&
          selectedReaderType.value === 'PassentryReader' &&
          readersCtx.readerState.loggedIn && (
            <Alert status="warning" borderRadius="15px" fontSize="14px">
              <AlertIcon color="brandDark" />
              Please note: Any changes made to the settings on this page will
              result in the automatic logout of this reader.
            </Alert>
          )}

        {!isEdit &&
          selectedReaderType &&
          selectedReaderType.value === 'PassentryReader' && (
            <Alert
              status="info"
              borderRadius="15px"
              fontSize="14px"
              backgroundColor="white"
            >
              <AlertIcon color="primaryDark06" />
              <Text>
                If you need more information on how to use the PassEntry Reader
                App, please{' '}
                <Link
                  textStyle="bodyBold"
                  fontSize="14px"
                  href="mailto:devteam@passentry.com"
                  color="secondaryDark"
                >
                  contact our team
                </Link>{' '}
                to discuss your use case and receive detailed setup
                instructions.
              </Text>
            </Alert>
          )}

        <Flex
          flexDirection={{ base: 'column', sm: 'row' }}
          alignItems="center"
          flexWrap="wrap"
          minH="54px"
        >
          <Spacer />
          <SimpleGrid w="full" columns={{ base: 1 }} mt={4}>
            <Box className="autofillForDarkBg" w="full">
              <Box
                className="primaryBoxShadow"
                bg={modeFormBoxBg}
                borderRadius="15px"
                overflow="hidden"
              >
                {/* READER NAME */}
                <FormControl
                  isRequired
                  isInvalid={errors.name}
                  bg={modeFormControlBg}
                >
                  <Flex alignItems="center">
                    <Hide below="2xl">
                      <Box pl="24px" flexShrink={0} w="240px">
                        <FormLabel m={0}>Reader name</FormLabel>
                      </Box>
                    </Hide>
                    <Box p="19px" w="full" bg={modeLabelBg}>
                      <Show below="2xl">
                        <FormLabel>Reader name</FormLabel>
                      </Show>
                      <Input
                        variant="filledForDarkBg"
                        id="name"
                        type="text"
                        placeholder="Name your reader to help identify it"
                        {...register('name')}
                        autoComplete="off"
                        onBlur={() => {
                          const value = watch('name');
                          if (value.trim() === '') {
                            setValue('name', '');
                          }
                        }}
                      />
                      <FormErrorMessage mt="4px">
                        {errors.name?.message || errors.name}
                      </FormErrorMessage>
                    </Box>
                  </Flex>
                </FormControl>
                <Divider borderColor={modeBorderColor} />
                {/* READER ID */}
                <FormControl
                  isRequired
                  isInvalid={errors.uuid}
                  bg={modeFormControlBg}
                >
                  <Flex alignItems="center">
                    <Hide below="2xl">
                      <Box flexShrink={0} w="240px" pl="24px">
                        <FormLabel m={0}>Reader ID</FormLabel>
                        <Text
                          textStyle="bodyFamilyRegular"
                          fontSize="12px"
                          pt="10px"
                          w="90%"
                          color={modeNoteText}
                        >
                          Unique identifier of your Reader that will be used for
                          login.
                        </Text>
                      </Box>
                    </Hide>
                    <Box
                      p="19px"
                      pt={{ base: '19px', '2xl': '43px' }}
                      pb={{ base: '19px', '2xl': '43px' }}
                      w="full"
                      bg={modeLabelBg}
                    >
                      <Show below="2xl">
                        <Stack direction="column">
                          <FormLabel m={0}>Reader ID</FormLabel>
                          <Text
                            pb="4px"
                            textStyle="bodyFamilyRegular"
                            fontSize="12px"
                            color={modeNoteText}
                          >
                            Unique identifier of your Reader that will be used
                            for login.
                          </Text>
                        </Stack>
                      </Show>
                      {readersCtx.readerState.setupConfirmed ? (
                        <Text ml="15px">{readersCtx.readerState.uuid}</Text>
                      ) : (
                        <>
                          <Input
                            variant="filledForDarkBg"
                            id="uuid"
                            type="text"
                            placeholder="Type reader ID here..."
                            {...register('uuid')}
                            autoComplete="off"
                            onBlur={() => {
                              const value = watch('uuid');
                              if (value.trim() === '') {
                                setValue('uuid', '');
                              }
                            }}
                          />

                          <FormErrorMessage mt="4px">
                            {errors.uuid?.message || errors.uuid}
                          </FormErrorMessage>
                        </>
                      )}
                    </Box>
                  </Flex>
                </FormControl>
                <Divider borderColor={modeBorderColor} />
                {/*  Reader Type */}
                <FormControl
                  isRequired
                  isInvalid={errors.type}
                  bg={modeFormControlBg}
                >
                  <Flex alignItems="center">
                    <Hide below="2xl">
                      <Box flexShrink={0} w="240px" pl="24px">
                        <FormLabel m={0} pb="4px">
                          Reader type
                        </FormLabel>
                      </Box>
                    </Hide>
                    <Box
                      pl="19px"
                      pr="19px"
                      pt={{ base: '19px', '2xl': '30px' }}
                      pb={{ base: '19px', '2xl': '30px' }}
                      w="full"
                      bg={modeLabelBg}
                    >
                      <Show below="2xl">
                        <Stack direction="column">
                          <FormLabel m={0} pb="4px">
                            Reader type
                          </FormLabel>
                        </Stack>
                      </Show>
                      {readersCtx.readerState.setupConfirmed ? (
                        <Text ml="15px">{readersCtx.readerState.uuid}</Text>
                      ) : (
                        <CustomReactSelect
                          isDisabled={isEdit}
                          options={readerTypes}
                          menuPortalTarget={document.body}
                          value={selectedReaderType || ''}
                          variant="filledForDarkBg"
                          id="readerType"
                          type="text"
                          onChange={(e) => setReaderType(e.value)}
                          autoComplete="off"
                          passType
                        />
                      )}
                    </Box>
                  </Flex>
                </FormControl>
                <Divider borderColor={modeBorderColor} />
                {/* <Collapse
                  in={
                    (readerType === 'PassentryReader' ||
                      selectedReaderType?.value === 'PassentryReader') &&
                    !isEdit
                  }
                  animateOpacity
                >
                  <Flex alignItems="center">
                    <Box
                      pl="19px"
                      pr="19px"
                      pt={{ base: '19px', '2xl': '30px' }}
                      pb={{ base: '19px', '2xl': '30px' }}
                      w="full"
                    >
                      <Text
                        textStyle="bodyFamilyMedium"
                        fontSize="16px"
                        color={modeHelperText}
                      >
                        If you are interested in using the PassEntry Reader App,
                        please{' '}
                        <Link
                          textStyle="bodyBold"
                          href="mailto:devteam@passentry.com"
                          color="secondaryDark"
                        >
                          contact our team
                        </Link>{' '}
                        to discuss your use case and get detailed setup
                        instructions.
                      </Text>
                    </Box>
                  </Flex>
                </Collapse> */}
                {/* Scanning Settings */}
                <Collapse
                  in={selectedReaderType?.value === 'PassentryReader'}
                  animateOpacity
                >
                  <Flex alignItems="center">
                    <Hide below="2xl">
                      <Box flexShrink={0} w="240px" pl="24px">
                        <FormLabel m={0} pb="4px">
                          Scanning settings
                        </FormLabel>
                      </Box>
                    </Hide>
                    <Box
                      pl="19px"
                      pr="19px"
                      pt={{ base: '19px', '2xl': '30px' }}
                      pb={{ base: '19px', '2xl': '30px' }}
                      w="full"
                      bg={modeLabelBg}
                    >
                      <FormControl px="24px" isInvalid={!!errors.scanEnabled}>
                        <VStack align="stretch" spacing={4}>
                          <Checkbox
                            id="scanEnabled"
                            {...register('scanEnabled')}
                          >
                            <Text color={modeLabelColor}>Enable scanning</Text>
                          </Checkbox>
                          <Checkbox
                            id="redeemEnabled"
                            {...register('redeemEnabled')}
                          >
                            <Text color={modeLabelColor}>
                              Enable redeeming points
                            </Text>
                          </Checkbox>
                          <Checkbox
                            id="addPointsEnabled"
                            {...register('addPointsEnabled')}
                          >
                            <Text color={modeLabelColor}>
                              Enable adding points
                            </Text>
                          </Checkbox>
                          <FormErrorMessage mt="4px">
                            {errors.scanEnabled?.message || errors.scanEnabled}
                          </FormErrorMessage>
                        </VStack>
                      </FormControl>
                      <FormControl px="24px" pt="20px">
                        <Checkbox id="qrEnabled" {...register('qrEnabled')}>
                          <Text color={modeLabelColor}>
                            Enable QR-code scanning
                          </Text>
                        </Checkbox>
                      </FormControl>
                      {/* <FormControl px="24px" pt="20px" bg={modeLabelBg}>
                        <Checkbox
                          id="mifareClassicNfcEnabled"
                          {...register('mifareClassicNfcEnabled')}
                        >
                          <Text color={modeLabelColor}>
                            Enable Mifare Classic NFC scanning
                          </Text>
                        </Checkbox>
                      </FormControl> */}
                      <FormControl px="24px" pt="20px" bg={modeLabelBg}>
                        <Checkbox
                          id="ndefTagScanningEnabled"
                          {...register('ndefTagScanningEnabled')}
                        >
                          <Text color={modeLabelColor}>
                            Enable NDEF Tag scanning
                          </Text>
                        </Checkbox>
                      </FormControl>
                    </Box>
                  </Flex>
                </Collapse>
                <Divider borderColor={modeBorderColor} />
                {/*  Password */}
                <Collapse
                  in={readerType == 'PassentryReader' || !!changePassword}
                  animateOpacity
                >
                  <FormControl
                    isRequired={readerType === 'PassentryReader'}
                    isInvalid={errors.password}
                    bg={modeFormControlBg}
                  >
                    <Flex alignItems="center">
                      <Hide below="2xl">
                        <Box pl="24px" flexShrink={0} w="240px">
                          <FormLabel m={0}>Password</FormLabel>
                        </Box>
                      </Hide>
                      <Box p="19px" w="full" bg={modeLabelBg}>
                        <Show below="2xl">
                          <FormLabel>Password</FormLabel>
                        </Show>
                        <Input
                          variant="filledForDarkBg"
                          id="name"
                          type="text"
                          placeholder={`Type ${
                            isEdit ? 'new' : ''
                          } reader password here...`}
                          {...register('password')}
                          autoComplete="off"
                          onBlur={() => {
                            const value = watch('password');
                            if (value.trim() === '') {
                              setValue('password', '');
                            }
                          }}
                        />
                        <FormErrorMessage mt="4px">
                          {errors.password?.message || errors.password}
                        </FormErrorMessage>
                      </Box>
                    </Flex>
                  </FormControl>
                </Collapse>
                {isEdit && preloadedValues.type === 'PassentryReader' && (
                  <>
                    {changePassword && (
                      <Divider borderColor={modeBorderColor} />
                    )}
                    <Box
                      pl="19px"
                      pr="19px"
                      pt={{ base: '19px', '2xl': '30px' }}
                      pb={{ base: '19px', '2xl': '30px' }}
                      w="full"
                      bg={changePassword ? 'white' : modeLabelBg}
                    >
                      {changePassword ? (
                        <Button
                          alt="Cancel password update"
                          size="sm"
                          variant="secondary"
                          onClick={() => setChangePassword(false)}
                        >
                          Cancel password update
                        </Button>
                      ) : (
                        <Button
                          alt="Update password"
                          size="sm"
                          onClick={() => setChangePassword(true)}
                        >
                          Update password
                        </Button>
                      )}
                    </Box>
                  </>
                )}
              </Box>
            </Box>
          </SimpleGrid>

          <Box mt={{ base: '20px' }} w="full">
            <Divider borderColor="primaryBackground" mb="15px" />
            <Box display="flex">
              <Button
                type="submit"
                isLoading={isSubmitting}
                isDisabled={
                  ((!isValid || !readerType || isSubmitting) && !isEdit) ||
                  (isEdit && (!isValid || isSubmitting))
                }
                alt="Save"
                size="sm"
                mr="24px"
                mb={{ base: '10px', sm: '0px' }}
              >
                Save
              </Button>
              <Button
                size="sm"
                variant="secondary"
                as={RouterLink}
                to={{
                  pathname: redirectCtx.addReaderFromPassState
                    ? `/passes/create`
                    : redirectCtx.addReaderFromEditPassState
                      ? `/passes/edit/${redirectCtx.passParamUrl}`
                      : '/readers',
                }}
                type="button"
              >
                Cancel
              </Button>
            </Box>
          </Box>
        </Flex>
      </Container>
    </form>
  );
};

export default ReadersCreatePageForm;

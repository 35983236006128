import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  useColorModeValue,
} from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';

const Breadcrumbs = (props) => {
  const breadcrumbLinks = props.breadcrumbLinks;
  const indexLast = breadcrumbLinks.length - 1;
  const modeLastBreadcrumbColor = useColorModeValue(
    'secondaryDark08',
    'secondaryBackground'
  );

  return (
    <Breadcrumb
      fontSize="12px"
      mt={props.apiView ? '' : 8}
      mb={props.apiView ? '30px' : ''}
    >
      {breadcrumbLinks.map((breadcrumb, index) => {
        return index === indexLast ? (
          <BreadcrumbItem isCurrentPage key={index} wordBreak="break-word">
            <BreadcrumbLink
              color={modeLastBreadcrumbColor}
              _hover={{ textDecoration: 'none', cursor: 'auto' }}
            >
              {breadcrumb.linkName}
            </BreadcrumbLink>
          </BreadcrumbItem>
        ) : (
          <BreadcrumbItem key={index} wordBreak="break-word">
            <BreadcrumbLink as={RouterLink} to={`${breadcrumb.linkUrl}`}>
              {breadcrumb.linkName}
            </BreadcrumbLink>
          </BreadcrumbItem>
        );
      })}
    </Breadcrumb>
  );
};

export default Breadcrumbs;

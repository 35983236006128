import React, { useContext, useState } from 'react';
import PassTemplateContext from '../../../store/client/PassTemplateContext';
import CropModal from '../../modals/CropModal';
import CustomDropzone from '../../common/CustomDropzone';
import { useDisclosure } from '@chakra-ui/react';

function IconLogo(props) {
  const [droppedImage, setDroppedImage] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const passTemplateCtx = useContext(PassTemplateContext);
  const { androidLogo: image } = passTemplateCtx;

  function handleUpdateDroppedImage(image) {
    setDroppedImage(image);
    passTemplateCtx.updateAndroidLogo({
      ...image,
      crop: { x: 0, y: 0 },
      zoom: 1,
      aspect: 1,
    });
  }

  function handleSaveDroppedImage(base64DroppedFile) {
    passTemplateCtx.updateIconLogo(base64DroppedFile);
    passTemplateCtx.updateAndroidLogo({
      ...image,
      src: droppedImage.preview,
      mimeType: droppedImage.type,
    });
    props.setEditedImages({ ...props.editedImages, icon_image: true });
  }

  function isImageAdded() {
    onOpen();
  }

  async function updateImage(image) {
    passTemplateCtx.updateAndroidLogo(image);
  }

  return (
    <>
      <CropModal
        key="icon_logo_crop_modal"
        onClose={onClose}
        onOpen={onOpen}
        isOpen={isOpen}
        image={image}
        updateImage={updateImage}
        parent="icon_logo"
        title="Crop icon logo"
        droppedImage={droppedImage}
        saveDroppedImage={handleSaveDroppedImage}
      />
      <CustomDropzone
        key="icon_logo_drop_zone"
        isSquare
        image={image}
        isImageAdded={isImageAdded}
        parent="icon_logo"
        // explanation="Recommended size: 150x150 pixels. Recommended file type is png with transparent background. Images must be square."
        explanation="Minimum size: 660x660 pixels. Images must be square. Recommended file type is png with transparent background."
        accept={['image/jpg', 'image/jpeg', 'image/png', 'image/x-png']}
        droppedImage={droppedImage}
        updateDroppedImage={handleUpdateDroppedImage}
      />
    </>
  );
}

export default IconLogo;

import { motion } from 'framer-motion';
import { Icon } from '@chakra-ui/react';

const ThankYouIconAnimated = () => {
  return (
    <Icon viewBox="0 0 196 160" width="196px" height="160px">
      <circle cx="98" cy="80" r="80" fill="#D1D1D1" />
      <motion.path
        d="M21.6624 104C19.2828 96.4237 18 88.3616 18 80C18 71.6384 19.2828 63.5763 21.6624 56L36 56C38.7614 56 41 58.2386 41 61L41 99C41 101.761 38.7614 104 36 104H21.6624Z"
        fill="white"
        animate={{
          opacity: [0, 1],
        }}
        transition={{ duration: 1, ease: 'easeInOut' }}
      />
      <motion.path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.7071 62.2929C35.0976 62.6834 35.0976 63.3166 34.7071 63.7071L28.7071 69.7071C28.3166 70.0976 27.6834 70.0976 27.2929 69.7071L24.2929 66.7071C23.9024 66.3166 23.9024 65.6834 24.2929 65.2929C24.6834 64.9024 25.3166 64.9024 25.7071 65.2929L28 67.5858L33.2929 62.2929C33.6834 61.9024 34.3166 61.9024 34.7071 62.2929Z"
        fill="#FF8477"
        animate={{
          opacity: [0, 1],
        }}
        transition={{ duration: 1, ease: 'easeInOut' }}
      />
      <motion.path
        d="M135 99C135 101.761 132.761 104 130 104L66 104C63.2386 104 61 101.761 61 99L61 61C61 58.2386 63.2386 56 66 56L130 56C132.761 56 135 58.2386 135 61L135 99Z"
        fill="white"
        animate={{
          opacity: [0, 1],
        }}
        transition={{ duration: 1, ease: 'easeInOut' }}
      />
      <motion.path
        d="M93 76C93 74.8954 93.8954 74 95 74H127C128.105 74 129 74.8954 129 76V79C129 80.1046 128.105 81 127 81H95C93.8954 81 93 80.1046 93 79V76Z"
        fill="#3B4851"
        fillOpacity="0.6"
        animate={{
          opacity: [0, 1],
        }}
        transition={{ duration: 1, ease: 'easeInOut' }}
      />
      <motion.path
        d="M93 64C93 62.8954 93.8954 62 95 62H107C108.105 62 109 62.8954 109 64V67C109 68.1046 108.105 69 107 69H95C93.8954 69 93 68.1046 93 67V64Z"
        fill="#FF8477"
        fillOpacity="0.6"
        animate={{
          opacity: [0, 1],
        }}
        transition={{ duration: 1, ease: 'easeInOut' }}
      />
      <motion.path
        d="M67 93C67 91.8954 67.8954 91 69 91H81C82.1046 91 83 91.8954 83 93V96C83 97.1046 82.1046 98 81 98H69C67.8954 98 67 97.1046 67 96V93Z"
        fill="#3B4851"
        fillOpacity="0.2"
        animate={{
          opacity: [0, 1],
        }}
        transition={{ duration: 1, ease: 'easeInOut' }}
      />
      <motion.path
        d="M29 95C29 93.3431 30.3431 92 32 92C33.6569 92 35 93.3431 35 95C35 96.6569 33.6569 98 32 98C30.3431 98 29 96.6569 29 95Z"
        fill="#3B4851"
        fillOpacity="0.2"
        animate={{
          opacity: [0, 1],
        }}
        transition={{ duration: 1, ease: 'easeInOut' }}
      />
      <motion.path
        d="M113 93C113 91.8954 113.895 91 115 91H127C128.105 91 129 91.8954 129 93V96C129 97.1046 128.105 98 127 98H115C113.895 98 113 97.1046 113 96V93Z"
        fill="#3B4851"
        fillOpacity="0.2"
        animate={{
          opacity: [0, 1],
        }}
        transition={{ duration: 1, ease: 'easeInOut' }}
      />
      <motion.circle
        cx="76.5"
        cy="71.5"
        r="9.5"
        fill="#FF8477"
        fillOpacity="0.8"
        animate={{
          opacity: [0, 1],
        }}
        transition={{ duration: 1, ease: 'easeInOut' }}
      />
      <motion.path
        d="M54 43H67V45L54 45C52.3431 45 51 46.3431 51 48L51 61H49L49 48C49 45.2386 51.2386 43 54 43Z"
        fill="#FF8477"
        animate={{
          opacity: [0, 1],
        }}
        transition={{ delay: 1, duration: 1, ease: 'easeInOut' }}
      />
      <motion.path
        d="M147 48L147 61L145 61L145 48C145 46.3431 143.657 45 142 45L129 45L129 43L142 43C144.761 43 147 45.2386 147 48Z"
        fill="#FF8477"
        animate={{
          opacity: [0, 1],
        }}
        transition={{ delay: 1, duration: 1, ease: 'easeInOut' }}
      />
      <motion.path
        d="M142 117L129 117L129 115L142 115C143.657 115 145 113.657 145 112L145 99L147 99L147 112C147 114.761 144.761 117 142 117Z"
        fill="#FF8477"
        animate={{
          opacity: [0, 1],
        }}
        transition={{ delay: 1, duration: 1, ease: 'easeInOut' }}
      />
      <motion.path
        d="M49 112L49 99L51 99L51 112C51 113.657 52.3431 115 54 115L67 115L67 117L54 117C51.2386 117 49 114.761 49 112Z"
        fill="#FF8477"
        animate={{
          opacity: [0, 1],
        }}
        transition={{ delay: 1, duration: 1, ease: 'easeInOut' }}
      />
      <motion.path
        d="M174.338 104C176.717 96.4237 178 88.3616 178 80C178 71.6384 176.717 63.5763 174.338 56L160 56C157.239 56 155 58.2386 155 61L155 99C155 101.761 157.239 104 160 104H174.338Z"
        fill="white"
        animate={{
          opacity: [0, 1],
        }}
        transition={{ duration: 1, ease: 'easeInOut' }}
      />
      <motion.path
        d="M175.967 62H163C161.895 62 161 62.8954 161 64V67C161 68.1046 161.895 69 163 69H177.25C176.924 66.632 176.495 64.2971 175.967 62Z"
        fill="#FF8477"
        fillOpacity="0.4"
        animate={{
          opacity: [0, 1],
        }}
        transition={{ duration: 1, ease: 'easeInOut' }}
      />
      <motion.path
        d="M177.975 78H163C161.895 78 161 78.8954 161 80C161 81.1046 161.895 82 163 82H177.975C177.992 81.3353 178 80.6686 178 80C178 79.3314 177.992 78.6647 177.975 78Z"
        fill="#3B4851"
        fillOpacity="0.2"
        animate={{
          opacity: [0, 1],
        }}
        transition={{ duration: 1, ease: 'easeInOut' }}
      />
      <motion.path
        d="M177.778 86H163C161.895 86 161 86.8954 161 88C161 89.1046 161.895 90 163 90H177.381C177.546 88.6765 177.679 87.3428 177.778 86Z"
        fill="#3B4851"
        fillOpacity="0.2"
        animate={{
          opacity: [0, 1],
        }}
        transition={{ duration: 1, ease: 'easeInOut' }}
      />
      <motion.path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M176.779 94H163C161.895 94 161 94.8954 161 96C161 97.1046 161.895 98 163 98H175.967C176.271 96.6789 176.542 95.3452 176.779 94Z"
        fill="#3B4851"
        fillOpacity="0.2"
        animate={{
          opacity: [0, 1],
        }}
        transition={{ duration: 1, ease: 'easeInOut' }}
      />
      <motion.path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.64245 100.945C5.64245 101.57 6.14945 102.077 6.77486 102.077C7.40028 102.077 7.90728 101.57 7.90728 100.945L7.90728 96.9051L11.9483 96.9051C12.5737 96.9051 13.0807 96.3982 13.0807 95.773C13.0807 95.1478 12.5737 94.6409 11.9483 94.6409H7.90728L7.90728 90.6011C7.90728 89.9759 7.40028 89.469 6.77486 89.469C6.14945 89.469 5.64245 89.9759 5.64245 90.6011L5.64245 94.6409L1.60151 94.6409C0.976096 94.6409 0.469098 95.1478 0.469098 95.773C0.469098 96.3982 0.976095 96.9051 1.60151 96.9051L5.64245 96.9051L5.64245 100.945Z"
        fill="#3B4851"
        fillOpacity="0.8"
        animate={{
          opacity: [0, 1],
          x: [20, 0],
          y: [-5, 0],
          scale: [0.5, 1],
        }}
        transition={{ delay: 0.5, duration: 1, ease: 'easeInOut' }}
      />
      <motion.path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M190.325 63.5725C190.325 64.0213 190.689 64.385 191.138 64.385C191.587 64.385 191.95 64.0212 191.95 63.5725V60.6733L194.851 60.6733C195.299 60.6733 195.663 60.3096 195.663 59.8609C195.663 59.4121 195.299 59.0484 194.851 59.0484H191.95L191.95 56.149C191.95 55.7002 191.587 55.3365 191.138 55.3365C190.689 55.3365 190.325 55.7002 190.325 56.149L190.325 59.0484L187.425 59.0484C186.976 59.0484 186.612 59.4121 186.612 59.8609C186.612 60.3096 186.976 60.6733 187.425 60.6733L190.325 60.6733L190.325 63.5725Z"
        fill="#3B4851"
        fillOpacity="0.6"
        animate={{
          opacity: [0, 1],
          x: [-20, 0],
          y: [5, 0],
          scale: [0.5, 1],
        }}
        transition={{ delay: 0.5, duration: 1, ease: 'easeInOut' }}
      />
      <motion.rect
        width="11.3304"
        height="2.11009"
        rx="1.05505"
        //transform="matrix(0.707212 -0.707001 0.707212 0.707001 159 35.0104)"
        fill="#3B4851"
        fillOpacity="0.6"
        animate={{
          opacity: [0, 1],
          x: [143, 158],
          y: [34, 24.0104],
          scale: [0.5, 1],
          rotate: [135, 135],
        }}
        transition={{ delay: 0.1, duration: 1, ease: 'easeInOut' }}
      />
      {/* <motion.rect
        width="7.9164"
        height="1.68802"
        rx="0.844009"
        //transform="matrix(-0.707212 -0.707001 0.707212 -0.707001 19.2876 12.6963)"
        fill="#3B4851"
        fillOpacity="0.6"
        animate={{
          opacity: [0, 1],
          x: [20, 10],
          y: [20, 10],
          scale: [0.5, 1],
          rotate: [45, 45],
        }}
        transition={{ delay: 0.3, duration: 1, ease: 'easeInOut' }}
      /> */}

      <motion.rect
        width="11.9493"
        height="2.54795"
        rx="1.27398"
        //transform="matrix(-0.707212 -0.707001 0.707212 -0.707001 37.4507 31.2495)"
        fill="#3B4851"
        fillOpacity="0.6"
        animate={{
          opacity: [0, 1],
          x: [40, 25],
          y: [40, 25],
          scale: [0.5, 1],
          rotate: [45, 45],
        }}
        transition={{ delay: 0.3, duration: 1, ease: 'easeInOut' }}
      />
      <motion.path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.8177 48.2074C15.735 48.2074 16.4785 47.464 16.4785 46.547C16.4785 45.63 15.735 44.8866 14.8177 44.8866C13.9004 44.8866 13.1568 45.63 13.1568 46.547C13.1568 47.464 13.9004 48.2074 14.8177 48.2074ZM14.8177 50.3206C16.9024 50.3206 18.5924 48.6311 18.5924 46.547C18.5924 44.4629 16.9024 42.7734 14.8177 42.7734C12.733 42.7734 11.043 44.4629 11.043 46.547C11.043 48.6311 12.733 50.3206 14.8177 50.3206Z"
        fill="#FF8477"
        animate={{
          opacity: [0, 1],
          x: [20, 0],
          y: [5, 0],
          scale: [0.5, 1],
        }}
        transition={{ delay: 0.1, duration: 1, ease: 'easeInOut' }}
      />
      <motion.path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.5297 128.038C34.3635 128.038 35.0395 127.362 35.0395 126.528C35.0395 125.695 34.3635 125.019 33.5297 125.019C32.6958 125.019 32.0198 125.695 32.0198 126.528C32.0198 127.362 32.6958 128.038 33.5297 128.038ZM33.5297 131.056C36.0313 131.056 38.0593 129.029 38.0593 126.528C38.0593 124.027 36.0313 122 33.5297 122C31.028 122 29 124.027 29 126.528C29 129.029 31.028 131.056 33.5297 131.056Z"
        fill="#3B4851"
        fillOpacity="0.6"
        animate={{
          opacity: [0, 1],
          x: [10, 0],
          y: [-10, 0],
          scale: [0.5, 1],
        }}
        transition={{ delay: 0.1, duration: 1, ease: 'easeInOut' }}
      />
      <motion.path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M161.285 130.547C162.535 130.547 163.549 129.533 163.549 128.283C163.549 127.032 162.535 126.019 161.285 126.019C160.034 126.019 159.02 127.032 159.02 128.283C159.02 129.533 160.034 130.547 161.285 130.547ZM161.285 133.566C164.203 133.566 166.569 131.201 166.569 128.283C166.569 125.365 164.203 123 161.285 123C158.366 123 156 125.365 156 128.283C156 131.201 158.366 133.566 161.285 133.566Z"
        fill="#FF8477"
        animate={{
          opacity: [0, 1],
          x: [-10, 0],
          y: [-10, 0],
          scale: [0.5, 1],
        }}
        transition={{ delay: 0.1, duration: 1, ease: 'easeInOut' }}
      />
    </Icon>
  );
};

export default ThankYouIconAnimated;

import { motion } from 'framer-motion';
import { Icon } from '@chakra-ui/react';

const ThankYouIconAnimated = () => {
  return (
    <Icon viewBox="0 0 196 160" width="196px" height="160px">
      <ellipse cx="98.0292" cy="80" rx="80.0238" ry="80" fill="#D1D1D1" />
      <mask
        id="mask0_2_246"
        maskUnits="userSpaceOnUse"
        x="18"
        y="0"
        width="161"
        height="160"
      >
        <ellipse cx="98.0292" cy="80" rx="80.0238" ry="80" fill="#EBDDDD" />
      </mask>
      <g mask="url(#mask0_2_246)">
        <motion.path
          d="M146.346 162.547C146.346 165.309 144.107 167.547 141.346 167.547L53.2032 167.547C50.4418 167.547 48.2032 165.309 48.2032 162.547L48.2033 32.1698C48.2033 29.4084 50.4418 27.1698 53.2033 27.1698L141.346 27.1698C144.107 27.1698 146.346 29.4084 146.346 32.1698L146.346 162.547Z"
          fill="white"
          animate={{
            opacity: [0, 1],
          }}
          transition={{ duration: 1, ease: 'easeInOut' }}
        />
      </g>
      <motion.rect
        x="64.019"
        y="51.3207"
        width="48.3163"
        height="4.5283"
        rx="2.26415"
        fill="#3B4851"
        fillOpacity="0.6"
        animate={{
          opacity: [0, 1],
        }}
        transition={{ duration: 1, ease: 'easeInOut' }}
      />
      <motion.rect
        x="64.019"
        y="37.7358"
        width="34.7273"
        height="7.54717"
        rx="3.77359"
        fill="#3B4851"
        fillOpacity="0.4"
        animate={{
          opacity: [0, 1],
        }}
        transition={{ duration: 1, ease: 'easeInOut' }}
      />
      <motion.rect
        x="64.019"
        y="72.4528"
        width="67.9448"
        height="9.0566"
        rx="4.5283"
        fill="#3B4851"
        fillOpacity="0.2"
        animate={{
          opacity: [0, 1],
        }}
        transition={{ duration: 1, ease: 'easeInOut' }}
      />
      <motion.rect
        x="64.019"
        y="98.1132"
        width="67.9448"
        height="9.0566"
        rx="4.5283"
        fill="#3B4851"
        fillOpacity="0.2"
        animate={{
          opacity: [0, 1],
        }}
        transition={{ duration: 1, ease: 'easeInOut' }}
      />
      <motion.rect
        x="64.019"
        y="64.9056"
        width="19.6285"
        height="3.01887"
        rx="1.50943"
        fill="#3B4851"
        fillOpacity="0.6"
        animate={{
          opacity: [0, 1],
        }}
        transition={{ duration: 1, ease: 'easeInOut' }}
      />
      <motion.rect
        x="64.019"
        y="90.566"
        width="19.6285"
        height="3.01887"
        rx="1.50943"
        fill="#3B4851"
        fillOpacity="0.6"
        animate={{
          opacity: [0, 1],
        }}
        transition={{ duration: 1, ease: 'easeInOut' }}
      />
      <motion.rect
        x="64.019"
        y="116.226"
        width="19.6285"
        height="3.01887"
        rx="1.50943"
        fill="#FF8477"
        fillOpacity="0.6"
        animate={{
          opacity: [0, 1],
        }}
        transition={{ duration: 1, ease: 'easeInOut' }}
      />
      <motion.rect
        x="64.019"
        y="123.774"
        width="67.9448"
        height="9.0566"
        rx="4"
        stroke="#FF8477"
        strokeLinecap="square"
        strokeDasharray="3 2"
        fill="transparent"
        animate={{
          opacity: [0, 1],
        }}
        transition={{ duration: 1, ease: 'easeInOut' }}
      />
      <motion.path
        d="M81.0244 146.217C81.0244 143.299 83.3897 140.934 86.3074 140.934H110.469C113.386 140.934 115.752 143.299 115.752 146.217C115.752 149.135 113.386 151.5 110.469 151.5H86.3074C83.3897 151.5 81.0244 149.135 81.0244 146.217Z"
        fill="url(#paint0_linear_2_246)"
        animate={{
          opacity: [0, 1],
        }}
        transition={{ duration: 1, ease: 'easeInOut' }}
      />
      <motion.rect
        x="152.972"
        y="34.717"
        width="21.1384"
        height="3.01887"
        rx="1.50943"
        fill="#FF8477"
        animate={{
          opacity: [0, 1],
        }}
        transition={{ delay: 1, duration: 1, ease: 'easeInOut' }}
      />
      <motion.rect
        x="139.383"
        y="24.1509"
        width="21.1321"
        height="3.01887"
        rx="1.50943"
        transform="rotate(-90 139.383 24.1509)"
        fill="#FF8477"
        animate={{
          opacity: [0, 1],
        }}
        transition={{ delay: 1, duration: 1, ease: 'easeInOut' }}
      />
      <motion.path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.64245 86.9421C5.64245 87.5673 6.14945 88.0742 6.77486 88.0742C7.40028 88.0742 7.90728 87.5673 7.90728 86.9421L7.90728 82.9024L11.9483 82.9024C12.5737 82.9024 13.0807 82.3955 13.0807 81.7703C13.0807 81.1451 12.5737 80.6382 11.9483 80.6382H7.90728L7.90728 76.5984C7.90728 75.9732 7.40028 75.4663 6.77486 75.4663C6.14945 75.4663 5.64245 75.9732 5.64245 76.5984L5.64245 80.6382L1.60151 80.6382C0.976096 80.6382 0.469098 81.1451 0.469098 81.7703C0.469098 82.3955 0.976095 82.9024 1.60151 82.9024L5.64245 82.9024L5.64245 86.9421Z"
        fill="#3B4851"
        fillOpacity="0.8"
        animate={{
          opacity: [0, 1],
          x: [20, 0],
          scale: [0.5, 1],
        }}
        transition={{ delay: 0.5, duration: 1, ease: 'easeInOut' }}
      />
      <motion.path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M190.325 79.6016C190.325 80.0504 190.689 80.4141 191.138 80.4141C191.587 80.4141 191.95 80.0504 191.95 79.6016V76.7025L194.851 76.7025C195.299 76.7025 195.663 76.3387 195.663 75.89C195.663 75.4413 195.299 75.0775 194.851 75.0775H191.95L191.95 72.1781C191.95 71.7294 191.587 71.3656 191.138 71.3656C190.689 71.3656 190.325 71.7294 190.325 72.1781L190.325 75.0775L187.425 75.0775C186.976 75.0775 186.612 75.4413 186.612 75.89C186.612 76.3387 186.976 76.7025 187.425 76.7025L190.325 76.7025L190.325 79.6016Z"
        fill="#3B4851"
        fillOpacity="0.6"
        animate={{
          opacity: [0, 1],
          x: [-30, 0],
          scale: [0.5, 1],
        }}
        transition={{ delay: 0.5, duration: 1, ease: 'easeInOut' }}
      />
      <motion.rect
        width="11.3304"
        height="2.11009"
        rx="1.05505"
        //transform="matrix(0.707212 -0.707001 0.707212 0.707001 171.091 59.3315)"
        fill="#3B4851"
        fillOpacity="0.6"
        animate={{
          opacity: [0, 1],
          x: [140, 171.091],
          y: [64, 59.3315],
          scale: [0.5, 1],
          rotate: [-45, -45],
        }}
        transition={{ delay: 0.3, duration: 1, ease: 'easeInOut' }}
      />
      <motion.rect
        width="11.9493"
        height="2.54795"
        rx="1.27398"
        transform="matrix(-0.707212 -0.707001 0.707212 -0.707001 30.0625 22.325)"
        fill="#3B4851"
        fillOpacity="0.6"
        animate={{
          opacity: [0, 1],
          x: [40, 20],
          y: [40, 20],
          scale: [0.5, 1],
          rotate: [45, 45],
        }}
        transition={{ delay: 0.5, duration: 1, ease: 'easeInOut' }}
      />
      <motion.rect
        width="20.8333"
        height="3.47222"
        rx="1.73611"
        transform="matrix(0.707212 -0.707001 0.707212 0.707001 149.801 24.6915)"
        fill="#FF8477"
        animate={{
          opacity: [0, 1],
        }}
        transition={{ delay: 1, duration: 1, ease: 'easeInOut' }}
      />
      <motion.path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.8177 49.2075C15.735 49.2075 16.4785 48.4641 16.4785 47.5471C16.4785 46.6301 15.735 45.8868 14.8177 45.8868C13.9004 45.8868 13.1568 46.6301 13.1568 47.5471C13.1568 48.4641 13.9004 49.2075 14.8177 49.2075ZM14.8177 51.3207C16.9024 51.3207 18.5924 49.6312 18.5924 47.5471C18.5924 45.4631 16.9024 43.7736 14.8177 43.7736C12.733 43.7736 11.043 45.4631 11.043 47.5471C11.043 49.6312 12.733 51.3207 14.8177 51.3207Z"
        fill="#FF8477"
        animate={{
          opacity: [0, 1],
          x: [20, 0],
          y: [20, 0],
          scale: [0.5, 1],
        }}
        transition={{ delay: 0.1, duration: 1, ease: 'easeInOut' }}
      />
      <motion.path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.1415 114.717C26.9754 114.717 27.6514 114.041 27.6514 113.208C27.6514 112.374 26.9754 111.698 26.1415 111.698C25.3076 111.698 24.6316 112.374 24.6316 113.208C24.6316 114.041 25.3076 114.717 26.1415 114.717ZM26.1415 117.736C28.6431 117.736 30.6711 115.708 30.6711 113.208C30.6711 110.707 28.6431 108.679 26.1415 108.679C23.6398 108.679 21.6118 110.707 21.6118 113.208C21.6118 115.708 23.6398 117.736 26.1415 117.736Z"
        fill="#3B4851"
        fillOpacity="0.6"
        animate={{
          opacity: [0, 1],
          x: [10, 0],
          y: [-10, 0],
          scale: [0.5, 1],
        }}
        transition={{ delay: 0.5, duration: 1, ease: 'easeInOut' }}
      />
      <motion.path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M174.866 129.811C176.116 129.811 177.13 128.798 177.13 127.547C177.13 126.297 176.116 125.283 174.866 125.283C173.615 125.283 172.601 126.297 172.601 127.547C172.601 128.798 173.615 129.811 174.866 129.811ZM174.866 132.83C177.784 132.83 180.15 130.465 180.15 127.547C180.15 124.629 177.784 122.264 174.866 122.264C171.947 122.264 169.581 124.629 169.581 127.547C169.581 130.465 171.947 132.83 174.866 132.83Z"
        fill="#FF8477"
        animate={{
          opacity: [0, 1],
          x: [-20, 0],
          y: [-20, 0],
          scale: [0.5, 1],
        }}
        transition={{ delay: 0.1, duration: 1, ease: 'easeInOut' }}
      />
      <defs>
        <linearGradient
          id="paint0_linear_2_246"
          x1="81.9206"
          y1="151.5"
          x2="106.976"
          y2="139.371"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF8477" />
          <stop offset="1" stopColor="#EC6759" />
        </linearGradient>
      </defs>
    </Icon>
  );
};

export default ThankYouIconAnimated;

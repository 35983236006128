import { PassTemplateContextProvider } from '../../store/client/PassTemplateContext';
import PassTemplateCreatePageForm from './PassTemplateCreatePageForm';
import UsePassTemplate from '../../hooks/usePassTemplate';

function PassTemplateEditPage() {
  return (
    <PassTemplateContextProvider>
      <UsePassTemplate>
        <PassTemplateCreatePageForm isEdit />
      </UsePassTemplate>
    </PassTemplateContextProvider>
  );
}

export default PassTemplateEditPage;

import { Icon } from '@chakra-ui/react';

export const CustomArrowRightIcon = (props) => (
  <Icon viewBox="0 0 29 27" {...props}>
    <path
      fill="currentColor"
      d="M13.6742 0.549175C14.4064 -0.183058 15.5936 -0.183058 16.3258 0.549175L27.5758 11.7992C28.3081 12.5314 28.3081 13.7186 27.5758 14.4508L16.3258 25.7008C15.5936 26.4331 14.4064 26.4331 13.6742 25.7008C12.9419 24.9686 12.9419 23.7814 13.6742 23.0492L23.5983 13.125L13.6742 3.20082C12.9419 2.46859 12.9419 1.28141 13.6742 0.549175Z"
    />
    <path
      fill="currentColor"
      d="M0 13.1259C0 12.0903 0.839466 11.2509 1.875 11.2509H24.6875C25.723 11.2509 26.5625 12.0903 26.5625 13.1259C26.5625 14.1614 25.723 15.0009 24.6875 15.0009H1.875C0.839466 15.0009 0 14.1614 0 13.1259Z"
    />
  </Icon>
);

import { Icon } from '@chakra-ui/react';

export const CustomSunMultiIcon = (props) => (
  <Icon viewBox="0 0 58 58" {...props}>
    <path
      className="multicolorElToDark darkBgElToWhite"
      d="M29.0001 12.8442C20.0773 12.8442 12.8443 20.0772 12.8443 29C12.8443 37.9228 20.0773 45.1558 29.0001 45.1558C37.923 45.1558 45.1559 37.9228 45.1559 29C45.1559 20.0772 37.923 12.8442 29.0001 12.8442ZM29.0001 41.376C22.1649 41.376 16.6242 35.8352 16.6242 29C16.6242 22.1648 22.1649 16.624 29.0001 16.624C35.8354 16.624 41.3761 22.1648 41.3761 29C41.3761 35.8352 35.8354 41.376 29.0001 41.376Z"
      fill="#FF8477"
      stroke="#FF8477"
      strokeWidth="0.2"
    />
    <path
      className="multicolorElToDark darkBgElToWhite"
      d="M2.2582 26.7418H7.60656C8.85322 26.7418 9.86475 27.7533 9.86475 29C9.86475 30.2467 8.85322 31.2582 7.60656 31.2582H2.2582C1.01154 31.2582 0 30.2467 0 29C0 27.7533 1.01154 26.7418 2.2582 26.7418Z"
      fill="#FF8477"
    />
    <path
      className="multicolorElToDark darkBgElToWhite"
      d="M31.2582 7.60656C31.2582 8.85322 30.2467 9.86475 29 9.86475C27.7533 9.86475 26.7418 8.85322 26.7418 7.60656V2.2582C26.7418 1.01154 27.7533 0 29 0C30.2467 0 31.2582 1.01154 31.2582 2.2582V7.60656Z"
      fill="#FF8477"
    />
    <path
      className="multicolorElToDark darkBgElToWhite"
      d="M26.7418 50.3934C26.7418 49.1468 27.7533 48.1352 29 48.1352C30.2467 48.1352 31.2582 49.1468 31.2582 50.3934V55.7418C31.2582 56.9885 30.2467 58 29 58C27.7533 58 26.7418 56.9885 26.7418 55.7418V50.3934Z"
      fill="#FF8477"
    />
    <path
      className="multicolorElToDark darkBgElToWhite"
      d="M50.3934 26.7418H55.7418C56.9885 26.7418 58 27.7533 58 29C58 30.2467 56.9885 31.2582 55.7418 31.2582H50.3934C49.1468 31.2582 48.1352 30.2467 48.1352 29C48.1352 27.7533 49.1468 26.7418 50.3934 26.7418Z"
      fill="#FF8477"
    />
    <path
      className="multicolorElToWhite darkBgElToBrand"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.49329 46.3125L12.2752 42.5307C13.1567 41.6491 14.5872 41.6491 15.4687 42.5307C16.3503 43.4122 16.3502 44.8427 15.4687 45.7242L11.6869 49.5061C10.8053 50.3876 9.37482 50.3876 8.49329 49.5061C7.61177 48.6246 7.61177 47.194 8.49329 46.3125ZM15.4687 12.2758C16.3502 13.1573 16.3503 14.5878 15.4687 15.4693C14.5872 16.3509 13.1567 16.3509 12.2752 15.4693L8.49329 11.6875C7.61177 10.806 7.61177 9.37543 8.49329 8.49391C9.37482 7.61239 10.8053 7.61239 11.6869 8.49391L15.4687 12.2758ZM42.5301 45.7242C41.6485 44.8427 41.6485 43.4122 42.53 42.5307C43.4116 41.6491 44.8421 41.6491 45.7236 42.5307L49.5055 46.3125C50.387 47.194 50.387 48.6246 49.5055 49.5061C48.624 50.3876 47.1934 50.3876 46.3119 49.5061L42.5301 45.7242ZM42.5301 12.2758L46.3119 8.49391C47.1934 7.61238 48.624 7.61238 49.5055 8.4939C50.387 9.37543 50.387 10.806 49.5055 11.6875L45.7236 15.4693C44.8421 16.3509 43.4116 16.3509 42.53 15.4693C41.6485 14.5878 41.6485 13.1573 42.5301 12.2758Z"
      fill="#3B4851"
    />
  </Icon>
);

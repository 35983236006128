import { Icon } from '@chakra-ui/react';

export const CustomDownloadIcon = (props) => (
  <Icon viewBox="0 0 34 34" {...props}>
    <path
      fill="currentColor"
      d="M17 30.4524C24.4296 30.4524 30.4524 24.4296 30.4524 17C30.4524 9.57037 24.4296 3.54749 17 3.54749C9.57037 3.54749 3.54749 9.57036 3.54749 17C3.54749 24.4296 9.57036 30.4524 17 30.4524ZM33.665 17C33.665 26.2038 26.2038 33.665 17 33.665C7.79613 33.665 0.33496 26.2038 0.334961 17C0.334962 7.79613 7.79613 0.33496 17 0.334961C26.2038 0.334962 33.665 7.79614 33.665 17Z"
    />
    <path
      fill="currentColor"
      d="M19.2684 25.0008C18.6519 25.6173 17.847 25.9305 17.039 25.9403C17.026 25.9406 17.0129 25.9407 16.9998 25.9407C16.9867 25.9407 16.9606 25.9403 16.9606 25.9403C16.1527 25.9305 15.3477 25.6173 14.7313 25.0008L9.4373 19.7069C8.81224 19.0818 8.81224 18.0684 9.4373 17.4434C10.0623 16.8183 11.0758 16.8183 11.7008 17.4434L15.3993 21.1418V18.5838L15.3993 18.5796L15.3993 18.5753L15.3993 9.46505C15.3993 8.58109 16.1159 7.8645 16.9998 7.8645C17.8838 7.8645 18.6004 8.58109 18.6004 9.46505V18.5753L18.6004 18.5796L18.6004 18.5838V21.1418L22.2988 17.4434C22.9239 16.8183 23.9373 16.8183 24.5624 17.4434C25.1874 18.0684 25.1874 19.0818 24.5624 19.7069L19.2684 25.0008Z"
    />
  </Icon>
);

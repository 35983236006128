import appsignal from '../../../appsignal';
import { useRef, useState, useContext, useEffect } from 'react';
import {
  Box,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Button,
  HStack,
  Text,
  Spacer,
  PopoverBody,
  RadioGroup,
  Radio,
  VStack,
  Stack,
  Collapse,
  Flex,
  FormControl,
  Input,
  FormErrorMessage,
  useDisclosure,
  useOutsideClick,
  useColorModeValue,
  useColorMode,
  useMediaQuery,
  Skeleton,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { CustomChevronLeftIcon } from '../../../theme/icons/CustomChevronLeftIcon';
import CustomDashboardDatePicker from './CustomDashboardDatePicker/CustomDashboardDatePicker';
import CustomDashboardTimePicker from './CustomDashboardTimePicker';
import { ClientDashboardStatsContext } from '../../../store/client/ClientDashboardStatsContext';
import moment from 'moment';
import { MeContext } from '../../../context/MeContext';

const DashboardTimeFilter = ({ loadingDashHeader }) => {
  const { userDashboardTimeFilter } = useContext(MeContext);
  const userTimeFilterArray = userDashboardTimeFilter();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [transformPopoverArrow, setTransformPopoverArrow] = useState(false);
  const { updateFilterTime, filterTime } = useContext(
    ClientDashboardStatsContext
  );
  // eslint-disable-next-line no-unused-vars
  const [timeOption, setTimeOption] = useState('All time');
  const [fromDate, setFromDate] = useState(null);
  const [fromTime, setFromTime] = useState('');
  const [toDate, setToDate] = useState(null);
  const [toTime, setToTime] = useState('');
  const [isDisabled, setIsDisabled] = useState(true);

  const transformPopoverArrowRef = useRef();
  const modeColorText = useColorModeValue('secondaryDark', '#FFFFFF');
  const modeColorTextHover = useColorModeValue('black', 'white');
  const modeBgColor = useColorModeValue('white', 'secondaryDark');
  const borderButtonColorActive = isOpen
    ? '2px solid #29323A'
    : '2px solid transparent';
  const modeDateTimeLabelMode = useColorModeValue('primaryDark', '#FFFFFF');
  const { colorMode } = useColorMode();
  const modeSkeletonStartColor = useColorModeValue('#d8d8d8', '#29323A');
  const modeSkeletonEndColor = useColorModeValue('#fff', '#515d66');
  const [isLargerThanSM] = useMediaQuery('(min-width: 30em)');
  const [isLargerThanLG] = useMediaQuery('(min-width: 62em)');

  const {
    handleSubmit,
    register,
    setError,
    formState: { errors },
  } = useForm();

  const prepareDatesForSubmit = (fDate, fTime, tDate, tTime) => {
    if (!fTime) {
      fTime = '12:00 AM';
    }

    if (!tTime) {
      tTime = '11:59 PM';
    }

    const fromDate = moment(fDate).format('YYYY-MM-DD');
    const fromTime = moment(fTime, ['hh:mm A']);

    const fromTimeHours = moment(fromTime).get('hours');
    const fromTimeMinutes = moment(fromTime).get('minutes');

    const toDate = moment(tDate).format('YYYY-MM-DD');
    const toTime = moment(tTime, ['hh:mm A']);

    const toTimeHours = moment(toTime).get('hours');
    const toTimeMinutes = moment(toTime).get('minutes');

    const labelFromDate = moment(fDate).format('DD/MM/YYYY');
    const labelToDate = moment(tDate).format('DD/MM/YYYY');

    const totalFromDate = moment(fromDate)
      .add(fromTimeHours, 'hours')
      .add(fromTimeMinutes, 'minutes');

    const totalToDate = moment(toDate)
      .add(toTimeHours, 'hours')
      .add(toTimeMinutes, 'minutes');

    if (totalToDate.isBefore(totalFromDate)) {
      setError(
        'fromDate',
        { message: 'Start time must be before end time.' },
        { shouldFocus: true }
      );
      setError(
        'fromTime',
        { message: 'Start time must be before end time.' },
        { shouldFocus: true }
      );
      setError(
        'toDate',
        { message: 'Start time must be before end time.' },
        { shouldFocus: true }
      );
      setError(
        'toTime',
        { message: 'Start time must be before end time.' },
        { shouldFocus: true }
      );
      // throw new Error('Validation Error');
      console.log('error');
    }

    return {
      totalFromDate: totalFromDate.format(),
      totalToDate: totalToDate.format(),
      fromDate: fDate,
      fromTime: fTime,
      toDate: tDate,
      toTime: tTime,
      labelFromDate,
      labelToDate,
    };
  };

  const onSubmit = () => {
    try {
      const data = prepareDatesForSubmit(fromDate, fromTime, toDate, toTime);
      updateFilterTime({
        name: 'Custom',
        data: { ...data },
        prevSelection: 'Custom',
      });
      onClose();
    } catch (error) {
      appsignal.sendError(error);
      console.log(error);
    }
  };

  useOutsideClick({
    ref: transformPopoverArrowRef,
    handler: () => {
      setTransformPopoverArrow(false);
    },
  });

  const btnHandler = () => {
    if (isOpen) {
      setTransformPopoverArrow(false);
    } else {
      setTransformPopoverArrow(true);
    }
  };

  const handleOnChange = (timeOptionName) => {
    setTimeOption(timeOptionName);

    if (timeOptionName !== 'Custom') {
      onClose();
    }

    updateFilterTime((prevState) => {
      return {
        name: timeOptionName,
        data: {},
        prevSelection: prevState.name,
      };
    });

    if (timeOptionName === 'Custom') {
      setTransformPopoverArrow(true);
      return;
    }
  };

  useEffect(() => {
    if (fromDate && toDate) {
      setIsDisabled(false);
    }

    return () => {
      setTimeOption(filterTime.name);
    };
  }, [fromDate, toDate]);

  const renderTimeQueryString = (time) => {
    switch (time) {
      case '7days':
        return 'Last 7 days';
      case '30days':
        return 'Last 30 days';
      case 'lastQuarter':
        return 'Last quarter';
      case 'lastYear':
        return 'Last year';
      default:
        return 'All time';
    }
  };

  // remember filtered time
  useEffect(() => {
    if (
      userTimeFilterArray &&
      userTimeFilterArray?.length !== 0 &&
      userTimeFilterArray?.name !== 'custom'
    ) {
      setTimeOption(renderTimeQueryString(userTimeFilterArray?.name));
      updateFilterTime((prevState) => {
        return {
          name: renderTimeQueryString(userTimeFilterArray?.name),
          data: {},
          prevSelection: prevState.name,
        };
      });
      return;
    }
    // in case the custom time filter is selected
    if (
      userTimeFilterArray &&
      userTimeFilterArray?.length !== 0 &&
      userTimeFilterArray?.name === 'custom'
    ) {
      const fromDateRes = moment(userTimeFilterArray?.from).toDate();
      const toDateRes = moment(userTimeFilterArray?.to).toDate();
      const fromTimeRes = moment(userTimeFilterArray?.from).format('hh:mm A');
      const toTimeRes = moment(userTimeFilterArray?.to).format('hh:mm A');
      const data = prepareDatesForSubmit(
        fromDateRes,
        fromTimeRes,
        toDateRes,
        toTimeRes
      );
      updateFilterTime({
        name: 'Custom',
        data: {
          ...data,
        },
        prevSelection: 'Custom',
      });
    }
  }, [userTimeFilterArray]);

  if (loadingDashHeader) {
    return (
      <Skeleton
        width="200px"
        borderRadius="15px"
        startColor={modeSkeletonStartColor}
        endColor={modeSkeletonEndColor}
      />
    );
  }

  return (
    <Box minWidth="200px">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Popover
          isOpen={isOpen}
          onOpen={onOpen}
          onClose={onClose}
          bg={modeBgColor}
          placement={isLargerThanSM ? 'bottom' : 'bottom-start'}
        >
          <PopoverTrigger>
            <Button
              variant="dashTime"
              onClick={btnHandler}
              w="full"
              bg={modeBgColor}
              border={borderButtonColorActive}
              ref={transformPopoverArrowRef}
              height="36px"
            >
              <HStack w="full">
                {!filterTime.data?.labelFromDate ? (
                  <Text
                    fontSize="14px"
                    top="1px"
                    pos="relative"
                    color={modeColorText}
                  >
                    {filterTime.name}
                  </Text>
                ) : (
                  <Text
                    fontSize="14px"
                    top="1px"
                    pos="relative"
                    color={modeColorText}
                  >
                    {`${filterTime.data.labelFromDate} `}
                    <Text
                      as="span"
                      fontWeight="bold"
                    >{`${filterTime.data.fromTime} - `}</Text>
                    {`${filterTime.data.labelToDate} `}
                    <Text as="span" fontWeight="bold">
                      {filterTime.data.toTime}
                    </Text>
                  </Text>
                )}

                <Spacer />
                <Box pl={3}>
                  <CustomChevronLeftIcon
                    transform={
                      transformPopoverArrow ? 'rotate(90deg)' : 'rotate(-90deg)'
                    }
                    alignSelf="flex-end"
                    color={transformPopoverArrow ? modeColorTextHover : null}
                  />
                </Box>
              </HStack>
            </Button>
          </PopoverTrigger>
          <PopoverContent
            width={filterTime.name === 'Custom' ? 'auto' : '200px'}
            minW="200px"
            borderRadius="6px"
            mr={
              filterTime.name === 'Custom'
                ? isLargerThanLG
                  ? '2.5rem'
                  : '1rem'
                : ''
            }
            bg={modeBgColor}
          >
            <PopoverBody p="24px">
              {/* ubaci isLargerThanSM */}
              <Stack direction={isLargerThanSM ? 'row' : 'column'}>
                <RadioGroup
                  value={filterTime.name}
                  onChange={handleOnChange}
                  w="135px"
                  borderRight={
                    filterTime.name === 'Custom' && isLargerThanSM
                      ? '1px solid #DDDDDD'
                      : ''
                  }
                  flexShrink="0"
                  mr={filterTime.name === 'Custom' ? '12px' : '0px'}
                  //color="red"
                >
                  <VStack alignItems="left">
                    <Radio value="All time">All time</Radio>
                    <Radio value="Last 7 days">Last 7 days</Radio>
                    <Radio value="Last 30 days">Last 30 days</Radio>
                    <Radio value="Last quarter">Last quarter</Radio>
                    <Radio value="Last year">Last year</Radio>
                    <Radio value="Custom">Custom</Radio>
                  </VStack>
                </RadioGroup>
                <Collapse in={filterTime.name === 'Custom'} animateOpacity>
                  <Box w={{ base: 'auto', lg: '312px' }}>
                    {/* from date/time */}
                    <VStack alignItems="left" mt="15px">
                      <Text
                        fontSize="14px"
                        fontWeight="600"
                        color={modeDateTimeLabelMode}
                        letterSpacing="2px"
                      >
                        From
                      </Text>
                      <Flex
                        flexDir={{ base: 'column', md: 'row' }}
                        alignItems="center"
                        w="full"
                        mt="0px"
                        gap="12px"
                      >
                        <Box w="full">
                          <FormControl isRequired isInvalid={errors.fromDate}>
                            <Box w="full">
                              <Box w={{ base: '100%', sm: '75%', md: '150px' }}>
                                {/* from date picker */}
                                <Input
                                  id="fromDate"
                                  name="fromDate"
                                  type="hidden"
                                  {...register('fromDate')}
                                />
                                {colorMode === 'dark' ? (
                                  <CustomDashboardDatePicker
                                    setDate={setFromDate}
                                    fromDate
                                    darkMode
                                  />
                                ) : (
                                  <CustomDashboardDatePicker
                                    setDate={setFromDate}
                                    fromDate
                                    dashboard
                                  />
                                )}
                              </Box>
                              <FormErrorMessage>
                                {errors.fromDate?.message || errors.fromDate}
                              </FormErrorMessage>
                            </Box>
                          </FormControl>
                        </Box>

                        <Box w="full">
                          <FormControl isRequired isInvalid={errors.fromTime}>
                            <Box w="full">
                              <Box w={{ base: '100%', sm: '75%', md: '150px' }}>
                                {/* from time picker */}
                                <Input
                                  id="fromTime"
                                  name="fromTime"
                                  type="hidden"
                                  {...register('fromTime')}
                                />
                                {colorMode === 'dark' ? (
                                  <CustomDashboardTimePicker
                                    timeValue={fromTime}
                                    setTimeValue={setFromTime}
                                    darkMode
                                    fromTime
                                  />
                                ) : (
                                  <CustomDashboardTimePicker
                                    timeValue={fromTime}
                                    setTimeValue={setFromTime}
                                    fromTime
                                  />
                                )}
                              </Box>
                              <FormErrorMessage>
                                {errors.fromTime?.message || errors.fromTime}
                              </FormErrorMessage>
                            </Box>
                          </FormControl>
                        </Box>
                      </Flex>
                    </VStack>

                    {/* to Date/time */}
                    <VStack alignItems="left" mt="15px">
                      <Text
                        fontSize="14px"
                        fontWeight="600"
                        color={modeDateTimeLabelMode}
                        letterSpacing="2px"
                      >
                        To
                      </Text>
                      <Flex
                        flexDir={{ base: 'column', md: 'row' }}
                        alignItems="center"
                        w="full"
                        mt="0px"
                        gap="12px"
                      >
                        <Box w="full">
                          <FormControl isRequired isInvalid={errors.fromDate}>
                            <Box w="full">
                              <Box w={{ base: '100%', sm: '75%', md: '150px' }}>
                                {/* from date picker */}
                                <Input
                                  id="toDate"
                                  name="toDate"
                                  type="hidden"
                                  {...register('toDate')}
                                />

                                {colorMode === 'dark' ? (
                                  <Box
                                    as={CustomDashboardDatePicker}
                                    setDate={setToDate}
                                    darkMode
                                    toDate
                                  />
                                ) : (
                                  <Box
                                    as={CustomDashboardDatePicker}
                                    placementTop
                                    setDate={setToDate}
                                    toDate
                                  />
                                )}
                              </Box>
                              <FormErrorMessage>
                                {errors.toDate?.message || errors.toDate}
                              </FormErrorMessage>
                            </Box>
                          </FormControl>
                        </Box>

                        <Box w="full">
                          <FormControl isRequired isInvalid={errors.fromTime}>
                            <Box w="full">
                              <Box w={{ base: '100%', sm: '75%', md: '150px' }}>
                                {/* to time picker */}
                                <Input
                                  id="toTime"
                                  name="toTime"
                                  type="hidden"
                                  {...register('toTime')}
                                />
                                {colorMode === 'dark' ? (
                                  <CustomDashboardTimePicker
                                    timeValue={toTime}
                                    setTimeValue={setToTime}
                                    darkMode
                                    toTime
                                  />
                                ) : (
                                  <CustomDashboardTimePicker
                                    timeValue={toTime}
                                    setTimeValue={setToTime}
                                    toTime
                                  />
                                )}
                              </Box>
                              <FormErrorMessage>
                                {errors.toTime?.message || errors.toTime}
                              </FormErrorMessage>
                            </Box>
                          </FormControl>
                        </Box>
                      </Flex>
                    </VStack>

                    <Box mt={{ base: '36px' }} w="full">
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent={{ base: 'left', md: 'right' }}
                      >
                        <Button
                          size="sm"
                          variant="white"
                          onClick={() => {
                            if (filterTime.prevSelection !== 'Custom') {
                              updateFilterTime((prevState) => {
                                return {
                                  ...prevState,
                                  name: prevState.prevSelection,
                                };
                              });
                            }
                            onClose();
                          }}
                          type="button"
                          bg={colorMode === 'dark' ? 'primaryDark' : '#DDDDDD'}
                          _hover={{
                            backgroundColor:
                              colorMode === 'dark'
                                ? 'primaryDark06'
                                : 'primaryDark03',
                          }}
                          mr="10px"
                        >
                          Cancel
                        </Button>
                        <Button
                          type="submit"
                          disabled={isDisabled}
                          alt="Apply"
                          size="sm"
                        >
                          Apply
                        </Button>
                      </Box>
                    </Box>
                  </Box>
                </Collapse>
              </Stack>
            </PopoverBody>
          </PopoverContent>
        </Popover>
      </form>
    </Box>
  );
};

export default DashboardTimeFilter;

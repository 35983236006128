import { useEffect, useState } from 'react';
import {
  Box,
  HStack,
  Text,
  Tooltip,
  useClipboard,
  useColorModeValue,
  useToast,
} from '@chakra-ui/react';
import { CustomLockIcon } from '../../../theme/multicolor-icons/CustomLockIcon';
import { CustomPasswordDotsIcon } from '../../../theme/icons/CustomPasswordDotsIcon';
import CustomToast from '../../../common/CustomToast';

const KeyHolder = ({ id, secretKey, visibleKeyUUID, isKeyVisible }) => {
  const modeIcon = useColorModeValue('secondaryDark', '#fff');
  const [isVisible, setIsVisible] = useState(false);
  const { onCopy, hasCopied } = useClipboard(secretKey);
  const toast = useToast();
  const bgTooltip = useColorModeValue('secondaryDark', '#dddddd');
  const textTooltip = useColorModeValue('#ffffff', 'primaryDark');

  const onCopyHandler = () => {
    onCopy();
  };

  useEffect(() => {
    if (hasCopied) {
      toast({
        render: (props) => (
          <CustomToast
            status="success"
            title={'Copied to clipboard'}
            description=""
            onClose={props.onClose}
          />
        ),
      });
    }
  }, [hasCopied]);

  useEffect(() => {
    if (visibleKeyUUID !== id) {
      setIsVisible(false);
      return;
    }

    if (visibleKeyUUID === id && isKeyVisible) {
      setIsVisible(true);
    }

    if (visibleKeyUUID === id && !isKeyVisible) {
      setIsVisible(false);
    }
  }, [visibleKeyUUID, isKeyVisible]);

  return (
    <HStack>
      <CustomLockIcon width="15" height="18" />
      {isVisible ? (
        <Box cursor="pointer" onClick={onCopyHandler}>
          <Tooltip
            label="Click to copy"
            isDisabled={hasCopied}
            hasArrow
            placement="right"
            fontSize="12px"
            borderRadius="6px"
            p="10px"
            bg={bgTooltip}
            color={textTooltip}
          >
            <Text
              w={{ base: '150px', sm: '100px', '2xl': '245px' }}
              pl={{ base: '0', '2xl': '14px' }}
            >
              {secretKey}
            </Text>
          </Tooltip>
        </Box>
      ) : (
        <CustomPasswordDotsIcon
          w={{
            base: id === 'secretKey' ? '255px' : '100px',
            sm: id === 'secretKey' ? '255px' : '100px',
            '2xl': '255px',
          }}
          height="8px"
          fill={modeIcon}
        />
      )}
    </HStack>
  );
};

export default KeyHolder;

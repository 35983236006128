import appsignal from '../../appsignal';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Button,
  Heading,
  Text,
  Stack,
  useToast,
  useColorModeValue,
} from '@chakra-ui/react';
import CustomToast from '../../common/CustomToast';
import { useForm } from 'react-hook-form';
import { useContext } from 'react';
import { useNavigate } from 'react-router';
import ReadersListContext from '../../store/client/ReadersListContext';
import ReadersListClientContext from '../../store/client/ReadersListContext';
import { getErrorResponsePayload } from '../../utils/ajax';
import { HttpContext } from '../../context/HttpContext';
import ReadersContext from '../../store/admin/ReadersContext';

const LogoutReaderModal = ({ onClose, isOpen, readerId }) => {
  const { authAxios } = useContext(HttpContext);
  const { updateReaderStatus } = useContext(ReadersListContext);
  const toast = useToast();
  const navigate = useNavigate();
  const modeBoxBg = useColorModeValue('white', 'secondaryDark');
  const modeTextBg = useColorModeValue('white', 'primaryDark06');
  const modeLabelBg = useColorModeValue('white', 'primaryDark03');
  const modeOffset = useColorModeValue('0', '16px !important');

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = useForm();

  const onSubmit = async () => {

    try {
      await authAxios.delete(`api/v1/readers/${readerId}/logout`);
      updateReaderStatus(readerId, false);
      toast({
        render: ({ onClose }) => (
          <CustomToast
            status="success"
            title="Reader has been logged out successfully."
            description=""
            onClose={onClose}
          />
        ),
      });
      navigate('/readers', { replace: true });
    } catch (error) {
      appsignal.sendError(error);
      // const { errors } = getErrorResponsePayload(error);
      toast({
        render: ({ onClose }) => (
          <CustomToast
            status="success"
            title="Something went wrong"
            description="Please try again later."
            onClose={onClose}
          />
        ),
      });
    }
    onClose();
  };

  return (
    <Modal onClose={onClose} isOpen={isOpen} size="xl">
      <ModalOverlay />
      <ModalContent bg={modeBoxBg}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalHeader bg={modeTextBg} pb={modeOffset} borderRadius="15px 15px 0 0">
            <Heading fontSize={{ base: '24px', lg: '32px' }} lineHeight="130%">
              Log Out Reader
            </Heading>
          </ModalHeader>
          <ModalBody bg={modeLabelBg}>
            <Text>
              Are you sure you want to log out this reader? This will end the active session, requiring re-login with ID and password.
            </Text>
          </ModalBody>
          <ModalFooter bg={modeTextBg} pt={modeOffset} borderRadius="0 0 15px 15px">
            <Stack
              spacing="10px"
              direction={{ base: 'column', sm: 'row' }}
              width={{ base: 'full', sm: 'auto' }}
            >
              <Button
                onClick={onClose}
                alt="Cancel"
                variant="secondary"
                width={{ base: 'full', sm: 'auto' }}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                alt="Delete Reader"
                width={{ base: 'full', sm: 'auto' }}
                isLoading={isSubmitting}
              >
                Log out Reader
              </Button>
            </Stack>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};
export default LogoutReaderModal;

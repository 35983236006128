import {
  Flex,
  Heading,
  Text,
  useColorModeValue,
  Spacer,
  Box,
  Button,
} from '@chakra-ui/react';
import { useContext } from 'react';
import RedirectionContext from '../../../context/RedirectionContext';
import PassContext from '../../../store/client/PassContext';
import { Link as RouterLink } from 'react-router-dom';
import { CustomEditIcon } from '../../../theme/icons/CustomEditIcon';

const PassesViewHeader = () => {
  const passCtx = useContext(PassContext);
  const redirectCtx = useContext(RedirectionContext);
  const modeHelperText = useColorModeValue('secondaryDark08', '#DDDDDD');

  return (
    <Flex
      flexDirection={{ base: 'column', sm: 'row' }}
      alignItems="center"
      flexWrap="wrap"
      mt="15px"
      py="9px"
      minH="54px"
    >
      <Heading
        alignSelf={{ base: 'flex-start', sm: 'center' }}
        wordBreak="break-word"
      >
        <>
          <Text
            as="span"
            display="inline-block"
            textStyle="bodyFamilyMedium"
            fontSize="16px"
            color={modeHelperText}
            lineHeight="130%"
          >
            / uuid: {passCtx.passState.uuid}
          </Text>{' '}
        </>
      </Heading>
      <Spacer />
      {(passCtx.passState.status === 'active' ||
        passCtx.passState.status === 'issued' ||
        passCtx.passState.status === 'pending') && (
        <Button
          alignSelf={{ base: 'flex-start', sm: 'center' }}
          as={RouterLink}
          size="sm"
          px="30px"
          to={`/passes/edit/${passCtx.passState.uuid}`}
          onClick={() => {
            redirectCtx.updateAddEventFromPassState(false);
            redirectCtx.updateAddEventFromEditPassState(false);
            redirectCtx.updatePassTemplateFromPassState(false);
            redirectCtx.updatePassTemplateFromEditPassState(false);
            redirectCtx.updateAddReaderFromPassState(false);
            redirectCtx.updateAddReaderFromEditPassState(false);
            redirectCtx.updateReaderAddedExternally(false);
          }}
        >
          <Box as="span" zIndex={1}>
            <CustomEditIcon boxSize="20px" mb="4px" color1="#fff" />
            <Text as="span" pl="8px">
              Edit pass
            </Text>
          </Box>
        </Button>
      )}
    </Flex>
  );
};
export default PassesViewHeader;

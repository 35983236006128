import { createContext, useState } from 'react';

const EventsListContext = createContext({
  eventState: {
    items: [],
    total: 0,
    pageCount: 0,
    status: 'idle',
    loading: false,
    error: null,
    update: 0,
  },
  updateEventState: () => {},
});

export const EventsListContextProvider = ({ children }) => {
  const [eventState, setEventState] = useState({
    items: [],
    total: 0,
    pageCount: 0,
    status: 'idle',
    loading: false,
    error: null,
    update: 0,
  });

  const updateEventStateHandler = (param) => {
    setEventState(param);
  };

  const context = {
    eventState,
    updateEventState: updateEventStateHandler,
  };

  return (
    <EventsListContext.Provider value={context}>{children}</EventsListContext.Provider>
  );
};

export default EventsListContext;

export const timezoneList = () => [
  {
    key: '(GMT) London',
    name: 'Europe/London',
    offset: 0,
  },
  {
    key: '(GMT) Casablanca',
    name: 'Africa/Casablanca',
    offset: 0,
  },
  {
    key: '(GMT) Dublin',
    name: 'Europe/Dublin',
    offset: 0,
  },
  {
    key: '(GMT) Lisbon',
    name: 'Europe/Lisbon',
    offset: 0,
  },
  {
    key: '(GMT) Monrovia',
    name: 'Africa/Monrovia',
    offset: 0,
  },
  {
    key: '(GMT-11:00) Midway Island',
    name: 'Pacific/Midway',
    offset: -11,
  },
  {
    key: '(GMT-11:00) Samoa',
    name: 'Pacific/Samoa',
    offset: -11,
  },
  {
    key: '(GMT-10:00) Hawaii',
    name: 'Pacific/Honolulu',
    offset: -10,
  },
  {
    key: '(GMT-09:00) Alaska',
    name: 'America/Anchorage',
    offset: -9,
  },
  {
    key: '(GMT-08:00) Pacific Time (US & Canada)',
    name: 'America/Los_Angeles',
    offset: -8,
  },
  {
    key: '(GMT-08:00) Tijuana',
    name: 'America/Tijuana',
    offset: -8,
  },
  {
    key: '(GMT-07:00) Arizona',
    name: 'America/Phoenix',
    offset: -7,
  },
  {
    key: '(GMT-07:00) Mountain Time (US & Canada)',
    name: 'America/Denver',
    offset: -7,
  },
  {
    key: '(GMT-07:00) Chihuahua',
    name: 'America/Chihuahua',
    offset: -7,
  },
  {
    key: '(GMT-07:00) Mazatlan',
    name: 'America/Mazatlan',
    offset: -7,
  },
  {
    key: '(GMT-06:00) Mexico City',
    name: 'America/Mexico_City',
    offset: -6,
  },
  {
    key: '(GMT-06:00) Monterrey',
    name: 'America/Monterrey',
    offset: -6,
  },
  {
    key: '(GMT-06:00) Saskatchewan',
    name: 'America/Canada/Saskatchewan',
    offset: -6,
  },
  {
    key: '(GMT-06:00) Central Time (US & Canada)',
    name: 'America/Chicago',
    offset: -6,
  },
  {
    key: '(GMT-05:00) Eastern Time (US & Canada)',
    name: 'America/New_York',
    offset: -5,
  },
  {
    key: '(GMT-05:00) Indiana (East)',
    name: 'America/Indiana/Indianapolis',
    offset: -5,
  },
  {
    key: '(GMT-05:00) Bogota',
    name: 'America/Bogota',
    offset: -5,
  },
  {
    key: '(GMT-05:00) Lima',
    name: 'America/Lima',
    offset: -5,
  },
  {
    key: '(GMT-04:30) Caracas',
    name: 'America/Caracas',
    offset: -4.5,
  },
  {
    key: '(GMT-04:00) Atlantic Time (Canada)',
    name: 'Canada/Atlantic',
    offset: -4,
  },
  {
    key: '(GMT-04:00) La Paz',
    name: 'America/La_Paz',
    offset: -4,
  },
  {
    key: '(GMT-04:00) Santiago',
    name: 'America/Santiago',
    offset: -4,
  },
  {
    key: '(GMT-03:30) Newfoundland',
    name: 'Canada/Newfoundland',
    offset: -3.5,
  },
  {
    key: '(GMT-03:00) Buenos Aires',
    name: 'America/Buenos_Aires',
    offset: -3,
  },
  {
    key: '(GMT-02:00) Stanley',
    name: 'Atlantic/Stanley',
    offset: -2,
  },
  {
    key: '(GMT-01:00) Azores',
    name: 'Atlantic/Azores',
    offset: -1,
  },
  {
    key: '(GMT-01:00) Cape Verde Is.',
    name: 'Atlantic/Cape_Verde',
    offset: -1,
  },

  {
    key: '(GMT+01:00) Amsterdam',
    name: 'Europe/Amsterdam',
    offset: +1,
  },
  {
    key: '(GMT+01:00) Belgrade',
    name: 'Europe/Belgrade',
    offset: +1,
  },
  {
    key: '(GMT+01:00) Berlin',
    name: 'Europe/Berlin',
    offset: +1,
  },
  {
    key: '(GMT+01:00) Bratislava',
    name: 'Europe/Bratislava',
    offset: +1,
  },
  {
    key: '(GMT+01:00) Brussels',
    name: 'Europe/Brussels',
    offset: +1,
  },
  {
    key: '(GMT+01:00) Budapest',
    name: 'Europe/Budapest',
    offset: +1,
  },
  {
    key: '(GMT+01:00) Copenhagen',
    name: 'Europe/Copenhagen',
    offset: +1,
  },
  {
    key: '(GMT+01:00) Ljubljana',
    name: 'Europe/Ljubljana',
    offset: +1,
  },
  {
    key: '(GMT+01:00) Madrid',
    name: 'Europe/Madrid',
    offset: +1,
  },
  {
    key: '(GMT+01:00) Paris',
    name: 'Europe/Paris',
    offset: +1,
  },
  {
    key: '(GMT+01:00) Prague',
    name: 'Europe/Prague',
    offset: +1,
  },
  {
    key: '(GMT+01:00) Rome',
    name: 'Europe/Rome',
    offset: +1,
  },
  {
    key: '(GMT+01:00) Sarajevo',
    name: 'Europe/Sarajevo',
    offset: +1,
  },
  {
    key: '(GMT+01:00) Skopje',
    name: 'Europe/Skopje',
    offset: +1,
  },
  {
    key: '(GMT+01:00) Stockholm',
    name: 'Europe/Stockholm',
    offset: +1,
  },
  {
    key: '(GMT+01:00) Vienna',
    name: 'Europe/Vienna',
    offset: +1,
  },
  {
    key: '(GMT+01:00) Warsaw',
    name: 'Europe/Warsaw',
    offset: +1,
  },
  {
    key: '(GMT+01:00) Zagreb',
    name: 'Europe/Zagreb',
    offset: +1,
  },
  {
    key: '(GMT+02:00) Athens',
    name: 'Europe/Athens',
    offset: +2,
  },
  {
    key: '(GMT+02:00) Bucharest',
    name: 'Europe/Bucharest',
    offset: +2,
  },
  {
    key: '(GMT+02:00) Cairo',
    name: 'Africa/Cairo',
    offset: +2,
  },
  {
    key: '(GMT+02:00) Harare',
    name: 'Africa/Harare',
    offset: +2,
  },
  {
    key: '(GMT+02:00) Helsinki',
    name: 'Europe/Helsinki',
    offset: +2,
  },
  {
    key: '(GMT+02:00) Istanbul',
    name: 'Europe/Istanbul',
    offset: +2,
  },
  {
    key: '(GMT+02:00) Jerusalem',
    name: 'Asia/Jerusalem',
    offset: +2,
  },
  {
    key: '(GMT+02:00) Kyiv',
    name: 'Europe/Kiev',
    offset: +2,
  },
  {
    key: '(GMT+02:00) Minsk',
    name: 'Europe/Minsk',
    offset: +2,
  },
  {
    key: '(GMT+02:00) Riga',
    name: 'Europe/Riga',
    offset: +2,
  },
  {
    key: '(GMT+02:00) Sofia',
    name: 'Europe/Sofia',
    offset: +2,
  },
  {
    key: '(GMT+02:00) Tallinn',
    name: 'Europe/Tallinn',
    offset: +2,
  },
  {
    key: '(GMT+02:00) Vilnius',
    name: 'Europe/Vilnius',
    offset: +2,
  },
  {
    key: '(GMT+03:00) Baghdad',
    name: 'Asia/Baghdad',
    offset: +3,
  },
  {
    key: '(GMT+03:00) Kuwait',
    name: 'Asia/Kuwait',
    offset: +3,
  },
  {
    key: '(GMT+03:00) Nairobi',
    name: 'Africa/Nairobi',
    offset: +3,
  },
  {
    key: '(GMT+03:00) Riyadh',
    name: 'Asia/Riyadh',
    offset: +3,
  },
  {
    key: '(GMT+03:00) Moscow',
    name: 'Europe/Moscow',
    offset: +3,
  },
  {
    key: '(GMT+03:30) Tehran',
    name: 'Asia/Tehran',
    offset: +3.5,
  },
  {
    key: '(GMT+04:00) Baku',
    name: 'Asia/Baku',
    offset: +4,
  },
  {
    key: '(GMT+04:00) Volgograd',
    name: 'Europe/Volgograd',
    offset: +4,
  },
  {
    key: '(GMT+04:00) Muscat',
    name: 'Asia/Muscat',
    offset: +4,
  },
  {
    key: '(GMT+04:00) Tbilisi',
    name: 'Asia/Tbilisi',
    offset: +4,
  },
  {
    key: '(GMT+04:00) Yerevan',
    name: 'Asia/Yerevan',
    offset: +4,
  },
  {
    key: '(GMT+04:30) Kabul',
    name: 'Asia/Kabul',
    offset: +4.5,
  },
  {
    key: '(GMT+05:00) Karachi',
    name: 'Asia/Karachi',
    offset: +5,
  },
  {
    key: '(GMT+05:00) Tashkent',
    name: 'Asia/Tashkent',
    offset: +5,
  },
  {
    key: '(GMT+05:30) Kolkata',
    name: 'Asia/Kolkata',
    offset: +5.5,
  },
  {
    key: '(GMT+05:45) Kathmandu',
    name: 'Asia/Kathmandu',
    offset: +5.75,
  },
  {
    key: '(GMT+06:00) Ekaterinburg',
    name: 'Asia/Yekaterinburg',
    offset: +6,
  },
  {
    key: '(GMT+06:00) Almaty',
    name: 'Asia/Almaty',
    offset: +6,
  },
  {
    key: '(GMT+06:00) Dhaka',
    name: 'Asia/Dhaka',
    offset: +6,
  },
  {
    key: '(GMT+07:00) Novosibirsk',
    name: 'Asia/Novosibirsk',
    offset: +7,
  },
  {
    key: '(GMT+07:00) Bangkok',
    name: 'Asia/Bangkok',
    offset: +7,
  },
  {
    key: '(GMT+07:00) Jakarta',
    name: 'Asia/Jakarta',
    offset: +7,
  },
  {
    key: '(GMT+08:00) Krasnoyarsk',
    name: 'Asia/Krasnoyarsk',
    offset: +8,
  },
  {
    key: '(GMT+08:00) Chongqing',
    name: 'Asia/Chongqing',
    offset: +8,
  },
  {
    key: '(GMT+08:00) Hong Kong',
    name: 'Asia/Hong_Kong',
    offset: +8,
  },
  {
    key: '(GMT+08:00) Kuala Lumpur',
    name: 'Asia/Kuala_Lumpur',
    offset: +8,
  },
  {
    key: '(GMT+08:00) Perth',
    name: 'Australia/Perth',
    offset: +8,
  },
  {
    key: '(GMT+08:00) Singapore',
    name: 'Asia/Singapore',
    offset: +8,
  },
  {
    key: '(GMT+08:00) Taipei',
    name: 'Asia/Taipei',
    offset: +8,
  },
  {
    key: '(GMT+08:00) Ulaan Bataar',
    name: 'Asia/Ulaanbaatar',
    offset: +8,
  },
  {
    key: '(GMT+08:00) Urumqi',
    name: 'Asia/Urumqi',
    offset: +8,
  },
  {
    key: '(GMT+09:00) Irkutsk',
    name: 'Asia/Irkutsk',
    offset: +9,
  },
  {
    key: '(GMT+09:00) Seoul',
    name: 'Asia/Seoul',
    offset: +9,
  },
  {
    key: '(GMT+09:00) Tokyo',
    name: 'Asia/Tokyo',
    offset: +9,
  },
  {
    key: '(GMT+09:30) Adelaide',
    name: 'Australia/Adelaide',
    offset: +9.5,
  },
  {
    key: '(GMT+09:30) Darwin',
    name: 'Australia/Darwin',
    offset: +9.5,
  },
  {
    key: '(GMT+10:00) Yakutsk',
    name: 'Asia/Yakutsk',
    offset: +10,
  },
  {
    key: '(GMT+10:00) Brisbane',
    name: 'Australia/Brisbane',
    offset: +10,
  },
  {
    key: '(GMT+10:00) Canberra',
    name: 'Australia/Canberra',
    offset: +10,
  },
  {
    key: '(GMT+10:00) Guam',
    name: 'Pacific/Guam',
    offset: +10,
  },
  {
    key: '(GMT+10:00) Hobart',
    name: 'Australia/Hobart',
    offset: +10,
  },
  {
    key: '(GMT+10:00) Melbourne',
    name: 'Australia/Melbourne',
    offset: +10,
  },
  {
    key: '(GMT+10:00) Port Moresby',
    name: 'Pacific/Port_Moresby',
    offset: +10,
  },
  {
    key: '(GMT+10:00) Sydney',
    name: 'Australia/Sydney',
    offset: +10,
  },
  {
    key: '(GMT+11:00) Vladivostok',
    name: 'Asia/Vladivostok',
    offset: +11,
  },
  {
    key: '(GMT+12:00) Magadan',
    name: 'Asia/Magadan',
    offset: +12,
  },
  {
    key: '(GMT+12:00) Auckland',
    name: 'Pacific/Auckland',
    offset: +12,
  },
  {
    key: '(GMT+12:00) Fiji',
    name: 'Pacific/Fiji',
    offset: +12,
  },
];

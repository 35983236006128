import { SimpleGrid, GridItem, Container, Button, useDisclosure } from '@chakra-ui/react';

import ConfirmationModal from '../modals/ConfirmationModal';
import EmailConfirmationModal from '../modals/EmailConfirmationModal';

function Modals() {
  const {
    isOpen: isEmailConfirmationOpen,
    onOpen: onEmailConfirmationOpen,
    onClose: onEmailConfirmationClose,
  } = useDisclosure();
  const {
    isOpen: isConfirmationOpen,
    onOpen: onConfirmationOpen,
    onClose: onConfirmationClose,
  } = useDisclosure();

  return (
    <Container bg="white" borderBottom="solid 1px #DDD">
      <Container maxW="1180px" px="0" py={6}>
        <SimpleGrid w="full" columns={{ base: 1, sm: 2, md: 4 }} columnGap={4} rowGap={4}>
          <GridItem>
            <Button size="sm" alt="Confirmation" onClick={onConfirmationOpen}>
              Confirmation
            </Button>
            <ConfirmationModal
              isOpen={isConfirmationOpen}
              onClose={onConfirmationClose}
            />
          </GridItem>

          <GridItem>
            <Button size="sm" alt="Email Confirmation" onClick={onEmailConfirmationOpen}>
              Email Confirmation
            </Button>
            <EmailConfirmationModal
              isOpen={isEmailConfirmationOpen}
              onClose={onEmailConfirmationClose}
            />
          </GridItem>
        </SimpleGrid>
      </Container>
    </Container>
  );
}

export default Modals;

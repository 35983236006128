import appsignal from '../../appsignal';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Button,
  VStack,
  Heading,
  Text,
  useToast,
  Input,
  FormControl,
  FormLabel,
  useColorModeValue,
  Stack,
  Box,
} from '@chakra-ui/react';
import CustomToast from '../../common/CustomToast';
import { useForm } from 'react-hook-form';
import { useContext } from 'react';
import { useNavigate } from 'react-router';
import KeysListContext from '../../store/client/KeysListContext';
import { saveAs } from 'file-saver';
import { CustomSquareDownloadIcon } from '../../theme/icons/CustomSquareDownloadIcon';
import { CustomCopyIcon } from '../../theme/icons/CustomCopyIcon';
import { getErrorResponsePayload } from '../../utils/ajax';
import { HttpContext } from '../../context/HttpContext';

const NewApiKeyModal = ({ isOpen, onClose, revealApiKey, setRevealApiKey }) => {
  const toast = useToast();
  const navigate = useNavigate();
  const { authAxios } = useContext(HttpContext);
  const keysListCtx = useContext(KeysListContext);

  const { handleSubmit, formState } = useForm();
  const { isSubmitting } = formState;

  const modeBoxBg = useColorModeValue('white', 'secondaryDark');
  const modeTextBg = useColorModeValue('white', 'primaryDark06');
  const modeLabelBg = useColorModeValue('white', 'primaryDark03');
  const modeOffset = useColorModeValue('0', '16px !important');

  const copyToClipboardHandler = async () => {
    if (revealApiKey.length === 1) {
      await navigator.clipboard.writeText(revealApiKey);
    } else {
      await navigator.clipboard.writeText(revealApiKey.length - 1);
    }
    toast({
      render: ({ onClose }) => (
        <CustomToast
          status="success"
          title="Copied to clipboard"
          description=""
          onClose={onClose}
        />
      ),
    });
  };

  const onSubmit = async () => {
    keysListCtx.updateKeyState({
      ...keysListCtx.keyState,
      loading: true,
      status: 'pending',
    });

    try {
      const { data } = await authAxios.get(`keys`);
      const keys = data?.data || [];
      const total = data?.data.length ?? 0;
      keysListCtx.updateKeyState({
        ...keysListCtx.keyState,
        items: keys,
        total: total,
        loading: false,
        status: 'resolved',
      });
      navigate('/developer-tools', { replace: true });
    } catch (error) {
      appsignal.sendError(error);
      console.log(error);
      keysListCtx.updateKeyState({
        ...keysListCtx.keyState,
        items: [],
        total: 0,
        loading: false,
        error: 'Something went wrong',
        status: 'rejected',
      });
      const { message, code } = getErrorResponsePayload(error);
      code !== 401 &&
        toast({
          render: (props) => (
            <CustomToast
              status="error"
              title={message ? message : `Something went wrong`}
              description={!message && 'Please try again later'}
              onClose={props.onClose}
            />
          ),
        });
    }
    onClose();
    setRevealApiKey([]);
  };

  const handleSaveApiKeyToTxtFile = () => {
    const blob = new Blob([revealApiKey], {
      type: 'text/plain;charset=utf-8',
    });
    saveAs(blob, 'PassEntryApiKey.txt');
  };

  return (
    <Modal onClose={onClose} isOpen={isOpen} closeOnOverlayClick={false} size="l">
      <ModalOverlay />
      <ModalContent bg={modeBoxBg}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalHeader bg={modeTextBg} pb={modeOffset} borderRadius="15px 15px 0 0">
            <Heading fontSize={{ base: '24px', lg: '32px' }} lineHeight="130%">
              New API key
            </Heading>
          </ModalHeader>
          <ModalBody bg={modeLabelBg}>
            <VStack spacing="20px">
              <FormControl>
                <FormLabel>Your API key is ready</FormLabel>
                <Input
                  id="apiKeyName"
                  type="string"
                  readOnly
                  defaultValue={revealApiKey}
                  height="53px"
                />
              </FormControl>
              <Stack
                width="full"
                spacing="11px"
                direction={{ base: 'column', sm: 'row' }}
              >
                <Button
                  width="full"
                  onClick={() => {
                    handleSaveApiKeyToTxtFile();
                  }}
                >
                  <Box as="span" zIndex={1}>
                    <CustomSquareDownloadIcon h="20px" w="auto" mr="10px" fill="white" />
                    Download API key
                  </Box>
                </Button>
                <Button fontSize="14px" width="full" onClick={copyToClipboardHandler}>
                  <Box as="span" zIndex={1}>
                    <CustomCopyIcon h="20px" w="auto" mr="10px" fill="white" />
                    Copy api key
                  </Box>
                </Button>
              </Stack>
              <Text as="div" textStyle="bodyMedium" mt={2}>
                Note&#58;&nbsp;
                <Text
                  color={useColorModeValue('secondaryDark08', 'white')}
                  display="inline"
                >
                  This API key will not be displayed again for security reasons. Make sure
                  to store it safely.
                </Text>
              </Text>
            </VStack>
          </ModalBody>
          <ModalFooter
            display="flex"
            justifyContent="center"
            bg={modeTextBg}
            //pt={modeOffset}
            borderRadius="0 0 15px 15px"
          >
            <Button
              type="submit"
              alt="OK"
              fontSize="14px"
              h="36px"
              width={{ base: 'full', sm: 'auto' }}
              isLoading={isSubmitting}
            >
              OK
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};
export default NewApiKeyModal;

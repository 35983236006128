import {
  Container,
  Heading,
  Divider,
  Flex,
  Text,
  Box,
  useColorModeValue,
  Spinner,
} from '@chakra-ui/react';
import Breadcrumbs from '../../common/Breadcrumbs';
import { useContext } from 'react';
import EventsContext from '../../store/client/EventsContext';
import EventsInfoCreateForm from '../../components/client/events/EventsInfoCreateForm';
import { useParams } from 'react-router';

const EventsCreatePageForm = ({ isEdit }) => {
  const { uuid } = useParams();
  const eventsCtx = useContext(EventsContext);

  const loading = isEdit && eventsCtx.eventState?.name === '';

  const breadcrumbLinks = [
    {
      linkName: 'Home',
      linkUrl: '/',
    },
    {
      linkName: 'Events',
      linkUrl: '/events',
    },
    {
      linkName: isEdit ? eventsCtx.eventState.name : 'Create event',
      linkUrl: null,
    },
  ];

  const modeBoxBg = useColorModeValue('primaryBackground', 'secondaryDark');
  const modeHelperText = useColorModeValue('secondaryDark08', '#DDDDDD');

  if (loading) {
    return (
      <Spinner
        width="52px"
        height="52px"
        thickness="4px"
        speed="0.65s"
        emptyColor="quinaryBackground"
        m="auto"
      />
    );
  }

  return (
    <Container display="flex" flexDir="column" flex="1" pb={6}>
      <Breadcrumbs breadcrumbLinks={breadcrumbLinks} />
      <Flex
        flexDirection={{ base: 'column', sm: 'row' }}
        alignItems="center"
        flexWrap="wrap"
        mt="15px"
        py="9px"
        minH="54px"
      >
        <Heading alignSelf="flex-start" mr={2} wordBreak="break-word">
          {isEdit ? eventsCtx.eventState.name : 'Create event'}{' '}
          {isEdit && (
            <Text
              as="span"
              display="inline-block"
              textStyle="bodyFamilyMedium"
              fontSize="14px"
              color={modeHelperText}
              lineHeight="130%"
            >
              / uuid: {uuid}
            </Text>
          )}
        </Heading>
      </Flex>

      <Divider borderColor="primaryBackground" mb={6} />

      <Box>
        <EventsInfoCreateForm isEdit={isEdit} />
      </Box>
    </Container>
  );
};

export default EventsCreatePageForm;

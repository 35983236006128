import appsignal from '../../../appsignal';
import {
  Box,
  Text,
  Divider,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  Flex,
  Button,
  Show,
  Hide,
  useDisclosure,
  InputGroup,
  InputRightElement,
  useColorModeValue,
  useToast,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import CustomToast from '../../../common/CustomToast';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { getErrorResponsePayload } from '../../../utils/ajax';
import { useContext, useState } from 'react';
import EmailConfirmationModal from '../../modals/EmailConfirmationModal';
import { CustomVisibilityIcon } from '../../../theme/icons/CustomVisibilityIcon';
import { CustomVisibilityOffIcon } from '../../../theme/icons/CustomVisibilityOffIcon';
import { AuthContext } from '../../../context/AuthContext';
import { MeContext } from '../../../context/MeContext';
import { HttpContext } from '../../../context/HttpContext';

function FormChangeEmailAddress() {
  const { authAxios } = useContext(HttpContext);
  const toast = useToast();

  const meCtx = useContext(MeContext);
  const account = meCtx?.state?.account;
  const {
    isOpen: isEmailConfirmationOpen,
    onOpen: onEmailConfirmationOpen,
    onClose: onEmailConfirmationClose,
  } = useDisclosure();
  const [show, setShow] = useState(false);
  const modeBoxBg = useColorModeValue('gradient', 'secondaryDark');
  const modeTextBg = useColorModeValue('quaternaryBackground', 'primaryDark06');
  const modeBorderColor = useColorModeValue('primaryBackground', 'primaryDark');
  const modeFormControlBg = useColorModeValue('white', 'primaryDark08');
  const modeLabelBg = useColorModeValue('#EFEFEF', 'primaryDark03');
  const modeIconColor = useColorModeValue('secondaryDark06', '#DDDDDD');

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .required('Please enter your email address')
      .email('Please enter a valid email address'),
    password: Yup.string().required('Please enter your password'),
  });

  const formOptions = {
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
  };

  const {
    register,
    watch,
    setValue,
    handleSubmit,
    formState: { errors, isSubmitting, isDirty, isValid },
    setError,
    reset,
  } = useForm(formOptions);

  const showPasswordClickHandler = () => setShow(!show);

  const onSubmit = async (values) => {
    try {
      const submittedData = {
        currentPassword: values.password,
        email: values.email,
      };
      await authAxios.post(`accounts/me/email`, submittedData);
      onEmailConfirmationOpen();
      reset();
    } catch (onError) {
      appsignal.sendError(onError);
      const { errors } = getErrorResponsePayload(onError);
      const filteredErrors = errors?.filter((error) => error) || [];

      if (filteredErrors.length > 0) {
        filteredErrors.forEach((error) => {
          toast({
            render: (props) => (
              <CustomToast
                status="error"
                title={error}
                onClose={props.onClose}
              />
            ),
          });
        });
      } else {
        toast({
          render: (props) => (
            <CustomToast
              status="error"
              title="Something went wrong"
              description="Please try again later."
              onClose={props.onClose}
            />
          ),
        });
      }
    }
  };

  return (
    <Box className="autofillForDarkBg" w="full">
      <Box className="primaryBoxShadow" bg={modeBoxBg} borderRadius="15px">
        <form onSubmit={handleSubmit(onSubmit)}>
          <Text
            fontSize="18px"
            textStyle="headingFamilyMedium"
            p="14px 14px 14px 24px"
            bg={modeTextBg}
            borderRadius="15px 15px 0 0"
          >
            Change email address
          </Text>
          <Divider borderColor={modeBorderColor} />

          <Box pl="24px" pr="14px" py="29px" bg={modeFormControlBg} minH="78px">
            <Text as="div" textStyle="bodyMedium">
              Current email:{' '}
              <Text
                color={useColorModeValue('secondaryDark08', 'white')}
                display="inline"
              >
                {account?.email}
              </Text>
              {account?.unconfirmed_email && (
                <Text color="brandDark" mt={2}>
                  A confirmation email has been sent to{' '}
                  {account.unconfirmed_email}. Please confirm your new email
                  address.
                </Text>
              )}
            </Text>
          </Box>
          <Divider borderColor={modeBorderColor} />

          <FormControl
            isRequired
            isInvalid={errors.email}
            bg={modeFormControlBg}
          >
            <Flex alignItems="center">
              <Hide below="2xl">
                <Box pl="24px" flexShrink={0} w="240px">
                  <FormLabel m={0}>New email</FormLabel>
                </Box>
              </Hide>
              <Box p="14px" w="full" bg={modeLabelBg}>
                <Show below="2xl">
                  <FormLabel>New email</FormLabel>
                </Show>
                <Input
                  variant="filledForDarkBg"
                  id="email"
                  type="email"
                  placeholder="Enter new email"
                  {...register('email')}
                  autoComplete="new-email"
                  defaultValue={account?.email}
                  onBlur={() => {
                    const value = watch('email');
                    if (value.trim() === '') {
                      setValue('email', account?.email);
                    }
                  }}
                />
                <FormErrorMessage pt={2} pl={3}>
                  {errors.email?.message || errors.email}
                </FormErrorMessage>
              </Box>
            </Flex>
          </FormControl>
          <Divider borderColor={modeBorderColor} />

          <FormControl
            isRequired
            isInvalid={errors.password}
            bg={modeFormControlBg}
          >
            <Flex alignItems="center">
              <Hide below="2xl">
                <Box pl="24px" flexShrink={0} w="240px">
                  <FormLabel m={0}>Password</FormLabel>
                </Box>
              </Hide>
              <Box p="14px" w="full" bg={modeLabelBg}>
                <Show below="2xl">
                  <FormLabel>Password</FormLabel>
                </Show>
                <InputGroup>
                  <Input
                    variant="filledForDarkBg"
                    id="password"
                    type={show ? 'text' : 'password'}
                    placeholder="Enter password"
                    {...register('password')}
                    autoComplete="new-password"
                  />
                  <InputRightElement>
                    {show ? (
                      <CustomVisibilityOffIcon
                        onClick={showPasswordClickHandler}
                        boxSize="18px"
                        cursor="pointer"
                        color={modeIconColor}
                      />
                    ) : (
                      <CustomVisibilityIcon
                        onClick={showPasswordClickHandler}
                        boxSize="18px"
                        cursor="pointer"
                        color={modeIconColor}
                      />
                    )}
                  </InputRightElement>
                </InputGroup>
                <FormErrorMessage pt={2} pl={3}>
                  {errors.password?.message || errors.password}
                </FormErrorMessage>
              </Box>
            </Flex>
          </FormControl>
          <Divider borderColor={modeBorderColor} />

          <Box pl="24px" pr="14px" pt="16px" bg={modeTextBg}>
            <Text as="div" textStyle="bodyMedium">
              Note:{' '}
              <Text
                color={useColorModeValue('secondaryDark08', 'white')}
                display="inline"
              >
                This will change the email address you use to log in. After
                changing this email address, please then check your inbox to
                confirm the new email address.
              </Text>
            </Text>
          </Box>

          <Flex
            w="full"
            justifyContent="flex-end"
            bg={modeTextBg}
            borderRadius="0 0 15px 15px"
            p="20px 14px"
          >
            <Button
              type="submit"
              isLoading={isSubmitting}
              disabled={!isDirty || !isValid || isSubmitting}
              alt="Change email"
              size="sm"
            >
              Change email
            </Button>
          </Flex>
        </form>
      </Box>
      <EmailConfirmationModal
        isOpen={isEmailConfirmationOpen}
        onClose={onEmailConfirmationClose}
      />
    </Box>
  );
}

export default FormChangeEmailAddress;

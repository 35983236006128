import { useContext, useEffect, useRef } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useTable, usePagination } from 'react-table';
import {
  Flex,
  Box,
  Heading,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Button,
  Text,
  useMediaQuery,
  Spinner,
  Hide,
  HStack,
  Show,
  useColorMode,
  useColorModeValue,
} from '@chakra-ui/react';
import TableBox from '../../common/TableBox';
import ReactTablePagination from '../../../common/ReactTablePagination';
import EmptyState from '../../../common/EmptyState';
import PassesRenderStatus from './PassesRenderStatus';
import PassesListMoreInfo from './PassesListMoreInfo';
import { CustomPassesMultiIcon } from '../../../theme/multicolor-icons/CustomPassesMultiIcon';
import { CustomEditIcon } from '../../../theme/icons/CustomEditIcon';
import PassesListCreatePassesButton from './PassesListCreatePassesButton';
import RedirectionContext from '../../../context/RedirectionContext';
import { CustomVisibilityIcon } from '../../../theme/multicolor-icons/CustomVisibilityIcon';
import EmptyDefaultSvgAnimated from '../../../theme/illustrations-animated/EmptyDefaultSvgAnimated';
import EmptyDefaultDarkSvgAnimated from '../../../theme/illustrations-animated/EmptyDefaultDarkSvgAnimated';
import PassesListDownload from './PassesListDownload';
import moment from 'moment';
import PassesListContext from '../../../store/client/PassesListContext';
import { MeContext } from '../../../context/MeContext';

// Create a default prop getter (per documentation https://react-table.tanstack.com/docs/examples/data-driven-classes-and-styles)
const defaultPropGetter = () => ({});

export default function PassesListTable({
  columns,
  data,
  fetchData,
  loading,
  total,
  pageCount: controlledPageCount,
  getColumnProps = defaultPropGetter,
  getCellProps = defaultPropGetter,
  query,
}) {
  const navigate = useNavigate();
  const { colorMode } = useColorMode();
  const redirectCtx = useContext(RedirectionContext);
  const modeEmptySvgIcon = useColorModeValue(
    <EmptyDefaultSvgAnimated />,
    <EmptyDefaultDarkSvgAnimated />
  );
  const [isLargerThan2XL] = useMediaQuery('(min-width: 96em)');
  const [isLargerThanXL] = useMediaQuery('(min-width: 80em)');
  const [isLargerThanLG] = useMediaQuery('(min-width: 62em)');
  const [isLargerThanMD] = useMediaQuery('(min-width: 48em)');
  const [isLargerThanSM] = useMediaQuery('(min-width: 30em)');
  const [isLargerThanS] = useMediaQuery('(min-width: 18em)');
  const [isLargerThanXS] = useMediaQuery('(min-width: 10em)');

  // use this ref to keep track of updating internal table state
  const tableStateUpdateRef = useRef(false);

  const passesCtx = useContext(PassesListContext);
  const meCtx = useContext(MeContext);
  const { filterEntity } = meCtx.state;
  const filterEntityRef = useRef(filterEntity);

  const currentPage =
    !!sessionStorage.getItem('lastVisitedPageIndex') &&
    sessionStorage.getItem('navigatedFromPassPage') === 'true' &&
    !passesCtx.newFiltersSet
      ? parseInt(sessionStorage.getItem('lastVisitedPageIndex'), 10) - 1
      : 0;

  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page, // Instead of using 'rows', we'll use page, which has only the rows for the active page
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    setHiddenColumns,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: currentPage },
      manualPagination: true,
      pageCount: controlledPageCount,
    },
    usePagination
  );

  useEffect(() => {
    gotoPage(currentPage);
    fetchData({ pageIndex, pageSize });
  }, [query, fetchData, pageSize, gotoPage, currentPage]);

  useEffect(() => {
    if (filterEntity !== filterEntityRef.current) {
      filterEntityRef.current = filterEntity;
      gotoPage(0);
      fetchData({ pageSize: 10, pageIndex: 0 });
    }
  }, [filterEntity]);

  useEffect(() => {
    if (isLargerThan2XL) {
      setHiddenColumns(['moreInfo']);
    } else if (isLargerThanXL) {
      setHiddenColumns(['moreInfo']);
    } else if (isLargerThanLG) {
      setHiddenColumns(['moreInfo', 'addedToWallet', 'deviceType']);
    } else if (isLargerThanMD) {
      setHiddenColumns(['actionSecond', 'addedToWallet', 'deviceType']);
    } else if (isLargerThanSM) {
      setHiddenColumns([
        'issuedAt',
        'actionSecond',
        'addedToWallet',
        'deviceType',
      ]);
    } else if (isLargerThanS) {
      setHiddenColumns([
        'passTemplate',
        'addedToWallet',
        'actionFirst',
        'actionSecond',
        'deviceType',
      ]);
    } else if (isLargerThanXS) {
      setHiddenColumns([
        'passTemplate',
        'issuedAt',
        'addedToWallet',
        'actionSecond',
        'status',
        'actionFirst',
      ]);
    } else {
      setHiddenColumns([
        'passTemplate',
        'issuedAt',
        'addedToWallet',
        'status',
        'actionSecond',
        'actionFirst',
      ]);
    }
  }, [
    isLargerThan2XL,
    isLargerThanXL,
    isLargerThanLG,
    isLargerThanMD,
    isLargerThanSM,
    isLargerThanS,
    setHiddenColumns,
    isLargerThanXS,
  ]);

  // Listen for changes in pagination and use the state to fetch our new data
  useEffect(() => {
    fetchData({ pageIndex, pageSize });
  }, [fetchData, pageIndex, pageSize]);

  // clear our ref when the data is loaded, after we perform any side effects
  useEffect(() => {
    tableStateUpdateRef.current = false;
  }, [data]);

  function updateSessionAndContext(pageIndex, passesCtx) {
    sessionStorage.setItem('lastVisitedPageIndex', (pageIndex + 1).toString());
    sessionStorage.setItem('navigatedFromPassPage', 'true');
    sessionStorage.setItem(
      'filterValues',
      JSON.stringify(passesCtx.filterValues)
    );
    passesCtx.updateNewFiltersSet(false);
  }

  function handleRowClick(row) {
    updateSessionAndContext(pageIndex, passesCtx);
    navigate(row.original.exampleForNestedObject.passesSinglePageUrl);
  }

  function handleEditClick(e, row) {
    e.stopPropagation();
    updateSessionAndContext(pageIndex, passesCtx);
    navigate(`/passes/edit/${row.original.id}`);
  }
  if (loading) {
    return (
      <Spinner
        width="52px"
        height="52px"
        thickness="4px"
        speed="0.65s"
        emptyColor="quinaryBackground"
        mt={4}
        mx="auto"
      />
    );
  }

  if (data.length === 0 && !loading && query === '') {
    return (
      <EmptyState
        iconSvgAnimated={modeEmptySvgIcon}
        heading="There are no passes yet."
        text="Click on one of the buttons below and start creating NFC passes."
      >
        <Flex
          spacing="10px"
          alignItems="center"
          flexDirection={{ base: 'column', sm: 'row' }}
        >
          <PassesListCreatePassesButton />
          <Text as="span" mr={{ base: '0px', sm: '10px' }}>
            or
          </Text>
          <Button
            alt="Create a pass"
            size="sm"
            as={RouterLink}
            to="/passes/create"
            onClick={() => {
              // reset temp pass state
              redirectCtx.updateReaderAddedExternally(false);
              redirectCtx.updatePassTemplateFromPassState(false);
              redirectCtx.updatePassTemplateFromEditPassState(false);
              redirectCtx.updateAddReaderFromPassState(false);
              redirectCtx.updateReadersArray([]);
              redirectCtx.updateAddReaderFromEditPassState(false);
              redirectCtx.updateEventAddedExternally(false);
              redirectCtx.updateAddEventFromPassState(false);
              redirectCtx.updateAddEventFromEditPassState(false);
            }}
          >
            Create a pass
          </Button>
        </Flex>
      </EmptyState>
    );
  } else if (data.length === 0 && !loading && query !== '') {
    return (
      <EmptyState
        iconSvgAnimated={modeEmptySvgIcon}
        heading="No results found."
        text="Try adjusting your filters to find the passes that you are looking for."
      />
    );
  }

  return (
    <>
      <TableBox>
        <Heading size="lg" mb="16px">
          Passes{' '}
          <Text
            as="span"
            textStyle="headingFamilyMedium"
            fontSize="18px"
            fontWeight="500"
          >
            (
            <Box as="span" color="brand">
              {total}
            </Box>
            )
          </Text>
        </Heading>
        <Table {...getTableProps()}>
          <Thead>
            {headerGroups.map((headerGroup) => (
              <Tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => {
                  return (
                    <Th
                      {...column.getHeaderProps()}
                      {...column.getToggleHiddenProps()}
                      isNumeric={column.isNumeric}
                    >
                      {column.render('Header')}
                    </Th>
                  );
                })}
              </Tr>
            ))}
          </Thead>

          {data.length > 0 && (
            <Tbody {...getTableBodyProps()}>
              {page.map((row, i) => {
                prepareRow(row);
                return (
                  <Tr
                    {...row.getRowProps()}
                    onClick={() => handleRowClick(row)}
                  >
                    {row.cells.map((cell) => {
                      return (
                        <Td
                          {...cell.getCellProps([
                            {
                              className: cell.column.className,
                              style: cell.column.style,
                            },
                            getColumnProps(cell.column),
                            getCellProps(cell),
                          ])}
                          isNumeric={cell.column.isNumeric}
                          textAlign={
                            (cell.column.id === 'actionSecond' ||
                              cell.column.id === 'actionFirst') &&
                            'right'
                          }
                        >
                          {cell.column.id === 'externalId' ? (
                            <Box>
                              <HStack spacing={3}>
                                <Hide below="xl">
                                  <Box>
                                    <CustomPassesMultiIcon
                                      boxSize="30px"
                                      className={
                                        colorMode === 'dark' &&
                                        'multicolorDarkBg'
                                      }
                                    />
                                  </Box>
                                </Hide>
                                <Text>{row.original.udi || 'N/A'}</Text>
                              </HStack>
                            </Box>
                          ) : cell.column.id === 'passTemplate' ? (
                            <Box>
                              <HStack spacing={0}>
                                <Hide below="xl"></Hide>
                                <Text>{row.original.templateName}</Text>
                              </HStack>
                            </Box>
                          ) : cell.column.id === 'issuedAt' ? (
                            <Box>
                              <HStack spacing={0} width="100px">
                                <Hide below="xl"></Hide>
                                <Text fontSize="12px">
                                  {row.original.issuedAt}
                                </Text>
                              </HStack>
                            </Box>
                          ) : cell.column.id === 'deviceType' ? (
                            <Box>
                              <HStack spacing={0} width="100px">
                                <Hide below="xl"></Hide>
                                <Text>{row.original.deviceType}</Text>
                              </HStack>
                            </Box>
                          ) : cell.column.id === 'addedToWallet' ? (
                            <Box>
                              <HStack spacing={0}>
                                <Hide below="xl"></Hide>
                                <Text>{row.original.addedToWallet}</Text>
                              </HStack>
                            </Box>
                          ) : cell.column.id === 'status' ? (
                            <PassesRenderStatus
                              value={row.original.attributes.status}
                              hasTooltip
                            />
                          ) : cell.column.id === 'actionFirst' ? (
                            <Button variant="link" pt="5px">
                              <Flex justifyContent="center" alignItems="center">
                                <Show below="sm">
                                  <CustomVisibilityIcon
                                    className={
                                      colorMode === 'dark' && 'multicolorDarkBg'
                                    }
                                  />
                                </Show>
                                <Show above="xl">
                                  <CustomVisibilityIcon
                                    className={
                                      colorMode === 'dark' && 'multicolorDarkBg'
                                    }
                                  />
                                </Show>
                                <Text
                                  textStyle="headingFamilyMedium"
                                  fontWeight="500"
                                  pl="7px"
                                  pt="1px"
                                >
                                  View
                                </Text>
                              </Flex>
                            </Button>
                          ) : cell.column.id === 'actionSecond' &&
                            (row.original.attributes.status === 'expired' ||
                              row.original.attributes.status ===
                                'deactivated') ? (
                            ''
                          ) : cell.column.id === 'actionSecond' ? (
                            <Button
                              size="xs"
                              px={isLargerThanSM ? '15px' : '7px'}
                              onClick={(e) => {
                                handleEditClick(e, row);
                                redirectCtx.updateAddEventFromPassState(false);
                                redirectCtx.updateAddEventFromEditPassState(
                                  false
                                );
                                redirectCtx.updatePassTemplateFromPassState(
                                  false
                                );
                                redirectCtx.updatePassTemplateFromEditPassState(
                                  false
                                );
                                redirectCtx.updateAddReaderFromPassState(false);
                                redirectCtx.updateAddReaderFromEditPassState(
                                  false
                                );
                                redirectCtx.updateReaderAddedExternally(false);
                              }}
                            >
                              <HStack zIndex={1}>
                                <Show below="sm">
                                  <CustomEditIcon
                                    boxSize="20px"
                                    color1="#fff"
                                  />
                                </Show>
                                <Show above="xl">
                                  <CustomEditIcon
                                    boxSize="20px"
                                    color1="#fff"
                                  />
                                </Show>
                                <Hide below="sm">
                                  <Text
                                    textStyle="headingFamilyMedium"
                                    fontWeight="500"
                                    lineHeight="28px"
                                    textTransform="none"
                                  >
                                    Edit pass
                                  </Text>
                                </Hide>
                              </HStack>
                            </Button>
                          ) : cell.column.id === 'moreInfo' ? (
                            <PassesListMoreInfo cell={cell}>
                              <PassesRenderStatus
                                value={cell.row.original.attributes.status}
                                inDrawer
                                hasTooltip
                              />
                            </PassesListMoreInfo>
                          ) : cell.value !== null &&
                            cell.value !== undefined &&
                            (cell.column.id === 'lastScan' ||
                              cell.column.id === 'addedToWallet') ? (
                            <Flex
                              gap={1}
                              direction={{
                                base: 'row',
                                md: 'column',
                                '2xl': 'row',
                              }}
                            >
                              <Text>
                                {moment(cell.value).format('DD/MM/YYYY')}
                              </Text>
                              <Text whiteSpace="nowrap">
                                {cell.column.id === 'lastScan'
                                  ? moment(cell.value).format('hh:mm:ss A')
                                  : moment(cell.value).format('hh:mm A')}
                              </Text>
                            </Flex>
                          ) : cell.value === null &&
                            (cell.column.id === 'lastScan' ||
                              cell.column.id === 'addedToWallet') ? (
                            ''
                          ) : cell.value !== null ? (
                            <Text>{cell.value}</Text>
                          ) : (
                            <Text as="span" color="secondaryDark06">
                              n/d
                            </Text>
                          )}
                        </Td>
                      );
                    })}
                  </Tr>
                );
              })}
            </Tbody>
          )}
        </Table>

        {data.length > 0 && (
          <Flex
            mt={6}
            flexDirection={{ base: 'column-reverse', '2xl': 'row' }}
            justifyContent={{ base: 'none', '2xl': 'space-between' }}
          >
            <PassesListDownload />
            <ReactTablePagination
              canPreviousPage={canPreviousPage}
              canNextPage={canNextPage}
              pageOptions={pageOptions}
              pageCount={pageCount}
              gotoPage={gotoPage}
              nextPage={nextPage}
              previousPage={previousPage}
              setPageSize={setPageSize}
              pageIndex={pageIndex}
              pageSize={pageSize}
            />
          </Flex>
        )}
      </TableBox>
    </>
  );
}

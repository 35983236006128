import appsignal from '../../../appsignal';
import {
  Button,
  Box,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  FormControl,
  VStack,
  Link,
  Circle,
  Text,
  Checkbox,
  Divider,
  Collapse,
  useColorModeValue,
  useToast,
  Flex,
  Hide,
  Show,
} from '@chakra-ui/react';
import { useDisclosure } from '@chakra-ui/react';
import { CustomFilterIcon } from '../../../theme/icons/CustomFilterIcon';
import { useForm } from 'react-hook-form';
import { useContext, useEffect, useState } from 'react';
import PassTemplatesListContext from '../../../store/client/PassTemplatesListContext';
import {
  CustomReactSelect,
  formatOptionLabelSelectPassType,
  formatOptionLabelFilterEvent,
} from '../../common/CustomReactSelect';
import { HttpContext } from '../../../context/HttpContext';
import { MeContext } from '../../../context/MeContext';
import { getErrorResponsePayload } from '../../../utils/ajax';
import CustomToast from '../../../common/CustomToast';
import { CloseIcon } from '@chakra-ui/icons';

function PassTemplatesListFilter() {
  const toast = useToast();
  const { authAxios } = useContext(HttpContext);
  const passTemplatesListCtx = useContext(PassTemplatesListContext);
  const [totalActiveFilters, setTotalActiveFilters] = useState(0);
  const [selectedPassType, setSelectedPassType] = useState('');
  const [selectedEvent, setSelectedEvent] = useState('');
  const [eventsList, setEventsList] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isOpenType, onToggle: onToggleType } = useDisclosure();
  const { isOpen: isOpenEvent, onToggle: onToggleEvent } = useDisclosure();
  const { register, handleSubmit, reset, setValue } = useForm();
  const meCtx = useContext(MeContext);
  const { filterEntity } = meCtx.state;

  // PASS TYPES
  const passTypes = [
    {
      label: 'Generic pass',
      value: 'generic',
    },
    {
      label: 'Loyalty pass',
      value: 'storeCard',
    },
    {
      label: 'Event ticket',
      value: 'eventTicket',
    },
    {
      label: 'Loyalty stamp card',
      value: 'stampCard',
    },
  ];

  // FETCHING EVENTS
  useEffect(() => {
    const getData = async () => {
      try {
        const endpoint = filterEntity
          ? `api/v1/entities/${filterEntity.uuid}/events`
          : 'api/v1/events';
        const response = await authAxios.get(`${endpoint}?per_page=100`);
        const fetchedEvents = response?.data?.data;
        setEventsList(fetchedEvents);
      } catch (error) {
        appsignal.sendError(error);
        console.log(error);
        const { message, code } = getErrorResponsePayload(error);
        code !== 401 &&
          toast({
            render: (props) => (
              <CustomToast
                status="error"
                title={message ? message : `Something went wrong`}
                description={!message && 'Please try again later'}
                onClose={props.onClose}
              />
            ),
          });
      }
    };

    getData();

    return () => {
      setEventsList([]);
    };
  }, [authAxios, filterEntity]);

  useEffect(() => {
    onResetAll();
  }, [filterEntity]);

  const onSubmit = async (values) => {
    // on submit alter values object and add status from useState
    values.event = selectedEvent ? selectedEvent.attributes.name : '';
    values.passType = selectedPassType ? selectedPassType.value : '';

    passTemplatesListCtx.updateFilterValues(values);

    let countableFilterValues = [];
    Object.entries(values).forEach(([key, value]) => {
      if (!key.startsWith('by') && value !== false) {
        countableFilterValues = countableFilterValues.concat(value);
      }
    });
    setTotalActiveFilters(
      countableFilterValues.filter((val) => val.length !== 0).length
    );
    onClose();
  };

  useEffect(() => {
    setValue('passType', selectedPassType.passType);
    setValue('event', selectedEvent.event);
  }, [selectedPassType, selectedEvent, setValue]);

  const onResetAll = async () => {
    reset();
    passTemplatesListCtx.updateFilterValues({
      passType: '',
      event: '',
    });
    setTotalActiveFilters(0);
    onClose();
    isOpenType && onToggleType();
    isOpenEvent && onToggleEvent();
    setSelectedPassType('');
    setSelectedEvent('');
  };

  const handleCheckboxChange = (e, toggleFunction, callbackFunction) => {
    toggleFunction();
    callbackFunction();
  };

  return (
    <Flex
      alignSelf="flex-end"
      justify="flex-start"
      flexDirection={{ base: 'row-reverse', sm: 'row' }}
      flexGrow={{ base: '1', sm: 'unset' }}
    >
      {totalActiveFilters > 0 && (
        <Button
          variant="white"
          size="sm"
          w={{ base: '36px', sm: 'unset' }}
          px={{ base: '0', sm: '20px' }}
          mr={{ base: '0', sm: '10px' }}
          ml={{ base: '5px', sm: '0' }}
          onClick={onResetAll}
        >
          <Hide below="sm">
            <Text
              textStyle="headingFamilyMedium"
              textTransform="none"
              fontWeight="500"
              lineHeight="28px"
            >
              Clear
            </Text>
          </Hide>
          <Show below="sm">
            <CloseIcon />
          </Show>
        </Button>
      )}
      <Button size="sm" px={{ base: '15px', sm: '30px' }} onClick={onOpen}>
        <Box as="span" zIndex={1}>
          {totalActiveFilters ? (
            <Box as="span" display="inline-block">
              <Circle
                bg="white"
                size="18px"
                mr="10px"
                color="brand"
                fontSize="12px"
              >
                {totalActiveFilters}
              </Circle>
            </Box>
          ) : (
            <CustomFilterIcon boxSize="19px" mr="10px" />
          )}
          Filter
        </Box>
      </Button>
      <Drawer onClose={onClose} isOpen={isOpen}>
        <DrawerOverlay />
        <form onSubmit={handleSubmit(onSubmit)}>
          <DrawerContent bg="white">
            <DrawerCloseButton />
            <DrawerHeader
              borderBottomWidth="1px"
              borderBottomColor="primaryBackground"
              pt={{ base: '16px', lg: '56px' }}
              bg="white"
            >
              Filter Pass Templates
            </DrawerHeader>
            <DrawerBody p="0" bg="white">
              <VStack spacing={0}>
                <FormControl px="24px" mt="12px">
                  <Checkbox
                    id="byPassType"
                    {...register('byPassType')}
                    onChange={(e) =>
                      handleCheckboxChange(e, onToggleType, () => {
                        reset({ passType: '' });
                        setSelectedPassType('');
                      })
                    }
                  >
                    <Text color="secondaryDark">By pass type</Text>
                  </Checkbox>
                  <Collapse in={isOpenType} animateOpacity>
                    <CustomReactSelect
                      options={passTypes}
                      formatOptionLabel={formatOptionLabelSelectPassType}
                      placeholder="Select pass type"
                      onChange={(passType) => setSelectedPassType(passType)}
                      value={selectedPassType}
                      id="passTypeFilter"
                      passType
                    />
                  </Collapse>
                </FormControl>
                <Box py="12px" w="full">
                  <Divider borderColor="primaryBackground" />
                </Box>

                <FormControl px="24px" mt="14px">
                  <Checkbox
                    id="byEvent"
                    {...register('byEvent')}
                    onChange={(e) =>
                      handleCheckboxChange(e, onToggleEvent, () => {
                        reset({ event: '' });
                        setSelectedEvent('');
                      })
                    }
                  >
                    <Text color="secondaryDark">By event</Text>
                  </Checkbox>
                  <Collapse in={isOpenEvent} animateOpacity>
                    <CustomReactSelect
                      options={eventsList}
                      formatOptionLabel={formatOptionLabelFilterEvent}
                      placeholder="Select event"
                      onChange={(event) => setSelectedEvent(event)}
                      value={selectedEvent}
                      id="eventFilter"
                      event
                    />
                  </Collapse>
                </FormControl>
                <Box py="12px" w="full">
                  <Divider borderColor="primaryBackground" />
                </Box>
              </VStack>
            </DrawerBody>
            <DrawerFooter
              borderTopWidth="1px"
              borderTopColor="primaryBackground"
              bg="white"
            >
              <Link
                color={useColorModeValue('secondaryDark08', '#DDDDDD')}
                fontSize="14px"
                onClick={onResetAll}
                mr="30px"
              >
                <Text textStyle="headingFamilyMedium">Reset all</Text>
              </Link>
              <Button
                type="submit"
                alt="Apply filters"
                size="sm"
                px="30px"
                alignSelf="flex-start"
              >
                Apply filters
              </Button>
            </DrawerFooter>
          </DrawerContent>
        </form>
      </Drawer>
    </Flex>
  );
}

export default PassTemplatesListFilter;

import useOrganizationStatus from '../../hooks/useOrganisationStatus';
import { Container, Divider, useColorModeValue } from '@chakra-ui/react';
import Breadcrumbs from '../../common/Breadcrumbs';
import { PassesListContextProvider } from '../../store/client/PassesListContext';
import PassesList from '../../components/client/passes/PassesList';
import PassesListHeader from '../../components/client/passes/PassesListHeader';
import EmptyDefaultSvgAnimated from '../../theme/illustrations-animated//EmptyDefaultSvgAnimated';
import EmptyDefaultDarkSvgAnimated from '../../theme/illustrations-animated//EmptyDefaultDarkSvgAnimated';

const PassesListPage = () => {
  const modeSvgIcon = useColorModeValue(
    <EmptyDefaultSvgAnimated />,
    <EmptyDefaultDarkSvgAnimated />
  );
  const { shouldRender, components } = useOrganizationStatus(modeSvgIcon);

  const breadcrumbLinks = [
    {
      linkName: 'Home',
      linkUrl: '/',
    },
    {
      linkName: 'Passes',
      linkUrl: '/passes',
    },
  ];

  return (
    <PassesListContextProvider>
      <Container display="flex" flexDir="column" flex="1" pb={6}>
        <Breadcrumbs breadcrumbLinks={breadcrumbLinks} />
        <PassesListHeader />
        <Divider borderColor="primaryBackground" mb={6} />

        {components}
        {shouldRender && <PassesList />}

      </Container>
    </PassesListContextProvider>
  );
};
export default PassesListPage;

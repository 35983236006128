import {
  Button,
  Center,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useColorModeValue,
  VStack,
} from '@chakra-ui/react';
import { CustomPassesMultiIcon } from '../../theme/multicolor-icons/CustomPassesMultiIcon';
import { useFormContext } from 'react-hook-form';

const TemplateUpdateConfirmationModal = ({
  onClose,
  isOpen,
  onConfirm,
  totalPasses,
}) => {
  const modeBoxBg = useColorModeValue('white', 'secondaryDark');
  const modeTextBg = useColorModeValue('white', 'primaryDark06');
  const modeLabelBg = useColorModeValue('white', 'primaryDark03');

  const {
    formState: { isSubmitting },
  } = useFormContext();

  let message = '';
  if (totalPasses < 5000) {
    message = 'The update should be completed within half an hour.';
  } else if (totalPasses < 10000) {
    message = 'The update should be completed within 1 hour.';
  } else if (totalPasses < 20000) {
    message = 'The update should be completed within 2 hours.';
  } else {
    message = 'The update will require a minimum of 2 hours to complete.';
  }

  return (
    <>
      <Modal onClose={onClose} isOpen={isOpen} size="xl">
        <ModalOverlay />
        <ModalContent bg={modeBoxBg} p="20px">
          <ModalHeader bg={modeTextBg} pb="20px" borderRadius="15px 15px 0 0">
            <Center>
              <CustomPassesMultiIcon width="60px" height="60px" />
            </Center>
          </ModalHeader>
          <ModalBody bg={modeLabelBg}>
            <VStack spacing="10px" textAlign="center">
              <Text fontSize="18px" textStyle="bodyBold" color="secondaryDark">
                You are about to update {totalPasses} pass
                {totalPasses > 1 ? 'es' : ''}
              </Text>
              <Text
                fontSize="16px"
                textStyle="bodyRegular"
                color="secondaryDark"
              >
                {message}
              </Text>
              <Text
                fontSize="16px"
                textStyle="bodyRegular"
                color="secondaryDark"
              >
                Please double-check that all images and field values are{' '}
              </Text>
              <Text textStyle="bodyBold" color="secondaryDark" lineHeight="0.5">
                correct and free of typos.
              </Text>
            </VStack>
          </ModalBody>
          <ModalFooter bg={modeTextBg} borderRadius="0 0 15px 15px">
            <Stack
              mt="15px"
              spacing="10px"
              direction={{ base: 'column', sm: 'row' }}
              width={{ base: 'full', sm: 'auto' }}
            >
              <Button
                onClick={onClose}
                alt="Cancel"
                variant="secondary"
                width={{ base: 'full', sm: 'auto' }}
              >
                Back
              </Button>
              <Button
                alt="confirm"
                isLoading={isSubmitting}
                width={{ base: 'full', sm: 'auto' }}
                onClick={onConfirm}
              >
                Confirm
              </Button>
            </Stack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default TemplateUpdateConfirmationModal;

import { Container, Divider, Box, Grid } from '@chakra-ui/react';
import Breadcrumbs from '../../../common/Breadcrumbs';
import PreviewPassTemplates from '../pass-templates/PreviewPassTemplates';
import PassInfoView from './PassInfoView';
import PassesViewHeader from './PassesViewHeader';

const PassViewPageView = () => {
  const breadcrumbLinks = [
    {
      linkName: 'Home',
      linkUrl: '/',
    },
    {
      linkName: 'Passes',
      linkUrl: '/passes',
    },
  ];

  return (
    <Container display="flex" flexDir="column" flex="1" pb={6}>
      <Breadcrumbs breadcrumbLinks={breadcrumbLinks} />
      <PassesViewHeader />
      <Divider borderColor="primaryBackground" mb={6} />
      <Box>
        <Grid w="full" templateColumns={{ base: '100%', xl: '40% 60%' }} gap={6} mt={6}>
          <PassInfoView />
          <PreviewPassTemplates clientView />
        </Grid>
      </Box>
    </Container>
  );
};
export default PassViewPageView;

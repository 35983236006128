import { Heading, Center, VStack, Text, Image } from '@chakra-ui/react';
import { FORM_PADDING_TOP_XL } from '../../utils/consts';
import { useContext } from 'react';
import { AuthContext } from '../../context/AuthContext';
import { Navigate } from 'react-router-dom';

function VerifyingYourEmail() {
  const { isAuthenticated } = useContext(AuthContext);

  if (isAuthenticated) {
    return <Navigate to="/" />;
  }

  return (
    <Center pt={FORM_PADDING_TOP_XL} pb="10px">
      <VStack spacing={2}>
        <Image
          src="./vectors/verifying-your-email.svg"
          alt="Verifying your email"
          mb={10}
          alignSelf="center"
        />
        <Heading size="2xl" textAlign="center">
          Redirecting...
        </Heading>
        <VStack spacing={5}>
          <Text textStyle="bodyRoman" color="secondaryDark08">
            Please be patient.
          </Text>
        </VStack>
      </VStack>
    </Center>
  );
}

export default VerifyingYourEmail;

import { Icon } from '@chakra-ui/react';

export const CustomClientsBookIcon = (props) => (
  <Icon viewBox="0 0 59 59" {...props}>
    <path
      className="multicolorElToWhite darkBgElToBrand"
      d="M7.0062 36.1376C5.5312 36.1376 4.7937 35.4 4.7937 33.925V25.0751C4.7937 23.6001 5.5312 22.8625 7.0062 22.8625C8.4812 22.8625 9.2187 23.6001 9.2187 25.0751V34.2938C9.2187 35.0313 8.4812 36.1376 7.0062 36.1376Z"
      fill="currentColor"
    />
    <path
      className="multicolorElToWhite darkBgElToBrand"
      d="M51.625 59H9.58745C7.0062 59 5.16245 57.1562 5.16245 54.575V49.4125C5.16245 47.9375 5.89995 47.2 7.37495 47.2C8.84995 47.2 9.58745 47.9375 9.58745 49.4125V54.575H51.625V4.425H9.58745V9.5875C9.58745 11.0625 8.84995 11.8 7.37495 11.8C5.89995 12.1687 4.7937 11.0625 4.7937 9.5875V4.425C4.7937 2.2125 6.63745 0 9.2187 0H51.2562C53.8374 0 55.6812 1.84375 55.6812 4.425V54.2062C56.05 57.1562 54.2062 59 51.625 59Z"
      fill="currentColor"
    />
    <path
      className="multicolorElToDark darkBgElToWhite"
      d="M36.875 28.7625C38.7188 26.9188 39.825 24.7063 39.825 22.125C39.825 16.9625 35.7688 12.9062 30.6063 12.9062C25.4438 12.9062 21.0187 16.9625 21.0187 22.125C21.0187 24.7063 22.125 27.2875 23.9688 28.7625C19.5437 30.975 16.5938 35.7688 16.5938 40.9313V44.25C16.5938 45.725 17.7 46.8312 18.8062 46.8312H41.6688C43.1438 46.8312 44.25 45.725 44.25 44.25V40.9313C44.25 35.7688 41.3 30.975 36.875 28.7625ZM30.6063 17.3312C33.1875 17.3312 35.0312 19.5438 35.0312 21.7563C35.0312 23.9688 32.8188 26.55 30.6063 26.55C28.3938 26.55 25.8125 24.7063 25.8125 22.125C25.8125 19.5437 28.025 17.3312 30.6063 17.3312ZM39.4562 42.0375H21.3875V40.9313C21.3875 35.7688 25.4438 31.7125 30.6063 31.7125C35.7688 31.7125 39.825 35.7688 39.825 40.9313V42.0375H39.4562Z"
      fill="#FF8477"
    />
    <path
      className="multicolorElToWhite darkBgElToBrand"
      d="M12.1687 43.8813H2.2125C0.7375 43.8813 0 43.1438 0 41.6688C0 40.1938 0.7375 39.4563 2.2125 39.4563H12.1687C13.6438 39.4563 14.3813 40.1938 14.3813 41.6688C14.3813 42.7751 13.275 43.8813 12.1687 43.8813Z"
      fill="currentColor"
    />
    <path
      className="multicolorElToWhite darkBgElToBrand"
      d="M11.4312 19.9124H2.2125C0.7375 19.9124 0 18.8062 0 17.3312C0 15.8562 0.7375 15.1187 2.2125 15.1187H11.4312C12.9062 15.1187 13.6438 15.8562 13.6438 17.3312C13.6438 18.8062 12.9062 19.9124 11.4312 19.9124Z"
      fill="currentColor"
    />
  </Icon>
);

export const FORM_PADDING_TOP = { base: '118px', md: '156px' };
export const FORM_PADDING_TOP_XL = { base: '118px', md: '156px', '2xl': '256px' };
export const HEADER_TOP = { base: '16px', md: '32px' };
export const HEADER_TOP_XL = { base: '16px', md: '55px' };
export const HEADER_TYPE_RESET = 'reset';
export const HEADER_TYPE_LOGIN = 'login';
export const HEADER_TYPE_BACK = 'back';
export const EXPIRES_COOKIE_IN_DAYS = 30;
export const TWENTY_MB = 20971520; // 20971520 = 20MB
export const NUMBER_KEYS = {
  0: 'Zero',
  1: 'One',
  2: 'Two',
  3: 'Three',
  4: 'Four',
  5: 'Five',
  6: 'Six',
  7: 'Seven',
  8: 'Eight',
  9: 'Nine',
  10: 'Ten',
  11: 'Eleven',
  12: 'Twelve',
};

export const DEFAULT_IDS = ['label1', 'label2', 'label3', 'label4', 'label5', 'label6'];

export const DEFAULT_LABELS = [
  'Label 1',
  'Label 2',
  'Label 3',
  'Label 4',
  'Label 5',
  'Label 6',
];

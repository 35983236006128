import appsignal from '../appsignal';
import React, { useContext, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router';
import PassTemplateContext from '../store/client/PassTemplateContext';
import { useToast } from '@chakra-ui/react';
import CustomToast from '../common/CustomToast';
import { getErrorResponsePayload } from '../utils/ajax';
import { HttpContext } from '../context/HttpContext';

function UsePassTemplate({ children }) {
  const { uuid } = useParams();
  const { authAxios } = useContext(HttpContext);
  const navigate = useNavigate();
  const passTemplateCtx = useContext(PassTemplateContext);
  const toast = useToast();

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await authAxios.get(`passes/templates/${uuid}`);
        const data = response.data?.data;
        passTemplateCtx.updateAllData(data);
      } catch (onError) {
        appsignal.sendError(onError);
        console.log(onError);
        const { message, code } = getErrorResponsePayload(onError);
        code !== 401 &&
          toast({
            render: (props) => (
              <CustomToast
                status="error"
                title={message ? message : `Something went wrong`}
                description={!message && 'Please try again later'}
                onClose={props.onClose}
              />
            ),
          });
        navigate('/pass-templates', { replace: true });
      }
    };

    getData();
  }, [authAxios]);

  return <>{children}</>;
}

export default UsePassTemplate;

import appsignal from '../../../appsignal';
import {
  Box,
  Text,
  Divider,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  Flex,
  Button,
  Show,
  Hide,
  useToast,
  useColorModeValue,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import CustomToast from '../../../common/CustomToast';
import { useContext } from 'react';
import { getErrorResponsePayload } from '../../../utils/ajax';
import { MeContext } from '../../../context/MeContext';
import { HttpContext } from '../../../context/HttpContext';

function FormAccountDetails({ user }) {
  const toast = useToast();
  const modeBoxBg = useColorModeValue('gradient', 'secondaryDark');
  const modeTextBg = useColorModeValue('quaternaryBackground', 'primaryDark06');
  const modeBorderColor = useColorModeValue('primaryBackground', 'primaryDark');
  const modeFormControlBg = useColorModeValue('white', '');
  const modeLabelBg = useColorModeValue('#EFEFEF', 'primaryDark03');
  const meCtx = useContext(MeContext);
  const firstName = meCtx?.state?.account?.first_name;
  const lastName = meCtx?.state?.account?.last_name;
  const companyName = meCtx?.state?.account?.company_name;
  const phoneNumber = meCtx?.state?.account?.phone_number;
  const { authAxios } = useContext(HttpContext);

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required('Please enter your first name'),
    lastName: Yup.string().required('Please enter your last name'),
    companyName: Yup.string()
      .required('Please enter company name')
      .min(3, 'Please enter at least 3 characters'),
    phone: Yup.string(),
  });

  const formOptions = {
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
  };

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors, isSubmitting, isDirty, isValid },
  } = useForm(formOptions);

  const onSubmit = async (values) => {
    try {
      await authAxios.patch(`accounts/me`, {
        account: {
          phone_number: values.phone,
          first_name: values.firstName,
          last_name: values.lastName,
          company_name: values.companyName,
        },
      });
      // const user = res.data.data;
      // setAuthUser(user);
      toast({
        render: (props) => (
          <CustomToast
            status="success"
            title="Account details changed."
            description="You have successfully saved your account details."
            onClose={props.onClose}
            id={props.id}
          />
        ),
      });
      // setTimeout(() => {
      //   window.location.reload(true);
      // }, 1000);
    } catch (onError) {
      appsignal.sendError(onError);
      const { code, errors } = getErrorResponsePayload(onError);
      if (code !== 401) {
        errors
          ?.filter((error) => error)
          .forEach((error) => {
            toast({
              render: (props) => (
                <CustomToast
                  status="error"
                  title={error || 'Something went wrong'}
                  description={error ? '' : 'Please try again later'}
                  onClose={props.onClose}
                />
              ),
            });
          });
      }
    }
  };

  return (
    <Box className="autofillForDarkBg" w="full">
      <Box className="primaryBoxShadow" bg={modeBoxBg} borderRadius="15px">
        <form onSubmit={handleSubmit(onSubmit)}>
          <Text
            fontSize="18px"
            textStyle="headingFamilyMedium"
            p="14px 14px 14px 24px"
            bg={modeTextBg}
            borderRadius="15px 15px 0 0"
          >
            Account details
          </Text>
          <Divider borderColor={modeBorderColor} />

          <FormControl isRequired isInvalid={errors.firstName} bg={modeFormControlBg}>
            <Flex alignItems="center">
              <Hide below="2xl">
                <Box pl="24px" flexShrink={0} w="240px">
                  <FormLabel m={0}>First name</FormLabel>
                </Box>
              </Hide>
              <Box p="14px" w="full" bg={modeLabelBg}>
                <Show below="2xl">
                  <FormLabel>First name</FormLabel>
                </Show>
                <Input
                  defaultValue={firstName}
                  variant="filledForDarkBg"
                  id="firstName"
                  type="text"
                  placeholder="Enter first name"
                  {...register('firstName')}
                  onBlur={() => {
                    const value = watch('firstName');
                    if (value.trim() === '') {
                      setValue('firstName', '');
                    }
                  }}
                />
                <FormErrorMessage pt={2} pl={3}>
                  {errors.firstName?.message || errors.firstName}
                </FormErrorMessage>
              </Box>
            </Flex>
          </FormControl>
          <Divider borderColor={modeBorderColor} />

          <FormControl isRequired isInvalid={errors.lastName} bg={modeFormControlBg}>
            <Flex alignItems="center">
              <Hide below="2xl">
                <Box pl="24px" flexShrink={0} w="240px">
                  <FormLabel m={0}>Last name</FormLabel>
                </Box>
              </Hide>
              <Box p="14px" w="full" bg={modeLabelBg}>
                <Show below="2xl">
                  <FormLabel>Last name</FormLabel>
                </Show>
                <Input
                  defaultValue={lastName}
                  variant="filledForDarkBg"
                  id="lastName"
                  type="text"
                  placeholder="Enter last name"
                  {...register('lastName')}
                  onBlur={() => {
                    const value = watch('lastName');
                    if (value.trim() === '') {
                      setValue('lastName', '');
                    }
                  }}
                />
                <FormErrorMessage pt={2} pl={3}>
                  {errors.lastName?.message || errors.lastName}
                </FormErrorMessage>
              </Box>
            </Flex>
          </FormControl>
          <Divider borderColor={modeBorderColor} />

          <FormControl isRequired isInvalid={errors.companyName} bg={modeFormControlBg}>
            <Flex alignItems="center">
              <Hide below="2xl">
                <Box pl="24px" flexShrink={0} w="240px">
                  <FormLabel m={0}>Company name</FormLabel>
                </Box>
              </Hide>
              <Box p="14px" w="full" bg={modeLabelBg}>
                <Show below="2xl">
                  <FormLabel>Company name</FormLabel>
                </Show>
                <Input
                  defaultValue={companyName}
                  variant="filledForDarkBg"
                  id="companyName"
                  type="text"
                  placeholder="Enter company name"
                  {...register('companyName')}
                  onBlur={() => {
                    const value = watch('companyName');
                    if (value.trim() === '') {
                      setValue('companyName', '');
                    }
                  }}
                />
                <FormErrorMessage pt={2} pl={3}>
                  {errors.companyName?.message || errors.companyName}
                </FormErrorMessage>
              </Box>
            </Flex>
          </FormControl>
          <Divider borderColor={modeBorderColor} />

          <FormControl isInvalid={errors.phone} bg={modeFormControlBg}>
            <Flex alignItems="center">
              <Hide below="2xl">
                <Box pl="24px" flexShrink={0} w="240px">
                  <FormLabel m={0}>Phone number</FormLabel>
                </Box>
              </Hide>
              <Box p="14px" w="full" bg={modeLabelBg}>
                <Show below="2xl">
                  <FormLabel>Phone number</FormLabel>
                </Show>
                <Input
                  defaultValue={phoneNumber}
                  variant="filledForDarkBg"
                  id="phone"
                  type="text"
                  placeholder="Your phone number"
                  {...register('phone')}
                  onBlur={() => {
                    const value = watch('phone');
                    if (value.trim() === '') {
                      setValue('phone', '');
                    }
                  }}
                />
                <FormErrorMessage pt={2} pl={3}>
                  {errors.phone?.message || errors.phone}
                </FormErrorMessage>
              </Box>
            </Flex>
          </FormControl>
          <Divider borderColor={modeBorderColor} />

          <Flex
            w="full"
            justifyContent="flex-end"
            bg={modeTextBg}
            borderRadius="0 0 15px 15px"
            p="20px 14px"
          >
            <Button
              type="submit"
              isLoading={isSubmitting}
              disabled={!isDirty || !isValid || isSubmitting}
              alt="Save changes"
              size="sm"
            >
              Save changes
            </Button>
          </Flex>
        </form>
      </Box>
    </Box>
  );
}

export default FormAccountDetails;

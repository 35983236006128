import { PassTemplateContextProvider } from '../../store/client/PassTemplateContext';
import PassTemplateCreatePageForm from './PassTemplateCreatePageForm';

function PassTemplateCreatePage() {
  return (
    <PassTemplateContextProvider>
      <PassTemplateCreatePageForm />
    </PassTemplateContextProvider>
  );
}

export default PassTemplateCreatePage;

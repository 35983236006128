import { Icon } from '@chakra-ui/react';

export const CustomCalendarIcon = (props) => (
  <Icon viewBox="0 0 24 24" fill="none" {...props}>
    <path
      d="M19.5 3.74902H4.5C3.25736 3.74902 2.25 4.75638 2.25 5.99902V19.499C2.25 20.7417 3.25736 21.749 4.5 21.749H19.5C20.7426 21.749 21.75 20.7417 21.75 19.499V5.99902C21.75 4.75638 20.7426 3.74902 19.5 3.74902Z"
      stroke="url(#paint0_linear_1889_1851)"
      strokeWidth="1.5"
      strokeLinejoin="round"
    />
    <path
      d="M18.6478 3.74902H5.35219C3.64172 3.74902 2.25 5.1623 2.25 6.89902V9.74902H3C3 8.99902 3.75 8.24902 4.5 8.24902H19.5C20.25 8.24902 21 8.99902 21 9.74902H21.75V6.89902C21.75 5.1623 20.3583 3.74902 18.6478 3.74902Z"
      fill="url(#paint1_linear_1889_1851)"
    />
    <path
      d="M13.875 11.999C14.4963 11.999 15 11.4953 15 10.874C15 10.2527 14.4963 9.74902 13.875 9.74902C13.2537 9.74902 12.75 10.2527 12.75 10.874C12.75 11.4953 13.2537 11.999 13.875 11.999Z"
      fill="url(#paint2_linear_1889_1851)"
    />
    <path
      d="M17.625 11.999C18.2463 11.999 18.75 11.4953 18.75 10.874C18.75 10.2527 18.2463 9.74902 17.625 9.74902C17.0037 9.74902 16.5 10.2527 16.5 10.874C16.5 11.4953 17.0037 11.999 17.625 11.999Z"
      fill="url(#paint3_linear_1889_1851)"
    />
    <path
      d="M13.875 15.7483C14.4963 15.7483 15 15.2446 15 14.6233C15 14.002 14.4963 13.4983 13.875 13.4983C13.2537 13.4983 12.75 14.002 12.75 14.6233C12.75 15.2446 13.2537 15.7483 13.875 15.7483Z"
      fill="url(#paint4_linear_1889_1851)"
    />
    <path
      d="M17.625 15.7483C18.2463 15.7483 18.75 15.2446 18.75 14.6233C18.75 14.002 18.2463 13.4983 17.625 13.4983C17.0037 13.4983 16.5 14.002 16.5 14.6233C16.5 15.2446 17.0037 15.7483 17.625 15.7483Z"
      fill="url(#paint5_linear_1889_1851)"
    />
    <path
      d="M6.375 15.7483C6.99632 15.7483 7.5 15.2446 7.5 14.6233C7.5 14.002 6.99632 13.4983 6.375 13.4983C5.75368 13.4983 5.25 14.002 5.25 14.6233C5.25 15.2446 5.75368 15.7483 6.375 15.7483Z"
      fill="url(#paint6_linear_1889_1851)"
    />
    <path
      d="M10.125 15.7483C10.7463 15.7483 11.25 15.2446 11.25 14.6233C11.25 14.002 10.7463 13.4983 10.125 13.4983C9.50368 13.4983 9 14.002 9 14.6233C9 15.2446 9.50368 15.7483 10.125 15.7483Z"
      fill="url(#paint7_linear_1889_1851)"
    />
    <path
      d="M6.375 19.4991C6.99632 19.4991 7.5 18.9955 7.5 18.3741C7.5 17.7528 6.99632 17.2491 6.375 17.2491C5.75368 17.2491 5.25 17.7528 5.25 18.3741C5.25 18.9955 5.75368 19.4991 6.375 19.4991Z"
      fill="url(#paint8_linear_1889_1851)"
    />
    <path
      d="M10.125 19.4991C10.7463 19.4991 11.25 18.9955 11.25 18.3741C11.25 17.7528 10.7463 17.2491 10.125 17.2491C9.50368 17.2491 9 17.7528 9 18.3741C9 18.9955 9.50368 19.4991 10.125 19.4991Z"
      fill="url(#paint9_linear_1889_1851)"
    />
    <path
      d="M13.875 19.4991C14.4963 19.4991 15 18.9955 15 18.3741C15 17.7528 14.4963 17.2491 13.875 17.2491C13.2537 17.2491 12.75 17.7528 12.75 18.3741C12.75 18.9955 13.2537 19.4991 13.875 19.4991Z"
      fill="url(#paint10_linear_1889_1851)"
    />
    <path
      d="M6 2.2489V3.7489"
      stroke="url(#paint11_linear_1889_1851)"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18 2.2489V3.7489"
      stroke="url(#paint12_linear_1889_1851)"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <defs>
      <linearGradient
        id="paint0_linear_1889_1851"
        x1="2.75323"
        y1="21.749"
        x2="19.6881"
        y2="19.047"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.styleguide ? 'currentColor' : '#FF8477'} />
        <stop offset="1" stopColor={props.styleguide ? 'currentColor' : '#FF8477'} />
      </linearGradient>
      <linearGradient
        id="paint1_linear_1889_1851"
        x1="2.75323"
        y1="9.74902"
        x2="16.882"
        y2="2.98603"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.styleguide ? 'currentColor' : '#FF8477'} />
        <stop offset="1" stopColor={props.styleguide ? 'currentColor' : '#FF8477'} />
      </linearGradient>
      <linearGradient
        id="paint2_linear_1889_1851"
        x1="12.8081"
        y1="11.999"
        x2="14.7693"
        y2="11.7102"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.styleguide ? 'currentColor' : '#FF8477'} />
        <stop offset="1" stopColor={props.styleguide ? 'currentColor' : '#FF8477'} />
      </linearGradient>
      <linearGradient
        id="paint3_linear_1889_1851"
        x1="16.5581"
        y1="11.999"
        x2="18.5193"
        y2="11.7102"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.styleguide ? 'currentColor' : '#FF8477'} />
        <stop offset="1" stopColor={props.styleguide ? 'currentColor' : '#FF8477'} />
      </linearGradient>
      <linearGradient
        id="paint4_linear_1889_1851"
        x1="12.8081"
        y1="15.7483"
        x2="14.7693"
        y2="15.4594"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.styleguide ? 'currentColor' : '#FF8477'} />
        <stop offset="1" stopColor={props.styleguide ? 'currentColor' : '#FF8477'} />
      </linearGradient>
      <linearGradient
        id="paint5_linear_1889_1851"
        x1="16.5581"
        y1="15.7483"
        x2="18.5193"
        y2="15.4594"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.styleguide ? 'currentColor' : '#FF8477'} />
        <stop offset="1" stopColor={props.styleguide ? 'currentColor' : '#FF8477'} />
      </linearGradient>
      <linearGradient
        id="paint6_linear_1889_1851"
        x1="5.30806"
        y1="15.7483"
        x2="7.26929"
        y2="15.4594"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.styleguide ? 'currentColor' : '#FF8477'} />
        <stop offset="1" stopColor={props.styleguide ? 'currentColor' : '#FF8477'} />
      </linearGradient>
      <linearGradient
        id="paint7_linear_1889_1851"
        x1="9.05806"
        y1="15.7483"
        x2="11.0193"
        y2="15.4594"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.styleguide ? 'currentColor' : '#FF8477'} />
        <stop offset="1" stopColor={props.styleguide ? 'currentColor' : '#FF8477'} />
      </linearGradient>
      <linearGradient
        id="paint8_linear_1889_1851"
        x1="5.30806"
        y1="19.4991"
        x2="7.26929"
        y2="19.2103"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.styleguide ? 'currentColor' : '#FF8477'} />
        <stop offset="1" stopColor={props.styleguide ? 'currentColor' : '#FF8477'} />
      </linearGradient>
      <linearGradient
        id="paint9_linear_1889_1851"
        x1="9.05806"
        y1="19.4991"
        x2="11.0193"
        y2="19.2103"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.styleguide ? 'currentColor' : '#FF8477'} />
        <stop offset="1" stopColor={props.styleguide ? 'currentColor' : '#FF8477'} />
      </linearGradient>
      <linearGradient
        id="paint10_linear_1889_1851"
        x1="12.8081"
        y1="19.4991"
        x2="14.7693"
        y2="19.2103"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.styleguide ? 'currentColor' : '#FF8477'} />
        <stop offset="1" stopColor={props.styleguide ? 'currentColor' : '#FF8477'} />
      </linearGradient>
      <linearGradient
        id="paint11_linear_1889_1851"
        x1="6.02581"
        y1="3.7489"
        x2="6.90787"
        y2="3.66229"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.styleguide ? 'currentColor' : '#FF8477'} />
        <stop offset="1" stopColor={props.styleguide ? 'currentColor' : '#FF8477'} />
      </linearGradient>
      <linearGradient
        id="paint12_linear_1889_1851"
        x1="18.0258"
        y1="3.7489"
        x2="18.9079"
        y2="3.66229"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.styleguide ? 'currentColor' : '#FF8477'} />
        <stop offset="1" stopColor={props.styleguide ? 'currentColor' : '#FF8477'} />
      </linearGradient>
    </defs>
  </Icon>
);

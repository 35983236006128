import { Icon } from '@chakra-ui/react';

export const CustomAppleIcon = (props) => (
  <Icon viewBox="0 0 25 30" {...props}>
    <path
      fill="currentColor"
      d="M20.4011 15.9381C20.4432 20.478 24.3838 21.9888 24.4275 22.0081C24.3941 22.1146 23.7978 24.1611 22.3514 26.2749C21.101 28.1025 19.8033 29.9233 17.7589 29.961C15.7502 29.998 15.1043 28.7698 12.8077 28.7698C10.5118 28.7698 9.79416 29.9233 7.89266 29.998C5.91937 30.0727 4.41673 28.0218 3.15595 26.201C0.579703 22.4764 -1.38908 15.6762 1.2545 11.0859C2.56778 8.80638 4.9147 7.36288 7.46208 7.32586C9.39979 7.2889 11.2288 8.6295 12.4133 8.6295C13.5971 8.6295 15.8197 7.01731 18.1562 7.25408C19.1344 7.2948 21.8801 7.6492 23.6432 10.2299C23.5011 10.318 20.367 12.1425 20.4011 15.9381V15.9381ZM16.6259 4.78995C17.6735 3.52183 18.3786 1.7565 18.1862 0C16.6762 0.0606918 14.8501 1.00628 13.767 2.2737C12.7963 3.39605 11.9462 5.19246 12.1755 6.91419C13.8587 7.04442 15.5782 6.05887 16.6259 4.78995"
    />
  </Icon>
);

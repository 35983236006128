import appsignal from '../../appsignal';
import {
  Container,
  Heading,
  Divider,
  Flex,
  Spacer,
  useMediaQuery,
} from '@chakra-ui/react';
import Breadcrumbs from '../../common/Breadcrumbs';
import InfoBoxList from '../../components/client/dashboard/InfoBoxList';
import { useContext, useEffect, useRef } from 'react';
import { MeContext } from '../../context/MeContext';
import DashboardTimeFilter from '../../components/client/dashboard/DashboardTimeFilter';
import DashboardPassTemplateFilter from '../../components/client/dashboard/DashboardPassTemplateFilter';
import UseClientDashboardStats from '../../hooks/useClientDashboardStats';
import { useState } from 'react';
import DashboardNoPassTemplates from '../../components/client/dashboard/DashboardNoPassTemplates';
import DashboardPassTaps from '../../components/client/dashboard/DashboardPassTaps';
import DashboardEvents from '../../components/client/dashboard/DashboardEvents';
import { HttpContext } from '../../context/HttpContext';
import useOrganizationStatus from '../../hooks/useOrganisationStatus';

function ClientDashboardPage() {
  const meCtx = useContext(MeContext);
  const authAxios = useContext(HttpContext).authAxios;
  const { shouldRender, components } = useOrganizationStatus();
  const [loadingDashHeader, setLoadingDashHeader] = useState(true);
  const [loadingDashBody, setLoadingDashBody] = useState(true);
  const [getDataCount, setGetDataCount] = useState(0);
  const [eventsData, setEventsData] = useState([]);
  const templates = meCtx.state?.passTemplates;
  const [isSmallerThan1360px] = useMediaQuery('(max-width: 1360px)');
  const { filterEntity } = meCtx.state;

  const breadcrumbLinks = [
    {
      linkName: 'Home',
      linkUrl: '/',
    },
    {
      linkName: 'Dashboard',
      linkUrl: null,
    },
  ];

  useEffect(() => {
    if (!shouldRender) {
      return;
    }
    const getEventsData = async () => {
      try {
        let response;
        if (filterEntity) {
          response = await authAxios.get(
            `api/v1/entities/${filterEntity.uuid}/events?nextEvent=true`
          );
        } else {
          response = await authAxios.get(`api/v1/events?nextEvent=true`);
        }
        const totalEvents = response.data?.meta?.total;
        const nextEvent = response.data?.data;
        const passesIssued = response.data?.data?.meta?.passesCount;
        const eventsData = {
          totalEvents,
          nextEvent,
          passesIssued,
        };
        setEventsData(eventsData);
      } catch (error) {
        appsignal.sendError(error);
        console.log(error);
      }
    };

    getEventsData();
  }, [shouldRender, filterEntity]);

  return (
    <Container pb={6} overflow="visible">
      <Breadcrumbs breadcrumbLinks={breadcrumbLinks} />
      <Flex
        alignItems="center"
        mt="15px"
        py="9px"
        minH="54px"
        flexWrap="wrap"
        gap="10px"
      >
        <Heading>Dashboard</Heading>
        <Spacer />
        <Flex flexWrap="wrap" gap="10px">
          {shouldRender && (
            <>
              <>
                <DashboardPassTemplateFilter
                  loadingDashHeader={loadingDashHeader}
                  loadingDashBody={loadingDashBody}
                  setLoadingDashBody={setLoadingDashBody}
                  setLoadingDashHeader={setLoadingDashHeader}
                  getDataCount={getDataCount}
                  setGetDataCount={setGetDataCount}
                />
                <DashboardTimeFilter
                  loadingDashHeader={loadingDashHeader}
                  loadingDashBody={loadingDashBody}
                  setLoadingDashBody={setLoadingDashBody}
                  setLoadingDashHeader={setLoadingDashHeader}
                  getDataCount={getDataCount}
                  setGetDataCount={setGetDataCount}
                />
              </>
            </>
          )}
        </Flex>
      </Flex>
      <Divider borderColor="primaryBackground" mb={6} />

      {components[0]}

      <UseClientDashboardStats
        loadingDashHeader={loadingDashHeader}
        loadingDashBody={loadingDashBody}
        setLoadingDashBody={setLoadingDashBody}
        setLoadingDashHeader={setLoadingDashHeader}
        getDataCount={getDataCount}
        setGetDataCount={setGetDataCount}
      >
        <Flex
          direction={isSmallerThan1360px ? 'column' : 'row'}
          gap={8}
          width="100%"
        >
          <Flex direction="column" gap={5} width="full">
            <InfoBoxList />
            {!templates?.length && shouldRender && <DashboardNoPassTemplates />}
          </Flex>
          <Flex direction="column" gap={5} width="full">
            {eventsData.totalEvents > 0 && (
              <DashboardEvents eventsData={eventsData} />
            )}
            <DashboardPassTaps />
          </Flex>
        </Flex>
      </UseClientDashboardStats>
    </Container>
  );
}

export default ClientDashboardPage;

import { Icon } from '@chakra-ui/react';
import { motion } from 'framer-motion';

const pathVariants = {
  hidden: {
    opacity: 0,
    pathLength: 0,
  },
  visible: {
    opacity: 1,
    pathLength: 1,
    transition: {
      duration: 0.9,
      ease: 'easeInOut',
    },
  },
};

export const CustomWalletIconStrokeAnimated = (props) => (
  <Icon viewBox="0 0 28 28" {...props} fill="transparent">
    <motion.path
      d="M1.25 7.66907H22.9996C25.0707 7.66907 26.7496 9.348 26.7496 11.4191V22.6987C26.7496 24.7698 25.0707 26.4487 22.9996 26.4487H5C2.92893 26.4487 1.25 24.7698 1.25 22.6987V7.66907Z"
      stroke="#FF8477"
      strokeWidth="2.5"
      variants={pathVariants}
      initial="hidden"
      animate="visible"
    />
    <motion.path
      d="M1.25708 6.60992L18.8535 1.71282C21.4027 1.00337 23.9259 2.92027 23.9259 5.56637V7.47518"
      stroke="#FF8477"
      strokeWidth="2.5"
      strokeLinecap="round"
      variants={pathVariants}
      initial="hidden"
      animate="visible"
    />
    <mask id="path-3-inside-1_3818_17406" fill="white" initial="hidden" animate="visible">
      <motion.path d="M14.5464 17.0196C14.5464 14.5936 16.5131 12.627 18.9391 12.627H26.9997C27.552 12.627 27.9997 13.0747 27.9997 13.627V20.4123C27.9997 20.9646 27.552 21.4123 26.9997 21.4123H18.9391C16.5131 21.4123 14.5464 19.4456 14.5464 17.0196Z" />
    </mask>
    <motion.path
      d="M14.5464 17.0196C14.5464 14.5936 16.5131 12.627 18.9391 12.627H26.9997C27.552 12.627 27.9997 13.0747 27.9997 13.627V20.4123C27.9997 20.9646 27.552 21.4123 26.9997 21.4123H18.9391C16.5131 21.4123 14.5464 19.4456 14.5464 17.0196Z"
      stroke="#fff"
      strokeWidth="5"
      mask="url(#path-3-inside-1_3818_17406)"
      variants={pathVariants}
      initial="hidden"
      animate="visible"
    />
  </Icon>
);

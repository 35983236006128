import { Icon } from '@chakra-ui/react';

export const CustomExclamationMarkTriangleIcon = (props) => (
  <Icon viewBox="0 0 20 18" {...props}>
    <path
      fill="currentColor"
      d="M19.051 15.7081L11.0621 0.872158C10.4958 -0.179717 8.98741 -0.179717 8.42069 0.872158L0.432255 15.7081C0.309307 15.9364 0.247667 16.1928 0.253351 16.4521C0.259035 16.7113 0.331848 16.9647 0.464683 17.1875C0.597519 17.4102 0.785838 17.5947 1.01126 17.723C1.23668 17.8512 1.4915 17.9188 1.75085 17.9192H17.7301C17.9896 17.9192 18.2447 17.8519 18.4705 17.7238C18.6962 17.5957 18.8849 17.4113 19.018 17.1885C19.1511 16.9657 19.2241 16.7121 19.2299 16.4526C19.2357 16.1932 19.174 15.9366 19.051 15.7081ZM9.74162 15.6223C9.5562 15.6223 9.37495 15.5673 9.22078 15.4643C9.06661 15.3613 8.94645 15.2149 8.87549 15.0436C8.80453 14.8723 8.78596 14.6838 8.82214 14.5019C8.85831 14.3201 8.9476 14.153 9.07871 14.0219C9.20982 13.8908 9.37687 13.8015 9.55873 13.7653C9.74058 13.7291 9.92908 13.7477 10.1004 13.8187C10.2717 13.8896 10.4181 14.0098 10.5211 14.164C10.6241 14.3181 10.6791 14.4994 10.6791 14.6848C10.6791 14.8079 10.6549 14.9298 10.6078 15.0436C10.5606 15.1573 10.4916 15.2607 10.4045 15.3477C10.3175 15.4348 10.2141 15.5038 10.1004 15.5509C9.98665 15.5981 9.86474 15.6223 9.74162 15.6223ZM10.7597 6.1934L10.4907 11.9122C10.4907 12.1111 10.4117 12.3018 10.271 12.4425C10.1304 12.5831 9.9396 12.6622 9.74069 12.6622C9.54177 12.6622 9.35101 12.5831 9.21036 12.4425C9.06971 12.3018 8.99069 12.1111 8.99069 11.9122L8.72163 6.19575C8.71558 6.05915 8.73709 5.92273 8.78489 5.79463C8.83269 5.66652 8.90579 5.54935 8.99984 5.4501C9.09389 5.35084 9.20695 5.27154 9.3323 5.21692C9.45765 5.1623 9.59271 5.13347 9.72944 5.13216H9.73928C9.87694 5.13208 10.0132 5.1599 10.1398 5.21394C10.2664 5.26797 10.3808 5.34709 10.4759 5.44653C10.5711 5.54597 10.6452 5.66367 10.6937 5.79252C10.7421 5.92136 10.764 6.05869 10.7579 6.19622L10.7597 6.1934Z"
    />
  </Icon>
);

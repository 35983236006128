import {
  Heading,
  Flex,
  Box,
  Image,
  Text,
  Center,
  useDisclosure,
  HStack,
} from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import PreviewPassTemplateApple from '../../../components/client/pass-templates/PreviewPassTemplateApple';
import viewEditSrc from '../../../assets/vectors/view-edit.svg';
import DeletePassTemplateModal from '../../modals/DeletePassTemplateModal';
import DuplicateButton from './DuplicateButton';
import DeleteTemplateButton from './DeleteTemplateButton';

function PassTemplatesListItem({ previewData }) {
  const { isOpen, onClose } = useDisclosure();
  return (
    <>
      <Box pos="relative">
        <PreviewPassTemplateApple
          previewData={previewData}
          cutoutBorderGrey
          templatesPage
        />
        <Flex
          pos="absolute"
          top="0"
          left="0"
          right="0"
          bottom="0"
          bg="rgba(41, 50, 58, 0.8)"
          // borderRadius="10px"
          color="white"
          alignItems="center"
          justifyContent="center"
          opacity="0"
          transition="0.3s"
          _hover={{ opacity: 1 }}
        >
          <Center
            flexDir="column"
            as={RouterLink}
            to={previewData.editUrl}
            role="group"
          >
            <Image w="64px" src={viewEditSrc} alt="" />
            <Text
              fontSize="13px"
              textStyle="headingFamilyMedium"
              mt="10px"
              _groupHover={{ textDecoration: 'underline' }}
            >
              View
              <Box as="span" color="brand">
                /
              </Box>
              Edit
            </Text>
          </Center>
        </Flex>
      </Box>
      <HStack
        justifyContent="center"
        alignItems="flex-start"
        alignContent="center"
      >
        <Box width="52px" />
        <Heading fontSize="18px" textAlign="center" mt={4} flex={1}>
          {previewData.templateName}
        </Heading>
        <Flex mt={4} gap={2} pr={2} data-testid="action-buttons-container">
          <>
            {previewData.passType !== 'stampCard' && (
              <DuplicateButton templateData={previewData} />
            )}
          </>
          <DeleteTemplateButton previewData={previewData} />
        </Flex>
      </HStack>
      <DeletePassTemplateModal
        isOpen={isOpen}
        onClose={onClose}
        portalProps={{ deletePassTemplateUrl: 'passes/templates' }}
      />
    </>
  );
}

export default PassTemplatesListItem;

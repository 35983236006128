import appsignal from '../../appsignal';
import {
  useMemo,
  useContext,
  useRef,
  useCallback,
  useState,
  useEffect,
} from 'react';
import { useDisclosure } from '@chakra-ui/react';
import MessagingHistoryTable from '../../components/client/messages/MessagingHistoryTable';
import MessagingHistoryContext from '../../store/client/MessagingHistoryContext';
import CustomToast from '../../common/CustomToast';
import { useToast } from '@chakra-ui/react';
import { HttpContext } from '../../context/HttpContext';
import moment from 'moment';
import AlertDialogWrapper from '../../components/common/AlertDialogWrapper';

const MessagingHistoryPage = ({
  filterEntity,
  activeTab,
  status = '',
  heading = 'Message Batches',
  setTabIndex,
}) => {
  const { authAxios } = useContext(HttpContext);
  const toast = useToast();
  const fetchIdRef = useRef(0);
  const {
    dataState,

    updateDataState,
  } = useContext(MessagingHistoryContext);
  const [cancelId, setCancelId] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const columns = useMemo(() => {
    const baseColumns = [
      {
        Header: 'Name',
        accessor: 'name',
        style: {
          fontFamily: 'Inter Bold, sans-serif',

          minWidth: '150px',
          width: '250px',
        },
      },
      {
        Header: 'Message Type',
        accessor: 'messageType',
        style: {
          minWidth: '100px',
          width: '200px',
          textAlign: 'center',
        },
      },
      {
        Header: 'Content',
        accessor: 'content',
        style: {
          minWidth: '200px',
          width: '350px',
        },
      },
      {
        Header: 'Recipients',
        accessor: 'recipients',
        style: {
          minWidth: '70px',
          width: '200px',
          textAlign: 'center',
        },
      },
    ];

    if (status === 'scheduled') {
      baseColumns.push(
        {
          Header: 'Scheduled At',
          accessor: 'scheduledAt',
          style: {
            minWidth: '120px',
            width: '200px',
          },
        },
        {
          Header: '',
          accessor: 'cancelAction',
          style: { minWidth: '100px', width: '150px', wordBreak: 'break-word' },
        }
      );
    } else {
      baseColumns.push(
        {
          Header: 'Status',
          accessor: 'status',
          style: {
            minWidth: '100px',
            width: '250px',
          },
        },
        {
          Header: 'Errors',
          accessor: 'errors',
          setTabIndex: setTabIndex,
          style: {
            minWidth: '70px',
            width: '200px',
            textAlign: 'center',
          },
        },
        {
          Header: 'Completed At',
          accessor: 'completedAt',
          style: {
            minWidth: '150px',
            width: '200px',
          },
        }
      );
    }
    baseColumns.push({
      Header: '',
      accessor: 'moreInfo',
      style: {
        minWidth: '50px',
        width: '50px',
      },
    });
    return baseColumns;
  }, [status]);

  const fetchData = useCallback(
    ({ pageSize, pageIndex }) => {
      const fetchId = ++fetchIdRef.current;

      updateDataState({
        loading: true,
        status: 'pending',
      });

      setTimeout(async () => {
        if (fetchId === fetchIdRef.current) {
          try {
            const statusQuery = status ? `&status=${status}` : '';
            const response = await authAxios.get(
              `/api/v1/entities/${filterEntity?.uuid}/messaging/history?per_page=${pageSize}&page=${pageIndex + 1}${statusQuery}`
            );
            const batches = response?.data?.data || [];
            const total = response?.data?.meta?.totalCount ?? 0;

            updateDataState({
              items: batches,
              total,
              pageCount: Math.ceil(total / pageSize),
              loading: false,
              status: 'resolved',
            });
          } catch (error) {
            appsignal.sendError(error);
            updateDataState({
              items: [],
              total: 0,
              pageCount: 0,
              loading: false,
              status: 'rejected',
            });
          }
        }
      }, 0);
    },
    [authAxios, filterEntity, status]
  );

  useEffect(() => {
    if (activeTab === 0 || activeTab === 2) {
      fetchData({ pageSize: 10, pageIndex: 0 });
    }
  }, [activeTab, fetchData]);

  const handleCancel = useCallback(
    (id) => {
      setCancelId(id);
      onOpen();
    },
    [onOpen]
  );

  const handleConfirmCancel = useCallback(() => {
    authAxios
      .patch(`/api/v1/messaging/history/${cancelId}/cancel`)
      .then((response) => {
        toast({
          render: (props) => (
            <CustomToast
              status="success"
              title={response?.data?.message}
              description=""
              onClose={props.onClose}
              id={props.id}
            />
          ),
        });
        fetchData({ pageSize: 10, pageIndex: 0 });
      })
      .catch((error) => {
        appsignal.sendError(error);
        toast({
          render: (props) => (
            <CustomToast
              status="error"
              title="Something went wrong"
              description={error?.response?.data?.message}
              onClose={props.onClose}
              id={props.id}
            />
          ),
        });
      })
      .finally(() => {
        onClose();
        setCancelId(null);
      });
  }, [authAxios, cancelId, onClose]);

  const normalizedData = useMemo(() => {
    if (dataState.items.length > 0) {
      return dataState.items.map((item) => ({
        id: item.id,
        name: item.attributes.name,
        messageType: item.attributes.messageType,
        status: item.attributes.status,
        tag: item.attributes.tag,
        content: item.attributes.content,
        errorMessage: item.attributes.errorMessage,
        mediaUrl: item.attributes.mediaUrl,
        scheduledAt: item.attributes.scheduledAt
          ? moment(item.attributes.scheduledAt).format('MMM D, YYYY HH:mm')
          : null,
        completedAt: item.attributes.completedAt
          ? moment(item.attributes.completedAt).format('MMM D, YYYY HH:mm')
          : null,
        recipients: item.attributes.totalRecipients,
        errors: item.attributes.errorCount,
      }));
    }
    return [];
  }, [dataState.items]);

  return (
    <>
      <MessagingHistoryTable
        columns={columns}
        heading={heading}
        cancel={handleCancel}
        activeTab={activeTab}
        data={normalizedData}
        fetchData={fetchData}
        loading={dataState.loading}
        pageCount={dataState.pageCount}
        total={dataState.total}
        filterEntity={filterEntity}
        tableType={status}
      />
      <AlertDialogWrapper
        isOpen={isOpen}
        onClose={() => {
          onClose();
          setCancelId(null);
        }}
        onConfirm={handleConfirmCancel}
        title="Cancel Message Batch"
        body="Are you sure you want to cancel this message batch? You won't be able to undo this action."
        buttonText="Cancel Batch"
      />
    </>
  );
};

export default MessagingHistoryPage;

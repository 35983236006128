import appsignal from '../../appsignal';
import {
  useMemo,
  useContext,
  useRef,
  useCallback,
  useState,
  useEffect,
} from 'react';
import { useDisclosure, useToast } from '@chakra-ui/react';
import MessagingLogsTable from '../../components/client/messages/MessagingLogsTable';
import MessagingLogsContext from '../../store/client/MessagingLogsContext';
import CustomToast from '../../common/CustomToast';
import { HttpContext } from '../../context/HttpContext';
import moment from 'moment';
import AlertDialogWrapper from '../../components/common/AlertDialogWrapper';

const FailedMessagesPage = ({ filterEntity, activeTab }) => {
  const { authAxios } = useContext(HttpContext);
  const toast = useToast();
  const fetchIdRef = useRef(0);
  const [resendId, setResendId] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { dataState, updateDataState } = useContext(MessagingLogsContext);

  const columns = useMemo(
    () => [
      {
        Header: 'Message Batch',
        accessor: 'messageBatchName',
        style: {
          wordBreak: 'break-word',
          minWidth: '150px',
          width: '250px',
        },
      },
      {
        Header: 'Message Type',
        accessor: 'messageType',
        style: {
          minWidth: '100px',
          width: '200px',
          wordBreak: 'break-word',
        },
      },
      {
        Header: 'Status',
        accessor: 'status',
        style: {
          minWidth: '100px',
          width: '250px',
          wordBreak: 'break-word',
        },
      },
      {
        Header: 'Error Message',
        accessor: 'errorMessage',
        style: {
          minWidth: '200px',
          width: '300px',
          wordBreak: 'break-word',
        },
      },
      {
        Header: 'Recipient',
        accessor: 'recipient',
        style: {
          minWidth: '150px',
          width: '300px',
          wordBreak: 'break-word',
        },
      },
      {
        Header: 'Failed At',
        accessor: 'failedAt',
        style: {
          minWidth: '120px',
          width: '250px',
          wordBreak: 'break-word',
        },
      },
      {
        Header: 'Resend Attempts',
        accessor: 'resendAttempts',
        style: {
          minWidth: '150px',
          width: '250px',
          wordBreak: 'break-word',
        },
      },
      {
        Header: '',
        accessor: 'resendAction',
        style: {
          minWidth: '50px',
          width: '50px',
          wordBreak: 'break-word',
        },
      },
      {
        Header: '',
        accessor: 'moreInfo',
        style: {
          minWidth: '50px',
          width: '50px',
          wordBreak: 'break-word',
        },
      },
    ],
    []
  );

  const handleConfirmResend = useCallback(() => {
    authAxios
      .post(`/api/v1/messaging/logs/${resendId}/resend`)
      .then((response) => {
        toast({
          render: (props) => (
            <CustomToast
              status="success"
              title={response?.data?.message}
              description=""
              onClose={props.onClose}
              id={props.id}
            />
          ),
        });
        fetchData({
          pageSize: 10,
          pageIndex: 0,
          statusFilter: ['failed', 'cancellation_failed', 'undelivered'],
        });
      })
      .catch((error) => {
        appsignal.sendError(error);
        toast({
          render: (props) => (
            <CustomToast
              status="error"
              title="Something went wrong"
              description={error?.response?.data?.message}
              onClose={props.onClose}
              id={props.id}
            />
          ),
        });
      })
      .finally(() => {
        onClose();
        setResendId(null);
      });
  }, [resendId]);

  const handleResend = useCallback((id) => {
    setResendId(id);
    onOpen();
  }, []);

  const fetchData = useCallback(
    ({ pageSize, pageIndex, statusFilter }) => {
      const fetchId = ++fetchIdRef.current;

      updateDataState({
        loading: true,
        status: 'pending',
      });

      setTimeout(async () => {
        if (fetchId === fetchIdRef.current) {
          try {
            const params = new URLSearchParams();
            params.append('per_page', pageSize);
            params.append('page', pageIndex + 1);

            if (statusFilter && statusFilter.length > 0) {
              statusFilter.forEach((status) => {
                params.append('status[]', status);
              });
            } else {
              params.append('status', 'failed');
            }

            const response = await authAxios.get(
              `/api/v1/entities/${filterEntity?.uuid}/messaging/logs?${params.toString()}`
            );

            const logs = response?.data?.data || [];
            const total = response?.data?.meta?.totalCount ?? 0;

            updateDataState({
              items: logs,
              total,
              pageCount: Math.ceil(total / pageSize),
              loading: false,
              status: 'resolved',
            });
          } catch (error) {
            appsignal.sendError(error);
            updateDataState({
              items: [],
              total: 0,
              pageCount: 0,
              loading: false,
              status: 'rejected',
            });
          }
        }
      }, 0);
    },
    [authAxios, filterEntity]
  );

  useEffect(() => {
    if (activeTab === 1) {
      fetchData({
        pageSize: 10,
        pageIndex: 0,
        statusFilter: ['failed', 'cancellation_failed', 'undelivered'],
      });
    }
  }, [activeTab, fetchData]);

  const normalizedData = useMemo(() => {
    if (dataState.items.length > 0) {
      return dataState.items.map((item) => ({
        id: item.id,
        messageBatchId: item.attributes.messageBatchId,
        messageBatchName: item.attributes.messageBatchName,
        messageType: item.attributes.messageType.toUpperCase(),
        status: item.attributes.status,
        content: item.attributes.content,
        errorMessage: item.attributes.errorMessage,
        recipient:
          item.attributes.messageType === 'notification'
            ? item.attributes.passId
            : item.attributes.mobileNumber,
        failedAt: item.attributes.failedAt
          ? moment(item.attributes.failedAt).format('MMM D, YYYY HH:mm')
          : '',
        resendAttempts: item.attributes.resendAttempts,
        resendable: item.attributes.resendable,
      }));
    }
    return [];
  }, [dataState.items]);

  return (
    <>
      <MessagingLogsTable
        columns={columns}
        data={normalizedData}
        fetchData={fetchData}
        resend={handleResend}
        loading={dataState.loading}
        pageCount={dataState.pageCount}
        total={dataState.total}
        filterEntity={filterEntity}
      />
      <AlertDialogWrapper
        isOpen={isOpen}
        onClose={() => {
          onClose();
          setResendId(null);
        }}
        onConfirm={handleConfirmResend}
        title="Resend Message"
        body="Are you sure you want to resend this message? You won't be able to undo this action."
        buttonText="Resend"
      />
    </>
  );
};

export default FailedMessagesPage;

import { Icon } from '@chakra-ui/react';

export const CustomReadersMultiIcon = (props) => (
  <Icon viewBox="0 0 64 64" {...props}>
    <path
      className="multicolorElToWhite darkBgElToBrand"
      d="M50.564 3.00001C50.564 1.81259 51.5266 0.850006 52.714 0.850006H60.9999C62.1873 0.850006 63.1499 1.81259 63.1499 3.00001V11.2859C63.1499 12.4734 62.1873 13.4359 60.9999 13.4359C59.8125 13.4359 58.8499 12.4734 58.8499 11.2859V5.15001H52.714C51.5266 5.15001 50.564 4.18742 50.564 3.00001Z"
      fill="#29323A"
    />
    <path
      className="multicolorElToWhite darkBgElToBrand"
      d="M0.850098 3.00001C0.850098 1.81259 1.81269 0.850006 3.0001 0.850006H11.286C12.4734 0.850006 13.436 1.81259 13.436 3.00001C13.436 4.18742 12.4734 5.15001 11.286 5.15001H5.1501V11.2859C5.1501 12.4734 4.18751 13.4359 3.0001 13.4359C1.81269 13.4359 0.850098 12.4734 0.850098 11.2859V3.00001Z"
      fill="#29323A"
    />
    <path
      className="multicolorElToWhite darkBgElToBrand"
      d="M3.0001 50.5656C4.18751 50.5656 5.1501 51.5281 5.1501 52.7156V58.8515H11.286C12.4734 58.8515 13.436 59.8141 13.436 61.0015C13.436 62.1889 12.4734 63.1515 11.286 63.1515H3.0001C1.81269 63.1515 0.850098 62.1889 0.850098 61.0015V52.7156C0.850098 51.5281 1.81269 50.5656 3.0001 50.5656Z"
      fill="#29323A"
    />
    <path
      className="multicolorElToWhite darkBgElToBrand"
      d="M60.9999 50.5656C62.1873 50.5656 63.1499 51.5282 63.1499 52.7156V61.0015C63.1499 62.189 62.1873 63.1515 60.9999 63.1515H52.714C51.5266 63.1515 50.564 62.189 50.564 61.0015C50.564 59.8141 51.5266 58.8515 52.714 58.8515H58.8499V52.7156C58.8499 51.5282 59.8125 50.5656 60.9999 50.5656Z"
      fill="#29323A"
    />
    <path
      className="multicolorElToWhite darkBgElToBrand"
      d="M7.06299 9.21448C7.06299 8.02707 8.02558 7.06448 9.21299 7.06448H25.7849C26.9723 7.06448 27.9349 8.02707 27.9349 9.21448V25.7863C27.9349 26.9738 26.9723 27.9363 25.7849 27.9363H9.21299C8.02558 27.9363 7.06299 26.9738 7.06299 25.7863V9.21448ZM11.363 11.3645V23.6363H23.6349V11.3645H11.363Z"
      fill="#29323A"
    />
    <path
      className="multicolorElToWhite darkBgElToBrand"
      d="M36.0637 9.21448C36.0637 8.02707 37.0263 7.06448 38.2137 7.06448H54.7856C55.973 7.06448 56.9356 8.02707 56.9356 9.21448V25.7863C56.9356 26.9738 55.973 27.9363 54.7856 27.9363H38.2137C37.0263 27.9363 36.0637 26.9738 36.0637 25.7863V9.21448ZM40.3637 11.3645V23.6363H52.6356V11.3645H40.3637Z"
      fill="#29323A"
    />
    <path
      className="multicolorElToWhite darkBgElToBrand"
      d="M7.06299 38.2152C7.06299 37.0278 8.02558 36.0652 9.21299 36.0652H25.7849C26.9723 36.0652 27.9349 37.0278 27.9349 38.2152V54.7871C27.9349 55.9745 26.9723 56.9371 25.7849 56.9371H9.21299C8.02558 56.9371 7.06299 55.9745 7.06299 54.7871V38.2152ZM11.363 40.3652V52.6371H23.6349V40.3652H11.363Z"
      fill="#29323A"
    />
    <path
      className="multicolorElToDark darkBgElToWhite"
      d="M36.0637 38.2152C36.0637 37.0278 37.0263 36.0652 38.2137 36.0652H46.4997C47.6871 36.0652 48.6497 37.0278 48.6497 38.2152C48.6497 39.4027 47.6871 40.3652 46.4997 40.3652H40.3637V46.5012C40.3637 47.6886 39.4011 48.6512 38.2137 48.6512C37.0263 48.6512 36.0637 47.6886 36.0637 46.5012V38.2152Z"
      fill="#FF8477"
    />
    <path
      className="multicolorElToDark darkBgElToWhite"
      d="M54.787 44.3512C55.9744 44.3512 56.937 45.3138 56.937 46.5012V54.7871C56.937 55.9745 55.9744 56.9371 54.787 56.9371H46.5011C45.3137 56.9371 44.3511 55.9745 44.3511 54.7871C44.3511 53.5997 45.3137 52.6371 46.5011 52.6371H52.637V46.5012C52.637 45.3138 53.5996 44.3512 54.787 44.3512Z"
      fill="#FF8477"
    />
    <path
      className="multicolorElToDark darkBgElToWhite"
      d="M42.2803 44.4297C42.2803 43.2423 43.2429 42.2797 44.4303 42.2797H48.5732C49.7607 42.2797 50.7232 43.2423 50.7232 44.4297V48.5727C50.7232 49.7601 49.7607 50.7227 48.5732 50.7227H44.4303C43.2429 50.7227 42.2803 49.7601 42.2803 48.5727V44.4297Z"
      fill="#FF8477"
    />
  </Icon>
);

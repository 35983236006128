import { motion } from 'framer-motion';
import { Icon } from '@chakra-ui/react';

const ThankYouIconAnimated = () => {
  return (
    <Icon viewBox="0 0 195 161" width="195px" height="161px">
      <circle cx="97" cy="80.75" r="80" fill="#D1D1D1" />
      <motion.path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.91198 94.9228C5.91198 95.5481 6.41898 96.0549 7.04439 96.0549C7.66981 96.0549 8.17681 95.5481 8.17681 94.9228L8.17681 90.8831L12.2178 90.8831C12.8432 90.8831 13.3502 90.3762 13.3502 89.751C13.3502 89.1258 12.8432 88.6189 12.2178 88.6189H8.17681L8.17681 84.5791C8.17681 83.9539 7.66981 83.4471 7.04439 83.4471C6.41898 83.4471 5.91198 83.9539 5.91198 84.5791L5.91198 88.6189L1.87104 88.6189C1.24563 88.6189 0.738629 89.1258 0.738629 89.751C0.738629 90.3762 1.24563 90.8831 1.87104 90.8831L5.91198 90.8831L5.91198 94.9228Z"
        fill="#3B4851"
        fillOpacity="0.8"
        animate={{
          opacity: [0, 1],
          x: [20, 0],
          scale: [0.5, 1],
        }}
        transition={{ delay: 0, duration: 1, ease: 'easeInOut' }}
      />
      <motion.path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M189.325 64.323C189.325 64.7717 189.689 65.1355 190.138 65.1355C190.587 65.1355 190.95 64.7717 190.95 64.323V61.4238L193.851 61.4238C194.299 61.4238 194.663 61.0601 194.663 60.6113C194.663 60.1626 194.299 59.7989 193.851 59.7989H190.95L190.95 56.8995C190.95 56.4507 190.587 56.087 190.138 56.087C189.689 56.087 189.325 56.4507 189.325 56.8995L189.325 59.7989L186.425 59.7989C185.976 59.7989 185.612 60.1626 185.612 60.6113C185.612 61.0601 185.976 61.4238 186.425 61.4238L189.325 61.4238L189.325 64.323Z"
        fill="#3B4851"
        fillOpacity="0.6"
        animate={{
          opacity: [0, 1],
          x: [-30, 0],
          y: [8, 0],
          scale: [0.5, 1],
        }}
        transition={{ delay: 0.5, duration: 1, ease: 'easeInOut' }}
      />
      <motion.rect
        width="11.9493"
        height="2.54795"
        rx="1.27398"
        //transform="matrix(-0.707212 -0.707001 0.707212 -0.707001 36.4512 32)"
        fill="#3B4851"
        fillOpacity="0.6"
        animate={{
          opacity: [0, 1],
          x: [36.4512, 28],
          y: [32, 26],
          scale: [0.5, 1],
          rotate: [45, 45],
        }}
        transition={{ delay: 0.3, duration: 1, ease: 'easeInOut' }}
      />
      <motion.path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.8177 48.9574C14.735 48.9574 15.4785 48.214 15.4785 47.297C15.4785 46.38 14.735 45.6366 13.8177 45.6366C12.9004 45.6366 12.1568 46.38 12.1568 47.297C12.1568 48.214 12.9004 48.9574 13.8177 48.9574ZM13.8177 51.0706C15.9024 51.0706 17.5924 49.3811 17.5924 47.297C17.5924 45.2129 15.9024 43.5234 13.8177 43.5234C11.733 43.5234 10.043 45.2129 10.043 47.297C10.043 49.3811 11.733 51.0706 13.8177 51.0706Z"
        fill="#FF8477"
        animate={{
          opacity: [0, 1],
          x: [20, 0],
          y: [5, 0],
          scale: [0.5, 1],
        }}
        transition={{ delay: 0.1, duration: 1, ease: 'easeInOut' }}
      />
      <motion.path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.5297 128.788C33.3635 128.788 34.0395 128.112 34.0395 127.278C34.0395 126.445 33.3635 125.769 32.5297 125.769C31.6958 125.769 31.0198 126.445 31.0198 127.278C31.0198 128.112 31.6958 128.788 32.5297 128.788ZM32.5297 131.807C35.0313 131.807 37.0593 129.779 37.0593 127.278C37.0593 124.777 35.0313 122.75 32.5297 122.75C30.028 122.75 28 124.777 28 127.278C28 129.779 30.028 131.807 32.5297 131.807Z"
        fill="#3B4851"
        fillOpacity="0.6"
        animate={{
          opacity: [0, 1],
          x: [20, 0],
          y: [-5, 0],
          scale: [0.5, 1],
        }}
        transition={{ delay: 0.1, duration: 1, ease: 'easeInOut' }}
      />
      <motion.path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M160.285 131.297C161.535 131.297 162.549 130.283 162.549 129.033C162.549 127.783 161.535 126.769 160.285 126.769C159.034 126.769 158.02 127.783 158.02 129.033C158.02 130.283 159.034 131.297 160.285 131.297ZM160.285 134.316C163.203 134.316 165.569 131.951 165.569 129.033C165.569 126.115 163.203 123.75 160.285 123.75C157.366 123.75 155 126.115 155 129.033C155 131.951 157.366 134.316 160.285 134.316Z"
        fill="#FF8477"
        animate={{
          opacity: [0, 1],
          x: [-20, 0],
          y: [-10, 0],
          scale: [0.5, 1],
        }}
        transition={{ delay: 0.3, duration: 1, ease: 'easeInOut' }}
      />
      <mask
        id="mask0_3562_8660"
        maskUnits="userSpaceOnUse"
        x="17"
        y="0"
        width="160"
        height="161"
      >
        <motion.circle
          cx="97"
          cy="80.75"
          r="80"
          fill="#D1D1D1"
          animate={{
            opacity: [0, 1],
          }}
          transition={{ duration: 1, ease: 'easeInOut' }}
        />
      </mask>
      <g mask="url(#mask0_3562_8660)">
        <motion.path
          d="M141.698 30.3516H52.3066C49.4362 30.3516 47.1094 32.8899 47.1094 36.0212V155.083C47.1094 158.214 49.4362 160.753 52.3066 160.753H141.698C144.569 160.753 146.895 158.214 146.895 155.083V36.0212C146.895 32.8899 144.569 30.3516 141.698 30.3516Z"
          fill="white"
          animate={{
            opacity: [0, 1],
          }}
          transition={{ duration: 1, ease: 'easeInOut' }}
        />
        <motion.path
          d="M57.4023 51.1836C57.4023 50.079 58.2978 49.1836 59.4023 49.1836H138.584C139.689 49.1836 140.584 50.079 140.584 51.1836V74.9547H57.4023V51.1836Z"
          fill="#FF8477"
          animate={{
            opacity: [0, 1],
          }}
          transition={{ duration: 1, ease: 'easeInOut' }}
        />
        <motion.path
          d="M57.4023 74.958H140.584V120.503C140.584 121.608 139.689 122.503 138.584 122.503H59.4023C58.2978 122.503 57.4023 121.608 57.4023 120.503V74.958Z"
          fill="#D1D1D1"
          fillOpacity="0.5"
          animate={{
            opacity: [0, 1],
          }}
          transition={{ duration: 1, ease: 'easeInOut' }}
        />
        <motion.rect
          opacity="0.5"
          x="82.2832"
          y="101.273"
          width="33.4119"
          height="4.76382"
          rx="2.38191"
          fill="#3B4851"
          animate={{
            opacity: [0, 0.5],
          }}
          transition={{ duration: 1, ease: 'easeInOut' }}
        />
        <motion.rect
          opacity="0.5"
          x="69.7578"
          y="109.125"
          width="58.4688"
          height="4.76382"
          rx="2.38191"
          fill="#3B4851"
          animate={{
            opacity: [0, 0.5],
          }}
          transition={{ duration: 1, ease: 'easeInOut' }}
        />
        <motion.rect
          x="91.3496"
          y="82.8271"
          width="15.2917"
          height="15.2917"
          rx="1"
          fill="#FF8477"
          animate={{
            opacity: [0, 1],
          }}
          transition={{ duration: 1, ease: 'easeInOut' }}
        />
        <g clipPath="url(#clip0_3562_8660)">
          <path
            d="M69.2746 39.9703C69.2746 40.6542 68.7399 41.1733 67.9061 41.1733H67.2535V42.1727C67.2535 42.2201 67.2441 42.2671 67.226 42.3109C67.2078 42.3547 67.1812 42.3945 67.1477 42.428C67.1142 42.4615 67.0744 42.4881 67.0306 42.5062C66.9868 42.5244 66.9399 42.5337 66.8925 42.5337C66.8451 42.5337 66.7981 42.5244 66.7543 42.5062C66.7105 42.4881 66.6707 42.4615 66.6372 42.428C66.6037 42.3945 66.5771 42.3547 66.559 42.3109C66.5408 42.2671 66.5315 42.2201 66.5315 42.1727V39.1521C66.5279 39.051 66.5646 38.9525 66.6335 38.8784C66.7025 38.8042 66.798 38.7605 66.8992 38.7568H67.9053C68.7414 38.7568 69.2746 39.2968 69.2746 39.9703ZM68.5743 39.9703C68.5743 39.5318 68.276 39.3736 67.874 39.3736H67.2535V40.558H67.874C68.276 40.558 68.5758 40.4141 68.5758 39.9703H68.5743Z"
            fill="#29323A"
          />
          <path
            d="M72.3645 40.0507V42.1823C72.3612 42.2752 72.3221 42.3633 72.2554 42.4281C72.1887 42.4929 72.0995 42.5293 72.0065 42.5299C71.9137 42.5302 71.8245 42.4939 71.7584 42.4288C71.6923 42.3637 71.6547 42.2751 71.6537 42.1823V42.0824C71.6104 42.2487 71.3278 42.5373 70.8624 42.5373C70.2739 42.5373 69.5684 42.0824 69.5684 41.1098C69.5684 40.1581 70.2791 39.6875 70.8624 39.6875C71.242 39.6875 71.5463 39.8904 71.6537 40.1417V40.0507C71.6562 39.9588 71.6943 39.8714 71.76 39.807C71.8256 39.7426 71.9137 39.7061 72.0057 39.7054C72.0982 39.7066 72.1868 39.743 72.2534 39.8071C72.32 39.8713 72.3597 39.9584 72.3645 40.0507ZM71.6858 41.1098C71.6858 40.5057 71.2897 40.2894 70.9907 40.2894C70.6379 40.2894 70.2687 40.5676 70.2687 41.1098C70.2687 41.652 70.6416 41.9302 70.9907 41.9302C71.289 41.9302 71.6858 41.7139 71.6858 41.1098Z"
            fill="#29323A"
          />
          <path
            d="M74.846 40.0774C74.8988 40.1449 74.9288 40.2274 74.9318 40.3131C74.9336 40.3459 74.9289 40.3787 74.918 40.4096C74.907 40.4406 74.8901 40.4691 74.8681 40.4935C74.8462 40.5179 74.8196 40.5377 74.7899 40.5518C74.7603 40.5659 74.7281 40.574 74.6953 40.5756C74.6832 40.5767 74.6709 40.5767 74.6588 40.5756C74.6015 40.5758 74.5449 40.5625 74.4938 40.5365C74.4427 40.5106 74.3986 40.4728 74.3649 40.4264C74.319 40.3686 74.2605 40.322 74.1938 40.2903C74.127 40.2586 74.0539 40.2427 73.9801 40.2437C73.7712 40.2437 73.6489 40.3459 73.6489 40.4951C73.6489 40.5808 73.681 40.6934 73.9264 40.7628L74.2531 40.859C74.6804 40.9769 75.0384 41.1797 75.0384 41.6556C75.0384 42.244 74.4932 42.5267 73.9376 42.5267C73.4558 42.5267 73.1671 42.3238 73.018 42.1418C72.9344 42.0485 72.8836 41.9304 72.8733 41.8055C72.8704 41.7376 72.8946 41.6713 72.9404 41.6212C72.9862 41.571 73.05 41.541 73.1179 41.5377H73.1403C73.1988 41.5406 73.2558 41.5577 73.3062 41.5876C73.3567 41.6175 73.399 41.6593 73.4297 41.7093C73.4879 41.7885 73.5646 41.8523 73.6531 41.895C73.7417 41.9378 73.8393 41.9583 73.9376 41.9546C74.1994 41.9546 74.3433 41.8428 74.3433 41.6929C74.3433 41.6019 74.2851 41.4848 74.0286 41.4094L73.722 41.317C73.182 41.1678 72.9576 40.8792 72.9576 40.4965C72.9576 39.9618 73.4491 39.6836 73.957 39.6836C74.3224 39.6866 74.67 39.8305 74.846 40.0774Z"
            fill="#29323A"
          />
          <path
            d="M77.3831 40.0798C77.4359 40.1473 77.4659 40.2298 77.4689 40.3155C77.4707 40.3483 77.466 40.3811 77.4551 40.4121C77.4442 40.443 77.4272 40.4715 77.4053 40.4959C77.3833 40.5203 77.3567 40.5401 77.327 40.5542C77.2974 40.5683 77.2652 40.5764 77.2324 40.578C77.2203 40.5791 77.2081 40.5791 77.1959 40.578C77.1386 40.5783 77.0821 40.5649 77.031 40.5389C76.9799 40.513 76.9357 40.4753 76.902 40.4289C76.8562 40.371 76.7976 40.3244 76.7309 40.2927C76.6642 40.261 76.591 40.2451 76.5172 40.2461C76.3084 40.2461 76.186 40.3483 76.186 40.4975C76.186 40.5832 76.2181 40.6959 76.4635 40.7652L76.7902 40.8614C77.2175 40.9793 77.5755 41.1821 77.5755 41.658C77.5755 42.2464 77.0303 42.5291 76.4747 42.5291C75.9929 42.5291 75.7042 42.3262 75.5551 42.1443C75.4715 42.0509 75.4207 41.9328 75.4104 41.8079C75.4075 41.74 75.4317 41.6737 75.4775 41.6236C75.5233 41.5734 75.5871 41.5434 75.655 41.5401H75.6774C75.7359 41.543 75.7929 41.5602 75.8433 41.5901C75.8938 41.6199 75.9361 41.6617 75.9668 41.7117C76.025 41.7909 76.1017 41.8547 76.1902 41.8975C76.2788 41.9402 76.3764 41.9607 76.4747 41.9571C76.7365 41.9571 76.8804 41.8452 76.8804 41.6953C76.8804 41.6043 76.8222 41.4872 76.5657 41.4119L76.2606 41.3209C75.7206 41.1717 75.4962 40.8831 75.4962 40.5005C75.4962 39.9657 75.9877 39.6875 76.4956 39.6875C76.8595 39.689 77.2071 39.8329 77.3831 40.0798Z"
            fill="#29323A"
          />
          <path
            d="M80.3105 41.8684C80.352 41.8684 80.393 41.8766 80.4313 41.8925C80.4695 41.9083 80.5043 41.9315 80.5336 41.9608C80.5629 41.9901 80.5861 42.0249 80.602 42.0632C80.6179 42.1015 80.626 42.1425 80.626 42.1839C80.6261 42.2253 80.618 42.2663 80.6023 42.3046C80.5865 42.3429 80.5633 42.3777 80.5341 42.407C80.5049 42.4363 80.4701 42.4595 80.4319 42.4754C80.3937 42.4912 80.3527 42.4994 80.3113 42.4994H78.4803C78.3789 42.503 78.2802 42.4663 78.206 42.3972C78.1317 42.3281 78.0879 42.2323 78.0842 42.131V39.1521C78.0806 39.051 78.1173 38.9525 78.1862 38.8784C78.2552 38.8042 78.3508 38.7605 78.4519 38.7568H80.2553C80.3386 38.7564 80.4187 38.7885 80.4787 38.8464C80.5386 38.9042 80.5735 38.9831 80.576 39.0664C80.5749 39.1511 80.5407 39.232 80.4808 39.2919C80.4209 39.3517 80.34 39.3859 80.2553 39.3871H78.8062V40.3171H79.7616C79.8011 40.3166 79.8403 40.324 79.8769 40.3387C79.9135 40.3535 79.9469 40.3754 79.975 40.4032C80.0031 40.4309 80.0254 40.464 80.0406 40.5004C80.0558 40.5369 80.0637 40.576 80.0637 40.6154C80.0642 40.6549 80.0568 40.6941 80.042 40.7308C80.0273 40.7674 80.0054 40.8007 79.9776 40.8288C79.9499 40.8569 79.9168 40.8792 79.8804 40.8944C79.8439 40.9097 79.8048 40.9175 79.7653 40.9175H78.8062V41.8684H80.3105Z"
            fill="#29323A"
          />
          <path
            d="M83.7696 40.9726V42.1846C83.7696 42.2775 83.7326 42.3667 83.6669 42.4324C83.6012 42.4982 83.512 42.5351 83.419 42.5351C83.3261 42.5349 83.2371 42.4979 83.1714 42.4322C83.1057 42.3665 83.0687 42.2775 83.0685 42.1846V40.9726C83.0685 40.4915 82.8172 40.2939 82.5024 40.2939C82.0206 40.2939 81.8603 40.7414 81.8394 40.8906V42.1846C81.8351 42.2774 81.7941 42.3648 81.7255 42.4275C81.6568 42.4901 81.566 42.5229 81.4732 42.5187C81.3858 42.5149 81.303 42.4784 81.2412 42.4166C81.1793 42.3547 81.1429 42.2719 81.139 42.1846V40.059C81.1348 39.9661 81.1676 39.8753 81.2303 39.8067C81.2929 39.738 81.3803 39.697 81.4732 39.6927C81.566 39.6885 81.6568 39.7213 81.7255 39.784C81.7941 39.8466 81.8351 39.934 81.8394 40.0269C81.8401 40.0376 81.8401 40.0483 81.8394 40.059V40.326C81.8603 40.1335 82.1757 39.695 82.753 39.695C83.2952 39.692 83.7696 40.0448 83.7696 40.9726Z"
            fill="#29323A"
          />
          <path
            d="M85.9173 42.1516C85.9173 42.397 85.6771 42.5245 85.3131 42.5245C84.9492 42.5245 84.5807 42.4074 84.5807 41.6638V40.2691H84.4256C84.3526 40.2691 84.2826 40.2401 84.231 40.1885C84.1794 40.1369 84.1504 40.0669 84.1504 39.9939C84.1504 39.9209 84.1794 39.8509 84.231 39.7993C84.2826 39.7477 84.3526 39.7187 84.4256 39.7187H84.5807V39.3547C84.5815 39.2612 84.6191 39.1717 84.6855 39.1056C84.7518 39.0396 84.8414 39.0023 84.935 39.002C85.0278 39.0029 85.1164 39.0406 85.1815 39.1067C85.2466 39.1728 85.283 39.2619 85.2826 39.3547V39.7202H85.6353C85.7083 39.7202 85.7783 39.7492 85.8299 39.8008C85.8816 39.8524 85.9106 39.9224 85.9106 39.9954C85.9106 40.0684 85.8816 40.1384 85.8299 40.19C85.7783 40.2416 85.7083 40.2706 85.6353 40.2706H85.2811V41.6877C85.2811 41.8428 85.3348 41.9226 85.4526 41.9226C85.5108 41.9226 85.5966 41.9017 85.6555 41.9017C85.7154 41.893 85.7763 41.9083 85.825 41.9444C85.8736 41.9804 85.906 42.0343 85.915 42.0942C85.918 42.1131 85.9188 42.1324 85.9173 42.1516Z"
            fill="#29323A"
          />
          <path
            d="M88.2653 40.0108C88.2653 40.2085 88.1162 40.3427 87.8178 40.3427C87.3957 40.3427 87.1279 40.6686 87.1011 40.8879V42.1797C87.0968 42.2725 87.0559 42.3599 86.9872 42.4226C86.9185 42.4852 86.8278 42.5181 86.7349 42.5138C86.6475 42.51 86.5647 42.4735 86.5029 42.4117C86.441 42.3498 86.4046 42.2671 86.4008 42.1797V40.0541C86.3965 39.9612 86.4293 39.8704 86.492 39.8018C86.5546 39.7331 86.642 39.6921 86.7349 39.6879C86.8278 39.6836 86.9185 39.7164 86.9872 39.7791C87.0559 39.8418 87.0968 39.9291 87.1011 40.022C87.1018 40.0327 87.1018 40.0434 87.1011 40.0541V40.3211C87.1227 40.1287 87.4539 39.6901 87.9357 39.6901C87.976 39.6872 88.0166 39.6922 88.055 39.705C88.0934 39.7178 88.1289 39.738 88.1595 39.7646C88.1901 39.7911 88.2151 39.8234 88.2331 39.8597C88.2511 39.8959 88.2618 39.9354 88.2646 39.9758C88.2659 39.9874 88.2662 39.9991 88.2653 40.0108Z"
            fill="#29323A"
          />
          <path
            d="M91.3519 40.0172C91.351 40.0734 91.3364 40.1284 91.3094 40.1776L89.9244 43.2952C89.9002 43.3656 89.855 43.427 89.7949 43.471C89.7347 43.515 89.6625 43.5395 89.588 43.5413C89.5476 43.5416 89.5075 43.5339 89.47 43.5187C89.4325 43.5035 89.3984 43.481 89.3696 43.4526C89.3408 43.4243 89.3178 43.3905 89.3021 43.3532C89.2863 43.3159 89.2781 43.2759 89.2778 43.2355V43.2243C89.28 43.169 89.2927 43.1146 89.3151 43.064L89.6201 42.3688L88.6252 40.1761C88.6028 40.1255 88.5902 40.0711 88.5879 40.0158C88.5887 39.9315 88.6228 39.8511 88.6827 39.792C88.7427 39.7328 88.8236 39.6999 88.9079 39.7003C88.9825 39.702 89.0547 39.7265 89.115 39.7705C89.1753 39.8145 89.2206 39.8759 89.245 39.9464L89.9617 41.6036L90.6941 39.9464C90.7687 39.7756 90.8917 39.7003 91.0253 39.7003C91.1097 39.6985 91.1914 39.7302 91.2524 39.7885C91.3135 39.8468 91.349 39.9269 91.3512 40.0113L91.3519 40.0172Z"
            fill="#29323A"
          />
          <path
            d="M59.2145 43.0606L59.0698 43.1113C58.9892 43.1376 58.918 43.187 58.865 43.2532C58.812 43.3195 58.7795 43.3997 58.7715 43.4842V43.514C58.7716 43.5936 58.7933 43.6716 58.8341 43.7399C58.8749 43.8081 58.9334 43.8641 59.0034 43.9019L60.9993 44.6805C61.355 44.8192 61.7294 44.5313 61.7294 44.1211V42.1768L59.2145 43.0606Z"
            fill="#B2B2B2"
          />
          <path
            d="M60.8105 36.6703L59.0548 37.2871C59.0023 37.3113 58.9551 37.3456 58.9158 37.388C58.8766 37.4304 58.8461 37.4802 58.826 37.5344C58.806 37.5887 58.7969 37.6463 58.7992 37.7041C58.8014 37.7618 58.8151 37.8186 58.8393 37.8711C58.8831 37.9664 58.9595 38.0428 59.0548 38.0866L61.3743 38.9913C61.4832 39.0381 61.5756 39.1162 61.6399 39.2157C61.7042 39.3151 61.7374 39.4315 61.7353 39.5499V42.1753L62.643 41.8568C63.1802 41.6681 63.6455 41.3173 63.9747 40.8528C64.3039 40.3882 64.4807 39.8329 64.4807 39.2635C64.4801 38.9018 64.4083 38.5437 64.2693 38.2097C64.1303 37.8757 63.9268 37.5723 63.6706 37.317C63.4143 37.0616 63.1102 36.8592 62.7758 36.7214C62.4413 36.5836 62.0829 36.513 61.7212 36.5137C61.411 36.5145 61.1031 36.5675 60.8105 36.6703Z"
            fill="#FF8477"
          />
          <path
            d="M62.6412 41.8567L58.5332 43.2991V37.8941C58.5336 37.7705 58.5723 37.6502 58.6441 37.5496C58.7158 37.449 58.817 37.3731 58.9337 37.3325L60.811 36.6702C61.4998 36.4275 62.2569 36.4683 62.9156 36.7837C63.5744 37.0991 64.0809 37.6632 64.3238 38.352C64.4267 38.6449 64.4797 38.953 64.4804 39.2634C64.4801 39.8329 64.303 40.3883 63.9736 40.8528C63.6441 41.3173 63.1786 41.6681 62.6412 41.8567Z"
            fill="url(#paint0_linear_3562_8660)"
          />
          <path
            d="M61.3727 38.9903L59.0554 38.0857C59.0029 38.0616 58.9556 38.0275 58.9163 37.9851C58.877 37.9427 58.8465 37.893 58.8264 37.8388C58.8064 37.7846 58.7973 37.727 58.7996 37.6693C58.8019 37.6116 58.8156 37.5548 58.8399 37.5024C58.8837 37.4069 58.9601 37.3302 59.0554 37.2861L58.9339 37.3316C58.8188 37.3716 58.7188 37.4459 58.6472 37.5444C58.5756 37.643 58.5359 37.761 58.5334 37.8828V43.2983C58.5306 43.4169 58.5637 43.5336 58.6282 43.6333C58.6927 43.7329 58.7857 43.8108 58.8951 43.8569L59.0077 43.9016C58.9377 43.8639 58.8792 43.8079 58.8384 43.7396C58.7975 43.6714 58.7759 43.5933 58.7758 43.5138V43.484C58.7838 43.3995 58.8163 43.3192 58.8693 43.253C58.9223 43.1867 58.9934 43.1374 59.0741 43.1111L59.2188 43.0603L61.7337 42.1743V39.549C61.7358 39.4305 61.7026 39.3142 61.6383 39.2147C61.574 39.1152 61.4815 39.0371 61.3727 38.9903Z"
            fill="#29323A"
          />
          <path
            d="M59.2145 43.0606L59.0698 43.1113C58.9892 43.1376 58.918 43.187 58.865 43.2532C58.812 43.3195 58.7795 43.3997 58.7715 43.4842V43.514C58.7716 43.5936 58.7933 43.6716 58.8341 43.7399C58.8749 43.8081 58.9334 43.8641 59.0034 43.9019L60.9993 44.6805C61.355 44.8192 61.7294 44.5313 61.7294 44.1211V42.1768L59.2145 43.0606Z"
            fill="url(#paint1_linear_3562_8660)"
          />
        </g>
      </g>
      <motion.path
        d="M69.8672 139.074C69.8672 136.444 71.9992 134.312 74.6291 134.312H119.372C122.002 134.312 124.134 136.444 124.134 139.074C124.134 141.704 122.002 143.836 119.372 143.836H74.6291C71.9992 143.836 69.8672 141.704 69.8672 139.074Z"
        fill="url(#paint2_linear_3562_8660)"
        animate={{
          opacity: [0, 1],
        }}
        transition={{ duration: 1, ease: 'easeInOut' }}
      />
      <motion.rect
        x="153.562"
        y="33.0234"
        width="17.5076"
        height="2.50034"
        rx="1.25017"
        fill="#FF8477"
        animate={{
          opacity: [0, 1],
        }}
        transition={{ delay: 1, duration: 1, ease: 'easeInOut' }}
      />
      <motion.rect
        x="142.312"
        y="24.2656"
        width="17.5024"
        height="2.50034"
        rx="1.25017"
        transform="rotate(-90 142.312 24.2656)"
        fill="#FF8477"
        animate={{
          opacity: [0, 1],
        }}
        transition={{ delay: 1, duration: 1, ease: 'easeInOut' }}
      />
      <motion.rect
        width="17.2549"
        height="2.87582"
        rx="1.43791"
        transform="matrix(0.707212 -0.707001 0.707212 0.707001 150.941 24.7266)"
        fill="#FF8477"
        animate={{
          opacity: [0, 1],
        }}
        transition={{ delay: 1, duration: 1, ease: 'easeInOut' }}
      />
      <motion.path
        d="M112.099 138.301V142.376C112.351 142.216 112.604 142.087 112.86 141.99C113.83 141.617 114.902 142.26 114.901 143.194C114.901 143.702 114.569 144.073 114.013 144.286C113.15 144.617 112.051 146.434 111.654 147.222C111.411 147.71 110.877 147.999 110.316 147.999L105.082 148C104.722 148 104.414 147.765 104.359 147.446C104.137 146.167 103.184 145.76 103.19 144.068C103.19 143.993 103.184 143.706 103.184 143.631C103.184 142.248 103.859 141.404 105.358 141.649C105.642 141.141 106.563 140.809 107.26 141.2C107.906 140.527 108.904 140.581 109.425 141.028V138.301C109.425 137.642 110.041 137.078 110.762 137.078C111.472 137.078 112.099 137.661 112.099 138.301ZM111.484 148.633L111.484 149.943C111.484 150.305 111.156 150.598 110.751 150.598H104.892C104.488 150.598 104.16 150.305 104.16 149.943L104.16 148.633C104.16 148.271 104.488 147.977 104.892 147.977H110.751C111.156 147.977 111.484 148.271 111.484 148.633Z"
        fill="#D1D1D1"
        animate={{
          opacity: [0, 1],
        }}
        transition={{ duration: 1, ease: 'easeInOut' }}
      />
      <defs>
        <linearGradient
          id="paint0_linear_3562_8660"
          x1="58.7831"
          y1="40.8938"
          x2="64.2448"
          y2="37.4429"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.25" stopColor="#3B4851" stopOpacity="0.4" />
          <stop offset="0.3" stopColor="#3B4851" stopOpacity="0.36" />
          <stop offset="0.65" stopColor="#3B4851" stopOpacity="0.1" />
          <stop offset="0.88" stopColor="#3B4851" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_3562_8660"
          x1="60.0163"
          y1="42.3617"
          x2="60.7569"
          y2="44.0122"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#3B4851" stopOpacity="0.4" />
          <stop offset="0.07" stopColor="#3B4851" stopOpacity="0.36" />
          <stop offset="0.59" stopColor="#3B4851" stopOpacity="0.1" />
          <stop offset="0.93" stopColor="#3B4851" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_3562_8660"
          x1="71.2676"
          y1="143.836"
          x2="99.6244"
          y2="120.039"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF8477" />
          <stop offset="1" stopColor="#EC6759" />
        </linearGradient>
        <clipPath id="clip0_3562_8660">
          <rect
            width="32.8164"
            height="8.20112"
            fill="white"
            transform="translate(58.5332 36.5137)"
          />
        </clipPath>
      </defs>
    </Icon>
  );
};

export default ThankYouIconAnimated;

import { Icon } from '@chakra-ui/react';
import { motion } from 'framer-motion';

const pathVariants = {
  hidden: {
    opacity: 0,
    pathLength: 0,
  },
  visible: {
    opacity: 1,
    pathLength: 1,
    transition: {
      duration: 0.9,
      ease: 'easeInOut',
    },
  },
};

export const CustomReaderIconAnimated = (props) => {
  return (
    <Icon viewBox="0 0 28 28" {...props} fill="transparent">
      <g clipPath="url(#clip0_4349_10422)">
        <motion.path
          d="M8.16467 6.345V2.315C8.16467 1.765 8.61467 1.315 9.16467 1.315H25.8647C26.4147 1.315 26.8647 1.765 26.8647 2.315V21.585C26.8647 22.135 26.4147 22.585 25.8647 22.585H11.7047"
          stroke="#FF8477"
          strokeWidth="2"
          strokeMiterlimit="10"
          variants={pathVariants}
          initial="hidden"
          animate="visible"
        />
        <motion.path
          d="M23.3246 6.99503V5.59503C23.3246 5.04275 22.8769 4.59503 22.3246 4.59503L12.7046 4.59503C12.1523 4.59503 11.7046 5.04275 11.7046 5.59503V6.99503C11.7046 7.54732 12.1523 7.99503 12.7046 7.99503H22.3246C22.8769 7.99503 23.3246 7.54732 23.3246 6.99503Z"
          stroke="#FF8477"
          strokeWidth="2"
          strokeMiterlimit="10"
          variants={pathVariants}
          initial="hidden"
          animate="visible"
        />
        <motion.path
          d="M17.5046 20.695C18.2226 20.695 18.8046 20.113 18.8046 19.395C18.8046 18.6771 18.2226 18.095 17.5046 18.095C16.7866 18.095 16.2046 18.6771 16.2046 19.395C16.2046 20.113 16.7866 20.695 17.5046 20.695Z"
          fill="#29323B"
          variants={pathVariants}
          initial="hidden"
          animate="visible"
        />
        <motion.path
          d="M22.2946 14.715C21.0446 13.535 19.3646 12.805 17.5146 12.805C15.5246 12.805 13.9946 13.385 12.7246 14.715"
          stroke="#29323B"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          variants={pathVariants}
          initial="hidden"
          animate="visible"
        />
        <motion.path
          d="M20.1946 17.165C19.4946 16.505 18.5546 16.095 17.5146 16.095C16.4046 16.095 15.5446 16.415 14.8346 17.165"
          stroke="#29323B"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          variants={pathVariants}
          initial="hidden"
          animate="visible"
        />
        <motion.path
          d="M10.3946 7.05499H2.74463C2.19234 7.05499 1.74463 7.50271 1.74463 8.05499V23.685C1.74463 24.2373 2.19234 24.685 2.74463 24.685H10.3946C10.9469 24.685 11.3946 24.2373 11.3946 23.685V8.05499C11.3946 7.50271 10.9469 7.05499 10.3946 7.05499Z"
          stroke="#FF8477"
          strokeWidth="2"
          strokeMiterlimit="10"
          variants={pathVariants}
          initial="hidden"
          animate="visible"
        />
        <motion.path
          d="M5.45459 21.335H7.68459"
          stroke="#FF8477"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          variants={pathVariants}
          initial="hidden"
          animate="visible"
        />
      </g>
      <defs>
        <clipPath id="clip0_4349_10422">
          <rect
            width="27.62"
            height="25.87"
            fill="white"
            transform="translate(0.494629 0.0650024)"
          />
        </clipPath>
      </defs>
    </Icon>
  );
};

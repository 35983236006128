import {
  Box,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  Show,
  Hide,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import { useContext, useEffect, useState } from 'react';
import PassTemplateContext from '../../../store/client/PassTemplateContext';
import BannerImage from './BannerImage';
import { NUMBER_KEYS } from '../../../utils/consts';
import { MeContext } from '../../../context/MeContext';
import WatermarkFields from './WatermarkFields';

function StampDetails(props) {
  const {
    isEdit,
    activeStep,
    editedImages,
    setEditedImages,
    editedFields,
    setEditedFields,
    stampValuesError,
  } = props;
  const passTemplateCtx = useContext(PassTemplateContext);
  const modeBorderColor = useColorModeValue('primaryBackground', 'primaryDark');
  const modeFormControlBg = useColorModeValue('white', '');
  const modeLabelBg = useColorModeValue('#EFEFEF', 'primaryDark03');
  const modeHelperText = useColorModeValue('secondaryDark08', '#DDDDDD');
  const [watermarkFieldsDisabled, setWatermarkFieldsDisabled] = useState(false);
  const [watermarkFieldsOpen, setWatermarkFieldsOpen] = useState(false);
  const meCtx = useContext(MeContext);
  const { watermarkEnabled } = meCtx.state;

  useEffect(() => {
    let lowerCaseNumber = NUMBER_KEYS[activeStep || 0].toLowerCase();
    if (isEdit) {
      if (!editedImages[`stamp_${lowerCaseNumber}_image`]) {
        setWatermarkFieldsDisabled(true);
        passTemplateCtx.updateDisplayBannerWatermarkPreview(false);
      } else {
        setWatermarkFieldsDisabled(false);
        passTemplateCtx.updateDisplayBannerWatermarkPreview(true);
      }
    }
  }, [isEdit, activeStep, editedImages]);

  useEffect(() => {
    setWatermarkFieldsOpen(false);
  }, [activeStep]);

  return (
    <>
      <FormControl isRequired bg={modeFormControlBg}>
        <Flex>
          <Hide below="2xl">
            <Box pl="24px" flexShrink={0} w="240px">
              <FormLabel m={0} pt="18px">
                Banner image
              </FormLabel>
              <Text
                color={modeHelperText}
                textStyle="bodyFamilyRegular"
                fontSize="12px"
              >
                {activeStep === 0
                  ? 'Unstamped'
                  : `${activeStep} completed stamp${
                      activeStep === 1 ? '' : 's'
                    }`}
              </Text>
            </Box>
          </Hide>
          <Box p="14px" w="full" bg={modeLabelBg}>
            <Show below="2xl">
              <FormLabel>Banner image</FormLabel>
              <Text
                color={modeHelperText}
                textStyle="bodyFamilyRegular"
                fontSize="12px"
                mb={2}
              >
                {activeStep === 0
                  ? 'Unstamped'
                  : `${activeStep} completed stamp${
                      activeStep === 1 ? '' : 's'
                    }`}
              </Text>
            </Show>
            <BannerImage
              editedFields={editedFields}
              setEditedFields={setEditedFields}
              editedImages={editedImages}
              setEditedImages={setEditedImages}
              type={'stamp'}
              isEdit={isEdit}
              setBannerWatermarkFieldDisabled={setWatermarkFieldsDisabled}
            />
          </Box>
        </Flex>
      </FormControl>
      <Divider borderColor={modeBorderColor} />
      {watermarkEnabled && (
        <WatermarkFields
          isEdit={isEdit}
          editedFields={editedFields}
          setEditedFields={setEditedFields}
          watermarkFieldsOpen={watermarkFieldsOpen}
          setWatermarkFieldsOpen={setWatermarkFieldsOpen}
          watermarkFieldsDisabled={watermarkFieldsDisabled}
        />
      )}

      {!!passTemplateCtx.stampShortKey && (
        <>
          <FormControl
            isRequired
            bg={modeFormControlBg}
            borderRadius="15px 15px 0 0"
          >
            <Flex alignItems="center">
              <Hide below="2xl">
                <Box pl="24px" flexShrink={0} w="240px">
                  <FormLabel m={0}>{passTemplateCtx.stampShortKey}</FormLabel>
                </Box>
              </Hide>
              <Box p="14px" w="full" bg={modeLabelBg}>
                <Show below="2xl">
                  <FormLabel>{passTemplateCtx.stampShortKey}</FormLabel>
                </Show>
                <Input
                  value={
                    passTemplateCtx[`stamp${NUMBER_KEYS[activeStep]}ShortValue`]
                  }
                  variant="filledForDarkBg"
                  id="stampShortValue"
                  type="text"
                  placeholder={
                    passTemplateCtx.stampCount - activeStep === 0
                      ? 'e.g. All stamps completed!'
                      : `e.g. ${passTemplateCtx.stampCount - activeStep} stamp${
                          passTemplateCtx.stampCount - activeStep === 1
                            ? ''
                            : 's'
                        } to go!`
                  }
                  onChange={(e) => {
                    passTemplateCtx[
                      `updateStamp${NUMBER_KEYS[activeStep]}ShortValue`
                    ](e.target.value);
                    if (isEdit) {
                      setEditedFields({
                        ...editedFields,
                        [`stamp_${NUMBER_KEYS[activeStep]}_short_value`]:
                          e.target.value,
                      });
                    }
                  }}
                  autoComplete="off"
                  isInvalid={
                    !passTemplateCtx[
                      `stamp${NUMBER_KEYS[activeStep]}ShortValue`
                    ] && stampValuesError
                  }
                />
                <FormErrorMessage></FormErrorMessage>
              </Box>
            </Flex>
          </FormControl>
          <Divider borderColor={modeBorderColor} />
        </>
      )}
      {!!passTemplateCtx.stampLongKey && (
        <>
          <FormControl
            isRequired
            bg={modeFormControlBg}
            borderRadius="15px 15px 0 0"
          >
            <Flex alignItems="center">
              <Hide below="2xl">
                <Box pl="24px" flexShrink={0} w="240px">
                  <FormLabel
                    m={0}
                  >{`${passTemplateCtx.stampLongKey}`}</FormLabel>
                </Box>
              </Hide>
              <Box p="14px" w="full" bg={modeLabelBg}>
                <Show below="2xl">
                  <FormLabel>{`${passTemplateCtx.stampLongKey}`}</FormLabel>
                </Show>
                <Input
                  value={
                    passTemplateCtx[`stamp${NUMBER_KEYS[activeStep]}LongValue`]
                  }
                  variant="filledForDarkBg"
                  id="stampLongValue"
                  type="text"
                  placeholder={
                    passTemplateCtx.stampCount - activeStep === 0
                      ? 'e.g. Congratulations on completing all of your stamps!'
                      : `e.g. Only ${
                          passTemplateCtx.stampCount - activeStep
                        } stamp${
                          passTemplateCtx.stampCount - activeStep === 1
                            ? ''
                            : 's'
                        } to go until you earn your free item!`
                  }
                  onChange={(e) => {
                    passTemplateCtx[
                      `updateStamp${NUMBER_KEYS[activeStep]}LongValue`
                    ](e.target.value);
                    if (isEdit) {
                      setEditedFields({
                        ...editedFields,
                        [`stamp_${NUMBER_KEYS[activeStep]}_long_value`]:
                          e.target.value,
                      });
                    }
                  }}
                  autoComplete="off"
                  isInvalid={
                    !passTemplateCtx[
                      `stamp${NUMBER_KEYS[activeStep]}LongValue`
                    ] && stampValuesError
                  }
                />
                <FormErrorMessage></FormErrorMessage>
              </Box>
            </Flex>
          </FormControl>
          <Divider borderColor={modeBorderColor} />
        </>
      )}
      <FormControl bg={modeFormControlBg} borderRadius="15px 15px 0 0">
        <Flex alignItems="center">
          <Hide below="2xl">
            <Box pl="24px" flexShrink={0} w="240px">
              <FormLabel m={0}>Push notification</FormLabel>
            </Box>
          </Hide>
          <Box p="14px" w="full" bg={modeLabelBg}>
            <Show below="2xl">
              <FormLabel>Push notification</FormLabel>
            </Show>
            <Input
              value={passTemplateCtx[`stamp${NUMBER_KEYS[activeStep]}Message`]}
              variant="filledForDarkBg"
              id="stampMessage"
              type="text"
              placeholder="Type a notification message here..."
              onChange={(e) => {
                passTemplateCtx[`updateStamp${NUMBER_KEYS[activeStep]}Message`](
                  e.target.value
                );
                if (isEdit) {
                  setEditedFields({
                    ...editedFields,
                    [`stamp_${NUMBER_KEYS[activeStep]}_message`]:
                      e.target.value,
                  });
                }
              }}
              autoComplete="off"
            />
            <FormErrorMessage></FormErrorMessage>
          </Box>
        </Flex>
      </FormControl>
    </>
  );
}

export default StampDetails;

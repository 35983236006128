export const MESSAGING_ENABLED_USERS = [
  'test@client.com',
  'demo@passentry.com',
  'emily@passentry.com',
  'lukas@passentry.com',
  'team@passentry.com',
  'nicolae.popescu@passentry.com',
  'Josie.kinnear@passesntry.com',
  'veerendra.shankara@network.global',
  'sivani.yelamanchili@network.global',
  'et@finds.is',
  'sivani.yelamanchili@network.global',
  'noor@passentry.com',
  'angad@passentry.com',
  'raquelitaa@nova.is',
  'arnark@nova.is',
];

import { Icon } from '@chakra-ui/react';

export const CustomAccountSettingsMultiIcon = (props) => (
  <Icon viewBox="0 0 32 32" {...props}>
    <g>
      <circle
        cx="16"
        cy="16"
        fill="none"
        r="15"
        stroke="currentColor"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="2"
      />
      <path
        d="M26,27L26,27   c0-5.523-4.477-10-10-10h0c-5.523,0-10,4.477-10,10v0"
        fill="none"
        stroke="#FF8477"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="2"
      />
      <circle
        cx="16"
        cy="11"
        fill="none"
        r="6"
        stroke="#FF8477"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="2"
      />
    </g>
  </Icon>
);

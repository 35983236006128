import {
  Container,
  Divider,
  useColorModeValue,
  useDisclosure,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  HStack,
  Text,
} from '@chakra-ui/react';
import Breadcrumbs from '../../common/Breadcrumbs';
import MessagesHeader from '../../components/client/messages/MessagesHeader';
import EmptyState from '../../common/EmptyState';

import { MessagingHistoryContextProvider } from '../../store/client/MessagingHistoryContext';
import { MessagingLogsContextProvider } from '../../store/client/MessagingLogsContext';

import { useEffect, useContext, useState, useCallback } from 'react';
import useOrganizationStatus from '../../hooks/useOrganisationStatus';
import EmptyArtistAnimated from '../../theme/illustrations-animated/EmptyArtistAnimated';
import EmptyArtistDarkAnimated from '../../theme/illustrations-animated/EmptyArtistDarkAnimated';
import { MeContext } from '../../context/MeContext';
import MessagingHistoryPage from './MessagingHistoryPage';
import FailedMessagesPage from './FailedMessagesPage';

const MessageLogsPage = () => {
  const meCtx = useContext(MeContext);
  const { filterEntity } = meCtx.state;
  const [tabIndex, setTabIndex] = useState(0);

  const modeSvgIcon = useColorModeValue(
    <EmptyArtistAnimated />,
    <EmptyArtistDarkAnimated />
  );

  const breadcrumbLinks = [
    {
      linkName: 'Home',
      linkUrl: '/',
    },
    {
      linkName: 'Messaging',
      linkUrl: '/messaging',
    },
  ];

  const selectedEntity =
    meCtx.state.entities?.length > 1 ? filterEntity : meCtx.state.entities?.[0];

  const { components, shouldRender } = useOrganizationStatus(modeSvgIcon);

  return (
    <Container display="flex" flexDir="column" flex="1" pb={6}>
      <Breadcrumbs breadcrumbLinks={breadcrumbLinks} />
      <MessagesHeader isCreatePage={false} />

      {components}

      {shouldRender && (
        <>
          <Tabs
            index={tabIndex}
            onChange={(index) => setTabIndex(index)}
            display="flex"
            flexDir="column"
            flexGrow="1"
          >
            <TabList>
              <Tab>
                <HStack alignItems="center">
                  <Text textStyle="bodyMedium">Message Batches</Text>
                </HStack>
              </Tab>

              <Tab>
                <HStack alignItems="center">
                  <Text textStyle="bodyMedium">Failed Messages</Text>
                </HStack>
              </Tab>
              <Tab>
                <HStack alignItems="center">
                  <Text textStyle="bodyMedium">Scheduled Batches</Text>
                </HStack>
              </Tab>
            </TabList>

            {selectedEntity ? (
              <TabPanels mt={8} display="flex" flexDir="column" flexGrow="1">
                <TabPanel px="0" flexGrow="1" display="flex" flexDir="column">
                  <MessagingHistoryContextProvider>
                    <MessagingHistoryPage
                      filterEntity={selectedEntity}
                      activeTab={tabIndex}
                      status=""
                      heading="Message Batches"
                      setTabIndex={setTabIndex}
                    />
                  </MessagingHistoryContextProvider>
                </TabPanel>

                <TabPanel px="0" flexGrow="1" display="flex" flexDir="column">
                  <MessagingLogsContextProvider>
                    <FailedMessagesPage
                      filterEntity={selectedEntity}
                      activeTab={tabIndex}
                    />
                  </MessagingLogsContextProvider>
                </TabPanel>
                <TabPanel px="0" flexGrow="1" display="flex" flexDir="column">
                  <MessagingHistoryContextProvider>
                    <MessagingHistoryPage
                      filterEntity={selectedEntity}
                      activeTab={tabIndex}
                      status="scheduled"
                      heading="Scheduled Batches"
                    />
                  </MessagingHistoryContextProvider>
                </TabPanel>
              </TabPanels>
            ) : (
              <Container display="flex" flexDir="column" flexGrow="1">
                <EmptyState
                  heading="No data available."
                  text="Please select an entity to view the messaging history."
                />
              </Container>
            )}
          </Tabs>
        </>
      )}
    </Container>
  );
};

export default MessageLogsPage;

import { Icon } from '@chakra-ui/react';

export const CustomCsvDownloadIcon = (props) => (
  <Icon viewBox="0 0 22 26" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.5036 4.85401L16.5036 4.85404L16.5125 4.86495L17.5165 6.09155H15.6914V3.84736L16.5036 4.85401ZM13.6914 2.57031L3.96875 2.57031V18.8975V23.0241H18.7109L18.7109 8.09155H15.1914C14.363 8.09155 13.6914 7.41998 13.6914 6.59155V2.57031ZM20.2586 6.28389L18.0601 3.59813L16.2176 1.31444C15.8379 0.843859 15.2657 0.570312 14.661 0.570312H3.96875C2.86418 0.570312 1.96875 1.46574 1.96875 2.57031V18.8975V23.0241C1.96875 24.1287 2.86418 25.0241 3.96875 25.0241H18.7109C19.8155 25.0241 20.7109 24.1287 20.7109 23.0241V7.55071C20.7109 7.08888 20.5511 6.64127 20.2586 6.28389Z"
      fill="currentColor"
    />
    <rect
      x="0.890625"
      y="10.5234"
      width="20.8056"
      height="10.6345"
      rx="1"
      fill="#EC6759"
    />
    <path
      d="M9.00609 14.0234C8.52609 13.4074 7.72609 13.1914 7.09409 13.1914C5.32609 13.1914 4.03809 14.3514 4.03809 16.1674C4.03809 17.9834 5.32609 19.1434 7.09409 19.1434C7.87009 19.1434 8.67009 18.8314 9.14209 18.1594L8.10209 17.3834C7.84609 17.7674 7.43809 17.9914 6.93409 17.9914C6.03009 17.9914 5.33409 17.2234 5.33409 16.1674C5.33409 15.1114 6.03009 14.3434 6.97409 14.3434C7.43809 14.3434 7.79809 14.5114 8.04609 14.8074L9.00609 14.0234Z"
      fill="#fff"
    />
    <path
      d="M13.4746 13.7834C12.9946 13.3594 12.3786 13.1914 11.7386 13.1914C10.6266 13.1914 9.57865 13.8234 9.57865 15.0314C9.57865 16.9194 12.2346 16.3594 12.2346 17.3994C12.2346 17.7994 11.7786 17.9914 11.3706 17.9914C10.9546 17.9914 10.5386 17.7914 10.2826 17.4554L9.39465 18.4314C9.93065 18.9274 10.5706 19.1434 11.2986 19.1434C12.4666 19.1434 13.4826 18.5434 13.4826 17.2794C13.4826 15.2954 10.8746 15.9594 10.8746 14.9274C10.8746 14.5034 11.3626 14.3434 11.7146 14.3434C12.0186 14.3434 12.4186 14.4634 12.6186 14.7194L13.4746 13.7834Z"
      fill="#fff"
    />
    <path
      d="M16.1164 18.9994H17.1164L19.4524 13.3354H18.1164L16.6924 17.0474H16.6764L15.2764 13.3354H13.8524L16.1164 18.9994Z"
      fill="#fff"
    />
  </Icon>
);

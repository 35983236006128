import { Box, Circle, useColorModeValue, HStack } from '@chakra-ui/react';

const MessageStatus = ({ status, type, showLabel = true }) => {
  const batchStatusConfig = {
    processing: {
      label: 'Processing',
      color: 'warning',
    },
    processed: {
      label: 'Processed',
      color: 'warning',
    },
    scheduled: {
      label: 'Scheduled',
      color: 'blue.500',
    },
    completed: {
      label: 'Completed',
      color: 'success',
    },
    failed: {
      label: 'Failed',
      color: 'error',
    },
    partially_canceled: {
      label: 'Partially Canceled',
      color: 'error',
    },
    canceled: {
      label: 'Canceled',
      color: 'error',
    },
    cancellation_failed: {
      label: 'Cancellation Failed',
      color: 'error',
    },
  };

  const logStatusConfig = {
    pending: {
      label: 'Pending',
      color: 'gray',
    },
    scheduled: {
      label: 'Scheduled',
      color: 'blue.500',
    },
    queued: {
      label: 'Queued',
      color: 'orange',
    },
    sent: {
      label: 'Sent',
      color: 'green',
    },
    failed: {
      label: 'Failed',
      color: 'error',
    },
    delivered: {
      label: 'Delivered',
      color: 'green',
    },
    undelivered: {
      label: 'Undelivered',
      color: 'error',
    },
    accepted: {
      label: 'Accepted',
      color: 'blue',
    },
    canceled: {
      label: 'Canceled',
      color: 'error',
    },
    cancellation_failed: {
      label: 'Cancellation Failed',
      color: 'error',
    },
  };

  const passCreationBatch = {
    processing: {
      label: 'Processing',
      color: 'warning',
    },
    successful: {
      label: 'Successful',
      color: 'success',
    },
    failed: {
      label: 'Failed',
      color: 'error',
    },
  };

  const statusConfig =
    type === 'log'
      ? logStatusConfig
      : type === 'pass'
        ? passCreationBatch
        : batchStatusConfig;

  const currentStatus = statusConfig[String(status)] || {
    label: 'Unknown',
    color: 'gray',
  };

  return (
    <HStack
      pr={showLabel ? '12px' : '0px'}
      pl={showLabel ? '12px' : '0px'}
      py={showLabel ? '7px' : '0px'}
      bg={showLabel ? '#ddd' : 'transparent'}
      borderRadius="20px"
      justifyContent="center"
      alignItems="center"
      width="fit-content"
    >
      <Circle bg={currentStatus.color} size="12px" />
      {showLabel && (
        <Box
          fontSize="12px"
          top="1px"
          pos="relative"
          pb="1px"
          color="secondaryDark"
          whiteSpace="nowrap"
        >
          {currentStatus.label}
        </Box>
      )}
    </HStack>
  );
};

export default MessageStatus;

import ReadersCreatePageForm from './ReadersCreatePageForm';
import { ReadersContextProvider } from '../../store/client/ReadersContext';
import UseReader from '../../hooks/useReader';

const EventsEditPage = () => {
  return (
    <ReadersContextProvider>
      <UseReader>
        <ReadersCreatePageForm isEdit />
      </UseReader>
    </ReadersContextProvider>
  );
};
export default EventsEditPage;

import {
  Box,
  Button,
  Collapse,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Heading,
  Hide,
  Input,
  Link,
  Show,
  Text,
  useColorModeValue,
  useOutsideClick,
  Tooltip,
} from '@chakra-ui/react';
import { useContext, useEffect, useRef, useState } from 'react';
import PassTemplateContext from '../../../store/client/PassTemplateContext';
import { CustomEditIcon } from '../../../theme/icons/CustomEditIcon';
import { CustomPlusIcon } from '../../../theme/icons/CustomPlusIcon';
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import { CustomExclamationMarkInverseIcon } from '../../../theme/icons/CustomExclamationMarkInverseIcon';

const FieldLabel = ({
  balanceTooltip,
  displayName,
  extraLabelText,
  bgTooltip,
  textTooltip,
  showTooltipHandler,
  modeHelperText,
  tooltipRef,
}) => {
  return (
    <>
      {balanceTooltip ? (
        <Flex
          direction="row"
          align="center"
          alignContent="center"
          alignItems="center"
          gap={1}
          my={0.5}
        >
          <FormLabel m={0}>{displayName}</FormLabel>
          <Tooltip
            label="Refers to a customer’s loyalty card balance (points, miles, etc.)."
            hasArrow
            placement="top"
            fontSize="12px"
            bg={bgTooltip}
            color={textTooltip}
            w="205px"
            borderRadius="6px"
            textAlign="center"
            p="10px"
          >
            <Box
              data-testid="tooltip-button"
              onClick={(e) => showTooltipHandler(e, 'balance')}
              ref={tooltipRef}
              mb={1}
              cursor="pointer"
            >
              <CustomExclamationMarkInverseIcon boxSize="12px" mode={'light'} />
            </Box>
          </Tooltip>
        </Flex>
      ) : (
        <>
          {extraLabelText ? (
            <FormLabel>
              {displayName}
              <Text
                color={modeHelperText}
                textStyle="bodyFamilyRegular"
                fontSize="12px"
                mb={1}
              >
                {extraLabelText}
              </Text>
            </FormLabel>
          ) : (
            <FormLabel pt="5px">{displayName}</FormLabel>
          )}
        </>
      )}
    </>
  );
};

const FieldDetails = (props) => {
  const {
    isEdit,
    isRequired,
    displayName,
    display,
    fieldName,
    editedFieldName,
    editedFields,
    setEditedFields,
    fieldOpen,
    setFieldOpen,
    showTooltipHandler,
    balanceTooltip,
    onClearFieldsOpen,
    isClearFieldsOpen,
    extraLabelText,
    displayDefaultValue,
    requiredFieldError,
    handleUpdateStampKeys,
    defaultValuePlaceholder,
    onErrorChange,
  } = props;

  const passTemplateCtx = useContext(PassTemplateContext);
  const modeFormControlBg = useColorModeValue('white', '');
  const modeLabelBg = useColorModeValue('#EFEFEF', 'primaryDark03');
  const modeBorderColor = useColorModeValue('primaryBackground', 'primaryDark');
  const modeHelperText = useColorModeValue('secondaryDark08', '#DDDDDD');
  const capitalizedFieldName =
    fieldName.charAt(0).toUpperCase() + fieldName.slice(1);
  const [fieldIdErrorMessage, setFieldIdErrorMessage] = useState('');
  const [fieldLabelErrorMessage, setFieldLabelErrorMessage] = useState('');
  const id = passTemplateCtx[`${fieldName}Id`];
  const label = passTemplateCtx[`${fieldName}Label`];
  const value = passTemplateCtx[`${fieldName}Value`];
  const ref = useRef();
  const bgTooltip = useColorModeValue('secondaryDark', '#dddddd');
  const textTooltip = useColorModeValue('#ffffff', 'primaryDark');
  const tooltipRef = useRef();

  const handleCloseField = () => {
    if (!fieldIdErrorMessage && !fieldLabelErrorMessage && !requiredFieldError)
      setFieldOpen(false);
  };

  const handleBlur = (fieldType) => {
    const suffix =
      fieldType === 'id' ? 'Id' : fieldType === 'label' ? 'Label' : 'Value';
    const value = passTemplateCtx[`${fieldName}${suffix}`];
    if (value.trim() === '') {
      passTemplateCtx[`update${capitalizedFieldName}${suffix}`]('');
      if (isEdit) {
        setEditedFields({
          ...editedFields,
          [`${editedFieldName}_${fieldType}`]: '',
        });
      }
    }
  };

  function isValidFieldId(str) {
    const validIdFormat = /^[a-zA-Z0-9_ ]*$/;
    return validIdFormat.test(str);
  }

  const handleIdChange = (e) => {
    if (passTemplateCtx.passType === 'stampCard') {
      handleUpdateStampKeys(fieldName, e.target.value);
    }
    passTemplateCtx[`update${capitalizedFieldName}Id`](e.target.value);
    if (isEdit) {
      setEditedFields({
        ...editedFields,
        [`${editedFieldName}_id`]: e.target.value,
      });
    }
  };

  const isClickInsideElement = (elementId, event) => {
    const element = document.getElementById(elementId);
    return element && element.contains(event.target);
  };

  useOutsideClick({
    ref: ref,
    handler: (event) => {
      const elementsToCheck = [
        'loyaltyConfigNumberType',
        'loyaltyConfigEnabled',
      ];

      const clickInsideAnyElement = elementsToCheck.some((id) =>
        isClickInsideElement(id, event)
      );

      if (clickInsideAnyElement && fieldName === 'headerOne') {
        return;
      }

      if (
        !fieldIdErrorMessage &&
        !fieldLabelErrorMessage &&
        !requiredFieldError &&
        !isClearFieldsOpen
      ) {
        setFieldOpen(false);
      }
    },
  });

  useEffect(() => {
    if (
      (!passTemplateCtx[`${fieldName}Id`] &&
        (passTemplateCtx[`${fieldName}Label`] ||
          passTemplateCtx[`${fieldName}Value`])) ||
      (!passTemplateCtx[`${fieldName}Id`] && isRequired)
    ) {
      setFieldIdErrorMessage('Field ID is required');
      return;
    } else if (
      passTemplateCtx[`${fieldName}Id`] &&
      !isValidFieldId(passTemplateCtx[`${fieldName}Id`])
    ) {
      setFieldIdErrorMessage('Must not contain special characters');
      return;
    } else if (
      (passTemplateCtx[`${fieldName}Id`] &&
        isValidFieldId(passTemplateCtx[`${fieldName}Id`])) ||
      (!passTemplateCtx[`${fieldName}Label`] &&
        !passTemplateCtx[`${fieldName}Value`])
    ) {
      setFieldIdErrorMessage('');
    }
  }, [id, label, value, fieldName, passTemplateCtx]);

  useEffect(() => {
    if (
      isRequired &&
      fieldName === 'primaryOne' &&
      !passTemplateCtx[`${fieldName}Label`]
    ) {
      setFieldLabelErrorMessage('Field label is required');
      return;
    } else {
      setFieldLabelErrorMessage('');
    }
  }, [label, value, fieldName, passTemplateCtx]);

  useEffect(() => {
    if (display === 'none') {
      setFieldOpen(false);
    }
  }, [display]);

  useEffect(() => {
    const hasError =
      !!fieldIdErrorMessage || !!fieldLabelErrorMessage || !!requiredFieldError;
    onErrorChange(fieldName, hasError);
  }, [fieldIdErrorMessage, fieldLabelErrorMessage, requiredFieldError]);

  return (
    <>
      <Box ref={ref}>
        {!fieldOpen ? (
          <FormControl
            isRequired={isRequired}
            display={display}
            bg={modeFormControlBg}
          >
            <Flex alignItems="center">
              <Hide below="2xl">
                <Box pl="24px" flexShrink={0} w="240px">
                  <FieldLabel
                    balanceTooltip={balanceTooltip}
                    displayName={displayName}
                    extraLabelText={extraLabelText}
                    bgTooltip={bgTooltip}
                    textTooltip={textTooltip}
                    showTooltipHandler={showTooltipHandler}
                    tooltipRef={tooltipRef}
                    modeHelperText={modeHelperText}
                  />
                  <Text
                    color={modeHelperText}
                    textStyle="bodyFamilyRegular"
                    fontSize="12px"
                  >
                    {passTemplateCtx[`${fieldName}Id`]}
                  </Text>
                </Box>
              </Hide>
              <Box p="14px" w="full" bg={modeLabelBg}>
                <Show below="2xl">
                  <FieldLabel
                    balanceTooltip={balanceTooltip}
                    displayName={displayName}
                    extraLabelText={extraLabelText}
                    bgTooltip={bgTooltip}
                    textTooltip={textTooltip}
                    showTooltipHandler={showTooltipHandler}
                    tooltipRef={tooltipRef}
                    modeHelperText={modeHelperText}
                  />

                  <Text
                    color={modeHelperText}
                    textStyle="bodyFamilyRegular"
                    fontSize="12px"
                  >
                    {passTemplateCtx[`${fieldName}Id`]}
                  </Text>
                </Show>
                <Flex
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  onClick={() => setFieldOpen(!fieldOpen)}
                  cursor="pointer"
                  pr={3}
                  py={extraLabelText ? 1.5 : 0}
                >
                  <>
                    <Box
                      as={Link}
                      onClick={() => setFieldOpen(!fieldOpen)}
                      cursor="pointer"
                    >
                      <HStack zIndex={1} padding="10px">
                        <>
                          {passTemplateCtx[`${fieldName}Id`] ||
                          passTemplateCtx[`${fieldName}Label`] ||
                          passTemplateCtx[`${fieldName}Value`] ? (
                            <>
                              <CustomEditIcon color="brand" boxSize="14px" />
                              <Heading
                                color="secondaryDark"
                                fontSize="14px"
                                top="1px"
                                pos="relative"
                              >
                                Edit
                              </Heading>
                            </>
                          ) : (
                            <>
                              <CustomPlusIcon color="brand" boxSize="12px" />
                              <Heading
                                color="secondaryDark"
                                fontSize="14px"
                                top="1px"
                                pos="relative"
                              >
                                Add
                              </Heading>
                            </>
                          )}
                        </>
                      </HStack>
                    </Box>
                    <ChevronDownIcon color="secondaryDark" boxSize="20px" />
                  </>
                </Flex>
              </Box>
            </Flex>
          </FormControl>
        ) : (
          <FormControl
            isRequired={isRequired}
            display={display}
            bg={modeFormControlBg}
          >
            <Flex alignItems="center">
              <Hide below="2xl">
                <Box pl="24px" flexShrink={0} w="240px">
                  <FieldLabel
                    balanceTooltip={balanceTooltip}
                    displayName={displayName}
                    extraLabelText={extraLabelText}
                    bgTooltip={bgTooltip}
                    textTooltip={textTooltip}
                    showTooltipHandler={showTooltipHandler}
                    tooltipRef={tooltipRef}
                    modeHelperText={modeHelperText}
                  />
                </Box>
              </Hide>
              <Box p="14px" w="full" bg={modeLabelBg}>
                <Show below="2xl">
                  <Flex
                    direction={!extraLabelText && 'row'}
                    justifyContent="space-between"
                    alignItems="center"
                    onClick={() => handleCloseField()}
                    cursor="pointer"
                    pr={3}
                  >
                    <FieldLabel
                      balanceTooltip={balanceTooltip}
                      displayName={displayName}
                      extraLabelText={extraLabelText}
                      bgTooltip={bgTooltip}
                      textTooltip={textTooltip}
                      showTooltipHandler={showTooltipHandler}
                      tooltipRef={tooltipRef}
                      modeHelperText={modeHelperText}
                    />
                    <ChevronUpIcon color="gray" boxSize="20px" />
                  </Flex>
                </Show>
                <Hide below="2xl">
                  <Flex
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                    onClick={() => handleCloseField()}
                    cursor="pointer"
                    pr={3}
                    pt={extraLabelText ? 3 : 0}
                  >
                    <ChevronUpIcon color="gray" boxSize="20px" />
                  </Flex>
                </Hide>
                {requiredFieldError && (
                  <Text
                    style={{
                      fontSize: '12px',
                      paddingLeft: '10px',
                      color: '#E53E3E',
                    }}
                  >
                    Field is required
                  </Text>
                )}
              </Box>
            </Flex>
          </FormControl>
        )}
        <Collapse in={fieldOpen} animateOpacity>
          <FormControl
            isRequired={
              passTemplateCtx[`${fieldName}Label`] ||
              passTemplateCtx[`${fieldName}Value`] ||
              isRequired
            }
            isInvalid={fieldIdErrorMessage}
            bg={modeFormControlBg}
          >
            <Flex alignItems="center">
              <Hide below="2xl">
                <Box pl="24px" flexShrink={0} w="240px">
                  <Flex
                    direction="row"
                    align="center"
                    alignContent="center"
                    alignItems="center"
                    gap={1}
                  >
                    <FormLabel m={0}>ID</FormLabel>
                    <Tooltip
                      label={
                        'Used to target the pass field via API. We recommend separating words with underscores. No other special characters permitted. Example: membership_type, full_name, etc.'
                      }
                      hasArrow
                      placement="top"
                      fontSize="12px"
                      bg={bgTooltip}
                      color={textTooltip}
                      w="205px"
                      borderRadius="6px"
                      textAlign="center"
                      p="10px"
                    >
                      <Box
                        onClick={(e) => showTooltipHandler(e, 'id')}
                        ref={tooltipRef}
                        cursor="pointer"
                      >
                        <CustomExclamationMarkInverseIcon
                          boxSize="12px"
                          mode={'light'}
                        />
                      </Box>
                    </Tooltip>
                  </Flex>
                </Box>
              </Hide>
              <Box p="14px" w="full" bg={modeLabelBg}>
                <Show below="2xl">
                  <Flex
                    direction="row"
                    align="center"
                    alignContent="center"
                    alignItems="center"
                    gap={1}
                    mb={2}
                  >
                    <FormLabel m={0}>ID</FormLabel>
                    <Tooltip
                      label={
                        'Used to target the pass field via API. We recommend separating words with underscores. No other special characters permitted. Example: membership_type, full_name, etc.'
                      }
                      hasArrow
                      placement="top"
                      fontSize="12px"
                      bg={bgTooltip}
                      color={textTooltip}
                      w="205px"
                      borderRadius="6px"
                      textAlign="center"
                      p="10px"
                    >
                      <Box
                        onClick={(e) => showTooltipHandler(e, 'id')}
                        ref={tooltipRef}
                        cursor="pointer"
                      >
                        <CustomExclamationMarkInverseIcon
                          boxSize="12px"
                          mode={'light'}
                        />
                      </Box>
                    </Tooltip>
                  </Flex>
                </Show>
                <Input
                  value={passTemplateCtx[`${fieldName}Id`]}
                  variant="filledForDarkBg"
                  id={`${fieldName}Id`}
                  type="text"
                  placeholder="Type a field ID here..."
                  onChange={(e) => handleIdChange(e)}
                  onBlur={() => handleBlur('id')}
                  autoComplete="off"
                  maxLength={30}
                />
                {fieldIdErrorMessage && (
                  <Text
                    style={{
                      fontSize: '12px',
                      paddingLeft: '22px',
                      paddingTop: '10px',
                      color: '#E53E3E',
                    }}
                  >
                    {fieldIdErrorMessage}
                  </Text>
                )}
              </Box>
            </Flex>
          </FormControl>
          <FormControl
            bg={modeFormControlBg}
            isRequired={isRequired && fieldName === 'primaryOne'}
            isInvalid={fieldLabelErrorMessage}
          >
            <Flex alignItems="center">
              <Hide below="2xl">
                <Box pl="24px" flexShrink={0} w="240px">
                  <Flex
                    direction="row"
                    align="center"
                    alignContent="center"
                    alignItems="center"
                    gap={1}
                    mb={2}
                  >
                    <FormLabel m={0}>Label</FormLabel>
                    <Tooltip
                      label={'Field label visible on pass.'}
                      hasArrow
                      placement="top"
                      fontSize="12px"
                      bg={bgTooltip}
                      color={textTooltip}
                      w="205px"
                      borderRadius="6px"
                      textAlign="center"
                      p="10px"
                    >
                      <Box
                        onClick={(e) => showTooltipHandler(e, 'label')}
                        ref={tooltipRef}
                        cursor="pointer"
                      >
                        <CustomExclamationMarkInverseIcon
                          boxSize="12px"
                          mode={'light'}
                        />
                      </Box>
                    </Tooltip>
                  </Flex>
                </Box>
              </Hide>
              <Box p="14px" w="full" bg={modeLabelBg}>
                <Show below="2xl">
                  <Flex
                    direction="row"
                    align="center"
                    alignContent="center"
                    alignItems="center"
                    gap={1}
                    mb={2}
                  >
                    <FormLabel m={0}>Label</FormLabel>
                    <Tooltip
                      label={'Field label visible on pass.'}
                      hasArrow
                      placement="top"
                      fontSize="12px"
                      bg={bgTooltip}
                      color={textTooltip}
                      w="205px"
                      borderRadius="6px"
                      textAlign="center"
                      p="10px"
                    >
                      <Box
                        onClick={(e) => showTooltipHandler(e, 'label')}
                        ref={tooltipRef}
                        cursor="pointer"
                      >
                        <CustomExclamationMarkInverseIcon
                          boxSize="12px"
                          mode={'light'}
                        />
                      </Box>
                    </Tooltip>
                  </Flex>
                </Show>
                <Input
                  value={passTemplateCtx[`${fieldName}Label`]}
                  variant="filledForDarkBg"
                  id={`${fieldName}Label`}
                  type="text"
                  placeholder="Type a field label here..."
                  onChange={(e) => {
                    passTemplateCtx[`update${capitalizedFieldName}Label`](
                      e.target.value
                    );
                    if (isEdit) {
                      setEditedFields({
                        ...editedFields,
                        [`${editedFieldName}_label`]: e.target.value,
                      });
                    }
                  }}
                  onBlur={() => handleBlur('label')}
                  autoComplete="off"
                  maxLength={30}
                />
                {fieldLabelErrorMessage && (
                  <Text
                    style={{
                      fontSize: '12px',
                      paddingLeft: '22px',
                      paddingTop: '10px',
                      color: '#E53E3E',
                    }}
                  >
                    {fieldLabelErrorMessage}
                  </Text>
                )}
                <FormErrorMessage></FormErrorMessage>
              </Box>
            </Flex>
          </FormControl>
          {displayDefaultValue && (
            <FormControl bg={modeFormControlBg}>
              <Flex alignItems="center">
                <Hide below="2xl">
                  <Box pl="24px" flexShrink={0} w="240px">
                    <Flex
                      direction="row"
                      align="center"
                      alignContent="center"
                      alignItems="center"
                      gap={1}
                      mb={2}
                    >
                      <FormLabel m={0}>Default value</FormLabel>
                      <Tooltip
                        label={
                          'An optional and static default value for the field, any value set/updated via an API call to the pass will override this value.'
                        }
                        hasArrow
                        placement="top"
                        fontSize="12px"
                        bg={bgTooltip}
                        color={textTooltip}
                        w="205px"
                        borderRadius="6px"
                        textAlign="center"
                        p="10px"
                      >
                        <Box
                          onClick={(e) => showTooltipHandler(e, 'value')}
                          ref={tooltipRef}
                          cursor="pointer"
                        >
                          <CustomExclamationMarkInverseIcon
                            boxSize="12px"
                            mode={'light'}
                          />
                        </Box>
                      </Tooltip>
                    </Flex>
                  </Box>
                </Hide>
                <Box p="14px" w="full" bg={modeLabelBg}>
                  <Show below="2xl">
                    <Flex
                      direction="row"
                      align="center"
                      alignContent="center"
                      alignItems="center"
                      gap={1}
                      mb={2}
                    >
                      <FormLabel m={0}>Default value</FormLabel>
                      <Tooltip
                        label={
                          'An optional and static default value for the field, any value set/updated via an API call to the pass will override this value.'
                        }
                        hasArrow
                        placement="top"
                        fontSize="12px"
                        bg={bgTooltip}
                        color={textTooltip}
                        w="205px"
                        borderRadius="6px"
                        textAlign="center"
                        p="10px"
                      >
                        <Box
                          onClick={(e) => showTooltipHandler(e, 'value')}
                          ref={tooltipRef}
                          cursor="pointer"
                        >
                          <CustomExclamationMarkInverseIcon
                            boxSize="12px"
                            mode={'light'}
                          />
                        </Box>
                      </Tooltip>
                    </Flex>
                  </Show>
                  <Input
                    value={passTemplateCtx[`${fieldName}Value`]}
                    variant="filledForDarkBg"
                    id={`${fieldName}Value`}
                    type="text"
                    placeholder={
                      defaultValuePlaceholder || 'Type a default value here...'
                    }
                    onChange={(e) => {
                      passTemplateCtx[`update${capitalizedFieldName}Value`](
                        e.target.value
                      );
                      if (isEdit) {
                        setEditedFields({
                          ...editedFields,
                          [`${editedFieldName}_default_value`]: e.target.value,
                        });
                      }
                    }}
                    onBlur={() => handleBlur('value')}
                    autoComplete="off"
                  />
                  <FormErrorMessage></FormErrorMessage>
                </Box>
              </Flex>
            </FormControl>
          )}
          {fieldOpen && (
            <FormControl bg={modeFormControlBg}>
              <Flex alignItems="center">
                <Hide below="2xl">
                  <Box pl="24px" flexShrink={0} w="240px">
                    <FormLabel pt="5px"></FormLabel>
                  </Box>
                </Hide>
                <Box p="14px" w="full" bg={modeLabelBg}>
                  <Show below="2xl">
                    <FormLabel></FormLabel>
                  </Show>
                  <Flex direction="row" alignItems="center" gap={3}>
                    <Button
                      size="xs"
                      px="5px"
                      onClick={() => {
                        if (
                          !fieldIdErrorMessage &&
                          !fieldLabelErrorMessage &&
                          !requiredFieldError
                        )
                          setFieldOpen(!fieldOpen);
                      }}
                      maxW="85px"
                    >
                      <HStack zIndex={1} padding="20px">
                        <Text fontSize="14px" top="1px" pos="relative">
                          Close
                        </Text>
                      </HStack>
                    </Button>
                    <Button
                      variant="secondary"
                      size="xs"
                      px="5px"
                      onClick={(event) => {
                        event.stopPropagation();
                        onClearFieldsOpen(
                          capitalizedFieldName,
                          editedFieldName
                        );
                      }}
                      maxW="85px"
                      isDisabled={
                        !passTemplateCtx[`${fieldName}Id`] &&
                        !passTemplateCtx[`${fieldName}Label`] &&
                        !passTemplateCtx[`${fieldName}Value`]
                      }
                    >
                      <HStack zIndex={1} padding="20px">
                        <Text fontSize="14px" top="1px" pos="relative">
                          Clear
                        </Text>
                      </HStack>
                    </Button>
                  </Flex>
                </Box>
              </Flex>
            </FormControl>
          )}
        </Collapse>
        <Divider display={display} borderColor={modeBorderColor} />
      </Box>
    </>
  );
};

export default FieldDetails;

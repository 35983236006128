import { Text, Container, useColorModeValue } from '@chakra-ui/react';

function SectionTitle(props) {
  return (
    <Container bg="quinaryBackground" borderBottom="solid 1px #DDD">
      <Container maxW="1180px" px="0" color={useColorModeValue('secondaryDark', 'white')}>
        <Text
          textStyle="headingFamilyMedium"
          fontSize={{ base: '24px', lg: '3vw' }}
          py="3vw"
        >
          {props.title}
        </Text>
      </Container>
    </Container>
  );
}

export default SectionTitle;

import { Textarea, useMediaQuery } from '@chakra-ui/react';
import ResizeTextarea from 'react-textarea-autosize';
import React from 'react';

export const TextareaAutosize = React.forwardRef((props, ref) => {
  const [isLargerThanMD] = useMediaQuery('(min-width: 48em)');
  const textareaRows = isLargerThanMD ? '4' : '4';

  return (
    <Textarea
      minH="unset"
      overflow={props.overflow ? props.overflow : 'hidden'}
      w="100%"
      resize="none"
      ref={ref}
      as={ResizeTextarea}
      {...props}
    />
  );
});

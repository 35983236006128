import appsignal from '../appsignal';
import { useContext, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router';
import PassContext from '../store/client/PassContext';
import PassTemplateContext from '../store/client/PassTemplateContext';
import { useToast } from '@chakra-ui/react';
import CustomToast from '../common/CustomToast';
import { getErrorResponsePayload } from '../utils/ajax';
import { HttpContext } from '../context/HttpContext';

const UseClientPass = ({ children }) => {
  const { uuid } = useParams();
  const { authAxios } = useContext(HttpContext);
  const passCtx = useContext(PassContext);
  const passTemplateCtx = useContext(PassTemplateContext);
  const toast = useToast();
  const navigate = useNavigate();

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await authAxios.get(`passes/${uuid}`);
        const passData = response?.data?.data?.data;
        const passContent = passData?.attributes?.passContent;
        const passDesign = passData?.attributes?.passDesign;
        const passStatus = passData?.attributes?.status;
        const passUrl = passData?.attributes?.downloadUrl;
        const passDeviceType = passData?.attributes?.deviceType;
        const passCreatedAt = passData?.attributes?.createdAt;
        const passDeactivatedAt = passData?.attributes?.deactivatedAt;
        const passAddedToWalletAt = passData?.attributes?.addedAt;
        const passExternalId = passData?.attributes?.extId;
        const passNfc = !!passData?.attributes?.nfc;
        const passQrValue = passData?.attributes?.barcode?.value;
        const passQrDisplayText =
          passData?.attributes?.barcode?.displayText != null
            ? passData?.attributes?.barcode?.displayText
            : '';
        const passGroupTag = passData?.attributes?.groupTag;
        const passExpiryDate = passData?.attributes?.expiresAt;
        const templateData = response?.data.meta.template;
        const templateFormatted = {
          templateName: templateData.name,
          uuid: templateData.uuid,
          passType: templateData.template_type,
          templateEntity: templateData.entity_id,
          headerOneId: templateData.header.header_one.id,
          headerOneLabel: templateData.header.header_one.label,
          headerOneValue: templateData.header.header_one.default_value,
          headerTwoId: templateData.header.header_two.id,
          headerTwoLabel: templateData.header.header_two.label,
          headerTwoValue: templateData.header.header_two.default_value,
          headerThreeId: templateData.header.header_three.id,
          headerThreeLabel: templateData.header.header_three.label,
          headerThreeValue: templateData.header.header_three.default_value,
          centralTitle: templateData.central_title,
          primaryOneId: templateData.primary.primary_one.id,
          primaryOneLabel: templateData.primary.primary_one.label,
          primaryOneValue: templateData.primary.primary_one.default_value,
          primaryTwoId: templateData.primary.primary_two.id,
          primaryTwoLabel: templateData.primary.primary_two.label,
          primaryTwoValue: templateData.primary.primary_two.default_value,
          secOneId: templateData.secondary.sec_one.id,
          secOneLabel: templateData.secondary.sec_one.label,
          secOneValue: templateData.secondary.sec_one.default_value,
          secTwoId: templateData.secondary.sec_two.id,
          secTwoLabel: templateData.secondary.sec_two.label,
          secTwoValue: templateData.secondary.sec_two.default_value,
          secThreeId: templateData.secondary.sec_three.id,
          secThreeLabel: templateData.secondary.sec_three.label,
          secThreeValue: templateData.secondary.sec_three.default_value,
          auxOneId: templateData.auxiliary.aux_one.id,
          auxOneLabel: templateData.auxiliary.aux_one.label,
          auxOneValue: templateData.auxiliary.aux_one.default_value,
          auxTwoId: templateData.auxiliary.aux_two.id,
          auxTwoLabel: templateData.auxiliary.aux_two.label,
          auxTwoValue: templateData.auxiliary.aux_two.default_value,
          auxThreeId: templateData.auxiliary.aux_three.id,
          auxThreeLabel: templateData.auxiliary.aux_three.label,
          auxThreeValue: templateData.auxiliary.aux_three.default_value,
          backOneId: templateData.back_fields.back_one.id,
          backOneLabel: templateData.back_fields.back_one.label,
          backOneValue: templateData.back_fields.back_one.default_value,
          backTwoId: templateData.back_fields.back_two.id,
          backTwoLabel: templateData.back_fields.back_two.label,
          backTwoValue: templateData.back_fields.back_two.default_value,
          backThreeId: templateData.back_fields.back_three.id,
          backThreeLabel: templateData.back_fields.back_three.label,
          backThreeValue: templateData.back_fields.back_three.default_value,
          backFourId: templateData.back_fields.back_four.id,
          backFourLabel: templateData.back_fields.back_four.label,
          backFourValue: templateData.back_fields.back_four.default_value,
          backFiveId: templateData.back_fields.back_five.id,
          backFiveLabel: templateData.back_fields.back_five.label,
          backFiveValue: templateData.back_fields.back_five.default_value,
          description: templateData.description,
          logoImage: templateData.logo_image,
          iconLogo: templateData.icon_image,
          bannerImage: templateData.banner_image,
          googleBannerImage: templateData.google_banner_image,
          thumbnailImage: templateData.thumbnail_image,
          bodyFontColor: templateData.text_color,
          passBackgroundColor: templateData.background_color,
          titleFontColor: templateData.label_color,
          appleAppLink: templateData.apple_app_link,
          googleAppLinkPlayStoreUrl:
            templateData.google_app_link.play_store_url,
          googleAppLinkPackageName: templateData.google_app_link.package_name,
          googleAppLinkWebAppUrl: templateData.google_app_link.web_app_url,
        };
        passTemplateCtx.updateAllData(templateFormatted);
        // Set pass status
        passCtx.passState.status = passStatus;

        // Set pass url
        passCtx.passState.url = passUrl;

        // Set pass device type
        passCtx.passState.deviceType = passDeviceType;

        // Set pass created at
        passCtx.passState.createdAt = passCreatedAt;

        // Set pass deactivated at
        passCtx.passState.deactivatedAt = passDeactivatedAt;

        // Set pass added to wallet at
        passCtx.passState.addedToWalletAt = passAddedToWalletAt;

        // Set pass group tag
        passCtx.passState.groupTag = passGroupTag
          ? { label: passGroupTag }
          : '';

        // Set pass expiry date
        passCtx.passState.expiryDate = passExpiryDate;

        // function camelize(str) {
        //   return str.replace(/(?:^\p{L}+|\s+\p{Ll}+|\s+)/gu, function (match, index) {
        //     if (+match === 0) return ''; // or if (/\s+/.test(match)) for white spaces
        //     return index === 0 ? match.toLowerCase() : match.toUpperCase();
        //   });
        // }

        passCtx.updateExternalId(passExternalId);
        passCtx.updateNfc(passNfc);
        passCtx.updateQrValue(passQrValue);
        passCtx.updateQrDisplayText(passQrDisplayText);
        passCtx.updateGroupTag(passGroupTag ? { label: passGroupTag } : '');
        passCtx.updateExpiryDate(passExpiryDate);
        !!passDesign && passCtx.updatePassDesign(passDesign);

        // Set pass field labels and values

        const formatId = (str) => {
          return str.replace(/\s/g, '').toLowerCase();
        };

        Object.entries(passContent).forEach(([key, value]) => {
          let formattedPassId = formatId(key);
          if (
            !!templateData.header.header_one.id &&
            formattedPassId === formatId(templateData.header.header_one.id)
          ) {
            passCtx.updateHeaderOne(value);
          }
          if (
            !!templateData.header.header_two.id &&
            formattedPassId === formatId(templateData.header.header_two.id)
          ) {
            passCtx.updateHeaderTwo(value);
          }
          if (
            !!templateData.header.header_three.id &&
            formattedPassId === formatId(templateData.header.header_three.id)
          ) {
            passCtx.updateHeaderThree(value);
          }
          if (
            !!templateData.primary.primary_one.id &&
            formattedPassId === formatId(templateData.primary.primary_one.id)
          ) {
            passCtx.updatePrimaryOne(value);
          }
          if (
            !!templateData.primary.primary_two.id &&
            formattedPassId === formatId(templateData.primary.primary_two.id)
          ) {
            passCtx.updatePrimaryTwo(value);
          }
          if (
            !!templateData.secondary.sec_one.id &&
            formattedPassId === formatId(templateData.secondary.sec_one.id)
          ) {
            passCtx.updateSecOne(value);
          }
          if (
            !!templateData.secondary.sec_two.id &&
            formattedPassId === formatId(templateData.secondary.sec_two.id)
          ) {
            passCtx.updateSecTwo(value);
          }
          if (
            !!templateData.secondary.sec_three.id &&
            formattedPassId === formatId(templateData.secondary.sec_three.id)
          ) {
            passCtx.updateSecThree(value);
          }
          if (
            !!templateData.auxiliary.aux_one.id &&
            formattedPassId === formatId(templateData.auxiliary.aux_one.id)
          ) {
            passCtx.updateAuxOne(value);
          }
          if (
            !!templateData.auxiliary.aux_two.id &&
            formattedPassId === formatId(templateData.auxiliary.aux_two.id)
          ) {
            passCtx.updateAuxTwo(value);
          }
          if (
            !!templateData.auxiliary.aux_three.id &&
            formattedPassId === formatId(templateData.auxiliary.aux_three.id)
          ) {
            passCtx.updateAuxThree(value);
          }
          if (
            !!templateData.back_fields.back_one.id &&
            formattedPassId === formatId(templateData.back_fields.back_one.id)
          ) {
            passCtx.updateBackOne(value);
          }
          if (
            !!templateData.back_fields.back_two.id &&
            formattedPassId === formatId(templateData.back_fields.back_two.id)
          ) {
            passCtx.updateBackTwo(value);
          }
          if (
            !!templateData.back_fields.back_three.id &&
            formattedPassId === formatId(templateData.back_fields.back_three.id)
          ) {
            passCtx.updateBackThree(value);
          }
          if (
            !!templateData.back_fields.back_four.id &&
            formattedPassId === formatId(templateData.back_fields.back_four.id)
          ) {
            passCtx.updateBackFour(value);
          }
          if (
            !!templateData.back_fields.back_five.id &&
            formattedPassId === formatId(templateData.back_fields.back_five.id)
          ) {
            passCtx.updateBackFive(value);
          }
        });

        passCtx.updateAllData({
          ...passCtx.passState,
          uuid: uuid,
          template: {
            name: templateData.name,
            uuid: templateData.uuid,
            passType: templateData.template_type,
            headerOneId: templateData.header.header_one.id,
            headerOneLabel: templateData.header.header_one.label,
            headerOneValue: templateData.header.header_one.default_value,
            headerTwoId: templateData.header.header_two.id,
            headerTwoLabel: templateData.header.header_two.label,
            headerTwoValue: templateData.header.header_two.default_value,
            headerThreeId: templateData.header.header_three.id,
            headerThreeLabel: templateData.header.header_three.label,
            headerThreeValue: templateData.header.header_three.default_value,
            centralTitle: templateData.central_title,
            primaryOneId: templateData.primary.primary_one.id,
            primaryOneLabel: templateData.primary.primary_one.label,
            primaryOneValue: templateData.primary.primary_one.default_value,
            primaryTwoId: templateData.primary.primary_two.id,
            primaryTwoLabel: templateData.primary.primary_two.label,
            primaryTwoValue: templateData.primary.primary_two.default_value,
            secOneId: templateData.secondary.sec_one.id,
            secOneLabel: templateData.secondary.sec_one.label,
            secOneValue: templateData.secondary.sec_one.default_value,
            secTwoId: templateData.secondary.sec_two.id,
            secTwoLabel: templateData.secondary.sec_two.label,
            secTwoValue: templateData.secondary.sec_two.default_value,
            secThreeId: templateData.secondary.sec_three.id,
            secThreeLabel: templateData.secondary.sec_three.label,
            secThreeValue: templateData.secondary.sec_three.default_value,
            auxOneId: templateData.auxiliary.aux_one.id,
            auxOneLabel: templateData.auxiliary.aux_one.label,
            auxOneValue: templateData.auxiliary.aux_one.default_value,
            auxTwoId: templateData.auxiliary.aux_two.id,
            auxTwoLabel: templateData.auxiliary.aux_two.label,
            auxTwoValue: templateData.auxiliary.aux_two.default_value,
            auxThreeId: templateData.auxiliary.aux_three.id,
            auxThreeLabel: templateData.auxiliary.aux_three.label,
            auxThreeValue: templateData.auxiliary.aux_three.default_value,
            backOneId: templateData.back_fields.back_one.id,
            backOneLabel: templateData.back_fields.back_one.label,
            backOneValue: templateData.back_fields.back_one.default_value,
            backTwoId: templateData.back_fields.back_two.id,
            backTwoLabel: templateData.back_fields.back_two.label,
            backTwoValue: templateData.back_fields.back_two.default_value,
            backThreeId: templateData.back_fields.back_three.id,
            backThreeLabel: templateData.back_fields.back_three.label,
            backThreeValue: templateData.back_fields.back_three.default_value,
            backFourId: templateData.back_fields.back_four.id,
            backFourLabel: templateData.back_fields.back_four.label,
            backFourValue: templateData.back_fields.back_four.default_value,
            backFiveId: templateData.back_fields.back_five.id,
            backFiveLabel: templateData.back_fields.back_five.label,
            backFiveValue: templateData.back_fields.back_five.default_value,
            status: passStatus,
            appleAppLink: templateData.apple_app_link,
            googleAppLinkPlayStoreUrl:
              templateData.google_app_link_play_store_url,
            googleAppLinkPackageName: templateData.google_app_link_package_name,
            googleAppLinkWebAppUrl: templateData.google_app_link_web_app_url,
          },
          // passEntity: item.pass,
          // addedToWallet: item?.addedToWalletAt,
          // lastScan: item?.lastScan?.createdAt,
        });
      } catch (error) {
        appsignal.sendError(error);
        console.log(error);
        const { message, code } = getErrorResponsePayload(error);
        code !== 401 &&
          toast({
            render: (props) => (
              <CustomToast
                status="error"
                title={message ? message : `Something went wrong`}
                description={!message && 'Please try again later'}
                onClose={props.onClose}
              />
            ),
          });
        navigate('/passes', { replace: true });
      }
    };

    getData();
  }, [authAxios]);

  return <>{children}</>;
  // }

  // return (
  //   <Spinner
  //     width="52px"
  //     height="52px"
  //     thickness="4px"
  //     speed="0.65s"
  //     emptyColor="quinaryBackground"
  //     m="auto"
  //   />
  // );
};

export default UseClientPass;

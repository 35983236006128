import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Button,
  Heading,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import { useContext } from 'react';
import PassContext from '../../store/client/PassContext';

const RevokeModal = ({ isOpen, onClose }) => {
  const passCtx = useContext(PassContext);
  const modeBoxBg = useColorModeValue('white', 'secondaryDark');
  const modeTextBg = useColorModeValue('white', 'primaryDark06');
  const modeLabelBg = useColorModeValue('white', 'primaryDark03');
  const modeOffset = useColorModeValue('0', '16px !important');

  // const [revokeStateLoading, setRevokeStateLoading] = useState(false);

  // useEffect(() => {
  //   if (!passCtx.passState.revoked) {
  //     setRevokeStateLoading(false);
  //   }
  // }, [passCtx.passState.revoked]);

  return (
    <Modal onClose={onClose} isOpen={isOpen} size="xl">
      <ModalOverlay />
      <ModalContent bg={modeBoxBg}>
        <ModalHeader bg={modeTextBg} pb={modeOffset} borderRadius="15px 15px 0 0">
          <Heading fontSize={{ base: '24px', lg: '32px' }} lineHeight="130%">
            Deactivate pass
          </Heading>
        </ModalHeader>
        <ModalBody bg={modeLabelBg}>
          <Text color={useColorModeValue('secondaryDark08', 'white')} display="inline">
            Once you deactivate a pass, user will not be able to enter the event.
          </Text>
        </ModalBody>
        <ModalFooter
          display="flex"
          justifyContent="flex-end"
          bg={modeTextBg}
          pt={modeOffset}
          borderRadius="0 0 15px 15px"
        >
          <Button
            alt="Cancel"
            width={{ base: 'full', sm: 'auto' }}
            variant="secondary"
            mr="10px"
            onClick={() => onClose()}
          >
            Cancel
          </Button>
          <Button
            alt="Deactivate pass"
            width={{ base: 'full', sm: 'auto' }}
            onClick={async () => {
              await passCtx.updateDeactivatedState(passCtx.passState.uuid);
              onClose();
              // setRevokeStateLoading(true);
            }}
            // isLoading={revokeStateLoading}
          >
            Deactivate pass
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
export default RevokeModal;

import { Button, Flex, Heading, Spacer, Text, useColorModeValue } from '@chakra-ui/react';
import { useContext, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { CustomSearchbar } from '../../common/CustomSearchbar';
import PassTemplatesListFilter from './PassTemplatesListFilter';
import PassTemplatesListContext from '../../../store/client/PassTemplatesListContext';
import useOrganizationStatus from '../../../hooks/useOrganisationStatus';

const PassTemplateHeader = (props) => {
  const passTemplatesListCtx = useContext(PassTemplatesListContext);
  const filters = getFiltersString(passTemplatesListCtx.filterValues);
  const [loadingDashHeader, setLoadingDashHeader] = useState(true);
  const [loadingDashBody, setLoadingDashBody] = useState(true);
  const filterModeColor = useColorModeValue('secondaryDark08', 'white');
  const { shouldRender } = useOrganizationStatus();

  function getFiltersString(filters) {
    const { passType, event } = filters;
    if (
      (passType === '' || passType === undefined) &&
      (event === '' || event === undefined)
    ) {
      return '';
    }

    let values = [];

    if (passType !== '') {
      values.push(passType);
    }
    if (event !== '') {
      values.push(event);
    }

    const count = values.length;
    let outString = '';
    values.forEach((value, index) => {
      if (index === count - 1) {
        outString = outString + value;
      } else {
        outString = outString + value + ', ';
      }
    });

    return outString;
  }

  return (
    <Flex
      flexDirection={{ base: 'column', sm: 'row' }}
      alignItems="center"
      flexWrap="wrap"
      mt="15px"
      py="9px"
      minH="54px"
    >
      <Heading alignSelf={{ base: 'flex-start', sm: 'center' }}>
        {filters === '' ? 'Pass Templates' : `Filter results: `}
        <Text
          as="span"
          display="inline-block"
          textStyle="bodyFamilyMedium"
          fontSize="16px"
          color={filterModeColor}
          wordBreak="break-word"
        >
          {filters}
        </Text>
      </Heading>
      {shouldRender && (
        <>
          <Spacer />
          <Flex
            direction={{ base: 'column', sm: 'row' }}
            w={{ base: '100%', sm: 'unset' }}
          >
            <CustomSearchbar
              handleInputSearch={props.handleInputSearch}
              setInputSearch={props.setInputSearch}
            />
            <Flex w={{ base: '100%', sm: 'unset' }} justify="flex-start">
              <Button
                alignSelf={{ base: 'flex-start', sm: 'center' }}
                mt={{ base: '12px', sm: '0' }}
                px={{ base: '17px', sm: '30px' }}
                alt="Create pass template"
                size="sm"
                as={RouterLink}
                to="/pass-templates/create"
                minW="180px"
                mr={{ base: '5px', sm: '15px' }}
                flexGrow="1"
              >
                Create pass template
              </Button>
              <PassTemplatesListFilter
                loadingDashHeader={loadingDashHeader}
                loadingDashBody={loadingDashBody}
                setLoadingDashBody={setLoadingDashBody}
                setLoadingDashHeader={setLoadingDashHeader}
              />
            </Flex>
          </Flex>
        </>
      )}
    </Flex>
  );
};
export default PassTemplateHeader;

import React, { createContext, useState } from 'react';

const MeContext = createContext({
  state: {
    account: null,
    organisationStatus: null,
    trialExpiry: null,
    watermarkEnabled: null,
    entities: null,
    filterEntity: null,
    passTemplates: null,
    invitations: null,
    invitationLimit: null,
    invitedAccount: null,
    batchNotificationsEnabled: null,
    status: 'idle',
    error: null,
  },
  updateState: () => {},
  isSetUpConfirmed: () => {},
  isOrganisationActive: () => {},
});
const { Provider } = MeContext;

const MeProvider = ({ children }) => {
  const [state, setState] = useState({
    account: null,
    organisationStatus: null,
    trialExpiry: null,
    watermarkEnabled: null,
    entities: null,
    filterEntity: null,
    passTemplates: null,
    invitations: null,
    invitationLimit: null,
    invitedAccount: null,
    batchNotificationsEnabled: null,
    status: 'idle',
    error: null,
  });

  function updateStateHandler({
    account,
    organisationStatus,
    trialExpiry,
    watermarkEnabled,
    entities,
    passTemplates,
    invitations,
    invitationLimit,
    invitedAccount,
    batchNotificationsEnabled,
    status,
    error,
  }) {
    setState({
      account,
      organisationStatus,
      trialExpiry,
      watermarkEnabled,
      entities,
      passTemplates,
      invitations,
      invitationLimit,
      invitedAccount,
      batchNotificationsEnabled,
      status,
      error,
    });
  }

  function updateFilterEntityHandler(filterEntity) {
    setState({
      ...state,
      filterEntity,
    });
  }

  function isSetUpConfirmed() {
    const { account } = state;
    return account?.is_active;
  }

  function accountDashboardPassTemplateFilter() {
    return state?.account?.filters?.dashboardMeta?.passTemplateFilter;
  }

  function isOrganisationActive() {
    const { organisationStatus } = state;
    return organisationStatus === 'trial' || organisationStatus === 'live';
  }

  function userDashboardTimeFilter() {
    return state?.account?.filters?.dashboardMeta?.timeFilter;
  }

  return (
    <Provider
      value={{
        state,
        updateState: (
          account,
          organisationStatus,
          trialExpiry,
          watermarkEnabled,
          entities,
          passTemplates,
          invitations,
          invitationLimit,
          invitedAccount,
          batchNotificationsEnabled
        ) =>
          updateStateHandler(
            account,
            organisationStatus,
            trialExpiry,
            watermarkEnabled,
            entities,
            passTemplates,
            invitations,
            invitationLimit,
            invitedAccount,
            batchNotificationsEnabled
          ),
        isSetUpConfirmed,
        isOrganisationActive,
        accountDashboardPassTemplateFilter,
        userDashboardTimeFilter,
        updateFilterEntity: updateFilterEntityHandler,
      }}
    >
      {children}
    </Provider>
  );
};

export { MeContext, MeProvider };

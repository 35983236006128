import { PassContextProvider } from '../../store/client/PassContext';
import UseClientPass from '../../hooks/useClientPass';
import PassesCreatePageForm from './PassesCreatePageForm';
import { PassTemplateContextProvider } from '../../store/client/PassTemplateContext';

const PassesEditPage = () => {
  return (
    <PassContextProvider>
      <PassTemplateContextProvider>
        <UseClientPass>
          <PassesCreatePageForm isEdit />
        </UseClientPass>
      </PassTemplateContextProvider>
    </PassContextProvider>
  );
};

export default PassesEditPage;

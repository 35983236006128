import { Global } from '@emotion/react';
import interBoldUrl from '../assets/fonts/Inter-Bold.otf';
import interMediumUrl from '../assets/fonts/Inter-Medium.otf';
import interRegularUrl from '../assets/fonts/Inter-Regular.otf';
import openSansRegularUrl from '../assets/fonts/OpenSans-Regular.otf';
import openSansSemiBoldUrl from '../assets/fonts/OpenSans-SemiBold.otf';
import sfProDisplayRegularUrl from '../assets/fonts/SfProDisplay-Regular.otf';
import sfProDisplayMediumUrl from '../assets/fonts/SfProDisplay-Medium.otf';
import spaceGroteskBoldUrl from '../assets/fonts/SpaceGrotesk-Bold.otf';

const Fonts = () => (
  <Global
    styles={`
      /* latin */
      @font-face {
        font-family: 'Inter Bold';
        font-style: normal;
        font-weight: 700;
        font-display: swap;
				src: local(''), url(${interBoldUrl}) format('opentype');
      }
      /* latin */
      @font-face {
        font-family: 'Inter Medium';
        font-style: normal;
        font-weight: 500;
        font-display: swap;
				src: local(''), url(${interMediumUrl}) format('opentype');
      }
      /* latin */
      @font-face {
        font-family: 'Inter Regular';
        font-style: normal;
        font-weight: 400;
        font-display: swap;
				src: local(''), url(${interRegularUrl}) format('opentype');
      }
      /* latin */
      @font-face {
        font-family: 'Open Sans Regular';
        font-style: normal;
        font-weight: 400;
        font-display: swap;
				src: local(''), url(${openSansRegularUrl}) format('opentype');
      }
      /* latin */
      @font-face {
        font-family: 'Open Sans SemiBold';
        font-style: normal;
        font-weight: 600;
        font-display: swap;
				src: local(''), url(${openSansSemiBoldUrl}) format('opentype');
      }
      /* latin */
      @font-face {
        font-family: 'Sf Pro Display Regular';
        font-style: normal;
        font-weight: 400;
        font-display: swap;
				src: local(''), url(${sfProDisplayRegularUrl}) format('opentype');
      }
      /* latin */
      @font-face {
        font-family: 'Sf Pro Display Medium';
        font-style: normal;
        font-weight: 500;
        font-display: swap;
				src: local(''), url(${sfProDisplayMediumUrl}) format('opentype');
      }
      /* latin */
      @font-face {
        font-family: 'Space Grotesk Bold';
        font-style: normal;
        font-weight: 700;
        font-display: swap;
        src: local(''), url(${spaceGroteskBoldUrl}) format('opentype');
      `}
  />
);

export default Fonts;

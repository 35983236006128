import appsignal from '../../../appsignal';
import { Skeleton, useColorModeValue, useToast } from '@chakra-ui/react';
import { useContext } from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import CustomToast from '../../../common/CustomToast';
import { HttpContext } from '../../../context/HttpContext';
import { MeContext } from '../../../context/MeContext';
import { ClientDashboardStatsContext } from '../../../store/client/ClientDashboardStatsContext';
import { getErrorResponsePayload } from '../../../utils/ajax';
import {
  CustomReactSelect,
  formatOptionLabelFilterPassTemplate,
} from '../../common/CustomReactSelect';

const DashboardPassTemplateFilter = ({
  loadingDashHeader,
  setLoadingDashHeader,
}) => {
  const toast = useToast();
  const { authAxios } = useContext(HttpContext);
  const [passTemplatesList, setPassTemplatesList] = useState([]);
  const { updateFilterPassTemplate, filterPassTemplate } = useContext(
    ClientDashboardStatsContext
  );
  const meCtx = useContext(MeContext);
  const { filterEntity } = meCtx.state;
  const { accountDashboardPassTemplateFilter } = useContext(MeContext);
  const accountPassTemplateUuid = accountDashboardPassTemplateFilter();
  const modeSkeletonStartColor = useColorModeValue('#d8d8d8', '#29323A');
  const modeSkeletonEndColor = useColorModeValue('#fff', '#515d66');

  // Define pass template dropdown
  useEffect(() => {
    const getData = async () => {
      try {
        let response;
        let templates = [];
        let passTemplates = [];
        if (filterEntity) {
          response = await authAxios.get(
            `entities/${filterEntity.uuid}/passes/templates?fields=name`
          );
        } else {
          response = await authAxios.get(`passes/templates?fields=name`);
        }
        passTemplates = response.data.data;
        if (passTemplates) {
          passTemplates.sort(
            (a, b) => new Date(b.created_at) - new Date(a.created_at)
          );
          passTemplates.forEach((item) => {
            templates.push({
              label: item.name,
              value: item,
            });
          });
        }
        updateFilterPassTemplate({ label: 'All pass templates' });
        setPassTemplatesList([{ label: 'All pass templates' }, ...templates]);
        setLoadingDashHeader(false);
      } catch (error) {
        appsignal.sendError(error);
        setLoadingDashHeader(false);
        console.log(error);
        const { message, code } = getErrorResponsePayload(error);
        code !== 401 &&
          toast({
            render: (props) => (
              <CustomToast
                status="error"
                title={message ? message : `Something went wrong`}
                description={!message && 'Please try again later'}
                onClose={props.onClose}
              />
            ),
          });
      }
    };

    getData();

    return () => {
      setLoadingDashHeader(true);
      setPassTemplatesList([]);
    };
  }, [authAxios, filterEntity]);

  // remember filtered Pass Template
  useEffect(() => {
    if (
      accountPassTemplateUuid &&
      accountPassTemplateUuid !== null &&
      passTemplatesList?.length !== 0
    ) {
      const [filteredPassTemplate] = passTemplatesList.filter(
        (passTemplate) => passTemplate?.value?.uuid === accountPassTemplateUuid
      );
      updateFilterPassTemplate(() => filteredPassTemplate);
    }
  }, [accountPassTemplateUuid, passTemplatesList]);

  if (loadingDashHeader) {
    return (
      <Skeleton
        width="200px"
        borderRadius="15px"
        startColor={modeSkeletonStartColor}
        endColor={modeSkeletonEndColor}
      />
    );
  }
  return (
    <CustomReactSelect
      options={passTemplatesList}
      formatOptionLabel={formatOptionLabelFilterPassTemplate}
      onChange={(passTemplate) => {
        updateFilterPassTemplate(passTemplate.value);
      }}
      value={
        filterPassTemplate
          ? filterPassTemplate.label
          : { label: 'All pass templates' }
      }
      placeholder="All pass templates"
      id="passTemplate"
      PassTemplate
    />
  );
};

export default DashboardPassTemplateFilter;

import { useColorModeValue, GridItem, Text, Divider } from '@chakra-ui/react';

const MoreInfoLine = ({ heading, value }) => {
  const modeIconColor = useColorModeValue('secondaryDark08', '#DDDDDD');
  const modeBorderColor = useColorModeValue('primaryBackground', 'primaryDark');

  return (
    <>
      <GridItem
        fontFamily="Inter Medium, sans-serif"
        fontSize="12px"
        color={modeIconColor}
        textTransform="uppercase"
        lineHeight="21px"
        padding="16px 4px 16px 22px"
      >
        {heading}
      </GridItem>
      <GridItem
        fontFamily="Inter Bold, sans-serif"
        fontSize="14px"
        padding="16px 10px 16px 22px"
        bg="quinaryBackground"
      >
        <Text wordBreak="break-word">
          {typeof value === 'string' ? (
            <Text wordBreak="break-word">{value}</Text>
          ) : (
            value
          )}
        </Text>
      </GridItem>
      <GridItem colSpan={2} w="full">
        <Divider borderColor={modeBorderColor} />
      </GridItem>
    </>
  );
};

export default MoreInfoLine;

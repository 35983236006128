import {
  Box,
  Text,
  HStack,
  Image,
  useMediaQuery,
  useColorModeValue,
  Flex,
  Circle,
} from '@chakra-ui/react';
import { CustomAndroidIcon } from '../../../theme/icons/CustomAndroidIcon';
import { ANDROID_SCREEN_BASE64 } from '../../../utils/base64';
import { CustomChevronLeftIcon } from '../../../theme/icons/CustomChevronLeftIcon';

function PreviewPassNotificationsAndroid({ previewData }) {
  const [isLargeForParallelDisplay] = useMediaQuery('(min-width: 1530px)');
  const modeIconColor = useColorModeValue('secondaryDark', '#DDDDDD');

  return (
    <Box
      m="0 auto"
      w="330px"
      maxW="full"
      borderRadius="15px"
      alignItems="start"
    >
      {isLargeForParallelDisplay && !previewData.inList && (
        <HStack alignItems="baseline" color={modeIconColor} mb="10px">
          <CustomAndroidIcon fontSize="18px" />
          <Text textStyle="bodyMedium">Android</Text>
        </HStack>
      )}
      <Flex direction="column" position="relative" align="center">
        <Box>
          <Image
            width="auto"
            height="auto"
            maxH={previewData.smallerDisplay && '600px'}
            src={ANDROID_SCREEN_BASE64}
            alt="android-screen"
          />
        </Box>
        <Box
          className="android-notification"
          w="70vw"
          maxW={previewData.smallerDisplay ? '260px' : '310px'}
          borderRadius="15px"
          minH={previewData.smallerDisplay ? '50px' : '60px'}
          bg="rgba(255, 255, 255, 0.8)"
          position="absolute"
          top="35%"
        >
          <Flex direction="column" py={3} pl={6} pr={4}>
            <Flex
              direction="row"
              justifyContent="space-between"
              alignContent="center"
            >
              <Flex gap={1} alignItems="center" align="center">
                <Text
                  textStyle="headingFamilyRegularGooglePay"
                  fontSize={previewData.smallerDisplay ? '11px' : '12px'}
                  lineHeight="base"
                >
                  Google Wallet
                </Text>
                <Text
                  textStyle="headingFamilyRegularGooglePay"
                  fontSize={previewData.smallerDisplay ? '11px' : '12px'}
                  lineHeight="base"
                >
                  •
                </Text>
                <Text
                  textStyle="headingFamilyRegularGooglePay"
                  fontSize={previewData.smallerDisplay ? '11px' : '12px'}
                  lineHeight="base"
                >
                  now
                </Text>
              </Flex>
              <Flex gap={1}>
                <Circle size="35px">
                  <Image
                    borderRadius="100%"
                    boxSize="full"
                    objectFit="cover"
                    src={previewData.iconLogo}
                    alt=""
                  />
                </Circle>
                <CustomChevronLeftIcon
                  transform="rotate(270deg)"
                  w="20px"
                  h="20px"
                  color="#A1A1A1"
                />
              </Flex>
            </Flex>
            <Flex direction="column" justifyContent="start" gap={1}>
              <Flex width="full" justifyContent="space-between">
                <Flex direction="column" textAlign="start" gap={1}>
                  <Text
                    textStyle="headingFamilySemiBoldGooglePay"
                    fontSize={previewData.smallerDisplay ? '13px' : '15px'}
                  >
                    New message
                  </Text>
                  <Text
                    textStyle="headingFamilyRegularGooglePay"
                    fontSize={previewData.smallerDisplay ? '12px' : '13px'}
                    lineHeight="base"
                  >
                    Tap to view pass
                  </Text>
                  <Text
                    textStyle="headingFamilySemiBoldGooglePay"
                    fontSize={previewData.smallerDisplay ? '12px' : '13px'}
                    pt={2}
                  >
                    Notification settings
                  </Text>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        </Box>
      </Flex>
    </Box>
  );
}

export default PreviewPassNotificationsAndroid;

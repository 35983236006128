import appsignal from '../../appsignal';
import {
  Heading,
  Input,
  Center,
  FormControl,
  FormLabel,
  FormErrorMessage,
  VStack,
  Button,
  Box,
  Link,
  Text,
  Flex,
  useToast,
  InputGroup,
  InputRightElement,
  useColorModeValue,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import CardForm from '../../components/common/CardForm';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { FORM_PADDING_TOP_XL } from '../../utils/consts';
import { useContext, useState } from 'react';
import { useNavigate } from 'react-router';
import CustomToast from '../../common/CustomToast';
import { CustomVisibilityIcon } from '../../theme/icons/CustomVisibilityIcon';
import { CustomVisibilityOffIcon } from '../../theme/icons/CustomVisibilityOffIcon';
import { AuthContext, KEYS } from '../../context/AuthContext';
import { HttpContext } from '../../context/HttpContext';

function LoginPage() {
  const { setAuthState, setAuthTokens } = useContext(AuthContext);
  const { authAxios, publicAxios } = useContext(HttpContext);
  const toast = useToast();
  const location = useLocation();
  const navigate = useNavigate();
  const [show, setShow] = useState(false);

  let nextPathname = location?.state?.next?.pathname ?? '/';
  if (nextPathname === '/logout') {
    nextPathname = '/';
  }

  const showPasswordClickHandler = () => setShow(!show);

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .required('Please enter your email')
      .email('Please enter a valid email address'),
    password: Yup.string().required('Please enter your password'),
  });
  const formOptions = {
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
  };

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting, isDirty, isValid },
    reset,
  } = useForm(formOptions);

  const onSubmit = async (values) => {
    try {
      const { email, password } = values;
      const response = await publicAxios.post(`accounts/sign_in`, {
        account: {
          email: email,
          password: password,
        },
      });

      const { token, refresh_token: refreshToken } = response.data;

      if (token) {
        localStorage.setItem(KEYS.token, token);
        const { data } = await authAxios.get(`accounts/me`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const account = data.account || null;
        if (account) {
          setAuthState({ token, account, refreshToken });
          // debugger;
        } else {
          setAuthTokens({ token, refreshToken });
        }
        reset();

        if (nextPathname !== '/') {
          setTimeout(() => navigate(nextPathname, { replace: true }), 2);
        }
      }
    } catch (onError) {
      appsignal.sendError(onError);
      console.log(onError);
      const error = onError.response.data.error;
      toast({
        render: (props) => (
          <CustomToast
            status="error"
            title={error || 'Something went wrong. Please try again later.'}
            description=""
            onClose={props.onClose}
            id={props.id}
          />
        ),
      });
    }
  };

  const modeIconColor = useColorModeValue('secondaryDark06', '#DDDDDD');
  const modeText = useColorModeValue('secondaryDark08', '#DDDDDD');

  return (
    <Center pt={FORM_PADDING_TOP_XL} pb="10px">
      <CardForm>
        <form onSubmit={handleSubmit(onSubmit)}>
          <VStack spacing={5} alignItems="flex-start">
            <VStack spacing={2} alignItems="flex-start" mb={4}>
              <Heading size="2xl">Welcome Back</Heading>
              <Text textStyle="bodyRoman" color={modeText}>
                Enter your email and password to sign in
              </Text>
            </VStack>
            <FormControl isRequired isInvalid={errors.email}>
              <FormLabel>Email</FormLabel>
              <Input
                id="email"
                type="email"
                placeholder="Your email address"
                {...register('email')}
              />
              <FormErrorMessage mt={2} ml={5}>
                {errors.email?.message || errors.email}
              </FormErrorMessage>
            </FormControl>
            <FormControl isRequired isInvalid={errors.password}>
              <FormLabel>Password</FormLabel>
              <InputGroup>
                <Input
                  id="password"
                  type={show ? 'text' : 'password'}
                  placeholder="Your password"
                  {...register('password')}
                />
                <InputRightElement>
                  {show ? (
                    <CustomVisibilityOffIcon
                      onClick={showPasswordClickHandler}
                      boxSize="18px"
                      cursor="pointer"
                      color={modeIconColor}
                    />
                  ) : (
                    <CustomVisibilityIcon
                      onClick={showPasswordClickHandler}
                      boxSize="18px"
                      cursor="pointer"
                      color={modeIconColor}
                    />
                  )}
                </InputRightElement>
              </InputGroup>
              <FormErrorMessage mt={2} ml={5}>
                {errors.password?.message || errors.password}
              </FormErrorMessage>
            </FormControl>
            <Flex w="full" justifyContent="flex-end">
              <Link
                as={RouterLink}
                to="/forgot-password"
                color="brand"
                textStyle="bodyFamilyMedium"
                fontSize="14px"
              >
                Forgot password?
              </Link>
            </Flex>
            <Box w="full">
              <Button
                type="submit"
                isLoading={isSubmitting}
                disabled={!isDirty || !isValid || isSubmitting}
                mt={3}
                alt="Login"
              >
                Login
              </Button>
            </Box>
          </VStack>
        </form>
      </CardForm>
    </Center>
  );
}

export default LoginPage;

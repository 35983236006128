import { Icon } from '@chakra-ui/react';

export const CustomScanMultiIcon = (props) => (
  <Icon viewBox="0 0 60 60" {...props}>
    <path
      className="multicolorElToWhite darkBgElToBrand"
      d="M0.5 8C0.5 3.85787 3.85786 0.5 8 0.5L21.3214 0.5C22.7021 0.5 23.8214 1.61929 23.8214 3C23.8214 4.38071 22.7021 5.5 21.3214 5.5L8 5.5C6.61929 5.5 5.5 6.61929 5.5 8L5.5 21.3214C5.5 22.7021 4.38071 23.8214 3 23.8214C1.61929 23.8214 0.5 22.7021 0.5 21.3214L0.5 8Z"
      fill="#29323A"
    />
    <path
      className="multicolorElToWhite darkBgElToBrand"
      d="M0.5 52C0.5 56.1421 3.85786 59.5 8 59.5H21.3214C22.7021 59.5 23.8214 58.3807 23.8214 57C23.8214 55.6193 22.7021 54.5 21.3214 54.5H8C6.61929 54.5 5.5 53.3807 5.5 52L5.5 38.6786C5.5 37.2979 4.38071 36.1786 3 36.1786C1.61929 36.1786 0.5 37.2979 0.5 38.6786L0.5 52Z"
      fill="#29323A"
    />
    <path
      className="multicolorElToWhite darkBgElToBrand"
      d="M59.5 8C59.5 3.85787 56.1421 0.5 52 0.5L38.6786 0.5C37.2979 0.5 36.1786 1.61929 36.1786 3C36.1786 4.38071 37.2979 5.5 38.6786 5.5L52 5.5C53.3807 5.5 54.5 6.61929 54.5 8V21.3214C54.5 22.7021 55.6193 23.8214 57 23.8214C58.3807 23.8214 59.5 22.7021 59.5 21.3214V8Z"
      fill="#29323A"
    />
    <path
      className="multicolorElToWhite darkBgElToBrand"
      d="M59.5 52C59.5 56.1421 56.1421 59.5 52 59.5H38.6786C37.2979 59.5 36.1786 58.3807 36.1786 57C36.1786 55.6193 37.2979 54.5 38.6786 54.5H52C53.3807 54.5 54.5 53.3807 54.5 52V38.6786C54.5 37.2979 55.6193 36.1786 57 36.1786C58.3807 36.1786 59.5 37.2979 59.5 38.6786V52Z"
      fill="#29323A"
    />
    <path
      className="multicolorElToDark darkBgElToWhite"
      d="M14 30C14 28.6193 15.1193 27.5 16.5 27.5H43.0179C44.3986 27.5 45.5179 28.6193 45.5179 30C45.5179 31.3807 44.3986 32.5 43.0179 32.5H16.5C15.1193 32.5 14 31.3807 14 30Z"
      fill="#FF8477"
    />
  </Icon>
);

import React, { useContext, useEffect, useState } from 'react';
import PassTemplateContext from '../../../store/client/PassTemplateContext';
import CropModal from '../../modals/CropModal';
import CustomDropzone from '../../common/CustomDropzone';
import {
  Box,
  Flex,
  Heading,
  Link,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react';
import { CustomPlusIcon } from '../../../theme/icons/CustomPlusIcon';
import { NUMBER_KEYS } from '../../../utils/consts';
import useAddWatermark from '../../../hooks/useAddWatermark';
import {
  BANNER_BASE64,
  EVENT_TICKET_BANNER_BASE64,
} from '../../../utils/base64';

function BannerImage(props) {
  const [droppedImage, setDroppedImage] = useState(null);
  const passTemplateCtx = useContext(PassTemplateContext);
  const { activeStep } = passTemplateCtx;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [aspectRatio, setAspectRatio] = useState('');
  const [lowerCaseStampNumber, setLowerCaseStampNumber] = useState('');
  const { determineWatermarkColor } = useAddWatermark();
  const [showCustomDropzone, setShowCustomDropzone] = useState(
    !!passTemplateCtx.bannerImage
  );
  const modeColorText = useColorModeValue('secondaryDark', '#DDDD');
  const modeColorTextHover = useColorModeValue('black', 'white');

  const {
    [`${
      props.type !== 'banner'
        ? `stamp${NUMBER_KEYS[activeStep || 0]}`
        : 'banner'
    }Logo`]: image,
  } = passTemplateCtx;

  function handleUpdateDroppedImage(image) {
    setDroppedImage(image);
  }

  async function handleSaveDroppedImage(base64DroppedFile) {
    const watermarkColor = await determineWatermarkColor(base64DroppedFile);
    passTemplateCtx[
      `update${
        props.type !== 'banner' ? `Stamp${NUMBER_KEYS[activeStep || 0]}` : ''
      }WatermarkColor`
    ](watermarkColor);
    props.setEditedFields({
      ...props.editedFields,
      [`${
        props.type === 'banner'
          ? 'watermark_color'
          : `stamp_${lowerCaseStampNumber}_watermark_color`
      }`]: watermarkColor,
    });
    passTemplateCtx[
      `update${
        props.type !== 'banner'
          ? `Stamp${NUMBER_KEYS[activeStep || 0]}`
          : 'Banner'
      }Image`
    ](base64DroppedFile);
    passTemplateCtx[
      `update${
        props.type !== 'banner'
          ? `Stamp${NUMBER_KEYS[activeStep || 0]}`
          : 'Banner'
      }Logo`
    ]({
      ...image,
      src: droppedImage.preview,
      mimeType: droppedImage.type,
    });
    props.setEditedImages({
      ...props.editedImages,
      [`${
        props.type === 'banner'
          ? 'banner_image'
          : `stamp_${lowerCaseStampNumber}_image`
      }`]: true,
    });
    if (props.type !== 'banner') {
      passTemplateCtx.updateBannerImage(base64DroppedFile);
    }
    if (props.isEdit) {
      props.setBannerWatermarkFieldDisabled(false);
      passTemplateCtx.updateDisplayBannerWatermarkPreview(true);
    }
  }

  function handleAddBannerToTemplate() {
    passTemplateCtx.resetBannerLogo();
    passTemplateCtx.updateBannerImage(
      passTemplateCtx.passType === 'eventTicket'
        ? EVENT_TICKET_BANNER_BASE64
        : BANNER_BASE64
    );
    props.setEditedImages({ ...props.editedImages, banner_image: true });
    passTemplateCtx.updateDisplayBannerWatermarkPreview(true);
    props.setBannerWatermarkFieldDisabled(false);
    setShowCustomDropzone(true);
  }

  function isImageAdded() {
    onOpen();
  }

  async function updateImage(image) {
    passTemplateCtx[
      `update${
        props.type !== 'banner'
          ? `Stamp${NUMBER_KEYS[activeStep || 0]}`
          : 'Banner'
      }Logo`
    ](image);
  }

  useEffect(() => {
    if (passTemplateCtx.passType === 'eventTicket') {
      setAspectRatio('1125x294');
    } else if (
      passTemplateCtx.passType === 'storeCard' ||
      passTemplateCtx.passType === 'stampCard'
    ) {
      setAspectRatio('1125x432');
    } else {
      setAspectRatio('1125x370');
    }
  }, [passTemplateCtx.passType]);

  useEffect(() => {
    if (props.type === 'stamp') {
      let lowerCaseNumber = NUMBER_KEYS[activeStep || 0].toLowerCase();
      setLowerCaseStampNumber(`${lowerCaseNumber}`);
    }
  }, [props.type, activeStep]);

  useEffect(() => {
    if (passTemplateCtx.passType !== 'generic') {
      setShowCustomDropzone(true);
    } else {
      setShowCustomDropzone(!!passTemplateCtx.bannerImage);
    }
  }, [passTemplateCtx.passType]);

  return (
    <>
      {showCustomDropzone ? (
        <>
          <CropModal
            key="banner_image_crop_modal"
            onClose={onClose}
            onOpen={onOpen}
            isOpen={isOpen}
            image={image}
            updateImage={updateImage}
            parent="banner_image"
            title="Crop banner image"
            droppedImage={droppedImage}
            saveDroppedImage={handleSaveDroppedImage}
          />
          <CustomDropzone
            key="banner_image_drop_zone"
            image={image}
            isImageAdded={isImageAdded}
            parent={props.type === 'banner' ? 'banner_image' : `stamp_image`}
            lowerCaseStampNumber={lowerCaseStampNumber}
            upperCaseStampNumber={NUMBER_KEYS[activeStep || 0]}
            // explanation="Recommended size: 1097x288 pixels. Recommended file type is jpg."
            explanation={`Minimum size: 500x200 pixels. Recommended size: ${aspectRatio} pixels. Use wide, rectangular images. Recommended file type is png.`}
            accept={['image/jpg', 'image/jpeg', 'image/png', 'image/x-png']}
            droppedImage={droppedImage}
            updateDroppedImage={handleUpdateDroppedImage}
            removable={props.removable}
            setShowCustomDropzone={setShowCustomDropzone}
            isEdit={props.isEdit}
            editedFields={props.editedFields}
            setEditedFields={props.setEditedFields}
          />
        </>
      ) : (
        <Box as={Link} onClick={handleAddBannerToTemplate}>
          <Flex align="center" gap={1} mt={2}>
            <CustomPlusIcon color="#FF8477" boxSize="12px" />
            <Heading
              fontSize="14px"
              top="1px"
              pos="relative"
              color={props.publicPage ? '#DDD' : modeColorText}
              _hover={
                props.publicPage
                  ? { color: 'white', transition: '0.3s' }
                  : { color: modeColorTextHover, transition: '0.3s' }
              }
              transition="0.3s"
            >
              Add banner image to template
            </Heading>
          </Flex>
        </Box>
      )}
    </>
  );
}

export default BannerImage;

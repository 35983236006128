import { Icon } from '@chakra-ui/react';

export const CustomCheckIcon = (props) => (
  <Icon viewBox="0 0 34 34" {...props}>
    <path
      fill="currentColor"
      d="M16.9999 33.6667C7.79492 33.6667 0.333252 26.205 0.333252 17C0.333252 7.79504 7.79492 0.333374 16.9999 0.333374C26.2049 0.333374 33.6666 7.79504 33.6666 17C33.6666 26.205 26.2049 33.6667 16.9999 33.6667ZM15.3383 23.6667L27.1216 11.8817L24.7649 9.52504L15.3383 18.9534L10.6233 14.2384L8.26659 16.595L15.3383 23.6667Z"
    />
  </Icon>
);

import { useContext, useEffect, useRef } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useTable, usePagination } from 'react-table';
import {
  Stack,
  Flex,
  Box,
  Heading,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Button,
  Text,
  useMediaQuery,
  Spinner,
  Hide,
  Image,
  HStack,
  Show,
  useColorModeValue,
  useColorMode,
} from '@chakra-ui/react';
import TableBox from '../../common/TableBox';
import ReactTablePagination from '../../../common/ReactTablePagination';
import EventsRenderStatus from './EventsRenderStatus';
import ticketEventSrc from '../../../assets/vectors/ticketEvent.svg';
import ticketEventDarkSrc from '../../../assets/vectors/ticketEvent-dark.svg';
import EmptyState from '../../../common/EmptyState';
import EventsListMoreInfo from './EventsListMoreInfo';
import { CustomEditIcon } from '../../../theme/icons/CustomEditIcon';
import { CustomVisibilityIcon } from '../../../theme/multicolor-icons/CustomVisibilityIcon';
import EventsIconAnimated from '../../../theme/illustrations-animated/EventsIconAnimated';
import EventsIconDarkAnimated from '../../../theme/illustrations-animated/EventsIconDarkAnimated';
import { MeContext } from '../../../context/MeContext';

// Create a default prop getter (per documentation https://react-table.tanstack.com/docs/examples/data-driven-classes-and-styles)
const defaultPropGetter = () => ({});

export default function EventsListTable({
  columns,
  data,
  fetchData,
  loading,
  total,
  pageCount: controlledPageCount,
  getColumnProps = defaultPropGetter,
  getCellProps = defaultPropGetter,
}) {
  const navigate = useNavigate();
  const { colorMode } = useColorMode();
  const meCtx = useContext(MeContext);
  const { filterEntity } = meCtx.state;

  // use this ref to keep track of updating internal table state
  const tableStateUpdateRef = useRef(false);

  const [isLargerThan2XL] = useMediaQuery('(min-width: 96em)');
  const [isLargerThanXL] = useMediaQuery('(min-width: 80em)');
  const [isLargerThanLG] = useMediaQuery('(min-width: 62em)');
  const [isLargerThanMD] = useMediaQuery('(min-width: 48em)');
  const [isLargerThanSM] = useMediaQuery('(min-width: 30em)');
  const [isLargerThanS] = useMediaQuery('(min-width: 18em)');
  const [isLargerThanXS] = useMediaQuery('(min-width: 10em)');

  const modeEventsIcon = useColorModeValue(
    <EventsIconAnimated />,
    <EventsIconDarkAnimated />
  );
  const modeTicketEventSrc = useColorModeValue(
    ticketEventSrc,
    ticketEventDarkSrc
  );

  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page, // Instead of using 'rows', we'll use page, which has only the rows for the active page
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    setHiddenColumns,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
      manualPagination: true, // Tell the usePagination
      // hook that we'll handle our own data fetching
      // This means we'll also have to provide our own
      // pageCount.
      pageCount: controlledPageCount,
    },
    usePagination
  );

  useEffect(() => {
    if (isLargerThan2XL) {
      setHiddenColumns(['moreInfo']);
    } else if (isLargerThanXL) {
      setHiddenColumns(['moreInfo']);
    } else if (isLargerThanLG) {
      setHiddenColumns(['eventStartTime']);
    } else if (isLargerThanMD) {
      setHiddenColumns(['eventStartDate', 'eventStartTime']);
    } else if (isLargerThanSM) {
      setHiddenColumns(['eventStartDate', 'eventStartTime', 'status']);
    } else if (isLargerThanS) {
      setHiddenColumns([
        'eventStartDate',
        'eventStartTime',
        'status',
        'venueName',
        'actionSecond',
      ]);
    } else if (isLargerThanXS) {
      setHiddenColumns([
        'venueName',
        'eventStartDate',
        'eventStartTime',
        'status',
        'action',
        'actionSecond',
      ]);
    } else {
      setHiddenColumns([
        'name',
        'venueName',
        'eventStartDate',
        'eventStartTime',
        'status',
        'action',
        'actionSecond',
      ]);
    }
  }, [
    isLargerThan2XL,
    isLargerThanXL,
    isLargerThanLG,
    isLargerThanMD,
    isLargerThanSM,
    isLargerThanS,
    setHiddenColumns,
    isLargerThanXS,
  ]);

  // Listen for changes in pagination and use the state to fetch our new data
  useEffect(() => {
    fetchData({ pageIndex, pageSize });
  }, [fetchData, pageIndex, pageSize]);

  // reset the page index to 0 when the table data updates due to something other than internal table state changes
  useEffect(() => {
    gotoPage(0);
  }, [gotoPage, filterEntity]);

  // clear our ref when the data is loaded, after we perform any side effects
  useEffect(() => {
    tableStateUpdateRef.current = false;
  }, [data]);

  function handleEditClick(e, row) {
    e.stopPropagation();
    navigate(`/events/edit/${row.original.id}`);
  }

  function handleView(row) {
    navigate(row.original.exampleForNestedObject.eventSinglePageUrl);
  }

  if (loading) {
    return (
      <Spinner
        width="52px"
        height="52px"
        thickness="4px"
        speed="0.65s"
        emptyColor="quinaryBackground"
        mt={4}
        mx="auto"
      />
    );
  }

  if (data.length === 0 && !loading) {
    return (
      <EmptyState
        iconSvgAnimated={modeEventsIcon}
        heading="There are no Events yet."
        text="Click on the button below and start creating Events."
      >
        <Button
          alt="Create event"
          size="sm"
          as={RouterLink}
          to="/events/create"
        >
          Create event
        </Button>
      </EmptyState>
    );
  }

  return (
    <>
      <TableBox>
        <Heading size="lg" mb="16px">
          Events{' '}
          <Text
            as="span"
            textStyle="headingFamilyMedium"
            fontSize="18px"
            fontWeight="500"
          >
            (
            <Box as="span" color="brand">
              {total}
            </Box>
            )
          </Text>
        </Heading>
        <Table {...getTableProps()}>
          <Thead>
            {headerGroups.map((headerGroup) => (
              <Tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => {
                  return (
                    <Th
                      {...column.getHeaderProps()}
                      {...column.getToggleHiddenProps()}
                      isNumeric={column.isNumeric}
                    >
                      {column.render('Header')}
                    </Th>
                  );
                })}
              </Tr>
            ))}
          </Thead>

          {data.length > 0 && (
            <Tbody {...getTableBodyProps()}>
              {page.map((row, i) => {
                prepareRow(row);
                return (
                  <Tr
                    {...row.getRowProps()}
                    onClick={() => {
                      handleView(row);
                    }}
                  >
                    {row.cells.map((cell) => {
                      return (
                        <Td
                          {...cell.getCellProps([
                            {
                              className: cell.column.className,
                              style: cell.column.style,
                            },
                            getColumnProps(cell.column),
                            getCellProps(cell),
                          ])}
                          isNumeric={cell.column.isNumeric}
                          textAlign={
                            (cell.column.id === 'actionSecond' ||
                              cell.column.id === 'actionFirst') &&
                            'right'
                          }
                        >
                          {cell.column.id === 'status' ? (
                            <EventsRenderStatus
                              value={cell.row.original.status}
                            />
                          ) : cell.column.id === 'actionFirst' ? (
                            <Button variant="link" pt="5px">
                              <Flex justifyContent="center" alignItems="center">
                                <Show above="xl">
                                  <CustomVisibilityIcon
                                    className={
                                      colorMode === 'dark' && 'multicolorDarkBg'
                                    }
                                  />
                                </Show>
                                <Text
                                  textStyle="headingFamilyMedium"
                                  fontWeight="500"
                                  pl="7px"
                                  pt="1px"
                                >
                                  View
                                </Text>
                              </Flex>
                            </Button>
                          ) : cell.column.id === 'actionSecond' &&
                            cell.row.original.status !== 'ended' ? (
                            <Button
                              size="xs"
                              px="15px"
                              onClick={(e) => {
                                handleEditClick(e, row);
                              }}
                            >
                              <HStack zIndex={1}>
                                <Show below="sm">
                                  <CustomEditIcon
                                    boxSize="20px"
                                    color1="#fff"
                                  />
                                </Show>
                                <Show above="xl">
                                  <CustomEditIcon
                                    boxSize="20px"
                                    color1="#fff"
                                  />
                                </Show>
                                <Hide below="sm">
                                  <Text
                                    textStyle="headingFamilyMedium"
                                    fontWeight="500"
                                    lineHeight="28px"
                                    textTransform="none"
                                  >
                                    Edit event
                                  </Text>
                                </Hide>
                              </HStack>
                            </Button>
                          ) : cell.column.id === 'name' ? (
                            <Box>
                              <HStack spacing={3}>
                                <Hide below="md">
                                  <Box>
                                    <Image
                                      src={modeTicketEventSrc}
                                      maxWidth="50px"
                                    />
                                  </Box>
                                </Hide>
                                <Text>{cell.value}</Text>
                              </HStack>
                            </Box>
                          ) : cell.column.id === 'moreInfo' ? (
                            <EventsListMoreInfo cell={cell}>
                              <EventsRenderStatus
                                value={cell.row.original.status}
                                inDrawer
                              />
                            </EventsListMoreInfo>
                          ) : cell.value !== null ? (
                            cell.render('Cell')
                          ) : (
                            <Text as="span" color="secondaryDark06">
                              N/D
                            </Text>
                          )}
                        </Td>
                      );
                    })}
                  </Tr>
                );
              })}
            </Tbody>
          )}
        </Table>

        {data.length > 0 && (
          <Flex
            mt={6}
            flexDirection={{ base: 'column-reverse', '2xl': 'row' }}
            justifyContent={{ base: 'none', '2xl': 'space-between' }}
          >
            <Stack
              spacing="18px"
              direction={{ base: 'column', sm: 'row' }}
              shouldWrapChildren="true"
            />
            <ReactTablePagination
              canPreviousPage={canPreviousPage}
              canNextPage={canNextPage}
              pageOptions={pageOptions}
              pageCount={pageCount}
              gotoPage={gotoPage}
              nextPage={nextPage}
              previousPage={previousPage}
              setPageSize={setPageSize}
              pageIndex={pageIndex}
              pageSize={pageSize}
            />
          </Flex>
        )}
      </TableBox>
    </>
  );
}

import {
  Container,
  Divider,
  Grid,
  GridItem,
  useDisclosure,
  useColorModeValue,
} from '@chakra-ui/react';
import Breadcrumbs from '../../common/Breadcrumbs';
import ApiKeysList from '../../components/client/api-docs/ApiKeysList';
import ViewApiDocs from '../../components/client/api-docs/ViewApiDocs';
import CreateApiKeyModal from '../../components/modals/CreateApiKeyModal';
import { KeysListContextProvider } from '../../store/client/KeysListContext';
import ApiKeysIconAnimated from '../../theme/illustrations-animated/ApiKeysIconAnimated';
import ApiKeysIconDarkAnimated from '../../theme/illustrations-animated/ApiKeysIconDarkAnimated';
import ApiKeyListHeader from '../../components/client/api-docs/ApiKeyListHeader';
import WebhooksContainer from '../../components/client/api-docs/WebhooksContainer';
import useOrganizationStatus from '../../hooks/useOrganisationStatus';

const DeveloperToolsPage = () => {
  const {
    isOpen: isCreateApiKeyOpen,
    onOpen: onCreateApiKeyOpen,
    onClose: onCreateApiKeyClose,
  } = useDisclosure();

  const modeKeysIcon = useColorModeValue(
    <ApiKeysIconAnimated />,
    <ApiKeysIconDarkAnimated />
  );
  const { shouldRender, components } = useOrganizationStatus(modeKeysIcon);

  const breadcrumbLinks = [
    {
      linkName: 'Home',
      linkUrl: '/',
    },
    {
      linkName: 'Developer tools',
      linkUrl: '/developer-tools',
    },
  ];

  return (
    <>
      <KeysListContextProvider>
        <Container display="flex" flexDir="column" flex="1" pb={6}>
          <Breadcrumbs breadcrumbLinks={breadcrumbLinks} />
          <ApiKeyListHeader onCreateApiKeyOpen={onCreateApiKeyOpen} />
          <Divider borderColor="primaryBackground" mb={6} />
          {components}
          {shouldRender && (
            <Grid
              templateColumns={{
                base: '1fr',
                xl: '2fr 1fr',
                '2xl': '2fr 1fr',
              }}
              gap={6}
              mt={6}
            >
              <GridItem w="full">
                <ApiKeysList />
                <WebhooksContainer />
              </GridItem>
              <GridItem w="full" placeSelf="flex-start">
                <ViewApiDocs />
              </GridItem>
            </Grid>
          )}
        </Container>
        <CreateApiKeyModal isOpen={isCreateApiKeyOpen} onClose={onCreateApiKeyClose} />
      </KeysListContextProvider>
    </>
  );
};
export default DeveloperToolsPage;

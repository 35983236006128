import {
  Button,
  Center,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useColorModeValue,
  VStack,
} from '@chakra-ui/react';
import { CustomEmailMultiIcon } from '../../theme/multicolor-icons/CustomEmailMultiIcon';

const MessagingConfirmationModal = ({
  onClose,
  isOpen,
  onConfirm,
  totalPasses,
}) => {
  const modeBoxBg = useColorModeValue('white', 'secondaryDark');
  const modeTextBg = useColorModeValue('white', 'primaryDark06');
  const modeLabelBg = useColorModeValue('white', 'primaryDark03');

  const handleConfirm = () => {
    onConfirm();
    onClose();
  };

  return (
    <>
      <Modal onClose={onClose} isOpen={isOpen} size="xl">
        <ModalOverlay />
        <ModalContent bg={modeBoxBg} p="20px">
          <ModalHeader bg={modeTextBg} pb="20px" borderRadius="15px 15px 0 0">
            <Center>
              <CustomEmailMultiIcon width="60px" height="60px" />
            </Center>
          </ModalHeader>
          <ModalBody bg={modeLabelBg}>
            <VStack spacing="10px" textAlign="center">
              <Text fontSize="18px" textStyle="bodyBold" color="secondaryDark">
                You are about to message {totalPasses} recipient
                {totalPasses > 1 ? 's' : ''}
              </Text>
              <Text
                fontSize="16px"
                textStyle="bodyRegular"
                color="secondaryDark"
              >
                Are you sure you wish to proceed?
              </Text>
            </VStack>
          </ModalBody>
          <ModalFooter bg={modeTextBg} borderRadius="0 0 15px 15px">
            <Stack
              mt="15px"
              spacing="10px"
              direction={{ base: 'column', sm: 'row' }}
              width={{ base: 'full', sm: 'auto' }}
            >
              <Button
                onClick={onClose}
                alt="Cancel"
                variant="secondary"
                width={{ base: 'full', sm: 'auto' }}
              >
                Cancel
              </Button>
              <Button
                alt="confirm"
                width={{ base: 'full', sm: 'auto' }}
                onClick={handleConfirm}
              >
                Send
              </Button>
            </Stack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default MessagingConfirmationModal;
